import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { ErrorMessage, useFormikContext } from 'formik';
import { Paperclip } from 'react-bootstrap-icons';

import DeleteIcon from 'assets/svg/delete-ic.svg';
import constants from 'constants/constants';
import Image from 'components/common/Image';

function UploadCoverImage({ onFileChange, imageFile, removeImage }) {
  const formikContent = useFormikContext();

  // drag state
  const [dragActive, setDragActive] = useState(false);
  // ref
  const inputRef = useRef([]);

  // handle drag events
  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  // triggers when file is dropped
  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      formikContent.setFieldValue('cover_image', e.dataTransfer.files[0]);
      onFileChange(e, { name: 'cover_image', type: 'single' });
    }
  };


  // triggers the input when the button is clicked
  const onButtonClick = (type) => {
    const currentItem = type == 'multiple' ? inputRef.current.attached_files : inputRef.current.cover_image;
    currentItem.click();
  };

  return (
    <div className="mb-3 row">
      <label htmlFor=" " className="form-label">
        プロジェクトのサムネイル画像
        <span className="label-required">必須</span>
      </label>
      <div className="col-12">
        <div className="input-group" id="form-file-upload" onDragEnter={handleDrag}>
          <input
            accept={constants.UPLOAD_IMAGE_SUPPORTED_FORMATS}
            className="form-control rounded-0 d-none"
            name="cover_image"
            id="cover_image"
            ref={(element) => { inputRef.current.cover_image = element; }}
            onChange={(e) => {
              formikContent.setFieldValue('cover_image', e.target.files[0]);
              onFileChange(e, { name: 'cover_image', type: 'single' });
            }}
            onClick={(e) => { e.target.value = null; }}
            type="file"
          />
          <label id="label-file-upload" htmlFor="cover_image" className={dragActive ? 'drag-active' : ''}>
            <div className="drap-text">
              <p className="fw-bold text-top">
                <span>ファイルをドラッグ＆ドロップするか</span>
                <br />
                <span>クリップボードから画像を貼り付けてください</span>
              </p>
              <p className="divider">または</p>
              <button
                className="btn btn-primary btn--blue"
                onClick={onButtonClick}
                title="ファイル選択（1枚まで）"
                type="button"
              >
                <Paperclip className="paperclip" />
                ファイル選択（1枚まで）
              </button>
              <p className="text-bottom">
                <span>png,jpg,jpeg形式のみアップロード可</span>
                <br />
                <span>画像は８M以下でアップロードしてください</span>
                <br />
                <span>※推奨画像サイズは1024px × 768px 以上になります</span>
              </p>
            </div>
          </label>
          { dragActive
            && (
            <div
              id="drag-file-element"
              onDragEnter={handleDrag}
              onDragLeave={handleDrag}
              onDragOver={handleDrag}
              onDrop={handleDrop}
            />
            ) }
        </div>
        <div className={formikContent.touched.cover_image && formikContent.errors.cover_image && 'is-invalid'}> </div>
        <ErrorMessage name="cover_image" component="div" className="invalid-feedback" />
        {Object.keys(imageFile.cover_image).length > 0 && (
          <div className="row">
            <div className="col-12 image-display-block">
              <Image className="cover-image-view" src={imageFile.cover_image.url} alt="" />
              <button
                className="btn delete-btn"
                onClick={() => {
                  formikContent.setFieldValue('cover_image', '');
                  removeImage({ id: imageFile.cover_image.id, name: 'cover_image' });
                }}
                title="Delete Image"
                type="button"
              >
                <img src={DeleteIcon} alt="" className="delete-ic" />
              </button>
            </div>
          </div>
        )}

      </div>
    </div>
  );
}

UploadCoverImage.propTypes = {
  imageFile: PropTypes.object.isRequired,
  onFileChange: PropTypes.func.isRequired,
  removeImage: PropTypes.func.isRequired
};

export default UploadCoverImage;
