import moment from 'moment';
import 'moment-timezone';
import * as Yup from 'yup';
import * as ja from 'helpers/yup-locale-ja';
import { USER_TYPE_NPO } from 'redux/client/auth';
import constants from 'constants/constants';

moment.tz.setDefault('Asia/Tokyo');

Yup.setLocale(ja.suggestive);
export const CreateProjectValidation = (user, submitActionCreateProject) => {
  return Yup.object().shape({
    delivery_at: Yup.date().required().min(moment()),
    type: Yup.string().required(),
    follower_min: Yup.number().required().min(0).max(9999999),
    recruits_number: Yup.number().required().min(1).max(9999999),
    budget:
      user.user_type != USER_TYPE_NPO
        && Yup.string().required().min(3, '100pt以上にしてください').max(9, '9,999,999pt以下にしてください'),
    reward_condition: user.user_type != USER_TYPE_NPO && Yup.string().when('type', {
      is: constants.PROJECT_TYPE.CANDIDACY.toString(),
      then: Yup.string().required().max(9, '9,999,999以下にしてください')
    }),
    comparison_type: user.user_type != USER_TYPE_NPO && Yup.string().when('type', {
      is: constants.PROJECT_TYPE.CANDIDACY.toString(),
      then: Yup.string().required().oneOf(['0', '1'], 'いずれかを入力してください')
    }),
    notes: Yup.string().max(1000),
    project_title: Yup.string().required().max(255),
    project_contents: Yup.string().required().max(3000),
    project_type: Yup.string().required(),
    project_category: Yup.string().required(),
    area_ids: Yup.array().of(Yup.string()).min(1, '1つ以上選択してください'),
    gifting: Yup.boolean().required(),
    gifting_notes: Yup.string().when('gifting', {
      is: (gifting) => {
        return !gifting;
      },
      then: Yup.string().required().max(1000)
    }),
    project_url: Yup.string().url(),
    cover_image: submitActionCreateProject === constants.PROJECT_PUBLIC.PUBLIC && Yup.mixed()
      .required('選択してください。')
      .test(
        'fileSize',
        '8MB以下のファイルを指定してください。',
        (value) => value === null || (value && value.size < constants.MAX_UPLOAD_SIZE_8MB)
      )
      .test(
        'fileFormat',
        'タイプのファイルを指定してください。',
        (value) => value === null || (value && constants.UPLOAD_IMAGE_SUPPORTED_FORMATS.includes(value.type))
      ),
    attached_files: Yup.mixed()
      .test('fileFormat', 'タイプのファイルを指定してください。', (value) => {
        if (value && value?.length > 0) {
          for (let i = 0; i < value.length; i += 1) {
            if (value[i].id == null && !constants.UPLOAD_FILE_SUPPORTED_FORMATS.includes(value[i]?.type)) {
              return false;
            }
          }
        }
        return true;
      })
      .test('fileSize', '5MB以下のファイルを指定してください。', (value) => {
        if (value && value?.length > 0) {
          for (let i = 0; i < value.length; i += 1) {
            if (value[i].id == null && value[i]?.size > constants.MAX_UPLOAD_SIZE_5MB) {
              return false;
            }
          }
        }
        return true;
      }),
    hashtag: Yup.array().max(7)
  });
};
