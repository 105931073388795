import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getMyNews } from 'redux/client/news';
import MyNewsList from './components/MyNewsList';


function MyNews() {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const store = useSelector((state) => ({
    myNews: state.news.myNews
  }));

  const onPagingClick = (event) => {
    _getMyNews(event.selected + 1);
  };

  useEffect(() => {
    _getMyNews(1);
  }, []);

  const _getMyNews = async (currentPage) => {
    try {
      setIsLoading(true);
      await dispatch(getMyNews(currentPage));
      setIsLoading(false);
      return true;
    } catch (error) {
      setIsLoading(false);
      return error;
    }
  };

  return (
    <div className="page-project my-projects">
      <div className="client-container">
        <div className="news-box">
          <div className="news">
            <div className="position-relative">
              <h2 className="page-title">お知らせ</h2>
              <div className="client-cpanel__page-title-after">
                <span className="shadow-text">News</span>
                <span className="stroke-text">Topics</span>
              </div>
            </div>
            <div className="news-content news-list mt-4">
              <MyNewsList
                isLoading={isLoading}
                news={store.myNews}
                onPagingClick={onPagingClick}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MyNews;
