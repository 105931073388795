import { HTTP_OK } from 'constants/ResponseStatus';
import { ERROR_404_PAGE } from 'constants/routes';
import React, { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { findByProjectIdAndInfluencer } from 'redux/client/message';
import Messages from '../talkroom/components/Messages';

function index() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [show, setShow] = useState(false);
  const [talkRoomSelect, setTalkRoomSelect] = useState(null);

  const handleMessageClose = () => {
    setShow(false);
    setTalkRoomSelect(null);
  };

  const _findTalkRoom = async () => {
    const resp = await dispatch(findByProjectIdAndInfluencer(id));
    if (resp.status != HTTP_OK) {
      navigate(ERROR_404_PAGE, {
        replace: true
      });
    }
    setTalkRoomSelect(resp.data);
  };

  const _initPage = async () => {
    setIsLoading(true);
    await _findTalkRoom();
    setIsLoading(false);
  };

  useEffect(() => {
    _initPage();
  }, []);

  return (
    <div className="client-cpanel-container page-message">
      { isLoading && (
      <div className="message-amazing">
        <div className="col-12">
          <h2 className="title mb-2 mb-sm-3">
            <Skeleton height={200} borderRadius={4} containerClassName="d-flex" />
          </h2>
        </div>
        <div className="vh-100">
          <Skeleton height={600} borderRadius={4} containerClassName="d-flex" />
        </div>
      </div>
      )}
      {talkRoomSelect && (
        <div className="message-amazing">
          <div className="col-12">
            <h2 className="title mb-2 mb-sm-3">{talkRoomSelect.project?.project_title}</h2>
          </div>
          <div className="vh-100 max-height-140vh message-mobile">
            <Messages
              talkRoomData={talkRoomSelect}
              isOffCanvas={false}
              show={show}
              handleMessageClose={handleMessageClose}
              projectData={talkRoomSelect.project}
              tiktokInfoData={talkRoomSelect.tiktok_info}
            />
          </div>
        </div>
      )}
    </div>
  );
}
export default index;
