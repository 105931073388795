import React, { useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import moment from 'moment';
import 'moment-timezone';
import { Button, Modal, Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import useSession from 'hooks/useSession';
import { setFormikErrors, scrollToErrorField, toMemberPage, findArray, whereInArrayConditions } from 'helpers/utils';
import * as ROUTES from 'constants/routes';
import { HTTP_OK, HTTP_UNPROCESSABLE_ENTITY } from 'constants/ResponseStatus';
import { useCallbackPrompt, useFileHandler } from 'hooks';
import { USER_TYPE_NPO } from 'redux/client/auth';
import { findMyProjectById, updateProject, validateEditProject } from 'redux/client/project';
import { getAllType } from 'redux/client/type';
import { getAllCategory } from 'redux/client/category_global';
import { getAllArea } from 'redux/client/area';
import LoadingOverlay from 'components/loading/LoadingOverlay';
import FileHandlerContext from 'hooks/FileHandlerContext';
import constants from 'constants/constants';
import DialogBox from 'components/common/DialogBox';
import EditProjectForm from './components/EditProjectForm';
import { EditProjectValidation } from './EditProjectValidation';

moment.tz.setDefault('Asia/Tokyo');

function Index() {
  const { id } = useParams();
  const { user } = useSession();
  const location = useLocation();
  const projectState = location.state || null;
  const [showModalUpdateSuccess, setShowModalUpdateSuccess] = useState(false);

  const [showDialog, setShowDialog] = useState(false);
  const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(showDialog);

  const fileHandler = useFileHandler({
    cover_image: projectState?.cover_image || {},
    attached_files: projectState?.attached_files || []
  });

  const { imageFile, setImageFile } = fileHandler;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const store = useSelector((state) => ({
    projectsOfUser: state.project.projectsOfUser,
    types: state.type.types,
    categories: state.categoryGlobal.categories,
    areas: state.area.areas
  }));

  const newProjectForm = {
    project_id: '',
    delivery_at: moment().add(1, 'day').format('YYYY-MM-DD'),
    type: user.user_type != USER_TYPE_NPO ? 0 : 1,
    follower_min: '',
    recruits_number: '',
    budget: '',
    reward_condition: '',
    comparison_type: '',
    notes: '',
    project_title: '',
    project_contents: '',
    project_type: '',
    project_category: '',
    area_ids: [],
    gifting: 0,
    gifting_notes: '',
    project_url: '',
    hashtag: ['#PR', '#ENMAPR'],
    cover_image: '',
    attached_files: [],
    public: 0
  };
  const [initFormikValues, setInitFormikValues] = useState(newProjectForm);

  const [isLoading, setIsLoading] = useState(false);

  const [myProject, setMyProject] = useState(null);

  const _getProjectDetail = async () => {
    setIsLoading(true);
    const resp = await dispatch(findMyProjectById(id));
    setIsLoading(false);
    if (resp.status != HTTP_OK || (resp.data && resp.data.length === 0)) {
      navigate(ROUTES.ERROR_404_PAGE, {
        replace: true
      });
    }
    if (resp.data?.is_allow_project_edit !== true) {
      navigate(toMemberPage(user?.user_type, ROUTES.MY_PROJECT_DETAIL.replace(':id', id)));
    }
    const respData = resp.data;
    setMyProject(respData);
    const areaIds = respData.area.map((el) => el.id.toString());
    if (projectState === null) {
      setInitFormikValues((prevState) => ({
        ...prevState,
        project_id: id,
        delivery_at: respData.delivery_at,
        type: user.user_type != USER_TYPE_NPO ? respData.type : prevState.type,
        follower_min: respData.follower_min,
        recruits_number: respData.recruits_number,
        budget: user.user_type != USER_TYPE_NPO
          ? parseInt(respData.budget.replace(/\D/g, ''), 10).toLocaleString() : 0,
        reward_condition: respData.reward_condition || '',
        comparison_type: respData.comparison_type?.toString() || '1',
        notes: respData.notes || '',
        project_title: respData.project_title || '',
        project_contents: respData.project_contents || '',
        project_type: respData.project_type,
        project_category: respData.project_category,
        area_ids: areaIds,
        gifting: respData.gifting,
        gifting_notes: respData.gifting_notes || '',
        project_url: respData.project_url || '',
        hashtag: respData?.hashtag.toString().split(','),
        cover_image: respData?.cover_image_file,
        attached_files: respData?.attachments_file,
        public: respData.public
      }));
      setImageFile({
        cover_image: respData?.cover_image_file,
        attached_files: respData?.attachments_file
      });
    } else {
      setInitFormikValues((prevState) => ({
        ...prevState,
        project_id: id,
        delivery_at: projectState.delivery_at,
        type: projectState.type,
        follower_min: projectState.follower_min,
        recruits_number: projectState.recruits_number,
        budget: user.user_type != USER_TYPE_NPO
          ? parseInt(respData.budget.replace(/\D/g, ''), 10).toLocaleString() : 0,
        reward_condition: projectState.reward_condition || '',
        comparison_type: projectState.comparison_type?.toString() || '1',
        notes: projectState.notes || '',
        project_title: projectState.project_title || '',
        project_contents: projectState.project_contents || '',
        project_type: projectState.project_type,
        project_category: projectState.project_category,
        area_ids: projectState.area_ids,
        gifting: projectState.gifting,
        gifting_notes: projectState.gifting_notes || '',
        project_url: projectState.project_url || '',
        hashtag: projectState?.hashtag.toString().split(','),
        cover_image: projectState?.cover_image,
        attached_files: projectState?.attached_files,
        public: respData.public
      }));
    }
  };

  useEffect(() => {
    // Handle reload, refresh page to reset state
    if (location.state !== null
      && location.pathname.includes(toMemberPage(user?.user_type, ROUTES.MY_PROJECT_EDIT.replace(':id', id)))) {
      navigate(toMemberPage(user?.user_type, ROUTES.MY_PROJECT_EDIT.replace(':id', id)), {
        state: null
      });
    }
    _getProjectDetail();
    initialPage();
  }, []);

  const handleUpdateSuccessClose = () => {
    setShowModalUpdateSuccess(false);
    navigate(toMemberPage(user?.user_type, ROUTES.MY_PROJECT_LIST));
    confirmNavigation();
  };

  const initialPage = async () => {
    try {
      const getAllTypePromise = store.types.length === 0 ? dispatch(getAllType()) : null;
      const getAllCategoryPromise = store.categories.length === 0 ? dispatch(getAllCategory()) : null;
      const getAllAreaPromise = store.areas.length === 0 ? dispatch(getAllArea()) : null;

      await Promise.all([getAllTypePromise, getAllCategoryPromise, getAllAreaPromise]);
      return true;
    } catch (error) {
      return error;
    }
  };

  let submitActionCreateProject = null;
  const savePublish = 0;
  const saveDraft = 1;
  const onFormSubmit = async (form, formikHelpers) => {
    const oldBudget = user.user_type != USER_TYPE_NPO ? parseInt(myProject.budget.toString().replace(/,/g, ''), 10) : 0;
    const budget = user.user_type != USER_TYPE_NPO ? form.budget.toString().replace(/\D/g, '') : 0;
    if (myProject.public === constants.PROJECT_PUBLIC.PUBLIC && budget !== 0 && oldBudget > parseInt(budget, 10)) {
      const errors = {
        budget: [
          `${myProject.budget.replace(/\D/g, '')}pt以上の数字を指定してください。`
        ]
      };
      setFormikErrors(errors, formikHelpers.setFieldError);
      scrollToErrorField();
    } else {
      const requestAllForm = {
        ...form,
        budget,
        reward_condition: form.reward_condition ? form.reward_condition.toString().replace(/,/g, '') : 0,
        cover_image: imageFile.cover_image,
        attached_files: imageFile.attached_files || [],
        public: submitActionCreateProject
      };

      if (
        myProject.public === constants.PROJECT_PUBLIC.DRAFT
        && submitActionCreateProject == constants.PROJECT_PUBLIC.PUBLIC
      ) {
        const storeFormData = {
          typeFormValue: findArray(requestAllForm.type, store.types),
          categoryForValue: findArray(requestAllForm.project_category, store.categories),
          areaFormValue: whereInArrayConditions(requestAllForm.area_ids, store.areas)
        };

        const resp = await dispatch(validateEditProject(requestAllForm));
        if (user.user_type == USER_TYPE_NPO) {
          if (resp && resp.status === HTTP_UNPROCESSABLE_ENTITY) {
            setFormikErrors(resp.data.errors, formikHelpers.setFieldError);
            scrollToErrorField();
          } else if (resp.status && resp.status === HTTP_OK) {
            navigate(toMemberPage(user?.user_type, ROUTES.MY_PROJECT_EDIT_CONFIRM.replace(':id', id)), {
              state: {
                ...requestAllForm,
                ...storeFormData
              }
            });
            confirmNavigation();
          }
        } else if (resp && resp.status === HTTP_UNPROCESSABLE_ENTITY) {
          setFormikErrors(resp.data.errors, formikHelpers.setFieldError);
          scrollToErrorField();
        } else if (resp.status && resp.status === HTTP_OK) {
          navigate(toMemberPage(user?.user_type, ROUTES.MY_PROJECT_EDIT_AND_SEARCH_NPO.replace(':id', id)), {
            state: {
              ...requestAllForm,
              ...storeFormData
            }
          });
          confirmNavigation();
        }
      } else {
        const resp = await dispatch(updateProject(requestAllForm));
        if (resp && resp.status === HTTP_UNPROCESSABLE_ENTITY) {
          setFormikErrors(resp.data.errors, formikHelpers.setFieldError);
          scrollToErrorField();
        } else if (resp.status && resp.status === HTTP_OK) {
          setShowModalUpdateSuccess(true);
        }
      }
    }
  };

  return (
    <div className="client-cpanel-container">
      <DialogBox
        showDialog={showPrompt}
        confirmNavigation={confirmNavigation}
        cancelNavigation={cancelNavigation}
      />

      <Modal className="modal-amazing" show={showModalUpdateSuccess} size="lg" centered>
        <Modal.Header>
          <Modal.Title className="text-center">編集が完了しました</Modal.Title>
        </Modal.Header>
        <Modal.Footer className="justify-content-center border-top-0">
          <Button variant="modal-redirect" className="common-btn" onClick={handleUpdateSuccessClose}>
            プロジェクト一覧へ
          </Button>
        </Modal.Footer>
      </Modal>
      {isLoading && <LoadingOverlay />}
      {!isLoading && (
      <div className="box-style-1__border project-create-page form-style-1">
        <Formik
          initialValues={initFormikValues}
          enableReinitialize
          validationSchema={() => EditProjectValidation(user, submitActionCreateProject)}
          onSubmit={onFormSubmit}
        >
          {({ handleSubmit, isSubmitting }) => {
            return (
              <Form onChange={() => setShowDialog(true)}>
                <div className="row title-wrap form-group-item">
                  <div className="col-12 col-lg-6">
                    <h3 className="fw-bold">プロジェクトの編集</h3>
                  </div>
                </div>
                <FileHandlerContext.Provider value={fileHandler}>
                  <EditProjectForm
                    typeData={store.types}
                    categoryData={store.categories}
                    areaData={store.areas}
                  />
                </FileHandlerContext.Provider>

                <div className="row justify-content-center submit-btn-list">
                  {initFormikValues.public == saveDraft && (
                    <div className="col-md-6 outline-theme-block">
                      <Button
                        className="common-outline-btn"
                        variant="redirect"
                        disabled={isSubmitting}
                        onClick={() => {
                          submitActionCreateProject = saveDraft;
                          handleSubmit();
                          scrollToErrorField();
                        }}
                      >
                        {isSubmitting && submitActionCreateProject == saveDraft && (
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            className="me-2"
                          />
                        )}
                        下書き保存
                      </Button>
                    </div>
                  )}
                  <div className="col-md-6">
                    <Button
                      variant="submit-project"
                      className="project-create__action common-btn"
                      disabled={isSubmitting}
                      onClick={() => {
                        submitActionCreateProject = savePublish;
                        handleSubmit();
                        scrollToErrorField();
                      }}
                    >
                      {isSubmitting && submitActionCreateProject == savePublish && (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                          className="me-2"
                        />
                      )}
                      編集する
                    </Button>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
      )}
    </div>
  );
}

export default Index;
