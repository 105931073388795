/* eslint-disable no-unused-expressions */
/* eslint-disable max-len */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { ErrorMessage, Field, isNaN, useFormikContext } from 'formik';
import { addDays } from 'date-fns';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { QuestionCircleFill } from 'react-bootstrap-icons';

import useSession from 'hooks/useSession';
import {
  CheckboxGroup,
  CustomDatePickerStartSunday,
  CustomInput,
  CustomSelect,
  CustomTextArea,
  RadioGroup
} from 'components/formik';
import constants from 'constants/constants';
import { USER_TYPE_NPO } from 'redux/client/auth';
import { projectTypeData } from 'data/projectTypeData';
import { giftingData } from 'data/giftingData';
import FileHandlerContext from 'hooks/FileHandlerContext';
import CustomTextAreaGiftingNotes from './CustomTextAreaGiftingNotes';
import CustomInputHashtag from './CustomInputHashtag';
import UploadCoverImage from './UploadCoverImage';
import UploadAttachedFile from './UploadAttachedFile';

function CreateProjectForm(props) {
  const fileHandler = useContext(FileHandlerContext);
  const { typeData, categoryData, areaData } = props;
  const { user } = useSession();
  const formikContent = useFormikContext();

  const { imageFile, onFileChange, removeImage } = fileHandler;

  const handleChangeNumber = (formik, e) => {
    const { value } = e.target;
    const parsedValue = parseInt(value.replace(/\D/g, ''), 10);
    formik.setFieldValue('reward_condition', !isNaN(+parsedValue) ? parsedValue.toLocaleString() : '');
  };
  return (
    <>
      <div className="row form-group-item">
        <div className="col-lg-4 col-12">
          <div className="project_input_react-datepicker">
            <label htmlFor="delivery_at" className="form-label">
              納品期限
              <span className="label-required">必須</span>
              <OverlayTrigger
                key="top"
                placement="top"
                overlay={(
                  <Tooltip id="tooltip-top" className="amazing-tooltip">
                    納品期限日を過ぎることでプロジェクトの募集が締め切られます
                  </Tooltip>
                )}
              >
                <span className="align-middle">
                  <QuestionCircleFill className="question-circle-style label-required mb-1 ms-3" size={25} />
                </span>
              </OverlayTrigger>
            </label>
            <Field
              name="delivery_at"
              // label={['納品期限', '必須']}
              placeholderText="日付を選択してください"
              minDate={addDays(new Date(), 1)}
              dateFormat="yyyy/MM/dd"
              component={CustomDatePickerStartSunday}
            />
          </div>
        </div>
      </div>

      <div
        className={formikContent.values.project_id
          ? 'custom-radio-shape form-group-item disabled' : 'custom-radio-shape form-group-item'}
      >
        {user.user_type != USER_TYPE_NPO && (
          <RadioGroup
            disabled={+!!formikContent.values.project_id}
            name="type"
            label={['プロジェクト形式', '必須']}
            options={typeData}
            optionType="project-type"
          />
        )}
      </div>

      <div className="form-group-item project_title">
        <Field name="project_title" label={['プロジェクトのタイトル', '必須', '※推奨文字数30文字']} placeholder="入力して下さい" component={CustomInput} />
      </div>

      <UploadCoverImage
        imageFile={imageFile}
        onFileChange={onFileChange}
        removeImage={removeImage}
      />

      <div className="form-group-item">
        <Field
          name="project_contents"
          label={['プロジェクト内容', '必須']}
          placeholder={(
            'プロジェクトの特徴やメリット、インフルエンサーにお願いしたいことを具体的に記載してください \n'
            + '※サイト外の連絡手段に誘導する記載はお控えください。'
          )}
          rows="6"
          component={CustomTextArea}
        />
      </div>

      <div className="form-group-item">
        <Field
          name="notes"
          label={['プロジェクト特記事項']}
          placeholder={(
            'インフルエンサーに必ずPRしてほしい情報、プロジェクトへの参加条件、PRする上での注意事項などを記載してください。\n'
            + '※参加条件や注意事項が厳しすぎると応募率が低くなる傾向にあります'
          )}
          rows="6"
          component={CustomTextArea}
        />
        <div className="text-note">
          (その他条件の追加も可能です）<br />
          インフルエンサーへ自身のTikTokアカウントのフォロー <br />
          動画へのアカウントタグ付け
        </div>
      </div>

      {user.user_type != USER_TYPE_NPO && (
        <>
          <div className="form-group-item custom-radio-shape">
            <RadioGroup name="gifting" label={['ギフティング', '必須']} options={giftingData} />
          </div>

          <div className="form-group-item">
            <Field
              name="gifting_notes"
              placeholder="入力して下さい"
              rows="6"
              component={CustomTextAreaGiftingNotes}
            />
          </div>
        </>
      )}

      <div className="form-group-item">
        <div className="row">
          <div className="col-12 col-md-6 col-xxl-4">
            <label htmlFor="recruits_number_label" className="form-label">
              募集予定人数
              <span className="label-required">必須</span>
              <OverlayTrigger
                key="top"
                placement="top"
                overlay={(
                  <Tooltip id="tooltip-top" className="amazing-tooltip">
                    募集予定人数を超える応募があった場合でも、予算が全て消化しない限りインフルエンサーは応募することが出来ます
                  </Tooltip>
                )}
              >
                <span className="align-middle">
                  <QuestionCircleFill className="question-circle-style label-required mb-1 ms-3" size={25} />
                </span>
              </OverlayTrigger>
            </label>
            <div className="input-group">
              <div className="col-5 col-xxl-8 col-md-6">
                <Field
                  name="recruits_number"
                  id="recruits_number"
                  type="number"
                  value={formikContent.values.recruits_number}
                  className={`form-control ${formikContent.touched.recruits_number && formikContent.errors.recruits_number && 'is-invalid'}`}
                  onChange={(e) => { e.target.value < 0 ? formikContent.setFieldValue('recruits_number', 0) : formikContent.setFieldValue('recruits_number', e.target.value.replace(/^0+/, '')); }}
                  onKeyPress={(event) => { (event.charCode < 48 || event.charCode > 57) ? event.preventDefault() : null; }}
                />
              </div>
              <label htmlFor="recruits_number_label" className="col-form-label px-2">
                人
              </label>
            </div>
            <ErrorMessage name="recruits_number" component="div" className="invalid-feedback text-nowrap d-block" />
          </div>
        </div>

      </div>

      {user.user_type != USER_TYPE_NPO && (
        <div className="form-group-item">
          <label htmlFor="budget" className="form-label">
            予算
            <span className="label-required">必須</span>
            <OverlayTrigger
              key="top"
              placement="top"
              overlay={(
                <Tooltip id="tooltip-top" className="amazing-tooltip">
                  <span>
                    設定予算を超える応募（自動採用）に達した場合、ポイントを該当のプロジェクトへ追加することで募集再開（自動採用）が可能となります<br />
                    ※ポイントはプロジェクトの編集画面から追加することが出来ます
                  </span>
                </Tooltip>
              )}
            >
              <span className="align-middle">
                <QuestionCircleFill className="question-circle-style label-required mb-1 ms-3" size={25} />
              </span>
            </OverlayTrigger>
          </label>
          <div className="row">
            <div className="col-6 col-lg-4">
              <div className="input-group">
                <input
                  type="text"
                  name="budget"
                  id="budget"
                  placeholder="最低予算100pt～"
                  value={formikContent.values.budget}
                  className={`form-control rounded-2 ${
                    formikContent.touched.budget && formikContent.errors.budget && 'is-invalid'
                  }`}
                  onChange={(e) => {
                    const currentValue = parseInt(e.target.value.replace(/\D/g, ''), 10);
                    formikContent.setFieldValue('budget', !isNaN(+currentValue) ? currentValue.toLocaleString() : '');
                  }}
                  onBlur={formikContent.handleBlur}
                />
                <label htmlFor="budget_label" className="col-form-label px-2">
                  pt
                </label>
              </div>
            </div>
          </div>
          <ErrorMessage name="budget" component="div" className="invalid-feedback d-block" />
        </div>
      )}

      {user.user_type != USER_TYPE_NPO && (
      <div className="form-group-item">
        <div className="row">
          <div className="col-12 col-md-6 col-xxl-4">
            <label htmlFor="follower_range" className="form-label">
              フォロワー数条件
              <span className="label-required">必須</span>
            </label>
            <div className="input-group">
              <div className="col-5 col-xxl-8 col-md-6">
                <Field
                  name="follower_min"
                  id="follower_min"
                  type="number"
                  value={formikContent.values.follower_min}
                  className={`form-control ${
                    formikContent.touched.follower_min && formikContent.errors.follower_min && 'is-invalid'
                  }`}
                />
              </div>
              <label htmlFor="follower_min" className="col-form-label px-2">
                名以上
              </label>
            </div>
            <ErrorMessage name="follower_min" component="div" className="invalid-feedback text-nowrap d-block" />
          </div>
          {formikContent.values.type == constants.PROJECT_TYPE.CANDIDACY && (
            <div className="col-12 mt-4 mt-xxl-0 col-xxl-8">
              <label htmlFor=" " className="form-label limit-reward">
                インフルエンサー1人当たりの報酬条件（ポイント数）
                <span className="label-required">必須</span>
                <OverlayTrigger
                  key="top"
                  placement="top"
                  overlay={(
                    <Tooltip id="tooltip-top" className="amazing-tooltip">
                      予算に応じて募集するインフルエンサーの報酬額を指定することができます
                    </Tooltip>
                  )}
                >
                  <span className="align-middle">
                    <QuestionCircleFill className="question-circle-style label-required mb-1 ms-3" size={25} />
                  </span>
                </OverlayTrigger>
              </label>
              <div className="row">
                <div className="col-12 col-md-6">
                  <div className="input-group">
                    <Field
                      name="reward_condition"
                      className={`form-control rounded-2 ${
                        formikContent.touched.reward_condition && formikContent.errors.reward_condition
                            && 'is-invalid'
                      }`}
                      onChange={(e) => handleChangeNumber(formikContent, e)}
                      onBlur={formikContent.handleBlur}
                    />
                    <label htmlFor=" " className="col-form-label px-2">
                      pt
                    </label>
                  </div>
                  <ErrorMessage name="reward_condition" component="div" className="invalid-feedback d-block" />
                </div>
                <div className="col-12 col-md-6">
                  <div>
                    <div className="form-check form-check-inline me-4 w-auto">
                      <Field type="radio" name="comparison_type" value="1" className="form-check-input" />
                      <label htmlFor="comparison_type">以上</label>
                    </div>
                    <div className="form-check form-check-inline w-auto me-0">
                      <Field type="radio" name="comparison_type" value="0" className="form-check-input" />
                      <label htmlFor="comparison_type">以下</label>
                    </div>
                    <ErrorMessage name="comparison_type" component="div" className="invalid-feedback d-block" />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      )}

      <div className="row form-group-item">
        <div className="col-md-6 col-12 cls-type mb-md-0 mb-sm-4">
          <Field
            name="project_type"
            label={['タイプ', '必須']}
            default_option="選択して下さい"
            options={projectTypeData}
            component={CustomSelect}
          />
        </div>
        <div className="col-md-6 col-12 cls-category">
          <Field
            name="project_category"
            label={['プロジェクトのジャンル', '必須']}
            default_option="選択して下さい"
            options={categoryData}
            component={CustomSelect}
          />
        </div>
      </div>

      <div className="form-group-item">
        <CheckboxGroup name="area_ids" label={['募集エリア', '必須']} options={areaData} />
      </div>

      <div className="form-group-item">
        <Field name="hashtag" label={['指定ハッシュタグ', '必須']} component={CustomInputHashtag} />
      </div>

      <div className="form-group-item">
        <Field name="project_url" label={['プロジェクトの参考URL']} placeholder="http://example.com" component={CustomInput} />
      </div>

      <UploadAttachedFile
        imageFile={imageFile}
        onFileChange={onFileChange}
        removeImage={removeImage}
      />
    </>
  );
}

CreateProjectForm.propTypes = {
  typeData: PropTypes.array.isRequired,
  categoryData: PropTypes.array.isRequired,
  areaData: PropTypes.array.isRequired
};

export default CreateProjectForm;
