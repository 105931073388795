import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Spinner } from 'react-bootstrap';
import * as Yup from 'yup';
import * as ja from 'helpers/yup-locale-ja';
import Stack from 'react-bootstrap/Stack';
import { Form, Formik, Field } from 'formik';
import { Link, useSearchParams } from 'react-router-dom';

import { CustomInput, CustomInputPassword } from 'components/formik';
import * as ROUTES from 'constants/routes';
import { getUserInfo, signIn, SAVE_ACCESS_TOKEN } from 'redux/client/auth';
import { HTTP_UNAUTHORIZED } from 'constants/ResponseStatus';
import constants from 'constants/constants';

import FacebookIcon from 'assets/svg/facebook-ic.svg';
import GoogleIcon from 'assets/svg/google-ic.svg';
import AmazingAlert from 'components/common/AmazingAlert';

Yup.setLocale(ja.suggestive);
const FormSchema = Yup.object().shape({
  email: Yup.string()
    .required()
    .min(3)
    .max(255)
    .email(),
  password: Yup.string().required().min(3)
});

const APP_API = `${constants.APP_API}`;
function index() {
  const initFormikValues = {
    email: '',
    password: ''
  };

  const [searchParams] = useSearchParams();
  const [isLoginFailed, setIsLoginFailed] = useState(false);
  const [isLoginSocialFailed, setIsLoginSocialFailed] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (searchParams.get('error') == HTTP_UNAUTHORIZED) {
      setIsLoginSocialFailed(true);
    }
    handleGetUserInfo();
  }, []);

  const handleGetUserInfo = async () => {
    const userAccessToken = searchParams.get('access_token');
    const userProvider = searchParams.get('provider');

    if (!userProvider || !userAccessToken) {
      return true;
    }

    try {
      await dispatch({ type: SAVE_ACCESS_TOKEN, payload: userAccessToken });
      await dispatch(getUserInfo());

      return true;
    } catch (error) {
      return error;
    }
  };

  const onSubmitForm = async (form) => {
    setIsLoginFailed(false);
    const resp = await dispatch(signIn(form));
    if (resp && resp.response.status === HTTP_UNAUTHORIZED) {
      await setIsLoginFailed(true);
    }
  };

  return !searchParams.get('access_token') && (
    <div className="client-container initial-auth__page">
      <div className="auth__card box-style-1__border">
        <Stack gap={4} className="mx-auto sns-button-list gap-4-ct">
          {isLoginSocialFailed && (
            <AmazingAlert message="ログインに失敗しました。" onCloseAlert={() => setIsLoginSocialFailed(false)} />
          )}

          {/* <a href={`${APP_API}/login?provider=twitter`} className="continueTwitterBtn">
            <img src={TwitterIcon} alt="twitter" />
            Twitterログイン
          </a> */}
          <a href={`${APP_API}/login?provider=facebook`} className="continueFacebookBtn">
            <img src={FacebookIcon} alt="facebook" />
            Facebookログイン
          </a>
          <a href={`${APP_API}/login?provider=google`} className="continueGoogleBtn">
            <img src={GoogleIcon} alt="google" />
            Googleログイン
          </a>
        </Stack>

        <div className="divider">
          <hr className="divider-line" />
          <div className="trfeet">または</div>
          <hr className="divider-line" />
        </div>

        <Stack gap={2} className="mx-auto">
          <Formik initialValues={initFormikValues} validationSchema={FormSchema} onSubmit={onSubmitForm}>
            {({ isValid, dirty, isSubmitting }) => (
              <Form className="auth-form">
                {isLoginFailed && (
                  <AmazingAlert
                    message="メールアドレスまたはパスワードが間違っています。"
                    onCloseAlert={() => setIsLoginFailed(false)}
                  />
                )}
                <div className="mb-3">
                  <Field
                    name="email"
                    label={['メールアドレス']}
                    placeholder="入力してください"
                    component={CustomInput}
                    autoComplete="email"
                  />
                </div>

                <div className="form-group">
                  <label className="form-label" htmlFor="password">
                    パスワード
                  </label>
                  <Field
                    id="password"
                    name="password"
                    placeholder="入力してください"
                    autoComplete="current-password"
                    component={CustomInputPassword}
                  />
                </div>

                <Button
                  type="submit"
                  variant="auth common-btn d-block mx-auto"
                  disabled={!(isValid && dirty) || isSubmitting}
                >
                  {isSubmitting && (
                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className="me-2" />
                  )}
                  ログイン
                </Button>
                <Link to={ROUTES.FORGOT_PASSWORD} className="forget-password-link">
                  パスワードを忘れた方はこちら
                </Link>
              </Form>
            )}
          </Formik>
        </Stack>
      </div>
    </div>
  );
}

export default index;
