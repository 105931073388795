export const userTypeData = [
  {
    key: 1,
    value: '企業'
  },
  {
    key: 2,
    value: 'テディベアプロジェクト'
  }
];
