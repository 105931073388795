import { useParams, useNavigate, Link } from 'react-router-dom';
import { HTTP_OK } from 'constants/ResponseStatus';
import * as ROUTES from 'constants/routes';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getNewsById } from 'redux/client/news';
import { getFormattedDate } from 'helpers/utils';
import Skeleton from 'react-loading-skeleton';

function index() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  const newsItem = useSelector((state) => state.news.newsDetail);

  useEffect(() => {
    _getNewsById();
  }, []);

  const _getNewsById = async () => {
    setIsLoading(true);
    const resp = await dispatch(getNewsById(id));
    setIsLoading(false);
    if (resp.status != HTTP_OK || (resp.data && resp.data.length === 0)) {
      navigate(ROUTES.ERROR_404_PAGE, {
        replace: true
      });
    }
  };

  return (
    <section className="news page-news-detail margin-content">
      <div className="client-container">
        <div className="row">
          <div className="col-12 col-lg-2">
            <div className="section title">
              <div className="header">
                <h2 className="title-bg">News</h2>
                <h2 className="title">お知らせ</h2>
                <h3 className="position-absolute outline-1">Topics</h3>
                <h3 className="position-absolute outline-2">Topics</h3>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-8 news-content">
            <div className="news-header d-flex flex-column flex-md-row">
              {isLoading ? (
                <Skeleton height={24} containerClassName="w-100" />
              ) : (
                <>
                  <span className="news-date">
                    <span>{getFormattedDate(newsItem.publish_at)}</span>
                  </span>
                  <h3 className="news-title">{newsItem.title}</h3>
                </>
              )}
            </div>
            <div className="news-body">
              {isLoading ? (
                <>
                  <Skeleton style={{ marginBottom: 12 }} count={3} height={24} />
                  <Skeleton height={24} width={220} />
                </>
              ) : (
                newsItem.body
              )}
            </div>
            <div className="d-flex justify-content-center button">
              <Link className="btn btn-redirect common-outline-btn py-3" to={ROUTES.NEWS_LIST}>
                <span>戻る</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default index;
