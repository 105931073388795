import { HOME } from 'constants/routes';
import React from 'react';
import { Link } from 'react-router-dom';
import TeddyBearSVG from 'assets/svg/teddy-bear-ic.svg';

function PageNotFound() {
  return (
    <div className="client-container error-page">
      <img className="error__img" src={TeddyBearSVG} alt="" />
      <h3 className="error__code">404</h3>
      <p className="error__msg">エラーが発生しました</p>
      <Link to={HOME} className="btn common-btn error-btn">
        トップに戻る
      </Link>
    </div>
  );
}

export default PageNotFound;
