import React from 'react';
import PropTypes from 'prop-types';

function ProjectStatistics({ userInfluencer }) {
  return (
    <section className="section-project-statistics py-4">
      <div className="row group-content">
        <div className="col-lg-3 col-md-6 col-sm-6 py-1">
          <div className="d-flex flex-wrap form-control border-0 bg-overlay light-pink p-4">
            <div className="flex-fill">
              採用回数
            </div>
            <div className="flex-fill">
              {userInfluencer?.hiring_number} 回
            </div>
          </div>
        </div>

        <div className="col-lg-3 col-md-6 col-sm-6 py-1">
          <div className="d-flex flex-wrap form-control border-0 bg-overlay light-pink p-4">
            <div className="flex-fill">
              進行中プロジェクト
            </div>
            <div className="flex-fill">
              {userInfluencer?.ongoing_project_number} 回
            </div>
          </div>
        </div>

        <div className="col-lg-3 col-md-6 col-sm-6 py-1">
          <div className="d-flex flex-wrap form-control border-0 bg-overlay light-pink p-4">
            <div className="flex-fill">
              取引キャンセル回数
            </div>
            <div className="flex-fill">
              {userInfluencer?.transaction_cancel_number} 回
            </div>
          </div>
        </div>

        <div className="col-lg-3 col-md-6 col-sm-6 py-1">
          <div className="d-flex flex-wrap form-control border-0 bg-overlay light-pink p-4">
            <div className="flex-fill">
              不採用
            </div>
            <div className="flex-fill">
              {userInfluencer?.rejections_number} 回
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

ProjectStatistics.propTypes = {
  userInfluencer: PropTypes.object
};

ProjectStatistics.defaultProps = {
  userInfluencer: null
};
export default ProjectStatistics;
