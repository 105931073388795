import React from 'react';
import PropTypes from 'prop-types';
import TypeIcon from 'assets/svg/type-ic.svg';
import { Link } from 'react-router-dom';
import Image from 'components/common/Image';
import useSession from 'hooks/useSession';
import { USER_TYPE_NPO } from 'redux/client/auth';
import RankSystem from 'components/common/RankSystem';
import constants from 'constants/constants';

function TopProjectSlideItem({ project }) {
  const { user } = useSession();

  return (
    <div className="slider-item">
      <div className="card position-relative">
        <div className="position-relative overflow-hidden">
          <Link to={`/project/${project.id}`}>
            <div className="slider-project__img-box">
              <Image className="w-100" src={project.link_cover_image} alt={project.project_title} />
            </div>
          </Link>
          <div className="position-absolute card-sub-title">
            <span>{project.category_name}</span>
          </div>
          <div className="position-absolute text-white m-0 card-title">
            <h5>
              <Link to={`/project/${project.id}`}>{project.project_title}</Link>
            </h5>
          </div>
        </div>
        <div className="card-content">
          <h5 className="m-0 d-flex flex-wrap align-items-center">
            <img src={TypeIcon} alt="" />
            <span className="me-2">{project.project_type_text}</span>
            <span className={`type type-${project.type}`}>{project.type_name}</span>
          </h5>
          <div className="rank-and-remain d-flex align-items-center justify-content-between">
            <p className="m-0">{project.npo_or_company_name}&nbsp;
            </p>
            <div className="d-flex align-items-center mobile-slider-rank">
              {user && project.user_type !== USER_TYPE_NPO && (
                <div className="me-md-2">
                  <RankSystem
                    userType={constants.USER_TYPE_BY_USER.KEY.company}
                    rank={project.user_rank?.rank || 0}
                  />
                </div>
              )}
              <div className="remain-day d-inline-flex align-items-baseline">
                <span>残り</span>
                <h3>
                  {project.day_remain} <span>日</span>
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

TopProjectSlideItem.propTypes = {
  project: PropTypes.object.isRequired
};
export default TopProjectSlideItem;
