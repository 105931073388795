import React from 'react';
import { Link, NavLink, useLocation, useParams } from 'react-router-dom';

import constants from 'constants/constants';
import * as ROUTES from 'constants/routes';
import { Navbar } from 'react-bootstrap';

function Sidebar() {
  const location = useLocation();
  const { id } = useParams();

  return (
    <Navbar.Collapse id="responsive-navbar-nav" className="admin-sidebar">
      <ul className="nav sidebar-nav">
        <li className="sidebar-nav__item">
          <Link to={ROUTES.APP_ADMIN} onClick={(e) => e.preventDefault()} className="sidebar-nav__link">
            ユーザー一覧
          </Link>
          <ul className="nav subsidebar-nav">
            <li>
              <NavLink to={ROUTES.USER_INFLUENCER_LIST_ADMIN} className="subsidebar-nav__link">
                インフルエンサー
              </NavLink>
            </li>
            <li>
              <NavLink to={ROUTES.USER_COMPANY_LIST_ADMIN} className="subsidebar-nav__link">
                企業
              </NavLink>
            </li>
            <li>
              <NavLink to={ROUTES.USER_NPO_LIST_ADMIN} className="subsidebar-nav__link">
                OTASUKE
              </NavLink>
            </li>
          </ul>
        </li>
        <li className="sidebar-nav__item">
          <NavLink to={ROUTES.USER_REQUEST_MANAGEMENT} className="sidebar-nav__link">
            申請管理
          </NavLink>
        </li>
        <li className="sidebar-nav__item">
          <NavLink
            to={ROUTES.ADMIN_PROJECT_LIST}
            className={`sidebar-nav__link ${
              location.pathname == `${ROUTES.APP_ADMIN}/${ROUTES.ADMIN_PROJECT_DETAIL.replace(':id', id)}`
                && 'active'
            }`}
          >
            プロジェクト一覧
          </NavLink>
          <ul className="nav subsidebar-nav">
            <li>
              <NavLink to={ROUTES.COMPANY_CANCELLATION_REQUEST} className="subsidebar-nav__link">
                取引キャンセル申請
              </NavLink>
            </li>
          </ul>
        </li>
        <li className="sidebar-nav__item">
          <NavLink to={ROUTES.ADMIN_NEWS_LIST} className="sidebar-nav__link">
            お知らせ配信
          </NavLink>
        </li>
        <li className="sidebar-nav__item">
          <Link to={ROUTES.APP_ADMIN} onClick={(e) => e.preventDefault()} className="sidebar-nav__link">
            振込関連
          </Link>
          <ul className="nav subsidebar-nav">
            <li>
              <Link
                to={`${ROUTES.TRANSFER_HISTORY_LIST_ADMIN}?type=${constants.USER_TYPE_BY_USER.TEXT[0]}`}
                className={`subsidebar-nav__link ${
                  location.pathname == `${ROUTES.APP_ADMIN}/${ROUTES.TRANSFER_HISTORY_LIST_ADMIN}`
                  && location.search == `?type=${constants.USER_TYPE_BY_USER.TEXT[0]}`
                    && 'active'
                }`}
              >
                インフルエンサー
              </Link>
            </li>
            <li>
              <Link
                to={`${ROUTES.TRANSFER_HISTORY_LIST_ADMIN}?type=${constants.USER_TYPE_BY_USER.TEXT[2]}`}
                className={`subsidebar-nav__link ${
                  location.pathname == `${ROUTES.APP_ADMIN}/${ROUTES.TRANSFER_HISTORY_LIST_ADMIN}`
                  && location.search == `?type=${constants.USER_TYPE_BY_USER.TEXT[2]}`
                    && 'active'
                }`}
              >
                OTASUKE
              </Link>
            </li>
          </ul>
        </li>
        <li className="sidebar-nav__item">
          <NavLink to={ROUTES.REWARD_RETE_SETTING} className="sidebar-nav__link">
            報酬倍率変更
          </NavLink>
        </li>
        <li className="sidebar-nav__item">
          <NavLink to={ROUTES.DISTRIBUTION_TO_NPO} className="sidebar-nav__link">
            OTASUKE報酬配分
          </NavLink>
        </li>
        <li className="sidebar-nav__item">
          <NavLink to={ROUTES.TAG} className="sidebar-nav__link">
            アカウント属性管理
          </NavLink>
        </li>
        <li className="sidebar-nav__item">
          <NavLink to={ROUTES.INVITATION} className="sidebar-nav__link">
            招待プログラム設定
          </NavLink>
        </li>
      </ul>
    </Navbar.Collapse>
  );
}

export default Sidebar;
