import React from 'react';
import PropTypes from 'prop-types';

function TagRow({ record, onClickEditRow, onClickDeleteTag }) {
  return (
    <tr>
      <td>{record.id}</td>
      <td>{record.name}</td>
      <td>{record.created_at}</td>
      <td>
        <div className="d-flex align-items-center">
          <div
            role="button"
            className="hyperlink text-decoration-underline me-3"
            tabIndex="0"
            onClick={onClickEditRow}
            onKeyDown={() => {}}
          >
            更新
          </div>
          <div
            role="button"
            tabIndex="0"
            className="hyperlink text-decoration-underline"
            onClick={onClickDeleteTag}
            onKeyDown={() => {}}
          >
            削除
          </div>
        </div>
      </td>
    </tr>
  );
}

TagRow.propTypes = {
  record: PropTypes.object.isRequired,
  onClickEditRow: PropTypes.func.isRequired,
  onClickDeleteTag: PropTypes.func.isRequired
};

export default TagRow;
