import AdminAPI from 'axios/AdminAPI';

export const GET_INVITE_SETTING = 'GET_INVITE_SETTING';
export const CREATE_OR_UPDATE_INVITE_SETTING_SUCCESS = 'CREATE_OR_UPDATE_INVITE_SETTING_SUCCESS';


export const getInviteSetting = () => (dispatch) => {
  return AdminAPI({
    method: 'GET',
    url: 'invitation/get'
  })
    .then((res) => {
      dispatch({
        type: GET_INVITE_SETTING,
        payload: res
      });
      return res;
    })
    .catch((error) => {
      return error;
    });
};

export const createOrUpdateInvitationSetting = (form) => (dispatch) => {
  return AdminAPI({
    method: 'POST',
    url: 'invitation/create-or-update',
    data: form
  })
    .then((res) => {
      dispatch({
        type: CREATE_OR_UPDATE_INVITE_SETTING_SUCCESS,
        payload: res
      });
      return res;
    })
    .catch((error) => {
      return error;
    });
};
