import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import useSession from 'hooks/useSession';
import { getFormattedDate, toMemberPage } from 'helpers/utils';
import * as ROUTES from 'constants/routes';
import { Link } from 'react-router-dom';
import { LoadingContext } from 'hooks/LoadingContext';
import Skeleton from 'react-loading-skeleton';

function ProjectNews({ newsList }) {
  const { user } = useSession();
  const [isLoading] = useContext(LoadingContext);

  return (
    <>
      <div className="position-relative">
        <h2 className="page-title">お知らせ</h2>
        <div className="client-cpanel__page-title-after">
          <span className="shadow-text">News</span>
          <span className="stroke-text">Topics</span>
        </div>
        <Link
          to={toMemberPage(user?.user_type, ROUTES.MY_NEWS)}
          className="nav-link text-decoration-underline hyperlink position-absolute bottom-0 end-0"
        >
          すべて見る &gt;
        </Link>
      </div>

      <ul className="nav flex-column news__list-style mt--20">
        {isLoading && [...Array(3)].map((e, i) => (
          <li className="news__item" key={`skeleton_v${i.toString()}`}>
            <Skeleton height={25} containerClassName="w-100" />
          </li>
        ))}
        {!isLoading && newsList?.map((item) => (
          <li className="news__item" key={item.id}>
            <span className="news__data">{getFormattedDate(item.publish_at)}</span>
            <Link
              to={toMemberPage(user?.user_type, ROUTES.MY_NEWS_DETAIL.replace(':id', item.id))}
              title={item.title}
              className="custom-maxline-1 news__title"
            >
              {item.title}
            </Link>
          </li>
        ))}
      </ul>
    </>
  );
}

ProjectNews.propTypes = {
  newsList: PropTypes.array.isRequired
};
export default ProjectNews;
