import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Field, Form, Formik } from 'formik';
import { Button, Spinner } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';

import { getTags, updateTag } from 'redux/admin/tag';
import { ResponseStatus } from 'constants';
import { setFormikErrors } from 'helpers/utils';
import CustomBottomToRightToast from 'components/admin/common/CustomBottomToRightToast';
import { CustomInput } from 'components/formik';
import { TagFormValidation } from '../TagFormValidation';

function EditTagModal({ tagRow, show, setShow, pageCurrent }) {
  const dispatch = useDispatch();
  const [showToast, setShowToast] = useState(false);

  const handleClose = () => setShow(false);

  const onSubmitForm = async (form, formikHelpers) => {
    const res = await dispatch(updateTag({ ...tagRow, ...form }));
    if (res && res.status == ResponseStatus.HTTP_UNPROCESSABLE_ENTITY) {
      setFormikErrors(res.data.errors, formikHelpers.setFieldError);
      return;
    }

    if (res.status == ResponseStatus.HTTP_OK) {
      await dispatch(getTags(pageCurrent));
    }

    handleClose();
    setShowToast(true);
  };

  return (
    <>
      <Modal show={show} centered size="lg" onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>タグ編集</Modal.Title>
        </Modal.Header>
        <Formik initialValues={{ name: tagRow.name }} validationSchema={TagFormValidation} onSubmit={onSubmitForm}>
          {({ isSubmitting }) => (
            <Form className="form-style-1">
              <Modal.Body>
                <Field name="name" label={['タグ名']} placeholder="入力してください " component={CustomInput} />
              </Modal.Body>
              <Modal.Footer className="border-top-0">
                <Button variant="secondary" onClick={handleClose} className="admin-btn-light">
                  キャンセル
                </Button>
                <Button type="submit" disabled={isSubmitting} variant="success" className="admin-btn-green px-5">
                  {isSubmitting && (
                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className="me-2" />
                  )}
                  更新
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>

      {/* Notification when edit tag success */}
      <CustomBottomToRightToast msg="更新されました" showToast={showToast} setShowToast={setShowToast} />
    </>
  );
}

EditTagModal.propTypes = {
  show: PropTypes.bool.isRequired,
  setShow: PropTypes.func.isRequired,
  tagRow: PropTypes.object.isRequired,
  pageCurrent: PropTypes.number.isRequired
};

export default EditTagModal;
