import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import useSession from 'hooks/useSession';
import * as ROUTES from 'constants/routes';
import { USER_TYPE_NPO } from 'redux/client/auth';
import Sidebar from 'components/common/Sidebar';
import { toMemberPage } from 'helpers/utils';

function ProtectedNPORoute() {
  const { accessToken, user } = useSession();
  const isAuthenticated = accessToken && user?.user_type == USER_TYPE_NPO;

  return isAuthenticated ? (
    <div className="client-cpanel">
      <Sidebar />
      <div className="client-cpanel__content">
        <Outlet />
      </div>
    </div>
  ) : (
    <Navigate to={toMemberPage(user?.user_type, ROUTES.MYPAGE)} />
  );
}

export default ProtectedNPORoute;
