import { useSelector } from 'react-redux';

const useSession = () => {
  return useSelector(({ auth: { user, accessToken, isPurchasedPoints, unreadMessages, myPoints, canLeaveGuild,
    unreadMessagesPublicOffering, unreadMessagesCandidacy, unreadMessagesNominated
  } }) => ({
    user: user || null,
    accessToken,
    isPurchasedPoints,
    unreadMessages,
    unreadMessagesPublicOffering,
    unreadMessagesCandidacy,
    unreadMessagesNominated,
    myPoints,
    canLeaveGuild
  }));
};

export default useSession;
