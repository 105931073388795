import { HTTP_OK } from 'constants/ResponseStatus';
import { ERROR_404_PAGE } from 'constants/routes';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import * as ja from 'helpers/yup-locale-ja';
import { Form, Formik, Field } from 'formik';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { findMyProjectById, reportProjectCsv, saveProjectToDraft, deleteDraftProject } from 'redux/client/project';
import { USER_TYPE_COMPANY, USER_TYPE_INFLUENCER, USER_TYPE_NPO } from 'redux/client/auth';
import TypeIcon from 'assets/svg/type-ic.svg';
import OpenLinkIcon from 'assets/svg/open-link-ic.svg';
import ImageDefaultLg from 'assets/default-image-lg.jpg';
import LoadingOverlay from 'components/loading/LoadingOverlay';
import useSession from 'hooks/useSession';
import { toMemberPage } from 'helpers/utils';
import * as ROUTES from 'constants/routes';
import constants from 'constants/constants';
import Image from 'components/common/Image';
import { Button, Spinner } from 'react-bootstrap';
import CustomProgressBar from 'components/client/CustomProgressBar';
import moment from 'moment';
import { ShieldCheck, FiletypePdf } from 'react-bootstrap-icons';
import RankSystem from 'components/common/RankSystem';
import CalculateRatingStars from 'components/common/CalculateRatingStars';
import AttachImagesModal from '../../projects/detail/AttachImagesModal';

Yup.setLocale(ja.suggestive);
const FormSchema = Yup.object().shape({
  confirmed_leave: Yup.boolean().required()
});

function index() {
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const hidden = searchParams.get('hidden');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSession();
  const userType = user?.user_type;
  const myProject = useSelector((state) => state.project.myProjectDetail);
  let indexImage = -1;
  const attachmentsFile = myProject.attachments_file?.map((file) => {
    if (file?.mime_type !== constants.PDF_FILE_TYPE) {
      indexImage += 1;
      return { ...file, indexImage };
    }
    return file;
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleteDraft, setIsDeleteDraft] = useState(false);
  const [isLoadingExportCSV, setIsLoadingExportCSV] = useState(false);
  const [isLoadingSaveDraft, setIsLoadingSaveDraft] = useState(false);
  const [isShowAttachImagesModal, setIsShowAttachImagesModal] = useState(false);
  const [attachImageSelected, setAttachImageSelected] = useState(0);

  const talkRoomsUrl = toMemberPage(user?.user_type, ROUTES.MY_PROJECT_LIST_TALK_ROOM.replace(':id', myProject.id));

  const messageUrl = toMemberPage(user?.user_type, ROUTES.MESSAGES.replace(':id', myProject.id));

  const initFormikValues = {
    confirmed_leave: false
  };

  useEffect(() => {
    _getProjectDetail();
  }, []);

  const _getProjectDetail = async () => {
    setIsLoading(true);
    const resp = await dispatch(findMyProjectById(id));
    setIsLoading(false);
    if (resp.status != HTTP_OK || (resp.data && resp.data.length === 0)) {
      navigate(ERROR_404_PAGE, {
        replace: true
      });
    }
  };

  const _reportProject = async () => {
    setIsLoadingExportCSV(true);
    await dispatch(reportProjectCsv(id)).then((res) => {
      const bom = new Uint8Array([0xEF, 0xBB, 0xBF]);
      const url = URL.createObjectURL(new Blob([bom, res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `project-report-${moment().format('YYYYMMDDHms')}.csv`);
      document.body.appendChild(link);
      link.click();
    });
    setIsLoadingExportCSV(false);
  };

  const _saveProjectToDraft = async () => {
    setIsLoadingSaveDraft(true);
    const res = await dispatch(saveProjectToDraft(id));
    if (res && res.status != HTTP_OK) {
      navigate(ERROR_404_PAGE, {
        replace: true
      });
    }
    setIsLoadingSaveDraft(false);
  };

  const redirectToEdit = () => {
    navigate(toMemberPage(user?.user_type, ROUTES.MY_PROJECT_EDIT.replace(':id', myProject.id)));
  };

  const deleteDraft = () => {
    setIsDeleteDraft(true);
    dispatch(deleteDraftProject(myProject.id)).then((res) => {
      if (res.data) {
        navigate(toMemberPage(user?.user_type, ROUTES.MY_PROJECT_LIST));
      }
    });
  };

  useEffect(() => {
    setTimeout(() => {
      if (myProject?.attachments_file?.length > 0) {
        const imageNameMaxHeight = 21;
        const listEl = document.querySelectorAll('.project__attachment-name');
        for (let i = 0; i < listEl.length; i += 1) {
          if (listEl[i].offsetHeight > imageNameMaxHeight) {
            const imageName = listEl[i].textContent;
            let maxLength = 20;
            while (listEl[i].offsetHeight > imageNameMaxHeight) {
              listEl[i].innerHTML = `${imageName.substring(0, maxLength)}...${imageName.slice(-7)}`;
              maxLength -= 1;
            }
          }
        }
      }
    }, 1000);
  }, [myProject]);

  const handleAttachImagesModal = (indexAttachImage) => {
    setAttachImageSelected(indexAttachImage);
    setIsShowAttachImagesModal(true);
  };

  return (
    <div className="client-cpanel-container">
      <AttachImagesModal
        attachImageSelected={attachImageSelected}
        isShowAttachImagesModal={isShowAttachImagesModal}
        setIsShowAttachImagesModal={setIsShowAttachImagesModal}
        attachments={myProject.attachments_file}
      />
      {isLoading && <div className="text-color-green"><LoadingOverlay /></div>}
      {!isLoading && (
        <div className="page-project-detail pb-5">
          <div className="row">
            <div className="navigate-wrapper d-flex justify-content-between align-items-center flex-wrap">
              <Button variant="link" className="nav-menu-link" onClick={() => navigate(-1)}>
                <span className="sr-only text-nowrap">戻る</span>
              </Button>
              <div>
                {myProject?.is_allow_project_edit === true && (
                  <Button
                    variant="link"
                    className="edit-project-link"
                    onClick={redirectToEdit}
                  >
                    <span className="sr-only text-nowrap">編集する</span>
                  </Button>
                )}
              </div>

            </div>
            <div className="col-12 row-header">
              <div className="title">
                <h2 className="text-truncate">{myProject.project_title}</h2>
              </div>
              <div className="d-sm-flex justify-content-between align-items-center sub-title">
                <p className="m-0 d-flex align-items-center mt-3 mt-md-0">
                  <span className="category">{myProject.category_name}</span>
                  <span className="project-type d-inline-flex align-items-center">
                    <img src={TypeIcon} alt="" />
                    {myProject.project_type_text}
                  </span>
                </p>
                {((userType === USER_TYPE_COMPANY || userType === USER_TYPE_NPO)
                && myProject.public == constants.PROJECT_PUBLIC.PUBLIC) && (
                  <div className="talk-room-actions">
                    {myProject?.can_save_draft && (
                      <div
                        role="button"
                        tabIndex="0"
                        className="btn common-btn btn-save-draft me-3 my-2"
                        onClick={_saveProjectToDraft}
                        onKeyDown={_saveProjectToDraft}
                      >
                        {isLoadingSaveDraft && (
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            className="me-2"
                          />
                        )}
                        非公開にする
                      </div>
                    )}
                    <div
                      role="button"
                      tabIndex="0"
                      className="btn common-btn btn-export-project me-3 my-2"
                      onClick={_reportProject}
                      onKeyDown={_reportProject}
                    >
                      {isLoadingExportCSV && (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                          className="me-2"
                        />
                      )}
                      レポート
                    </div>
                    <a className="btn common-btn btn-talk-room me-3 my-2" href={talkRoomsUrl}>
                      プロジェクトトークルーム
                      {myProject?.unread_messages_count > 0 && (
                        <span>{myProject.unread_messages_count}</span>
                      )}
                    </a>
                  </div>
                )}
                {/* TO DO: link talk room https://nat-doj.backlog.com/view/AMAZING-86 */}
                {userType == USER_TYPE_INFLUENCER && hidden != 1
                  && myProject.public == constants.PROJECT_PUBLIC.PUBLIC && (
                  <a className="btn common-btn btn-talk-room me-3 my-2" href={messageUrl}>
                    プロジェクトトークルーム
                    {myProject?.unread_messages_count > 0 && (
                      <span>{myProject.unread_messages_count}</span>
                    )}
                  </a>
                )}
              </div>
            </div>
          </div>
          <div className="row project-item">
            <div className="col-12">
              <div className="card">
                <div className="card-inner">
                  <div className="cover-image">
                    <Image
                      className="img-fluid"
                      src={myProject.link_cover_image || ImageDefaultLg}
                      alt={myProject.project_title}
                    />
                  </div>
                  <div className="card-body">
                    {myProject.user_type_logged == USER_TYPE_INFLUENCER && (
                      <div className="row mb-3">
                        <div className="col-md-6 col-sm-6">
                          <div className="fw-bold custom-maxline-2">{myProject.npo_or_company_name}</div>
                          {myProject?.user_type !== USER_TYPE_NPO && (
                            <div className="user-verify-wrap d-flex my-2">
                              <div className="user-verify d-flex align-items-center">
                                <div className={`d-inline-flex align-items-center user-info__certificate
                                  ${myProject?.is_identity_verify ? 'success' : ''}`}
                                >
                                  <ShieldCheck />
                                  <span className="ms-1">{myProject?.is_identity_verify ? '本人確認済' : '本人確認前'}</span>
                                </div>
                              </div>
                              <div className="user-verify d-flex align-items-center ms-4">
                                <div className={`d-inline-flex align-items-center user-info__certificate
                                  ${myProject?.is_nda ? 'success' : ''}`}
                                >
                                  <ShieldCheck />
                                  <span className="ms-1">{myProject?.is_nda ? 'NDA締結済' : 'NDA未締結'}</span>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                        <div className="col-md-6 col-sm-6">
                          <div
                            className={`d-flex align-items-center flex-wrap ${
                              myProject?.user_type !== USER_TYPE_NPO || myProject?.project_review?.reviewer_total > 0
                                ? 'mb-2'
                                : ''
                            }`}
                          >
                            {myProject?.user_type !== USER_TYPE_NPO && (
                              <>
                                <div className="user-rank-area-new company me-2">
                                  <RankSystem
                                    userType={constants.USER_TYPE_BY_USER.KEY.company}
                                    rank={myProject.user_rank?.rank || 0}
                                  />
                                </div>

                                {myProject?.project_review && (
                                  <div className="d-flex my-1">
                                    <CalculateRatingStars rating={myProject.project_review.rank} />
                                    <span>({myProject.project_review.reviewer_total})</span>
                                  </div>
                                )}
                              </>
                            )}
                          </div>

                          <p className="text-decoration-underline mb-2">
                            {myProject.npo_or_company_address}
                          </p>
                          {myProject.npo_or_company_url && (
                            <a
                              href={myProject.npo_or_company_url}
                              target="_blank"
                              rel="noreferrer"
                              className="link-blank"
                            >
                              {myProject.npo_or_company_url}
                            </a>
                          )}
                        </div>
                      </div>
                    )}

                    {myProject?.user_type !== USER_TYPE_NPO && (
                      <span className="col-lg-12 col-12 px-0 label-rate">予算消化率</span>
                    )}
                    <div className="row align-items-center">
                      {myProject?.user_type !== USER_TYPE_NPO && (
                        <div className="col-xl-3 col-lg-4 col-md-4 col-sm-7 my-2 col-12 content">
                          <CustomProgressBar now={myProject.budget_utilization_rate} />
                        </div>
                      )}
                      <div className="col-lg-3 col-md-3 col-sm-5 my-2 label-project-type text-md-center">
                        {myProject.type_of_project?.name}
                      </div>
                      <div className="col-xl-6 col-lg-5 col-md-5 my-2 d-flex flex-md-column flex-xxl-row
                            align-items-center justify-content-between current-recruit"
                      >
                        <div className="d-flex align-items-center">
                          <p className="px-0 my-0 label">現在の応募人数</p>
                          <p className="content my-0 ms-4">
                            {myProject.current_recruits_number}
                            <span>人</span>
                          </p>
                        </div>
                        <div className="d-flex align-items-center">
                          <p className="px-0 my-0 label">現在の採用人数</p>
                          <p className="content my-0 ms-4">
                            {myProject.current_applicant_number}
                            <span>人</span>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="row info">
                      <p className="col-md-3 col-12 px-0 label">納品期限</p>
                      <p className="col-md-9 col-12 content">{myProject.delivery_at_format}</p>
                    </div>
                    <div className="row info">
                      <p className="col-md-3 col-12 px-0 label">プロジェクト形式</p>
                      <p className="col-md-9 col-12 content">{myProject.type_name}</p>
                    </div>
                    <div className="row info">
                      <p className="col-md-3 col-12 px-0 label">プロジェクト内容</p>
                      <p className="col-md-9 col-12 content text-pre-line">{myProject.project_contents}</p>
                    </div>
                    <div className="row info">
                      <p className="col-md-3 col-12 px-0 label">プロジェクト特記事項</p>
                      <p className="col-md-9 col-12 content text-pre-line">{myProject.notes}</p>
                    </div>
                    <div className="row info">
                      <p className="col-md-3 col-12 px-0 label">ギフティング</p>
                      <p className="col-md-9 col-12 content">{myProject.gifting_label}</p>
                    </div>
                    <div className="row info">
                      <p className="col-md-3 col-12 px-0 label">
                        ギフティングに <br />
                        関する特記事項
                      </p>
                      <p className="col-md-9 col-12 content text-pre-line">{myProject.gifting_notes}</p>
                    </div>
                    <div className="row info">
                      <p className="col-md-3 col-12 px-0 label">募集予定人数</p>
                      <p className="col-md-9 col-12 content">{myProject.recruits_number}人</p>
                    </div>
                    {userType == USER_TYPE_COMPANY && (
                    <div className="row info">
                      <p className="col-md-3 col-12 px-0 label">予算</p>
                      <p className="col-md-9 col-12 content">{myProject.budget}pt</p>
                    </div>
                    )}
                    {userType != USER_TYPE_INFLUENCER && (
                    <div className="row info">
                      <p className="col-md-3 col-12 px-0 label">フォロワー数条件</p>
                      <p className="col-md-9 col-12 content">
                        {myProject.follower_min}名以上
                      </p>
                    </div>
                    )}
                    <div className="row info">
                      <p className="col-md-3 col-12 px-0 label">タイプ</p>
                      <p className="col-md-3 col-12 content">{myProject.project_type_text}</p>
                      <p className="col-md-3 col-12 px-0 label">プロジェクトのジャンル</p>
                      <p className="col-md-3 col-12 content">{myProject.category_name}</p>
                    </div>
                    <div className="row info">
                      <p className="col-md-3 col-12 px-0 label">募集エリア</p>
                      <div className="col-md-9 col-12 content">
                        <ul className="list-unstyled">
                          {myProject.area?.map((item) => (
                            <li key={item.id}>{item.name}</li>
                          ))}
                        </ul>
                      </div>
                    </div>
                    <div className="row info align-items-center">
                      <p className="col-md-3 col-12 px-0 label">指定ハッシュタグ</p>
                      <div className="col-md-9 col-12 content">
                        <ul className="list-unstyled">
                          {myProject.hashtag?.map((item) => (
                            <li className="hashtag" key={item}>
                              {item}
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                    <div className="row info">
                      <p className="col-md-3 col-12 px-0 label">プロジェクトの参考URL</p>
                      <p className="col-md-9 col-12 content">
                        {myProject.project_url && (
                          <span className="d-flex align-items-center">
                            {myProject.project_url} &nbsp;
                            <a href={myProject.project_url} target="_blank" rel="noreferrer" className="line-height--0">
                              <img src={OpenLinkIcon} alt={myProject.project_url} />
                            </a>
                          </span>
                        )}
                      </p>
                    </div>
                    <div className="row info">
                      <p className="col-md-3 col-12 px-0 label">プロジェクトの参考素材</p>
                      <div className="col-md-9 col-12 content">
                        <ul className="nav project__attachment-list">
                          {attachmentsFile?.map((item) => (
                            <li key={item.id} className="project__attachment-item">
                              {item.mime_type === constants.PDF_FILE_TYPE ? (
                                <a href={item.url} target="_blank" rel="noreferrer">
                                  <div className="project__attachment-box text-dark">
                                    <FiletypePdf size={150} />
                                  </div>
                                  <span className="project__attachment-name">{item.file.name}</span>
                                </a>
                              ) : (
                                <Button
                                  variant="link"
                                  className="text-decoration-none w-100 p-0"
                                  onClick={() => { handleAttachImagesModal(item.indexImage); }}
                                  title={item.file.name}
                                >
                                  <div className="project__attachment-box">
                                    <Image src={item.url} alt={item.file.name} />
                                  </div>
                                  <span className="project__attachment-name">{item.file.name}</span>
                                </Button>
                              )}
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {myProject.public == constants.PROJECT_PUBLIC.DRAFT && (
                <Formik initialValues={initFormikValues} validationSchema={FormSchema} onSubmit={deleteDraft}>
                  {({ handleSubmit, handleChange, values }) => (
                    <Form className="transfer-modal form-style-1">
                      <div className="text-center mt-5">
                        <div className="form-check form-check-inline m-0">
                          <Field
                            id="confirm-leave"
                            type="checkbox"
                            name="confirmed_leave"
                            className="form-check-input"
                            onChange={(e) => {
                              handleChange(e);
                            }}
                          />
                          <label className="form-check-label" htmlFor="confirm-leave">
                            プロジェクトの削除は取り消しができません。削除しますか？
                          </label>
                        </div>
                      </div>

                      <div className="text-center mt-3">
                        <Button
                          className="common-btn btn-delete-draft"
                          onClick={() => handleSubmit()}
                          disabled={isDeleteDraft || !values.confirmed_leave}
                        >
                          {isDeleteDraft && (
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                              className="me-2"
                            />
                          )}
                          <span className="sr-only">削除する</span>
                        </Button>
                      </div>
                    </Form>
                  )}
                </Formik>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default index;
