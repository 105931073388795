import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { Spinner } from 'react-bootstrap';
import Toast from 'react-bootstrap/Toast';
import * as Yup from 'yup';
import { HTTP_UNPROCESSABLE_ENTITY } from 'constants/ResponseStatus';
import { updateOrCreateRewardRateSetting } from 'redux/admin/reward_rate_setting';
import { setFormikErrors } from 'helpers/utils';

function FormRewardRateSetting({ initialValuesForm }) {
  const dispatch = useDispatch();
  const RewardRateValidation = Yup.object().shape({
    comment_count: Yup.number()
      .required()
      .min(0)
      .max(9999999)
      .test(
        'maxDigitsAfterDecimal',
        '小数点5桁まで入力してください。',
        (number) => /^\d+(\.\d{1,5})?$/.test(number)
      ),
    like_count: Yup.number()
      .required()
      .min(0)
      .max(9999999)
      .test(
        'maxDigitsAfterDecimal',
        '小数点5桁まで入力してください。',
        (number) => /^\d+(\.\d{1,5})?$/.test(number)
      ),
    share_count: Yup.number()
      .required()
      .min(0)
      .max(9999999)
      .test(
        'maxDigitsAfterDecimal',
        '小数点5桁まで入力してください。',
        (number) => /^\d+(\.\d{1,5})?$/.test(number)
      ),
    view_count: Yup.number()
      .required()
      .min(0)
      .max(9999999)
      .test(
        'maxDigitsAfterDecimal',
        '小数点5桁まで入力してください。',
        (number) => /^\d+(\.\d{1,5})?$/.test(number)
      ),
    engagement: Yup.number()
      .required()
      .min(0)
      .max(9999999)
      .test(
        'maxDigitsAfterDecimal',
        '小数点5桁まで入力してください。',
        (number) => /^\d+(\.\d{1,5})?$/.test(number)
      ),
    average_pr_engagement: Yup.number()
      .required()
      .min(0)
      .max(9999999)
      .test(
        'maxDigitsAfterDecimal',
        '小数点5桁まで入力してください。',
        (number) => /^\d+(\.\d{1,5})?$/.test(number)
      ),
    type: Yup.number.required
  });

  const [isProcess, setIsProcess] = useState(false);

  const handleSubmitRewardRateSetting = async (form, formikHelpers) => {
    setIsProcess(false);
    const resp = await dispatch(updateOrCreateRewardRateSetting(form));
    if (resp && resp.status === HTTP_UNPROCESSABLE_ENTITY) {
      setFormikErrors(resp.data.errors, formikHelpers.setFieldError);
    }

    setIsProcess(true);
  };

  const toggleToast = () => setIsProcess(!isProcess);

  return (
    <div>
      <Formik
        initialValues={initialValuesForm}
        validationSchema={RewardRateValidation}
        enableReinitialize
        onSubmit={handleSubmitRewardRateSetting}
      >
        {({ isSubmitting, isValid, touched, errors }) => (
          <Form className="custom-form__page">
            <Field
              name="type"
              type="hidden"
              className={`form-control ${touched?.type && errors?.type && 'is-invalid'}`}
            />
            <ErrorMessage name="type" component="span" className="invalid-feedback d-inline" />
            <div className="row mb-3 mb-lg-4">
              <div className="col-6">
                <div className="row align-items-center">
                  <label htmlFor="comment_count" className="col-12 col-md-6 col-lg-8 col-form-label">
                    コメント数
                  </label>
                  <div className="col-12 col-md-6 col-lg-4">
                    <Field
                      name="comment_count"
                      type="number"
                      className={`form-control ${touched?.comment_count && errors?.comment_count && 'is-invalid'}`}
                      placeholder="0.000"
                    />
                  </div>
                </div>
                <div className="text-start text-md-end">
                  <ErrorMessage name="comment_count" component="span" className="invalid-feedback d-inline" />
                </div>
              </div>
              <div className="col-6">
                <div className="row align-items-center">
                  <label htmlFor="like_count" className="col-12 col-md-6 col-lg-8 col-form-label">
                    いいね数
                  </label>
                  <div className="col-12 col-md-6 col-lg-4">
                    <Field
                      name="like_count"
                      type="number"
                      className={`form-control ${touched?.like_count && errors?.like_count && 'is-invalid'}`}
                      placeholder="0.000"
                    />
                  </div>
                </div>
                <div className="text-start text-md-end">
                  <ErrorMessage name="like_count" component="span" className="invalid-feedback d-inline" />
                </div>
              </div>
            </div>
            <div className="row mb-3 mb-lg-4">
              <div className="col-6">
                <div className="row align-items-center">
                  <label htmlFor="engagement" className="col-12 col-md-6 col-lg-8 col-form-label">
                    エンゲージメント率
                  </label>
                  <div className="col-12 col-md-6 col-lg-4">
                    <Field
                      name="engagement"
                      type="number"
                      className={`form-control ${touched?.engagement && errors?.engagement && 'is-invalid'}`}
                      placeholder="0.000"
                    />
                  </div>
                </div>
                <div className="text-start text-md-end">
                  <ErrorMessage name="engagement" component="span" className="invalid-feedback d-inline" />
                </div>
              </div>
              <div className="col-6">
                <div className="row align-items-center">
                  <label htmlFor="share_count" className="col-12 col-md-6 col-lg-8 col-form-label">
                    シェア数
                  </label>
                  <div className="col-12 col-md-6 col-lg-4">
                    <Field
                      name="share_count"
                      type="number"
                      className={`form-control ${touched?.share_count && errors?.share_count && 'is-invalid'}`}
                      placeholder="0.000"
                    />
                  </div>
                </div>
                <div className="text-start text-md-end">
                  <ErrorMessage name="share_count" component="span" className="invalid-feedback d-inline" />
                </div>
              </div>
            </div>
            <div className="row mb-4 mb-lg-5">
              <div className="col-6">
                <div className="row align-items-center">
                  <label htmlFor="average_pr_engagement" className="col-12 col-md-6 col-lg-8 col-form-label">
                    実績エンゲージメント数
                  </label>
                  <div className="col-12 col-md-6 col-lg-4">
                    <Field
                      name="average_pr_engagement"
                      type="number"
                      className={`form-control ${
                        touched?.average_pr_engagement && errors?.average_pr_engagement && 'is-invalid'
                      }`}
                      placeholder="0.000"
                    />
                  </div>
                </div>
                <div className="text-start text-md-end">
                  <ErrorMessage name="average_pr_engagement" component="span" className="invalid-feedback d-inline" />
                </div>
              </div>
              <div className="col-6">
                <div className="row align-items-center">
                  <label htmlFor="view_count" className="col-12 col-md-6 col-lg-8 col-form-label">
                    動画再生回数
                  </label>
                  <div className="col-12 col-md-6 col-lg-4">
                    <Field
                      name="view_count"
                      type="number"
                      className={`form-control ${touched?.view_count && errors?.view_count && 'is-invalid'}`}
                      placeholder="0.000"
                    />
                  </div>
                </div>
                <div className="text-start text-md-end">
                  <ErrorMessage name="view_count" component="span" className="invalid-feedback d-inline" />
                </div>
              </div>
            </div>
            <div className="text-center">
              <button type="submit" className="submit btn admin-btn-green px-5" disabled={!isValid || isSubmitting}>
                {isSubmitting && (
                  <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className="me-2" />
                )}
                登録する
              </button>
            </div>
          </Form>
        )}
      </Formik>

      <div aria-live="polite" aria-atomic="true" className="toast-fixed-rn-custom-1">
        <div className="toast-fixed-rn-custom-1">
          <Toast show={isProcess} onClose={toggleToast} delay={4000} autohide className="toast-success">
            <Toast.Body>更新されました</Toast.Body>
          </Toast>
        </div>
      </div>
    </div>
  );
}

const initialValuesFormDefault = {
  type: '',
  comment_count: '',
  like_count: '',
  share_count: '',
  view_count: '',
  engagement: '',
  average_pr_engagement: ''
};

FormRewardRateSetting.defaultProps = {
  initialValuesForm: initialValuesFormDefault
};

FormRewardRateSetting.propTypes = {
  initialValuesForm: PropTypes.object
};

export default FormRewardRateSetting;
