/* eslint-disable max-len */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { Spinner } from 'react-bootstrap';
import Toast from 'react-bootstrap/Toast';
import { createOrUpdateInvitationSetting } from 'redux/admin/invitation_setting';
import { HTTP_UNPROCESSABLE_ENTITY } from 'constants/ResponseStatus';
import { setFormikErrors } from 'helpers/utils';
import { useDispatch } from 'react-redux';
import { USER_TYPE_COMPANY, USER_TYPE_INFLUENCER } from 'redux/client/auth';
import { InvitationFormValidationCompany, InvitationFormValidationInfluencer } from '../InvitationFormValidation';

function FormInvitationSetting({ initialValuesForm, typeSetting, products }) {
  const [isProcess, setIsProcess] = useState(false);
  const dispatch = useDispatch();

  const handleSubmitInvitationSetting = async (form, formikHelpers) => {
    setIsProcess(false);
    const resp = await dispatch(createOrUpdateInvitationSetting(form));
    if (resp && resp.status === HTTP_UNPROCESSABLE_ENTITY) {
      setFormikErrors(resp.data.errors, formikHelpers.setFieldError);
    }

    setIsProcess(true);
  };
  const toggleToast = () => setIsProcess(!isProcess);

  return (
    <div>
      <Formik
        initialValues={initialValuesForm}
        validationSchema={typeSetting === USER_TYPE_COMPANY ? InvitationFormValidationCompany : InvitationFormValidationInfluencer}
        enableReinitialize
        onSubmit={handleSubmitInvitationSetting}
      >
        {({ isSubmitting, isValid, touched, errors }) => (
          <Form className="custom-form__page">
            <div className="row">
              {
                  (initialValuesForm?.invitation_settings.map((item, index) => {
                    const fieldProduct = `invitation_settings.${index}.product_id`;
                    const fieldCompanyPoints = `invitation_settings.${index}.company_points`;
                    const fieldInfluencerPoints = `invitation_settings.${index}.influencer_points`;
                    return (
                      <div className="col-12 col-lg-6 row mb-4 align-items-start" key={item.product_id}>
                        <Field
                          name={fieldProduct}
                          type="hidden"
                          className={`form-control ${touched?.fieldProduct && errors?.fieldProduct && 'is-invalid'}`}
                        />
                        <label htmlFor="number_point" className="col col-form-label">
                          <span>
                            ポイント付与数
                            {(Array.isArray(products) && products.find((product) => product.id === item.product_id) && (
                              `(￥${products.find((product) => product.id === item.product_id)?.price} - ${products.find((product) => product.id === item.product_id)?.point}Pt)`
                            ))}
                          </span>
                        </label>
                        {(typeSetting === USER_TYPE_COMPANY) ? (
                          <div className="col-12 col-md-4">
                            <Field
                              name={fieldCompanyPoints}
                              type="number"
                              className={`form-control ${touched?.fieldCompanyPoints && errors?.fieldCompanyPoints && 'is-invalid'}`}
                              placeholder="入力してください"
                            />
                            <div className="text-start">
                              <ErrorMessage name={fieldCompanyPoints} component="span" className="invalid-feedback d-inline" />
                            </div>
                          </div>
                        ) : (
                          <div className="col-12 col-md-4">
                            <Field
                              name={fieldInfluencerPoints}
                              type="number"
                              className={`form-control ${touched?.fieldInfluencerPoints && errors?.fieldInfluencerPoints && 'is-invalid'}`}
                              placeholder="入力してください"
                            />
                            <div className="text-start">
                              <ErrorMessage name={fieldInfluencerPoints} component="span" className="invalid-feedback d-inline" />
                            </div>
                          </div>
                        )}
                      </div>
                    );
                  }))
                }
              <div className="text-center mt-4">
                <button type="submit" className="submit btn admin-btn-green px-5" disabled={!isValid || isSubmitting}>
                  {isSubmitting && (
                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className="me-2" />
                  )}
                  登録する
                </button>
              </div>

            </div>
          </Form>
        )}
      </Formik>

      <div aria-live="polite" aria-atomic="true" className="toast-fixed-rn-custom-1">
        <div className="toast-fixed-rn-custom-1">
          <Toast show={isProcess} onClose={toggleToast} delay={4000} autohide className="toast-success">
            <Toast.Body>更新されました</Toast.Body>
          </Toast>
        </div>
      </div>
    </div>
  );
}

const initialValuesFormDefault = {
  invitation_settings: [
    {
      product_id: 1,
      company_point: 0,
      influencer_point: 0
    },
    {
      product_id: 2,
      company_point: 0,
      influencer_point: 0
    },
    {
      product_id: 3,
      company_point: 0,
      influencer_point: 0
    },
    {
      product_id: 4,
      company_point: 0,
      influencer_point: 0
    },
    {
      product_id: 5,
      company_point: 0,
      influencer_point: 0
    },
    {
      product_id: 6,
      company_point: 0,
      influencer_point: 0
    }
  ]
};

FormInvitationSetting.defaultProps = {
  initialValuesForm: initialValuesFormDefault,
  typeSetting: USER_TYPE_INFLUENCER,
  products: []
};

FormInvitationSetting.propTypes = {
  initialValuesForm: PropTypes.object,
  typeSetting: PropTypes.number,
  products: PropTypes.array
};

export default FormInvitationSetting;
