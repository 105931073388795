import React, { useEffect, useState } from 'react';
import { Field, Form, Formik } from 'formik';
import moment from 'moment';
import 'moment-timezone';
import { Button, Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import useSession from 'hooks/useSession';
import { findArray, scrollToErrorField, setFormikErrors, toMemberPage, whereInArrayConditions } from 'helpers/utils';
import * as ROUTES from 'constants/routes';
import { HTTP_OK, HTTP_UNPROCESSABLE_ENTITY } from 'constants/ResponseStatus';
import { useCallbackPrompt, useFileHandler } from 'hooks';
import { USER_TYPE_NPO } from 'redux/client/auth';
import constants from 'constants/constants';
import { validateCreateProject, createProject, getAllProjectByUserId } from 'redux/client/project';
import { getAllType } from 'redux/client/type';
import { getAllCategory } from 'redux/client/category_global';
import { getAllArea } from 'redux/client/area';
import FileHandlerContext from 'hooks/FileHandlerContext';
import DialogBox from 'components/common/DialogBox';
import CreateProjectForm from './components/CreateProjectForm';
import { CreateProjectValidation } from './CreateProjectValidation';
import ScrollToFieldError from './components/ScrollToFieldError';

moment.tz.setDefault('Asia/Tokyo');

function MyProjectCreate() {
  const { user } = useSession();
  const location = useLocation();
  const initialValues = location.state || null;

  const [showDialog, setShowDialog] = useState(false);
  const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(showDialog);

  const fileHandler = useFileHandler({
    cover_image: initialValues?.cover_image || {},
    attached_files: initialValues?.attached_files || []
  });

  const { imageFile } = fileHandler;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const store = useSelector((state) => ({
    projectsOfUser: state.project.projectsOfUser,
    types: state.type.types,
    categories: state.categoryGlobal.categories,
    areas: state.area.areas
  }));

  const newProjectForm = {
    project_id: initialValues?.project_id || '',
    delivery_at: initialValues?.delivery_at || moment().add(1, 'day').format('YYYY-MM-DD'),
    type: user.user_type != USER_TYPE_NPO ? initialValues?.type || '0' : '1',
    follower_min: user.user_type != USER_TYPE_NPO ? initialValues?.follower_min || '' : 0,
    recruits_number: initialValues?.recruits_number || '',
    budget: initialValues?.budget || '',
    reward_condition: initialValues?.reward_condition || '',
    comparison_type: initialValues?.comparison_type || '1',
    notes: initialValues?.notes || '',
    project_title: initialValues?.project_title || '',
    project_contents: initialValues?.project_contents || '',
    project_type: initialValues?.project_type.toString() || '',
    project_category: initialValues?.project_category.toString() || '',
    area_ids: initialValues?.area_ids || [],
    gifting: user.user_type != USER_TYPE_NPO ? initialValues?.gifting || 0 : 1,
    gifting_notes: initialValues?.gifting_notes || '',
    project_url: initialValues?.project_url || '',
    hashtag: initialValues?.hashtag || ['#PR', '#ENMAPR'],
    cover_image: initialValues?.cover_image.file || '',
    attached_files: initialValues?.attached_files.map((item) => item.file) || [],
    public: 0
  };
  const [initFormikValues, setInitFormikValues] = useState(newProjectForm);

  useEffect(() => {
    // Handle reload, refresh page to reset state
    if (location.state !== null
      && location.pathname.includes(toMemberPage(user?.user_type, ROUTES.MY_PROJECT_CREATE))) {
      navigate(location?.pathname, {
        state: null
      });
    }

    initialPage();
    scrollToErrorField();
  }, []);

  const initialPage = async () => {
    try {
      const getAllProjectByUserPromise = dispatch(getAllProjectByUserId());
      const getAllTypePromise = store.types.length === 0 ? dispatch(getAllType()) : null;
      const getAllCategoryPromise = store.categories.length === 0 ? dispatch(getAllCategory()) : null;
      const getAllAreaPromise = store.areas.length === 0 ? dispatch(getAllArea()) : null;

      await Promise.all([getAllProjectByUserPromise, getAllTypePromise, getAllCategoryPromise, getAllAreaPromise]);
      return true;
    } catch (error) {
      return error;
    }
  };

  const handleChangeSelectedProject = (e) => {
    const { value } = e.target;
    if (!value || store.projectsOfUser.length === 0) {
      setInitFormikValues(newProjectForm);
      return;
    }

    const selectedProject = store.projectsOfUser.find((element) => {
      return element.id == value;
    });
    if (!selectedProject) {
      setInitFormikValues(newProjectForm);
      return;
    }

    const areaIds = selectedProject.area.map((el) => el.id.toString());
    setInitFormikValues((prevState) => ({
      ...prevState,
      project_id: value,
      type: user.user_type != USER_TYPE_NPO ? selectedProject.type : prevState.type,
      follower_min: user.user_type != USER_TYPE_NPO ? selectedProject.follower_min : prevState.follower_min,
      recruits_number: selectedProject.recruits_number,
      budget: selectedProject.budget,
      notes: selectedProject.notes || '',
      project_title: selectedProject.project_title || '',
      project_contents: selectedProject.project_contents || '',
      project_type: selectedProject.project_type,
      project_category: selectedProject.project_category,
      area_ids: areaIds,
      gifting: user.user_type != USER_TYPE_NPO ? selectedProject.gifting : prevState.gifting,
      gifting_notes: selectedProject.gifting_notes || '',
      project_url: selectedProject.project_url || '',
      hashtag: selectedProject.hashtag ? selectedProject.hashtag.toString().split(',') : newProjectForm.hashtag,
      cover_image: imageFile.cover_image?.file
    }));
  };

  let submitActionCreateProject = null;
  const onFormSubmit = async (form, formikHelpers) => {
    const requestAllForm = {
      ...form,
      budget: user.user_type != USER_TYPE_NPO ? form.budget.toString().replace(/,/g, '') : 0,
      reward_condition: form.reward_condition ? form.reward_condition.toString().replace(/,/g, '') : 0,
      cover_image: imageFile.cover_image,
      attached_files: imageFile.attached_files || [],
      public: submitActionCreateProject
    };

    if (submitActionCreateProject == constants.PROJECT_PUBLIC.DRAFT) {
      const resp = await dispatch(createProject(requestAllForm));
      if (resp && resp.status === HTTP_UNPROCESSABLE_ENTITY) {
        setFormikErrors(resp.data.errors, formikHelpers.setFieldError);
        scrollToErrorField();
      }
      if (resp.status && resp.status === HTTP_OK) {
        navigate(toMemberPage(user?.user_type, ROUTES.MY_PROJECT_LIST));
        confirmNavigation();
      }
    }

    const storeFormData = {
      typeFormValue: findArray(requestAllForm.type, store.types),
      categoryForValue: findArray(requestAllForm.project_category, store.categories),
      areaFormValue: whereInArrayConditions(requestAllForm.area_ids, store.areas)
    };
    if (submitActionCreateProject == constants.PROJECT_PUBLIC.PUBLIC) {
      if (user.user_type == USER_TYPE_NPO) {
        const resp = await dispatch(createProject(requestAllForm));
        if (resp && resp.status === HTTP_UNPROCESSABLE_ENTITY) {
          setFormikErrors(resp.data.errors, formikHelpers.setFieldError);
          scrollToErrorField();
        }
        if (resp.status && resp.status === HTTP_OK) {
          navigate(toMemberPage(user?.user_type, ROUTES.MY_PROJECT_CREATE_CONFIRM), {
            state: {
              ...requestAllForm,
              ...storeFormData,
              createSuccess: true
            }
          });
          confirmNavigation();
        }
      } else {
        const resp = await dispatch(validateCreateProject(requestAllForm));
        if (resp && resp.status === HTTP_UNPROCESSABLE_ENTITY) {
          setFormikErrors(resp.data.errors, formikHelpers.setFieldError);
          scrollToErrorField();
        }

        if (resp.status && resp.status === HTTP_OK) {
          navigate(`${ROUTES.APP_COMPANY}/${ROUTES.MY_PROJECT_CREATE_AND_SEARCH_NPO}`, {
            state: {
              ...requestAllForm,
              ...storeFormData
            }
          });
          confirmNavigation();
        }
      }
    }
  };

  return (
    <div className="client-cpanel-container">
      <DialogBox
        showDialog={showPrompt}
        confirmNavigation={confirmNavigation}
        cancelNavigation={cancelNavigation}
      />
      <div className="box-style-1__border project-create-page form-style-1">
        <Formik
          initialValues={initFormikValues}
          enableReinitialize
          validationSchema={() => CreateProjectValidation(user, submitActionCreateProject)}
          onSubmit={onFormSubmit}
        >
          {({ handleSubmit, isSubmitting, values }) => {
            return (
              <Form onChange={() => setShowDialog(true)}>
                <ScrollToFieldError />
                <div className="row title-wrap form-group-item">
                  <div className="col-12 col-lg-6">
                    <h3 className="fw-bold">プロジェクト新規作成</h3>
                  </div>
                  <div className="col-12 col-lg-6">
                    <label htmlFor="project" className="form-label">
                      過去のプロジェクトを複製する
                    </label>
                    <Field
                      as="select"
                      name="project_id"
                      className="custom-form-select form-select form-control"
                      required
                      onChange={handleChangeSelectedProject}
                    >
                      <option value="">複製するプロジェクトを選択する</option>
                      {store.projectsOfUser.length >= 1
                      && store.projectsOfUser.map((option) => {
                        return (
                          <option key={option.id} value={option.id}>
                            {option.project_title}
                          </option>
                        );
                      })}
                    </Field>
                  </div>
                </div>
                <FileHandlerContext.Provider value={fileHandler}>
                  <CreateProjectForm
                    typeData={store.types}
                    categoryData={store.categories}
                    areaData={store.areas}
                  />
                </FileHandlerContext.Provider>

                <div className="row justify-content-center submit-btn-list">
                  <div className="col-md-6 outline-theme-block">
                    <Button
                      className="common-outline-btn"
                      variant="redirect"
                      disabled={isSubmitting}
                      onClick={() => {
                        submitActionCreateProject = constants.PROJECT_PUBLIC.DRAFT;
                        handleSubmit();
                        scrollToErrorField();
                      }}
                    >
                      {isSubmitting && submitActionCreateProject == constants.PROJECT_PUBLIC.DRAFT && (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                          className="me-2"
                        />
                      )}
                      下書き保存
                    </Button>
                  </div>
                  <div className="col-md-6">
                    <Button
                      variant="submit-project"
                      className="project-create__action common-btn"
                      disabled={isSubmitting}
                      onClick={() => {
                        submitActionCreateProject = constants.PROJECT_PUBLIC.PUBLIC;
                        handleSubmit();
                        scrollToErrorField();
                      }}
                    >
                      {isSubmitting && submitActionCreateProject == constants.PROJECT_PUBLIC.PUBLIC && (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                          className="me-2"
                        />
                      )}
                      {user.user_type == USER_TYPE_NPO && values.type == constants.PROJECT_TYPE.CANDIDACY ? (
                        <span>公開する</span>
                      ) : (
                        <span>支援先の選択</span>
                      )}
                    </Button>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
}

export default MyProjectCreate;
