import React from 'react';
import PropTypes from 'prop-types';

import DefaultAvatarImg from 'assets/image.png';

function TiktokInfo({ userInfluencer }) {
  const onClickOpenTiktok = () => {
    window.location.href = `${userInfluencer?.tiktok_info?.profile_deep_link}`;
  };

  return (
    <section className="section-tiktok-info py-5">
      <div className="row">
        <div className="col-md-7 col-12">
          <div className="row py-2">
            <div className="col-md-6 col-sm-6">
              <div className="label form-control border-0 bg-overlay light-pink px-3">TikTok Open ID</div>
            </div>

            <div className="col-md-6 col-sm-6">
              <div className="info form-control border-0 custom-maxline-1">{userInfluencer?.tiktok_open_id}</div>
            </div>
          </div>

          <div className="row py-2">
            <div className="col-md-6 col-sm-6">
              <div className="label form-control border-0 bg-overlay light-pink px-3">ニックネーム</div>
            </div>

            <div className="col-md-6 col-sm-6">
              <div className="info form-control border-0">{userInfluencer?.tiktok_info?.nickname}</div>
            </div>
          </div>

          <div className="row py-2">
            <div className="col-md-6 col-sm-6">
              <div className="label form-control border-0 bg-overlay light-pink px-3">フォロワー数</div>
            </div>

            <div className="col-md-6 col-sm-6">
              <div className="info form-control border-0">
                {userInfluencer?.tiktok_info?.follower_count}
              </div>
            </div>
          </div>

          <div className="row py-2">
            <div className="col-md-6 col-sm-6">
              <div className="label form-control border-0 bg-overlay light-pink px-3">直近7日間に投稿された動画のいいね数</div>
            </div>

            <div className="col-md-6 col-sm-6">
              <div className="info form-control border-0">
                {userInfluencer?.tiktok_info?.like_count}
              </div>
            </div>
          </div>

          <div className="row py-2">
            <div className="col-md-6 col-sm-6">
              <div className="label form-control border-0 bg-overlay light-pink px-3">直近7日間に投稿された動画のコメント数</div>
            </div>

            <div className="col-md-6 col-sm-6">
              <div className="info form-control border-0">
                {userInfluencer?.tiktok_info?.comment_count}
              </div>
            </div>
          </div>

          <div className="row py-2">
            <div className="col-md-6 col-sm-6">
              <div className="label form-control border-0 bg-overlay light-pink px-3">直近7日間に投稿された動画のシェア数</div>
            </div>

            <div className="col-md-6 col-sm-6">
              <div className="info form-control border-0">
                {userInfluencer?.tiktok_info?.share_count}
              </div>
            </div>
          </div>

          <div className="row py-2">
            <div className="col-md-6 col-sm-6">
              <div className="label form-control border-0 bg-overlay light-pink px-3">直近7日間に投稿された動画の再生回数</div>
            </div>

            <div className="col-md-6 col-sm-6">
              <div className="info form-control border-0">
                {userInfluencer?.tiktok_info?.view_count}
              </div>
            </div>
          </div>

          <div className="row py-2">
            <div className="col-md-6 col-sm-6">
              <div className="label form-control border-0 bg-overlay light-pink px-3">連携情報 最終更新日</div>
            </div>

            <div className="col-md-6 col-sm-6">
              <div className="info form-control border-0">
                {userInfluencer?.tiktok_info?.updated_at}
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-5 col-12 text-center">
          <div className="tiktok-avatar mx-auto">
            <img
              className="rounded-circle"
              width={150}
              height={150}
              src={userInfluencer?.tiktok_info?.link_avatar_image || DefaultAvatarImg}
              alt=""
            />
          </div>

          <div className="py-3">
            {
              userInfluencer?.tiktok_info?.profile_deep_link ? (
                <button
                  type="button"
                  className="btn rounded-pill admin-btn-green"
                  onClick={onClickOpenTiktok}
                >
                  <strong>TikTokページを見る</strong>
                </button>
              ) : ''
            }
          </div>

          <div className="current-salary">
            <div className="group-content mb-0">
              <div className="bg-overlay light-pink text-center p-sm-4 p-3">
                <h3 className="title-sub mb-0">このインフルエンサーの報酬額</h3>
                <div className="d-sm-flex justify-content-sm-start align-items-sm-center">
                  <span className="min-w-60">立候補:</span>
                  <p className="point-number mx-auto">{userInfluencer?.public_reward}<span> pt</span></p>
                </div>
                <div className="d-sm-flex justify-content-sm-start align-items-sm-center">
                  <span className="min-w-60">先着:</span>
                  <p className="point-number mx-auto">{userInfluencer?.order_reward}<span> pt</span></p>
                </div>
                <div className="d-sm-flex justify-content-sm-start align-items-sm-center">
                  <span className="min-w-60">指名:</span>
                  <p className="point-number mx-auto">{userInfluencer?.certain_reward}<span> pt</span></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

TiktokInfo.propTypes = {
  userInfluencer: PropTypes.object
};

TiktokInfo.defaultProps = {
  userInfluencer: null
};
export default TiktokInfo;
