export const bankAccountTypeData = [
  {
    key: 1,
    value: '普通'
  },
  {
    key: 2,
    value: '当座'
  },
  {
    key: 3,
    value: '貯蓄'
  }
];
