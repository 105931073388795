import API from 'axios/API';

export const GET_POINTS_BALANCE = 'GET_POINTS_BALANCE';
export const GET_POINTS_TRANSFER_HISTORY = 'GET_POINTS_TRANSFER_HISTORY';
export const MINIMUM_TRANSFERABLE_POINTS = 566;

export const getPointsBalance = () => (dispatch) => {
  return API({
    method: 'GET',
    url: 'transfer-history/available-reward'
  }).then((res) => {
    dispatch({
      type: GET_POINTS_BALANCE,
      payload: res
    });
  });
};

export const getPointsTransferHistory = (currentPage = 1) => (dispatch) => {
  return API({
    method: 'GET',
    url: `transfer-history/point-earned?page=${currentPage}`
  }).then((res) => {
    dispatch({
      type: GET_POINTS_TRANSFER_HISTORY,
      payload: res
    });
  });
};

export const addToTransferHistory = (transferAmount) => () => {
  return API({
    method: 'POST',
    url: 'transfer-history/create',
    data: {
      transfer_amount: transferAmount
    }
  })
    .then((res) => {
      return res;
    });
};

export const checkIsPurchasedPoints = () => () => {
  return API({
    method: 'GET',
    url: 'transfer-history/is-purchased'
  })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error;
    });
};

