import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Skeleton from 'react-loading-skeleton';
import { getTags } from 'redux/admin/tag';
import Pagination from 'components/admin/Pagination/Pagination';
import TagRow from './components/TagRow';
import TagCreate from './components/TagCreate';
import EditTagModal from './components/EditTagModal';
import DeleteTagModal from './components/DeleteTagModal';

function index() {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [tagId, setTagId] = useState(0);
  const [pageCurrent, setPageCurrent] = useState(1);

  const [showEditModal, setShowEditModal] = useState(false);
  const [editTagRow, setEditTagRow] = useState({});

  const [remountComponent, setRemountComponent] = useState(0);

  const store = useSelector((state) => ({
    tagList: state.tagAdmin.tags
  }));

  useEffect(() => {
    _getTags();
  }, []);

  const _getTags = async (page = 1) => {
    setIsLoading(true);
    try {
      await dispatch(getTags(page));
      setIsLoading(false);
      return true;
    } catch (error) {
      setIsLoading(false);
      return error;
    }
  };

  const _handlePageClick = (event) => {
    _getTags(event.selected + 1);
    setPageCurrent(event.selected + 1);
  };

  const editRow = (tag) => {
    setShowEditModal(true);
    setEditTagRow(tag);
  };

  const deleteTag = (id) => {
    setShowDeleteModal(true);
    setTagId(id);
  };

  return (
    <>
      <TagCreate setRemountComponent={setRemountComponent} setPageCurrent={setPageCurrent} />
      <EditTagModal tagRow={editTagRow} show={showEditModal} setShow={setShowEditModal} pageCurrent={pageCurrent} />
      <DeleteTagModal
        show={showDeleteModal}
        setShow={setShowDeleteModal}
        tagId={tagId}
        setRemountComponent={setRemountComponent}
      />

      <div className="table-reward table-responsive">
        <table className="table table-lg table-hover admin-table-custom-1">
          {!isLoading && store.tagList?.data.length <= 0 && (
            <caption className="text-center py-5 h5">見つかりません。</caption>
          )}
          <thead>
            <tr>
              <th>ID</th>
              <th>タグ名</th>
              <th>登録日</th>
              <th width="10%">&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            {isLoading
              && [...Array(30)].map((e, i) => (
                <tr key={`${i.toString()}`}>
                  <td>
                    <Skeleton height={17} />
                  </td>
                  <td>
                    <Skeleton height={17} />
                  </td>
                  <td>
                    <Skeleton height={17} />
                  </td>
                  <td>
                    <Skeleton height={17} />
                  </td>
                </tr>
              ))}

            {(!isLoading && store.tagList?.data)
              && store.tagList.data.map((item) => (
                <TagRow
                  key={item.id}
                  record={item}
                  onClickEditRow={() => editRow(item)}
                  onClickDeleteTag={() => deleteTag(item.id)}
                />
              ))}
          </tbody>
        </table>
      </div>
      {store.tagList?.last_page > 1 && (
        <div className="d-flex justify-content-center my-5">
          <Pagination
            className="pagination admin-pagination-custom-1"
            key={remountComponent}
            pageCount={store.tagList?.last_page}
            onPageChange={_handlePageClick}
          />
        </div>
      )}
    </>
  );
}
export default index;
