import * as Yup from 'yup';
import * as ja from 'helpers/yup-locale-ja';
import moment from 'moment/moment';
import 'moment-timezone';

moment.tz.setDefault('Asia/Tokyo');

Yup.setLocale(ja.suggestive);
export const UpdateInfluencerValidation = Yup.object().shape({
  email: Yup.string()
    .required()
    .min(3)
    .max(255)
    .email(),
  first_name: Yup.string()
    .required()
    .min(1)
    .max(128)
    .matches(/^([一-龠 ]|[ぁ-ゔ ]|[ァ-ヴー ]|[a-zA-Z ]|[ａ-ｚＡ-Ｚ ]|[々〆〤ヶ ])+$/)
    .matches(/\S/), // Names cannot contain all spaces
  family_name: Yup.string()
    .required()
    .min(1)
    .max(128)
    .matches(/^([一-龠 ]|[ぁ-ゔ ]|[ァ-ヴー ]|[a-zA-Z ]|[ａ-ｚＡ-Ｚ ]|[々〆〤ヶ ])+$/)
    .matches(/\S/), // Names cannot contain all spaces
  first_name_kana: Yup.string()
    .required()
    .min(1)
    .max(128)
    .matches(/^([ァ-ン]|ー)+$/),
  family_name_kana: Yup.string()
    .required()
    .min(1)
    .max(128)
    .matches(/^([ァ-ン]|ー)+$/),
  phone: Yup.string()
    .required()
    .matches(/^[0-9]{10,11}$/),
  birthday: Yup.date()
    .required('今日以前の日付を指定してください。')
    .max(moment().subtract(1, 'day'), '今日以前の日付を指定してください。')
    .min(moment('1920-01-01').format('YYYY-MM-DD')),
  gender: Yup.string().required(),
  tag_ids: Yup.array().of(Yup.string()).min(1, '1つ以上選択してください'),
  prefecture_ids: Yup.array().required('選択してください').max(255)
});
