import React from 'react';
import PropTypes from 'prop-types';

function Statistics({ userInfo }) {
  return (
    <section className="section-project-statistics py-4">
      <div className="row group-content">
        <div className="col-md-4 col-sm-6 py-1">
          <div className="d-flex flex-wrap form-control border-0 bg-overlay light-pink p-4">
            <div className="flex-fill">
              公開中のプロジェクト数
            </div>
            <div className="flex-fill fw-bold">
              {userInfo?.public_projects}
            </div>
          </div>
        </div>

        <div className="col-md-4 col-sm-6 py-1">
          <div className="d-flex flex-wrap form-control border-0 bg-overlay light-pink p-4">
            <div className="flex-fill">
              投稿完了数
            </div>
            <div className="flex-fill fw-bold">
              {userInfo?.approved_posts} 回
            </div>
          </div>
        </div>

        <div className="col-md-4 col-sm-6 py-1">
          <div className="d-flex flex-wrap form-control border-0 bg-overlay light-pink p-4">
            <div className="flex-fill">
              取引キャンセル数
            </div>
            <div className="flex-fill fw-bold">
              {userInfo?.canceled_posts} 回
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

Statistics.propTypes = {
  userInfo: PropTypes.object
};

Statistics.defaultProps = {
  userInfo: null
};
export default Statistics;
