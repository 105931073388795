import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import useSession from 'hooks/useSession';
import DefaultAvatarImg from 'assets/image.png';
import { ResponseStatus } from 'constants';
import * as ROUTES from 'constants/routes';
import { ResponseStatusMessage } from 'constants/ResponseStatusMessage';
import { toMemberPage } from 'helpers/utils';
import { USER_TYPE_INFLUENCER } from 'redux/client/auth';

import { Field, Form, Formik } from 'formik';
import { Button, Spinner } from 'react-bootstrap';
import LoadingOverlay from 'components/loading/LoadingOverlay';
import { getUserReviewInfo, updateOrCreateReview } from 'redux/client/project_reviews';
import Rating from './components/Rating';

function index() {
  const param = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useSession();
  const userType = user?.user_type;
  const [isLoading, setIsLoading] = useState(false);
  const [userReview, setUserReview] = useState(false);

  const _goBack = () => {
    navigate(-1);
  };

  const _getUserReviewInfo = async (projectId, talkRoomId) => {
    setIsLoading(true);

    try {
      const res = await dispatch(getUserReviewInfo(projectId, talkRoomId));
      if (res?.status != ResponseStatus.HTTP_OK) {
        navigate(ROUTES.ERROR_PAGE, {
          replace: true,
          state: {
            error_code: res?.status,
            error_msg: ResponseStatusMessage[res?.status]
          }
        });
      }

      if (res.data && res.data.is_project_review) {
        _goBack();
      }

      setUserReview(res.data);
      setIsLoading(false);
      return true;
    } catch (error) {
      navigate(ROUTES.ERROR_PAGE, {
        replace: true,
        state: {
          error_code: error.response?.status,
          error_msg: ResponseStatusMessage[error.response?.status]
        }
      });
      setIsLoading(false);
      return error;
    }
  };

  const initFormikValues = {
    total_point: 0
  };

  const onFormSubmit = async (form) => {
    try {
      const res = await dispatch(updateOrCreateReview(param.id, param.idTalkRoom, form));
      if (res?.status != ResponseStatus.HTTP_OK) {
        navigate(ROUTES.ERROR_PAGE, {
          replace: true,
          state: {
            error_code: res?.status,
            error_msg: ResponseStatusMessage[res?.status]
          }
        });
      }

      let talkRoomsUrl = '';
      if (userType === USER_TYPE_INFLUENCER) {
        talkRoomsUrl = toMemberPage(
          userType,
          ROUTES.MESSAGES.replace(':id', param.id)
        );
      } else {
        talkRoomsUrl = toMemberPage(
          userType,
          ROUTES.MY_PROJECT_LIST_TALK_ROOM.replace(':id', param.id)
        );
      }
      navigate(talkRoomsUrl, { replace: true });

      return true;
    } catch (error) {
      navigate(ROUTES.ERROR_PAGE, {
        replace: true,
        state: {
          error_code: error.response?.status,
          error_msg: ResponseStatusMessage[error.response?.status]
        }
      });
      return error;
    }
  };

  useEffect(() => {
    if (location.state == null || (location.state && !location.state.isProjectReview)) {
      _goBack();
    }
    _getUserReviewInfo(param.id, param.idTalkRoom);
  }, []);

  return (
    <div className="review-index-page">
      <div className="client-cpanel-container">
        <div className="box-style-1__border">
          <div className="justify-content-center">
            <div className="content__wrapper">
              <div className="wrapper__896">
                {isLoading ? (
                  <div className="text-color-green">
                    <LoadingOverlay />
                  </div>
                ) : (
                  <>
                    <div className="position-relative">
                      <h2 className="page-title">評価の登録</h2>
                    </div>

                    <section className="review-user-info">
                      <div className="">
                        <div className="row">
                          <div className="col-12">
                            <h3 className="title-sub mb-3">{userReview?.project_title}</h3>
                          </div>
                        </div>

                        <div className="row mobile-center">
                          <div className="col-lg-12 d-sm-flex">
                            <img
                              className="rounded-circle"
                              width={182}
                              height={182}
                              src={userReview?.user_avatar || DefaultAvatarImg}
                              alt=""
                            />
                            <div className="content-display-right">
                              <div className="item-title" title={userReview?.user_name}>
                                {userReview?.user_name}
                              </div>
                              <div className="item-datatime d-md-flex">
                                <div className="datatime-post-completion">
                                  {
                                    userReview?.create_time && (
                                      <>完了報告日 : {userReview?.create_time}</>
                                    )
                                  }
                                </div>
                                <div className="datatime-completed-approval">
                                  {
                                    userReview?.posted_at && (
                                      <>完了承認日 : {userReview?.posted_at}</>
                                    )
                                  }
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>

                    <div className="divider">
                      <hr className="divider-line my-4" />
                    </div>

                    <section className="review-list">
                      <Formik
                        initialValues={initFormikValues}
                        onSubmit={onFormSubmit}
                      >
                        {({ isSubmitting }) => {
                          return (
                            <Form className="form-style-1">
                              <Field name="total_point">
                                {({ field, form }) => (
                                  <Rating
                                    label=""
                                    field={field}
                                    form={form}
                                    name="total_point"
                                  />
                                )}
                              </Field>

                              <div className="text-center mt-5">
                                <Button
                                  variant="auth common-btn my-2"
                                  type="submit"
                                  disabled={isSubmitting}
                                >
                                  {isSubmitting && (
                                    <Spinner
                                      as="span"
                                      animation="border"
                                      size="sm"
                                      role="status"
                                      aria-hidden="true"
                                      className="me-2"
                                    />
                                  )}
                                  <span className="sr-only">登録</span>
                                </Button>
                              </div>
                            </Form>
                          );
                        }}
                      </Formik>
                    </section>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default index;
