import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Formik } from 'formik';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Button, Spinner } from 'react-bootstrap';

import { checkUserAuthToken, USER_TYPE_NPO, validatorRegistrationUserNPO } from 'redux/client/auth';
import { getAllCategory, getAllNPOCategory, getAllPrefectures } from 'redux/client/category_global';
import { inArrayCategory, isSNSRegistration, setFormikErrors, scrollToErrorField } from 'helpers/utils';
import * as ROUTES from 'constants/routes';
import LoadingOverlay from 'components/loading/LoadingOverlay';
import SignUpUserForm from './components/SignUpUserForm';
import { SignUpNPOValidation } from './SignUpNPOValidation';

function SignUpNPO() {
  const confirmSignUpLocation = useLocation();
  const navigate = useNavigate();
  const { userAuthToken } = useParams();

  const store = useSelector((state) => ({
    userAuth: state.auth.userAuth,
    categories: state.categoryGlobal.categories,
    NPOCategories: state.categoryGlobal.NPOCategories,
    prefectures: state.categoryGlobal.prefectures
  }));
  const dispatch = useDispatch();

  const [initFormikValues, setInitFormikValues] = useState({
    password: '',
    first_name: '',
    family_name: '',
    first_name_kana: '',
    family_name_kana: '',
    phone: '',
    npo_category: '',
    category_ids: [],
    category_text: '',
    npo_name: '',
    npo_name_kana: '',
    url: '',
    delegate_name: '',
    department: '',
    position: '',
    postcode: '',
    prefecture_id: '',
    address: '',
    npo_purpose: '',
    is_sns_registration: false
  });

  useEffect(() => {
    if (confirmSignUpLocation.state !== null) {
      setInitFormikValues(confirmSignUpLocation.state);
    }

    initialPage();
  }, []);

  const initialPage = async () => {
    try {
      const checkUserAuthTokenPromise = dispatch(
        checkUserAuthToken({
          token: userAuthToken,
          user_type: USER_TYPE_NPO
        })
      ).catch(() => {
        navigate(ROUTES.ERROR_404_PAGE);
      });

      const getAllCategoryPromise = store.categories.length === 0 ? dispatch(getAllCategory()) : null;
      const getAllNPOCategoryPromise = store.NPOCategories.length === 0 ? dispatch(getAllNPOCategory()) : null;
      const getAllPrefecturesPromise = store.prefectures.length === 0 ? dispatch(getAllPrefectures()) : null;
      await Promise.all([
        checkUserAuthTokenPromise,
        getAllCategoryPromise,
        getAllNPOCategoryPromise,
        getAllPrefecturesPromise
      ]);

      return true;
    } catch (error) {
      return error;
    }
  };

  const onFormSubmit = async (form, formikHelpers) => {
    const resp = await dispatch(validatorRegistrationUserNPO(form));
    if (resp && resp.status === 422) {
      setFormikErrors(resp.data.errors, formikHelpers.setFieldError);
      scrollToErrorField();
    } else {
      navigate(ROUTES.SIGN_UP_NPO_CONFIRM, {
        state: Object.assign(form, {
          email: store.userAuth.email,
          verifyToken: userAuthToken,
          categoryList: inArrayCategory(store.categories, form.category_ids, form.category_text),
          prefectureData: store.prefectures,
          npoCategories: store.NPOCategories
        })
      });
    }
  };

  return (
    <div className="signup-confirm-page">
      {!store.userAuth ? (
        <LoadingOverlay />
      ) : (
        <div className="container main-container">
          <div className="row justify-content-center">
            <div className="col-lg-10 col-11 form__wrapper">
              <Formik
                initialValues={({ ...initFormikValues, is_sns_registration: isSNSRegistration(store.userAuth) })}
                enableReinitialize
                validationSchema={SignUpNPOValidation}
                onSubmit={onFormSubmit}
              >
                {({ handleSubmit, isSubmitting }) => (
                  <Form className="form-style-1">
                    <h3 className="title-form">本会員登録</h3>
                    <div className="form-group-item row">
                      <div className="col-lg-2 col-md-4 col-sm-4 col-12 mb-mobile-form col-md-ct-4">
                        <span className="form-label">メールアドレス</span>
                      </div>
                      <div className="col-lg-7 col-md-8 col-sm-8 col-12">
                        <span className="text-color-gray-custom">{store.userAuth && store.userAuth.email}</span>
                      </div>
                    </div>

                    <div className="mb-5">
                      <SignUpUserForm
                        categories={store.categories}
                        NPOCategories={store.NPOCategories}
                        prefectureData={store.prefectures}
                        isSNSRegistration={isSNSRegistration(store.userAuth)}
                      />
                    </div>

                    <div className="col-12 text-center mt-btn-form">
                      <Button
                        variant="auth common-btn"
                        onClick={handleSubmit}
                        disabled={isSubmitting}
                      >
                        {isSubmitting && (
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            className="me-2"
                          />
                        )}
                        <span className="sr-only">確認</span>
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default SignUpNPO;
