import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';

import './style/select-picker-custom.scss';
import { ErrorMessage } from 'formik';
import { ChevronDown, X } from 'react-bootstrap-icons';
import { findArray } from 'helpers/utils';
import { Spinner } from 'react-bootstrap';

function CustomSelectMultiplePicker({
  field,
  form: { touched, errors, setFieldValue },
  label,
  selectData,
  onChange,
  mouseLeaveChange,
  isLoadingForm,
  ...props
}) {
  const [showSelectPopper, setShowSelectPopper] = useState(false);

  const [selectPopperStyle, setSelectPopperStyle] = useState({});
  const [clickSelected, setClickSelected] = useState(false);
  const selectInputRef = useRef();
  const selectPopperRef = useRef();

  const onClickSelectInput = async (event) => {
    await setShowSelectPopper((prev) => !prev);
    const clientYScreen = document.documentElement.clientHeight - event.clientY;
    const selectInputHeight = selectInputRef.current.offsetHeight;
    const selectPopperHeight = selectPopperRef.current.offsetHeight;

    if (clientYScreen > selectPopperHeight + selectInputHeight) {
      setSelectPopperStyle({
        inset: '0px 0px auto auto',
        transform: `translate(0px, ${selectInputHeight}px)`
      });
    } else {
      setSelectPopperStyle({
        inset: 'auto 0px 0px auto',
        transform: `translate(0px, -${selectInputHeight}px)`
      });
    }

    return true;
  };

  const onClickSelectPickerOptionPopper = (key = '', selectedItem = false) => {
    if (!key) {
      if (field.value && field.value.length > 0) {
        onClickRemoveAllSelect();
      }
      return;
    }
    if (isLoadingForm && mouseLeaveChange && selectedItem) {
      return;
    }
    let newVal = [];
    const selectedKey = key.toString();
    const isSelected = field.value?.includes(selectedKey);
    if (isSelected) {
      newVal = field.value.filter((item) => item !== selectedKey);
    } else {
      newVal = [...field.value ?? [], selectedKey];
    }
    setFieldValue(field.name, newVal);
    if (!mouseLeaveChange) {
      onChange();
    }

    if (mouseLeaveChange) {
      if (selectedItem) {
        onChange();
      } else {
        setClickSelected(true);
      }
    }
  };

  const handleMouseLeave = () => {
    setShowSelectPopper(false);
    if (mouseLeaveChange && clickSelected) {
      onChange();
      setClickSelected(false);
    }
  };

  const onClickRemoveAllSelect = () => {
    setFieldValue(field.name, []);
    setShowSelectPopper(false);
    onChange();
  };

  return (
    <>
      {label.length > 0 && (
        <label htmlFor={field.name} className="form-label">
          {label[0]}
          {label[1] && <span className="label-required">{label[1]}</span>}
        </label>
      )}

      <div className={`select-picker__container ${touched[field.name] && errors[field.name] && 'is-invalid'}`}>
        <div className="select-picker__input-group">
          <input
            id={field.name}
            ref={selectInputRef}
            type="text"
            className="select-picker__input"
            autoComplete="off"
            onBlur={(e) => {
              setTimeout(() => {
                field.onBlur(e);
              }, 100);
            }}
            name={field.name}
            {...props}
            onClick={onClickSelectInput}
            onKeyPress={(e) => e.preventDefault()}
          />
          <span className="select-picker__input-group-text">
            <ChevronDown size={18} color="#8B8E8D " />
          </span>
        </div>

        <div
          ref={selectPopperRef}
          style={selectPopperStyle}
          className={`select-picker-option__popper ${showSelectPopper ? 'show' : ''}`}
          onMouseLeave={handleMouseLeave}
        >
          {{ ...props }.placeholder && (
            <div
              className="select-picker-option__item placeholder-text"
              onClick={() => onClickSelectPickerOptionPopper()}
              aria-hidden="true"
            >
              {{ ...props }.placeholder}
            </div>
          )}

          {Array.isArray(selectData) && selectData.map((option) => (
            <div
              className={`select-picker-option__item ${field.value?.includes(option.key.toString()) && 'active'}`}
              key={option.key}
              onClick={() => onClickSelectPickerOptionPopper(option.key.toString())}
              aria-hidden="true"
            >
              {option.value}
            </div>
          ))}
        </div>
      </div>
      <ErrorMessage name={field.name} component="div" className="invalid-feedback" />

      <div className="multiple_select__container">
        {
          (field.value && field.value.length > 0 && Array.isArray(selectData)) && (
            isLoadingForm ? (
              <div className="text-center w-100">
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  className="me-2 text-color-green"
                />
              </div>
            ) : (
              <>
                {(field.value && field.value.length > 1) && (
                  <div className="select__input-text d-inline-flex align-items-center btn_remove_all">
                    全て削除する
                    <button
                      type="button"
                      className="btn select__btn-remove"
                      onClick={onClickRemoveAllSelect}
                    >
                      <X />
                    </button>
                  </div>
                )}

                {
                  field.value.map((item) => (
                    <div key={`${item}`} className="select__input-text d-inline-flex align-items-center">
                      {findArray(item, selectData)?.value}
                      <button
                        type="button"
                        className="btn select__btn-remove"
                        onClick={() => onClickSelectPickerOptionPopper(item, true)}
                      >
                        <X />
                      </button>
                    </div>
                  ))
                }
              </>
            )
          )
        }
      </div>
    </>
  );
}

CustomSelectMultiplePicker.defaultProps = {
  label: [],
  onChange: () => {},
  mouseLeaveChange: false,
  isLoadingForm: false
};

CustomSelectMultiplePicker.propTypes = {
  label: PropTypes.array,
  selectData: PropTypes.array.isRequired,
  onChange: PropTypes.func,
  mouseLeaveChange: PropTypes.bool,
  isLoadingForm: PropTypes.bool,
  field: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired
};

export default CustomSelectMultiplePicker;
