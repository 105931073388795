import React from 'react';
import { FastField, Form, FormikProvider, useFormik } from 'formik';
import { setFormikErrors } from 'helpers/utils';
import { useDispatch } from 'react-redux';
import { getUserAdmin, registerUserAdmin } from 'redux/admin/user';
import * as ROUTES from 'constants/routes';
import { ResponseStatus, ResponseStatusMessage } from 'constants';
import { useNavigate } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import { CustomInput, CustomInputPassword } from 'components/formik';
import { UserAdminRegisterValidation } from './UserAdminRegisterValidation';

function UserAdminRegister() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      email: '',
      password: ''
    },
    validationSchema: UserAdminRegisterValidation,
    onSubmit: (form, formikHelpers) => onFormSubmit(form, formikHelpers)
  });

  const onFormSubmit = async (form, formikHelpers) => {
    const resp = await dispatch(registerUserAdmin(form));
    if (resp && resp.status == ResponseStatus.HTTP_UNPROCESSABLE_ENTITY) {
      setFormikErrors(resp.data.errors, formikHelpers.setFieldError);
      return;
    }
    // If check validate not OK
    if (resp.status == ResponseStatus.HTTP_OK) {
      await dispatch(getUserAdmin());
    } else {
      navigate(ROUTES.ERROR_PAGE, {
        replace: true,
        state: {
          error_code: resp.status,
          error_msg: ResponseStatusMessage[resp.status]
        }
      });
    }
    // setIsSuccess(true);
    formik.resetForm();
  };

  return (
    <FormikProvider value={formik}>
      <Form className="form-style-1 admin-form-bg-custom-1 p-4">
        <h2 className="input-group">管理ユーザーの追加</h2>
        <div className="row mb-3">
          <div className="col-lg-5 col-12">
            <FastField
              type="text"
              name="email"
              className=""
              label={['メールアドレス']}
              placeholder="入力してください "
              component={CustomInput}
            />
          </div>
          <div className="col-lg-5 col-12">
            <label className="form-label" htmlFor="password">パスワード</label>
            <FastField
              id="password"
              name="password"
              placeholder="入力してください "
              component={CustomInputPassword}
            />
          </div>
          <div className="col-lg-2 col-12 text-end text-lg-start btn-submit-register">
            <button
              type="button"
              className="btn admin-btn-green rounded"
              onClick={formik.submitForm}
              disabled={!(formik.isValid && formik.dirty) || formik.isSubmitting}
            >
              {formik.isSubmitting && (
                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className="me-2" />
              )}
              <span>登録する</span>
            </button>
          </div>
        </div>
      </Form>
    </FormikProvider>
  );
}

export default UserAdminRegister;
