import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import useSession from 'hooks/useSession';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Button, Spinner } from 'react-bootstrap';
import moment from 'moment';
import 'moment-timezone';
import { createNdaconclusion } from 'redux/client/nda';
import constants from 'constants/constants';
import { HTTP_OK } from 'constants/ResponseStatus';
import { ERROR_PAGE } from 'constants/routes';
import { ResponseStatusMessage } from 'constants/ResponseStatusMessage';
import NdaMessage from './NdaMessage';

moment.tz.setDefault('Asia/Tokyo');
moment.locale('ja');

function NdaInfo({ myInfo }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSession();
  const [isDisableSpinner, setIsDisableSpinner] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [createdDateNda, setCreatedNda] = useState(moment().format('YYYY年MM月DD日'));
  const [isNdaConclusion, setIsNdaConclusion] = useState(false);

  const _handleNdaconclusion = async () => {
    setIsSubmit(true);

    try {
      const res = await dispatch(createNdaconclusion());
      if (res.status === HTTP_OK) {
        const resData = res.data;
        if (resData?.user_id && resData.user_id == user.id) {
          setIsNdaConclusion(true);
        }
      }

      setIsSubmit(false);
      return res;
    } catch (error) {
      navigate(ERROR_PAGE, {
        replace: true,
        state: {
          error_code: error.response?.status,
          error_msg: ResponseStatusMessage[error.response?.status]
        }
      });
      return error;
    }
  };

  useEffect(() => {
    if (myInfo?.identity_verify?.status == constants.IDENTITY_VERIFY_APPROVED) {
      setIsDisableSpinner(true);
    }
    if (myInfo?.nda_conclusion) {
      setCreatedNda(moment(myInfo.nda_conclusion.created_at).format('YYYY年MM月DD日'));
      setIsNdaConclusion(true);
    }
  }, [myInfo]);

  return (
    <>
      {(myInfo.nda_conclusion || isNdaConclusion) && (
      <NdaMessage />
      )}
      <div className="box-style-1__border">
        <div className="justify-content-center">
          <div className="content__wrapper">
            <div className="group-content">
              <h3 className="title-sub">機密保持契約（NDA）とは</h3>
              <p>
                NDA（機密保持契約）とは、お仕事のやり取りによってお互いが知りえた機密情報を他者に漏らさないことを定める契約になります。
                <br />
                すでにENMA利⽤規約で定められていますが、改めてENMAと契約締結することで、ページ内に表⽰されるため信頼感が増し仕事の依頼が届きやすくなります。
              </p>
              <p>
                ※NDAの締結には本⼈確認が必須となります。
                <br />
                ※本⼈確認情報が更新された場合、契約締結済み情報も⾃動的に更新されます。
              </p>
            </div>

            <div className="group-content">
              <h3 className="title-sub">契約違反をした場合</h3>
              <p>
                本契約に違反すると、8条に基づき損害賠償を請求させていただく場合がございますので、契約の遵守をお願いいたします。
              </p>
            </div>

            <div className="group-content">
              <h3 className="title-sub">機密保持契約書</h3>
              <p>
                {myInfo?.full_name}（以下「甲」という）とENMA（以下「⼄」という）とは、両当事者が開⽰する情報の取り扱いについて、下記のとおりに契約を締結します。
              </p>
            </div>

            <div className="group-content">
              <div className="bg-content list-item-custom">
                <p>
                  <span className="fw-bold">第1条（⽬的）</span>
                  <br />
                  本契約は、甲が⼄のサービスを利⽤するにあたり、甲または⼄がそれぞれ保有する情報を、相⼿⽅に提供または開⽰する際の条件を定めることを⽬的とします。
                </p>

                <div>
                  <span className="fw-bold">第2条（機密情報）</span>
                  <ol>
                    <li>
                      <span className="fw-bold">本契約において機密情報には、以下の①ないし③の情報を含む。</span>
                      <ul className="list-unstyled">
                        <li>① ⼄のサービスを利⽤して仕事を依頼するクライアントの情報</li>
                        <li>② ①におけるクライアントが発注する仕事に関する情報</li>
                        <li>
                          ③ ユーザーが、⼄のサービスを利⽤して受注した仕事に関して、クライアントまたは⼄から受領した情報
                        </li>
                      </ul>
                    </li>

                    <li>
                      <span className="fw-bold">
                        前項の規定にかかわらず、次の各号に定める情報は、機密情報から除外するものとする。
                      </span>
                      <ul className="list-unstyled">
                        <li>(1) 開⽰者から開⽰を受ける前に、被開⽰者が正当に保有していたことを証明できる情報</li>
                        <li>(2) 開⽰者から開⽰を受ける前に、公知となっていた情報</li>
                        <li>(3) 開⽰者から開⽰を受けた後に、被開⽰者の責に帰すべからざる事由により公知となった情報</li>
                        <li>(4) 被開⽰者が、正当な権限を有する第三者から機密保持義務を負うことなく正当に⼊⼿した情報</li>
                        <li>(5) 被開⽰者が、開⽰された情報によらず独⾃に開発した情報</li>
                      </ul>
                    </li>
                  </ol>
                </div>

                <div>
                  <span className="fw-bold">第3条（機密保持）</span>
                  <ul>
                    <li>
                      甲は、機密情報を掲載した画⾯を閲覧する際は、第三者に閲覧されないよう注意を払うとともに、閲覧した情報を機密として保持し、第三者への開⽰または漏洩をしてはならないものとします。
                    </li>
                    <li>
                      甲および⼄は、相⼿⽅から開⽰された機密情報を機密として保持し、事前に開⽰者の書⾯による承諾を得ることなく、
                      第三者への開⽰または漏洩、⼄のサービス上で利⽤する以外の⽬的での使⽤をしてはならないものとします。
                    </li>
                    <li>
                      甲および⼄は、相⼿⽅から開⽰された機密情報について、⾃⼰の役員または使⽤⼈のうち、当該機密情報を業務遂⾏上知る必要のある者に限定して開⽰するものとし、
                      それ以外の役員または使⽤⼈に対して開⽰または漏洩してはならないものとします。
                    </li>
                    <li>
                      甲および⼄は、相⼿⽅から開⽰された機密情報について、⼄のサービス上で、業務遂⾏上必要な範囲で複製することができるものとします。
                      ただし、複製した情報も機密情報として取り扱わなければならないものとします。
                    </li>
                  </ul>
                </div>

                <div>
                  <span className="fw-bold">第4条（被開⽰者の責務）</span>
                  <ul>
                    <li>
                      甲および⼄は、相⼿⽅から開⽰された機密情報を知得した⾃⼰の役員または使⽤⼈（機密情報を知得後退職した者も含む）に対し、本契約に定める機密保持契約の順守を徹底させるものとします。
                    </li>
                    <li>
                      甲および⼄は、相⼿⽅から開⽰された機密情報を知得後に退職した⾃⼰の役員または使⽤⼈の本契約条項に違反する⾏為について、相⼿⽅に対して⼀切の責を負うものとします。
                    </li>
                  </ul>
                </div>

                <div>
                  <span className="fw-bold">第5条（第三被開⽰者）</span>
                  <ul>
                    <li>
                      甲及び⼄は、相⼿⽅の事前の承諾に基づき、第三者に相⼿⽅の機密情報を開⽰したときは（以下、当該第三者を「第三被開⽰者」という。）、第三被開⽰者に対し、
                      本契約に基づき⾃⼰が負うのと同⼀の責任ないし義務を課し、遵守させなくてはならないものとします。
                    </li>
                    <li>
                      前項の規定にかかわらず、第三被開⽰者に相⼿⽅の機密情報を開⽰した当事者は、第三被開⽰者の本契約条項に違反する⾏為について、相⼿⽅に対して⼀切の責を負うものとします。
                    </li>
                  </ul>
                </div>

                <p>
                  <span className="fw-bold">第6条（管理責任）</span>
                  <br />
                  甲および⼄は、相⼿⽅から開⽰された機密情報の機密を保持するため、当該機密情報の⼀部または全部を含む資料、記憶媒体およびそれらの複写物等（以下、「機密情報資料」という。）
                  につき、機密が不当に開⽰されまたは漏洩されないよう、他の資料等と明確に区別を⾏い、善良な管理者の注意義務をもって管理しなければならないものとします。
                </p>

                <p>
                  <span className="fw-bold">第7条（返還義務）</span>
                  <br />
                  甲および⼄は、本契約終了後、相⼿⽅から要請があったときは、開⽰された機密情報の⼀部または全部を含む機密情報資料（複写物を含む）を、相⼿⽅の指⽰にしたがい、
                  返還または破棄するものとし、破棄したときはその旨を書⾯にて相⼿⽅に通知するものとします。
                </p>

                <p>
                  <span className="fw-bold">第8条（損害賠償）</span>
                  <br />
                  甲または⼄は、本契約条項に違反したときは、相⼿⽅が被った損害を賠償する責を負うものとします。
                </p>

                <p>
                  <span className="fw-bold">第9条（有効期間）</span>
                  <br />
                  本契約は、本契約締結の⽇から、甲または⼄が、本契約を終了する旨、相⼿⽅に申し⼊れるまで有効に存続するものとします。ただし、本契約の終了後といえども、
                  第3条、第4条、第5条、第7条、第8条、第10条および本条但書の規定については、本契約終了後も3年間存続するものとします。
                </p>

                <p>
                  <span className="fw-bold">第10条（合意管轄）</span>
                  <br />
                  甲および⼄は、本契約に関連して⽣じた紛争については、東京地⽅裁判所を第⼀審の専属的合意管轄裁判所とします。
                </p>

                <p>
                  <span className="fw-bold">第11条（協議）</span>
                  <br />
                  甲および⼄は、本契約に定めのない事項または本契約の条項の解釈に疑義が⽣じたときは、本契約締結の趣旨に則り、甲⼄誠意をもって協議の上解決するものとします。
                </p>
              </div>
            </div>

            <div className="group-content">
              <div className="row">
                <div className="col-lg-7 col-md-7 col-sm-7 col-12" />
                <div className="col-lg-5 col-md-5 col-sm-5 col-12">
                  <div className="pb-4">
                    {createdDateNda} <br />甲 : {myInfo?.full_name}
                  </div>

                  <div>
                    乙：東京都豊島区巣鴨⼆丁⽬15番1-901号 <br />
                    ENMA
                  </div>
                </div>
              </div>
            </div>
            { (!myInfo.nda_conclusion && !isNdaConclusion) && (
            <>
              <div className="group-content text-center">
                <p>契約内容を確認し合意の上で、「機密保持契約（NDA）を締結する」ボタンを押して下さい。</p>
                <p>※ NDAを締結するには本人確認が必要です</p>
              </div>
              <div className="text-center">
                <Button
                  variant="submit-nda common-btn"
                  onClick={_handleNdaconclusion}
                  disabled={isSubmit || !isDisableSpinner}
                >
                  {isSubmit && (
                  <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className="me-2" />
                  )}
                  <span className="sr-only">機密保持契約（NDA）を締結する</span>
                </Button>
              </div>
            </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

NdaInfo.propTypes = {
  myInfo: PropTypes.object.isRequired
};

export default NdaInfo;
