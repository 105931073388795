import React from 'react';
import ReactPaginate from 'react-paginate';
import { ArrowLeftShort, ArrowRightShort } from 'react-bootstrap-icons';

function Pagination(props) {
  return (
    <ReactPaginate
      {...props}
      pageRangeDisplayed={2}
      marginPagesDisplayed={1}
      nextLabel={<ArrowRightShort />}
      previousLabel={<ArrowLeftShort />}
      pageClassName="page-item"
      pageLinkClassName="page-link"
      previousClassName="page-item prev"
      previousLinkClassName="page-link"
      nextClassName="page-item next"
      nextLinkClassName="page-link"
      breakLabel="..."
      breakClassName="page-item"
      breakLinkClassName="page-link"
      containerClassName="pagination"
      activeClassName="active"
      renderOnZeroPageCount={null}
    />
  );
}

export default Pagination;
