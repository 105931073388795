import { GET_LIST_TAG } from './tagAction';

const initialState = {
  tags: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_LIST_TAG: {
      return {
        ...state,
        tags: action.payload.data
      };
    }
    default:
      return state;
  }
};
