import React, { useState } from 'react';
import { Modal, Spinner } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import * as ja from 'helpers/yup-locale-ja';
import { Formik, Form, FastField } from 'formik';
import PropTypes, { array } from 'prop-types';
import { ResponseStatus } from 'constants';
import { createDonationForNPOs } from 'redux/admin/donation';
import { CustomInput } from 'components/formik';
import NPORow from './NPORow';

Yup.setLocale(ja.suggestive);
const FormSchema = Yup.object().shape({
  donation_points: Yup.number()
    .min(1)
    .when('available_points', (availablePoints, schema) => schema.max(availablePoints))
    .required()
});
function DistributionToNPOModal({
  isShowModal,
  setIsShowModal,
  pointsForDonation,
  selectedItems,
  npoCategoryId,
  handleCheckedEvent
}) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const dispatch = useDispatch();

  const initFormikValues = {
    donation_points: '',
    available_points: pointsForDonation?.points ?? '',
    npo_ids: selectedItems.map((item) => item.id).join(','),
    npo_category: npoCategoryId
  };
  const onSubmitForm = async (form) => {
    await setIsSubmitting(true);
    try {
      const res = await dispatch(createDonationForNPOs(form));
      if (res.status === ResponseStatus.HTTP_OK) {
        setIsSubmitting(false);
        setIsShowModal(false);
        window.location.reload();
      } else {
        setIsSubmitting(false);
        setIsShowModal(false);
      }
      return true;
    } catch (error) {
      setIsSubmitting(false);
      return error;
    }
  };

  return (
    <div>
      <Modal
        onHide={() => setIsShowModal(false)}
        show={isShowModal}
        size="lg"
        centered
        className="admin-cancellation-modal"
      >
        <Modal.Header closeButton />
        <Modal.Body>
          <div className="container">
            <Formik initialValues={initFormikValues} validationSchema={FormSchema} onSubmit={onSubmitForm}>
              {({ handleSubmit, isValid, dirty }) => (
                <Form className="row flex-column flex-md-row admin-form-custom-1 form-update">
                  <div className="col-6">
                    <FastField
                      type="number"
                      name="donation_points"
                      label={['OTASUKEに付与するポイント数']}
                      component={CustomInput}
                    />
                  </div>
                  <div className="col-4 offset-1">
                    <button
                      type="button"
                      className="btn btn-green w-100 mt-30px"
                      onClick={() => {
                        handleSubmit();
                      }}
                      disabled={!(isValid && dirty) || isSubmitting}
                    >
                      {isSubmitting && (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                          className="me-2"
                        />
                      )}
                      付与する
                    </button>
                  </div>

                  <div className="col-12 mt-2">
                    <p>現在配分可能なポイント数 <span className="ms-3">{pointsForDonation?.points_format}pt</span></p>
                  </div>
                </Form>

              )}
            </Formik>
            <span>選択されたOTASUKE一覧</span>
            <div className="table-responsive max-height-325">
              <table className="table table-lg table-hover admin-table-custom-1">
                <thead className="border-top border-2">
                  <tr>
                    <th colSpan={2} className="text-center">ID</th>
                    <th>団体名</th>
                    <th>代表者名</th>
                    <th>OTASUKEカテゴリ</th>
                    <th className="text-center">累計付与ポイント</th>
                  </tr>
                </thead>
                <tbody>
                  {selectedItems.length > 0 && selectedItems.map((item) => (
                    <NPORow
                      key={item.user_id}
                      itemRow={item}
                      isShowCheckBox={false}
                      handleCheckedEvent={handleCheckedEvent}
                    />
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

DistributionToNPOModal.defaultProps = {
  pointsForDonation: {},
  selectedItems: array,
  npoCategoryId: null
};
DistributionToNPOModal.propTypes = {
  isShowModal: PropTypes.bool.isRequired,
  setIsShowModal: PropTypes.func.isRequired,
  pointsForDonation: PropTypes.object,
  selectedItems: PropTypes.array,
  npoCategoryId: PropTypes.string,
  handleCheckedEvent: PropTypes.func.isRequired
};

export default DistributionToNPOModal;
