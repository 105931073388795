import { Tab } from 'bootstrap/dist/js/bootstrap.bundle';
import React, { useEffect } from 'react';
import { Tabs } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { getInviteSetting } from 'redux/admin/invitation_setting';
import { USER_TYPE_COMPANY, USER_TYPE_INFLUENCER } from 'redux/client/auth';
import { getAllProduct } from 'redux/client/product';
import FormInvitationSetting from './components/FormInvitationSetting';

function InvitationSetting() {
  const dispatch = useDispatch();
  const invitationSetting = useSelector((state) => state.invitationSetting.data);
  const products = useSelector((state) => state.product.products);

  const initialValues = (userType) => {
    if (invitationSetting && invitationSetting.length > 0) {
      const invitationSettings = invitationSetting.map((item) => {
        if (userType === USER_TYPE_INFLUENCER) {
          return {
            product_id: item.id,
            influencer_points: item.influencer_points
          };
        }
        return {
          product_id: item.id,
          company_points: item.company_points
        };
      });
      return {
        invitation_settings: invitationSettings
      };
    }
    const result = [];
    for (let i = 1; i <= 6; i += 1) {
      if (userType === USER_TYPE_INFLUENCER) {
        result[i - 1] = {
          product_id: i,
          influencer_points: 0
        };
      } else {
        result[i - 1] = {
          product_id: i,
          company_points: 0
        };
      }
    }
    return {
      invitation_settings: result
    };
  };

  const initialPage = async () => {
    try {
      const promiseGetInvite = dispatch(getInviteSetting());
      const promiseGetProducts = dispatch(getAllProduct());
      await Promise.all([promiseGetInvite, promiseGetProducts]);
      return true;
    } catch (error) {
      return error;
    }
  };

  useEffect(() => {
    initialPage();
  }, []);

  return (
    <div id="invitation-setting__page">
      <div className="card rounded-0 border-0">
        <div className="card-header px-0 bg-transparent border-bottom-0 title__page">招待プログラム設定</div>
        <div className="card-body p-0">
          <p>※1pt＝10円になります。例：100ptと入力した場合、1,000円分を付与</p>
          <div className="card-body p-0 az-admin-tabs">
            <Tabs defaultActiveKey="reward-0" id="reward-rate-setting-tab" className="mb-3">
              <Tab key="reward-0" eventKey="reward-0" title="インフルエンサー">
                <div className="row pt-4">
                  <div className="col-12 col-lg-12 col-xl-10 col-xxl-10">
                    <FormInvitationSetting
                      initialValuesForm={initialValues(USER_TYPE_INFLUENCER)}
                      typeSetting={USER_TYPE_INFLUENCER}
                      products={products}
                    />
                  </div>
                </div>
              </Tab>

              <Tab key="reward-1" eventKey="reward-1" title="会社">
                <div className="row pt-4">
                  <div className="col-12 col-lg-12 col-xl-10 col-xxl-10">
                    <FormInvitationSetting
                      initialValuesForm={initialValues(USER_TYPE_COMPANY)}
                      typeSetting={USER_TYPE_COMPANY}
                      products={products}
                    />
                  </div>
                </div>
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
    </div>
  );
}

export default InvitationSetting;
