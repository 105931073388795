import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'react-bootstrap';

function UpdateCompleted({ showModalCompleted, handleCloseModal }) {
  return (
    <Modal className="modal-amazing small-modal" show={showModalCompleted} size="lg" centered>
      <Modal.Body>
        <div className="text-center">
          <div className="text-center modal-title h4">更新しました。</div>
        </div>
      </Modal.Body>
      <Modal.Footer className="justify-content-center border-top-0">
        <Button variant="modal-redirect" className="common-btn btn-small py-2" onClick={handleCloseModal}>
          閉じる
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

UpdateCompleted.propTypes = {
  showModalCompleted: PropTypes.bool.isRequired,
  handleCloseModal: PropTypes.func.isRequired
};

export default UpdateCompleted;
