import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { FastField, Form, FormikProvider, useFormik } from 'formik';
import { useDispatch } from 'react-redux';

import { setFormikErrors } from 'helpers/utils';
import { ResponseStatus, ResponseStatusMessage } from 'constants';
import * as ROUTES from 'constants/routes';
import { getTags, createTag } from 'redux/admin/tag';
import { CustomInput } from 'components/formik';
import CustomBottomToRightToast from 'components/admin/common/CustomBottomToRightToast';
import { TagFormValidation } from '../TagFormValidation';

function TagCreate({ setRemountComponent, setPageCurrent }) {
  const [showToast, setShowToast] = useState(false);
  const page = 1;

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      name: ''
    },
    validationSchema: TagFormValidation,
    onSubmit: (form, formikHelpers) => onFormSubmit(form, formikHelpers)
  });

  const onFormSubmit = async (form, formikHelpers) => {
    const resp = await dispatch(createTag(form));
    if (resp && resp.status == ResponseStatus.HTTP_UNPROCESSABLE_ENTITY) {
      setFormikErrors(resp.data.errors, formikHelpers.setFieldError);
      return;
    }

    if (resp.status == ResponseStatus.HTTP_OK) {
      setRemountComponent(Math.random());
      await dispatch(getTags());
      setPageCurrent(page);
      setShowToast(true);
    } else {
      navigate(ROUTES.ERROR_PAGE, {
        replace: true,
        state: {
          error_code: resp.status,
          error_msg: ResponseStatusMessage[resp.status]
        }
      });
    }
    formik.resetForm();
  };

  return (
    <FormikProvider value={formik}>
      <Form className="form-style-1 admin-form-bg-custom-1 p-4">
        <h2 className="input-group">タグの追加</h2>
        <div className="row mb-3">
          <div className="col-lg-5 col-12">
            <FastField
              type="text"
              name="name"
              className=""
              label={['タグ名']}
              placeholder="入力してください "
              component={CustomInput}
            />
          </div>
          <div className="col-lg-2 col-12 text-end text-lg-start btn-submit-register">
            <button
              type="button"
              className="btn admin-btn-green rounded"
              onClick={formik.submitForm}
              disabled={!(formik.isValid && formik.dirty) || formik.isSubmitting}
            >
              {formik.isSubmitting && (
                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className="me-2" />
              )}
              <span>登録する</span>
            </button>
          </div>
        </div>
      </Form>

      {/* Notification when create tag success */}
      <CustomBottomToRightToast msg="追加されました" showToast={showToast} setShowToast={setShowToast} />
    </FormikProvider>
  );
}
TagCreate.propTypes = {
  setRemountComponent: PropTypes.func.isRequired,
  setPageCurrent: PropTypes.func.isRequired
};
export default TagCreate;
