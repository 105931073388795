export const talkRoomStatusData = [
  {
    key: 0,
    value: '応募',
    style: {
      backgroundColor: '#54DBC2',
      color: '#FFFFFF'
    }
  },
  {
    key: 1,
    value: '不採用',
    style: {
      backgroundColor: '#C8C8C8',
      color: '#919191'
    }
  },
  {
    key: 2,
    value: '採用',
    style: {
      backgroundColor: '#CDF1EA',
      color: '#919191'
    }
  },
  {
    key: 3,
    value: '保留',
    style: {
      backgroundColor: '#C8C8C8',
      color: '#919191'
    }
  },
  {
    key: 4,
    value: '調整中',
    style: {
      backgroundColor: '#54dbc2',
      color: '#FFFFFF'
    }
  },
  {
    key: 5,
    value: '取引キャンセル',
    style: {
      backgroundColor: '#C8C8C8',
      color: '#919191'
    }
  },
  {
    key: 6,
    value: '取引キャンセル承認',
    style: {
      backgroundColor: '#54DBC2',
      color: '#FFFFFF'
    }
  },
  {
    key: 7,
    value: '投稿申請',
    style: {
      backgroundColor: '#F1DEF4',
      color: '#D869DB'
    }
  },
  {
    key: 8,
    value: '投稿承認',
    style: {
      backgroundColor: '#d869db',
      color: '#FFFFFF'
    }
  },
  {
    key: 9,
    value: '指名承認',
    style: {
      backgroundColor: '#f1def4',
      color: '#d869db'
    }
  }
];
