import {
  GET_INVITE_SETTING,
  CREATE_OR_UPDATE_INVITE_SETTING_SUCCESS
} from './invitationSettingAction';

const initialState = {
  data: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_INVITE_SETTING: {
      return {
        ...state,
        data: action.payload.data
      };
    }

    case CREATE_OR_UPDATE_INVITE_SETTING_SUCCESS: {
      return {
        ...state,
        data: action.payload.data
      };
    }

    default:
      return state;
  }
};
