import API from 'axios/API';
import { USER_TYPE_INFLUENCER } from 'redux/client/auth';

export const GET_MY_POINTS_COMPANY_SUCCESS = 'GET_MY_POINTS_COMPANY_SUCCESS';
export const GET_LATEST_REWARD_CHANGE_DATE = 'GET_LATEST_REWARD_CHANGE_DATE';
export const UPDATE_USER_INFO_SUCCESS = 'UPDATE_USER_INFO_SUCCESS';
export const MY_USER_INFO = 'MY_USER_INFO';
export const GET_MY_POINTS_AND_RANK = 'GET_MY_POINTS_AND_RANK';

export const getLatestRewardChangeDate = () => (dispatch) => {
  return API({
    method: 'GET',
    url: 'influencer/my-reward'
  })
    .then((res) => {
      dispatch({
        type: GET_LATEST_REWARD_CHANGE_DATE,
        payload: res
      });
    })
    .catch((error) => {
      return error;
    });
};

export const doChangePassword = (_requestForm) => () => {
  return API({
    method: 'POST',
    url: 'change-password',
    data: _requestForm
  })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error;
    });
};

export const getMyInfo = (userType = USER_TYPE_INFLUENCER) => (dispatch) => {
  const userTypeUrls = {
    0: 'influencer/my-info/user',
    1: 'company/my-info/user',
    2: 'npo/my-info/user'
  };
  const userUrl = userTypeUrls[userType] || userTypeUrls[0];

  return API({
    method: 'GET',
    url: userUrl
  })
    .then((res) => {
      dispatch({
        type: MY_USER_INFO,
        payload: res
      });
      return res;
    })
    .catch((error) => {
      return error;
    });
};

export const validatorUpdateUser = (requestAll = {}, userType = USER_TYPE_INFLUENCER) => () => {
  const userTypeUrls = {
    0: 'influencer/my-info/update/validator',
    1: 'company/my-info/update/validator',
    2: 'npo/my-info/update/validator'
  };
  const userUrl = userTypeUrls[userType] || userTypeUrls[0];
  return API({
    method: 'POST',
    url: userUrl,
    data: requestAll
  }).catch((error) => {
    return error;
  });
};
export const updateUser = (requestAll = {}, userType = USER_TYPE_INFLUENCER) => (dispatch) => {
  const userTypeUrls = {
    0: 'influencer/my-info/update',
    1: 'company/my-info/update',
    2: 'npo/my-info/update'
  };
  const userUrl = userTypeUrls[userType] || userTypeUrls[0];
  return API({
    method: 'POST',
    url: userUrl,
    data: requestAll
  })
    .then((res) => {
      dispatch({
        type: UPDATE_USER_INFO_SUCCESS,
        payload: requestAll
      });
      return res;
    })
    .catch((error) => {
      return error;
    });
};

export const myPointsCompany = () => (dispatch) => {
  return API({
    method: 'GET',
    url: 'company/my-points/user'
  })
    .then((res) => {
      dispatch({
        type: GET_MY_POINTS_COMPANY_SUCCESS,
        payload: res
      });
    });
};

export const getMyPointAndRank = () => (dispatch) => {
  return API({
    method: 'GET',
    url: 'company/points-and-rank'
  })
    .then((res) => {
      dispatch({
        type: GET_MY_POINTS_AND_RANK,
        payload: res
      });
    });
};

export const updateUserStatus = (userId) => () => {
  return API({
    method: 'PUT',
    url: 'user/update-status',
    data: {
      user_id: userId
    }
  })
    .then((res) => {
      return res;
    });
};

export const requestInfluencerLogin = (userIdInfluencer) => () => {
  return API({
    method: 'POST',
    url: 'request-influencer-login',
    data: {
      user_id: userIdInfluencer
    }
  })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error;
    });
};
