import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useSearchParams } from 'react-router-dom';
import { getProjectsForUserInfluencerForMyProject, PROJECT_ALL_TYPE } from 'redux/client/project';
import { LoadingContext } from 'hooks/LoadingContext';
import ProjectInfluencerList from './components/ProjectInfluencerList';
import MyProjectInfluencerFilter from './components/MyProjectInfluencerFilter';
import ProjectInfluencerFavoriteList from './components/ProjectInfluencerFavoriteList';

function MyProjectInfluencer() {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const value = useMemo(() => [isLoading], [isLoading]);
  const store = useSelector((state) => ({ myProjects: state.project.projectsInfluencerMyProject }));
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  let type = `${PROJECT_ALL_TYPE}`; // -1 -> get all
  if (location.pathname.includes('/favorite')) {
    type = 'is_favorited';
  } else if (location.pathname.includes('/nominated')) {
    type = '2';
  } else if (location?.state?.active_tab_favorite) {
    type = 'is_favorited';
  }
  const [paramsUrl, setParamsUrl] = useState({
    type
  });

  const initialPage = async () => {
    _getProjects();
  };

  const _getProjects = async () => {
    await dispatch(getProjectsForUserInfluencerForMyProject(paramsUrl));
    setIsLoading(false);
  };

  useEffect(() => {
    initialPage();
  }, [paramsUrl]);

  const onSubmitMyProjectInfluencerFilter = (form) => {
    setIsLoading(true);
    const tabActive = searchParams.get('tabActive');
    if (tabActive) {
      searchParams.delete('tabActive');
      setSearchParams(searchParams);
    }
    setParamsUrl((preState) => ({
      ...preState,
      ...form
    }));
  };

  return (
    <LoadingContext.Provider value={value}>
      <div className="page-project my-projects">
        <div className="client-container">
          <div className="content_wrapper">
            <div className="section title pt-lg-5">
              <div className="header">
                <h2 className="title-bg">Project List</h2>
                <h2 className="title">プロジェクト一覧</h2>
              </div>
            </div>
            <MyProjectInfluencerFilter initFormikValues={paramsUrl} onSubmit={onSubmitMyProjectInfluencerFilter} />
            {paramsUrl.type !== 'is_favorited' ? (
              <ProjectInfluencerList myProjects={store.myProjects} typeProject={paramsUrl.type} />
            ) : (
              <ProjectInfluencerFavoriteList myProjects={store.myProjects} />
            )}
          </div>
        </div>
      </div>
    </LoadingContext.Provider>
  );
}
export default MyProjectInfluencer;
