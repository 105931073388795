import { GET_ALL_TYPE_SUCCESS } from './typeActions';

const initialState = {
  types: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_TYPE_SUCCESS:
      return {
        ...state,
        types: action.payload.data || []
      };

    default:
      return state;
  }
};
