import React from 'react';
import PropTypes from 'prop-types';
import { Field, Formik } from 'formik';
import moment from 'moment';

function YearFilter({ yearOptions, onSubmitFilterForm }) {
  const initFormikValues = {
    year: moment().local().format('YYYY') ?? ''
  };

  return (
    <Formik initialValues={initFormikValues} onSubmit={onSubmitFilterForm}>
      {({ handleChange, handleSubmit }) => (
        <form>
          <Field
            as="select"
            name="year"
            className="form-select"
            onChange={(e) => { handleChange(e); handleSubmit(); }}
          >
            <option value="">選択してください</option>
            {yearOptions.map((item) => (
              <option value={item} key={`year${item}`}>
                {item}
              </option>
            ))}
          </Field>
        </form>
      )}
    </Formik>
  );
}
YearFilter.propTypes = {
  yearOptions: PropTypes.array.isRequired,
  onSubmitFilterForm: PropTypes.func.isRequired
};
export default YearFilter;
