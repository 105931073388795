import React from 'react';
import PropTypes from 'prop-types';

function UserCompanyInfo({ userCompany }) {
  return (
    <section className="user-info">
      <div className="row py-2">
        <div className="col-md-12 col-sm-12">
          <div className="row">
            <div className="col-md-3 col-sm-3">
              <div className="label form-control border-0 bg-overlay px-3">会社名</div>
            </div>
            <div className="col-md-9 col-sm-9">
              <div className="info form-control border-0">{userCompany?.company_name}</div>
            </div>
          </div>
        </div>
      </div>
      <div className="row py-2">
        <div className="col-md-6 col-sm-6">
          <div className="row">
            <div className="col-md-6 col-sm-6">
              <div className="label form-control border-0 bg-overlay px-3">ID</div>
            </div>

            <div className="col-md-6 col-sm-6">
              <div className="info form-control border-0">{userCompany?.user_id}</div>
            </div>
          </div>
        </div>

        <div className="col-md-6 col-sm-6">
          <div className="row">
            <div className="col-md-6 col-sm-6">
              <div className="label form-control border-0 bg-overlay px-3">メールアドレス</div>
            </div>

            <div className="col-md-6 col-sm-6">
              <div className="info form-control border-0">{userCompany?.user?.email}</div>
            </div>
          </div>
        </div>
      </div>

      <div className="row py-2">
        <div className="col-md-6 col-sm-6">
          <div className="row">
            <div className="col-md-6 col-sm-6">
              <div className="label form-control border-0 bg-overlay px-3">氏名</div>
            </div>

            <div className="col-md-6 col-sm-6">
              <div className="info form-control border-0">{userCompany?.full_name}</div>
            </div>
          </div>
        </div>

        <div className="col-md-6 col-sm-6">
          <div className="row">
            <div className="col-md-6 col-sm-6">
              <div className="label form-control border-0 bg-overlay px-3">氏名（フリガナ）</div>
            </div>

            <div className="col-md-6 col-sm-6">
              <div className="info form-control border-0">{userCompany?.full_name_kana}</div>
            </div>
          </div>
        </div>
      </div>

      <div className="row py-2">
        <div className="col-md-6 col-sm-6">
          <div className="row">
            <div className="col-md-6 col-sm-6">
              <div className="label form-control border-0 bg-overlay px-3">電話番号</div>
            </div>

            <div className="col-md-6 col-sm-6">
              <div className="info form-control border-0">{userCompany?.phone}</div>
            </div>
          </div>
        </div>

        <div className="col-md-6 col-sm-6">
          <div className="row">
            <div className="col-md-6 col-sm-6">
              <div className="label form-control border-0 bg-overlay px-3">URL</div>
            </div>

            <div className="col-md-6 col-sm-6">
              <div className="info form-control border-0">{userCompany?.url}</div>
            </div>
          </div>
        </div>
      </div>

      <div className="row py-2">
        <div className="col-md-6 col-sm-6">
          <div className="row">
            <div className="col-md-6 col-sm-6">
              <div className="label form-control border-0 bg-overlay px-3">部署名</div>
            </div>

            <div className="col-md-6 col-sm-6">
              <div className="info form-control border-0">{userCompany?.department}</div>
            </div>
          </div>
        </div>

        <div className="col-md-6 col-sm-6">
          <div className="row">
            <div className="col-md-6 col-sm-6">
              <div className="label form-control border-0 bg-overlay px-3">役職名</div>
            </div>

            <div className="col-md-6 col-sm-6">
              <div className="info form-control border-0">{userCompany?.position}</div>
            </div>
          </div>
        </div>
      </div>

      <div className="row py-2">
        <div className="col-md-6 col-12">
          <div className="row">
            <div className="col-md-6 col-sm-6">
              <div className="label form-control border-0 bg-overlay px-3">郵便番号</div>
            </div>

            <div className="col-md-6 col-sm-6">
              <div className="info form-control border-0">{userCompany?.postcode}</div>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-12">
          <div className="row">
            <div className="col-md-6 col-sm-6">
              <div className="label form-control border-0 bg-overlay px-3">都道府県</div>
            </div>

            <div className="col-md-6 col-sm-6">
              <div className="info form-control border-0">
                {userCompany?.prefecture?.name}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row py-2">
        <div className="col-md-6 col-sm-6">
          <div className="row">
            <div className="col-md-6 col-sm-6">
              <div className="label form-control border-0 bg-overlay px-3">住所</div>
            </div>

            <div className="col-md-6 col-sm-6">
              <div className="info form-control border-0">{userCompany?.address}</div>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-sm-6">
          <div className="row">
            <div className="col-md-6 col-sm-6">
              <div className="label form-control border-0 bg-overlay px-3">利用動機</div>
            </div>

            <div className="col-md-6 col-sm-6">
              <div className="info form-control border-0">{userCompany?.company_purpose_text}</div>
            </div>
          </div>
        </div>
      </div>

      <div className="row py-2">
        <div className="col-12">
          <div className="row">
            <div className="col-md-3 col-sm-3">
              <div className="label form-control border-0 bg-overlay px-3">ジャンル</div>
            </div>

            <div className="col-md-9 col-sm-9">
              <div className="info form-control border-0">
                <ul className="list-unstyled">
                  {userCompany?.company_categories?.map((item) => (
                    <li key={`category_${item.category_id}`}>{item.category_text}</li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

UserCompanyInfo.propTypes = {
  userCompany: PropTypes.object
};

UserCompanyInfo.defaultProps = {
  userCompany: null
};
export default UserCompanyInfo;
