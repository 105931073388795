import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Formik, FastField } from 'formik';
import { useNavigate } from 'react-router-dom';
import { Button, Spinner } from 'react-bootstrap';
import { CustomInput } from 'components/formik';

import { getAllCategory, getAllPrefectures } from 'redux/client/category_global';
import {
  setFormikErrors,
  isSNSRegistration,
  inArrayCategory,
  toMemberPage,
  scrollToErrorField
} from 'helpers/utils';
import { ResponseStatus } from 'constants';
import * as ROUTES from 'constants/routes';
import LoadingOverlay from 'components/loading/LoadingOverlay';
import { getMyInfo, validatorUpdateUser } from 'redux/client/user';
import { USER_TYPE_COMPANY } from 'redux/client/auth';
import { ResponseStatusMessage } from 'constants/ResponseStatusMessage';

import LeaveGuild from '../components/LeaveGuild';
import UpdateCompanyForm from './components/UpdateCompanyForm';
import { UpdateCompanyValidation } from './UpdateCompanyValidation';

function UpdateCompany() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const store = useSelector((state) => ({
    categories: state.categoryGlobal.categories,
    prefectures: state.categoryGlobal.prefectures,
    myInfo: state.user.myInfo
  }));
  const getFormData = (info = {}) => {
    return {
      id: info.id ?? '',
      first_name: info.first_name ?? '',
      family_name: info.family_name ?? '',
      first_name_kana: info.first_name_kana ?? '',
      family_name_kana: info.family_name_kana ?? '',
      phone: info.phone ?? '',
      prefecture_id: info.prefecture_id ?? '',
      category_ids: info.category_ids ?? [],
      category_text: info.category_text ?? '',
      company_name: info.company_name ?? '',
      company_name_kana: info.company_name_kana ?? '',
      url: info.url ?? '',
      delegate_name: info.delegate_name ?? '',
      department: info.department ?? '',
      position: info.position ?? '',
      postcode: info.postcode ?? '',
      address: info.address ?? '',
      company_purpose: info.company_purpose ?? '',

      email: info.email ?? '',
      is_sns_registration: isSNSRegistration(info) ?? false
    };
  };

  const [isLoading, setIsLoading] = useState(true);
  const [initFormikValues, setInitFormikValues] = useState(getFormData());

  useEffect(() => {
    if (Object.keys(store.myInfo).length > 0) {
      setInitFormikValues(getFormData(store.myInfo));
    }

    initialPage();
  }, []);

  const initialPage = async () => {
    try {
      setIsLoading(true);
      const getMyInfoPromise = dispatch(getMyInfo(USER_TYPE_COMPANY));
      const getAllCategoryPromise = store.categories.length === 0 ? dispatch(getAllCategory()) : null;
      const getAllPrefecturesPromise = store.prefectures.length === 0 ? dispatch(getAllPrefectures()) : null;

      await Promise.all([getMyInfoPromise, getAllCategoryPromise, getAllPrefecturesPromise]).then(([myInfo]) => {
        if (myInfo.status != ResponseStatus.HTTP_OK) {
          navigate(ROUTES.ERROR_PAGE, {
            replace: true,
            state: {
              error_code: myInfo.response?.status,
              error_msg: ResponseStatusMessage[myInfo.response?.status]
            }
          });
        } else {
          setInitFormikValues(getFormData(myInfo.data));
        }
      });
      setIsLoading(false);
      return true;
    } catch (error) {
      setIsLoading(false);
      return error;
    }
  };


  const onFormSubmit = async (form, formikHelpers) => {
    const requestForm = Object.assign(form, {
      categoryList: inArrayCategory(store.categories, form.category_ids, form.category_text),
      prefectureData: store.prefectures
    });
    const resp = await dispatch(validatorUpdateUser(requestForm, USER_TYPE_COMPANY));
    if (resp && resp.status === ResponseStatus.HTTP_UNPROCESSABLE_ENTITY) {
      setFormikErrors(resp.data.errors, formikHelpers.setFieldError);
      scrollToErrorField();
      return;
    }
    // If check validate not OK
    if (resp.status != ResponseStatus.HTTP_OK) {
      navigate(ROUTES.ERROR_PAGE, {
        replace: true,
        state: {
          error_code: resp.response?.status,
          error_msg: ResponseStatusMessage[resp.response?.status]
        }
      });
    } else {
      navigate(toMemberPage(USER_TYPE_COMPANY, ROUTES.MY_PROFILE_UPDATE_CONFIRM), {
        state: requestForm
      });
    }
  };

  return (
    <div className="auth__wrapper">
      {isLoading ? (
        <LoadingOverlay />
      ) : (
        <>
          <div className="container main-container mb-5">
            <div className="row justify-content-center">
              <div className="col-lg-10 col-11 form__wrapper">
                <Formik
                  initialValues={initFormikValues}
                  enableReinitialize
                  validationSchema={UpdateCompanyValidation}
                  onSubmit={onFormSubmit}
                >
                  {({ isSubmitting }) => (
                    <Form className="form-style-1">
                      <h3 className="title-form">会員情報</h3>
                      {isSNSRegistration(store.myInfo) ? (
                        <div className="form-group-item row">
                          <div className="col-lg-2 col-md-4 col-sm-4 col-12 mb-mobile-form col-md-ct-4">
                            <span className="form-label">メールアドレス</span>
                          </div>
                          <div className="col-lg-7 col-md-8 col-sm-8 col-12">
                            <span className="text-color-gray-custom">{store.myInfo.email}</span>
                          </div>
                        </div>
                      ) : (
                        <div className="col-lg-6 col-md-6 col-sm-6 col-12 mb-mobile-form mb-sm-4">
                          <FastField
                            name="email"
                            label={['メールアドレス', '必須']}
                            placeholder="入力してください"
                            component={CustomInput}
                          />
                        </div>
                      )}

                      <UpdateCompanyForm
                        categoryData={store.categories}
                        prefectureData={store.prefectures}
                      />

                      <div className="col-12 text-center mt-btn-form">
                        <Button
                          variant="auth common-btn"
                          type="submit"
                          disabled={isSubmitting}
                        >
                          {isSubmitting && (
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                              className="me-2"
                            />
                          )}
                          <span className="sr-only">確認</span>
                        </Button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
          <LeaveGuild />
        </>
      )}
    </div>
  );
}

export default UpdateCompany;
