import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import useSession from 'hooks/useSession';
import { toMemberPage } from 'helpers/utils';
import { ERROR_404_PAGE, MY_NEWS } from 'constants/routes';
import { findMyNewsById } from 'redux/client/news';
import { HTTP_OK } from 'constants/ResponseStatus';
import Skeleton from 'react-loading-skeleton';

function MyNewsDetail() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSession();
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [myNewsDetail, setMyNewsDetail] = useState(null);
  useEffect(() => {
    _getNewsById();
  }, []);

  const _getNewsById = async () => {
    setIsLoading(true);
    const resp = await dispatch(findMyNewsById(id));
    setIsLoading(false);
    if (resp.status != HTTP_OK) {
      navigate(ERROR_404_PAGE, {
        replace: true
      });
    }
    setMyNewsDetail(resp.data.data);
  };
  return (
    <div className="client-container">
      <div className="content_wrapper">
        <div className="my-news-detail pb-5">
          <div className="row">
            <Link to={toMemberPage(user?.user_type, MY_NEWS)} className="btn-back-link ms-4">
              <span>戻る</span>
            </Link>
          </div>
          <div className="col-lg-12 col-12 px-0 publish-at">
            {isLoading ? <Skeleton width="25%" height={24} /> : <span>{myNewsDetail?.publish_at_format}</span>}
          </div>
          <div className="col-lg-12 col-12 title border-bottom border-2">
            <h2>{isLoading ? <Skeleton height={24} width="50%" /> : myNewsDetail.title}</h2>
          </div>
          <div className="col-lg-12 col-12 body pt-4 px-4">
            {isLoading ? (
              <>
                <Skeleton style={{ marginBottom: 12 }} count={8} height={24} />
                <Skeleton height={24} width={220} />
              </>
            ) : (
              myNewsDetail.body
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default MyNewsDetail;
