import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import PropTypes from 'prop-types';

function LineChart({ item }) {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );

  const options = {
    responsive: true,
    scales: {
      y: {
        beginAtZero: true
      }
    },
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'bottom'
      },
      title: {
        display: false,
        text: item.title || ''
      }
    }
  };

  const labels = item.labels || [];

  const data = {
    labels,
    datasets: [
      {
        label: item.title || '',
        data: item.values,
        borderColor: item.borderColor || '#98A6B5',
        backgroundColor: item.backgroundColor || '#FFFFFF'
      }
    ]
  };

  return (
    <Line options={options} data={data} />
  );
}

LineChart.propTypes = {
  item: PropTypes.object.isRequired
};

export default LineChart;
