export const APP_INFLUENCER = '/influencer';
export const APP_COMPANY = '/company';
export const APP_NPO = '/npo';

export const HOME = '/';

export const TERMS = '/terms';
export const TERMS_OF_USE_COMPANY = '/terms-of-use-company';
export const TERMS_OF_USE_INFLUENCER = '/terms-of-use-influencer';
export const POLICY = '/policy';
export const POLICY_PRIVACY = '/privacy-policy';
export const LEGAL = '/legal';

export const SIGN_IN = '/login';
export const INITIAL_SIGN_UP = '/:role/registration';
export const REGISTER_COMPANY = `${APP_COMPANY}/registration`;
export const REGISTER_INFLUENCER = `${APP_INFLUENCER}/registration`;
export const EMAIL_VERIFY = '/member/email-verify/:token';
export const FORGOT_PASSWORD = '/forgot-password';
export const RESET_PASSWORD = '/reset-password/:token';
export const RESET_PASSWORD_COMPLETE = '/reset-password/complete';

export const SIGN_UP_COMPLETE = '/member/registration/complete';

// Company Routes
export const SIGN_UP_COMPANY = '/member/company/registration';
export const SIGN_UP_COMPANY_CONFIRM = '/company/registration/confirm';
export const COMPANY_IDENTITY_VERIFY = '/company/identity-verify';
export const COMPANY_SEARCH_INFLLUENCERS = '/company/search/influencer';
export const COMPANY_LEAVE_GUILD = '/company/leave-guild';

// NPO Routes
export const SIGN_UP_NPO = '/member/npo/registration';
export const SIGN_UP_NPO_CONFIRM = '/member/npo/registration/confirm';
export const NPO_LEAVE_GUILD = '/npo/leave-guild';

// Influencer Routes
export const SIGN_UP_INFLUENCER_STEP_1 = '/member/influencer/registration/step1';
export const SIGN_UP_INFLUENCER_STEP_2 = '/member/influencer/registration/step2';
export const SIGN_UP_INFLUENCER_CONFIRM = '/member/influencer/registration/confirm';
export const INFLUENCER_LEAVE_GUILD = '/influencer/leave-guild';

export const TEMP_COMPLETE_SIGN_UP_NPO_MAIL = '/npo/registration/complete/';
export const TEMP_COMPLETE_SIGN_UP_COMPANY_MAIL = '/company/registration/complete/';
export const TEMP_COMPLETE_SIGN_UP_INFLUENCER_MAIL = '/influencer/registration/complete/';
export const INITIAL_SIGN_UP_SNS_CALLBACK = 'login/:provider/callback';

export const MYPAGE = 'mypage';
export const MY_PROJECT_LIST = 'myproject';
export const MY_PROJECT_LIST_FAVORITE = 'myproject/favorite';
export const MY_PROJECT_LIST_NOMINATED = 'myproject/nominated';
export const MY_PROJECT_CREATE = 'myproject/create';
export const MY_PROJECT_EDIT = 'myproject/:id/edit';
export const MY_PROJECT_CREATE_AND_SEARCH_NPO = 'myproject/creation/select-npo';
export const MY_PROJECT_EDIT_AND_SEARCH_NPO = 'myproject/:id/edit/select-npo';
export const MY_PROJECT_CREATE_CONFIRM = 'myproject/confirm';
export const MY_PROJECT_EDIT_CONFIRM = 'myproject/:id/confirm';
export const MY_PROJECT_DETAIL = 'myproject/:id/detail';
export const MY_PROJECT_LIST_TALK_ROOM = 'myproject/:id/talk-rooms';
export const INVITATION = 'invitation';

// Project Review
export const PROJECT_REVIEW = 'myproject/:id/talk-room/:idTalkRoom/review';

export const NDA_COMPANY = '/company/nda';

export const REWARD_CHANGE = '/influencer/reward-change';

export const EVALUATION = 'evaluation';

export const MY_PROFILE_UPDATE = 'my-profile/update';
export const MY_PROFILE_UPDATE_CONFIRM = 'my-profile/update/confirm';

// Only NPO And Influencer Routes
export const BANK_ACCOUNT = 'bank-account';

// Error Routes
export const ERROR_PAGE = '/error.html';
export const ERROR_404_PAGE = '/404.html';

// Project creation
export const INFLUENCER_SELECT = 'myproject/creation/select-influencer';
export const PROJECT_CREATION_INFLUENCER_DETAIL = 'myproject/creation/influencer-detail/:id';
export const PROJECT_EDIT_INFLUENCER_SELECT = 'myproject/:id/edit/select-influencer';
export const PROJECT_EDIT_INFLUENCER_DETAIL = 'myproject/:id/edit/influencer-detail/:influencerId';

// project
export const PROJECT_LIST = 'projects';
export const PROJECT_CATEGORY_LIST = 'projects/category/:category_id';
export const PROJECT_TYPE_LIST = 'projects/project-type/:project_type';
export const PROJECT_USER_TYPE_LIST = 'projects/:user_type';
export const PROJECT_DETAIL = 'project/:id';


// news
export const NEWS_LIST = '/news';
export const NEWS_DETAIL = '/news/:id';
export const MY_NEWS = 'my-news';
export const MY_NEWS_DETAIL = 'my-news/:id';

export const IDENTITY_VERIFY = 'identity-verify';
export const NDA = 'nda';

// project
export const ADMIN_PROJECT_LIST = 'projects';
export const ADMIN_PROJECT_DETAIL = 'project/:id/detail';

// user
export const USER_CHANGE_PASSWORD = 'user/change-password';

// news
export const ADMIN_NEWS_LIST = 'news';

// purchase
export const PURCHASE_POINTS = 'purchase/product/:id';

export const PURCHASE_COMPLETE = 'purchase/complete';

// points
export const MY_POINT_INDEX = 'plan/points/buy';
export const MY_POINT_PURCHASE = 'plan/points/my-purchase';

// admin
export const ADMIN_CHANGE_PASSWORD = 'change-password';
export const USER_ADMIN_LIST = 'user-admin';

// message
export const MESSAGES = 'myproject/:id/messages';

// request transfer point
export const TRANSFER_HISTORY = 'points/transfer';

// Admin page
export const APP_ADMIN = '/stf';
export const MY_PAGE_ADMIN = 'mypage';
export const SIGN_IN_ADMIN = 'sign-in';
export const USER_COMPANY_LIST_ADMIN = 'user-company';
export const USER_COMPANY_DETAIL_ADMIN = 'user-company/:id';
export const USER_INFLUENCER_LIST_ADMIN = 'user-influencer';
export const USER_INFLUENCER_DETAIL_ADMIN = 'user-influencer/:id';
export const USER_NPO_LIST_ADMIN = 'user-npo';
export const USER_NPO_DETAIL_ADMIN = 'user-npo/:id';
export const USER_REQUEST_MANAGEMENT = 'user-request-management';
export const TRANSFER_HISTORY_LIST_ADMIN = 'transfer-history';
export const COMPANY_CANCELLATION_REQUEST = 'cancelations';
export const REWARD_RETE_SETTING = 'reward-rate-setting/setting';
export const TAG = 'tag';
export const DISTRIBUTION_TO_NPO = 'donation';
