import API from 'axios/API';

export const GET_PAYMENT_KEY = 'GET_PAYMENT_KEY';
export const GET_POP_CLIENT_KEY = 'GET_POP_CLIENT_KEY';
export const GET_MY_PURCHASE = 'GET_MY_PURCHASE';

export const CREDIT_CARD_PAYMENT = 0;
// export const BANK_PAYMENT = 1;
export const PAYMENT_COMPLETE = 0;
export const PAYMENT_UNPAID = 1;

export const purchasePoints = (product) => (dispatch) => {
  return API({
    method: 'POST',
    url: 'plan/purchase/payment-key',
    data: {
      product_id: product.id,
      custom_points: product.point,
      original_points: product.original_point,
      original_price: product.original_price
    }
  })
    .then((res) => {
      dispatch({
        type: GET_PAYMENT_KEY,
        payload: res
      });
      return res;
    })
    .catch((error) => {
      return error;
    });
};

export const getPopClientKey = () => (dispatch) => {
  return API({
    method: 'GET',
    url: 'plan/purchase/pop-client-key'
  })
    .then((res) => {
      dispatch({
        type: GET_POP_CLIENT_KEY,
        payload: res
      });
      return res;
    })
    .catch((error) => {
      return error;
    });
};

export const getMyPurchases = (currentPage = 1) => (dispatch) => {
  return API({
    method: 'GET',
    url: `plan/purchase/my?page=${currentPage}`
  })
    .then((res) => {
      dispatch({
        type: GET_MY_PURCHASE,
        payload: res
      });
      return res;
    })
    .catch((error) => {
      return error;
    });
};
