import React, { useState } from 'react';
import { Modal, Spinner } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import * as ja from 'helpers/yup-locale-ja';
import { Field, Formik, ErrorMessage, FastField } from 'formik';
import PropTypes from 'prop-types';
import ToastMessage from 'components/admin/common/ToastMessage';
import { ResponseStatus } from 'constants';
import { CustomDatePicker } from 'components/formik';
import moment from 'moment/moment';

import { createNews } from 'redux/admin/news';
import { setFormikErrors } from 'helpers/utils';

Yup.setLocale(ja.suggestive);
const FormSchema = Yup.object().shape({
  title: Yup.string().required(),
  publish_at: Yup.date().required().min(moment().local().format('YYYY-MM-DD HH:mm'))
});

function NewsAddModal({
  isAddNewsModalShow,
  setIsAddNewsModalShow,
  userId
}) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const dispatch = useDispatch();

  const initFormikValues = {
    user_id: userId,
    title: '',
    body: '',
    publish_at: ''
  };

  const onSubmitForm = async (form, formikHelpers) => {
    setIsSubmitting(true);
    const res = await dispatch(createNews(form));
    if (res && res.status === ResponseStatus.HTTP_UNPROCESSABLE_ENTITY) {
      setFormikErrors(res.data.errors, formikHelpers.setFieldError);
      setIsSubmitting(false);
      return;
    }
    if (res && res.status === ResponseStatus.HTTP_OK) {
      setIsSubmitting(false);
      setIsAddNewsModalShow(false);
    } else {
      setIsSubmitting(false);
      setShowToast(true);
    }
  };

  return (
    <div>
      <Modal
        onHide={() => setIsAddNewsModalShow(false)}
        show={isAddNewsModalShow}
        size="lg"
        centered
        className="user-request-modal form-modal"
      >
        <Modal.Header closeButton />
        <Modal.Body>
          <div className="container">
            <Formik initialValues={initFormikValues} validationSchema={FormSchema} onSubmit={onSubmitForm}>
              {({ handleSubmit, dirty, isValid, errors, touched }) => (
                <form className="admin-form-custom-1 form-update form-style-1">
                  <div className="row mt-4">
                    <div className="col-12">
                      <label className="form-label" htmlFor="title">
                        タイトル
                      </label>
                      <FastField
                        type="text"
                        name="title"
                        id="title"
                        className={`form-control ${!isValid && errors.title && touched.title ? 'is-invalid' : ''}`}
                        placeholder="入力してください"
                      />
                      <ErrorMessage component="div" name="title" className="invalid-feedback" />
                    </div>
                  </div>
                  <div className="row mt-4">
                    <div className="col-12">
                      <label className="form-label" htmlFor="body">
                        本文
                      </label>
                      <Field
                        as="textarea"
                        rows="5"
                        name="body"
                        id="body"
                        className="form-control"
                        placeholder="入力してください"
                      />
                    </div>
                  </div>
                  <div className="row mt-4">
                    <div className="col-6">
                      <Field
                        name="publish_at"
                        label={['公開日']}
                        placeholderText="日付を選択してください"
                        minDate={Date.now()}
                        dateFormat="yyyy/MM/dd HH:mm"
                        showTimeSelect
                        component={CustomDatePicker}
                      />
                    </div>
                  </div>
                  <div className="row mt-4">
                    <div className="col-12 text-center">
                      <button
                        type="button"
                        className="btn admin-btn-green"
                        onClick={() => {
                          handleSubmit();
                        }}
                        disabled={!(isValid && dirty) || isSubmitting}
                      >
                        {isSubmitting && (
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            className="me-2"
                          />
                        )}
                        登録する
                      </button>
                    </div>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </Modal.Body>
      </Modal>
      <ToastMessage
        isShow={showToast}
        onClose={() => {
          setShowToast(false);
        }}
      />
    </div>
  );
}
NewsAddModal.defaultProps = {
  userId: null
};
NewsAddModal.propTypes = {
  isAddNewsModalShow: PropTypes.bool.isRequired,
  setIsAddNewsModalShow: PropTypes.func.isRequired,
  userId: PropTypes.number
};

export default NewsAddModal;
