import AdminAPI from 'axios/AdminAPI';

export const SIGN_IN_ADMIN = 'SIGN_IN_ADMIN';
export const REMOVE_ACCESS_TOKEN = 'REMOVE_ACCESS_TOKEN';

export const logOut = () => (dispatch) => {
  return AdminAPI({
    method: 'POST',
    url: 'logout'
  }).then(() => {
    dispatch({
      type: REMOVE_ACCESS_TOKEN
    });
  });
};

export const signIn = (requestForm) => (dispatch) => {
  return AdminAPI({
    method: 'POST',
    url: 'login',
    data: requestForm
  })
    .then((res) => {
      dispatch({
        type: SIGN_IN_ADMIN,
        payload: res
      });
      return res;
    })
    .catch((error) => {
      return error;
    });
};

export const removeAccessToken = () => ({
  type: REMOVE_ACCESS_TOKEN
});

export const doChangeAdminPassword = (_requestForm) => () => {
  return AdminAPI({
    method: 'POST',
    url: 'change-password',
    data: _requestForm
  })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error;
    });
};
