import React, { useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import useSession from 'hooks/useSession';

import Navigation from 'components/common/Navigation';
import * as page from 'pages';
import * as ROUTES from 'constants/routes';
import Footer from 'components/common/Footer';
import { useSelector } from 'react-redux';
import RegexpRouter from './RegexpRouter';
import PublicRoute from './PublicRoute';
import ProtectedNPORoute from './ProtectedNPORoute';
import ProtectedCompanyRoute from './ProtectedCompanyRoute';
import ProtectedInfluencerRoute from './ProtectedInfluencerRoute';

const CompleteSignUp = React.lazy(() => import('pages/auth/signup/CompleteSignUp'));
const ForgotPassword = React.lazy(() => import('pages/auth/forgot_password/ForgotPassword'));
const ResetPassword = React.lazy(() => import('pages/auth/forgot_password/ResetPassword'));
const CompleteResetPassword = React.lazy(() => import('pages/auth/forgot_password/CompleteResetPassword'));
const Terms = React.lazy(() => import('pages/terms/Terms'));
const TermsCompany = React.lazy(() => import('pages/terms/TermsCompany'));
const TermsInfluencer = React.lazy(() => import('pages/terms/TermsInfluencer'));
const Policy = React.lazy(() => import('pages/policy/Policy'));
const PrivacyPolicy = React.lazy(() => import('pages/policy/PrivacyPolicy'));
const Legal = React.lazy(() => import('pages/terms/Legal'));


function ClientRouter() {
  const newsHeaderItem = useSelector((state) => state.news.newsHeader);
  const { user } = useSession();
  const [headerHeight, setHeaderHeight] = useState(0);

  useEffect(() => {
    function handleResize() {
      const mainMenuHeader = document.getElementById('maiMenuWrapper');
      if (mainMenuHeader) {
        setHeaderHeight(mainMenuHeader.offsetHeight);
      }
    }

    // Handle padding top of client main wrapper
    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [headerHeight, newsHeaderItem]);

  return (
    <>
      <Navigation />

      <main
        id="clientMainWrapper"
        className={`client-main-wrapper bg-overlay ${Object.keys(newsHeaderItem).length > 0 ? 'has-top-bar' : ''} ${
          user ? 'client-logged' : ''
        }`}
        style={{ paddingTop: `${headerHeight}px` }}
      >
        <Routes>
          <Route path={ROUTES.HOME} element={<page.Home />} />
          <Route
            path={ROUTES.TERMS}
            element={(
              <React.Suspense>
                <Terms />
              </React.Suspense>
            )}
          />
          <Route
            path={ROUTES.TERMS_OF_USE_COMPANY}
            element={(
              <React.Suspense>
                <TermsCompany />
              </React.Suspense>
            )}
          />
          <Route
            path={ROUTES.TERMS_OF_USE_INFLUENCER}
            element={(
              <React.Suspense>
                <TermsInfluencer />
              </React.Suspense>
            )}
          />
          <Route
            path={ROUTES.POLICY}
            element={(
              <React.Suspense>
                <Policy />
              </React.Suspense>
            )}
          />
          <Route
            path={ROUTES.POLICY_PRIVACY}
            element={(
              <React.Suspense>
                <PrivacyPolicy />
              </React.Suspense>
            )}
          />
          <Route
            path={ROUTES.LEGAL}
            element={(
              <React.Suspense>
                <Legal />
              </React.Suspense>
            )}
          />

          <Route element={<PublicRoute />}>
            <Route path={ROUTES.SIGN_IN} element={<page.Login />} />
            <Route
              path={ROUTES.INITIAL_SIGN_UP}
              element={<RegexpRouter regexVal="company|npo|influencer" paramName="role" />}
            >
              <Route index element={<page.InitialSignUp />} />
            </Route>
            <Route
              path={ROUTES.FORGOT_PASSWORD}
              element={(
                <React.Suspense>
                  <ForgotPassword />
                </React.Suspense>
              )}
            />
            <Route
              path={ROUTES.RESET_PASSWORD}
              element={(
                <React.Suspense>
                  <ResetPassword />
                </React.Suspense>
              )}
            />
            <Route
              path={ROUTES.RESET_PASSWORD_COMPLETE}
              element={(
                <React.Suspense>
                  <CompleteResetPassword />
                </React.Suspense>
              )}
            />

            <Route path={ROUTES.TEMP_COMPLETE_SIGN_UP_NPO_MAIL} element={<page.TempCompleteSignUpMail />} />
            <Route path={ROUTES.TEMP_COMPLETE_SIGN_UP_COMPANY_MAIL} element={<page.TempCompleteSignUpMail />} />
            <Route path={ROUTES.TEMP_COMPLETE_SIGN_UP_INFLUENCER_MAIL} element={<page.TempCompleteSignUpMail />} />

            <Route
              path={ROUTES.SIGN_UP_COMPLETE}
              element={(
                <React.Suspense>
                  <CompleteSignUp />
                </React.Suspense>
              )}
            />

            <Route path={ROUTES.SIGN_UP_COMPANY_CONFIRM} element={<page.ConfirmSignUpCompany />} />
            <Route path={`${ROUTES.SIGN_UP_COMPANY}/:userAuthToken`} element={<page.SignUpCompany />} />

            <Route path={ROUTES.SIGN_UP_NPO_CONFIRM} element={<page.ConfirmSignUpNPO />} />
            <Route path={`${ROUTES.SIGN_UP_NPO}/:userAuthToken`} element={<page.SignUpNPO />} />

            <Route path={ROUTES.SIGN_UP_INFLUENCER_CONFIRM} element={<page.ConfirmSignInInfluencer />} />
            <Route path={`${ROUTES.SIGN_UP_INFLUENCER_STEP_1}/:userAuthToken`}>
              <Route index element={<page.SignUpInfluencerStep1 />} />
              <Route path=":tiktokAccessToken" element={<page.SignUpInfluencerStep1 />} />
            </Route>
            <Route path={`${ROUTES.SIGN_UP_INFLUENCER_STEP_2}/:userAuthToken`}>
              <Route index element={<page.SignUpInfluencerStep2 />} />
              <Route path=":tiktokAccessToken" element={<page.SignUpInfluencerStep2 />} />
            </Route>
          </Route>

          {/* Group Company Routes */}
          <Route path={ROUTES.APP_COMPANY} element={<ProtectedCompanyRoute />}>
            <Route path={ROUTES.MYPAGE} element={<page.MyPageCompany />} />
            <Route path={ROUTES.MY_PROJECT_LIST} element={<page.MyProjectList />} />
            <Route path={ROUTES.MY_PROJECT_LIST_NOMINATED} element={<page.MyProjectList />} />
            <Route path={ROUTES.MY_PROJECT_CREATE} element={<page.MyProjectCreate />} />
            <Route path={ROUTES.MY_PROJECT_CREATE_AND_SEARCH_NPO} element={<page.MyProjectCreateAndSearchNPO />} />
            <Route path={ROUTES.MY_PROJECT_EDIT_AND_SEARCH_NPO} element={<page.MyProjectEditAndSearchNPO />} />
            <Route path={ROUTES.MY_PROJECT_CREATE_CONFIRM} element={<page.MyProjectCreateConfirm />} />
            <Route path={ROUTES.INFLUENCER_SELECT} element={<page.InfluencerSelect />} />
            <Route path={ROUTES.PROJECT_CREATION_INFLUENCER_DETAIL} element={<page.InfluencerDetail />} />
            <Route path={ROUTES.PROJECT_EDIT_INFLUENCER_SELECT} element={<page.MyProjectEditInfluencerSelect />} />
            <Route path={ROUTES.PROJECT_EDIT_INFLUENCER_DETAIL} element={<page.MyProjectEditInfluencerDetail />} />
            <Route path={ROUTES.MY_PROJECT_DETAIL} element={<page.MyProjectDetail />} />
            <Route path={ROUTES.MY_PROJECT_EDIT} element={<page.MyProjectEdit />} />
            <Route path={ROUTES.MY_PROJECT_EDIT_CONFIRM} element={<page.MyProjectEditConfirm />} />
            <Route path={ROUTES.USER_CHANGE_PASSWORD} element={<page.ChangePassword />} />
            <Route path={ROUTES.MY_PROJECT_LIST_TALK_ROOM} element={<page.ListTalkRoom />} />

            <Route path={ROUTES.MY_PROFILE_UPDATE} element={<page.UpdateCompany />} />
            <Route path={ROUTES.MY_PROFILE_UPDATE_CONFIRM} element={<page.ConfirmUpdateCompany />} />
            <Route path={ROUTES.MY_NEWS} element={<page.MyNews />} />
            <Route path={ROUTES.MY_NEWS_DETAIL} element={<page.MyNewsDetail />} />
            <Route path={ROUTES.PURCHASE_POINTS} element={<page.Purchase />} />
            <Route path={ROUTES.PURCHASE_COMPLETE} element={<page.PurchaseComplete />} />
            <Route path={ROUTES.MY_POINT_INDEX} element={<page.MyPointsCompany />} />
            <Route path={ROUTES.MY_POINT_PURCHASE} element={<page.MyPurchase />} />
            <Route path={ROUTES.NDA_COMPANY} element={<page.NdaCompany />} />
            <Route path={ROUTES.COMPANY_IDENTITY_VERIFY} element={<page.CompanyIdentityVerify />} />
            <Route path={ROUTES.MESSAGES} element={<page.Messages />} />
            <Route path={ROUTES.PROJECT_REVIEW} element={<page.ProjectReviews />} />
            <Route path={ROUTES.COMPANY_SEARCH_INFLLUENCERS} element={<page.SearchInfluencers />} />
          </Route>

          {/* Group NPO Routes */}
          <Route path={ROUTES.APP_NPO} element={<ProtectedNPORoute />}>
            <Route path={ROUTES.MYPAGE} element={<page.MyPageNPO />} />
            <Route path={ROUTES.MY_PROJECT_LIST} element={<page.MyProjectList />} />
            <Route path={ROUTES.MY_PROJECT_CREATE} element={<page.MyProjectCreate />} />
            <Route path={ROUTES.MY_PROJECT_CREATE_CONFIRM} element={<page.MyProjectCreateConfirm />} />
            <Route path={ROUTES.PROJECT_CREATION_INFLUENCER_DETAIL} element={<page.InfluencerDetail />} />
            <Route path={ROUTES.MY_PROJECT_DETAIL} element={<page.MyProjectDetail />} />
            <Route path={ROUTES.MY_PROJECT_EDIT} element={<page.MyProjectEdit />} />
            <Route path={ROUTES.MY_PROJECT_EDIT_CONFIRM} element={<page.MyProjectEditConfirm />} />
            <Route path={ROUTES.USER_CHANGE_PASSWORD} element={<page.ChangePassword />} />
            <Route path={ROUTES.MY_PROJECT_LIST_TALK_ROOM} element={<page.ListTalkRoom />} />

            <Route path={ROUTES.MY_PROFILE_UPDATE} element={<page.UpdateNpo />} />
            <Route path={ROUTES.MY_PROFILE_UPDATE_CONFIRM} element={<page.ConfirmUpdateNpo />} />
            <Route path={ROUTES.BANK_ACCOUNT} element={<page.BankAccount />} />
            <Route path={ROUTES.MY_NEWS} element={<page.MyNews />} />
            <Route path={ROUTES.MY_NEWS_DETAIL} element={<page.MyNewsDetail />} />
            <Route path={ROUTES.MESSAGES} element={<page.Messages />} />
            <Route path={ROUTES.TRANSFER_HISTORY} element={<page.TransferPoints />} />
            <Route path={ROUTES.PROJECT_REVIEW} element={<page.ProjectReviews />} />
          </Route>

          {/* Group Influencer Routes */}
          <Route path={ROUTES.APP_INFLUENCER} element={<ProtectedInfluencerRoute />}>
            <Route path={ROUTES.MYPAGE} element={<page.MyPageInfluencer />} />
            <Route path={ROUTES.MY_PROJECT_LIST} element={<page.MyProjectInfluencer />} />
            <Route path={ROUTES.MY_PROJECT_LIST_FAVORITE} element={<page.MyProjectInfluencer />} />
            <Route path={ROUTES.MY_PROJECT_LIST_NOMINATED} element={<page.MyProjectInfluencer />} />
            <Route path={ROUTES.MY_PROJECT_DETAIL} element={<page.MyProjectDetail />} />
            <Route path={ROUTES.REWARD_CHANGE}>
              <Route index element={<page.RewardChange />} />
              <Route path=":tiktokAccessToken" element={<page.RewardChange />} />
            </Route>
            <Route path={ROUTES.USER_CHANGE_PASSWORD} element={<page.ChangePassword />} />

            <Route path={ROUTES.MY_PROFILE_UPDATE} element={<page.UpdateInfluencer />} />
            <Route path={ROUTES.MY_PROFILE_UPDATE_CONFIRM} element={<page.ConfirmUpdateInfluencer />} />
            <Route path={ROUTES.BANK_ACCOUNT} element={<page.BankAccount />} />
            <Route path={ROUTES.MY_NEWS} element={<page.MyNews />} />
            <Route path={ROUTES.MY_NEWS_DETAIL} element={<page.MyNewsDetail />} />
            <Route path={ROUTES.EVALUATION} element={<page.EvaluationInfluencer />} />
            <Route path={ROUTES.MESSAGES} element={<page.Messages />} />
            <Route path={ROUTES.TRANSFER_HISTORY} element={<page.TransferPoints />} />
            <Route path={ROUTES.PROJECT_REVIEW} element={<page.ProjectReviews />} />
            <Route path={ROUTES.INVITATION} element={<page.InvitationInfluencer />} />
          </Route>

          {/* Project routes */}
          <Route path={ROUTES.PROJECT_LIST} element={<page.ProjectList />} />
          <Route path={ROUTES.PROJECT_CATEGORY_LIST} element={<page.ProjectList />} />
          <Route path={ROUTES.PROJECT_TYPE_LIST} element={<page.ProjectList />} />
          <Route path={ROUTES.PROJECT_USER_TYPE_LIST} element={<page.ProjectList />} />
          <Route path={ROUTES.PROJECT_DETAIL} element={<page.ProjectDetail />} />

          {/* news routes */}
          <Route path={ROUTES.NEWS_LIST} element={<page.NewsList />} />
          <Route path={ROUTES.NEWS_DETAIL} element={<page.NewsDetail />} />

          <Route path={ROUTES.EMAIL_VERIFY} element={<page.EmailVerify />} />

          <Route path={ROUTES.ERROR_PAGE} element={<page.PageErrorCommon />} />
          <Route path="*" element={<page.PageNotFound />} />
        </Routes>
      </main>
      <Footer />
    </>
  );
}

export default ClientRouter;
