import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import useSession from 'hooks/useSession';
import { toMemberPage } from 'helpers/utils';
import * as ROUTES from 'constants/routes';
import CalculateRatingStars from 'components/common/CalculateRatingStars';
import constants from 'constants/constants';
import RankSystem from 'components/common/RankSystem';
import MyPointIcon from 'assets/svg/point-influencer.svg';
import { ShieldCheck } from 'react-bootstrap-icons';
import ProjectNews from './ProjectNews';

function CompanyInformation({ newsList, userCompanyPointsAndRank, myProjects }) {
  const { user } = useSession();
  const navigate = useNavigate();

  const linkToMyProjectList = (tabId) => {
    const urlRedirect = `${ROUTES.MY_PROJECT_LIST}?tabActive=${tabId}`;
    navigate(toMemberPage(user?.user_type, urlRedirect));
  };

  return (
    <div className="row">
      <div className="col-12">
        <div className="card box-style-1__border mypage-user-info-left__card mb-5 align-items-center">
          <div className="card-body">
            <div className="user-rank d-flex align-items-center">
              <div className="my-point d-flex me-5">
                <img src={MyPointIcon} alt="" className="me-1 me-lg-3" />
                <span>
                  <strong className="d-inline-block me-2 me-lg-3">
                    {userCompanyPointsAndRank?.points || 0}
                  </strong>
                  <small>Pt</small>
                </span>
              </div>
              <RankSystem
                userType={constants.USER_TYPE_BY_USER.KEY.company}
                rank={(typeof userCompanyPointsAndRank?.user_rank === 'undefined')
                  ? 0 : parseInt(userCompanyPointsAndRank.user_rank, 10)}
              />
            </div>
            <div className="my-review d-flex align-items-center">
              <CalculateRatingStars
                rating={userCompanyPointsAndRank?.user_review_rank || 0}
                size={20}
              />
              <span className="ms-2"> ({userCompanyPointsAndRank?.user_review_person || 0})</span>
            </div>
            <div className="user-verify-wrap d-flex mt-3">
              <div className="user-verify d-flex align-items-center">
                <div className={`d-inline-flex align-items-center user-info__certificate
                                ${userCompanyPointsAndRank?.is_identity_verify ? 'success' : ''}`}
                >
                  <ShieldCheck />
                  <span className="ms-1">{userCompanyPointsAndRank?.is_identity_verify ? '本人確認済' : '本人確認前'}</span>
                </div>
              </div>
              <div className="user-verify d-flex align-items-center ms-4">
                <div className={`d-inline-flex align-items-center user-info__certificate
                                ${userCompanyPointsAndRank?.is_nda ? 'success' : ''}`}
                >
                  <ShieldCheck />
                  <span className="ms-1">{userCompanyPointsAndRank?.is_nda ? 'NDA締結済' : 'NDA未締結'}</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <ProjectNews newsList={newsList} />

        <div className="user-statistical">
          <div className="project-statistical">
            <div className="project-statistical-inner">
              <div
                className="project-statistical-item processing"
                onClick={() => linkToMyProjectList(0)}
                onKeyDown={() => linkToMyProjectList(0)}
                role="button"
                tabIndex="0"
              >
                <div className="number">{myProjects?.count?.processing || 0}</div>
                <div className="type-text">進行中</div>
              </div>
              <div
                className="project-statistical-item draft"
                onClick={() => linkToMyProjectList(1)}
                onKeyDown={() => linkToMyProjectList(1)}
                role="button"
                tabIndex="0"
              >
                <div className="number">{myProjects?.count?.draft || 0}</div>
                <div className="type-text">下書き</div>
              </div>
              <div
                className="project-statistical-item archive"
                onClick={() => linkToMyProjectList(2)}
                onKeyDown={() => linkToMyProjectList(2)}
                role="button"
                tabIndex="0"
              >
                <div className="number">{myProjects?.count?.archive || 0}</div>
                <div className="type-text">アーカイブ</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
CompanyInformation.propTypes = {
  userCompanyPointsAndRank: PropTypes.object.isRequired,
  myProjects: PropTypes.object.isRequired,
  newsList: PropTypes.array.isRequired
};
export default CompanyInformation;
