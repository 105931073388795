import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUserCompanyIdentity } from 'redux/admin/user';
import Skeleton from 'react-loading-skeleton';
import { Field, Formik } from 'formik';
import Pagination from 'components/admin/Pagination/Pagination';
import UserCompanyIdentityRow from './UserCompanyIdentityRow';
import UserCompanyIdentityModal from './UserCompanyIdentityModal';

function UserCompanyIdentityList() {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [popupData, setPopupData] = useState({});
  const [isModalShow, setIsModalShow] = useState(false);
  const [requestParams, setRequestParams] = useState([]);

  const store = useSelector((state) => ({
    userCompanyIdentityList: state.userAdminPanel.userCompanyIdentity
  }));
  useEffect(() => {
    _getUserCompanyIdentityPaginate();
  }, []);

  const _getUserCompanyIdentityPaginate = async (page = 1, params = []) => {
    setIsLoading(true);
    try {
      await dispatch(getUserCompanyIdentity(page, params));
      setIsLoading(false);
      return true;
    } catch (error) {
      setIsLoading(false);
      return error;
    }
  };

  const _handlePageClick = (event) => {
    _getUserCompanyIdentityPaginate(event.selected + 1, requestParams);
  };

  const userCompanyIdentityModalHandle = (item) => {
    setIsModalShow(true);
    setPopupData(item);
  };

  const initFormikValues = {
    is_verify: false
  };

  const onSubmitForm = async (form) => {
    if (isLoading) {
      return;
    }
    setRequestParams(form);
    _getUserCompanyIdentityPaginate(1, form);
  };

  return (
    <>
      <UserCompanyIdentityModal
        isModalShow={isModalShow}
        setIsModalShow={setIsModalShow}
        data={popupData}
        requestParams={requestParams}
      />
      <Formik initialValues={initFormikValues} onSubmit={onSubmitForm}>
        {({ handleChange, handleSubmit }) => (
          <form className="admin-form-custom-1">
            <div className="mb-0 mt-4">
              <div className="form-check">
                <Field
                  type="checkbox"
                  name="is_verify"
                  id="is_verify"
                  className="form-check-input"
                  onChange={(e) => {
                    handleChange(e);
                    handleSubmit();
                  }}
                />
                <label className="form-check-label" htmlFor="is_verify">
                  承認済みのデータを表示
                </label>
              </div>
            </div>
          </form>
        )}
      </Formik>
      <div className="table-reward mt-3">
        <table className="table table-lg table-hover admin-table-custom-1">
          {(!isLoading && store.userCompanyIdentityList?.total < 1) && (
            <caption className="text-center py-5 h5">見つかりません。</caption>
          )}
          <thead>
            <tr>
              <th width="10%">ID</th>
              <th width="25%">氏名</th>
              <th width="25%">メールアドレス</th>
              <th width="15%">生年月日</th>
              <th width="15%">登録日</th>
              <th width="10%">&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            {isLoading
              && [...Array(30)].map((e, i) => (
                <tr key={`${i.toString()}`}>
                  <td>
                    <Skeleton height={17} />
                  </td>
                  <td>
                    <Skeleton height={17} />
                  </td>
                  <td>
                    <Skeleton height={17} />
                  </td>
                  <td>
                    <Skeleton height={17} />
                  </td>
                  <td>
                    <Skeleton height={17} />
                  </td>
                  <td>
                    <Skeleton height={17} />
                  </td>
                </tr>
              ))}

            {(!isLoading && store.userCompanyIdentityList?.data)
              && store.userCompanyIdentityList?.data?.map((item) => (
                <UserCompanyIdentityRow
                  key={item.id}
                  itemRow={item}
                  onClickUserCompanyIdentityShowModal={() => userCompanyIdentityModalHandle(item)}
                />
              ))}
          </tbody>
        </table>
      </div>
      {store.userCompanyIdentityList?.last_page > 1 && (
        <div className="d-flex justify-content-center my-5">
          <Pagination
            className="pagination admin-pagination-custom-1"
            pageCount={store.userCompanyIdentityList?.last_page}
            onPageChange={_handlePageClick}
          />
        </div>
      )}
    </>
  );
}

export default UserCompanyIdentityList;
