/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ja } from 'date-fns/locale';
import ReactDatePicker from 'react-datepicker';
import { Calendar2 } from 'react-bootstrap-icons';

import 'react-datepicker/dist/react-datepicker.css';
import './style/date-picker-custom.css';
import { ErrorMessage } from 'formik';
import moment from 'moment';


function CustomDatePickerStartSunday({ field, form: { touched, errors, setFieldValue }, label, ...props }) {
  const [startDate, setStartDate] = useState(null);
  useEffect(() => {
    if (field.value) {
      setStartDate(new Date(field.value));
    }
  }, [field.value]);

  return (
    <>
      {label.length > 0 && (
        <label htmlFor={field.name} className="form-label">
          {label[0]}
          {label[1] && <span className="label-required">{label[1]}</span>}
        </label>
      )}

      <div className={`datepicker_start-sunday react-datepicker-input-group ${touched[field.name] && errors[field.name] && 'is-invalid'}`}>
        <ReactDatePicker
          id={field.name}
          wrapperClassName="react-datepicker-wrapper-custom-1"
          popperClassName="react-datepicker-popper-custom-1"
          className={`form-control ${touched[field.name] && errors[field.name] && 'is-invalid'}`}
          selected={startDate}
          calendarStartDay={0}
          locale={ja}
          popperPlacement="bottom-end"
          showPopperArrow={false}
          dayClassName={(date) => {
            const day = date.getDay();
            const saturday = 6;
            const sunday = 0;
            return day === saturday || day === sunday ? 'react-datepicker__day--highlighted-custom-1' : undefined;
          }}
          name={field.name}
          onBlur={field.onBlur}
          onChange={(date) => {
            const formatDate = { ...props }.showTimeSelect ? 'YYYY-MM-DD HH:mm:ss' : 'YYYY-MM-DD';
            setFieldValue(field.name, date ? moment(date).local().format(formatDate) : '');
            setStartDate(date);
          }}
          {...props}
        />
        <span className="input-group-text">
          <Calendar2 size={18} color="#8B8E8D " />
        </span>
      </div>
      <ErrorMessage name={field.name} component="div" className="invalid-feedback" />
    </>
  );
}

CustomDatePickerStartSunday.defaultProps = {
  label: []
};

CustomDatePickerStartSunday.propTypes = {
  label: PropTypes.array,
  field: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired
};

export default CustomDatePickerStartSunday;
