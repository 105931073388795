import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import Slider from 'react-slick';
import constants from 'constants/constants';
import Image from 'components/common/Image';

function AttachImagesModal({
  isShowAttachImagesModal,
  setIsShowAttachImagesModal,
  attachments,
  attachImageSelected
}) {
  const handleClose = () => {
    setIsShowAttachImagesModal(false);
  };

  const listImages = attachments.length > 0 ? attachments.filter((item) => {
    return item.mime_type !== constants.PDF_FILE_TYPE;
  }) : [];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: attachImageSelected
  };

  return (
    <Modal
      show={isShowAttachImagesModal}
      onHide={handleClose}
      size="lg"
      centered
      className="modal-amazing modal-attach-images"
    >
      <Modal.Header closeButton />
      <div className="justify-content-center">
        {listImages.length > 0 && (
          <Slider {...settings}>
            {listImages.map((item) => (
              <div className="wrap-item d-flex justify-content-center" key={item.id}>
                <Image
                  src={item.link_attached_file ?? item.url}
                  alt={item.name ?? item.file.name}
                />
              </div>
            ))}
          </Slider>
        )}
      </div>
    </Modal>
  );
}
AttachImagesModal.defaultProps = {
  attachments: [],
  attachImageSelected: 0
};
AttachImagesModal.propTypes = {
  isShowAttachImagesModal: PropTypes.bool.isRequired,
  setIsShowAttachImagesModal: PropTypes.func.isRequired,
  attachments: PropTypes.array,
  attachImageSelected: PropTypes.number
};
export default AttachImagesModal;
