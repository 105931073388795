import {
  GET_POINTS_BALANCE,
  GET_POINTS_TRANSFER_HISTORY
} from './TransferPointsAction';

const initialState = {
  availablePoints: {},
  pointsTransferHistory: [],
  transferHistorys: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_POINTS_BALANCE:
      return {
        ...state,
        availablePoints: action.payload.data
      };
    case GET_POINTS_TRANSFER_HISTORY:
      return {
        ...state,
        pointsTransferHistory: action.payload.data
      };
    default:
      return state;
  }
};
