import React from 'react';
import PropTypes from 'prop-types';
import { getFormattedDate } from 'helpers/utils';

function UserAdminRow({ itemRow, onDeleteClick }) {
  return (
    <tr>
      <td>{itemRow.id}</td>
      <td>{itemRow.email}</td>
      <td>{itemRow.created_at ? getFormattedDate(itemRow.created_at) : ''}</td>
      <td>
        <div
          role="button"
          tabIndex="0"
          className="hyperlink underline"
          onClick={onDeleteClick}
          onKeyDown={onDeleteClick}
        >
          <span>削除</span>
        </div>
      </td>
    </tr>
  );
}
UserAdminRow.propTypes = {
  itemRow: PropTypes.object.isRequired,
  onDeleteClick: PropTypes.func.isRequired
};
export default UserAdminRow;
