import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Skeleton from 'react-loading-skeleton';
import { getUserAdmin } from 'redux/admin/user';
import UserAdminRow from './UserAdminRow';
import UserAdminDeleteConfirmModal from './UserAdminDeleteConfirmModal';
import UserAdminRegister from './UserAdminRegister';

function index() {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [isDeleteModalShow, setIsDeleteModalShow] = useState(false);
  const [userAdminId, setUserAdminId] = useState(0);

  const store = useSelector((state) => ({
    userAdminList: state.userAdminPanel.usersAdmin
  }));
  useEffect(() => {
    _getUserAdminList();
  }, []);

  const _getUserAdminList = async () => {
    setIsLoading(true);
    try {
      await dispatch(getUserAdmin());
      setIsLoading(false);
      return true;
    } catch (error) {
      setIsLoading(false);
      return error;
    }
  };

  const deleteModalHandle = (id) => {
    setIsDeleteModalShow(true);
    setUserAdminId(id);
  };
  return (
    <>
      <UserAdminDeleteConfirmModal
        isDeleteModalShow={isDeleteModalShow}
        setIsDeleteModalShow={setIsDeleteModalShow}
        userAdminId={userAdminId}
      />
      <UserAdminRegister />
      <div className="table-reward table-responsive">
        <table className="table table-lg table-hover admin-table-custom-1">
          {!isLoading && store.userAdminList?.length <= 0 && (
            <caption className="text-center py-5 h5">見つかりません。</caption>
          )}
          <thead>
            <tr>
              <th width="15%">ID</th>
              <th width="35%">メールアドレス</th>
              <th width="25%">登録日</th>
              <th width="25%">&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            {isLoading
              && [...Array(30)].map((e, i) => (
                <tr key={`${i.toString()}`}>
                  <td>
                    <Skeleton height={17} />
                  </td>
                  <td>
                    <Skeleton height={17} />
                  </td>
                  <td>
                    <Skeleton height={17} />
                  </td>
                  <td>
                    <Skeleton height={17} />
                  </td>
                  <td>
                    <Skeleton height={17} />
                  </td>
                  <td>
                    <Skeleton height={17} />
                  </td>
                  <td>
                    <Skeleton height={17} />
                  </td>
                </tr>
              ))}

            {!isLoading
              && store.userAdminList?.length > 0
              && store.userAdminList?.map((item) => (
                <UserAdminRow key={item.id} itemRow={item} onDeleteClick={() => deleteModalHandle(item.id)} />
              ))}
          </tbody>
        </table>
      </div>
    </>
  );
}
export default index;
