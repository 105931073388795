import { useEffect } from 'react';

const useScript = (url, popClientKey) => {
  useEffect(() => {
    const script = document.createElement('script');

    script.src = url;
    script.async = false;
    script.type = 'text/javascript';
    if (popClientKey != '') {
      script.setAttribute('data-client-key', popClientKey);
    }
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [popClientKey]);
};

export default useScript;
