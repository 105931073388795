// talkRoomSystemNotice[project_type][user_type][talk_status]
// Influencer: user_type = 0; Company|Npo: user_type = 1
export const talkRoomSystemNotice = [
  // project_type = 0
  [
    // Influencer
    [
      {
        key: 0,
        value: ''
      },
      {
        key: 1,
        value: 'ステータスが不採用に変更されました。このプロジェクトはアーカイブに移行されます。'
      },
      {
        key: 2,
        value: 'ステータスが採用に変更されました。投稿日時の調整を行ってください。'
          + '\n※ギフティングがある場合は企業へ商品の送り先をお伝えください。'
      },
      {
        key: 3,
        value: ''
      },
      {
        key: 4,
        value: 'ステータスが日時調整に変更されました。日程調整ボタンから希望日を選択し、送信してください。'
      },
      {
        key: 5,
        value: '取引キャンセルの申請が提出されています。運営の確認中です。'
      },
      {
        key: 6,
        value: '取引キャンセルが承認されたため、このプロジェクトはアーカイブに移行されます。'
      },
      {
        key: 7,
        value: '企業による投稿承認をお待ちください。'
      },
      {
        key: 8,
        value: 'ステータスが投稿承認に変更されました。{company_npo_name}さんの評価を入力してください'
      },
      {
        key: 9,
        value: ''
      },
      {
        key: 10,
        value: '指示された場所への来店もしくは動画を投稿後、投稿申請を送ってください。' // case: project_appoint.appoint_date is exist
      },
      {
        key: 11,
        value: '投稿修正を依頼されています。投稿動画を修正後、投稿申請を送ってください。' // case: status = 2 && old_status = 7
      },
      {
        key: 12,
        value: '' // case: status = 0 => 1
      },
      {
        key: 13,
        value: '' // case: status = 9 => 1
      },
      {
        key: 14,
        value: '' // case: status = 9 => 3
      },
      {
        key: 15,
        value: '' // case: status = 8 and was reviewed
      }
    ],
    // Company
    [
      {
        key: 0,
        value: ''
      },
      {
        key: 1,
        value: 'ステータスが不採用に変更されました。不採用のインフルエンサーとは連絡のやり取りができません。'
      },
      {
        key: 2,
        value: 'ステータスが採用に変更されました。インフルエンサーと投稿日時の調整を行ってください。'
          + '\n※ギフティングが必要な場合はインフルエンサーへ商品の送り先を確認してください。'
      },
      {
        key: 3,
        value: 'ステータスが保留に変更されました。'
      },
      {
        key: 4,
        value: 'ステータスが日時調整に変更されました。インフルエンサーによる日程の確認作業をお待ちください。'
      },
      {
        key: 5,
        value: '取引キャンセル申請を受け取りました。運営の確認中です。'
      },
      {
        key: 6,
        value: '取引キャンセルが承認されました。'
      },
      {
        key: 7,
        value: 'URLから動画を確認し、投稿承認ステータスに進むとプロジェクト完了となります。'
          + '\n※投稿の動画に修正が必要な場合は投稿修正依頼に変更してください。'
      },
      {
        key: 8,
        value: 'ステータスが投稿承認に変更されました。{user_name}さんの評価を入力してください'
      },
      {
        key: 9,
        value: ''
      },
      {
        key: 10,
        value: 'インフルエンサーによる来店もしくは動画投稿申請をお待ちください。' // case: project_appoint.appoint_date is exist
      },
      {
        key: 11,
        value: '投稿修正依頼をしました。インフルエンサーによる動画投稿申請をお待ちください。' // case: status = 2 && old_status = 7
      },
      {
        key: 12,
        value: '' // case: status = 0 => 1
      },
      {
        key: 13,
        value: '' // case: status = 9 => 1
      },
      {
        key: 14,
        value: '' // case: status = 9 => 3
      },
      {
        key: 15,
        value: '' // case: status = 8 and was reviewed
      }
    ]
  ],
  // project_type = 1
  [
    // Influencer
    [
      {
        key: 0,
        value: ''
      },
      {
        key: 1,
        value: ''
      },
      {
        key: 2,
        value: 'プロジェクトへ参加しました。'
          + '\n指定された納品期限までにPR動画の作成、投稿を行ってください。'
          + '\n※ギフティングがある場合は企業へ商品の送り先をお伝えください。'
          + '\n動画の作成、投稿が完了したらステータスを【投稿申請】に切り替えることで次のステップに進みます。'
      },
      {
        key: 3,
        value: ''
      },
      {
        key: 4,
        value: 'ステータスが日時調整に変更されました。日程調整ボタンから希望日を選択し、送信してください。'
      },
      {
        key: 5,
        value: '取引キャンセルの申請が提出されています。運営の確認中です。'
      },
      {
        key: 6,
        value: '取引キャンセルが承認されたため、このプロジェクトはアーカイブに移行されます。'
      },
      {
        key: 7,
        value: '企業による投稿承認をお待ちください。'
      },
      {
        key: 8,
        value: 'ステータスが投稿承認に変更されました。{company_npo_name}さんの評価を入力してください'
      },
      {
        key: 9,
        value: ''
      },
      {
        key: 10,
        value: '指示された場所への来店もしくは動画を投稿後、投稿申請を送ってください。' // case: project_appoint.appoint_date is exist
      },
      {
        key: 11,
        value: '投稿修正を依頼されています。投稿動画を修正後、投稿申請を送ってください。' // case: status = 2 && old_status = 7
      },
      {
        key: 12,
        value: '' // case: status = 0 => 1
      },
      {
        key: 13,
        value: '' // case: status = 9 => 1
      },
      {
        key: 14,
        value: '' // case: status = 9 => 3
      },
      {
        key: 15,
        value: '' // case: status = 8 and was reviewed
      },
      {
        key: 16,
        value: 'OTASUKEによる投稿承認をお待ちください。' // case: project type 1 (CANDIDACY) and created by NPO and status = 7
      },
      {
        key: 17,
        value: 'ステータスが投稿承認に変更されました。' // case: project type 1 (CANDIDACY) and created by NPO and status = 8
      }
    ],
    // Company
    [
      {
        key: 0,
        value: ''
      },
      {
        key: 1,
        value: ''
      },
      {
        key: 2,
        value: 'ステータスが採用に変更されました。インフルエンサーと投稿日時の調整を行ってください。'
          + '\n※ギフティングが必要な場合はインフルエンサーへ商品の送り先を確認してください。'
      },
      {
        key: 3,
        value: ''
      },
      {
        key: 4,
        value: 'ステータスが日時調整に変更されました。インフルエンサーによる日程の確認作業をお待ちください。'
      },
      {
        key: 5,
        value: '取引キャンセル申請を受け取りました。運営の確認中です。'
      },
      {
        key: 6,
        value: '取引キャンセルが承認されました。'
      },
      {
        key: 7,
        value: 'URLから動画を確認し、投稿承認ステータスに進むとプロジェクト完了となります。'
          + '\n※投稿の動画に修正が必要な場合は投稿修正依頼に変更してください。'
      },
      {
        key: 8,
        value: 'ステータスが投稿承認に変更されました。{user_name}さんの評価を入力してください'
      },
      {
        key: 9,
        value: ''
      },
      {
        key: 10,
        value: 'インフルエンサーによる来店もしくは動画投稿申請をお待ちください。' // case: project_appoint.appoint_date is exist
      },
      {
        key: 11,
        value: '投稿修正依頼をしました。インフルエンサーによる動画投稿申請をお待ちください。' // case: status = 2 && old_status = 7
      },
      {
        key: 12,
        value: '' // case: status = 0 => 1
      },
      {
        key: 13,
        value: '' // case: status = 9 => 1
      },
      {
        key: 14,
        value: '' // case: status = 9 => 3
      },
      {
        key: 15,
        value: '' // case: status = 8 and was reviewed
      },
      {
        key: 16,
        value: ''
      },
      {
        key: 17,
        value: 'ステータスが投稿承認に変更されました。' // case: project type 1 (CANDIDACY) and created by NPO and status = 8
      }
    ]
  ],
  // project_type = 2
  [
    // Influencer
    [
      {
        key: 0,
        value: '指名オファーが届きました。指名承認するか否認するか選択してください。'
          + '\n指名承認するとプロジェクトが開始します。'
      },
      {
        key: 1,
        value: ''
      },
      {
        key: 2,
        value: '指名オファーを承認しました。投稿日時の調整を行ってください。'
          + '\n※ギフティングがある場合は企業へ商品の送り先をお伝えください。'
      },
      {
        key: 3,
        value: ''
      },
      {
        key: 4,
        value: 'ステータスが日時調整に変更されました。日程調整ボタンから希望日を選択し、送信してください。'
      },
      {
        key: 5,
        value: '取引キャンセルの申請が提出されています。運営の確認中です。'
      },
      {
        key: 6,
        value: '取引キャンセルが承認されたため、このプロジェクトはアーカイブに移行されます。'
      },
      {
        key: 7,
        value: '企業による投稿承認をお待ちください。'
      },
      {
        key: 8,
        value: 'ステータスが投稿承認に変更されました。{company_npo_name}さんの評価を入力してください'
      },
      {
        key: 9,
        value: 'ステータスが指名承諾に変更されました。正式な採用連絡をお待ちください。'
      },
      {
        key: 10,
        value: '指示された場所への来店もしくは動画を投稿後、投稿申請を送ってください。' // case: project_appoint.appoint_date is exist
      },
      {
        key: 11,
        value: '投稿修正を依頼されています。投稿動画を修正後、投稿申請を送ってください。' // case: status = 2 && old_status = 7
      },
      {
        key: 12,
        value: 'このプロジェクトはアーカイブに移行されます。' // case: status = 0 => 1
      },
      {
        key: 13,
        value: 'ステータスが不採用に変更されました。このプロジェクトはアーカイブに移行されます。' // case: status = 9 => 1
      },
      {
        key: 14,
        value: '' // case: status = 9 => 3
      },
      {
        key: 15,
        value: '' // case: status = 8 and was reviewed
      }
    ],
    // Company
    [
      {
        key: 0,
        value: '指名オファーを送信しました。インフルエンサーによる返答をお待ちください。'
      },
      {
        key: 1,
        value: ''
      },
      {
        key: 2,
        value: '指名オファーが承認されました。インフルエンサーと投稿日時の調整を行ってください。'
          + '\n※ギフティングが必要な場合はインフルエンサーへ商品の送り先を確認してください。'
      },
      {
        key: 3,
        value: ''
      },
      {
        key: 4,
        value: 'ステータスが日時調整に変更されました。インフルエンサーによる日程の確認作業をお待ちください。'
      },
      {
        key: 5,
        value: '取引キャンセル申請を受け取りました。運営の確認中です。'
      },
      {
        key: 6,
        value: '取引キャンセルが承認されました。'
      },
      {
        key: 7,
        value: 'URLから動画を確認し、投稿承認ステータスに進むとプロジェクト完了となります。'
          + '\n※投稿の動画に修正が必要な場合は投稿修正依頼に変更してください。'
      },
      {
        key: 8,
        value: 'ステータスが投稿承認に変更されました。{user_name}さんの評価を入力してください'
      },
      {
        key: 9,
        value: 'インフルエンサーが指名オファーを承認しました。正式にこのインフルエンサーを採用する場合、ステータスを採用に変更してください。'
      },
      {
        key: 10,
        value: 'インフルエンサーによる来店もしくは動画投稿申請をお待ちください。' // case: project_appoint.appoint_date is exist
      },
      {
        key: 11,
        value: '投稿修正依頼をしました。インフルエンサーによる動画投稿申請をお待ちください。' // case: status = 2 && old_status = 7
      },
      {
        key: 12,
        value: 'ステータスが指名否認に変更されました。このインフルエンサーとは連絡のやり取りができません。' // case: status = 0 => 1
      },
      {
        key: 13,
        value: 'ステータスが不採用に変更されました。不採用のインフルエンサーとは連絡のやり取りができません。' // case: status = 9 => 1
      },
      {
        key: 14,
        value: 'ステータスが保留に変更されました。' // case: status = 9 => 3
      },
      {
        key: 15,
        value: '' // case: status = 8 and was reviewed
      }
    ]
  ]
];
