export const purposeData = [
  {
    key: 0,
    value: 'サービスの宣伝'
  },
  {
    key: 1,
    value: '商品の宣伝'
  },
  {
    key: 2,
    value: '会社の宣伝'
  },
  {
    key: 3,
    value: 'フォロワーを増やしたい'
  },
  {
    key: 4,
    value: 'まだ決まっていない'
  }
];

export const purposeDataNPO = [
  {
    key: 0,
    value: '活動内容の認知向上'
  },
  {
    key: 1,
    value: '社会課題の啓発'
  },
  {
    key: 2,
    value: '資金やリソースの獲得支援'
  },
  {
    key: 3,
    value: 'ネットワーク拡大とパートナーシップ構築'
  }
];
