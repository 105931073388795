import {
  GET_MESSAGES,
  FIND_TALK_ROOM,
  FIND_VIDEO_INFO,
  FIND_PROJECT_APPOINT
} from './messageActions';

const initialState = {
  messages: {
    data: []
  },
  talkRoom: {},
  videoInfo: [],
  appoint: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_MESSAGES:
      return {
        ...state,
        messages: action.payload.data
      };
    case FIND_TALK_ROOM:
      return {
        ...state,
        talkRoom: action.payload.data
      };
    case FIND_VIDEO_INFO:
      return {
        ...state,
        videoInfo: action.payload.data
      };
    case FIND_PROJECT_APPOINT:
      return {
        ...state,
        appoint: action.payload.data
      };
    default:
      return state;
  }
};
