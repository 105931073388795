import * as Yup from 'yup';
import * as ja from 'helpers/yup-locale-ja';
import constants from 'constants/constants';

Yup.setLocale(ja.suggestive);
export const CreateIdentityVerifyValidation = () => {
  return Yup.object().shape({
    docs_type: Yup.string().required(),
    attached_files: Yup.mixed()
      .test('fileFormat', 'タイプのファイルを指定してください。', (value) => {
        if (value && value?.length > 0) {
          for (let i = 0; i < value.length; i += 1) {
            if (value[i].id == null && !constants.UPLOAD_IMAGE_SUPPORTED_FORMATS.includes(value[i].file?.type)) {
              return false;
            }
          }
        }
        return true;
      })
      .test('fileMin', '1つファイル以上アップロードしてください', (value) => value.length > 0)
      .test('fileMax', '3ファイル以下アップロードしてください', (value) => value.length <= constants.UPLOAD_FILE_MAX_FILE_IDENTITY_VERIFY)
      .test('fileSize', '8MB以下のファイルを指定してください。', (value) => {
        if (value && value?.length > 0) {
          for (let i = 0; i < value.length; i += 1) {
            if (value[i].id == null && value[i].file?.size > constants.MAX_UPLOAD_SIZE_8MB) {
              return false;
            }
          }
        }
        return true;
      })
  });
};
