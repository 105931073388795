import * as Yup from 'yup';
import * as ja from 'helpers/yup-locale-ja';

Yup.setLocale(ja.suggestive);
export const UserAdminRegisterValidation = Yup.object().shape({
  email: Yup.string().required().email(),
  password: Yup.string()
    .required()
    .max(255)
    .matches(/^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z0-9]{8,}$/, '半角英数字8桁以上で入力してください。')
});
