/* eslint-disable no-param-reassign */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import HeartFullIcon from 'assets/svg/heart-full-ic.svg';
import HeartEmptyIcon from 'assets/svg/heart-empty-ic.svg';
import TypeIcon from 'assets/svg/type-ic.svg';
import { HTTP_OK } from 'constants/ResponseStatus';
import { useDispatch } from 'react-redux';
import { addProjectToFavorite, removeProjectFromFavorite } from 'redux/client/project';
import { Spinner } from 'react-bootstrap';
import useSession from 'hooks/useSession';
import { Link } from 'react-router-dom';
import Image from 'components/common/Image';
import CustomProgressBar from 'components/client/CustomProgressBar';
import { USER_TYPE_COMPANY, USER_TYPE_NPO } from 'redux/client/auth';
import constants from 'constants/constants';
import RankSystem from 'components/common/RankSystem';

const USER_TYPE_INFLUENCER = 0;

function ProjectItem({ project, projectUrl }) {
  const dispatch = useDispatch();
  const [isProcessing, setIsProcessing] = useState(false);
  const { user } = useSession();
  const addToFavorite = async (projectItem) => {
    setIsProcessing(true);
    const resp = await dispatch(addProjectToFavorite(projectItem.id));
    if (resp && resp.status === HTTP_OK) {
      projectItem.is_favorited = 1;
    }
    setIsProcessing(false);
  };

  const removeFromFavorite = async (projectItem) => {
    setIsProcessing(true);
    const resp = await dispatch(removeProjectFromFavorite(projectItem.id));
    if (resp && resp.status === HTTP_OK) {
      projectItem.is_favorited = 0;
    }
    setIsProcessing(false);
  };

  return (
    <div className="mb-4 col-xl-4 col-md-6 col-12 project-item ">
      <div className="card">
        <div className="card-inner">
          <div className="cover-image">
            <Link to={projectUrl} title={project.project_title}>
              <Image className="img-fluid project__img" src={project.link_cover_image} alt={project.project_title} />
            </Link>
            <span className="category">{project.category_name}</span>
            {user?.user_type === USER_TYPE_INFLUENCER && (
              <button
                type="button"
                className="favorite"
                onClick={() => (project.is_favorited ? removeFromFavorite(project) : addToFavorite(project))}
              >
                {isProcessing && (<Spinner as="span" animation="border" />)}
                {!isProcessing && project.is_favorited === 0 && (<img src={HeartEmptyIcon} alt="" />)}
                {!isProcessing && project.is_favorited === 1 && (<img src={HeartFullIcon} alt="" />)}
              </button>
            )}
            <div className="position-absolute bottom-0 start-0 end-0 remaining">
              {user && project.recruits_number !== undefined && (
                <span className="remaining-delivery-days">
                  <span className="label">残り</span>
                  <span className="number">
                    {project.day_remain}
                    <span className="day">日</span>
                  </span>
                </span>
              )}
            </div>
          </div>
          <div
            className={project.user_type === USER_TYPE_COMPANY ? 'card-body company-project' : 'card-body npo-project'}
          >
            <div className="type-info">
              <span className="project-type">
                <img src={TypeIcon} alt="" />
                {project.project_type_text}
              </span>
              <span className={`type type-${project.type}`}>{project.type_name}</span>
              {user && project.user_type !== USER_TYPE_NPO && (
                <div className="user-rank-area-new company list-project">
                  <RankSystem
                    userType={constants.USER_TYPE_BY_USER.KEY.company}
                    rank={project.user_rank?.rank || 0}
                  />
                </div>
              )}
            </div>
            <h2 className="title">
              <Link to={projectUrl} title={project.project_title}>{project.project_title}</Link>
            </h2>
            <p className="company-name">{project.npo_or_company_name}&nbsp;</p>
            {user && (
              <>
                <div className="recruits-info">
                  <p className="info">
                    <span className="label">募集予定人数</span>
                    <span className="number">
                      {project.recruits_number || 0}
                      <span className="person">人</span>
                    </span>
                  </p>
                  <p className="info">
                    <span className="label">現在の応募人数</span>
                    <span className="number">
                      {project.current_recruits_number || 0}
                      <span className="person">人</span>
                    </span>
                  </p>
                </div>
                {project.user_type === USER_TYPE_COMPANY ? (
                  <>
                    <p className="budget-label">予算消化率</p>
                    <CustomProgressBar now={project.budget_utilization_rate} />
                  </>
                ) : (
                  <>
                    <p className="budget-label">&nbsp;</p>
                    <CustomProgressBar now={100} customLabel="テディベアプロジェクト" />
                  </>
                )}
              </>
            )}
            <p className="delivery">{project.delivery_at_format}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

ProjectItem.propTypes = {
  project: PropTypes.object.isRequired,
  projectUrl: PropTypes.string.isRequired
};
export default ProjectItem;
