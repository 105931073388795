import React from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import UserCompanyIdentityList from './company/components/identity/UserCompanyIdentityList';

function UserRequestManagement() {
  return (
    <div className="management-user-tab">
      <Tabs defaultActiveKey="tab3" id="management-user-tab" className="mb-3">
        <Tab eventKey="tab3" title="本人確認（企業）">
          <UserCompanyIdentityList />
        </Tab>
      </Tabs>
    </div>
  );
}
export default UserRequestManagement;
