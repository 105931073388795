import React from 'react';
import PropTypes from 'prop-types';

function UserCompanyStatistics({ userCompany }) {
  return (
    <section className="section-project-statistics py-4">
      <div className="row group-content">
        <div className="col-md-3 col-sm-6 py-1">
          <div className="d-flex flex-wrap form-control border-0 bg-overlay light-pink p-4">
            <div className="flex-fill">
              公開中
            </div>
            <div className="flex-fill fw-bold">
              {userCompany?.project_statistics?.public_number} 回
            </div>
          </div>
        </div>

        <div className="col-md-3 col-sm-6 py-1">
          <div className="d-flex flex-wrap form-control border-0 bg-overlay light-pink p-4">
            <div className="flex-fill">
              投稿完了
            </div>
            <div className="flex-fill fw-bold">
              {userCompany?.project_statistics?.hiring_number} 回
            </div>
          </div>
        </div>

        <div className="col-md-3 col-sm-6 py-1">
          <div className="d-flex flex-wrap form-control border-0 bg-overlay light-pink p-4">
            <div className="flex-fill">
              取引キャンセル
            </div>
            <div className="flex-fill fw-bold">
              {userCompany?.project_statistics?.transaction_cancel_number} 回
            </div>
          </div>
        </div>

        <div className="col-md-3 col-sm-6 py-1">
          <div className="d-flex flex-wrap form-control border-0 bg-overlay light-pink p-4">
            <div className="flex-fill">
              不採用
            </div>
            <div className="flex-fill fw-bold">
              {userCompany?.project_statistics?.rejections_number} 回
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

UserCompanyStatistics.propTypes = {
  userCompany: PropTypes.object
};

UserCompanyStatistics.defaultProps = {
  userCompany: null
};
export default UserCompanyStatistics;
