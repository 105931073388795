import AdminAPI from 'axios/AdminAPI';

export const GET_REWARD_RATE_SETTING_SUCCESS = 'GET_REWARD_RATE_SETTING_SUCCESS';
export const UPDATE_OR_CREATE_RATE_SETTING_SUCCESS = 'UPDATE_OR_CREATE_RATE_SETTING_SUCCESS';

export const getRewardRateSetting = () => (dispatch) => {
  return AdminAPI({
    method: 'GET',
    url: 'reward-rate-setting/get'
  }).then((res) => {
    dispatch({
      type: GET_REWARD_RATE_SETTING_SUCCESS,
      payload: res
    });
  });
};

export const updateOrCreateRewardRateSetting = (form) => (dispatch) => {
  return AdminAPI({
    method: 'POST',
    url: 'reward-rate-setting/update-or-create',
    data: form
  }).then(() => {
    dispatch({
      type: UPDATE_OR_CREATE_RATE_SETTING_SUCCESS,
      payload: form
    });
  });
};
