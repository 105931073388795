import React, { useMemo, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import * as ROUTES from 'constants/routes';
import { LoadingContext } from 'hooks/LoadingContext';
import useSession from 'hooks/useSession';
import Skeleton from 'react-loading-skeleton';
import HeartFill from 'assets/svg/heart-fill.svg';
import Heart from 'assets/svg/heart.svg';
import IconHiringNumber from 'assets/svg/icon30-1.svg';
import IconFollowerCount from 'assets/svg/icon30-2.svg';
import IconEngagement from 'assets/svg/icon30-3.svg';
import LocationIcon from 'assets/svg/location-ic.svg';
import { addInfluencerToFavorite, removeUserFromFavorite, findInfluencer } from 'redux/client/project';
import { HTTP_OK } from 'constants/ResponseStatus';
import { ResponseStatusMessage } from 'constants/ResponseStatusMessage';
import { Button, Modal, OverlayTrigger, Spinner, Tooltip } from 'react-bootstrap';
import { TikTokEmbed } from 'react-social-media-embed';
import CalculateTeddyBearRank from 'components/common/CalculateTeddyBearRank';
import DefaultAvatarImg from 'assets/image.png';
import constants from 'constants/constants';
import RankSystem from 'components/common/RankSystem';
import { QuestionCircleFill } from 'react-bootstrap-icons';
import { requestInfluencerLogin } from 'redux/client/user';
import { ResponseStatus } from 'constants';

function InfluencerDetail() {
  const { user, isPurchasedPoints } = useSession();
  const { id } = useParams();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [showModalView, setShowModalView] = useState(false);
  const [videoSelected, setVideoSelected] = useState(null);
  const [nominatedList, setNominatedList] = useState([]);
  const [isAddingToFavorite, setIsAddingToFavorite] = useState(false);
  const [isFavorite, setIsFavorite] = useState(false);
  const providerValue = useMemo(() => [isLoading], [isLoading]);
  const navigate = useNavigate();
  const [userInfluencerDetail, setUserInfluencerDetail] = useState({});
  const location = useLocation();
  const projectState = location.state || null;
  const [isLoadingSendMail, setIsLoadingSendMail] = useState(false);
  const [isShowModalSendMail, setIsShowModalSendMail] = useState(false);
  const [isSendMail, setIsSendMail] = useState(false);

  const isDisableNomination = (influencerId) => {
    if (nominatedList
      && nominatedList.find((item) => {
        return item.influencerId == influencerId;
      })
    ) {
      return true;
    }
    return false;
  };

  const handleNomination = (influencerId, nickName, salaryAmount) => {
    if (isDisableNomination(influencerId)) {
      navigate(`${ROUTES.APP_COMPANY}/${ROUTES.INFLUENCER_SELECT}`, {
        state: {
          ...projectState,
          nominatedInfluencers: nominatedList.filter((item) => item.influencerId !== influencerId)
        }
      });
    } else {
      navigate(`${ROUTES.APP_COMPANY}/${ROUTES.INFLUENCER_SELECT}`, {
        state: {
          ...projectState,
          nominatedInfluencers: [...nominatedList, { influencerId, nickName, salaryAmount }]
        }
      });
    }
  };


  const handleCloseModal = () => {
    setShowModalView(false);
    setVideoSelected(null);
  };

  const _getUserInfluencerDetail = async () => {
    try {
      const res = await dispatch(findInfluencer(id));
      setUserInfluencerDetail(res.data);

      if (res.data?.favorite_influencer_count > 0) {
        setIsFavorite(true);
      }

      return true;
    } catch (error) {
      navigate(ROUTES.ERROR_PAGE, {
        replace: true,
        state: {
          error_code: error.response?.status,
          error_msg: ResponseStatusMessage[error.response?.status]
        }
      });
      return error;
    }
  };

  const initialPage = async () => {
    if (projectState) setNominatedList(projectState.nominatedInfluencers ?? []);
    setIsLoading(true);
    await _getUserInfluencerDetail();
    setIsLoading(false);
  };

  const addToFavorite = async (influencer) => {
    setIsAddingToFavorite(true);
    const resp = await dispatch(addInfluencerToFavorite(user.id, influencer.id));
    if (resp.status === HTTP_OK) {
      setIsFavorite(true);
    }
    setIsAddingToFavorite(false);
    return true;
  };

  const removeFavorite = async (influencer) => {
    setIsAddingToFavorite(true);
    const resp = await dispatch(removeUserFromFavorite(influencer.id));
    if (resp.status === HTTP_OK) {
      setIsFavorite(false);
    }
    setIsAddingToFavorite(false);
    return true;
  };

  const handleNavigate = () => {
    navigate(`${ROUTES.APP_COMPANY}/${ROUTES.INFLUENCER_SELECT}`, {
      state: {
        ...projectState
      }
    });
  };

  const handleRequestInfluencerLogin = async (userIdInfluencer) => {
    setIsLoadingSendMail(true);
    const res = await dispatch(requestInfluencerLogin(userIdInfluencer));
    if (res && res.status == ResponseStatus.HTTP_UNPROCESSABLE_ENTITY) {
      return;
    }

    if (res.status != ResponseStatus.HTTP_OK) {
      navigate(ROUTES.ERROR_PAGE, {
        replace: true,
        state: {
          error_code: res.status,
          error_msg: ResponseStatusMessage[res.status]
        }
      });
    }
    setIsSendMail(true);
    setIsShowModalSendMail(true);
    setIsLoadingSendMail(false);
  };

  useEffect(() => {
    initialPage();
    const handleContextmenu = (e) => {
      e.preventDefault();
    };
    document.addEventListener('contextmenu', handleContextmenu);
  }, []);

  return (
    <LoadingContext.Provider value={providerValue}>
      <div className={isPurchasedPoints
        ? 'client-user-detail mt-4' : 'client-user-detail mt-4 myproject-creation-influencer-detail dont-purchase'}
      >
        <div className="client-cpanel-container">
          <div className="box-style-1__border">
            <div className="influencer-info__container">
              <div className="go-back-page__box">
                <Button
                  variant="link"
                  className="go-back__btn"
                  onClick={() => {
                    if (projectState) {
                      handleNavigate();
                    } else {
                      navigate(-1);
                    }
                  }}
                >
                  <span className="sr-only text-nowrap">戻る</span>
                </Button>
              </div>

              <div className="row influencer-detail-info-box">
                <div className="col-12 col-md-4 mb-4 mb-md-0 col-ct-4">
                  <div className="position-relative item-avatar mx-auto">
                    <img
                      className="rounded-circle img-fluid"
                      width={300}
                      height={300}
                      src={userInfluencerDetail.tiktok_info?.link_avatar_image || DefaultAvatarImg}
                      alt=""
                      draggable={isPurchasedPoints ? 'true' : 'false'}
                    />
                    <span className="position-absolute bottom-0 end-0" role="button">
                      <button
                        type="button"
                        className="border-0 bg-transparent"
                        disabled={isAddingToFavorite || !isPurchasedPoints}
                        onClick={() => (isFavorite
                          ? removeFavorite(userInfluencerDetail) : addToFavorite(userInfluencerDetail))}
                      >
                        {isFavorite ? <img src={HeartFill} alt="" /> : <img src={Heart} alt="" />}
                      </button>
                    </span>
                  </div>
                </div>
                <div className="col-12 col-md-8 col-ct-8">
                  <div className="row mb-2">
                    <div className="col-12 col-sm-auto">
                      <p className="tiktok-info__username mb-2">@{userInfluencerDetail.tiktok_info?.username}</p>
                      <a
                        href={isPurchasedPoints ? userInfluencerDetail.tiktok_info?.profile_deep_link : ''}
                        className="btn btn-remove-css item-title text-decoration-underline"
                        target="_blank"
                        rel="noreferrer"
                        onClick={(event) => {
                          if (!isPurchasedPoints) {
                            event.preventDefault();
                          }
                        }}
                        draggable={isPurchasedPoints ? 'true' : 'false'}
                      >
                        {userInfluencerDetail.tiktok_info?.nickname}
                      </a>
                    </div>
                    <div className="col-12 col-sm-auto">
                      {!isLoading && userInfluencerDetail && !userInfluencerDetail?.is_update_reward && (
                      <div className="ranks_and_request-login align-items-start">
                        <div className="request-login flex-row">
                          {isPurchasedPoints && (
                          <OverlayTrigger
                            key="top"
                            placement="top"
                            overlay={(
                              <Tooltip id="tooltip-top" className="amazing-tooltip">
                                このインフルエンサーは直近での戦闘力の計測を行っていないため、指名オファーを送る事はできません。<br />
                                ログインリクエストを押すとインフルエンサーにあなたからのリクエストが通知されます。
                              </Tooltip>
                          )}
                          >
                            <QuestionCircleFill className="question-circle-style label-icon ms-2" size={20} />
                          </OverlayTrigger>
                          )}
                          <button
                            type="button"
                            className="btn btn-outline-green ms-2 mb-1 mt-2"
                            disabled={
                                  !isPurchasedPoints
                                  || isLoadingSendMail
                                  || isSendMail
                                }
                            onClick={() => handleRequestInfluencerLogin(userInfluencerDetail.user_id)}
                          >
                            { isLoadingSendMail ? (
                              <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                                className="me-2"
                              />
                            ) : (
                              'ログインリクエスト'
                            )}
                          </button>
                        </div>
                        <p className="text-danger mb-0">最終更新日時: {userInfluencerDetail.updated_at_reward ?? '-'}</p>
                      </div>
                      )}
                    </div>
                    {!isLoading && projectState && (
                      <div className="col-12 col-md-5">
                        <button
                          type="button"
                          className={`${
                            isDisableNomination(userInfluencerDetail.id) ? 'btn-amazing-disabled' : ''
                          } btn btn-green btn-influencer-select`}
                          disabled={isLoading || !userInfluencerDetail.is_update_reward}
                          onClick={() => {
                            handleNomination(
                              userInfluencerDetail.id,
                              userInfluencerDetail.tiktok_info?.nickname,
                              userInfluencerDetail.influencer_reward?.expected_salary_amount_certain
                            );
                          }}
                        >
                          <span className="sr-only">指名する</span>
                        </button>
                      </div>
                    )}
                  </div>
                  <div className="user-rank-area-new has-teddybear d-flex align-items-center mb-2 flex-wrap">
                    <RankSystem
                      userType={constants.USER_TYPE_BY_USER.KEY.influencer}
                      rank={userInfluencerDetail.user_rank?.rank || 0}
                    />
                    <CalculateTeddyBearRank rating={userInfluencerDetail.teddy_bear_rank} />
                  </div>
                  <div className="influencer-info__box">
                    <div className="influencer-info__item">
                      <span className="influencer-info__name">得意ジャンル</span>
                      <div className="influencer-info__value">
                        {userInfluencerDetail.tags?.map((item) => (
                          <p className="m-0" key={`${item.id}_tag_influencer`}>
                            {item.name}
                          </p>
                        ))}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6 col-md-12">
                        <div className="influencer-info__item">
                          <span className="influencer-info__name">年齢</span>
                          <div className="influencer-info__value">
                            {userInfluencerDetail.age}歳
                          </div>
                        </div>
                      </div>
                      <div className="col-6 col-md-12">
                        <div className="influencer-info__item">
                          <span className="influencer-info__name">性別</span>
                          <div className="influencer-info__value">
                            {userInfluencerDetail.gender_text}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="influencer-info__item">
                      <span className="influencer-info__name">活動可能地域</span>
                      <div className="influencer-info__value">
                        {userInfluencerDetail.prefectures?.map((item) => (
                          <p className="mb-2 align-self-center me-3 me-md-0" key={`${item.id}_prefecture_influencer`}>
                            <img src={LocationIcon} className="me-2" alt="" />
                            {item.name}
                          </p>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="influencer-pr-box d-flex flex-wrap">
                <div className="influencer-label">PR</div>
                <p className="influencer-value mb-0">{userInfluencerDetail.pr}</p>
              </div>
              <div className="influencer-info__flex-reverse">
                <h5 className="font-mb-16">このインフルエンサーへの依頼ポイント</h5>
                <div className="row gx-3 tiktok-info__box">
                  <div className="col-4 col-md-6 col-xl-4 mb-3 mb-xl-0">
                    <div className="influencer-reward-amount-box">
                      <strong className="amount__label">立候補</strong>
                      <strong className="amount__value">
                        {userInfluencerDetail.influencer_reward?.expected_salary_amount_public || 0}
                        <small>pt</small>
                      </strong>
                    </div>
                  </div>
                  <div className="col-4 col-md-6 col-xl-4 mb-3 mb-xl-0">
                    <div className="influencer-reward-amount-box">
                      <strong className="amount__label">先着</strong>
                      <strong className="amount__value">
                        {userInfluencerDetail.influencer_reward?.expected_salary_amount_order || 0}
                        <small>pt</small>
                      </strong>
                    </div>
                  </div>
                  <div className="col-4 col-md-6 col-xl-4 mb-3 mb-xl-0">
                    <div className="influencer-reward-amount-box">
                      <strong className="amount__label">指名</strong>
                      <strong className="amount__value">
                        {userInfluencerDetail.influencer_reward?.expected_salary_amount_certain || 0}
                        <small>pt</small>
                      </strong>
                    </div>
                  </div>
                </div>
                <div className="row gx-3 tiktok-info__box">
                  <div className="col-6 col-xl-3 mb-3">
                    <div className="text-center shortcut-item pt-3">
                      <p>
                        <img src={IconHiringNumber} alt="" />
                      </p>
                      <p>採用回数</p>
                      <p className="item-number filter-blur">{userInfluencerDetail.hiring_number || 0} 回</p>
                    </div>
                  </div>
                  <div className="col-6 col-xl-3 mb-3">
                    <div className="text-center shortcut-item pt-3">
                      <p>
                        <img src={IconFollowerCount} alt="" />
                      </p>
                      <p>フォロワー数</p>
                      <p className="item-number filter-blur">{userInfluencerDetail.tiktok_info?.follower_count || 0}</p>
                    </div>
                  </div>
                  <div className="col-6 col-xl-3 mb-3">
                    <div className="text-center shortcut-item pt-3">
                      <p>
                        <img src={IconEngagement} alt="" />
                      </p>
                      <p>エンゲージメント数</p>
                      <p className="item-number filter-blur">
                        {userInfluencerDetail.tiktok_info?.engagement_count || 0}
                      </p>
                    </div>
                  </div>
                  <div className="col-6 col-xl-3 mb-3">
                    <div className="text-center shortcut-item pt-3">
                      <p>
                        <img src={IconEngagement} alt="" />
                      </p>
                      <p>エンゲージメント率</p>
                      <p className="item-number filter-blur">{userInfluencerDetail.tiktok_info?.engagement || 0}%</p>
                    </div>
                  </div>
                </div>
                <div className="row gx-3 tiktok-info__box">
                  <div className="col-6 col-xl-3 mb-3">
                    <div className="text-center shortcut-item py-3 h-auto">
                      <p>直近のいいね数</p>
                      <p className="item-number mb-0 filter-blur">
                        {userInfluencerDetail.tiktok_info?.like_count || 0}
                      </p>
                    </div>
                  </div>
                  <div className="col-6 col-xl-3 mb-3">
                    <div className="text-center shortcut-item py-3 h-auto">
                      <p>直近のコメント数</p>
                      <p className="item-number mb-0 filter-blur">
                        {userInfluencerDetail.tiktok_info?.comment_count || 0}
                      </p>
                    </div>
                  </div>
                  <div className="col-6 col-xl-3 mb-3">
                    <div className="text-center shortcut-item py-3 h-auto">
                      <p>直近のシェア数</p>
                      <p className="item-number mb-0 filter-blur">
                        {userInfluencerDetail.tiktok_info?.share_count || 0}
                      </p>
                    </div>
                  </div>
                  <div className="col-6 col-xl-3 mb-3">
                    <div className="text-center shortcut-item py-3 h-auto">
                      <p>直近の動画再生数</p>
                      <p className="item-number mb-0 filter-blur">
                        {userInfluencerDetail.tiktok_info?.view_count || 0}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row gx-3 tiktok-info__box">
                  <div className="col-6 col-xl-3 mb-3 mb-xl-0">
                    <div className="text-center shortcut-item py-3 h-auto">
                      <p>PR３日後のいいね数<br />（合計）</p>
                      <p className="item-number mb-0 filter-blur">
                        {userInfluencerDetail?.project_reports_sum_like_count_after || 0}
                      </p>
                    </div>
                  </div>
                  <div className="col-6 col-xl-3 mb-3 mb-xl-0">
                    <div className="text-center shortcut-item py-3 h-auto">
                      <p>PR３日後のコメント数<br />（合計）</p>
                      <p className="item-number mb-0 filter-blur">
                        {userInfluencerDetail?.project_reports_sum_comment_count_after || 0}
                      </p>
                    </div>
                  </div>
                  <div className="col-6 col-xl-3 mb-3 mb-xl-0">
                    <div className="text-center shortcut-item py-3 h-auto">
                      <p>PR３日後のシェア数<br />（合計）</p>
                      <p className="item-number mb-0 filter-blur">
                        {userInfluencerDetail?.project_reports_sum_share_count_after || 0}
                      </p>
                    </div>
                  </div>
                  <div className="col-6 col-xl-3 mb-3 mb-xl-0">
                    <div className="text-center shortcut-item py-3 h-auto">
                      <p>PR３日後の動画再生数<br />（合計）</p>
                      <p className="item-number mb-0 filter-blur">
                        {userInfluencerDetail?.project_reports_sum_view_count_after || 0}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="note mt-3">
                  ※「直近」とは直近7日間に投稿された動画を対象としています<br />
                  ※PRはENMAを通したPRの数値になります
                </div>
              </div>
            </div>
          </div>
          <div className="section title pt-5 recent-posts">
            <div className="header pb-4 pb-md-5">
              <h2 className="title-bg">Recent Posts</h2>
              <h2 className="title">最近の投稿</h2>
            </div>
            <div className="row">
              {isLoading
              && [...Array(10)].map((e, i) => (
                <div className="col-6 col-xl-4 mb-3 mb-sm-5" key={`skeleton_v${i.toString()}`}>
                  <div className="box-style-1__border shortcut-video-item">
                    <Skeleton height={255} />
                  </div>
                </div>
              ))}
              {!isLoading && !userInfluencerDetail.tiktok_latest_video && <p>見つかりません。</p>}
              {!isLoading
              && userInfluencerDetail.tiktok_latest_video?.videos.map((item) => (
                <div className="col-6 col-xl-4 mb-3 mb-sm-5" key={`video_${item.index}`}>
                  <div className="box-style-1__border shortcut-video-item">
                    <button
                      type="button"
                      className="btn btn-remove-css p-0 w-100"
                      onClick={() => {
                        setVideoSelected(item);
                        setShowModalView(true);
                      }}
                      disabled={!isPurchasedPoints}
                    >
                      <img src={item.oembed?.thumbnail_url} className="img-fluid" alt="" />
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <Modal show={showModalView} centered onHide={handleCloseModal}>
          {videoSelected && (
            <>
              <Modal.Header closeButton>{videoSelected.oembed?.title}</Modal.Header>
              <Modal.Body closeButton>
                <TikTokEmbed url={videoSelected.url} />
              </Modal.Body>
            </>
          )}
        </Modal>
        <Modal className="modal-amazing" show={isShowModalSendMail} size="lg" centered>
          <Modal.Body>
            <div className="text-center">
              <p className="mb-0">インフルエンサーにログインリクエストを送信しました</p>
            </div>
          </Modal.Body>
          <Modal.Footer className="justify-content-center border-top-0">
            <Button
              variant="modal-redirect"
              className="common-btn btn-small py-2"
              onClick={() => { setIsShowModalSendMail(!isShowModalSendMail); }}
            >
              閉じる
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </LoadingContext.Provider>
  );
}

export default InfluencerDetail;
