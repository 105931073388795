import Cookies from 'js-cookie';
import { CookieStorage } from 'redux-persist-cookie-storage';
import persistReducer from 'redux-persist/es/persistReducer';

import { authReducer } from './auth';
import { categoryReducer } from './category_global';
import { areaReducer } from './area';
import { projectReducer } from './project';
import { typeReducer } from './type';
import { ndaReducer } from './nda';
import { userReducer } from './user';
import { talkRoomReducer } from './talk_room';
import { projectReviewReducer } from './project_reviews';
import { newsReducer } from './news';
import { purchasePointsReducer } from './purchase_points';
import { bankAccountReducer } from './bank_account';
import { productReducer } from './product';
import { messageReducer } from './message';
import { transferPointsReducer } from './transfer_points';
import { tagReducer } from './tag';
import { invitationReducer } from './invitation';

const authPersistConfig = {
  key: 'auth_client',
  storage: new CookieStorage(Cookies, {
    expiration: {
      default: 604800 // one week
    }
  }),
  blacklist: []
};

const clientReducer = {
  auth: persistReducer(authPersistConfig, authReducer),
  categoryGlobal: categoryReducer,
  type: typeReducer,
  area: areaReducer,
  project: projectReducer,
  user: userReducer,
  nda: ndaReducer,
  bankAccount: bankAccountReducer,
  talkRoom: talkRoomReducer,
  news: newsReducer,
  purchasePoints: purchasePointsReducer,
  product: productReducer,
  message: messageReducer,
  projectReview: projectReviewReducer,
  transferPoints: transferPointsReducer,
  tag: tagReducer,
  invitation: invitationReducer
};

export default clientReducer;
