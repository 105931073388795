import { GET_AVAILABLE_POINTS_BY_NPO_CATEGORY, GET_NPOS_FOR_DONATION } from './donationAction';

const initialState = {
  availablePoints: {},
  npos: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_AVAILABLE_POINTS_BY_NPO_CATEGORY:
      return {
        ...state,
        availablePoints: action.payload.data
      };
    case GET_NPOS_FOR_DONATION:
      return {
        ...state,
        npos: action.payload.data
      };
    default:
      return state;
  }
};
