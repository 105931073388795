import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import * as ROUTES from 'constants/routes';
import { ResponseStatusMessage } from 'constants/ResponseStatusMessage';
import { myPointsCompany } from 'redux/client/user';
import { getAllProduct } from 'redux/client/product';
import Skeleton from 'react-loading-skeleton';
import { toMemberPage } from 'helpers/utils';
import useSession from 'hooks/useSession';

function MyPointsCompany() {
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSession();
  const userType = user?.user_type;
  const store = useSelector((state) => ({
    products: state.product.products,
    currentMyPointsCompany: state.user.currentMyPointsCompany
  }));

  useEffect(() => {
    initialPage();
  }, []);

  const initialPage = async () => {
    setIsLoading(true);
    try {
      const myPointCompanyPromise = dispatch(myPointsCompany());
      const getAllProductPromise = store.products.length === 0 ? dispatch(getAllProduct()) : null;
      await Promise.all([myPointCompanyPromise, getAllProductPromise]);
    } catch (error) {
      navigate(ROUTES.ERROR_PAGE, {
        replace: true,
        state: {
          error_code: error.response?.status,
          error_msg: ResponseStatusMessage[error.response?.status]
        }
      });
    }
    setIsLoading(false);
  };

  return (
    <div className="my-points-index-page my-points-index_default">
      <div className="client-cpanel-container">
        <div className="position-relative">
          <h2 className="page-title">ポイント管理</h2>
          <div className="client-cpanel__page-title-after">
            <span className="shadow-text">Point Management</span>
          </div>
        </div>

        <div className="box-style-1__border mb-4">
          <div className="my-points__container">
            {isLoading ? (
              <div className="w-100 d-flex align-items-center justify-content-between">
                <Skeleton containerClassName="w-50" height={61} />
                <Skeleton containerClassName="w-25" height={61} />
              </div>
            ) : (
              <>
                <div className="d-flex justify-content-between align-items-center flex-column flex-sm-row mb-3">
                  <div className="d-inline-flex flex-wrap align-items-end justify-content-center my-points-value-group">
                    <strong className="my-points__name">現在保持しているポイント</strong>
                    <div className="my-points__box-value">
                      <span>{store.currentMyPointsCompany?.points}</span>
                      <span>pt</span>
                    </div>
                  </div>
                  <Link
                    to={toMemberPage(userType, ROUTES.MY_POINT_PURCHASE)}
                    className="btn common-outline-btn my-points__btn"
                  >
                    購入履歴
                  </Link>
                </div>
                <p className="mb-0">現在プロジェクト予算に充てられているポイント：{store.currentMyPointsCompany?.point_pool} pt</p>
              </>
            )}
          </div>
        </div>

        <div className="points-list__container">
          <div className="points-list__header pb-2">
            <strong>ポイントを購入する ※インフルエンサーの最小依頼ポイントは50pt～です </strong>
          </div>
          <div className="points-list__body">
            {isLoading && [...Array(4)].map((e, i) => (
              <div className="points-list__item" key={`${i.toString()}`}>
                <Skeleton containerClassName="w-50" height={30} />
                <Skeleton containerClassName="w-25" height={75} />
              </div>
            ))}

            {Array.isArray(store.products) && store.products.map((record) => (
              <div className="points-list__item" key={record.id}>
                <div className="points-list__btn-price-2">
                  <div className="cost__item text-end">
                    <span className="font-small-18 text-decoration-line-through">通常価格</span>
                    <span className="font-small-22 text-decoration-line-through">￥</span>
                    <span className="text-decoration-line-through">{record.cost}</span>
                  </div>
                  <div className="price__item">
                    <span className="font-small-18">期間限定価格</span>
                    <span className="font-small-35">￥</span>
                    <span>{record.price}</span>
                  </div>
                </div>
                <Link
                  to={toMemberPage(user?.user_type, ROUTES.PURCHASE_POINTS.replace(':id', record.id))}
                  className="btn common-btn points-list__box-value-2"
                >
                  {record.point} <span className="font-small-18">pt</span>
                </Link>
              </div>
            ))}
          </div>
          <div className="points-list__footer pt-2">
            <span>ポイント購入金額は税込金額となります。</span><br />
            <span>ポイントの有効期限は購入から180日間となります </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MyPointsCompany;
