import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';
import { useDispatch, useSelector } from 'react-redux';
import { exportUserCompany, getUserCompanyPaginate } from 'redux/admin/user';

import Pagination from 'components/admin/Pagination/Pagination';
import { getAllCategory, getAllPrefectures } from 'redux/client/category_global';
import ToastMessage from 'components/admin/common/ToastMessage';
import CustomBottomToRightToast from 'components/admin/common/CustomBottomToRightToast';
import UserCompanyRow from './components/UserCompanyRow';
import UserCompanySearchBar from './components/UserCompanySearchBar';
import NewsAddModal from '../NewsAddModal';
import PointAddModal from '../PointAddModal';
import CantDeleteUserModal from '../CantDeleteUserModal';
import DeleteUserModal from '../DeleteUserModal';

function UserCompanyList() {
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingExportCSV, setIsLoadingExportCSV] = useState(false);
  const [remountComponent, setRemountComponent] = useState(0);
  const [requestParamsFilter, setRequestParamsFilter] = useState([]);
  const [isAddNewsModalShow, setIsAddNewsModalShow] = useState(false);
  const [isAddPointModalShow, setIsAddPointModalShow] = useState(false);
  const [isDeleteUserModalShow, setIsDeleteUserModalShow] = useState(false);
  const [isCantDeleteUserModalShow, setIsCantDeleteUserModalShow] = useState(false);
  const [userId, setUserId] = useState(null);
  const [showToastError, setShowToastError] = useState(false);
  const [showToastSuccess, setShowToastSuccess] = useState(false);
  const dispatch = useDispatch();
  const store = useSelector((state) => ({
    categories: state.categoryGlobal.categories,
    prefectures: state.categoryGlobal.prefectures,
    usersCompany: state.userAdminPanel.usersCompany
  }));

  useEffect(() => {
    initialPage();
    _getUserCompanyPaginate();
  }, []);

  const initialPage = async () => {
    try {
      const getAllCategoryPromise = store.categories.length === 0 ? dispatch(getAllCategory()) : null;
      const getAllPrefecturesPromise = store.prefectures.length === 0 ? dispatch(getAllPrefectures()) : null;
      await Promise.all([getAllCategoryPromise, getAllPrefecturesPromise]);

      return true;
    } catch (error) {
      return error;
    }
  };

  const _getUserCompanyPaginate = async (page = 1, requestParams = []) => {
    setIsLoading(true);
    try {
      await dispatch(getUserCompanyPaginate(page, requestParams));

      setIsLoading(false);
      return true;
    } catch (error) {
      setIsLoading(false);
      return error;
    }
  };

  const _exportUserCompany = async () => {
    setIsLoadingExportCSV(true);
    await dispatch(exportUserCompany(requestParamsFilter)).then((res) => {
      const bom = new Uint8Array([0xEF, 0xBB, 0xBF]);
      const url = URL.createObjectURL(new Blob([bom, res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `company-users-${moment().format('YYYYMMDDHms')}.csv`);
      document.body.appendChild(link);
      link.click();
    });

    setIsLoadingExportCSV(false);
  };

  const _handlePageClick = (event) => {
    _getUserCompanyPaginate(event.selected + 1, requestParamsFilter);
  };

  const _onSubmitFilterForm = (form) => {
    if (isLoading) {
      return;
    }
    setRemountComponent(Math.random());
    setRequestParamsFilter(form);
    _getUserCompanyPaginate(1, form);
  };

  const _onAddNewsClickHandle = (currentUserId) => {
    setIsAddNewsModalShow(true);
    setUserId(currentUserId);
  };

  const _onAddPointClickHandle = (currentUserId) => {
    setIsAddPointModalShow(true);
    setUserId(currentUserId);
  };

  const _onDeleteUser = (userCompany) => {
    if (userCompany?.can_delete_user) {
      setIsDeleteUserModalShow(true);
      setUserId(userCompany.user_id);
    } else {
      setIsCantDeleteUserModalShow(true);
    }
  };

  return (
    <div className="user-list-inner">
      <NewsAddModal
        isAddNewsModalShow={isAddNewsModalShow}
        setIsAddNewsModalShow={setIsAddNewsModalShow}
        userId={userId}
      />
      <PointAddModal
        isAddPointModalShow={isAddPointModalShow}
        setIsAddPointModalShow={setIsAddPointModalShow}
        userId={userId}
      />
      <DeleteUserModal
        isDeleteUserModalShow={isDeleteUserModalShow}
        setIsDeleteUserModalShow={setIsDeleteUserModalShow}
        userId={userId}
        setShowToastError={setShowToastError}
        setShowToastSuccess={setShowToastSuccess}
        getUserPaginate={_getUserCompanyPaginate}
      />
      <CantDeleteUserModal
        isCantDeleteUserModalShow={isCantDeleteUserModalShow}
        setIsCantDeleteUserModalShow={setIsCantDeleteUserModalShow}
      />
      <UserCompanySearchBar
        categoryData={store.categories}
        prefectureData={store.prefectures}
        onSubmitFilterForm={_onSubmitFilterForm}
      />
      <div className="mb-4 text-end">
        <button
          type="button"
          className="btn btn-outline-primary rounded-pill admin-btn-green"
          onClick={_exportUserCompany}
          disabled={isLoading || !store.usersCompany?.total || isLoadingExportCSV}
        >
          {isLoadingExportCSV && (
            <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className="me-2" />
          )}
          <strong>CSVダウンロード</strong>
        </button>
      </div>

      <div className="table-responsive">
        <table className="table table-lg table-hover admin-table-custom-1">
          {(!isLoading && store.usersCompany?.total < 1) && (
            <caption className="text-center py-5 h5">見つかりません。</caption>
          )}
          <thead className="border-top border-2">
            <tr>
              <th width="5%">ID</th>
              <th width="40%">会社名</th>
              <th width="10%">担当者名</th>
              <th width="25%">ジャンル</th>
              <th width="5%">保有pt</th>
              <th width="5%">使用累計pt</th>
              <th width="10%">&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            {isLoading && [...Array(20)].map((e, i) => (
              <tr key={`${i.toString()}`}>
                <td>
                  <Skeleton height={17} />
                </td>
                <td>
                  <Skeleton height={17} />
                </td>
                <td>
                  <Skeleton height={17} />
                </td>
                <td>
                  <Skeleton height={17} />
                </td>
                <td>
                  <Skeleton height={17} />
                </td>
                <td>
                  <Skeleton height={17} />
                </td>
                <td>
                  <Skeleton height={17} />
                </td>
              </tr>
            ))}

            {(!isLoading && store.usersCompany?.data)
              && store.usersCompany.data.map((item) => (
                <UserCompanyRow
                  key={item.user_id}
                  itemRow={item}
                  onAddNewsClick={() => _onAddNewsClickHandle(item.user_id)}
                  onAddPointClick={() => _onAddPointClickHandle(item.user_id)}
                  onDeleteUser={() => _onDeleteUser(item)}
                />
              ))}
          </tbody>
        </table>
      </div>

      {store.usersCompany?.last_page > 1 && (
        <div className="d-flex justify-content-center my-5">
          <Pagination
            className="pagination admin-pagination-custom-1"
            key={remountComponent}
            pageCount={store.usersCompany?.last_page}
            onPageChange={_handlePageClick}
          />
        </div>
      )}
      <ToastMessage
        isShow={showToastError}
        onClose={() => {
          setShowToastError(false);
        }}
      />
      <CustomBottomToRightToast msg="更新されました" showToast={showToastSuccess} setShowToast={setShowToastSuccess} />
    </div>
  );
}

export default UserCompanyList;
