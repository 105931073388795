import React from 'react';
import PropTypes from 'prop-types';

function ProjectInfo({ project }) {
  return (
    <section className="project-info">
      <div className="row mb-4">
        <div className="col-sm-6 pb-4">
          <div className="d-flex flex-wrap form-control border-0 bg-overlay p-4">
            <div className="flex-fill">プロジェクト予算</div>
            <div className="flex-fill"><strong>{project.budget} pt</strong></div>
          </div>
        </div>
        <div className="col-sm-6 pb-4">
          <div className="d-flex flex-wrap form-control border-0 bg-overlay light-pink p-4">
            <div className="flex-fill">消費したポイント数</div>
            <div className="flex-fill"><strong>{project.total_amount} pt</strong></div>
          </div>
        </div>
      </div>
      <div className="row mb-4">
        <div className="col-sm-6 col-md-3 pb-4">
          <div className="d-flex flex-wrap form-control border-0 bg-overlay light-pink p-4">
            <div className="flex-fill">採用人数</div>
            <div className="flex-fill"><strong>{project.total_talkroom_of_hires} 人</strong></div>
          </div>
        </div>
        <div className="col-sm-6 col-md-3 pb-4">
          <div className="d-flex flex-wrap form-control border-0 bg-overlay light-pink p-4">
            <div className="flex-fill">現在進行中の人数</div>
            <div className="flex-fill"><strong>{project.total_talkroom_in_progress} 人</strong></div>
          </div>
        </div>
        <div className="col-sm-6 col-md-3 pb-4">
          <div className="d-flex flex-wrap form-control border-0 bg-overlay light-pink p-4">
            <div className="flex-fill">取引キャンセル数の人数</div>
            <div className="flex-fill"><strong>{project.total_talkroom_transaction_cancellations} 回</strong></div>
          </div>
        </div>
        <div className="col-sm-6 col-md-3 pb-4">
          <div className="d-flex flex-wrap form-control border-0 bg-overlay light-pink p-4">
            <div className="flex-fill">不採用の人数</div>
            <div className="flex-fill"><strong>{project.total_talkroom_rejections} 人</strong></div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-6 col-md-3 pb-3">
          <div className="label form-control border-0 bg-overlay px-3">プロジェクト名</div>
        </div>
        <div className="col-sm-6 col-md-9 pb-3">
          <div className="info form-control border-0">{project.project_title}</div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-6">
          <div className="row">
            <div className="col-sm-6 pb-3">
              <div className="label form-control border-0 bg-overlay px-3">納品日</div>
            </div>
            <div className="col-sm-6 pb-3">
              <div className="info form-control border-0">{project.delivery_at}</div>
            </div>
          </div>
        </div>
        <div className="col-sm-6">
          <div className="row">
            <div className="col-sm-6 pb-3">
              <div className="label form-control border-0 bg-overlay px-3">区分</div>
            </div>
            <div className="col-sm-6 pb-3">
              <div className="info form-control border-0">{project.type_name}</div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-6">
          <div className="row">
            <div className="col-sm-6 pb-3">
              <div className="label form-control border-0 bg-overlay px-3">タイプ</div>
            </div>
            <div className="col-sm-6 pb-3">
              <div className="info form-control border-0">{project.project_type_text}</div>
            </div>
          </div>
        </div>
        <div className="col-sm-6">
          <div className="row">
            <div className="col-sm-6 pb-3">
              <div className="label form-control border-0 bg-overlay px-3">カテゴリ</div>
            </div>
            <div className="col-sm-6 pb-3">
              <div className="info form-control border-0">{project.category_name}</div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-6">
          <div className="row">
            <div className="col-sm-6 pb-3">
              <div className="label form-control border-0 bg-overlay px-3">フォロワー数条件</div>
            </div>
            <div className="col-sm-6 pb-3">
              <div className="info form-control border-0">{project.follower_min}名以上</div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-6">
          <div className="row">
            <div className="col-sm-6 pb-3">
              <div className="label form-control border-0 bg-overlay px-3">募集予定人数</div>
            </div>
            <div className="col-sm-6 pb-3">
              <div className="info form-control border-0">{project.recruits_number}人</div>
            </div>
          </div>
        </div>
        <div className="col-sm-6">
          <div className="row">
            <div className="col-sm-6 pb-3">
              <div className="label form-control border-0 bg-overlay px-3">ギフティング</div>
            </div>
            <div className="col-sm-6 pb-3">
              <div className="info form-control border-0">{project.gifting}</div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-6 col-md-3 pb-3">
          <div className="label form-control border-0 bg-overlay px-3">エリア</div>
        </div>
        <div className="col-sm-6 col-md-9 pb-3">
          <div className="info form-control border-0">{project.area_text}</div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-6 col-md-3 pb-3">
          <div className="label form-control border-0 bg-overlay px-3">内容</div>
        </div>
        <div className="col-sm-6 col-md-9 pb-3">
          <div className="info form-control border-0">{project.project_contents}</div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-6 col-md-3 pb-3">
          <div className="label form-control border-0 bg-overlay px-3">URL</div>
        </div>
        <div className="col-sm-6 col-md-9 pb-3">
          <div className="info form-control border-0">{project.project_url}</div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-6 col-md-3 pb-3">
          <div className="label form-control border-0 bg-overlay px-3">ハッシュタグ</div>
        </div>
        <div className="col-sm-6 col-md-9 pb-3">
          <div className="form-control border-0 hashtags">
            {project.hashtag.map((item) => {
              return (
                <span className="d-inline-block bg-overlay px-3 py-1" key={item}>{item}</span>
              );
            })}
          </div>
        </div>
      </div>
      {project?.user_type == 1 && (
        <div className="row">
          <div className="col-sm-6 col-md-3 pb-3">
            <div className="label form-control border-0 bg-overlay px-3">支援するOTASUKE</div>
          </div>
          <div className="col-sm-6 col-md-9 pb-3">
            <div className="info form-control border-0">{project.npo_name}</div>
          </div>
        </div>
      )}
      <div className="row">
        <div className="col-sm-6 col-md-3 pb-3">
          <div className="label form-control border-0 bg-overlay px-3">特記事項</div>
        </div>
        <div className="col-sm-6 col-md-9 pb-3">
          <div className="info form-control border-0">{project.notes}</div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-6 col-md-3 pb-3">
          <div className="label form-control border-0 bg-overlay px-3">ギフティング注意事項</div>
        </div>
        <div className="col-sm-6 col-md-9 pb-3">
          <div className="info form-control border-0">{project.gifting_notes}</div>
        </div>
      </div>
    </section>
  );
}

ProjectInfo.propTypes = {
  project: PropTypes.object
};

ProjectInfo.defaultProps = {
  project: null
};
export default ProjectInfo;
