import { redirectMemberData } from 'data/redirectMemberData';
import { CATEGORY_OTHERS_ID } from 'redux/client/category_global';
import moment from 'moment';
import 'moment-timezone';
import * as ROUTES from 'constants/routes';

moment.tz.setDefault('Asia/Tokyo');

/* eslint-disable array-callback-return */
export const setFormikErrors = (errorObject, setErrorFunction) => {
  const errors = Object.keys(errorObject);
  errors.map((item) => {
    setErrorFunction(item, errorObject[item].join('\r\n'));
  });
};

export const findArray = (key, array) => {
  if (!key?.toString()) {
    return null;
  }
  return array.find((element) => element.key == key);
};

export const whereInArrayConditions = (conditions, array) => {
  return array.filter((element) => conditions.includes(element.key.toString()));
};

export const inArrayCategory = (array, needle, categoryText = '') => {
  const data = [];
  array.forEach((element) => {
    if (element.key === CATEGORY_OTHERS_ID && needle.includes(CATEGORY_OTHERS_ID.toString())) {
      if (categoryText) {
        data.push(categoryText);
      }
    } else if (needle.includes(element.key.toString())) {
      data.push(element.value);
    }
  });

  return data || [];
};

export const removeCategoryOthers = (categories) => {
  return categories.filter((v) => v.key !== CATEGORY_OTHERS_ID);
};

export const getArrayValueFromKeyPair = (array, needle) => {
  const data = [];
  array.forEach((element) => {
    if (element.key == needle) {
      data.push(element.value);
    }
  });
  return data;
};

export const getArrayValueFromArrayKeyPair = (array, needleArr) => {
  const data = [];
  needleArr.forEach((element) => {
    if (findArray(element, array)) {
      data.push(findArray(element, array)?.value);
    }
  });
  return data;
};

export const getYesterday = new Date(new Date().valueOf() - 1000 * 60 * 60 * 24).toLocaleDateString();

function dec2hex(dec) {
  return dec.toString(16).padStart(2, '0');
}

export const generateId = (len) => {
  const arr = new Uint8Array((len || 40) / 2);
  window.crypto.getRandomValues(arr);
  return Array.from(arr, dec2hex).join('');
};

export const formatMoney = (number) => {
  const numberFormat = new Intl.NumberFormat({ maximumSignificantDigits: 3 }).format(number);

  return numberFormat;
};

export const toMemberPage = (userType, path) => {
  if (userType === undefined || userType === '') {
    return ROUTES.SIGN_IN;
  }

  const userTypeName = redirectMemberData[userType] || redirectMemberData.default;
  if (!userTypeName) {
    return ROUTES.HOME;
  }

  return `${redirectMemberData[userType]}/${path}`;
};

export const isSNSRegistration = (userAuth) => {
  if (!userAuth) {
    return false;
  }

  const socialIds = [
    userAuth?.facebook_id,
    userAuth?.google_id,
    userAuth?.twitter_id,
    userAuth?.instagram_id
  ];
  return socialIds.filter(Boolean).length > 0;
};

export const getFormattedDate = (date, dateFormat = 'YYYY.MM.DD') => {
  return moment(date).format(dateFormat);
};

export const getFileNameFromUrl = (url) => {
  return url.substring(url.lastIndexOf('/') + 1);
};

export const toAdminPageDetail = (path) => {
  const result = path.split('/');
  return `${result[1]}`;
};

export function objectFlip(obj) {
  return Object.entries(obj).reduce((acc, [key, value]) => {
    acc[value] = key;
    return acc;
  }, {});
}

export const checkNumberParam = (param) => {
  if (Number(param)) {
    return param;
  }
  return false;
};

export const scrollToErrorField = (className = '.is-invalid') => {
  setTimeout(() => {
    const el = document.querySelector(className);
    if (el) {
      el.scrollIntoView({ behavior: 'smooth', block: 'center' });
      el.focus({ preventScroll: true });
    } else if (el == null) {
      const coverImageElement = document.querySelector('.invalid-feedback.cover_image');
      const areaIdsErrorElement = document.querySelector('.area_ids').nextSibling;
      if (coverImageElement) {
        coverImageElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
      } else if (areaIdsErrorElement) {
        areaIdsErrorElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
  }, 10);
};
