import { ADMIN_UPDATE_TAG_SUCCESS, GET_LIST_ADMIN_TAG } from './tagAction';

const initialState = {
  tags: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_LIST_ADMIN_TAG: {
      return {
        ...state,
        tags: action.payload.data
      };
    }

    case ADMIN_UPDATE_TAG_SUCCESS: {
      const newTagData = state.tags.data.map((tag) => {
        if (tag.id == action.payload.id) {
          return {
            ...tag,
            name: action.payload.name
          };
        }
        return tag;
      });

      return {
        ...state,
        tags: {
          ...state.tags,
          data: newTagData
        }
      };
    }

    default:
      return state;
  }
};
