import API from 'axios/API';

export const GET_ALL_TYPE_SUCCESS = 'GET_ALL_TYPE_SUCCESS';

export const getAllType = () => (dispatch) => {
  return API({
    method: 'GET',
    url: 'type'
  }).then((res) => {
    dispatch({
      type: GET_ALL_TYPE_SUCCESS,
      payload: res
    });
  });
};
