import React from 'react';
import PropTypes from 'prop-types';

function UserInfo({ userInfo }) {
  return (
    <section className="user-info">
      <div className="row py-2">
        <div className="col-md-12 col-sm-12">
          <div className="row">
            <div className="col-md-3 col-sm-3">
              <div className="label form-control border-0 bg-overlay px-3">団体名</div>
            </div>
            <div className="col-md-9 col-sm-9">
              <div className="info form-control border-0">{userInfo?.npo_name}</div>
            </div>
          </div>
        </div>
      </div>
      <div className="row py-2">
        <div className="col-md-6 col-sm-6">
          <div className="row">
            <div className="col-md-6 col-sm-6">
              <div className="label form-control border-0 bg-overlay px-3">ID</div>
            </div>

            <div className="col-md-6 col-sm-6">
              <div className="info form-control border-0">{userInfo?.user_id}</div>
            </div>
          </div>
        </div>

        <div className="col-md-6 col-sm-6">
          <div className="row">
            <div className="col-md-6 col-sm-6">
              <div className="label form-control border-0 bg-overlay px-3">メールアドレス</div>
            </div>

            <div className="col-md-6 col-sm-6">
              <div className="info form-control border-0">{userInfo?.email}</div>
            </div>
          </div>
        </div>
      </div>

      <div className="row py-2">
        <div className="col-md-6 col-sm-6">
          <div className="row">
            <div className="col-md-6 col-sm-6">
              <div className="label form-control border-0 bg-overlay px-3">氏名</div>
            </div>

            <div className="col-md-6 col-sm-6">
              <div className="info form-control border-0">{userInfo?.name}</div>
            </div>
          </div>
        </div>

        <div className="col-md-6 col-sm-6">
          <div className="row">
            <div className="col-md-6 col-sm-6">
              <div className="label form-control border-0 bg-overlay px-3">氏名フリガナ</div>
            </div>

            <div className="col-md-6 col-sm-6">
              <div className="info form-control border-0">{userInfo?.name_kana}</div>
            </div>
          </div>
        </div>
      </div>

      <div className="row py-2">
        <div className="col-md-6 col-sm-6">
          <div className="row">
            <div className="col-md-6 col-sm-6">
              <div className="label form-control border-0 bg-overlay px-3">電話番号</div>
            </div>

            <div className="col-md-6 col-sm-6">
              <div className="info form-control border-0">{userInfo?.phone}</div>
            </div>
          </div>
        </div>

        <div className="col-md-6 col-sm-6">
          <div className="row">
            <div className="col-md-6 col-sm-6">
              <div className="label form-control border-0 bg-overlay px-3">URL</div>
            </div>

            <div className="col-md-6 col-sm-6">
              <div className="info form-control border-0">{userInfo?.url}</div>
            </div>
          </div>
        </div>
      </div>

      <div className="row py-2">
        <div className="col-md-6 col-sm-6">
          <div className="row">
            <div className="col-md-6 col-sm-6">
              <div className="label form-control border-0 bg-overlay px-3">部署名</div>
            </div>

            <div className="col-md-6 col-sm-6">
              <div className="info form-control border-0">{userInfo?.department}</div>
            </div>
          </div>
        </div>

        <div className="col-md-6 col-sm-6">
          <div className="row">
            <div className="col-md-6 col-sm-6">
              <div className="label form-control border-0 bg-overlay px-3">役職名</div>
            </div>

            <div className="col-md-6 col-sm-6">
              <div className="info form-control border-0">{userInfo?.position}</div>
            </div>
          </div>
        </div>
      </div>

      <div className="row py-2">
        <div className="col-md-6 col-12">
          <div className="row">
            <div className="col-md-6 col-sm-6">
              <div className="label form-control border-0 bg-overlay px-3">郵便番号</div>
            </div>

            <div className="col-md-6 col-sm-6">
              <div className="info form-control border-0">{userInfo?.postcode}</div>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-12">
          <div className="row">
            <div className="col-md-6 col-sm-6">
              <div className="label form-control border-0 bg-overlay px-3">都道府県</div>
            </div>

            <div className="col-md-6 col-sm-6">
              <div className="info form-control border-0">
                {userInfo?.prefecture}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row py-2">
        <div className="col-md-6 col-sm-6">
          <div className="row">
            <div className="col-md-6 col-sm-6">
              <div className="label form-control border-0 bg-overlay px-3">住所</div>
            </div>

            <div className="col-md-6 col-sm-6">
              <div className="info form-control border-0">{userInfo?.address}</div>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-sm-6">
          <div className="row">
            <div className="col-md-6 col-sm-6">
              <div className="label form-control border-0 bg-overlay px-3">利用動機</div>
            </div>

            <div className="col-md-6 col-sm-6">
              <div className="info form-control border-0">{userInfo?.npo_purpose_text}</div>
            </div>
          </div>
        </div>
      </div>

      <div className="row py-2">
        <div className="col-md-12 col-12">
          <div className="row">
            <div className="col-md-3 col-sm-3">
              <div className="label form-control border-0 bg-overlay px-3">OTASUKEカテゴリ</div>
            </div>

            <div className="col-md-9 col-sm-9">
              <div className="info form-control border-0">{userInfo?.npo_category}</div>
            </div>
          </div>
        </div>
      </div>

      <div className="row py-2">
        <div className="col-12">
          <div className="row">
            <div className="col-md-3 col-sm-3">
              <div className="label form-control border-0 bg-overlay px-3">ジャンル</div>
            </div>

            <div className="col-md-9 col-sm-9">
              <div className="info form-control border-0">
                <ul className="list-unstyled">
                  {userInfo?.category?.map((item) => (
                    <li key={`category_${item}`}>{item}</li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

UserInfo.propTypes = {
  userInfo: PropTypes.object
};

UserInfo.defaultProps = {
  userInfo: null
};
export default UserInfo;
