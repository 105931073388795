/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/forbid-prop-types */
import { ErrorMessage } from 'formik';
import PropType from 'prop-types';
import React from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { Form, Tooltip } from 'react-bootstrap';

function CustomInput({ field, form: { touched, errors }, label, inputRef, ...props }) {
  return (
    <Form.Group>
      {label && (
        <Form.Label htmlFor={field.name}>
          {label[0]}
          {label[1] && <span className="label-required">{label[1]}</span>}
          {label[3] ? (
            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{label[3]}</Tooltip>}>
              <span className="d-inline-block">{label[2] && <span className="label-icon">{label[2]}</span>}</span>
            </OverlayTrigger>
          ) : (
            <span className="d-inline-block">{label[2] && <span className="label-icon">{label[2]}</span>}</span>
          )}
        </Form.Label>
      )}

      <Form.Control
        type="text"
        id={field.name}
        ref={inputRef}
        isInvalid={touched[field.name] && errors[field.name]}
        {...field}
        {...props}
      />
      <ErrorMessage name={field.name} component="div" className="invalid-feedback" />
    </Form.Group>
  );
}

CustomInput.defaultProps = {
  inputRef: undefined
};

CustomInput.propTypes = {
  label: PropType.array.isRequired,
  field: PropType.object.isRequired,
  form: PropType.object.isRequired,
  inputRef: PropType.oneOfType([
    PropType.func,
    PropType.shape({
      current: PropType.instanceOf(Element)
    })
  ])
};

export default CustomInput;
