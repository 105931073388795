import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Formik } from 'formik';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Button, Spinner } from 'react-bootstrap';

import { checkUserAuthToken, USER_TYPE_INFLUENCER, validatorSignUpUserInfluencerStep2 } from 'redux/client/auth';
import { getAllPrefectures } from 'redux/client/category_global';
import {
  setFormikErrors,
  scrollToErrorField,
  isSNSRegistration,
  whereInArrayConditions
} from 'helpers/utils';
import constants from 'constants/constants';
import * as ROUTES from 'constants/routes';
import LoadingOverlay from 'components/loading/LoadingOverlay';
import { ResponseStatus } from 'constants';
import { getAllTags } from 'redux/client/tag';
import SignUpInfluencerFormStep2 from './components/SignUpInfluencerFormStep2';
import { SignUpInfluencerStep2Validation } from './SignUpInfluencerStep2Validation';

function SignUpInfluencerStep2() {
  const confirmSignUpLocation = useLocation();
  const navigate = useNavigate();
  const location = useLocation();
  const userState = location.state || null;
  const { userAuthToken, tiktokAccessToken } = useParams();

  const store = useSelector((state) => ({
    userAuth: state.auth.userAuth,
    prefectures: state.categoryGlobal.prefecturesAndForeign,
    tags: state.tag.tags
  }));
  const dispatch = useDispatch();

  useEffect(() => {
    const influencerDataForm = localStorage.getItem(constants.OLD_DATA_INFLUENCER_LOCAL_STORAGE);
    if (influencerDataForm) {
      setInitFormikValues(JSON.parse(influencerDataForm));
    }

    if (confirmSignUpLocation.state !== null) {
      setInitFormikValues(confirmSignUpLocation.state);
    }

    initialPage();
  }, []);

  const [initFormikValues, setInitFormikValues] = useState({
    password: userState?.password,
    first_name: userState?.first_name,
    family_name: userState?.family_name,
    first_name_kana: userState?.first_name_kana,
    family_name_kana: userState?.family_name_kana,
    phone: userState?.phone,
    birthday: userState?.birthday,
    gender: userState?.gender,
    prefectures: [],
    tag_ids: [],
    pr: '',
    // Tiktok info
    tiktok_info: '',
    tiktok_auth_access_token: '',
    is_sns_registration: false
  });

  const initialPage = async () => {
    try {
      const checkUserAuthTokenPromise = dispatch(
        checkUserAuthToken({
          token: userAuthToken,
          user_type: USER_TYPE_INFLUENCER
        })
      ).catch(() => {
        navigate(ROUTES.ERROR_404_PAGE);
      });

      const getAllPrefecturesPromise = store.prefectures.length === 0 ? dispatch(getAllPrefectures()) : null;
      const getAllTagPromise = store.tags.length === 0 ? dispatch(getAllTags()) : null;
      await Promise.all([checkUserAuthTokenPromise, getAllPrefecturesPromise, getAllTagPromise]);

      return true;
    } catch (error) {
      return error;
    }
  };

  const onFormSubmit = async (form, formikHelpers) => {
    const requestForm = Object.assign(form, {
      tagList: whereInArrayConditions(form.tag_ids, store.tags),
      prefecturesValue: whereInArrayConditions(form.prefectures, store.prefectures)
    });

    const resp = await dispatch(validatorSignUpUserInfluencerStep2(requestForm));
    if (resp && resp.status == ResponseStatus.HTTP_UNPROCESSABLE_ENTITY) {
      setFormikErrors(resp.data.errors, formikHelpers.setFieldError);
      scrollToErrorField();
    }
    // If check validate not OK
    if (resp.status != ResponseStatus.HTTP_OK) {
      navigate(resp.status);
    } else {
      const requestAll = Object.assign(form);
      navigate(ROUTES.SIGN_UP_INFLUENCER_CONFIRM, {
        state: requestAll
      });
    }
  };

  const _goBackToStep1 = () => {
    let goBackUrl = `${ROUTES.SIGN_UP_INFLUENCER_STEP_1}/${userAuthToken}`;
    if (tiktokAccessToken) {
      goBackUrl += `/${tiktokAccessToken}`;
    }

    navigate(goBackUrl, {
      state: userState
    });
  };

  return (
    <div className="signup-confirm-page">
      {!store.userAuth ? (
        <LoadingOverlay />
      ) : (
        <div className="container main-container">
          <div className="row justify-content-center">
            <div className="col-lg-10 col-11 form__wrapper">
              <Formik
                initialValues={({ ...initFormikValues, is_sns_registration: isSNSRegistration(store.userAuth) })}
                enableReinitialize
                validationSchema={SignUpInfluencerStep2Validation}
                onSubmit={onFormSubmit}
              >
                {({ handleSubmit, isSubmitting }) => (
                  <Form className="form-style-1">
                    <h3 className="title-form">本会員登録</h3>

                    <div className="mb-5">
                      <SignUpInfluencerFormStep2
                        prefectures={store.prefectures}
                        tags={store.tags}
                      />
                    </div>

                    <div className="row mt-btn-cf-form text-center">
                      <div className="col-lg-10 offset-lg-1 col-12 col-ct-12">
                        <div className="row">
                          <div className="col-lg-6 col-md-6 col-12 col-child-ct-12">
                            <Button
                              variant="redirect common-outline-btn my-2"
                              onClick={_goBackToStep1}
                            >
                              <span className="sr-only">戻る</span>
                            </Button>
                          </div>

                          <div className="col-lg-6 col-md-6 col-12 col-child-ct-12">
                            <Button
                              variant="auth common-btn my-2"
                              onClick={handleSubmit}
                              disabled={isSubmitting}
                            >
                              {isSubmitting && (
                              <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                                className="me-2"
                              />
                              )}
                              <span className="sr-only">確認</span>
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default SignUpInfluencerStep2;
