/* eslint-disable max-len */
import React, { useEffect, useMemo, useState } from 'react';

import { LoadingContext } from 'hooks/LoadingContext';
import IconGuide1 from 'assets/svg/invitations/shape-icon_guide-1.svg';
import IconGuide2 from 'assets/svg/invitations/shape-icon_guide-2.svg';
import LineIcon from 'assets/svg/invitations/line-ic.svg';
import TwitterXIcon from 'assets/svg/invitations/twitter-x-ic.svg';
import ShareCodeIcon from 'assets/svg/invitations/share-code-ic.svg';
import { getInviteCode, createInviteCode } from 'redux/client/invitation';
import { useDispatch, useSelector } from 'react-redux';
import Skeleton from 'react-loading-skeleton';
import { LineShareButton, TwitterShareButton } from 'react-share';
import * as ROUTES from 'constants/routes';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

function InvitationInfluencer() {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(true);

  const store = useSelector((state) => ({
    userInfluencer: state.invitation.userInfluencer
  }));

  const [toolTipMessage, setToolTipMessage] = useState('クリップボードにコピー');

  useEffect(() => {
    initialPage();
  }, []);

  const initialPage = async () => {
    try {
      setIsLoading(true);

      const res = await dispatch(getInviteCode());
      if (!res.data.invite_code) {
        await dispatch(createInviteCode());
      }

      setIsLoading(false);
      return true;
    } catch (error) {
      setIsLoading(false);
      return error;
    }
  };

  const urlRegisterCompany = `${process.env.REACT_APP_APP_WEB + ROUTES.REGISTER_COMPANY}?invite_code=${store.userInfluencer?.invite_code}`;
  const titleLine = `『ENMA』でTikTokerにPR依頼をしよう！\n招待コード {${store.userInfluencer?.invite_code}}`;
  const title = `TikTok専門インフルエンサーマッチングサイト！\n『ENMA』でTikTokerにPR依頼をしよう！\n招待コードを使って会員登録しポイント購入すると、追加でポイントプレゼント🎉\n招待コード：${store.userInfluencer?.invite_code}\n※本紹介プログラムは企業ユーザーの登録時にのみ適用となります\n会員登録はこちら:`;
  const handleShareButton = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          // eslint-disable-next-line object-shorthand
          title: title,
          text: title,
          url: urlRegisterCompany
        });
      } catch (err) {
        console.error('Share failed:', err.message);
      }
    }
  };

  const handleCopyToClipboard = async (text) => {
    if (!text) return;
    await navigator.clipboard.writeText(text).then(() => {
      setToolTipMessage(`コピーされました: ${text}`);
    }, () => {
      setToolTipMessage('クリップボードにコピー');
    });
  };

  const value = useMemo(() => [isLoading], [isLoading]);

  return (
    <LoadingContext.Provider value={value}>
      <div className="page-project my-projects">
        <div className="client-container">
          <div className="invitation-page">
            <div className="position-relative">
              <h2 className="page-title">招待プログラム</h2>
              <div className="client-cpanel__page-title-after">
                <span className="shadow-text">Invitation</span>
              </div>
            </div>
            <div className="invitation-content mt-4">
              <p className="title-guide">あなたの招待コードから、ENMAに会員登録した企業が初回ポイントを購入すると、あなたに<span className="text-price">10,000円分</span>のポイント、企業に<span className="text-price">500円分</span>のポイントが付与されます！</p>
              <div className="guide_content__box d-flex flex-wrap">
                <div className="col-12 col-lg-6 guide-item guide-item__first">
                  <div className="guide-box guide-first box-style-1__border d-flex align-items-center justify-content-start">
                    <img src={IconGuide1} className="shape-guide" alt="" />
                    <div className="guide-text">
                      <p>招待コードを拡散する</p>
                      <p>あなたの招待コードをSNSを使って拡散しましょう！ <br />
                        招待数は無制限なのでたくさんの企業に登録してもらいましょう
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-lg-6 guide-item guide-item__second">
                  <div className="guide-box guide-second box-style-1__border d-flex align-items-center justify-content-start">
                    <img src={IconGuide2} className="shape-guide" alt="" />
                    <div className="guide-text">
                      <p>ポイントゲット！</p>
                      <p>招待コードを入力してENMAに登録した企業が、初回ポイント購入完了すると、お互いにポイントが付与されます！
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              {isLoading ? (
                <div className="mt-4">
                  <Skeleton height={100} />
                </div>
              ) : (
                <div className="code-content d-flex flex-wrap align-items-center">
                  <div className="group-content group-content__invitation d-flex flex-wrap align-items-center">
                    <p className="col-12 col-md-auto label-code">あなたの招待コード</p>
                    <div
                      className="col-12 col-md-auto show-code d-flex align-items-bottom justify-content-between"
                      onClick={() => handleCopyToClipboard(store.userInfluencer?.invite_code)}
                      role="presentation"
                    >
                      <p className="input-code">{store.userInfluencer?.invite_code}</p>
                      <OverlayTrigger
                        key="top"
                        placement="top"
                        overlay={(
                          <Tooltip id="tooltip-top" className="amazing-tooltip">
                            {toolTipMessage}
                          </Tooltip>
                        )}
                      >
                        <button
                          type="button"
                          className="btn btn-copy_code"
                          onClick={() => handleCopyToClipboard(store.userInfluencer?.invite_code)}
                        >
                          コピーする
                        </button>
                      </OverlayTrigger>
                    </div>
                  </div>
                  <div className="group-content group-content__sns d-flex flex-wrap align-items-center">
                    <p className="col-12 col-md-auto label-sns">SNSでシェアする</p>
                    <div className="col-12 col-md-auto group-icon-sns d-flex align-items-center">
                      <div className="icon-sns">
                        <LineShareButton
                          url={urlRegisterCompany}
                          title={titleLine}
                        >
                          <img src={LineIcon} alt="" />
                        </LineShareButton>
                      </div>
                      <div className="icon-sns">
                        <TwitterShareButton
                          url={urlRegisterCompany}
                          title={title}
                        >
                          <img src={TwitterXIcon} alt="" />
                        </TwitterShareButton>
                      </div>
                      <div className="icon-sns" role="presentation" onClick={handleShareButton}>
                        <img src={ShareCodeIcon} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div className="invitation-description hospitality-method">
                <h3 className="title">招待方法</h3>
                <p className="note">
                  まだENMAに登録していない企業に、<b>あなたの招待コード</b> を伝えます。<br />
                  企業がENMAの会員登録時に、あなたの招待コードを使って登録し、初回のポイント購入が完了すると、あなたと登録した企業にポイントが付与されます。
                </p>
              </div>
              <div className="invitation-description about-points">
                <h3 className="title">ポイントについて</h3>
                <p className="note">
                  ※ 招待した方および招待された方がもらえるポイントは、予告なく変更される場合があります。<br />
                  ※ 付与されたポイントの有効期限は180日です。<br />
                  ※ 招待数に制限はありません。<br />
                  ※ ポイントの付与は、招待された方が初回のポイント購入完了時となります。<br />
                  ※ 本紹介プログラムは企業ユーザーの紹介に向けたものであり、ポイント付与対象は紹介を受けて登録、購入をした企業ユーザーのみとなります。<br />
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </LoadingContext.Provider>
  );
}


export default InvitationInfluencer;
