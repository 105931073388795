import React, { useState } from 'react';
import { Modal, Spinner } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { updateStatusUserCompanyIdentity, getUserCompanyIdentity } from 'redux/admin/user';
import * as Yup from 'yup';
import * as ja from 'helpers/yup-locale-ja';
import { Field, Formik, ErrorMessage } from 'formik';
import PropTypes from 'prop-types';
import ToastMessage from 'components/admin/common/ToastMessage';
import { getFormattedDate, getFileNameFromUrl } from 'helpers/utils';
import { ResponseStatus } from 'constants';
import { tiktokInfoStatusData } from 'data/tiktokInfoStatusData';

const STATUS_COMPANY_IDENTITY_APPROVED = '1';
const STATUS_COMPANY_IDENTITY_REJECT = '2';

Yup.setLocale(ja.suggestive);
function UserCompanyIdentityModal({ isModalShow, setIsModalShow, data, requestParams }) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const dispatch = useDispatch();

  let FormSchema = '';
  if (data.identity_verify?.status == STATUS_COMPANY_IDENTITY_APPROVED) {
    FormSchema = Yup.object().shape({
      status: Yup.string().test('status', '選んでください。', (val) => val == STATUS_COMPANY_IDENTITY_REJECT),
      comment: Yup.string().when('status', {
        is: (status) => status === STATUS_COMPANY_IDENTITY_REJECT,
        then: Yup.string().required()
      })
    });
  } else {
    FormSchema = Yup.object().shape({
      status: Yup.string().required(),
      comment: Yup.string().when('status', {
        is: (status) => status === STATUS_COMPANY_IDENTITY_REJECT,
        then: Yup.string().required()
      })
    });
  }

  const initFormikValues = {
    id_company_identity: data.identity_verify?.id ?? '',
    status: data.identity_verify?.status ?? '',
    comment: ''
  };

  const onSubmitForm = async (form) => {
    setIsSubmitting(true);
    try {
      const res = await dispatch(updateStatusUserCompanyIdentity(form));
      if (res.status === ResponseStatus.HTTP_OK) {
        await dispatch(getUserCompanyIdentity(1, requestParams));
        setIsSubmitting(false);
        setIsModalShow(false);
      } else {
        setIsSubmitting(false);
        setIsModalShow(false);
        setShowToast(true);
      }
      return true;
    } catch (error) {
      setIsSubmitting(false);
      return error;
    }
  };

  return (
    <div>
      <Modal
        onHide={() => setIsModalShow(false)}
        show={isModalShow}
        size="lg"
        centered
        className="user-request-modal influencer-identity-modal"
      >
        <Modal.Header closeButton />
        <Modal.Body>
          <div className="container">
            <div className="row">
              <div className="col-12">
                <p className="header-title">登録情報</p>
                <div className="info">
                  <p>
                    <span className="label">姓</span>
                    <span className="content">{data.family_name}</span>
                  </p>
                  <p>
                    <span className="label">名</span>
                    <span className="content">{data.first_name}</span>
                  </p>
                </div>
                <div className="info">
                  <p>
                    <span className="label">生年月日</span>
                    <span className="content">{getFormattedDate(data.birthday)}</span>
                  </p>
                </div>
              </div>

              <div className="col-12">
                <p className="header-title">本人確認書類</p>
                <p>{data.identity_verify?.docs_type_text}</p>
                {data.identity_verify?.attached_1 && (
                  <p>
                    <a
                      className="hyperlink"
                      href={data.identity_verify?.link_attached_1}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {getFileNameFromUrl(data.identity_verify?.attached_1)}
                    </a>
                  </p>
                )}
                {data.identity_verify?.attached_2 && (
                  <p>
                    <a
                      className="hyperlink"
                      href={data.identity_verify?.link_attached_2}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {getFileNameFromUrl(data.identity_verify?.attached_2)}
                    </a>
                  </p>
                )}
                {data.identity_verify?.attached_3 && (
                  <p>
                    <a
                      className="hyperlink"
                      href={data.identity_verify?.link_attached_3}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {getFileNameFromUrl(data.identity_verify?.attached_3)}
                    </a>
                  </p>
                )}
              </div>
            </div>
            <Formik initialValues={initFormikValues} validationSchema={FormSchema} onSubmit={onSubmitForm}>
              {({ handleSubmit, dirty, values, isValid, errors, touched }) => (
                <form className="admin-form-custom-1 form-update">
                  <div className="row mt-4">
                    <div className="col-6">
                      <Field as="select" name="status" id="status" className="form-select rounded-3" required>
                        <option value="">選択して下さい</option>
                        {tiktokInfoStatusData.map((status) => (
                          <option key={status.id} value={status.id}>
                            {status.text}
                          </option>
                        ))}
                      </Field>
                    </div>
                    <div className="col-4 offset-sm-2 text-end">
                      <button
                        type="button"
                        className="btn admin-btn-green"
                        onClick={() => {
                          handleSubmit();
                        }}
                        disabled={!(isValid && dirty) || isSubmitting}
                      >
                        {isSubmitting && (
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            className="me-2"
                          />
                        )}
                        登録する
                      </button>
                    </div>
                    <div className="col-12 mt-3">
                      <Field
                        as="textarea"
                        rows="5"
                        name="comment"
                        id="comment"
                        className={`form-control ${!isValid && errors.comment && touched.comment ? 'is-invalid' : ''}`}
                        placeholder="入力してください "
                        disabled={values.status !== STATUS_COMPANY_IDENTITY_REJECT}
                      />
                      <ErrorMessage component="div" name="comment" className="invalid-feedback" />
                    </div>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </Modal.Body>
      </Modal>
      <ToastMessage
        isShow={showToast}
        onClose={() => {
          setShowToast(false);
        }}
      />
    </div>
  );
}

UserCompanyIdentityModal.propTypes = {
  isModalShow: PropTypes.bool.isRequired,
  setIsModalShow: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  requestParams: PropTypes.array.isRequired
};

export default UserCompanyIdentityModal;
