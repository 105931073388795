import AdminAPI from 'axios/AdminAPI';
import { ResponseStatus } from 'constants';

export const PROJECT_LIST = 'ADMIN_PROJECT_LIST';
export const PROJECT_DETAIL = 'ADMIN_PROJECT_DETAIL';
export const PROJECT_SAVE_TO_DRAFT = 'PROJECT_SAVE_TO_DRAFT';

export const getProjects = (currentPage, filterItems) => (dispatch) => {
  let urlProject = `project/list?page=${currentPage}`;

  if (filterItems?.user_type) {
    urlProject += `&user_type=${filterItems.user_type}`;
  }
  if (filterItems?.area_id?.toString()) {
    urlProject += `&area_id=${filterItems.area_id}`;
  }
  if (filterItems?.project_type?.toString()) {
    urlProject += `&project_type=${filterItems.project_type}`;
  }
  if (filterItems?.category_id?.toString()) {
    urlProject += `&category_id=${filterItems.category_id}`;
  }
  if (filterItems?.type_id?.toString()) {
    urlProject += `&type_id=${filterItems.type_id}`;
  }
  if (filterItems?.is_public) {
    urlProject += `&is_public=${filterItems.is_public}`;
  }

  return AdminAPI({
    method: 'GET',
    url: urlProject
  })
    .then((res) => {
      dispatch({
        type: PROJECT_LIST,
        payload: res
      });
      return res;
    })
    .catch((error) => {
      return error;
    });
};

export const exportProjects = (requestParams) => () => {
  let URLApi = 'project/export';
  const requestParamsStr = new URLSearchParams(requestParams).toString();
  if (requestParamsStr) {
    URLApi += `?${requestParamsStr}`;
  }

  return AdminAPI({
    method: 'GET',
    url: URLApi
  })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error;
    });
};

export const getProjectDetail = (projectId) => () => {
  const URLApi = `project/${projectId}`;

  return AdminAPI({
    method: 'GET',
    url: URLApi
  })
    .then((res) => {
      return res;
    });
};

export const saveProjectToDraft = (projectId) => (dispatch) => {
  return AdminAPI({
    method: 'PUT',
    url: 'project/save-to-draft',
    data: {
      project_id: projectId
    }
  })
    .then((res) => {
      if (res.status == ResponseStatus.HTTP_OK) {
        dispatch({
          type: PROJECT_SAVE_TO_DRAFT,
          payload: projectId
        });
      }
      return res;
    });
};

export const reportProjectCsv = (projectId) => () => {
  return AdminAPI({
    method: 'GET',
    url: `project/report/${projectId}`
  })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error;
    });
};
