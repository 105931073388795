import React, { useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import * as ROUTES from 'constants/routes';
import { toMemberPage } from 'helpers/utils';
import useSession from 'hooks/useSession';

function PurchaseComplete() {
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useSession();
  const userType = user?.user_type;
  const paymentState = location.state;

  useEffect(() => {
    if (!paymentState) {
      navigate(ROUTES.ERROR_404_PAGE);
    }
  }, []);
  return (
    <div className="client-cpanel-container">
      <div className="row page-purchase-complete pb-5 pt-5">

        <div className="col-12 total-fee text-center">
          <div className="total-fee-container">
            <div className="align-items-center justify-content-center">
              <h3 className="title-section mb-5">お支払いが完了しました！</h3>
              <div className="fee-text mb-5">
                弊社サービスをご利用いただきありがとうございます。 <br />
                ポイント履歴画面に戻り、ポイントの購入履歴をご確認ください。
              </div>
              <Link
                to={toMemberPage(userType, ROUTES.MY_POINT_PURCHASE)}
                className="btn btn-redirect common-btn mt-3 pop-navigate"
              >
                <strong>ポイント履歴に移動</strong>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PurchaseComplete;
