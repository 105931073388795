import React from 'react';
import PropTypes from 'prop-types';

function UserInfo({ userInfluencer }) {
  return (
    <section className="user-info">
      <div className="row py-2">
        <div className="col-md-6 col-sm-6">
          <div className="row">
            <div className="col-md-6 col-sm-6">
              <div className="label form-control border-0 bg-overlay px-3">ID</div>
            </div>

            <div className="col-md-6 col-sm-6">
              <div className="info form-control border-0">{userInfluencer?.user_id}</div>
            </div>
          </div>
        </div>

        <div className="col-md-6 col-sm-6">
          <div className="row">
            <div className="col-md-6 col-sm-6">
              <div className="label form-control border-0 bg-overlay px-3">メールアドレス</div>
            </div>

            <div className="col-md-6 col-sm-6">
              <div className="info form-control border-0">{userInfluencer?.email}</div>
            </div>
          </div>
        </div>
      </div>

      <div className="row py-2">
        <div className="col-md-6 col-sm-6">
          <div className="row">
            <div className="col-md-6 col-sm-6">
              <div className="label form-control border-0 bg-overlay px-3">氏名</div>
            </div>

            <div className="col-md-6 col-sm-6">
              <div className="info form-control border-0">{userInfluencer?.full_name}</div>
            </div>
          </div>
        </div>

        <div className="col-md-6 col-sm-6">
          <div className="row">
            <div className="col-md-6 col-sm-6">
              <div className="label form-control border-0 bg-overlay px-3">氏名（フリガナ）</div>
            </div>

            <div className="col-md-6 col-sm-6">
              <div className="info form-control border-0">{userInfluencer?.full_name_kana}</div>
            </div>
          </div>
        </div>
      </div>

      <div className="row py-2">
        <div className="col-md-6 col-sm-6">
          <div className="row">
            <div className="col-md-6 col-sm-6">
              <div className="label form-control border-0 bg-overlay px-3">電話番号</div>
            </div>

            <div className="col-md-6 col-sm-6">
              <div className="info form-control border-0">{userInfluencer?.phone}</div>
            </div>
          </div>
        </div>

        <div className="col-md-6 col-sm-6">
          <div className="row">
            <div className="col-md-6 col-sm-6">
              <div className="label form-control border-0 bg-overlay px-3">性別</div>
            </div>

            <div className="col-md-6 col-sm-6">
              <div className="info form-control border-0">{userInfluencer?.gender_text}</div>
            </div>
          </div>
        </div>
      </div>

      <div className="row py-2">
        <div className="col-md-6 col-sm-6">
          <div className="row">
            <div className="col-md-6 col-sm-6">
              <div className="label form-control border-0 bg-overlay px-3">生年月日</div>
            </div>

            <div className="col-md-6 col-sm-6">
              <div className="info form-control border-0">{userInfluencer?.birthday}</div>
            </div>
          </div>
        </div>

        <div className="col-md-6 col-sm-6">
          <div className="row">
            <div className="col-md-6 col-sm-6">
              <div className="label form-control border-0 bg-overlay px-3">年齢</div>
            </div>

            <div className="col-md-6 col-sm-6">
              <div className="info form-control border-0">{userInfluencer?.age}歳</div>
            </div>
          </div>
        </div>
      </div>

      <div className="row py-2">
        <div className="col-md-6 col-12">
          <div className="row">
            <div className="col-md-6 col-sm-6">
              <div className="label form-control border-0 bg-overlay px-3">活動可能地域</div>
            </div>

            <div className="col-md-6 col-sm-6">
              <div className="info form-control border-0">{userInfluencer?.region}</div>
            </div>
          </div>
        </div>
      </div>

      <div className="row py-2">
        <div className="col-md-6 col-12">
          <div className="row">
            <div className="col-md-6 col-sm-6">
              <div className="label form-control border-0 bg-overlay px-3">得意ジャンル</div>
            </div>

            <div className="col-md-6 col-sm-6">
              <div className="info form-control border-0">{userInfluencer?.category_text}</div>
            </div>
          </div>
        </div>
      </div>

      <div className="row py-2">
        <div className="col-12">
          <div className="row">
            <div className="col-md-3 col-sm-3">
              <div className="label form-control border-0 bg-overlay px-3">PR文</div>
            </div>

            <div className="col-md-9 col-sm-9">
              <div className="info form-control border-0">{userInfluencer?.pr}</div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

UserInfo.propTypes = {
  userInfluencer: PropTypes.object
};

UserInfo.defaultProps = {
  userInfluencer: null
};
export default UserInfo;
