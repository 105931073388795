/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import { ErrorMessage } from 'formik';

function CustomSelect({ field, form: { touched, errors }, label, inputRef, ...props }) {
  return (
    <Form.Group>
      <Form.Label htmlFor={field.name}>
        {label[0]}
        {label[1] && <span className="label-required">{label[1]}</span>}
      </Form.Label>

      <Form.Select
        required
        className="form-control"
        isInvalid={touched[field.name] && errors[field.name]}
        {...field}
        {...props}
      >
        {props.default_option != '' && <option value="">{props.default_option}</option>}
        {props.options?.map((option) => (
          <option key={`${field.name}${option.key}`} value={option.key}>
            {option.value}
          </option>
        ))}
      </Form.Select>
      <ErrorMessage name={field.name} component="div" className="invalid-feedback" />
    </Form.Group>
  );
}

CustomSelect.defaultProps = {
  inputRef: undefined,
  default_option: '',
  options: []
};

CustomSelect.propTypes = {
  label: PropTypes.array.isRequired,
  field: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  default_option: PropTypes.string,
  options: PropTypes.array,
  inputRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({
      current: PropTypes.instanceOf(Element)
    })
  ])
};

export default CustomSelect;
