import ScrollToTop from 'components/common/ScrollToTop';
import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import AdminRouter from './AdminRouter';
import ClientRouter from './ClientRouter';

const LandingPage = React.lazy(() => import('landing_page'));
function AppRouter() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route
          path="service"
          element={(
            <React.Suspense>
              <LandingPage />
            </React.Suspense>
          )}
        />
        <Route path="/*" element={<ClientRouter />} />
        <Route path="/stf/*" element={<AdminRouter />} />
      </Routes>
    </BrowserRouter>
  );
}

export default AppRouter;
