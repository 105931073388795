import React, { useEffect } from 'react';
import { PlusLg, PersonFill } from 'react-bootstrap-icons';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import useSession from 'hooks/useSession';
import { toMemberPage } from 'helpers/utils';
import * as ROUTES from 'constants/routes';
import { USER_TYPE_COMPANY, USER_TYPE_INFLUENCER, USER_TYPE_NPO, SAVE_IS_PURCHASED_POINTS } from 'redux/client/auth';
import { useDispatch } from 'react-redux';
import { checkIsPurchasedPoints } from 'redux/client/transfer_points';
import { ResponseStatusMessage } from 'constants/ResponseStatusMessage';

function Sidebar() {
  const navigate = useNavigate();
  const { user, isPurchasedPoints, unreadMessages } = useSession();
  const userType = user?.user_type;

  const dispatch = useDispatch();
  useEffect(() => {
    initialPage();
  }, []);

  const initialPage = () => {
    if (userType == USER_TYPE_COMPANY && !isPurchasedPoints) {
      try {
        dispatch(checkIsPurchasedPoints()).then((value) => {
          if (!value?.data) {
            dispatch({ type: SAVE_IS_PURCHASED_POINTS, payload: null });
          } else {
            dispatch({ type: SAVE_IS_PURCHASED_POINTS, payload: true });
          }
        });
      } catch (error) {
        navigate(ROUTES.ERROR_PAGE, {
          replace: true,
          state: {
            error_code: error.response?.status,
            error_msg: ResponseStatusMessage[error.response?.status]
          }
        });
      }
    }
  };

  return (
    <div className="client-sidebar">
      {userType != USER_TYPE_INFLUENCER && (
        <ul className="nav client-sidebar__action">
          <li className="nav-item">
            <Link to={toMemberPage(userType, ROUTES.MY_PROJECT_CREATE)} className="btn common-outline-btn w-100">
              <PlusLg className="fw-bold" />
              <span>新規プロジェクト作成</span>
            </Link>
          </li>
          {userType == USER_TYPE_COMPANY && (
            <li className="nav-item">
              <Link
                to={ROUTES.COMPANY_SEARCH_INFLLUENCERS}
                className="btn  btn-small common-btn w-100"
              >
                インフルエンサーを探す
              </Link>
            </li>
          )}
        </ul>
      )}

      <ul className="nav client-sidebar__navbar">
        <li className="nav-item">
          <NavLink to={toMemberPage(userType, ROUTES.MYPAGE)} className="nav-link my-page">
            <PersonFill color="#98A6B5" size={30} />
            マイページ
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink to={toMemberPage(userType, ROUTES.MY_PROJECT_LIST)} className="nav-link my-projects-list">
            <i className="icon-svg pen-to-square-svg nav-icon" />
            マイプロジェクト
            {unreadMessages > 0 && (
              <span>{unreadMessages}</span>
            )}
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink to={toMemberPage(userType, ROUTES.MY_NEWS)} className="nav-link">
            <i className="icon-svg news-svg nav-icon" />
            お知らせ一覧
          </NavLink>
        </li>
        {userType === USER_TYPE_INFLUENCER && (
          <li className="nav-item">
            <NavLink to={toMemberPage(userType, ROUTES.EVALUATION)} className="nav-link">
              <i className="icon-svg chart-simple-svg nav-icon" />
              評価
            </NavLink>
          </li>
        )}
        {userType == USER_TYPE_COMPANY && (
          <li className="nav-item">
            <NavLink to={ROUTES.MY_POINT_INDEX} className="nav-link">
              <i className="icon-svg point-svg nav-icon" />
              ポイント管理
            </NavLink>
          </li>
        )}

        {userType == USER_TYPE_NPO && (
          <li className="nav-item">
            <NavLink to={toMemberPage(userType, ROUTES.TRANSFER_HISTORY)} className="nav-link">
              <i className="icon-svg influencer-point-svg nav-icon" />
              ポイント管理
            </NavLink>
          </li>
        )}

        {userType == USER_TYPE_INFLUENCER && (
          <>
            <li className="nav-item">
              <NavLink to={toMemberPage(userType, ROUTES.TRANSFER_HISTORY)} className="nav-link">
                <i className="icon-svg influencer-point-svg nav-icon" />
                ポイント管理
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to={ROUTES.REWARD_CHANGE} className="nav-link">
                <i className="icon-svg fresh-svg nav-icon" />
                戦闘力の計測
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to={toMemberPage(userType, ROUTES.INVITATION)} className="nav-link">
                <i className="icon-svg invitation-svg nav-icon" />
                招待プログラム
              </NavLink>
            </li>
          </>
        )}
        <li className="nav-item">
          <NavLink to={toMemberPage(userType, ROUTES.MY_PROFILE_UPDATE)} className="nav-link">
            <i className="icon-svg user-profile-svg nav-icon" />
            会員情報
          </NavLink>
        </li>
        {userType === USER_TYPE_COMPANY && (
          <>
            <li className="nav-item">
              <NavLink to={toMemberPage(userType, ROUTES.IDENTITY_VERIFY)} className="nav-link">
                <i className="icon-svg user-svg nav-icon" />
                本人確認
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to={toMemberPage(userType, ROUTES.NDA)} className="nav-link">
                <i className="icon-svg lock-svg nav-icon" />
                NDA
              </NavLink>
            </li>
          </>
        )}
      </ul>
    </div>
  );
}

export default Sidebar;
