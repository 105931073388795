import API from 'axios/API';

export const createIdentityVerify = (_requestForm) => () => {
  const requestForm = {
    docs_type: _requestForm.docs_type,
    attached_files: _requestForm.attached_files.filter((item) => !item.id),
    old_attached_index: _requestForm.attached_files.map((item,) => item.id).filter(Boolean)
  };

  const formData = new FormData();
  requestForm.attached_files.forEach((attachedFile) => {
    formData.append('attached_files[]', attachedFile.file);
  });
  delete requestForm.attached_files;

  Object.keys(requestForm).forEach((key) => {
    formData.append(key, requestForm[key]);
  });

  return API({
    method: 'POST',
    url: 'company/identity-verify',
    data: formData
  }).catch((error) => {
    return error;
  });
};
