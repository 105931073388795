import React from 'react';
import PropTypes from 'prop-types';

function UserCompanyPurchasePointsRow({ itemRow }) {
  return (
    <tr>
      <td>{itemRow.payment_at}</td>
      <td>{itemRow.points} pt
        {itemRow.is_buy == 1 && (
          itemRow.product_id ? (
            <span>を購入</span>
          ) : (
            <span>{itemRow.title ? itemRow.title : '付与'}</span>
          )
        )}

        {itemRow.is_buy == 0 && (
          <span>を使用</span>
        )}
      </td>
    </tr>
  );
}

UserCompanyPurchasePointsRow.propTypes = {
  itemRow: PropTypes.object.isRequired
};
export default UserCompanyPurchasePointsRow;
