import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import StarFillLeft from 'assets/svg/star_icon/star_active__left.svg';
import StarFillRight from 'assets/svg/star_icon/star_active__right.svg';
import StarLeft from 'assets/svg/star_icon/star_noactive__left.svg';
import StarRight from 'assets/svg/star_icon/star_noactive__right.svg';

const StarCountMax = 10;

function Rating({ label, description, field, form }) {
  const { name, value = 0 } = field;
  const starBoxRef = useRef(null);

  const setStarBackgroundImage = (star, i) => {
    let backgroundImageUrL = null;
    const isOdd = i % 2 != 0;

    if (star < i) {
      backgroundImageUrL = isOdd ? StarLeft : StarRight;
    } else {
      backgroundImageUrL = isOdd ? StarFillLeft : StarFillRight;
    }

    const starButton = starBoxRef.current.querySelector(`li:nth-child(${i}) button`);
    starButton.style.backgroundImage = `url(${backgroundImageUrL})`;
  };

  const handleMouseLeave = () => {
    for (let i = 1; i <= StarCountMax; i += 1) {
      const star = value * 2;
      setStarBackgroundImage(star, i);
    }
  };

  const handleMouseEnter = (star) => {
    for (let i = 1; i <= StarCountMax; i += 1) {
      setStarBackgroundImage(star, i);
    }
  };

  const handleClickRating = (i) => {
    let startNumber = i * 0.5;
    const star = value;
    if (star == 0.5 && i == 1) {
      startNumber = 0;
    }
    form.setFieldValue(name, startNumber);
  };

  return (
    <div className="review-item mb-3">
      <div className="review-label mb-2">
        <span className="font-bold">{label}</span>
      </div>
      <div className="review-star">
        <div className="review-description mb-2">{description}</div>
        <div className="star-list">
          <ul className="star__container-box" ref={starBoxRef} onMouseLeave={handleMouseLeave}>
            {[...Array(StarCountMax)].map((_, i) => (
              <li key={`${i.toString()}`} className="star__item" onMouseEnter={() => handleMouseEnter(i + 1)}>
                <button type="button" className="star__btn" onClick={() => handleClickRating(i + 1)}>
                  <span> </span>
                </button>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}

Rating.defaultProps = {
  label: '評価',
  description: '星をクリックして評価してください',
  field: {
    name: '',
    value: '',
    onChange: () => {},
    onBlur: () => {}
  },
  form: {
    setFieldValue: () => {}
  }
};

Rating.propTypes = {
  label: PropTypes.string,
  description: PropTypes.string,
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.any.isRequired,
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func.isRequired
  }),
  form: PropTypes.shape({
    setFieldValue: PropTypes.func.isRequired
  })
};

export default Rating;
