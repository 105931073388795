import React from 'react';
import PropTypes from 'prop-types';

import { Field, Form, Formik } from 'formik';
import { CustomDatePicker } from 'components/formik';
import moment from 'moment';
import 'moment-timezone';

moment.tz.setDefault('Asia/Tokyo');

function TransferHistorySearchBar({ isLoading, initFormikValues, onSubmitFilterForm }) {
  return (
    <Formik initialValues={initFormikValues} onSubmit={onSubmitFilterForm} enableReinitialize>
      {({ setFieldValue, submitForm }) => (
        <Form onChange={submitForm} className="row flex-column flex-md-row">
          <div className="col mb-2 mb-md-0">
            <div role="group" className="btn-group transfer-history__btn-group">
              <Field
                disabled={isLoading}
                id="user_type0"
                className="btn-check"
                type="radio"
                name="user_type"
                value="0"
              />
              <label htmlFor="user_type0" className="btn">
                インフルエンサー
              </label>

              <Field
                disabled={isLoading}
                id="user_type2"
                className="btn-check"
                type="radio"
                name="user_type"
                value="2"
              />
              <label htmlFor="user_type2" className="btn">
                OTASUKE
              </label>
            </div>
          </div>

          <div className="col">
            <Field
              disabled={isLoading}
              className="form-control rounded-0"
              name="applicated_at"
              dateFormat="yyyy/MM"
              showMonthYearPicker
              autoComplete="off"
              onChange={(date) => {
                setFieldValue('applicated_at', date ? moment(date).format('YYYY-MM') : '');
                submitForm();
              }}
              component={CustomDatePicker}
            />
          </div>
        </Form>
      )}
    </Formik>
  );
}

TransferHistorySearchBar.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  initFormikValues: PropTypes.object.isRequired,
  onSubmitFilterForm: PropTypes.func.isRequired
};

export default TransferHistorySearchBar;
