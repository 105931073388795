import React, { useState, useEffect } from 'react';

import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { QuestionCircleFill } from 'react-bootstrap-icons';
import constants from 'constants/constants';
import { useParams } from 'react-router-dom';

function NpoTooltip() {
  const routeParams = useParams();
  const [isUserTypeNpo, setIsUserTypeNpo] = useState(false);
  const textToolTipQuestion1 = 'ENMAが支援するNPO団体の活動プロジェクトです。\nインフルエンサーがプロジェクトに参加することで、金銭報酬の代わりにテディベアポイントが贈呈されます。';
  const textToolTipQuestion2 = 'テディベアプロジェクトへの参加と達成によって贈呈されるポイントです。\nインフルエンサーの社会貢献度を示す指標にもなります。';
  useEffect(() => {
    if (routeParams.user_type === constants.USER_TYPE_BY_USER.TEXT?.[2]) {
      setIsUserTypeNpo(true);
    } else {
      setIsUserTypeNpo(false);
    }
  }, [routeParams.user_type]);

  return (
    <div className="pt-3 pt-md-0">
      {isUserTypeNpo && (
        <div className="npo-tooltip-wrapper px-4 px-md-0 py-1 py-md-0">
          <div className="npo-tooltip-items row">
            <div className="tooltip-item col-12 col-md-6 text-md-center py-1 py-md-3">
              <OverlayTrigger
                key="bottom1"
                placement="bottom"
                overlay={(
                  <Tooltip id="tooltip-bottom" className="amazing-tooltip">
                    {textToolTipQuestion1}
                  </Tooltip>
                )}
              >
                <QuestionCircleFill
                  className="question-circle-style label-icon me-2 align-text-bottom d-md-none"
                  size={20}
                />
              </OverlayTrigger>
              <strong>テディベアプロジェクトとは</strong>
              <OverlayTrigger
                key="top2"
                placement="top"
                overlay={(
                  <Tooltip id="tooltip-top" className="amazing-tooltip">
                    {textToolTipQuestion1}
                  </Tooltip>
                )}
              >
                <QuestionCircleFill
                  className="question-circle-style label-icon ms-2 align-text-bottom d-none d-md-inline"
                  size={20}
                />
              </OverlayTrigger>
            </div>
            <div className="tooltip-item col-12 col-md-6 text-md-center py-1 py-md-3">
              <OverlayTrigger
                key="bottom3"
                placement="bottom"
                overlay={(
                  <Tooltip id="tooltip-bottom" className="amazing-tooltip">
                    {textToolTipQuestion2}
                  </Tooltip>
                )}
              >
                <QuestionCircleFill
                  className="question-circle-style label-icon me-2 align-text-bottom d-md-none"
                  size={20}
                />
              </OverlayTrigger>
              <strong>テディベアポイントとは</strong>
              <OverlayTrigger
                key="top4"
                placement="top"
                overlay={(
                  <Tooltip id="tooltip-top" className="amazing-tooltip">
                    {textToolTipQuestion2}
                  </Tooltip>
                )}
              >
                <QuestionCircleFill
                  className="question-circle-style label-icon ms-2 align-text-bottom d-none d-md-inline"
                  size={20}
                />
              </OverlayTrigger>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default NpoTooltip;
