import React, { useEffect, useState, useRef } from 'react';
import DefaultAvatarImg from 'assets/image.png';
import PropTypes from 'prop-types';
import { HTTP_OK, HTTP_UNPROCESSABLE_ENTITY } from 'constants/ResponseStatus';
import { ERROR_404_PAGE, PROJECT_REVIEW, MY_PROJECT_LIST } from 'constants/routes';
import constants from 'constants/constants';
import { Field, Form, Formik, ErrorMessage, FormikProvider, useFormik } from 'formik';
import { talkRoomStatusData } from 'data/talkRoomStatusData';
import { talkRoomSystemNotice } from 'data/talkRoomSystemNotice';
import { CustomProjectStatusSelectPicker, CustomTextArea } from 'components/formik';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useSession from 'hooks/useSession';
import {
  getNextStatus,
  sendMessage,
  findTalkRoom,
  findTalkRoomOnly,
  updateStatus,
  updateNotes,
  getMessagesAfterCreatedAt,
  findVideoInfo,
  getUnreadMessagesCount,
  updateReadTalkRoomNotification,
  findProjectAppoint
} from 'redux/client/message';
import { UPDATE_UNREAD_MESSAGE, USER_TYPE_INFLUENCER, USER_TYPE_NPO } from 'redux/client/auth';
import { Button, FormLabel, OverlayTrigger, Tooltip } from 'react-bootstrap';
import IconNotes from 'assets/svg/icon28-notes.svg';
import IconClose from 'assets/svg/icon32-close.svg';
import IconSend from 'assets/svg/icon28-send.svg';
import { QuestionCircleFill, PlayCircle } from 'react-bootstrap-icons';
import { setFormikErrors, toMemberPage } from 'helpers/utils';
import moment from 'moment';
import 'moment-timezone';
import { sendCancellationRequest } from 'redux/client/talk_room';
import CalculateTeddyBearRank from 'components/common/CalculateTeddyBearRank';
import RankSystem from 'components/common/RankSystem';
import MessageItem from './MessageItem';
import { MessagesValidation } from './MessagesValidation';
import { UpdateNotesValidation } from './UpdateNotesValidation';
import NotPointsRecruitTalkRoomModal from './NotPointsRecruitTalkRoomModal';
import CancellationRequestModal from './CancellationRequestModal';
import ScheduleModal from './ScheduleModal';
import GuideMethodPostRequestModel from './GuideMethodPostRequestModel';

moment.tz.setDefault('Asia/Tokyo');

const filterTalkRoomStatus = (dataTalkRoomStatus, talkRoomStatus) => {
  const filtered = dataTalkRoomStatus.filter((el) => {
    return talkRoomStatus.includes(el.key);
  });
  return filtered;
};

function Messages({ talkRoomData, isOffCanvas, handleMessageClose, projectData, tiktokInfoData }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSession();
  const userType = user?.user_type;
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingAutoTalkRoom, setIsLoadingAutoTalkRoom] = useState(true);
  const [isLoadingAutoMessage, setIsLoadingAutoMessage] = useState(true);
  const [isShowForm, setIsShowForm] = useState(false);
  const [isShowGuideVideoModel, setIsShowGuideVideoModel] = useState(false);
  const [isShowFormMemoInfluencer, setIsShowFormMemoInfluencer] = useState(false);
  const [messages, setMessages] = useState([]);
  const messageRef = useRef([]);
  const [talkRoom, setTalkRoom] = useState(talkRoomData);
  const [nextStatus, setNextStatus] = useState(filterTalkRoomStatus(talkRoomStatusData, [talkRoomData.status]));
  const [currentTalkRoomStatus, setCurrentTalkRoomStatus] = useState(talkRoomData.status);
  const [isShowCancellationRequestModal, setIsShowCancellationRequestModal] = useState(false);
  const messagesEndRef = useRef(null);
  const [isSubmittingCancellation, setIsSubmittingCancellation] = useState(false);
  const [isShowVideoUrlForm, setIsShowVideoUrlForm] = useState(false);
  const [isShowScheduleModal, setIsShowScheduleModal] = useState(false);
  const [influencerStatusText, setInfluencerStatusText] = useState(talkRoomData.status_text);
  const [companyStatusText, setCompanyStatusText] = useState(talkRoomData.status_text);
  const textFormMemo = '連絡共有掲示板';
  const placeholderFormMeno = 'インフルエンサーと企業で連絡を共有するための入力欄です。\n'
  + '日程の再確認や特殊な条件が発生した場合など、お互いの確認用としてご利用ください';
  const userTypeUpdateNotification = [
    constants.USER_TYPE_BY_USER.KEY.company,
    constants.USER_TYPE_BY_USER.KEY.npo
  ];

  const formik = useFormik({
    initialValues: {
      talk_room_id: talkRoom.id,
      status: talkRoom.status,
      contents: '',
      video_url: ''
    },
    onSubmit: (form, formikHelpers) => _doSubmit(form, formikHelpers),
    enableReinitialize: true,
    validationSchema: MessagesValidation
  });

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const _iniPage = async (talkRoomId) => {
    setIsLoading(true);
    setNextStatus(filterTalkRoomStatus(talkRoomStatusData, [talkRoomData.status]));
    await _findTalkRoomAndMessages(talkRoomId);
    if (userTypeUpdateNotification.includes(userType)) {
      const resp = await updateReadTalkRoomNotification(talkRoomId);
      if (resp.status != HTTP_OK) {
        navigate(ERROR_404_PAGE, {
          replace: true
        });
      }
    }
    await getUnreadMessagesCount().then((res) => {
      dispatch({ type: UPDATE_UNREAD_MESSAGE, payload: res?.data?.unread_messages_count });
    });
    setIsLoading(false);
    setIsLoadingAutoMessage(false);
    setIsLoadingAutoTalkRoom(false);
  };

  const _findTalkRoomAndMessages = async (talkRoomId) => {
    const resp = await dispatch(findTalkRoom(talkRoomId));
    if (resp.status != HTTP_OK) {
      navigate(ERROR_404_PAGE, {
        replace: true
      });
    }
    setTalkRoom(resp.data);
    setMessages(resp.data.messages);
  };

  const _getNextStatus = async (talkRoomId) => {
    const resp = await dispatch(getNextStatus(talkRoomId));
    if (resp.status != HTTP_OK) {
      navigate(ERROR_404_PAGE, {
        replace: true
      });
    }
    const status = resp.data;
    if (status?.length > 0) {
      setNextStatus(filterTalkRoomStatus(talkRoomStatusData, status));
    }
  };

  const _getProjectAppoint = async (talkRoomId) => {
    setIsLoading(true);
    const resp = await dispatch(findProjectAppoint(talkRoomId));
    if (resp.status != HTTP_OK) {
      navigate(ERROR_404_PAGE, {
        replace: true
      });
    }

    setIsLoading(false);
  };


  const _doChangeNotes = async (form, formikHelpers) => {
    const resp = await dispatch(updateNotes(form));
    if (resp && resp.status === HTTP_UNPROCESSABLE_ENTITY) {
      setFormikErrors(resp.data.errors, formikHelpers.setFieldError);
      return;
    }
    if (resp.status != HTTP_OK) {
      navigate(ERROR_404_PAGE, {
        replace: true
      });
    }
    setTalkRoom(resp.data);
  };

  const _doSendMessage = async (form, formikHelpers) => {
    if (form.contents == '') {
      formikHelpers.setFieldError('contents', '入力してください');
      return;
    }
    const resp = await dispatch(sendMessage(form));
    if (resp && resp.status === HTTP_UNPROCESSABLE_ENTITY) {
      setFormikErrors(resp.data.errors, formikHelpers.setFieldError);
      return;
    }
    if (resp.status != HTTP_OK) {
      navigate(ERROR_404_PAGE, {
        replace: true
      });
    }
    formikHelpers.setFieldValue('contents', '');
    setMessages((prevMessages) => [...prevMessages, resp.data]);
  };

  const _onHideCancellationRequestModal = async (setFieldValue) => {
    setIsShowCancellationRequestModal(false);
    setFieldValue('status', talkRoom.status);
  };
  const _onSentCancellationRequest = async (form, formikHelpers) => {
    // submit form
    setIsSubmittingCancellation(true);
    const resp = await dispatch(sendCancellationRequest(talkRoom.id, form));
    if (resp && resp.status === HTTP_UNPROCESSABLE_ENTITY) {
      setFormikErrors(resp.data.errors, formikHelpers.setFieldError);
    } else {
      setTalkRoom(resp.data);
      setIsShowCancellationRequestModal(false);
      const mess = {
        id: Math.random(),
        contents: resp.data,
        is_system: 1,
        created_at: moment().format('YYYY.MM.DD HH:MM:SS').toString()
      };
      setMessages((prevMessages) => [...prevMessages, mess]);
      formikHelpers.setFieldValue('status', talkRoom.status);
    }
    setIsSubmittingCancellation(false);
  };

  const _doChangeStatus = async (form, formikHelpers) => {
    if (form.status == talkRoom.status) return;
    const resp = await dispatch(updateStatus(form));
    if (resp && resp.status === HTTP_UNPROCESSABLE_ENTITY) {
      setFormikErrors(resp.data.errors, formikHelpers.setFieldError);
      return;
    }
    if (resp.status != HTTP_OK) {
      navigate(ERROR_404_PAGE, {
        replace: true
      });
    }
    setTalkRoom(resp.data);
    const mess = {
      id: Math.random(),
      contents: resp.data,
      is_system: 1,
      created_at: moment().format('YYYY.MM.DD HH:MM:SS').toString()
    };
    setMessages((prevMessages) => [...prevMessages, mess]);
    formikHelpers.setFieldValue('status', talkRoom.status);
  };

  const _doSubmit = async (form, formikHelpers) => {
    // if talk room status is transaction cancellation then display modal cancellation AMAZING-198
    if (form.status == talkRoom.status) {
      await _doSendMessage(form, formikHelpers);
      return;
    }

    if (form.contents != '') {
      await _doSendMessage(form, formikHelpers);
    }

    if (form.status == constants.TALK_ROOM_STATUS.CANCEL_THE_TRANSACTION) {
      setIsShowCancellationRequestModal(true);
      return;
    }
    setIsShowCancellationRequestModal(false);

    if (form.status === constants.TALK_ROOM_STATUS.POST_REQUEST) {
      setIsShowVideoUrlForm(true);
      return;
    }
    setIsShowVideoUrlForm(false);
    if (form.status != talkRoom.status) {
      await _doChangeStatus(form, formikHelpers);
    }
  };

  const autoGetMessage = async () => {
    if (isLoadingAutoMessage == true) return;
    setIsLoadingAutoMessage(true);
    // eslint-disable-next-line camelcase
    const lastElement = [...messageRef.current].sort().reverse().find(({ is_system }) => is_system == undefined);
    if (lastElement != undefined) {
      const resp = await dispatch(getMessagesAfterCreatedAt(talkRoomData.id, lastElement.created_at));
      if (resp && resp.status == HTTP_OK) {
        const mess = resp.data;
        if (mess?.length > 0) {
          setMessages((prevMessages) => [...prevMessages, ...mess]);
        }
      }
    }
    setIsLoadingAutoMessage(false);
  };

  const autoGetCurrTalkRoom = async () => {
    if (isLoadingAutoTalkRoom == true) return;
    setIsLoadingAutoTalkRoom(true);
    const resp = await dispatch(findTalkRoomOnly(talkRoomData.id));
    if (resp && resp.status == HTTP_OK) {
      if (resp.data?.status != talkRoom.status) {
        setTalkRoom(resp.data);
      }
    }
    setIsLoadingAutoTalkRoom(false);
  };

  const _handleOnClickReview = () => {
    const urlProjectReviews = toMemberPage(
      userType,
      PROJECT_REVIEW.replace(':id', projectData.id).replace(':idTalkRoom', talkRoomData.id)
    );
    navigate(`${urlProjectReviews}`, {
      state: {
        isProjectReview: true
      }
    });
  };

  const videoURLHandle = async (videoUrl) => {
    if (videoUrl === '') {
      formik.setErrors({ video_url: '投稿動画のURLを入力してください' });
      return;
    }
    formik.setSubmitting(true);
    const resp = await dispatch(findVideoInfo(talkRoomData.id, videoUrl));
    if (resp && resp.status === HTTP_UNPROCESSABLE_ENTITY) {
      formik.setErrors({ video_url: resp.data.errors.video_url });
      formik.setSubmitting(false);
      return;
    }
    if (resp.status == HTTP_OK) {
      await _doChangeStatusAfterGetVideoInfo();
    }
    formik.setSubmitting(false);
  };

  const _doChangeStatusAfterGetVideoInfo = async () => {
    const data = {
      talk_room_id: talkRoom.id,
      status: constants.TALK_ROOM_STATUS.POST_REQUEST
    };
    const resp = await dispatch(updateStatus(data));
    if (resp.status == HTTP_OK) {
      setTalkRoom(resp.data);
      const mess = {
        id: Math.random(),
        contents: '投稿申請が完了しました',
        is_system: 2,
        created_at: moment().format('YYYY.MM.DD HH:MM:SS').toString()
      };
      setMessages((prevMessages) => [...prevMessages, mess]);
      formik.setFieldValue('status', talkRoom.status);
      setIsShowVideoUrlForm(false);
    }
  };


  useEffect(() => {
    const intervalMessage = setInterval(() => {
      autoGetMessage();
    }, 30 * 1000);
    return () => clearInterval(intervalMessage);
  }, [isLoadingAutoMessage]);

  useEffect(() => {
    const intervalCurrTalkRoom = setInterval(() => {
      autoGetCurrTalkRoom();
    }, 10 * 1000);
    return () => clearInterval(intervalCurrTalkRoom);
  }, [isLoadingAutoTalkRoom]);

  useEffect(() => {
    _iniPage(talkRoomData.id);
  }, [talkRoomData.id]);

  useEffect(() => {
    messageRef.current = messages;
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    if (projectData && projectData.public == constants.PROJECT_PUBLIC.DRAFT) {
      navigate(toMemberPage(userType, MY_PROJECT_LIST), {
        replace: true
      });
    }
    _getNextStatus(talkRoom.id);
    _getProjectAppoint(talkRoom.id);
    updateCurrentTalkRoomStatus();
    updateStatusText();
  }, [talkRoom]);

  const updateCurrentTalkRoomStatus = () => {
    if (talkRoom.status == constants.TALK_ROOM_STATUS.RECRUIT && talkRoom?.appoint_date) {
      if (talkRoom?.old_status == constants.TALK_ROOM_STATUS.POST_REQUEST
        && talkRoom?.updated_at > talkRoom?.appoint_updated_at) {
        setCurrentTalkRoomStatus(11);
      } else {
        setCurrentTalkRoomStatus(10);
      }
    } else if (talkRoom.status == constants.TALK_ROOM_STATUS.RECRUIT
      && talkRoom?.old_status == constants.TALK_ROOM_STATUS.POST_REQUEST) {
      setCurrentTalkRoomStatus(11);
    } else if (talkRoom.status == constants.TALK_ROOM_STATUS.DO_NOT_RECRUIT
      && projectData.type == constants.PROJECT_TYPE.SPECIFIED
      && talkRoom?.old_status == constants.TALK_ROOM_STATUS.RECRUITMENT) {
      setCurrentTalkRoomStatus(12);
    } else if (talkRoom.status == constants.TALK_ROOM_STATUS.DO_NOT_RECRUIT
      && talkRoom?.old_status == constants.TALK_ROOM_STATUS.INDICATION_APPROVAL) {
      setCurrentTalkRoomStatus(13);
    } else if (talkRoom.status == constants.TALK_ROOM_STATUS.PENDING
      && talkRoom?.old_status == constants.TALK_ROOM_STATUS.INDICATION_APPROVAL) {
      setCurrentTalkRoomStatus(14);
    } else if (talkRoom.status == constants.TALK_ROOM_STATUS.POST_APPROVAL
      && talkRoom?.is_user_review) {
      setCurrentTalkRoomStatus(15);
    } else if (projectData?.type == constants.PROJECT_TYPE.CANDIDACY && talkRoom?.user?.user_type == USER_TYPE_NPO
      && talkRoom.status == constants.TALK_ROOM_STATUS.POST_REQUEST && userType == USER_TYPE_INFLUENCER) {
      setCurrentTalkRoomStatus(16);
    } else if (projectData?.type == constants.PROJECT_TYPE.CANDIDACY && talkRoom?.user?.user_type == USER_TYPE_NPO
      && talkRoom.status == constants.TALK_ROOM_STATUS.POST_APPROVAL) {
      setCurrentTalkRoomStatus(17);
    } else {
      setCurrentTalkRoomStatus(talkRoom.status);
    }
  };

  const updateStatusText = () => {
    if (userType == USER_TYPE_INFLUENCER) {
      if (talkRoom.status == constants.TALK_ROOM_STATUS.PENDING) {
        if (projectData.type == constants.PROJECT_TYPE.PUBLIC) {
          setInfluencerStatusText('応募');
        } else if (projectData.type == constants.PROJECT_TYPE.SPECIFIED) {
          setInfluencerStatusText('指名承諾');
        } else {
          setInfluencerStatusText(talkRoom.status_text);
        }
      } else if (projectData.type == constants.PROJECT_TYPE.SPECIFIED) {
        if (talkRoom.status == constants.TALK_ROOM_STATUS.DO_NOT_RECRUIT
          && talkRoom.old_status == constants.TALK_ROOM_STATUS.RECRUITMENT) {
          setInfluencerStatusText('指名否認');
        } else if (talkRoom.status == constants.TALK_ROOM_STATUS.RECRUIT
          && talkRoom.old_status == constants.TALK_ROOM_STATUS.RECRUITMENT) {
          setInfluencerStatusText('指名承認');
        } else if (talkRoom.status == constants.TALK_ROOM_STATUS.RECRUITMENT) {
          setInfluencerStatusText('指名オファー');
        } else {
          setInfluencerStatusText(talkRoom.status_text);
        }
      } else if (talkRoom.status == constants.TALK_ROOM_STATUS.RECRUIT
        && talkRoom?.old_status == constants.TALK_ROOM_STATUS.POST_REQUEST) {
        setInfluencerStatusText('投稿修正依頼');
      } else {
        setInfluencerStatusText(talkRoom.status_text);
      }
    }
    if (userType != USER_TYPE_INFLUENCER) {
      if (talkRoom.status == constants.TALK_ROOM_STATUS.RECRUITMENT
        && projectData.type == constants.PROJECT_TYPE.SPECIFIED) {
        setCompanyStatusText('指名オファー');
      } else if (talkRoom.status == constants.TALK_ROOM_STATUS.RECRUIT
        && talkRoom?.old_status == constants.TALK_ROOM_STATUS.POST_REQUEST) {
        setCompanyStatusText('投稿修正依頼');
      } else if (projectData.type == constants.PROJECT_TYPE.SPECIFIED
        && talkRoom.status == constants.TALK_ROOM_STATUS.DO_NOT_RECRUIT
        && talkRoom.old_status == constants.TALK_ROOM_STATUS.RECRUITMENT) {
        setCompanyStatusText('指名否認');
      } else {
        setCompanyStatusText(talkRoom.status_text);
      }
    }
  };

  const disableTalkRoomForm = () => {
    if (talkRoom.status == constants.TALK_ROOM_STATUS.DO_NOT_RECRUIT
      || talkRoom.status == constants.TALK_ROOM_STATUS.CANCEL_THE_TRANSACTION
      || talkRoom.status == constants.TALK_ROOM_STATUS.APPROVE_TO_CANCEL_TRANSACTION
      || talkRoom.status == constants.TALK_ROOM_STATUS.POST_APPROVAL) {
      return true;
    }
    return false;
  };

  const bodyTalkRoomReview = (userTypeCreateProject) => {
    if (userTypeCreateProject != constants.USER_TYPE_BY_USER.KEY.npo) {
      return (
        <div className="d-flex justify-content-center border-top border-4">
          {
            (typeof talkRoom.is_user_review === 'undefined' || talkRoom.is_user_review) ? (
              <Button
                variant="redirect common-btn mt-5 mb-4"
                disabled
              >
                <span className="sr-only">評価登録済み</span>
              </Button>
            ) : (
              <Button
                variant="redirect common-btn mt-5 mb-4"
                onClick={_handleOnClickReview}
              >
                <span className="sr-only">評価を入力する</span>
              </Button>
            )
          }
        </div>
      );
    }

    return <div className="d-flex justify-content-center border-top border-4" />;
  };

  const handleShowGuideVideo = () => {
    setIsShowGuideVideoModel(true);
  };

  return (
    <>
      <ScheduleModal
        isShowScheduleModal={isShowScheduleModal}
        setIsShowScheduleModal={setIsShowScheduleModal}
        talkRoomId={talkRoomData.id}
      />
      <GuideMethodPostRequestModel
        isShowGuideVideoModel={isShowGuideVideoModel}
        setIsShowGuideVideoModel={setIsShowGuideVideoModel}
      />
      {isOffCanvas ? (
        <div>
          <div className="offcanvas-header-amazing
          d-column-flex justify-content-center border-bottom border-4 bg-white sticky-top"
          >
            <div className="d-flex offcanvas-sub-header-amazing">
              <div className="d-flex w-inherit align-items-center">
                <div className="col-md-3 col-1">
                  <img
                    className="rounded-circle img-fluid"
                    width={180}
                    height={180}
                    src={tiktokInfoData?.link_avatar_image || DefaultAvatarImg}
                    alt={tiktokInfoData?.nickname}
                  />
                </div>
                <div className="col-md-9 col-11 ms-2">
                  <div className="pb-sm-2 col-12 d-flex align-items-center justify-content-between row-1">
                    <div className="col-9 col-sm-10 d-flex justify-content-start align-items-center">
                      <span className={`text-center text-nowrap
                      pt-1 pb-1 ps-2 pe-2 me-2 room-status status-${talkRoom.status}`}
                      >
                        {companyStatusText}
                      </span>
                      <span className="sub-title1 text-truncate">{tiktokInfoData?.nickname}</span>
                      <button
                        type="button"
                        className="d-block d-md-none btn btn-link text-decoration-none p-0"
                        onClick={() => {
                          setIsShowForm(!isShowForm);
                        }}
                      >
                        <img src={IconNotes} alt="Show notes" height={28} width={28} />
                      </button>
                    </div>
                    <div className="col-3 col-sm-2 d-flex justify-content-end">
                      <button
                        onClick={handleMessageClose}
                        type="button"
                        className="d-flex align-items-center text-nowrap
                              text-end btn btn-link btn-close-offcanvas-amazing text-decoration-none"
                        aria-label="Close"
                      >
                        閉じる <img src={IconClose} alt="Close" height={32} width={32} />
                      </button>
                    </div>
                  </div>
                  <div className="pb-sm-2 col-12 d-flex align-items-center justify-content-between row-2">
                    <div className="col-12 col-sm-10 d-flex justify-content-start align-items-center flex-wrap ">
                      <div className="user-rank-area-new has-teddybear d-flex align-items-center flex-wrap">
                        <RankSystem
                          userType={constants.USER_TYPE_BY_USER.KEY.influencer}
                          rank={talkRoomData.user_rank?.rank || 0}
                        />
                        <CalculateTeddyBearRank rating={talkRoomData.teddy_bear_rank} />
                      </div>
                      <div className="paid-reward ms-1">
                        <div className="points d-flex justify-content-center align-items-center">
                          <i className="icon-svg point-svg nav-icon" />
                          <span className="fsize-32">{talkRoomData.influencer_reward || 0} pt</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <Formik
                    initialValues={{ notes: talkRoom.notes ?? '', talk_room_id: talkRoom.id }}
                    onSubmit={_doChangeNotes}
                    validationSchema={UpdateNotesValidation}
                  >
                    {({ isSubmitting, handleSubmit }) => (
                      <Form
                        className={`form-style-1 ${
                          isShowForm == true ? 'position-fixed p-3 bg-white' : 'd-none d-md-block'
                        }`}
                      >
                        <input type="hidden" name="talk_room_id" />
                        <div className="fw-bold py-1">
                          {textFormMemo}
                        </div>
                        <div className="col-12">
                          <Field
                            as="textarea"
                            className="col-12 form-control"
                            name="notes"
                            cols="60"
                            rows="2"
                            placeholder={placeholderFormMeno}
                            component={CustomTextArea}
                            disabled={disableTalkRoomForm()}
                          />
                        </div>
                        <div className="row mt-3">
                          <div className="col-md-8 form-label">※メモ欄の内容は相手にも表示されます</div>
                          <div className="col-md-4 d-flex justify-content-end">
                            <button
                              className="w160 btn btn-outline-green"
                              type="submit"
                              onClick={handleSubmit}
                              disabled={isSubmitting || disableTalkRoomForm()}
                            >
                              保存
                            </button>
                          </div>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
            {talkRoomSystemNotice[projectData.type][1][currentTalkRoomStatus]?.value && (
              <div className="talk-room-system-notice text-pre-wrap">
                {talkRoomSystemNotice[projectData.type][1][currentTalkRoomStatus].value
                  .replace('{user_name}', talkRoom?.tiktok_info?.username)}
              </div>
            )}
          </div>
          <div>
            <div className="d-block offcanvas-amazing-body">
              <div className="offcanvas-amazing-content">
                {isLoading
                  && [...Array(15)].map((e, i) => <MessageItem isLoading={isLoading} key={`${i.toString()}`} />)}
                {messages?.length === 0 && !isLoading && <p>メッセージはありません。</p>}
                {!isLoading
                  && messages?.map((message, index) => (
                    <MessageItem
                      message={message}
                      isLoading={isLoading}
                      projectData={projectData}
                      key={`message_${index.toString()}_${message.id}`}
                    />
                  ))}
                <div className="messages-end-ref" ref={messagesEndRef} />
              </div>
            </div>
            {
              talkRoom.status == constants.TALK_ROOM_STATUS.POST_APPROVAL ? (
                bodyTalkRoomReview(talkRoom.user.user_type)
              ) : (
                <div className={`offcanvas-amazing-footer 
                border-top border-4 bg-white ${!isOffCanvas ? 'sticky-bottom' : ''}`}
                >
                  <div className="col-12 offcanvas-amazing-footer-form">
                    <Formik
                      initialValues={{ talk_room_id: talkRoom.id, status: talkRoom.status, contents: '' }}
                      onSubmit={_doSubmit}
                      enableReinitialize
                      validationSchema={MessagesValidation}
                    >
                      {({ isSubmitting, handleSubmit, setFieldValue, errors }) => (
                        <Form className="form-style-1">
                          <NotPointsRecruitTalkRoomModal
                            showModal={!!errors.not_points_recruit_talk_room_error}
                            missingBudgetScore={errors.missing_budget_score}
                          />
                          <CancellationRequestModal
                            isShowModal={isShowCancellationRequestModal}
                            isSubmitting={isSubmittingCancellation}
                            onSentCancellationRequest={_onSentCancellationRequest}
                            onHideModal={() => _onHideCancellationRequestModal(setFieldValue)}
                          />
                          <input type="hidden" name="act" />
                          <input type="hidden" name="talk_room_id" />
                          <div className="col-12 d-flex pt-2">
                            <div className="col d-flex justify-content-start align-items-start">
                              {/* <button
                                type="button"
                                className="ps-0 d-block d-md-none btn btn-link text-decoration-none"
                                onClick={() => {
                                  setIsShowScheduleModal(true);
                                }}
                              >
                                <img src={IconDateTime} alt="Close" height={28} width={28} />
                              </button> */}
                              <FormLabel
                                htmlFor="status"
                                className="d-none d-md-block amazing-form-label pe-3 pt-3 col-sm-3 text-nowrap"
                              >
                                ステータス
                              </FormLabel>
                              <div className="col-sm-9">
                                <Field
                                  name="status"
                                  placeholder="選択して下さい"
                                  selectData={nextStatus}
                                  currentStatus={talkRoom.status}
                                  oldStatus={talkRoom.old_status}
                                  projectData={projectData}
                                  setIsShowVideoUrlForm={setIsShowVideoUrlForm}
                                  component={CustomProjectStatusSelectPicker}
                                  disabled={isSubmitting || disableTalkRoomForm()}
                                  className="offcanvas-amazing-status input-status"
                                />
                              </div>
                            </div>
                            <div className="col d-flex justify-content-end">
                              <div className="d-none d-md-block">
                                {/* {!isDisableScheduleButtonCompanyNpo && (
                                  <button
                                    className="w160 btn btn-outline-green me-lg-4 me-2"
                                    type="button"
                                    onClick={() => {
                                      setIsShowScheduleModal(true);
                                    }}
                                  >
                                    日程調整
                                  </button>
                                )} */}
                                <button
                                  className="w160 btn common-btn btn-send"
                                  type="button"
                                  disabled={isSubmitting
                                          || (talkRoom.status == constants.TALK_ROOM_STATUS.CANCEL_THE_TRANSACTION)
                                          || disableTalkRoomForm()}
                                  onClick={() => {
                                    handleSubmit();
                                  }}
                                >
                                  送信する
                                </button>
                              </div>
                            </div>
                          </div>
                          <div className="d-flex col-12 pt-3">
                            <div className="col-11 col-md-12 pe-2 row-content">
                              <Field
                                className="col-12"
                                name="contents"
                                cols="60"
                                rows="2"
                                placeholder="メッセージを入力してください"
                                disabled={talkRoom.status == constants.TALK_ROOM_STATUS.CANCEL_THE_TRANSACTION
                                        || disableTalkRoomForm()}
                                component={CustomTextArea}
                              />
                            </div>
                            <div className="col-1 d-block d-md-none d-flex align-content-center flex-wrap">
                              <button
                                type="button"
                                className="p-0 btn btn-link text-decoration-none"
                                disabled={isSubmitting}
                                onClick={() => {
                                  handleSubmit();
                                }}
                              >
                                <img src={IconSend} alt="Send" height={28} width={28} />
                              </button>
                            </div>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
              )
            }
          </div>
        </div>
      ) : (
        <div>
          <div className="offcanvas-header-amazing border-bottom border-4 bg-white 1111">
            <div className="offcanvas-sub-header-amazing offcanvas-mobile p-0">
              <div className="row">
                <div className="d-flex flex-wrap align-items-center">
                  <span className={`btn text-nowrap me-2 room-status status-${talkRoom.status} mb-2`}>
                    {influencerStatusText}
                  </span>
                  <span className="sub-title mb-2">{projectData?.npo_or_company_name}</span>
                  <div className="user-rank-area-new company d-inline-block ml-40 mr-25 mb-2">
                    <RankSystem
                      userType={constants.USER_TYPE_BY_USER.KEY.company}
                      rank={talkRoomData.user_rank?.rank || 0}
                    />
                  </div>
                  <div className="paid-reward d-inline-block mb-2">
                    <div className="points d-flex justify-content-center align-items-center">
                      <i className="icon-svg point-svg nav-icon" />
                      <span className="fsize-32">{talkRoomData.project_reward?.reward || 0} pt</span>
                    </div>
                  </div>
                  <button
                    type="button"
                    className="d-inline-block d-md-none btn btn-link text-decoration-none p-0 ms-2 mb-2"
                    onClick={() => {
                      setIsShowFormMemoInfluencer(!isShowFormMemoInfluencer);
                    }}
                  >
                    <img src={IconNotes} alt="Show notes" height={28} width={28} />
                  </button>
                </div>
                <div className="fw-bold py-1">
                  {textFormMemo}
                </div>
                <Formik
                  initialValues={{ notes: talkRoom.notes ?? '', talk_room_id: talkRoom.id }}
                  onSubmit={_doChangeNotes}
                  validationSchema={UpdateNotesValidation}
                >
                  {({ isValid, isSubmitting, handleSubmit }) => (
                    <Form
                      className={`form-style-1 animation slideDownIn ${
                        isShowFormMemoInfluencer == true ? 'bg-white' : 'd-none d-md-block'
                      }`}
                    >
                      <input type="hidden" name="talk_room_id" />
                      <div className="row mb-4">
                        <div className="col-12 col-lg-10">
                          <Field
                            as="textarea"
                            name="notes"
                            cols="60"
                            rows="2"
                            placeholder={placeholderFormMeno}
                            component={CustomTextArea}
                            disabled={disableTalkRoomForm()}
                          />
                          <div className="form-label mt-2">※メモ欄の内容は相手にも表示されます</div>
                        </div>
                        <div className="col-12 col-lg-2 text-end">
                          <button
                            className="w160 h70 btn btn-outline-green"
                            type="submit"
                            onClick={handleSubmit}
                            disabled={!(isValid) || isSubmitting || disableTalkRoomForm()}
                          >
                            保存
                          </button>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
            {talkRoomSystemNotice[projectData.type][0][currentTalkRoomStatus]?.value && (
              <div className="talk-room-system-notice text-pre-wrap">
                {talkRoomSystemNotice[projectData.type][0][currentTalkRoomStatus].value
                  .replace('{company_npo_name}', talkRoom?.user?.company_npo_name)}
              </div>
            )}
          </div>
          <div className="d-block offcanvas-amazing-body">
            <div className="offcanvas-amazing-content">
              {isLoading && [...Array(20)].map((e, i) => <MessageItem isLoading={isLoading} key={`${i.toString()}`} />)}
              {messages?.length === 0 && !isLoading && <p>メッセージはありません。</p>}
              {!isLoading
                && messages?.map((message, index) => (
                  <MessageItem
                    message={message}
                    isLoading={isLoading}
                    projectData={projectData}
                    key={`message_${index.toString()}_${message.id}`}
                  />
                ))}
              <div className="messages-end-ref" ref={messagesEndRef} />
            </div>
          </div>
          {
            talkRoom.status == constants.TALK_ROOM_STATUS.POST_APPROVAL ? (
              bodyTalkRoomReview(talkRoom.user.user_type)
            ) : (
              <div className="offcanvas-amazing-footer border-top border-4 bg-white pt-2 sticky-bottom">
                <div className="col-12 offcanvas-amazing-footer-form p-0">
                  <FormikProvider value={formik}>
                    <Form className="form-style-1">
                      <input type="hidden" name="talk_room_id" />
                      <div className="row pb-2 pt-sm-4 pt-3 flex-column-reverse flex-lg-row">
                        <div className="col-lg-9">
                          <Field
                            name="contents"
                            cols="60"
                            rows="3"
                            placeholder="メッセージを入力してください"
                            component={CustomTextArea}
                            className="input-content"
                            value={formik.values.contents}
                            disabled={talkRoom.status == constants.TALK_ROOM_STATUS.CANCEL_THE_TRANSACTION
                                    || disableTalkRoomForm()}
                            onChange={
                              (e) => formik.setFieldValue('contents', e.target.value)
                            }
                          />
                        </div>
                        <div className="col-lg-3 content-status">
                          <div className="row">
                            <FormLabel htmlFor="status" className="text-nowrap col-sm-12 col-3 align-self-center">
                              ステータス
                            </FormLabel>
                            <Field
                              name="status"
                              placeholder="選択して下さい"
                              selectData={nextStatus}
                              currentStatus={talkRoom.status}
                              oldStatus={talkRoom.old_status}
                              projectData={projectData}
                              setIsShowVideoUrlForm={setIsShowVideoUrlForm}
                              component={CustomProjectStatusSelectPicker}
                              disabled={formik.isSubmitting || disableTalkRoomForm()}
                              className="col-sm-12 col-9 talk-influencer"
                            />
                          </div>
                        </div>
                      </div>

                      {isShowVideoUrlForm && (
                        <div className="row mt-3 mb-4 form-get-video">
                          <label htmlFor="video_url" className="form-label">
                            <OverlayTrigger
                              key="top"
                              placement="top"
                              overlay={(
                                <Tooltip id="tooltip-top" className="amazing-tooltip">
                                  TikTokアプリで投稿動画のリンクをコピーします。<br />
                                  その後、そのリンクを任意のブラウザで開きます。
                                  ブラウザのアドレスバーに表示されている&quot;https://www.tiktok.com/&quot;から始まるテキストをコピーし、フォームに貼り付けてください。
                                </Tooltip>
                              )}
                            >
                              <span className="align-middle">
                                <QuestionCircleFill className="question-circle-style label-required mb-1" />
                                <span className="label px-1 text-underline"> 投稿動画のURLとは？</span>
                              </span>
                            </OverlayTrigger>
                            <div
                              className="align-middle link d-inline-block cursor-pointer"
                              onClick={() => handleShowGuideVideo()}
                              aria-hidden="true"
                            >
                              <PlayCircle className="question-circle-style link mb-1 ms-2" />
                              <span className="label px-1 text-underline fw-bold"> 動画で手順を確認する</span>
                            </div>
                          </label>
                          <div className="row input-group">
                            <div className="col-xl-7 col-lg-8 col-md-8 col-sm-7 col-12 mb-2">
                              <Field
                                name="video_url"
                                id="video_url"
                                type="text"
                                placeholder="投稿動画のURLを入力してください"
                                className={`form-control input-content ${!formik.isValid ? 'is-invalid' : ''}`}
                                value={formik.values.video_url}
                              />
                              <ErrorMessage component="div" name="video_url" className="invalid-feedback" />
                            </div>
                            <div className="col-xl-2 col-lg-3 col-md-3 col-4 text-end">
                              <button
                                className="btn btn-green btn-video-url"
                                type="button"
                                disabled={formik.isSubmitting || !formik.isValid || formik.values.video_url == ''
                                        || disableTalkRoomForm()}
                                onClick={() => videoURLHandle(formik.values.video_url)}
                              >
                                投稿動画を確定する
                              </button>
                            </div>
                          </div>
                        </div>
                      )}

                      <div className="row pt-2">
                        {/* {!isDisableScheduleButtonInfluencer && (
                          <div className="col-6 text-end">
                            <button
                              className="w160 btn btn-outline-green me-4"
                              type="button"
                              onClick={() => {
                                setIsShowScheduleModal(true);
                              }}
                            >
                              日程調整
                            </button>
                          </div>
                        )} */}
                        <div
                          // className={`${isDisableScheduleButtonInfluencer ? 'col-12 text-center' : 'col-6'}`}
                          className="col-12 text-center"
                        >
                          <button
                            className="w160 btn common-btn btn-send"
                            type="button"
                            disabled={formik.isSubmitting
                                    || (talkRoom.status == constants.TALK_ROOM_STATUS.CANCEL_THE_TRANSACTION)
                                    || disableTalkRoomForm()}
                            onClick={() => {
                              formik.handleSubmit();
                            }}
                          >
                            送信する
                          </button>
                        </div>
                      </div>
                    </Form>
                  </FormikProvider>
                </div>
              </div>
            )
          }
        </div>
      )}
    </>
  );
}

Messages.defaultProps = {
  projectData: {},
  tiktokInfoData: {},
  handleMessageClose: PropTypes.func
};

Messages.propTypes = {
  talkRoomData: PropTypes.object.isRequired,
  isOffCanvas: PropTypes.bool.isRequired,
  handleMessageClose: PropTypes.func,
  projectData: PropTypes.object,
  tiktokInfoData: PropTypes.object
};

export default Messages;
