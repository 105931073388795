import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';

function LeaveGuildSuccessModal({
  isLeaveGuildSuccessModal,
  setIsLeaveGuildSuccessModal
}) {
  const handleClose = () => {
    setIsLeaveGuildSuccessModal(false);
  };

  return (
    <div>
      <Modal className="modal-amazing" show={isLeaveGuildSuccessModal} size="lg" centered>
        <Modal.Header>
          <Modal.Title className="text-center">
            退会手続きが完了しました。<br />
            ご利用いただきありがとうございました。
          </Modal.Title>
        </Modal.Header>
        <Modal.Footer className="justify-content-center border-top-0">
          <Button className="common-btn btn px-5" onClick={handleClose}>
            閉じる
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
LeaveGuildSuccessModal.propTypes = {
  isLeaveGuildSuccessModal: PropTypes.bool.isRequired,
  setIsLeaveGuildSuccessModal: PropTypes.func.isRequired
};

export default LeaveGuildSuccessModal;
