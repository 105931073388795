import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import { getDonationsByUserId } from 'redux/admin/user';
import UserDonationsRow from './UserDonationsRow';


function UserDonations() {
  const dispatch = useDispatch();
  const param = useParams();
  const containerRef = useRef();
  const [loadingData, setLoadingData] = useState(false);
  const [page, setPage] = useState(1);
  const [data, setData] = useState([]);
  const [disableApi, setDisableApi] = useState(false);
  const [dataTotal, setDataTotal] = useState(0);
  const [dataLimit, setDataLimit] = useState(0);

  const loadMoreData = async () => {
    if (!disableApi) {
      setLoadingData(true);
      setDisableApi(true);
      const res = await dispatch(getDonationsByUserId(param.id, page));
      if (res.data.data?.length > 0) {
        setDataTotal(res.data.total);
        setDataLimit(res.data.per_page);
        setData([...data, ...res.data.data]);
        setPage(page + 1);
        setDisableApi(false);
        setLoadingData(false);
      }

      if (res.data.data?.length <= 0) {
        setLoadingData(false);
      }
    }
  };
  useEffect(() => {
    loadMoreData();
  }, []);
  useEffect(() => {
    const handleScroll = () => {
      const container = containerRef.current;
      if (
        container && container.scrollTop + container.clientHeight >= container.scrollHeight && !disableApi
        && dataTotal > dataLimit
      ) {
        loadMoreData();
      }
    };

    const container = containerRef.current;
    if (container) {
      container.addEventListener('scroll', handleScroll);
    }
    return () => {
      if (container) {
        container.removeEventListener('scroll', handleScroll);
      }
    };
  }, [data, disableApi]);

  const renderRows = (list, message = null) => {
    if (list?.length > 0) {
      return list?.map((item, index) => (
        <UserDonationsRow key={`purchase_${index.toString()}`} itemRow={item} />
      ));
    }

    if (message) {
      return (
        <tr>
          <td colSpan={2} className="text-center">
            {message}
          </td>
        </tr>
      );
    }

    return true;
  };

  return (
    <section>
      <h5 className="py-4">寄付ポイント履歴</h5>
      <div className="table-responsive position-relative table-scroll" ref={containerRef}>
        <table className="table table-lg table-hover admin-table-custom-1 mb-0">
          <thead className="border-bottom border-2 bg-light sticky-top top-0">
            <tr>
              <th>日付</th>
              <th>内容</th>
            </tr>
          </thead>
          <tbody>
            {renderRows(data)}

            {
              loadingData && (
                <tr>
                  <td colSpan={2} className="text-center">
                    <Spinner
                      as="span"
                      animation="border"
                      size="lg"
                      role="status"
                      aria-hidden="true"
                      className="text-color-green"
                    />
                  </td>
                </tr>
              )
            }
          </tbody>
        </table>
      </div>
    </section>
  );
}

export default UserDonations;
