import React from 'react';
import DefaultAvatarImg from 'assets/image.png';
import PropTypes from 'prop-types';
import CalculateTeddyBearRank from 'components/common/CalculateTeddyBearRank';
import constants from 'constants/constants';
import RankSystem from 'components/common/RankSystem';
import { Link } from 'react-router-dom';
import * as ROUTES from 'constants/routes';
import useSession from 'hooks/useSession';
import { toMemberPage } from 'helpers/utils';

const renderInfluencerInfo = (talkRoomData, classExtend = '') => {
  return (
    <div className={`d-flex align-items-center ${classExtend}`}>
      <div className="nickname item-title me-4 flex-fill-ct">
        {talkRoomData.nickname}
        {talkRoomData.unread_message_count > 0 && (
          <span className="circle-count circle-count-s32">{talkRoomData.unread_message_count}</span>
        )}
      </div>
      <div className="user-rank-area-new d-flex align-items-center py-1 flex-fill-ct has-teddybear">
        <RankSystem
          userType={constants.USER_TYPE_BY_USER.KEY.influencer}
          rank={talkRoomData.user_rank?.rank || 0}
        />
        <CalculateTeddyBearRank rating={talkRoomData.teddy_bear_rank} />
      </div>
    </div>
  );
};

const renderPaidReward = (influencerReward, classExtend = '') => {
  return (
    <div className={`paid-reward ${classExtend}`}>
      <div className="points d-flex justify-content-center align-items-center">
        <i className="icon-svg point-svg nav-icon" />
        <span className="fsize-32">{influencerReward} pt</span>
      </div>
    </div>
  );
};

function TalkRoomItem({ talkRoom, isDisPlayStatus }) {
  const { user } = useSession();

  return (
    <div className="talk-room-item pb-3 pt-3">
      <div className="row">
        <div className="col-lg-12 d-flex mobile-display">
          <img
            className="rounded-circle"
            width={180}
            height={180}
            src={talkRoom.link_avatar_image || DefaultAvatarImg}
            alt=""
          />

          <div className="content-display-right">
            {
              isDisPlayStatus ? (
                <>
                  <div className="d-flex justify-content-between align-items-center mb-sm-2">
                    <div className="appoint-info">
                      <span className={`btn room-status status-${talkRoom.status}`}>
                        {talkRoom.status == constants.TALK_ROOM_STATUS.RECRUIT
                          && talkRoom?.old_status == constants.TALK_ROOM_STATUS.POST_REQUEST
                          ? '投稿修正依頼' : talkRoom.status_text}
                      </span>
                      {talkRoom.appoint_date && (
                      <span className="appoint-date">
                        日程調整:
                        <b>{talkRoom.appoint_date}</b>
                      </span>
                      )}
                    </div>

                    {renderPaidReward(talkRoom.influencer_reward || 0)}
                  </div>
                  {renderInfluencerInfo(talkRoom, 'flex-wrap-mobile')}
                </>
              ) : (
                <div className="d-flex flex-direction-lg-ct justify-content-between">
                  {renderPaidReward(
                    talkRoom.influencer_reward || 0,
                    'order-lg-2 d-flex justify-content-center align-items-center'
                  )}

                  {renderInfluencerInfo(talkRoom, 'flex-wrap justify-content-lg-between')}
                </div>
              )
            }

            <div className="d-flex flex-column mt-2">
              {talkRoom.msg_sent_time && (<p className="latest-time">{talkRoom.msg_sent_time}</p>)}
              {talkRoom.latest_unread_mgs && (
                <p className="latest-msg text-color-gray-custom">{talkRoom.latest_unread_mgs}</p>
              )}
              {talkRoom.notes && (<p className="latest-note">{talkRoom.notes}</p>)}
              <Link
                className="btn btn-outline-green w208 h50 btn-mobile-with-max"
                to={`${toMemberPage(user?.user_type, ROUTES.PROJECT_CREATION_INFLUENCER_DETAIL)
                  .replace(':id', talkRoom?.tiktok_info?.influencer_id)}`}
              >
                詳細情報を見る
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

TalkRoomItem.defaultProps = {
  talkRoom: {},
  isDisPlayStatus: true
};

TalkRoomItem.propTypes = {
  talkRoom: PropTypes.object,
  isDisPlayStatus: PropTypes.bool
};

export default TalkRoomItem;
