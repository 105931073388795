import React, { useState } from 'react';
import { Modal, Spinner } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import * as ja from 'helpers/yup-locale-ja';
import { Field, Formik, ErrorMessage, FastField } from 'formik';
import PropTypes from 'prop-types';
import { ResponseStatus } from 'constants';
import { CustomDatePicker } from 'components/formik';
import moment from 'moment/moment';
import { createNews, getNews, updateNews } from 'redux/admin/news';
import { setFormikErrors } from 'helpers/utils';
import CustomBottomToRightToast from 'components/admin/common/CustomBottomToRightToast';

const DISPLAY_AT_HEADER = '1';
const NEWS_TYPE_IS_ALL = '4';

Yup.setLocale(ja.suggestive);
const FormSchema = Yup.object().shape({
  news_type: Yup.string().required(),
  title: Yup.string().required(),
  publish_at: Yup.date().required().min(moment().local().format('YYYY-MM-DD HH:mm'))
});

function NewsAddOrEditModal({
  isAddOrEditModalShow,
  setIsAddOrEditModalShow,
  data,
  newsTypeData,
  displayAtData,
  requestParamsFilter,
  setRemountPagingComponent
}) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const dispatch = useDispatch();

  const initFormikValues = {
    id: data?.id ?? null,
    news_type: data?.news_type?.toString() ?? '',
    title: data?.title ?? '',
    body: data?.body ?? '',
    publish_at: data?.publish_at ?? '',
    display_at: data?.display_at?.toString() ?? ''
  };

  const onSubmitForm = async (form, formikHelpers) => {
    setIsSubmitting(true);
    const res = await (form.id?.toString() ? dispatch(updateNews(form)) : dispatch(createNews(form)));
    if (res && res.status === ResponseStatus.HTTP_UNPROCESSABLE_ENTITY) {
      setFormikErrors(res.data.errors, formikHelpers.setFieldError);
      setIsSubmitting(false);
      return;
    }
    if (res && res.status === ResponseStatus.HTTP_OK) {
      dispatch(getNews(1, requestParamsFilter));
      setRemountPagingComponent(Math.random());
      setIsSubmitting(false);
      setIsAddOrEditModalShow(false);
      setShowToast(true);
    } else {
      setIsSubmitting(false);
    }
  };

  return (
    <div>
      <Modal
        onHide={() => setIsAddOrEditModalShow(false)}
        show={isAddOrEditModalShow}
        size="lg"
        centered
        className="user-request-modal form-modal"
      >
        <Modal.Header closeButton />
        <Modal.Body>
          <div className="container">
            <Formik initialValues={initFormikValues} validationSchema={FormSchema} onSubmit={onSubmitForm}>
              {({ handleSubmit, dirty, values, isValid, errors, touched }) => (
                <form className="admin-form-custom-1 form-update form-style-1">
                  <div className="row mt-2">
                    <div className="col-6">
                      <label className="form-label" htmlFor="news_type">
                        区分
                      </label>
                      <Field
                        as="select"
                        name="news_type"
                        id="news_type"
                        className={`form-select rounded-3 
                          ${!isValid && errors.news_type && touched.news_type ? 'is-invalid' : ''}`}
                        required
                      >
                        <option value="">選択して下さい</option>
                        {newsTypeData.map((item) => (
                          <option key={item.key} value={item.key}>
                            {item.value}
                          </option>
                        ))}
                      </Field>
                      <ErrorMessage component="div" name="news_type" className="invalid-feedback" />
                    </div>
                  </div>
                  <div className="row mt-4">
                    <div className="col-12">
                      <label className="form-label" htmlFor="title">
                        タイトル
                      </label>
                      <FastField
                        type="text"
                        name="title"
                        id="title"
                        className={`form-control ${!isValid && errors.title && touched.title ? 'is-invalid' : ''}`}
                        placeholder="入力してください"
                      />
                      <ErrorMessage component="div" name="title" className="invalid-feedback" />
                    </div>
                  </div>
                  <div className="row mt-4">
                    <div className="col-12">
                      <label className="form-label" htmlFor="body">
                        本文
                      </label>
                      <Field
                        as="textarea"
                        rows="5"
                        name="body"
                        id="body"
                        className="form-control"
                        placeholder="入力してください"
                        disabled={values.display_at === DISPLAY_AT_HEADER && values.news_type === NEWS_TYPE_IS_ALL}
                      />
                    </div>
                  </div>
                  <div className="row mt-4">
                    <div className="col-6">
                      <Field
                        name="publish_at"
                        label={['公開日']}
                        placeholderText="日付を選択してください"
                        minDate={Date.now()}
                        dateFormat="yyyy/MM/dd HH:mm"
                        showTimeSelect
                        isNearInput
                        component={CustomDatePicker}
                      />
                    </div>
                    <div className="col-6">
                      <label className="form-label" htmlFor="display_at">
                        表示場所
                      </label>
                      <Field
                        as="select"
                        name="display_at"
                        id="display_at"
                        className="form-select rounded-3"
                        disabled={values.news_type !== NEWS_TYPE_IS_ALL}
                        required
                      >
                        <option value="">表示場所</option>
                        {displayAtData.map((item) => (
                          <option key={item.key} value={item.key}>
                            {item.value}
                          </option>
                        ))}
                      </Field>
                    </div>
                  </div>
                  <div className="row mt-4">
                    <div className="col-12 text-end">
                      <button
                        type="button"
                        className="btn admin-btn-green"
                        onClick={() => {
                          handleSubmit();
                        }}
                        disabled={!(isValid && dirty) || isSubmitting}
                      >
                        {isSubmitting && (
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            className="me-2"
                          />
                        )}
                        登録する
                      </button>
                    </div>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </Modal.Body>
      </Modal>
      <CustomBottomToRightToast msg="追加されました" showToast={showToast} setShowToast={setShowToast} />
    </div>
  );
}
NewsAddOrEditModal.defaultProps = {
  data: null
};
NewsAddOrEditModal.propTypes = {
  isAddOrEditModalShow: PropTypes.bool.isRequired,
  setIsAddOrEditModalShow: PropTypes.func.isRequired,
  data: PropTypes.object,
  newsTypeData: PropTypes.array.isRequired,
  displayAtData: PropTypes.array.isRequired,
  requestParamsFilter: PropTypes.array.isRequired,
  setRemountPagingComponent: PropTypes.func.isRequired
};

export default NewsAddOrEditModal;
