import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getTop5MyProjects } from 'redux/client/project';
import { LoadingContext } from 'hooks/LoadingContext';
import { getTop3MyNews } from 'redux/client/news';
import { getMyPointAndRank } from 'redux/client/user';
import CompanyInformation from './components/CompanyInformation';
import MyPurchaseHistory from './components/MyPurchaseHistory';

function MyPageCompany() {
  const [isLoading, setIsLoading] = useState(false);
  const providerValue = useMemo(() => [isLoading], [isLoading]);

  const dispatch = useDispatch();
  const store = useSelector((state) => ({
    top5MyProjects: state.project.top5MyProjects,
    top3MyNews: state.news.top3MyNews,
    myPointAndRank: state.user.myPointAndRank
  }));

  useEffect(() => {
    initialPage();
  }, []);

  const initialPage = async () => {
    setIsLoading(true);
    try {
      const top5MyProjectsPromise = dispatch(getTop5MyProjects());
      const getTop3MyNewsPromise = dispatch(getTop3MyNews());
      const myPointPromise = dispatch(getMyPointAndRank());
      await Promise.all([
        top5MyProjectsPromise,
        getTop3MyNewsPromise,
        myPointPromise]);
      setIsLoading(false);
      return true;
    } catch (error) {
      setIsLoading(false);
      return error;
    }
  };


  return (
    <LoadingContext.Provider value={providerValue}>
      <div className="page-project my-projects my-page-company">
        <div className="client-cpanel-container">
          <div className="content_wrapper">
            <CompanyInformation
              newsList={store.top3MyNews}
              userCompanyPointsAndRank={store.myPointAndRank}
              myProjects={store.top5MyProjects}
            />
            <MyPurchaseHistory />
          </div>
        </div>
      </div>
    </LoadingContext.Provider>
  );
}

export default MyPageCompany;
