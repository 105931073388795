import { HOME, SIGN_IN } from 'constants/routes';
import React, { useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import TeddyBearSVG from 'assets/svg/teddy-bear-ic.svg';
import { HTTP_UNAUTHORIZED } from 'constants/ResponseStatus';

function PageErrorCommon() {
  const navigate = useNavigate();
  const { state } = useLocation();
  useEffect(() => {
    if (!state) {
      navigate(HOME);
    }

    if (state.error_code && state.error_code == HTTP_UNAUTHORIZED) {
      navigate(SIGN_IN);
    }
  }, []);
  return (
    <div className="client-container error-page">
      <img className="error__img" src={TeddyBearSVG} alt="" />
      {state?.error_code && <h3 className="error__code">{state.error_code}</h3>}
      {state?.error_msg && <p className="error__msg">{state.error_msg}</p>}

      <Link to={HOME} className="btn common-btn error-btn">
        トップに戻る
      </Link>
    </div>
  );
}

export default PageErrorCommon;
