import React, { useEffect, useState } from 'react';
import { Offcanvas, Tab, Tabs } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import useSession from 'hooks/useSession';
import { toMemberPage } from 'helpers/utils';

import { findMyProjectById, PROJECT_TYPE_CANDIDACY, PROJECT_TYPE_NOMINATION } from 'redux/client/project';
import { HTTP_OK } from 'constants/ResponseStatus';
import { ERROR_404_PAGE, MY_PROJECT_LIST } from 'constants/routes';

import {
  getListTalkRoomByProjectId,
  STATUS_ROOM_CANDIDACY,
  STATUS_ROOM_DO_NOT_HIRED,
  STATUS_ROOM_PENDING,
  STATUS_ROOM_TRANSACTION_CANCELLATION_APPROVAL } from 'redux/client/talk_room';
import LoadingOverlay from 'components/loading/LoadingOverlay';
import constants from 'constants/constants';
import TalkRoomItem from './components/TalkRoomItem';
import TalkRoomFilter from './components/TalkRoomFilter';
import Messages from './components/Messages';


function ListTalkRoom() {
  const { id } = useParams();
  const { user } = useSession();
  const userType = user?.user_type;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const store = useSelector((state) => ({
    myProject: state.project.myProjectDetail,
    talkRooms: state.talkRoom.talkRooms
  }));

  const [isLoading, setIsLoading] = useState(false);
  const [currentExchangeStatus, setCurrentExchangeStatus] = useState({
    status: 'all'
  });
  const [allowDisplayedTabs, setAllowDisplayedTabs] = useState({
    exchange: true,
    candidate: false,
    indicationApproval: false,
    application: false,
    hidden: false,
    denial: false
  });

  useEffect(() => {
    getInnitialTalkRoomsData();
  }, []);

  const getInnitialTalkRoomsData = async () => {
    setIsLoading(true);
    const resp = await dispatch(findMyProjectById(id));
    await dispatch(getListTalkRoomByProjectId(id));

    if (resp.status != HTTP_OK || (resp.data && resp.data.length === 0)
    || resp.data.user_id != user.id) {
      navigate(ERROR_404_PAGE, {
        replace: true
      });
    }

    if (resp.data.public == constants.PROJECT_PUBLIC.DRAFT) {
      navigate(toMemberPage(userType, MY_PROJECT_LIST), {
        replace: true
      });
    }

    settingAllowDisplayedTabs(resp.data?.type);
    setIsLoading(false);
  };

  const settingAllowDisplayedTabs = (type = 0) => {
    if (type === PROJECT_TYPE_CANDIDACY) {
      setAllowDisplayedTabs({
        exchange: true,
        candidate: false,
        application: false,
        hidden: false,
        denial: false
      });
    } else if (type === PROJECT_TYPE_NOMINATION) {
      setAllowDisplayedTabs({
        exchange: true,
        candidate: false,
        application: true,
        hidden: true,
        denial: true
      });
    } else {
      setAllowDisplayedTabs({
        exchange: true,
        candidate: true,
        application: true,
        hidden: true,
        denial: true
      });
    }
  };

  const onSubmitTalkRoomFilter = (form) => {
    setCurrentExchangeStatus((preState) => ({
      ...preState,
      ...form
    }));
    dispatch({
      type: 'FILTER_EXCHANGE_TAB',
      payload: {
        status: form.status
      }
    });
  };

  const [show, setShow] = useState(false);
  const [talkRoomSelect, setTalkRoomSelect] = useState(null);
  const handleMessageClose = () => {
    setShow(false);
    setTalkRoomSelect(null);
    dispatch(getListTalkRoomByProjectId(id));
  };
  const handleTalkRoomSelect = async (talkRoom) => {
    setTalkRoomSelect(talkRoom);
    setShow(true);
  };

  return (
    <div className="client-cpanel-container">
      <div className="page-list-talk-room pb-5 pt-5">
        <h3 className="page-title pb-4 pb-4">{store.myProject.project_title}</h3>
        <Tabs
          defaultActiveKey="all"
          id="uncontrolled-tab"
          className="mb-3 amazing-tabs"
        >
          {allowDisplayedTabs.exchange && (
          <Tab
            eventKey="all"
            title={(
              <span>採用済み
                <span className={store.talkRooms?.unread_message_count?.exchange > 0
                  ? 'circle-count circle-count-s24 has-messages' : 'circle-count circle-count-s24'}
                >
                  {!isLoading ? store.talkRooms.exchange?.count : 0}
                </span>
              </span>
              )}
          >
            <div className="subtab-action">
              <TalkRoomFilter initFormikValues={currentExchangeStatus} onSubmit={onSubmitTalkRoomFilter} />
            </div>
            <div className="list-room">
              {isLoading && (
                <LoadingOverlay />
              )}
              {store.talkRooms.exchange?.count === 0 && !isLoading && <p className="mt-4">見つかりません。</p>}
              {!isLoading && store.talkRooms.exchange?.data.map((talkRoom) => (
                <button
                  type="button"
                  onClick={() => { handleTalkRoomSelect(talkRoom); }}
                  className="btn btn-link talk-room-link text-decoration-none"
                  key={`linktalkroom_${talkRoom.id}`}
                >
                  <TalkRoomItem talkRoom={talkRoom} isDisPlayStatus key={`talkroom_${talkRoom.id}`} />
                </button>
              ))}
            </div>
          </Tab>
          )}
          {allowDisplayedTabs.candidate && (
          <Tab
            eventKey={STATUS_ROOM_PENDING}
            title={(
              <span>保留
                <span className={store.talkRooms?.unread_message_count?.candidate > 0
                  ? 'circle-count circle-count-s24 has-messages' : 'circle-count circle-count-s24'}
                >
                  {!isLoading ? store.talkRooms.candidate?.count : 0}
                </span>
              </span>
            )}
          >
            <div className={store.talkRooms.candidate?.count >= 1 && !isLoading ? 'list-room' : ''}>
              {isLoading && (
                <LoadingOverlay />
              )}
              {store.talkRooms.candidate?.count === 0 && !isLoading && <p className="mt-4">見つかりません。</p>}
              {!isLoading && store.talkRooms.candidate?.data.map((talkRoom) => (
                <button
                  type="button"
                  onClick={() => { handleTalkRoomSelect(talkRoom); }}
                  className="btn btn-link talk-room-link text-decoration-none"
                  key={`linktalkroom_${talkRoom.id}`}
                >
                  <TalkRoomItem talkRoom={talkRoom} isDisPlayStatus={false} key={`talkroom_${talkRoom.id}`} />
                </button>
              ))}
            </div>
          </Tab>
          )}

          {allowDisplayedTabs.application && (
          <Tab
            eventKey={STATUS_ROOM_CANDIDACY}
            title={(
              <span>{store.myProject.type != constants.PROJECT_TYPE.SPECIFIED ? '応募' : '指名オファー送信済み'}
                <span className={store.talkRooms?.unread_message_count?.application > 0
                  ? 'circle-count circle-count-s24 has-messages' : 'circle-count circle-count-s24'}
                >
                  {!isLoading ? store.talkRooms.application?.count : 0}
                </span>
              </span>
            )}
          >
            <div className={store.talkRooms.application?.count >= 1 && !isLoading ? 'list-room' : ''}>
              {isLoading && (
                <LoadingOverlay />
              )}
              {store.talkRooms.application?.count === 0 && !isLoading && <p className="mt-4">見つかりません。</p>}
              {!isLoading && store.talkRooms.application?.data.map((talkRoom) => (
                <button
                  type="button"
                  onClick={() => { handleTalkRoomSelect(talkRoom); }}
                  className="btn btn-link talk-room-link text-decoration-none"
                  key={`linktalkroom_${talkRoom.id}`}
                >
                  <TalkRoomItem talkRoom={talkRoom} isDisPlayStatus={false} key={`talkroom_${talkRoom.id}`} />
                </button>
              ))}

            </div>

          </Tab>
          )}
          {allowDisplayedTabs.hidden && (
          <Tab
            eventKey={STATUS_ROOM_DO_NOT_HIRED}
            title={(
              <span>{store.myProject.type === PROJECT_TYPE_NOMINATION ? '否認' : '不採用' }
                <span className={store.talkRooms?.unread_message_count?.hidden > 0
                  ? 'circle-count circle-count-s24 has-messages' : 'circle-count circle-count-s24'}
                >
                  { !isLoading ? store.talkRooms.hidden?.count : 0}
                </span>
              </span>
      )}
          >
            <div className={store.talkRooms.hidden?.count >= 1 && !isLoading ? 'list-room' : ''}>
              {isLoading && (
                <LoadingOverlay />
              )}
              {store.talkRooms.hidden?.count === 0 && !isLoading && <p className="mt-4">見つかりません。</p>}
              {!isLoading && store.talkRooms.hidden?.data.map((talkRoom) => (
                <button
                  type="button"
                  onClick={() => { handleTalkRoomSelect(talkRoom); }}
                  className="btn btn-link talk-room-link text-decoration-none"
                  key={`linktalkroom_${talkRoom.id}`}
                >
                  <TalkRoomItem talkRoom={talkRoom} isDisPlayStatus={false} key={`talkroom_${talkRoom.id}`} />
                </button>
              ))}
            </div>
          </Tab>
          )}
          {allowDisplayedTabs.denial && (
          <Tab
            eventKey={STATUS_ROOM_TRANSACTION_CANCELLATION_APPROVAL}
            title={(
              <span>取引キャンセル
                <span className={store.talkRooms?.unread_message_count?.denial > 0
                  ? 'circle-count circle-count-s24 has-messages' : 'circle-count circle-count-s24'}
                >
                  {!isLoading ? store.talkRooms.denial?.count : 0}
                </span>
              </span>
            )}
          >
            <div className={store.talkRooms.denial?.count >= 1 && !isLoading ? 'list-room' : ''}>
              {isLoading && (
                <LoadingOverlay />
              )}
              {store.talkRooms.denial?.count === 0 && !isLoading && <p className="mt-4">見つかりません。</p>}
              {!isLoading && store.talkRooms.denial?.data.map((talkRoom) => (
                <button
                  type="button"
                  onClick={() => { handleTalkRoomSelect(talkRoom); }}
                  className="btn btn-link talk-room-link text-decoration-none"
                  key={`linktalkroom_${talkRoom.id}`}
                >
                  <TalkRoomItem talkRoom={talkRoom} isDisPlayStatus={false} key={`talkroom_${talkRoom.id}`} />
                </button>
              ))}
            </div>
          </Tab>
          )}
        </Tabs>
      </div>
      {talkRoomSelect && (
        <Offcanvas
          show={show}
          onHide={handleMessageClose}
          placement="end"
          className="offcanvas-amazing message-amazing"
        >
          <Offcanvas.Body className="vh-100 offcanvas-amazing-master-body">
            <Messages
              talkRoomData={talkRoomSelect}
              isOffCanvas
              show={show}
              handleMessageClose={handleMessageClose}
              projectData={store.myProject}
              tiktokInfoData={talkRoomSelect.tiktok_info}
            />
          </Offcanvas.Body>
        </Offcanvas>
      )}
    </div>
  );
}

export default ListTalkRoom;
