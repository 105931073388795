import React, { useEffect, useMemo, useState } from 'react';

import { getTop10News } from 'redux/client/news';
import { getTop5Projects } from 'redux/client/project';
import { useDispatch, useSelector } from 'react-redux';
import { projectTypeData } from 'data/projectTypeData';
import { getPopularCategories } from 'redux/client/category_global';

import { LoadingContext } from 'hooks/LoadingContext';
import TopProject from './components/TopProject';
import PopularGenre from './components/PopularGenre';
import Point from './components/Point';
import News from './components/News';
import ContactLine from './components/ContactLine';


function Home() {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const providerValue = useMemo(() => [isLoading], [isLoading]);

  const store = useSelector((state) => ({
    popularCategories: state.categoryGlobal.popularCategories,
    top5Projects: state.project.top5Projects,
    top10News: state.news.top10News
  }));

  useEffect(() => {
    initialPage();
  }, []);

  const initialPage = async () => {
    try {
      const getPopularCategoryPromise = store.popularCategories.length === 0 ? dispatch(getPopularCategories()) : null;
      const getTop5ProjectPromise = store.top5Projects.length === 0 ? dispatch(getTop5Projects()) : null;
      const getTop10NewsPromise = store.top10News.length === 0 ? dispatch(getTop10News()) : null;
      await Promise.all([getPopularCategoryPromise, getTop5ProjectPromise, getTop10NewsPromise]);
      setIsLoading(false);
      return true;
    } catch (error) {
      return error;
    }
  };

  return (
    <LoadingContext.Provider value={providerValue}>
      <div className="full-row">
        <TopProject top5Projects={store.top5Projects} />
        <ContactLine />
        {/* <PopularCategory categories={store.popularCategories} /> */}
        <PopularGenre projectTypeData={projectTypeData} />
        <Point />
        <News top10News={store.top10News} />
      </div>
    </LoadingContext.Provider>
  );
}

export default Home;
