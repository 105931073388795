import React from 'react';
import PropTypes from 'prop-types';

function IncomeStatistics({ userInfluencer }) {
  return (
    <section className="section-income-statistics">
      <div className="row group-content">
        <div className="col-md-6 col-sm-10">
          <div className="d-flex flex-wrap form-control border-0 bg-overlay light-pink p-4 text-center">
            <div className="flex-fill">
              累計獲得報酬
            </div>
            <div className="flex-fill">
              <h5 className="mb-0">{userInfluencer?.current_total_project_reward} pt</h5>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

IncomeStatistics.propTypes = {
  userInfluencer: PropTypes.object
};

IncomeStatistics.defaultProps = {
  userInfluencer: null
};
export default IncomeStatistics;
