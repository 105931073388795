import { TRANSFER_HISTORY_LIST_SUCCESS } from './transferHistoryActions';

const initialState = {
  transferHistories: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TRANSFER_HISTORY_LIST_SUCCESS:
      return {
        ...state,
        transferHistories: action.payload.data
      };
    default:
      return state;
  }
};
