import React from 'react';
import PropTypes from 'prop-types';

function UserDonationsRow({ itemRow }) {
  return (
    <tr>
      <td>{itemRow.created_at}</td>
      <td>{itemRow.donation_format} <span> pt を使用</span></td>
    </tr>
  );
}

UserDonationsRow.propTypes = {
  itemRow: PropTypes.object.isRequired
};
export default UserDonationsRow;
