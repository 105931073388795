/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useSession from 'hooks/useSession';
import { HTTP_OK } from 'constants/ResponseStatus';
import * as ROUTES from 'constants/routes';
import * as ResponseStatusMessage from 'constants/ResponseStatusMessage';
import constants from 'constants/constants';
import { Button, Modal } from 'react-bootstrap';
import { getFormattedDate, toMemberPage } from 'helpers/utils';
import { applyToProject } from 'redux/client/project';
import { ERROR_PAGE, MYPAGE } from 'constants/routes';

function ApplyProjectConfirm({
  isConfirmShow,
  setIsConfirmShow,
  project,
  isApplyToProject,
  setIsApplyToProject,
  validatorErrorMessage
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSession();
  const [isHandleApplySuccess, setIsHandleApplySuccess] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const messageUrl = toMemberPage(user?.user_type, ROUTES.MESSAGES.replace(':id', project.id));

  const handleApply = async () => {
    setIsProcessing(true);
    setIsConfirmShow(false);
    const resp = await dispatch(applyToProject(project.id));
    if (resp?.status != HTTP_OK) {
      navigate(ERROR_PAGE, {
        replace: true,
        state: {
          error_code: resp.status || 413,
          error_msg: ResponseStatusMessage[resp.status || 413]
        }
      });
    }

    setIsConfirmShow(true);
    setIsProcessing(false);
    setIsApplyToProject(false);
    setIsHandleApplySuccess(true);
  };

  const handleClose = () => {
    setIsConfirmShow(false);
    setIsApplyToProject(true);
  };

  return (
    <div>
      <Modal show={isConfirmShow} onHide={handleClose} size="lg" centered className="modal-amazing">
        <Modal.Header closeButton />
        {isApplyToProject && !isHandleApplySuccess && (
          <>
            <Modal.Title className="text-center">このプロジェクトに応募しますか？</Modal.Title>
            <Modal.Body className="w-100">
              <ul className="list-unstyled mt-4">
                {project?.user_type_create_project === constants.USER_TYPE_BY_USER.KEY.npo
                  && project.type === constants.PROJECT_TYPE.CANDIDACY && (
                  <li className="pb-3">
                    このプロジェクトはNPO団体の社会貢献活動を支援する<span className="text-danger">ボランティア型のプロジェクト</span>です。<br />
                    <span className="text-danger">金銭報酬は発生しない</span>ため予めご了承ください
                  </li>
                )}
                <li className="title-sub pb-3">{project.project_title}</li>
                <li className="pb-3">納品期限：{getFormattedDate(project.delivery_at)}</li>
                <li className="pb-3">特記事項
                  <p className="notes pt-2">{project.notes}</p>
                </li>
              </ul>
            </Modal.Body>
            <Modal.Footer className="justify-content-center border-top-0 pb-5 w-100">
              <Button
                variant="apply-project common-btn"
                onClick={handleApply}
                disabled={isProcessing}
              >
                応募する
              </Button>
            </Modal.Footer>
          </>
        )}
        {!isApplyToProject && !isHandleApplySuccess && (
          <>
            <Modal.Title className="text-center">プロジェクトの応募条件を満たしていません</Modal.Title>
            <Modal.Body>
              {validatorErrorMessage == constants.VALIDATOR_APPLY_PROJECT_MESSAGE.no_salary_change_request ? (
                <div className="card card-info pb-4">
                  <div className="card-body justify-content-md-center">
                    <div className="pb-lg-0 pb-3">
                      <div className="title-sub mb-3">報酬変更申請は完了していますか？</div>
                      <span>
                        最終報酬変更申請日から8日以上経過している場合、プロジェクトへの応募及び指名オファーが届きません<br />
                        マイページから報酬変更申請をしてください。
                      </span>
                    </div>
                  </div>

                  <div className="d-flex justify-content-center">
                    <Button
                      variant="modal-redirect"
                      className="common-btn btn__reward-change"
                      onClick={() => navigate(ROUTES.REWARD_CHANGE)}
                    >
                      報酬変更申請
                    </Button>
                  </div>
                </div>
              ) : (
                <div className="text-center h4">{validatorErrorMessage}</div>
              )}
            </Modal.Body>
            <Modal.Footer className="justify-content-center border-top-0">
              <Button
                variant="modal-redirect"
                className="common-btn"
                onClick={() => navigate(toMemberPage(user.user_type, MYPAGE))}
              >
                プロジェクト一覧へ
              </Button>
            </Modal.Footer>
          </>
        )}
        {isHandleApplySuccess && (
        <>
          <Modal.Title className="text-center">応募が完了しました</Modal.Title>
          <div className="row justify-content-center mt-5 p-5">
            <div className="col-lg-6 col-md-12">
              <Button
                variant="modal-redirect"
                className="common-btn btn-applied"
                onClick={() => navigate(ROUTES.HOME)}
              >
                戻る
              </Button>
            </div>
            <div className="col-lg-6 col-md-12 mt-3 mt-lg-0">
              <Button
                variant="modal-redirect"
                className="common-btn btn-applied"
                onClick={() => navigate(messageUrl, { replace: true })}
              >
                プロジェクトトークルームへ
              </Button>
            </div>

          </div>
        </>
        )}
      </Modal>
    </div>
  );
}

export default ApplyProjectConfirm;
