import React from 'react';
import PropTypes from 'prop-types';
import constants from 'constants/constants';

const renderIconRankClass = (userTypeNumber, rankNumber) => {
  let className = '';
  if (userTypeNumber == constants.USER_TYPE_BY_USER.KEY.company) {
    switch (true) {
      case (rankNumber <= 13):
        className = `company-rank-0-13-${String(rankNumber).length > 2 ? 'number-than-2-svg' : 'svg'} rank-color-1`;
        break;
      case (rankNumber <= 40):
        className = `company-rank-14-40-${String(rankNumber).length > 2 ? 'number-than-2-svg' : 'svg'} rank-color-1`;
        break;
      case (rankNumber <= 81):
        className = `company-rank-41-81-${String(rankNumber).length > 2 ? 'number-than-2-svg' : 'svg'} rank-color-1`;
        break;
      case (rankNumber <= 135):
        className = `company-rank-82-135-${String(rankNumber).length > 2 ? 'number-than-2-svg' : 'svg'} rank-color-2`;
        break;
      default:
        className = `company-rank-271-${String(rankNumber).length > 2 ? 'number-than-2-svg' : 'svg'} rank-color-2`;
    }
  }

  if (userTypeNumber == constants.USER_TYPE_BY_USER.KEY.influencer) {
    switch (true) {
      case (rankNumber <= 15):
        className = `influencer-rank-0-15-${String(rankNumber).length > 2 ? 'number-than-2-svg' : 'svg'} rank-color-1`;
        break;
      case (rankNumber <= 30):
        className = `influencer-rank-16-30-${String(rankNumber).length > 2 ? 'number-than-2-svg' : 'svg'} rank-color-1`;
        break;
      case (rankNumber <= 77):
        className = `influencer-rank-31-77-${String(rankNumber).length > 2 ? 'number-than-2-svg' : 'svg'} rank-color-1`;
        break;
      case (rankNumber <= 200):
        // eslint-disable-next-line max-len
        className = `influencer-rank-78-200-${String(rankNumber).length > 2 ? 'number-than-2-svg' : 'svg'} rank-color-2`;
        break;
      case (rankNumber <= 500):
        // eslint-disable-next-line max-len
        className = `influencer-rank-201-500-${String(rankNumber).length > 2 ? 'number-than-2-svg' : 'svg'} rank-color-2`;
        break;
      default:
        className = `influencer-rank-500-${String(rankNumber).length > 2 ? 'number-than-2-svg' : 'svg'} rank-color-2`;
    }
  }

  return className;
};

function RankSystem({ userType, rank }) {
  return (
    <span className={`rank-icon ${String(rank).length > 2 ? 'rank-icon_number-than-2' : ''}`}>
      <i className={`icon-svg ${renderIconRankClass(userType, rank)}`}>
        <span className={`rank-number rank-number_${String(rank).length}`}>{rank}</span>
      </i>
    </span>
  );
}

RankSystem.defaultProps = {
  userType: 0,
  rank: 0
};

RankSystem.propTypes = {
  userType: PropTypes.number,
  rank: PropTypes.number
};

export default RankSystem;
