import React, { useState, useEffect } from 'react';
import { Field, Formik } from 'formik';

import constants from 'constants/constants';
import PropTypes from 'prop-types';
import { CustomSelectPicker } from 'components/formik';
import { userTypeData } from 'data/userTypeData';
import { useParams } from 'react-router-dom';
import SearchIcon from 'assets/search.png';

function ProjectFilter({ areaData, projectTypeData, onSubmitFilterForm }) {
  const routeParams = useParams();
  const [isUserTypeNpo, setIsUserTypeNpo] = useState(false);
  const [isShowForm, setIsShowForm] = useState(false);

  useEffect(() => {
    if (routeParams.user_type === constants.USER_TYPE_BY_USER.TEXT?.[2]) {
      setIsUserTypeNpo(true);
    } else {
      setIsUserTypeNpo(false);
    }
  }, [routeParams.user_type]);

  const initFormikValues = {
    user_type: constants.USER_TYPE_BY_USER.KEY?.[routeParams.user_type] || '',
    area_id: '',
    project_type: routeParams.project_type ?? '',
    category_id: routeParams.category_id ?? '',
    type_id: isUserTypeNpo ? `${constants.PROJECT_TYPE.CANDIDACY}` : '',
    order: 'normal'
  };

  const handleToggleForm = () => {
    setIsShowForm(!isShowForm);
  };

  return (
    <Formik
      initialValues={initFormikValues}
      onSubmit={onSubmitFilterForm}
      enableReinitialize
    >
      {({ handleChange, handleSubmit }) => (
        <div className="mt-4 section filter">
          <div className="header">
            <h2 className="title-bg">Search Entry</h2>
            <h2 className="title d-none d-md-block">検索項目</h2>
          </div>

          <div
            className={`mobile-filter d-md-none d-flex align-items-center justify-content-center
            py-1 mt-2 mt-sm-4 text-center ${isShowForm ? 'show-form' : ''}`}
            onClick={handleToggleForm}
            onKeyDown={handleToggleForm}
            role="button"
            tabIndex="0"
          >
            <span className="font-mb-26 fw-bold pe-2">検索項目</span> <img src={SearchIcon} alt="Search" />
          </div>
          <form className={`${isShowForm ? 'd-block' : 'd-none'} d-md-block animation slideDownIn`}>
            <div className="project-filter_group mt-md-4 d-flex flex-wrap justify-content-start">
              <div className="filter-input filter-input_user-type">
                <Field
                  id="user_type"
                  name="user_type"
                  onChange={handleSubmit}
                  label={['区分']}
                  placeholder="選択して下さい"
                  selectData={userTypeData}
                  component={CustomSelectPicker}
                />
              </div>
              <div className="filter-input filter-input_area">
                <Field
                  id="area_id"
                  name="area_id"
                  onChange={handleSubmit}
                  label={['エリア']}
                  placeholder="都道府県"
                  selectData={areaData}
                  component={CustomSelectPicker}
                />
              </div>
              <div className="filter-input filter-input_project-type">
                <Field
                  id="project_type"
                  name="project_type"
                  onChange={handleSubmit}
                  label={['タイプ']}
                  placeholder="タイプ"
                  selectData={projectTypeData}
                  component={CustomSelectPicker}
                />
              </div>
            </div>
            <div className="mt-1 mt-sm-4 d-flex group-radio-button">
              <div className="order-label">
                <label className="form-label order" htmlFor="order">
                  並べ替え
                </label>
              </div>
              <div className="mb-3 radio-button">
                <Field
                  id="order1"
                  type="radio"
                  name="order"
                  value="normal"
                  onChange={(e) => {
                    handleChange(e);
                    handleSubmit();
                  }}
                />
                <label htmlFor="order1">通常順</label>
              </div>
              <div className="mb-3 radio-button">
                <Field
                  id="order2"
                  type="radio"
                  name="order"
                  value="favorite"
                  onChange={(e) => {
                    handleChange(e);
                    handleSubmit();
                  }}
                />
                <label htmlFor="order2">人気順</label>
              </div>
              <div className="mb-sm-3 radio-button">
                <Field
                  id="order3"
                  type="radio"
                  name="order"
                  value="public_date"
                  onChange={(e) => {
                    handleChange(e);
                    handleSubmit();
                  }}
                />
                <label htmlFor="order3">新着順</label>
              </div>
              <div className="mb-sm-3 radio-button">
                <Field
                  id="order4"
                  type="radio"
                  name="order"
                  value="delivery_at"
                  onChange={(e) => {
                    handleChange(e);
                    handleSubmit();
                  }}
                />
                <label htmlFor="order4">終了日が近い順</label>
              </div>
            </div>
            <div className="mt-1 mt-sm-2 d-flex group-radio-button">
              <div className="order-label">
                <label className="form-label order" htmlFor="order">
                  プロジェクト形式
                </label>
              </div>
              <div className="mb-3 radio-button">
                <Field
                  id="type1"
                  type="radio"
                  name="type_id"
                  disabled={isUserTypeNpo}
                  value={`${constants.PROJECT_TYPE.PUBLIC}`}
                  onChange={(e) => {
                    handleChange(e);
                    handleSubmit();
                  }}
                />
                <label htmlFor="type1" className={isUserTypeNpo ? 'disabled' : ''}>立候補式</label>
              </div>
              <div className="mb-3 radio-button">
                <Field
                  id="type2"
                  type="radio"
                  name="type_id"
                  value={`${constants.PROJECT_TYPE.CANDIDACY}`}
                  onChange={(e) => {
                    handleChange(e);
                    handleSubmit();
                  }}
                />
                <label htmlFor="type2">先着式</label>
              </div>
            </div>
          </form>
        </div>
      )}
    </Formik>
  );
}
ProjectFilter.propTypes = {
  areaData: PropTypes.array.isRequired,
  projectTypeData: PropTypes.array.isRequired,
  onSubmitFilterForm: PropTypes.func.isRequired
};
export default ProjectFilter;
