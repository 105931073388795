import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { getFormattedDate } from 'helpers/utils';
import * as ROUTES from 'constants/routes';
import { Link } from 'react-router-dom';
import { LoadingContext } from 'hooks/LoadingContext';
import Skeleton from 'react-loading-skeleton';

function News({ top10News }) {
  const [isLoading] = useContext(LoadingContext);

  return (
    <section className="news margin-content">
      <div className="client-container">
        <div className="row">
          <div className="col-12 col-lg-3">
            <div className="section title">
              <div className="header">
                <h2 className="title-bg">News</h2>
                <h2 className="title">お知らせ</h2>
                <h3 className="position-absolute outline-1">Topics</h3>
                <h3 className="position-absolute outline-2">Topics</h3>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-8 news-content">
            {isLoading ? [...Array(4)].map((e, i) => (
              <div className="news__item" key={`${i.toString()}`}>
                <Skeleton height={24} />
              </div>
            )) : top10News?.map((item) => (
              <div key={item.id} className="d-flex align-items-md-center flex-column flex-md-row news__item">
                <div className="date">
                  <span>{getFormattedDate(item.publish_at)}</span>
                </div>
                <div className="title">
                  <Link
                    to={ROUTES.NEWS_DETAIL.replace(':id', item.id)}
                    title={item.title}
                    className="custom-maxline-1"
                  >
                    {item.title}
                  </Link>
                </div>
              </div>
            ))}
            <div className="d-flex justify-content-end">
              <Link className="action_new" to={ROUTES.NEWS_LIST}>
                お知らせ一覧
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
News.propTypes = {
  top10News: PropTypes.array.isRequired
};
export default News;
