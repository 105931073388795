import { GET_REWARD_RATE_SETTING_SUCCESS, UPDATE_OR_CREATE_RATE_SETTING_SUCCESS } from './rewardRateSettingActions';

const initialState = {
  data: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_REWARD_RATE_SETTING_SUCCESS:
      return {
        ...state,
        data: action.payload.data
      };

    case UPDATE_OR_CREATE_RATE_SETTING_SUCCESS: {
      const { data } = state;
      const { payload } = action;

      const newData = data && data.success ? [payload] : data.map((item) => {
        if (item.type == payload.type) {
          return payload;
        }
        return item;
      });

      if (!newData.includes(payload)) {
        newData.push(payload);
      }

      return {
        ...state,
        data: newData
      };
    }

    default:
      return state;
  }
};
