/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import useSession from 'hooks/useSession';
import { toMemberPage } from 'helpers/utils';
import * as ROUTES from 'constants/routes';
import { useNavigate, Link } from 'react-router-dom';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { QuestionCircleFill, ExclamationTriangle } from 'react-bootstrap-icons';
import CalculateRatingStars from 'components/common/CalculateRatingStars';
import CalculateTeddyBearRank from 'components/common/CalculateTeddyBearRank';
import constants from 'constants/constants';
import RankSystem from 'components/common/RankSystem';
import UnTickIcon from 'assets/svg/un-tick.svg';
import TickIcon from 'assets/svg/tick.svg';
import MyPointIcon from 'assets/svg/point-influencer.svg';
import Image from 'components/common/Image';
import ProjectNews from './ProjectNews';

function InfluencerInformation({ newsList, influencerInformation, myProjects }) {
  const { user } = useSession();
  const navigate = useNavigate();

  const linkToMyProjectListFavorite = (e) => {
    e.preventDefault();
    navigate(toMemberPage(user?.user_type, ROUTES.MY_PROJECT_LIST), {
      replace: true,
      state: {
        active_tab_favorite: true
      }
    });
  };

  const linkToMyProjectList = (tabId) => {
    const urlRedirect = `${ROUTES.MY_PROJECT_LIST}?tabActive=${tabId}`;
    navigate(toMemberPage(user?.user_type, urlRedirect));
  };

  return (
    <div className="row mypage-top__row">
      <div className="col-12">
        <div className="card box-style-1__border mypage-user-info-left__card mb-2">
          <div className="card-body d-flex align-items-center">
            <div className="card-left me-3 me-xl-5">
              <div className="tiktok-avatar">
                <Image
                  src={influencerInformation?.tiktok_info?.link_avatar_image}
                  alt={influencerInformation?.tiktok_info?.nickname}
                />
              </div>
            </div>
            <div className="card-right">
              <div className="card-right-top d-flex align-items-center">
                <div className="tiktok-name">{influencerInformation?.tiktok_info?.nickname}</div>
                <div className="my-review d-flex align-items-center mx-5">
                  <CalculateRatingStars
                    rating={influencerInformation.project_review_average_total_point || 0}
                    size={20}
                  />
                  <span className="ms-2"> ({influencerInformation.project_review_count || 0})</span>
                </div>
                <div className="user-rank d-flex flex-wrap align-items-center">
                  <RankSystem
                    userType={constants.USER_TYPE_BY_USER.KEY.influencer}
                    rank={(typeof influencerInformation.current_user_rank === 'undefined') ? 0 : parseInt(influencerInformation.current_user_rank, 10)}
                  />
                  <OverlayTrigger
                    key="top"
                    placement="top"
                    overlay={(
                      <Tooltip id="tooltip-top" className="amazing-tooltip">
                        プロジェクトの受注数・評価に応じてランクが変動し、ランクが高いほど企業からの指名オファーが届きやすくなります
                      </Tooltip>
                    )}
                  >
                    <span className="align-middle ms-0 ms-md-2 me-2 me-md-0">
                      <QuestionCircleFill className="question-circle-style label-icon ms-0 ms-sm-1" size={25} />
                    </span>
                  </OverlayTrigger>
                  <div className="ms-sm-2 my-1">
                    <CalculateTeddyBearRank rating={influencerInformation.teddy_bear_rank} />
                  </div>
                  <div className="my-point d-flex d-md-none me-5">
                    <img src={MyPointIcon} alt="" className="me-1 me-lg-3" />
                    <span>
                      <strong className="d-inline-block me-2 me-lg-3">
                        {influencerInformation.my_points || 0}
                      </strong>
                      <small>Pt</small>
                    </span>
                    <OverlayTrigger
                      key="top"
                      placement="top"
                      overlay={(
                        <Tooltip id="tooltip-top" className="amazing-tooltip">
                          現在保有しているポイントの合計数
                        </Tooltip>
                      )}
                    >
                      <span className="align-middle align-self-center ms-0 ms-md-2 ms-2 ms-md-0">
                        <QuestionCircleFill className="question-circle-style label-icon ms-0 ms-sm-1" size={25} />
                      </span>
                    </OverlayTrigger>
                  </div>
                </div>
              </div>
              <div className="card-right-botom d-flex">
                <div className="my-point d-none d-md-flex me-5">
                  <img src={MyPointIcon} alt="" className="me-1 me-lg-3" />
                  <span>
                    <strong className="d-inline-block me-2 me-lg-3">
                      {influencerInformation.my_points || 0}
                    </strong>
                    <small>Pt</small>
                  </span>
                  <OverlayTrigger
                    key="top"
                    placement="top"
                    overlay={(
                      <Tooltip id="tooltip-top" className="amazing-tooltip">
                        現在保有しているポイントの合計数
                      </Tooltip>
                    )}
                  >
                    <span className="align-middle align-self-center ms-0 ms-md-2 me-2 me-md-0">
                      <QuestionCircleFill className="question-circle-style label-icon ms-0 ms-sm-1" size={25} />
                    </span>
                  </OverlayTrigger>
                </div>
                <div className="user-verify d-flex align-items-center">
                  {influencerInformation.has_bank_account ? (
                    <>
                      <img src={TickIcon} alt="" className="me-2 has-tick" /> 振込口座登録済み
                    </>
                  ) : (
                    <>
                      <img src={UnTickIcon} alt="" className="me-2" /> 振込口座未登録
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="alert-box d-flex align-items-center justify-content-center p-2">
          <span className="align-middle me-3">
            <ExclamationTriangle className="question-circle-style label-required mb-1 ms-3" size={35} />
          </span>
          <span className="text-center">
            報酬獲得後も継続してPR動画の公開にご協力ください<br />
            ※報酬獲得後、2週間以内にPR動画を削除した場合は獲得した報酬ptは無効となります
          </span>
        </div>

        <ProjectNews newsList={newsList} />

        <div className="user-statistical row">
          <div className="col-12 col-xl-7 project-statistical">
            <div className="project-statistical-inner">
              <div
                className="project-statistical-item processing"
                onClick={() => linkToMyProjectList(0)}
                onKeyDown={() => linkToMyProjectList(0)}
                role="button"
                tabIndex="0"
              >
                <div className="number">{myProjects?.count?.processing || 0}</div>
                <div className="type-text">進行中</div>
              </div>
              <div
                className="project-statistical-item recruiting"
                onClick={() => linkToMyProjectList(1)}
                onKeyDown={() => linkToMyProjectList(1)}
                role="button"
                tabIndex="0"
              >
                <div className="number">{myProjects?.count?.recruiting?.applying || 0}</div>
                <div className="type-text">応募中</div>
              </div>
              <div
                className="project-statistical-item offer"
                onClick={() => linkToMyProjectList(2)}
                onKeyDown={() => linkToMyProjectList(2)}
                role="button"
                tabIndex="0"
              >
                <div className="number">{myProjects?.count?.offer || 0}</div>
                <div className="type-text">オファー</div>
              </div>
              <div
                className="project-statistical-item archive"
                onClick={() => linkToMyProjectList(3)}
                onKeyDown={() => linkToMyProjectList(3)}
                role="button"
                tabIndex="0"
              >
                <div className="number">{myProjects?.count?.archive || 0}</div>
                <div className="type-text">アーカイブ</div>
              </div>
            </div>
            <div className="text-end mt-3">
              <Link
                to="/"
                onClick={(e) => linkToMyProjectListFavorite(e)}
                className="hyperlink"
              >
                お気に入りプロジェクト &gt;
              </Link>
            </div>
          </div>
          <div className="col-12 col-xl-5 reward-statistical">
            <div className="reward-statistical-inner">
              <div className="block-title">
                <span>あなたの戦闘力</span>
                <OverlayTrigger
                  key="top"
                  placement="top"
                  overlay={(
                    <Tooltip id="tooltip-top" className="amazing-tooltip">
                      プロジェクトに参加することで、以下の報酬額を獲得することができます
                    </Tooltip>
                  )}
                >
                  <span className="align-middle ms-3">
                    <QuestionCircleFill className="question-circle-style label-icon ms-0" size={25} />
                  </span>
                </OverlayTrigger>
              </div>
              <div className="reward-statistical-items-wrap d-flex justify-content-between">
                <div className="reward-statistical-item public d-flex flex-column align-items-center justify-content-center">
                  <div className="type-text">立候補式</div>
                  <i className="icon-svg public-project-svg mt-3 mb-1" />
                  <div className="reward-point">
                    <strong>
                      {influencerInformation.public_reward || 0}
                      <small className="ms-1">Pt</small>
                    </strong>
                  </div>
                </div>
                <div className="reward-statistical-item candidacy d-flex flex-column align-items-center justify-content-center">
                  <div className="type-text">先着式</div>
                  <i className="icon-svg candidacy-project-svg mt-3 mb-1" />
                  <div className="reward-point">
                    <strong>
                      {influencerInformation.order_reward || 0}
                      <small className="ms-1">Pt</small>
                    </strong>
                  </div>
                </div>
                <div className="reward-statistical-item specified d-flex flex-column align-items-center justify-content-center">
                  <div className="type-text">指名式</div>
                  <i className="icon-svg specified-project-svg mt-3 mb-1" />
                  <div className="reward-point">
                    <strong>
                      {influencerInformation.certain_reward || 0}
                      <small className="ms-1">Pt</small>
                    </strong>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
InfluencerInformation.propTypes = {
  influencerInformation: PropTypes.object.isRequired,
  myProjects: PropTypes.object.isRequired,
  newsList: PropTypes.array.isRequired
};
export default InfluencerInformation;
