import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Spinner } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

import { ResponseStatus } from 'constants';
import { deleteTag, getTags } from 'redux/admin/tag';
import CustomBottomToRightToast from 'components/admin/common/CustomBottomToRightToast';

function DeleteTagModal({ show, setShow, tagId, setRemountComponent }) {
  const dispatch = useDispatch();
  const [showToast, setShowToast] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);

  const handleDelete = async () => {
    setIsProcessing(true);
    const res = await dispatch(deleteTag(tagId));
    if (res && res.status === ResponseStatus.HTTP_OK) {
      await dispatch(getTags());
      setRemountComponent(Math.random());
      setIsProcessing(false);
      setShow(false);
      setShowToast(true);
    }
  };

  return (
    <>
      <Modal
        onHide={() => setShow(false)}
        show={show}
        size="lg"
        centered
        className="user-request-modal form-modal"
      >
        <Modal.Header closeButton />
        <Modal.Body>
          <div className="container text-center">
            <h4>データを削除すると復元することができません。</h4>
            <h4>データを削除しますか？</h4>
            <button
              type="button"
              className="btn admin-btn-green mt-4"
              disabled={isProcessing}
              onClick={() => {
                handleDelete();
              }}
            >
              {isProcessing && (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  className="me-2"
                />
              )}
              削除する
            </button>
          </div>
        </Modal.Body>
      </Modal>

      {/* Notification when delete tag success */}
      <CustomBottomToRightToast msg="削除されました" showToast={showToast} setShowToast={setShowToast} />
    </>
  );
}

DeleteTagModal.propTypes = {
  show: PropTypes.bool.isRequired,
  setShow: PropTypes.func.isRequired,
  tagId: PropTypes.number.isRequired,
  setRemountComponent: PropTypes.func.isRequired
};

export default DeleteTagModal;
