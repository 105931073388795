import React, { useEffect, useRef, useState } from 'react';
import { Dropdown, Nav, Navbar, NavDropdown, OverlayTrigger, Tooltip } from 'react-bootstrap';
import * as ROUTES from 'constants/routes';
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';
import useSession from 'hooks/useSession';
import { PersonFill, EnvelopeFill, PlusLg, QuestionCircleFill } from 'react-bootstrap-icons';
import { logOut, USER_TYPE_COMPANY, USER_TYPE_INFLUENCER, USER_TYPE_NPO, getDashboardCount } from 'redux/client/auth';
import { useDispatch, useSelector } from 'react-redux';
import { toMemberPage } from 'helpers/utils';
import { getNewsHeader } from 'redux/client/news';
import LOGO from 'assets/logo-black.svg';
import constants from 'constants/constants';

function NavDropdownTitleUser() {
  return (
    <>
      <PersonFill color="#98A6B5" size={24} />
      <span className="userinfo-name">マイページ</span>
    </>
  );
}

function Navigation() {
  const location = useLocation();
  const navigate = useNavigate();
  const [hideOnScroll, setHideOnScroll] = useState(false);
  const { user, unreadMessages, myPoints } = useSession();
  const [isProcessing, setIsProcessing] = useState(false);
  const userType = user?.user_type;
  const dispatch = useDispatch();
  const newsHeaderItem = useSelector((state) => state.news.newsHeader);

  const intervalCurrTalkRoomRef = useRef(null);

  useEffect(() => {
    setHideOnScroll(false);
    document.body.classList.remove('show-nav-mobile');
  }, [location.key]);

  useEffect(() => {
    _getNewsHeader();
  }, []);

  useEffect(() => {
    intervalCurrTalkRoomRef.current = setInterval(() => {
      _getDashboardCount();
    }, 10 * 1000);
    return () => clearInterval(intervalCurrTalkRoomRef.current);
  }, []);

  const handleLogOut = async () => {
    setIsProcessing(true);
    await dispatch(logOut());
    clearInterval(intervalCurrTalkRoomRef.current);
    setIsProcessing(false);
  };

  const _getDashboardCount = async () => {
    if (!user) return;

    dispatch(getDashboardCount());
  };

  const _getNewsHeader = async () => {
    try {
      const getNewsHeaderPromise = Object.keys(newsHeaderItem).length === 0 ? dispatch(getNewsHeader()) : null;
      await Promise.all([getNewsHeaderPromise]);
      return true;
    } catch (error) {
      return error;
    }
  };

  const handleScrollNav = () => {
    document.body.classList.toggle('show-nav-mobile');
    setHideOnScroll(!hideOnScroll);
  };

  return (
    <Navbar
      expand="xl"
      fixed="top"
      id="maiMenuWrapper"
      className={`main-menu-wrapper ${Object.keys(newsHeaderItem).length > 0 ? 'has-top-bar' : ''} ${
        user && 'nav-logged'
      }`}
    >
      {Object.keys(newsHeaderItem).length > 0 && (
        <div className="news-header">
          <div className="client-container text-center">
            {newsHeaderItem.title}
          </div>
        </div>
      )}
      <div className="main-menu__client client-container">
        <Navbar.Brand as="div" className="navbar-brand-box">
          <div>
            <Link to="/" className="text-decoration-none">
              <img src={LOGO} alt="インフルエンサーマッチング Webアプリ" className="navbar-brand__logo" />
            </Link>
            <h1 className="title-page mb-0">エンマがつなぐ、未来が変わる</h1>
          </div>
          <div>
            {user && (
              <Link
                to={toMemberPage(userType, ROUTES.MY_PROJECT_LIST)}
                className={`messages-mobile me-4${unreadMessages ? ' badge' : ''}`}
              >
                <EnvelopeFill color="#98A6B5" size={28} />
              </Link>
            )}
            <button
              type="button"
              className={`navbar-toggler ${!hideOnScroll && 'collapsed'}`}
              onClick={() => handleScrollNav()}
            >
              <span className="navbar-toggler-icon"> </span>
            </button>
          </div>
        </Navbar.Brand>
        <Navbar.Collapse in={hideOnScroll} id="responsive-navbar-nav" className="menu-navbar justify-content-xl-end">
          <Nav className="ms-auto align-items-xl-center" navbarScroll>
            {user && (
              <div className="siderbar-user-point nav-sidebar-link">
                <div className="d-flex justify-content-between">
                  <span>所持ポイント: <strong>{myPoints}</strong><small>pt</small></span>
                  <Link
                    to={userType == USER_TYPE_COMPANY ? toMemberPage(userType, ROUTES.MY_POINT_INDEX)
                      : toMemberPage(userType, ROUTES.TRANSFER_HISTORY)}
                    className="d-inline-block ms-5 hyperlink"
                  >
                    {userType == USER_TYPE_COMPANY ? 'ポイント購入' : 'ポイント管理'} &gt;
                  </Link>
                </div>
              </div>
            )}
            {!user && (
              <Nav.Link href="https://influence.enma.buzz/corporate/" className="nav-menu-link nav-link">
                プロジェクトをはじめる
              </Nav.Link>
            )}
            <Nav.Link as={Link} to={ROUTES.PROJECT_LIST} className="nav-menu-link">
              プロジェクトを探す
            </Nav.Link>
            <Nav.Link
              as={Link}
              to={ROUTES.PROJECT_USER_TYPE_LIST.replace(':user_type', constants.USER_TYPE_BY_USER.TEXT[2])}
              className="nav-menu-link"
            >
              テディベアプロジェクトを探す
              <OverlayTrigger
                key="top"
                placement="top"
                overlay={(
                  <Tooltip id="tooltip-top" className="amazing-tooltip">
                    社会公益性の高い活動を行う団体の支援を目的としたプロジェクトです
                  </Tooltip>
                )}
              >
                <QuestionCircleFill className="question-circle-style label-icon ms-2" size={20} />
              </OverlayTrigger>
            </Nav.Link>

            {user && (
              <>
                {userType != USER_TYPE_INFLUENCER && (
                  <>
                    <Nav.Link
                      as={NavLink}
                      to={toMemberPage(userType, ROUTES.MY_PROJECT_CREATE)}
                      className="nav-sidebar-link my-project-create"
                    >
                      <PlusLg className="fw-bold" />
                      新規プロジェクト作成
                    </Nav.Link>
                    {userType === USER_TYPE_COMPANY && (
                    <Nav.Link
                      as={NavLink}
                      to={ROUTES.COMPANY_SEARCH_INFLLUENCERS}
                      className="nav-sidebar-link"
                    >
                      インフルエンサーを探す
                    </Nav.Link>
                    )}
                  </>
                )}
                <Nav.Link
                  as={NavLink}
                  to={toMemberPage(userType, ROUTES.MYPAGE)}
                  className="nav-sidebar-link my-projects-list"
                >
                  <PersonFill color="#98A6B5" size={30} />
                  マイページ
                </Nav.Link>
                <Nav.Link
                  as={NavLink}
                  to={toMemberPage(userType, ROUTES.MY_PROJECT_LIST)}
                  className="nav-sidebar-link my-projects-list"
                >
                  <i className="icon-svg pen-to-square-svg nav-icon" />
                  マイプロジェクト
                  {unreadMessages > 0 && (
                    <span>{unreadMessages}</span>
                  )}
                </Nav.Link>
                <Nav.Link as={NavLink} to={toMemberPage(userType, ROUTES.MY_NEWS)} className="nav-sidebar-link">
                  <i className="icon-svg news-svg nav-icon" />
                  お知らせ一覧
                </Nav.Link>
                {userType === USER_TYPE_INFLUENCER && (
                  <>
                    <Nav.Link
                      as={NavLink}
                      to={toMemberPage(userType, ROUTES.EVALUATION)}
                      className="nav-sidebar-link"
                    >
                      <i className="icon-svg chart-simple-svg nav-icon" />
                      評価
                    </Nav.Link>
                    <Nav.Link
                      as={NavLink}
                      to={toMemberPage(userType, ROUTES.TRANSFER_HISTORY)}
                      className="nav-sidebar-link"
                    >
                      <i className="icon-svg influencer-point-svg nav-icon" />
                      ポイント管理
                    </Nav.Link>
                  </>
                )}
                {userType == USER_TYPE_COMPANY && (
                  <Nav.Link
                    as={NavLink}
                    to={toMemberPage(userType, ROUTES.MY_POINT_INDEX)}
                    className="nav-sidebar-link"
                  >
                    <i className="icon-svg point-svg nav-icon" />
                    ポイント管理
                  </Nav.Link>
                )}
                {userType == USER_TYPE_INFLUENCER && (
                  <Nav.Link as={NavLink} to={ROUTES.REWARD_CHANGE} className="nav-sidebar-link">
                    <i className="icon-svg fresh-svg nav-icon" />
                    戦闘力の計測
                  </Nav.Link>
                )}
                {userType == USER_TYPE_INFLUENCER && (
                  <Nav.Link as={NavLink} to={toMemberPage(userType, ROUTES.INVITATION)} className="nav-sidebar-link">
                    <i className="icon-svg invitation-svg nav-icon" />
                    招待プログラム
                  </Nav.Link>
                )}
                {userType == USER_TYPE_NPO && (
                  <Nav.Link
                    as={NavLink}
                    to={toMemberPage(userType, ROUTES.TRANSFER_HISTORY)}
                    className="nav-sidebar-link"
                  >
                    <i className="icon-svg influencer-point-svg nav-icon" />
                    ポイント管理
                  </Nav.Link>
                )}
                <Nav.Link
                  as={NavLink}
                  to={toMemberPage(userType, ROUTES.MY_PROFILE_UPDATE)}
                  className="nav-sidebar-link"
                >
                  <i className="icon-svg user-profile-svg nav-icon" />
                  会員情報
                </Nav.Link>
                {userType === USER_TYPE_COMPANY && (
                  <>
                    <Nav.Link
                      as={NavLink}
                      to={toMemberPage(userType, ROUTES.IDENTITY_VERIFY)}
                      className="nav-sidebar-link"
                    >
                      <i className="icon-svg user-svg nav-icon" />
                      本人確認
                    </Nav.Link>
                    <Nav.Link as={NavLink} to={toMemberPage(userType, ROUTES.NDA)} className="nav-sidebar-link">
                      <i className="icon-svg lock-svg nav-icon" />
                      NDA
                    </Nav.Link>
                  </>
                )}
                {(userType == USER_TYPE_INFLUENCER || userType == USER_TYPE_NPO) && (
                  <Nav.Link as={NavLink} to={toMemberPage(userType, ROUTES.BANK_ACCOUNT)} className="nav-sidebar-link">
                    銀行口座登録
                  </Nav.Link>
                )}
                <Nav.Link
                  className="nav-sidebar-link"
                  onClick={handleLogOut}
                  disabled={isProcessing}
                >
                  ログアウト
                </Nav.Link>
              </>
            )}
          </Nav>

          {user ? (
            <>
              <Link
                to={toMemberPage(userType, ROUTES.MY_PROJECT_LIST)}
                className={unreadMessages ? 'd-none d-xl-block text-decoration-none messages badge'
                  : 'd-none d-xl-block text-decoration-none messages'}
              >
                <EnvelopeFill color="#98A6B5" size={24} />
              </Link>
              <NavDropdown
                align="end"
                title={<NavDropdownTitleUser />}
                className="userinfo-dropdown d-none d-xl-block text-decoration-none mx-4"
                renderMenuOnMount
                show={false}
                onClickCapture={() => navigate(toMemberPage(userType, ROUTES.MYPAGE))}
              >
                <Dropdown.Item as={Link} to={toMemberPage(userType, ROUTES.USER_CHANGE_PASSWORD)}>
                  パスワード変更
                </Dropdown.Item>
                {(userType == USER_TYPE_INFLUENCER || userType == USER_TYPE_NPO) && (
                  <Dropdown.Item as={Link} to={toMemberPage(userType, ROUTES.BANK_ACCOUNT)}>
                    銀行口座登録
                  </Dropdown.Item>
                )}
                <Dropdown.Item
                  onClick={handleLogOut}
                  disabled={isProcessing}
                >
                  ログアウト
                </Dropdown.Item>
              </NavDropdown>
              <div className="d-none d-xl-block my-point">
                <span><i className="icon-svg point-green-svg nav-icon me-1" /> {myPoints}</span>
              </div>
            </>
          ) : (
            <div className="nav-action-group d-flex">
              <Nav.Link as={Link} to={ROUTES.SIGN_IN} className="signin-link" title="ログイン">
                ログイン
              </Nav.Link>
              <Nav.Link as={Link} to="/influencer/registration" className="registration-link" title="新規登録">
                新規登録
              </Nav.Link>
            </div>
          )}
        </Navbar.Collapse>
      </div>
    </Navbar>
  );
}

export default Navigation;
