import React from 'react';
import PropTypes from 'prop-types';
import { toMemberPage } from 'helpers/utils';
import * as ROUTES from 'constants/routes';
import useSession from 'hooks/useSession';
import { Link } from 'react-router-dom';

function MyNewsItem({ newsItem }) {
  const { user } = useSession();
  const newsItemUrl = toMemberPage(user?.user_type, ROUTES.MY_NEWS_DETAIL.replace(':id', newsItem.id));
  return (
    <div className="d-flex align-items-md-center flex-column flex-md-row news__item">
      <div className="date">
        <span>{newsItem.publish_at_format}</span>
      </div>
      <div className="title">
        <Link to={newsItemUrl} title={newsItem.title} className="custom-maxline-1">
          {newsItem.title}
        </Link>
      </div>
    </div>
  );
}

MyNewsItem.propTypes = {
  newsItem: PropTypes.object.isRequired
};
export default MyNewsItem;
