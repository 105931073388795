import { PROJECT_LIST, PROJECT_SAVE_TO_DRAFT } from './projectActions';

const initialState = {
  projects: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case PROJECT_LIST:
      return {
        ...state,
        projects: action.payload.data
      };
    case PROJECT_SAVE_TO_DRAFT: {
      const newProjectsData = state.projects.data.map((project) => {
        if (project.id == action.payload) {
          return {
            ...project,
            public: 1
          };
        }
        return project;
      });

      return {
        ...state,
        projects: {
          ...state.projects,
          data: newProjectsData
        }
      };
    }

    default:
      return state;
  }
};
