import { CookieStorage } from 'redux-persist-cookie-storage';
import Cookies from 'js-cookie';
import persistReducer from 'redux-persist/es/persistReducer';
import { authReducer } from 'redux/admin/auth';
import { userReducer } from './user';
import { projectReducer } from './project';
import { newsReducer } from './news';
import { cancellationReducer } from './cancellation';
import { transferHistoryReducer } from './transfer_history';
import { rewardRateSettingReducer } from './reward_rate_setting';
import { tagReducer } from './tag';
import { donationReducer } from './donation';
import { myPageReducer } from './my_page';
import invitationSettingReducer from './invitation_setting/invitationSettingReducer';

const authPersistConfig = {
  key: 'auth_admin',
  storage: new CookieStorage(Cookies, {
    expiration: {
      default: 604800 // one week
    }
  }),
  blacklist: []
};

const adminReducer = {
  authAdmin: persistReducer(authPersistConfig, authReducer),
  userAdminPanel: userReducer,
  projectAdmin: projectReducer,
  newsAdmin: newsReducer,
  cancellation: cancellationReducer,
  transferHistory: transferHistoryReducer,
  rewardRateSetting: rewardRateSettingReducer,
  tagAdmin: tagReducer,
  donation: donationReducer,
  myPage: myPageReducer,
  invitationSetting: invitationSettingReducer
};

export default adminReducer;
