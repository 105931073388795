import API from 'axios/API';

export const GET_ALL_CATEGORY_SUCCESS = 'GET_ALL_CATEGORY_SUCCESS';
// NPO
export const GET_ALL_NPO_CATEGORY_SUCCESS = 'GET_ALL_NPO_CATEGORY_SUCCESS';

export const GET_ALL_PREFECTURES = 'GET_ALL_PREFECTURES';
export const POPULAR_CATEGORY = 'POPULAR_CATEGORY';

// key: 6 value: その他
export const CATEGORY_OTHERS_ID = 6;

export const getAllCategory = () => (dispatch) => {
  return API({
    method: 'GET',
    url: 'category'
  })
    .then((res) => {
      dispatch({
        type: GET_ALL_CATEGORY_SUCCESS,
        payload: res
      });
      return res;
    })
    .catch((error) => {
      return error;
    });
};

// NPO
export const getAllNPOCategory = () => (dispatch) => {
  return API({
    method: 'GET',
    url: 'npo/category'
  }).then((res) => {
    dispatch({
      type: GET_ALL_NPO_CATEGORY_SUCCESS,
      payload: res
    });
    return res;
  });
};

export const getAllPrefectures = () => (dispatch) => {
  return API({
    method: 'GET',
    url: 'prefecture'
  }).then((res) => {
    dispatch({
      type: GET_ALL_PREFECTURES,
      payload: res
    });
    return res;
  });
};

export const getPopularCategories = () => (dispatch) => {
  const urlApi = 'popular-category';
  return API({
    method: 'GET',
    url: urlApi
  })
    .then((res) => {
      dispatch({
        type: POPULAR_CATEGORY,
        payload: res
      });
      return res;
    });
};
