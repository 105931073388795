import AdminAPI from 'axios/AdminAPI';

export const TRANSFER_HISTORY_LIST_SUCCESS = 'TRANSFER_HISTORY_LIST_SUCCESS';

export const getTransferHistoryPaginate = (requestParams, page) => (dispatch) => {
  let URLApi = `transfer-history/list?page=${page}`;
  const requestParamsStr = new URLSearchParams(requestParams).toString();
  if (requestParamsStr) {
    URLApi += `&${requestParamsStr}`;
  }

  return AdminAPI({
    method: 'GET',
    url: URLApi
  }).then((res) => {
    dispatch({
      type: TRANSFER_HISTORY_LIST_SUCCESS,
      payload: res
    });
  });
};

export const exportTransferHistory = (requestParams) => () => {
  let URLApi = 'transfer-history/export';
  const requestParamsStr = new URLSearchParams(requestParams).toString();
  if (requestParamsStr) {
    URLApi += `?${requestParamsStr}`;
  }
  return AdminAPI({
    method: 'GET',
    url: URLApi
  })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error;
    });
};
