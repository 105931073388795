import * as Yup from 'yup';
import * as ja from 'helpers/yup-locale-ja';

Yup.setLocale(ja.suggestive);
export const CreateOrUpdateBackAccountValidation = () => {
  return Yup.object().shape({
    bank_code: Yup.string()
      .required()
      .min(4)
      .max(4)
      .when('bank_name', (bankName, schema) => {
        return schema.test({
          test: () => {
            return !!bankName;
          },
          message: '銀行コードは無効です。'
        });
      }),
    branch_code: Yup.string()
      .required()
      .min(3)
      .max(3)
      .when('branch_name', (branchName, schema) => {
        return schema.test({
          test: () => {
            return !!branchName;
          },
          message: '支店コードは無効です。'
        });
      }),
    account_type: Yup.string().required(),
    account_number: Yup.string()
      .required()
      .matches(/^[0-9]+$/)
      .min(7)
      .max(7),
    account_name: Yup.string()
      .required()
      /* eslint-disable no-irregular-whitespace */
      .matches(/^([ァ-ン　]|ー　)+$/)
      .max(255)
  });
};
