import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import LOGO from 'assets/logo-black.svg';
import PropTypes from 'prop-types';

function DialogBox({ showDialog, cancelNavigation, confirmNavigation }) {
  return (
    <Modal show={showDialog} className="bs-modal-custom-1 bs-dialog-box" centered>
      <Modal.Header className="border-bottom-0 mx-auto">
        <img src={LOGO} alt="インフルエンサーマッチング Webアプリ" />
      </Modal.Header>
      <Modal.Body className="text-center">
        <p className="dialog-box__title">
          <b>このサイトを離れますか？</b>
        </p>
        行った変更が保存されない可能性があります。
      </Modal.Body>
      <Modal.Footer className="flex-column border-top-0">
        <Button className="common-btn" onClick={confirmNavigation}>
          このページを離れる
        </Button>
        <Button className="common-outline-btn" onClick={cancelNavigation}>
          キャンセル
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

DialogBox.propTypes = {
  showDialog: PropTypes.bool.isRequired,
  cancelNavigation: PropTypes.func.isRequired,
  confirmNavigation: PropTypes.func.isRequired
};

export default DialogBox;
