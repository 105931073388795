import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useSearchParams } from 'react-router-dom';
import { LoadingContext } from 'hooks/LoadingContext';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { QuestionCircleFill } from 'react-bootstrap-icons';
import { PROJECT_ALL_TYPE, PROJECT_TYPE_PUBLIC_RECRUITMENT, PROJECT_TYPE_NOMINATION } from 'redux/client/project';
import ProjectItem from './ProjectItem';

function ProjectInfluencerList({ myProjects, typeProject }) {
  const [isLoading] = useContext(LoadingContext);
  const recruitingTitles = ['応募中', '', '指名承諾前'];
  const [searchParams] = useSearchParams();

  const setDefaultActiveTabs = () => {
    const tabArr = ['processing', 'recruiting-applying', 'recruiting-before-nomination-approval', 'archive'];
    const tabActive = searchParams.get('tabActive');
    if (tabActive != '') {
      return tabArr[tabActive];
    }

    return tabArr[0];
  };

  return (
    <Tabs defaultActiveKey={setDefaultActiveTabs()} className="client-tabs mb-4">
      <Tab
        eventKey="processing"
        title={<p>進行中<span className="number">{!isLoading ? myProjects.count.processing : 0}</span></p>}
        tabClassName="mt-4"
      >
        <div className="section row">
          {isLoading && [...Array(20)].map((e, i) => (
            <ProjectItem key={`${i.toString()}`} />
          ))}
          {myProjects.count?.processing === 0 && !isLoading && <p>見つかりません。</p>}
          {!isLoading && myProjects.processing?.map((item) => (
            <ProjectItem project={item} key={item.id} />
          ))}
        </div>
      </Tab>
      {(typeProject == PROJECT_TYPE_PUBLIC_RECRUITMENT || typeProject == PROJECT_ALL_TYPE) && (
        <Tab
          eventKey="recruiting-applying"
          title={(
            <p>{recruitingTitles[0]}
              <span className="number">{!isLoading ? (myProjects.count.recruiting?.applying ?? 0) : 0}</span>
            </p>
          )}
          tabClassName="mt-4"
        >
          <div className="section row">
            {isLoading && [...Array(20)].map((e, i) => (
              <ProjectItem key={`${i.toString()}`} />
            ))}
            {myProjects.count?.recruiting?.applying === 0 && !isLoading && <p>見つかりません。</p>}
            {!isLoading && myProjects.recruiting?.applying?.map((item) => (
              <ProjectItem project={item} key={item.id} />
            ))}
          </div>
        </Tab>
      )}
      {(typeProject == PROJECT_TYPE_NOMINATION || typeProject == PROJECT_ALL_TYPE) && (
        <Tab
          eventKey="recruiting-before-nomination-approval"
          title={(
            <p>{recruitingTitles[2]}
              <span className="number">
                {!isLoading ? (myProjects.count.recruiting?.before_nomination_approval ?? 0) : 0}
              </span>
            </p>
          )}
          tabClassName="mt-4"
        >
          <div className="section row">
            {isLoading && [...Array(20)].map((e, i) => (
              <ProjectItem key={`${i.toString()}`} />
            ))}
            {myProjects.count?.recruiting?.before_nomination_approval === 0 && !isLoading && <p>見つかりません。</p>}
            {!isLoading && myProjects.recruiting?.before_nomination_approval?.map((item) => (
              <ProjectItem project={item} key={item.id} />
            ))}
          </div>
        </Tab>
      )}
      <Tab
        eventKey="archive"
        title={(
          <p>
            アーカイブ
            <span className="number">
              {!isLoading ? myProjects.count.archive : 0}
            </span>
            <OverlayTrigger
              key="top"
              placement="top"
              overlay={(
                <Tooltip id="tooltip-top" className="amazing-tooltip">
                  完了・不採用・取引キャンセルとなったプロジェクトがアーカイブに収納されます
                </Tooltip>
              )}
            >
              <QuestionCircleFill className="question-circle-style label-icon ms-2 align-text-bottom" size={24} />
            </OverlayTrigger>
          </p>
        )}
        tabClassName="mt-4"
      >
        <div className="section row">
          {isLoading && [...Array(20)].map((e, i) => (
            <ProjectItem key={`${i.toString()}`} />
          ))}
          {myProjects.count?.archive === 0 && !isLoading && <p>見つかりません。</p>}
          {!isLoading && myProjects.archive?.map((item) => (
            <ProjectItem project={item} key={item.id} />
          ))}
        </div>
      </Tab>
    </Tabs>
  );
}
ProjectInfluencerList.propTypes = {
  myProjects: PropTypes.object.isRequired,
  typeProject: PropTypes.string.isRequired
};
export default ProjectInfluencerList;
