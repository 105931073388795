import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';

import { Field, Formik } from 'formik';

import * as Yup from 'yup';
import * as ja from 'helpers/yup-locale-ja';
import { Spinner } from 'react-bootstrap';
import { CustomTextArea } from 'components/formik';

Yup.setLocale(ja.suggestive);
const FormSchema = Yup.object().shape({
  comment: Yup.string().required()
});

function CancellationRequestModal({
  isShowModal,
  isSubmitting,
  onSentCancellationRequest,
  onHideModal
}) {
  const initFormikValues = {
    comment: ''
  };

  return (
    <Modal
      onHide={() => onHideModal()}
      show={isShowModal}
      centered
      dialogClassName="bs-modal-custom-1 not_points_recruit_talk_room"
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton />
      <Modal.Body>
        <div className="container">
          <Formik initialValues={initFormikValues} validationSchema={FormSchema} onSubmit={onSentCancellationRequest}>
            {({ handleSubmit, values }) => (
              <form className=" cancellation-request-modal form-style-1">
                <div className="mt-4 form-group-item">
                  <Field name="comment" placeholder="理由を記入してください。" rows="6" component={CustomTextArea} />
                </div>
                <div className="row mt-4">
                  <div className="col-12 text-center">
                    <button
                      type="button"
                      className="btn common-btn confirmed-earned-points__btn"
                      onClick={() => {
                        handleSubmit();
                      }}
                      disabled={isSubmitting || !values.comment}
                    >
                      {isSubmitting && (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        className="me-2"
                      />
                      )}
                      送信
                    </button>
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </Modal.Body>
    </Modal>
  );
}

CancellationRequestModal.propTypes = {
  isShowModal: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  onSentCancellationRequest: PropTypes.func.isRequired,
  onHideModal: PropTypes.func.isRequired
};

export default CancellationRequestModal;
