import React from 'react';
import { Link } from 'react-router-dom';

import PropTypes from 'prop-types';
import { getFormattedDate, formatMoney } from 'helpers/utils';
import * as ROUTES from 'constants/routes';
import constants from 'constants/constants';

function ProjectRow({ project, onSaveToDraftClickHandle }) {
  return (
    <tr className="cursor-pointer position-relative">
      <td>
        <Link
          to={`${ROUTES.APP_ADMIN}/${ROUTES.ADMIN_PROJECT_DETAIL.replace(':id', project.id)}`}
          className="full-row__link"
        />
        {project.id}
      </td>
      <td className="title-col">
        <p>{project.project_title}</p>
      </td>
      <td>{project.user.user_type_text}</td>
      <td>{project.project_type_text}</td>
      <td>{project.type_name}</td>
      <td>{formatMoney(project.budget)} pt</td>
      <td>{project.current_recruits_number} 人</td>
      <td>{project.recruited_number} 人</td>
      <td>{getFormattedDate(project.delivery_at)}</td>
      <td className="action-column">
        {project.public === constants.PROJECT_PUBLIC.PUBLIC && (
          <div
            role="button"
            tabIndex="0"
            className="hyperlink underline d-inline-block mx-2"
            onClick={onSaveToDraftClickHandle}
            onKeyDown={onSaveToDraftClickHandle}
          >
            <span>非公開にする</span>
          </div>
        )}
      </td>
    </tr>
  );
}

ProjectRow.propTypes = {
  project: PropTypes.object.isRequired,
  onSaveToDraftClickHandle: PropTypes.func.isRequired
};
export default ProjectRow;
