import { useEffect } from 'react';
import { useFormikContext } from 'formik';
import { scrollToErrorField } from 'helpers/utils';

function ScrollToFieldError() {
  const { submitCount, isValid } = useFormikContext();
  useEffect(() => {
    if (isValid) return;
    scrollToErrorField();
  }, [submitCount]);

  return null;
}

export default ScrollToFieldError;
