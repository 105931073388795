/* eslint-disable react/prop-types */
import React from 'react';
import BearIcon from 'assets/svg/new-teddy-bear-ic.svg';

function CalculateTeddyBearRank({ rating }) {
  const teddyBearRank = Math.floor(rating);

  if (!teddyBearRank) {
    return false;
  }

  return (
    <div className="teddybear-rating">
      {[...Array(teddyBearRank)].map((_, index) => (
        <img src={BearIcon} alt="" key={`${index.toString()}`} />
      ))}
    </div>
  );
}

CalculateTeddyBearRank.defaultProps = {
  rating: 0
};

export default CalculateTeddyBearRank;
