import React from 'react';
import { Field, Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import Skeleton from 'react-loading-skeleton';
import useSession from 'hooks/useSession';
import constants from 'constants/constants';
import { PROJECT_ALL_TYPE } from 'redux/client/project';

function MyProjectFilter({ typeData, initFormikValues, onSubmit }) {
  const { unreadMessagesPublicOffering, unreadMessagesCandidacy, unreadMessagesNominated } = useSession();
  const { user } = useSession();
  const userType = user?.user_type;

  const showUnreadMessages = (typeProject) => {
    let contentHtml = '';
    const totalMess = unreadMessagesPublicOffering + unreadMessagesCandidacy + unreadMessagesNominated;
    switch (typeProject) {
      case constants.PROJECT_TYPE.PUBLIC:
        contentHtml = unreadMessagesPublicOffering > 0 && (
          <span className="unread-messages-total">{unreadMessagesPublicOffering}</span>
        );
        break;
      case constants.PROJECT_TYPE.CANDIDACY:
        contentHtml = unreadMessagesCandidacy > 0 && (
          <span className="unread-messages-total">{unreadMessagesCandidacy}</span>
        );
        break;
      case constants.PROJECT_TYPE.SPECIFIED:
        contentHtml = unreadMessagesNominated > 0 && (
          <span className="unread-messages-total">{unreadMessagesNominated}</span>
        );
        break;
      case PROJECT_ALL_TYPE:
        contentHtml = totalMess > 0 && (
          <span className="unread-messages-total">{totalMess}</span>
        );
        break;
      default:
        break;
    }
    return contentHtml;
  };

  return (
    <Formik initialValues={initFormikValues} onSubmit={onSubmit}>
      {({ handleBlur, handleSubmit }) => (
        <div className="filter">
          {typeData.length == 0 ? (
            <div className="row">
              {[...Array(4)].map((e, i) => (
                <div className="col-lg-1 col-4" key={`${i.toString()}`}>
                  <Skeleton height={45} />
                </div>
              ))}
            </div>
          ) : (
            <Form
              onChange={(e) => {
                handleBlur(e);
                handleSubmit();
              }}
              className="d-flex flex-wrap"
            >
              {/* Get All */}
              {
                userType == constants.USER_TYPE_BY_USER.KEY.company && (
                  <div>
                    <div className="radio-button type-button pb-2">
                      <Field id="type-all" type="radio" name="type_id" value={`${PROJECT_ALL_TYPE}`} />
                      <label className="form-control" htmlFor="type-all">
                        すべて
                        {showUnreadMessages(PROJECT_ALL_TYPE) || ''}
                      </label>
                    </div>
                  </div>
                )
              }

              {typeData?.map((item) => (
                <div key={item.key}>
                  <div className="radio-button type-button pb-2">
                    <Field id={`type${item.key}`} type="radio" name="type_id" value={`${item.key}`} />
                    <label className="form-control" htmlFor={`type${item.key}`}>
                      {item.value}
                      {showUnreadMessages(item.key) || ''}
                    </label>
                  </div>
                </div>
              ))}
            </Form>
          )}
        </div>
      )}
    </Formik>
  );
}

MyProjectFilter.propTypes = {
  initFormikValues: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  typeData: PropTypes.array.isRequired
};

export default MyProjectFilter;
