import React from 'react';
import PropTypes from 'prop-types';

function PointStatistics({ userInfo }) {
  return (
    <section className="row py-4">
      <div className="col-sm-6">
        <ul className="align-self-center list-unstyled text-center light-pink p-4 fw-bold">
          <li>寄付累計ポイント</li>
          <li>{userInfo?.donation.toLocaleString()} pt</li>
        </ul>
      </div>
      <div className="col-sm-6">
        <ul className="align-self-center list-unstyled text-center light-pink p-4 fw-bold">
          <li>保有済み累計ポイント</li>
          <li>{userInfo?.statistical_points_held.toLocaleString()} pt</li>
        </ul>
      </div>
    </section>
  );
}

PointStatistics.propTypes = {
  userInfo: PropTypes.object
};

PointStatistics.defaultProps = {
  userInfo: null
};
export default PointStatistics;
