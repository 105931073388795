import React, { useRef, useState } from 'react';
import { ErrorMessage, useFormikContext } from 'formik';
import { Paperclip } from 'react-bootstrap-icons';

import constants from 'constants/constants';
import DeleteIcon from 'assets/svg/delete-ic.svg';
import { useFileHandler } from 'hooks';

function UploadMultipleFileForm() {
  const formikContent = useFormikContext();
  const [multipleDragActive, setMultipleDragActive] = useState(false);
  const inputRef = useRef([]);
  const { imageFile, onFileChange, removeImage } = useFileHandler({
    attached_files: formikContent.values.attached_files || []
  });

  const handleMultipleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setMultipleDragActive(true);
    } else if (e.type === 'dragleave') {
      setMultipleDragActive(false);
    }
  };

  const handleMultipleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setMultipleDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      formikContent.setFieldValue('attached_files', getValueUploadMultipleFile(e.dataTransfer.files));
      onFileChange(e, { name: 'attached_files', type: 'multiple' });
    }
  };

  const onButtonClick = () => {
    inputRef.current.click();
  };

  const onClickUploadFile = (e) => {
    formikContent.setFieldValue('attached_files', getValueUploadMultipleFile(e.target.files));
    onFileChange(e, { name: 'attached_files', type: 'multiple' });
  };

  const onClickRemoveFile = (fileId, i) => {
    const attachedFiles = formikContent.values.attached_files;
    attachedFiles.splice(i, 1);
    formikContent.setFieldValue('attached_files', attachedFiles);
    removeImage({ id: fileId, name: 'attached_files' });
  };

  const getValueUploadMultipleFile = (files) => {
    const values = [];
    for (let index = 0; index < files.length; index += 1) {
      values.push({
        file: files[index],
        id: null,
        url: null
      });
    }

    return [...formikContent.values.attached_files, ...values];
  };

  return (
    <div className="amazing-multiple-upload">
      <div id="multiple-file-upload" onDragEnter={handleMultipleDrag}>
        <label id="label-multiple-upload" htmlFor="attached_files" className={multipleDragActive ? 'drag-active' : ''}>
          <input
            accept={constants.UPLOAD_IMAGE_SUPPORTED_FORMATS.toString()}
            hidden
            id="attached_files"
            name="attached_files"
            ref={inputRef}
            multiple
            onChange={onClickUploadFile}
            onClick={(e) => {
              e.target.value = null;
            }}
            type="file"
          />
          <div className="drap-text">
            <p className="fw-bold text-top">
              <span>ファイルをドラッグ＆ドロップするか</span>
              <br />
              <span>クリップボードから画像を貼り付けてください</span>
            </p>
            <p className="divider">または</p>
            <button className="btn btn-green" onClick={onButtonClick} title="ファイル選択" type="button">
              <Paperclip className="paperclip" />
              ファイル選択
            </button>
            <p className="text-bottom">
              <span>png,jpg,jpeg形式のみアップロード可</span>
              <br />
              <span>画像は８M以下でアップロードしてください</span>
            </p>
          </div>
        </label>
        {multipleDragActive && (
          <div
            id="drag-multiple-element"
            onDragEnter={handleMultipleDrag}
            onDragLeave={handleMultipleDrag}
            onDragOver={handleMultipleDrag}
            onDrop={handleMultipleDrop}
          />
        )}
      </div>
      <ErrorMessage name="attached_files" component="div" className="invalid-feedback d-block" />

      {imageFile.attached_files.length >= 1 && (
        <div className="row">
          <div className="attached-files-block">
            {imageFile.attached_files.map((image, i) => (
              <div key={image.id} className="attached-file-cover">
                <div className="mb-2 rounded align-items-center justify-content-between">
                  <img className="cover-image-view" src={image.url} alt={image.file.name} />
                  <button
                    className="btn delete-btn"
                    onClick={() => onClickRemoveFile(image.id, i)}
                    title="Delete Image"
                    type="button"
                  >
                    <img src={DeleteIcon} alt="" className="delete-ic" />
                  </button>
                </div>
                <p className="attach-file-name">{image.file.name}</p>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default UploadMultipleFileForm;
