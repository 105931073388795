import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import { getFormattedDate, toMemberPage } from 'helpers/utils';
import * as ROUTES from 'constants/routes';
import useSession from 'hooks/useSession';
import { LoadingContext } from 'hooks/LoadingContext';
import TypeIcon from 'assets/svg/type-ic.svg';
import Image from 'components/common/Image';
import Skeleton from 'react-loading-skeleton';

function ProjectItem({ project }) {
  const [isLoading] = useContext(LoadingContext);
  const { user } = useSession();
  let projectUrl = !isLoading && toMemberPage(
    user?.user_type,
    ROUTES.MY_PROJECT_DETAIL.replace(':id', project.id)
  );
  if (project.not_show_messages == 1) {
    projectUrl += '?hidden=1';
  }
  return (
    <div className="mb-4 col-lg-4 col-12 col-md-6 col-sm-6 project-item ">
      <div className="card">
        {project?.unread_messages_count > 0 && (
          <span className="unread-messages">{project.unread_messages_count}</span>
        )}
        <div className="card-inner">
          <div className="cover-image">
            {isLoading ? (
              <Skeleton height={363} borderRadius={0} containerClassName="d-flex" />
            ) : (
              <>
                <Link to={projectUrl}>
                  <Image className="img-fluid" src={project.link_cover_image} alt={project.project_title} />
                </Link>
                <span className="category">{project.category_name}</span>
                <div className="position-absolute bottom-0 start-0 end-0 remaining"> </div>
              </>
            )}
          </div>
          <div className="card-body">
            <p className="type-info">
              {isLoading ? <Skeleton height={32} /> : (
                <>
                  <span className="project-type">
                    <img src={TypeIcon} alt="" />
                    {project.project_type_text}
                  </span>
                  <span className={`type type-${project.type}`}>{project.type_name}</span>
                </>
              )}
            </p>

            <h2 className="title">
              {isLoading ? <Skeleton count={2} /> : <Link to={projectUrl}>{project.project_title}</Link>}
            </h2>
            <p className="company-name">{isLoading ? <Skeleton width={180} /> : project.npo_or_company_name}</p>
            <p className="delivery">{isLoading ? <Skeleton width={140} /> : getFormattedDate(project.delivery_at)}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

ProjectItem.defaultProps = {
  project: {}
};

ProjectItem.propTypes = {
  project: PropTypes.object
};

export default ProjectItem;
