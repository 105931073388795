import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { ChevronDown } from 'react-bootstrap-icons';
import useSession from 'hooks/useSession';

import './style/project-status-select-picker-custom.scss';
import { ErrorMessage } from 'formik';
import { findArray } from 'helpers/utils';
import { talkRoomStatusData } from 'data/talkRoomStatusData';
import { USER_TYPE_COMPANY, USER_TYPE_INFLUENCER, USER_TYPE_NPO } from 'redux/client/auth';
import constants from 'constants/constants';

function CustomProjectStatusSelectPicker({
  field,
  form: { touched, errors, setFieldValue },
  label,
  selectData,
  currentStatus,
  oldStatus,
  projectData,
  setIsShowVideoUrlForm,
  onChange,
  ...props
}) {
  const { user } = useSession();
  const userType = user?.user_type;
  const [selected, setSelected] = useState();
  const [selectedText, setSelectedText] = useState('');
  const [showSelectPopper, setShowSelectPopper] = useState(false);
  const [selectInputValueStyle, setSelectInputValueStyle] = useState({});
  const [selectPopperStyle, setSelectPopperStyle] = useState({});

  const selectInputRef = useRef(null);
  const selectPopperRef = useRef(null);

  useEffect(() => {
    const handler = (event) => {
      if (!selectInputRef.current.contains(event.target)) {
        setShowSelectPopper(false);
      }
    };
    document.addEventListener('click', handler);

    const handleResize = () => {
      const resetSelectedStyle = findArray(field.value, selectData)?.style;
      setSelectInputValueStyle({
        marginLeft: selectInputRef.current.offsetWidth / 2 - 110,
        ...resetSelectedStyle
      });
    };
    window.addEventListener('resize', handleResize);

    return () => {
      document.removeEventListener('click', handler);
      document.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (field.value?.toString() && selectData.length > 0) {
      setSelected(findArray(field.value, selectData)?.value || null);
      const resetSelectedStyle = findArray(field.value, selectData)?.style;
      setSelectInputValueStyle({
        marginLeft: selectInputRef.current.offsetWidth / 2 - 110,
        ...resetSelectedStyle
      });
    }
  }, [selectData, field.value]);

  const onClickSelectInput = async (event) => {
    await setShowSelectPopper((prev) => !prev);
    const clientYScreen = document.documentElement.clientHeight - event.clientY;
    const selectInputHeight = selectInputRef.current.offsetHeight;
    const selectPopperHeight = selectPopperRef.current.offsetHeight;

    if (clientYScreen > selectPopperHeight + selectInputHeight) {
      setSelectPopperStyle({
        inset: '0px 0px auto auto',
        transform: `translate(0px, ${selectInputHeight}px)`
      });
    } else {
      setSelectPopperStyle({
        inset: 'auto 0px 0px auto',
        transform: `translate(0px, -${selectInputHeight}px)`
      });
    }

    return true;
  };

  const onClickSelectPickerOptionPopper = (style, options) => {
    const { key, value } = options;
    setSelectInputValueStyle((prevState) => ({
      ...prevState,
      ...style
    }));

    setSelected(key !== '' ? value : null);
    setFieldValue(field.name, key);
    onChange();
    if (userType === USER_TYPE_INFLUENCER && constants.TALK_ROOM_STATUS.POST_REQUEST == key) {
      setIsShowVideoUrlForm(true);
    } else {
      setIsShowVideoUrlForm(false);
    }
  };

  useEffect(() => {
    if (userType === USER_TYPE_COMPANY || userType === USER_TYPE_NPO) {
      if ((currentStatus == constants.TALK_ROOM_STATUS.POST_REQUEST && talkRoomStatusData[2]?.value == selected)
        || (currentStatus == constants.TALK_ROOM_STATUS.RECRUIT
          && oldStatus == constants.TALK_ROOM_STATUS.POST_REQUEST)) {
        setSelectedText('投稿修正依頼');
      } else if (projectData.type == constants.PROJECT_TYPE.SPECIFIED) {
        if (currentStatus == constants.TALK_ROOM_STATUS.RECRUITMENT) {
          setSelectedText('指名オファー');
        } else if (currentStatus == constants.TALK_ROOM_STATUS.DO_NOT_RECRUIT
          && oldStatus == constants.TALK_ROOM_STATUS.RECRUITMENT) {
          setSelectedText('指名否認');
        } else {
          setSelectedText(selected);
        }
      } else {
        setSelectedText(selected);
      }
    } else if (userType === USER_TYPE_INFLUENCER) {
      if (projectData.type == constants.PROJECT_TYPE.PUBLIC
        && currentStatus == constants.TALK_ROOM_STATUS.PENDING) {
        setSelectedText('応募');
      } else if (projectData.type == constants.PROJECT_TYPE.SPECIFIED) {
        if ((currentStatus == constants.TALK_ROOM_STATUS.RECRUITMENT && talkRoomStatusData[1]?.value == selected)
          || (currentStatus == constants.TALK_ROOM_STATUS.DO_NOT_RECRUIT
          && oldStatus == constants.TALK_ROOM_STATUS.RECRUITMENT)) {
          setSelectedText('指名否認');
        } else if (talkRoomStatusData[2]?.value == selected
            && (currentStatus == constants.TALK_ROOM_STATUS.RECRUITMENT
            || currentStatus == constants.TALK_ROOM_STATUS.RECRUIT)) {
          setSelectedText('指名承認');
        } else if (currentStatus == constants.TALK_ROOM_STATUS.PENDING) {
          setSelectedText('指名承諾');
        } else if (currentStatus == constants.TALK_ROOM_STATUS.RECRUITMENT) {
          setSelectedText('指名オファー');
        } else {
          setSelectedText(selected);
        }
      } else if (currentStatus == constants.TALK_ROOM_STATUS.RECRUIT
        && oldStatus == constants.TALK_ROOM_STATUS.POST_REQUEST) {
        setSelectedText('投稿修正依頼');
      } else {
        setSelectedText(selected);
      }
    }
  }, [selected]);

  return (
    <>
      {label.length > 0 && (
        <label htmlFor={field.name} className="form-label">
          {label[0]}
          {label[1] && <span className="label-required">{label[1]}</span>}
        </label>
      )}
      <div
        className={`project-status-select-picker__container ${props.className} ${
          touched[field.name] && errors[field.name] && 'is-invalid'
        }`}
      >
        <div className="select-picker__input-group">
          <input
            ref={selectInputRef}
            name={field.name}
            id={field.name}
            type="text"
            className="select-picker__input"
            autoComplete="off"
            // eslint-disable-next-line react/prop-types
            placeholder={!selected ? props.placeholder : ''}
            // eslint-disable-next-line react/prop-types
            disabled={props.disabled}
            onClick={onClickSelectInput}
            onKeyPress={(e) => e.preventDefault()}
          />
          <div className="select-picker__input-group-value" style={selectInputValueStyle}>
            {selectedText}
          </div>
          <span className="select-picker__input-group-text">
            <ChevronDown size={18} color="#8B8E8D " />
          </span>
        </div>
        <div
          ref={selectPopperRef}
          style={selectPopperStyle}
          className={`select-picker-option__popper ${showSelectPopper ? 'show' : ''}`}
        >
          {selectData.map(({ style, ...options }) => {
            let statusText = options.value;
            if (userType === USER_TYPE_COMPANY || userType == USER_TYPE_NPO) {
              if ((currentStatus == constants.TALK_ROOM_STATUS.POST_REQUEST
                && options.key == constants.TALK_ROOM_STATUS.RECRUIT)
                || (currentStatus == constants.TALK_ROOM_STATUS.RECRUIT
                  && oldStatus == constants.TALK_ROOM_STATUS.POST_REQUEST && options.key == currentStatus)) {
                statusText = '投稿修正依頼';
              } else if (projectData.type == constants.PROJECT_TYPE.SPECIFIED) {
                if (currentStatus == constants.TALK_ROOM_STATUS.RECRUITMENT) {
                  statusText = '指名オファー';
                } else if (currentStatus == constants.TALK_ROOM_STATUS.DO_NOT_RECRUIT
                  && oldStatus == constants.TALK_ROOM_STATUS.RECRUITMENT) {
                  statusText = '指名否認';
                }
              }
            } else if (userType === USER_TYPE_INFLUENCER) {
              if (projectData.type == constants.PROJECT_TYPE.PUBLIC
                && currentStatus == constants.TALK_ROOM_STATUS.PENDING) {
                statusText = '応募';
              } else if (projectData.type == constants.PROJECT_TYPE.SPECIFIED) {
                if ((currentStatus == constants.TALK_ROOM_STATUS.RECRUITMENT
                  && options.key == constants.TALK_ROOM_STATUS.DO_NOT_RECRUIT)
                  || (currentStatus == constants.TALK_ROOM_STATUS.DO_NOT_RECRUIT
                    && oldStatus == constants.TALK_ROOM_STATUS.RECRUITMENT)) {
                  statusText = '指名否認';
                } else if (options.key == constants.TALK_ROOM_STATUS.RECRUIT
                  && (currentStatus == constants.TALK_ROOM_STATUS.RECRUITMENT
                      || currentStatus == constants.TALK_ROOM_STATUS.RECRUIT)) {
                  statusText = '指名承認';
                } else if (currentStatus == constants.TALK_ROOM_STATUS.PENDING) {
                  statusText = '指名承諾';
                } else if (currentStatus == constants.TALK_ROOM_STATUS.RECRUITMENT) {
                  statusText = '指名オファー';
                }
              } else if (currentStatus == constants.TALK_ROOM_STATUS.RECRUIT
                && oldStatus == constants.TALK_ROOM_STATUS.POST_REQUEST && options.key == currentStatus) {
                statusText = '投稿修正依頼';
              }
            }
            return (
              <div
                className={`select-picker-option__item ${options.value == selected && 'active'}`}
                key={options.key}
                onClick={() => onClickSelectPickerOptionPopper(style, options)}
                aria-hidden="true"
              >
                <span
                  className={`select-picker-option__badge ${options.value == selected && 'active'}`}
                  style={{ color: style?.color, backgroundColor: style?.backgroundColor }}
                >
                  {statusText}
                </span>
              </div>
            );
          })}
        </div>
      </div>
      <ErrorMessage name={field.name} component="div" className="invalid-feedback" />
    </>
  );
}

CustomProjectStatusSelectPicker.defaultProps = {
  label: [],
  selectData: [],
  currentStatus: 0,
  oldStatus: 0,
  projectData: [],
  onChange: () => {},
  className: ''
};

CustomProjectStatusSelectPicker.propTypes = {
  label: PropTypes.array,
  selectData: PropTypes.array,
  currentStatus: PropTypes.number,
  oldStatus: PropTypes.number,
  projectData: PropTypes.object,
  onChange: PropTypes.func,
  setIsShowVideoUrlForm: PropTypes.func.isRequired,
  field: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  className: PropTypes.string
};

export default CustomProjectStatusSelectPicker;
