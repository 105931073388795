import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Spinner } from 'react-bootstrap';
import moment from 'moment';
import Skeleton from 'react-loading-skeleton';

import { getAllPrefectures } from 'redux/client/category_global';
import { genderData } from 'data/genderData';
import { exportUserInfluencer, getUserInfluencerPaginate } from 'redux/admin/user';
import Pagination from 'components/admin/Pagination/Pagination';
import { getAllTags } from 'redux/client/tag';
import ToastMessage from 'components/admin/common/ToastMessage';
import CustomBottomToRightToast from 'components/admin/common/CustomBottomToRightToast';
import UserInfluencerRow from './components/UserInfluencerRow';
import UserInfluencerSearchBar from './components/UserInfluencerSearchBar';
import NewsAddModal from '../NewsAddModal';
import PointAddModal from '../PointAddModal';
import CantDeleteUserModal from '../CantDeleteUserModal';
import DeleteUserModal from '../DeleteUserModal';


function UserInfluencerList() {
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingExportCSV, setIsLoadingExportCSV] = useState(false);
  const [remountComponent, setRemountComponent] = useState(0);
  const [requestParamsFilter, setRequestParamsFilter] = useState([]);
  const [isAddNewsModalShow, setIsAddNewsModalShow] = useState(false);
  const [isAddPointModalShow, setIsAddPointModalShow] = useState(false);
  const [isDeleteUserModalShow, setIsDeleteUserModalShow] = useState(false);
  const [isCantDeleteUserModalShow, setIsCantDeleteUserModalShow] = useState(false);
  const [userId, setUserId] = useState(null);
  const [showToastError, setShowToastError] = useState(false);
  const [showToastSuccess, setShowToastSuccess] = useState(false);
  const dispatch = useDispatch();
  const store = useSelector((state) => ({
    tags: state.tag.tags,
    prefectures: state.categoryGlobal.prefectures,
    influencersCompany: state.userAdminPanel.influencersCompany
  }));

  useEffect(() => {
    initialPage();
    _getUserInfluencerPaginate();
  }, []);

  const initialPage = async () => {
    try {
      const getAllTagPromise = store.tags.length === 0 ? dispatch(getAllTags()) : null;
      const getAllPrefecturesPromise = store.prefectures.length === 0 ? dispatch(getAllPrefectures()) : null;
      await Promise.all([getAllTagPromise, getAllPrefecturesPromise]);

      return true;
    } catch (error) {
      return error;
    }
  };

  const _getUserInfluencerPaginate = async (page = 1, requestParams = []) => {
    setIsLoading(true);
    try {
      await dispatch(getUserInfluencerPaginate(page, requestParams));

      setIsLoading(false);
      return true;
    } catch (error) {
      setIsLoading(false);
      return error;
    }
  };

  const _handlePageClick = (event) => {
    _getUserInfluencerPaginate(event.selected + 1, requestParamsFilter);
  };

  const _onSubmitFilterForm = (form) => {
    if (isLoading) {
      return;
    }

    setRemountComponent(Math.random());
    setRequestParamsFilter(form);
    _getUserInfluencerPaginate(1, form);
  };

  const _exportUserInfluencer = async () => {
    setIsLoadingExportCSV(true);
    await dispatch(exportUserInfluencer(requestParamsFilter)).then((res) => {
      const bom = new Uint8Array([0xEF, 0xBB, 0xBF]);
      const url = URL.createObjectURL(new Blob([bom, res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `influencer-users-${moment().format('YYYYMMDDHms')}.csv`);
      document.body.appendChild(link);
      link.click();
    });
    setIsLoadingExportCSV(false);
  };

  const _onAddNewsClickHandle = (currentUserId) => {
    setIsAddNewsModalShow(true);
    setUserId(currentUserId);
  };

  const _onAddPointClickHandle = (currentUserId) => {
    setIsAddPointModalShow(true);
    setUserId(currentUserId);
  };

  const _onDeleteUser = (influencersCompany) => {
    if (influencersCompany?.can_delete_user) {
      setIsDeleteUserModalShow(true);
      setUserId(influencersCompany.user_id);
    } else {
      setIsCantDeleteUserModalShow(true);
    }
  };

  return (
    <div className="user-list-inner">
      <NewsAddModal
        isAddNewsModalShow={isAddNewsModalShow}
        setIsAddNewsModalShow={setIsAddNewsModalShow}
        userId={userId}
      />
      <PointAddModal
        isAddPointModalShow={isAddPointModalShow}
        setIsAddPointModalShow={setIsAddPointModalShow}
        userId={userId}
      />
      <DeleteUserModal
        isDeleteUserModalShow={isDeleteUserModalShow}
        setIsDeleteUserModalShow={setIsDeleteUserModalShow}
        userId={userId}
        setShowToastError={setShowToastError}
        setShowToastSuccess={setShowToastSuccess}
        getUserPaginate={_getUserInfluencerPaginate}
      />
      <CantDeleteUserModal
        isCantDeleteUserModalShow={isCantDeleteUserModalShow}
        setIsCantDeleteUserModalShow={setIsCantDeleteUserModalShow}
      />
      <UserInfluencerSearchBar
        tagData={store.tags}
        prefectureData={store.prefectures}
        genderData={genderData}
        onSubmitFilterForm={_onSubmitFilterForm}
        isLoadingForm={isLoading}
      />
      <div className="mb-4 text-end">
        <button
          type="button"
          className="btn btn-outline-primary rounded-pill admin-btn-green mt-0"
          onClick={_exportUserInfluencer}
          disabled={isLoading || !store.influencersCompany?.total || isLoadingExportCSV}
        >
          {isLoadingExportCSV && (
            <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className="me-2" />
          )}
          <strong>CSVダウンロード</strong>
        </button>
      </div>

      <div className="table-responsive">
        <table className="table table-lg table-hover admin-table-custom-1">
          {(!isLoading && store.influencersCompany?.total < 1) && (
            <caption className="text-center py-5 h5">見つかりません。</caption>
          )}
          <thead className="border-top border-2">
            <tr>
              <th width="10%">ID</th>
              <th width="30%">氏名</th>
              <th width="30%">メールアドレス</th>
              <th width="10%">性別</th>
              <th width="10%">採用回数</th>
              <th width="10%">ランク</th>
              <th width="10%">&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            {isLoading && [...Array(20)].map((e, i) => (
              <tr key={`${i.toString()}`}>
                <td>
                  <Skeleton height={17} />
                </td>
                <td>
                  <Skeleton height={17} />
                </td>
                <td>
                  <Skeleton height={17} />
                </td>
                <td>
                  <Skeleton height={17} />
                </td>
                <td>
                  <Skeleton height={17} />
                </td>
                <td>
                  <Skeleton height={17} />
                </td>
                <td>
                  <Skeleton height={17} />
                </td>
              </tr>
            ))}

            {(!isLoading && store.influencersCompany?.data)
              && store.influencersCompany.data.map((item) => (
                <UserInfluencerRow
                  key={item.user_id}
                  itemRow={item}
                  onAddNewsClick={() => _onAddNewsClickHandle(item.user_id)}
                  onAddPointClick={() => _onAddPointClickHandle(item.user_id)}
                  onDeleteUser={() => _onDeleteUser(item)}
                />
              ))}
          </tbody>
        </table>
      </div>

      {store.influencersCompany?.last_page > 1 && (
        <div className="d-flex justify-content-center my-5">
          <Pagination
            className="pagination admin-pagination-custom-1"
            key={remountComponent}
            pageCount={store.influencersCompany?.last_page}
            onPageChange={_handlePageClick}
          />
        </div>
      )}
      <ToastMessage
        isShow={showToastError}
        onClose={() => {
          setShowToastError(false);
        }}
      />
      <CustomBottomToRightToast msg="更新されました" showToast={showToastSuccess} setShowToast={setShowToastSuccess} />
    </div>
  );
}

export default UserInfluencerList;
