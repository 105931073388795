import React from 'react';
import { Field, Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import {
  STATUS_ROOM_POST_APPROVAL,
  STATUS_ROOM_RECRUITMENT,
  STATUS_ROOM_SUBMISSION_APPLICATION,
  STATUS_ROOM_TRANSACTION_CANCELLATION
} from 'redux/client/talk_room';

function TalkRoomFilter({ initFormikValues, onSubmit }) {
  return (
    <Formik initialValues={initFormikValues} onSubmit={onSubmit}>
      {({ handleBlur, handleSubmit, values }) => (
        <div>
          <Form
            onChange={(e) => {
              handleBlur(e);
              handleSubmit();
            }}
            className="d-flex"
          >
            <div className="radio-button type-button">
              <Field
                id="all"
                type="radio"
                name="status"
                value="all"
                checked={values.status == 'all'}
              />
              <label className="form-control" htmlFor="all">
                すべて
              </label>
            </div>
            <div className="radio-button type-button">
              <Field
                id="recruitment"
                type="radio"
                name="status"
                value={STATUS_ROOM_RECRUITMENT}
                checked={values.status == STATUS_ROOM_RECRUITMENT}
              />
              <label
                className="form-control"
                htmlFor="recruitment"
              >
                進行中
              </label>
            </div>
            <div className="radio-button type-button">
              <Field
                id="transaction_cancelation"
                type="radio"
                name="status"
                value={STATUS_ROOM_TRANSACTION_CANCELLATION}
                checked={values.status == STATUS_ROOM_TRANSACTION_CANCELLATION}
              />
              <label
                className="form-control"
                htmlFor="transaction_cancelation"
              >
                キャンセル申請
              </label>
            </div>
            <div className="radio-button type-button">
              <Field
                id="submission_application"
                type="radio"
                name="status"
                value={STATUS_ROOM_SUBMISSION_APPLICATION}
                checked={values.status == STATUS_ROOM_SUBMISSION_APPLICATION}
              />
              <label
                className="form-control"
                htmlFor="submission_application"
              >
                投稿確認申請
              </label>
            </div>
            <div className="radio-button type-button">
              <Field
                id="post_approval"
                type="radio"
                name="status"
                value={STATUS_ROOM_POST_APPROVAL}
                checked={values.status == STATUS_ROOM_POST_APPROVAL}
              />
              <label
                className="form-control"
                htmlFor="post_approval"
              >
                完了
              </label>
            </div>
          </Form>
        </div>
      )}
    </Formik>
  );
}

TalkRoomFilter.propTypes = {
  initFormikValues: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired
};

export default TalkRoomFilter;
