import API from 'axios/API';

export const getUserReviewInfo = (projectId, talkRoomId) => () => {
  const useUrl = `project-review/get-user-review/${projectId}/${talkRoomId}`;

  return API({
    method: 'GET',
    url: useUrl
  });
};

export const updateOrCreateReview = (projectId, talkRoomId, requestForm) => () => {
  const useUrl = `project-review/${projectId}/${talkRoomId}`;

  return API({
    method: 'POST',
    url: useUrl,
    data: requestForm
  });
};

export const getMyReviews = () => () => {
  const useUrl = 'my-reviews';

  return API({
    method: 'GET',
    url: useUrl
  });
};
