import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Spinner } from 'react-bootstrap';

import * as ROUTES from 'constants/routes';
import constants from 'constants/constants';
import { HTTP_UNPROCESSABLE_ENTITY } from 'constants/ResponseStatus';
import { checkUserAuthToken, USER_TYPE_COMPANY, validatorRegistrationUserCompany } from 'redux/client/auth';
import { getAllCategory, getAllPrefectures } from 'redux/client/category_global';
import { inArrayCategory, isSNSRegistration, setFormikErrors, scrollToErrorField } from 'helpers/utils';
import LoadingOverlay from 'components/loading/LoadingOverlay';
import SignUpCompanyForm from './components/SignUpCompanyForm';
import { SignUpCompanyValidation } from './SignUpCompanyValidation';

function SignUpCompany() {
  const navigate = useNavigate();
  const { userAuthToken } = useParams();
  const confirmSignUpLocation = useLocation();
  const dispatch = useDispatch();

  const store = useSelector((state) => ({
    userAuth: state.auth.userAuth,
    categories: state.categoryGlobal.categories,
    prefectures: state.categoryGlobal.prefectures
  }));

  const [initFormikValues, setInitFormikValues] = useState({
    password: '',
    family_name: '',
    first_name: '',
    family_name_kana: '',
    first_name_kana: '',
    phone: '',
    category_id: [],
    category_text: '',
    company_name: '',
    company_name_kana: '',
    url: '',
    delegate_name: '',
    department: '',
    position: '',
    postcode: '',
    prefecture_id: '',
    address: '',
    company_purpose: 0,
    is_sns_registration: false,
    invite_code: ''
  });

  useEffect(() => {
    if (confirmSignUpLocation.state !== null) {
      setInitFormikValues(confirmSignUpLocation.state);
    }

    setInitFormikValues({
      ...initFormikValues,
      invite_code: localStorage.getItem(constants.INVITE_CODE_STORAGE) ?? ''
    });

    initialPage();
  }, []);

  const initialPage = async () => {
    try {
      const checkUserAuthTokenPromise = dispatch(
        checkUserAuthToken({
          token: userAuthToken,
          user_type: USER_TYPE_COMPANY
        })
      ).catch(() => {
        navigate(ROUTES.ERROR_404_PAGE);
      });
      const getAllCategoryPromise = store.categories.length === 0 ? dispatch(getAllCategory()) : null;
      const getAllPrefecturesPromise = store.prefectures.length === 0 ? dispatch(getAllPrefectures()) : null;

      await Promise.all([checkUserAuthTokenPromise, getAllCategoryPromise, getAllPrefecturesPromise]);
      return true;
    } catch (error) {
      return error;
    }
  };

  const onFormSubmit = async (form, formikHelpers) => {
    const resp = await dispatch(validatorRegistrationUserCompany(form));
    if (resp && resp.status === HTTP_UNPROCESSABLE_ENTITY) {
      setFormikErrors(resp.data.errors, formikHelpers.setFieldError);
      scrollToErrorField();
    } else {
      navigate(ROUTES.SIGN_UP_COMPANY_CONFIRM, {
        state: Object.assign(form, {
          email: store.userAuth.email,
          verifyToken: userAuthToken,
          categoryList: inArrayCategory(store.categories, form.category_id, form.category_text),
          prefectureData: store.prefectures
        })
      });
    }
  };

  return (
    <div className="signup-confirm-page">
      {!store.userAuth ? (
        <LoadingOverlay />
      ) : (
        <div className="container main-container">
          <div className="row justify-content-center">
            <div className="col-lg-10 col-11 form__wrapper">
              <Formik
                initialValues={({ ...initFormikValues, is_sns_registration: isSNSRegistration(store.userAuth) })}
                enableReinitialize
                validationSchema={SignUpCompanyValidation}
                onSubmit={onFormSubmit}
              >
                {({ handleSubmit, isSubmitting }) => (
                  <Form className="form-style-1">
                    <h3 className="title-form">本会員登録</h3>
                    <div className="form-group-item row">
                      <div className="col-lg-2 col-md-4 col-sm-4 col-12 mb-mobile-form col-md-ct-4">
                        <span className="form-label">メールアドレス</span>
                      </div>
                      <div className="col-lg-7 col-md-8 col-sm-8 col-12">
                        <span className="text-color-gray-custom">{store.userAuth && store.userAuth.email}</span>
                      </div>
                    </div>

                    <SignUpCompanyForm
                      categories={store.categories}
                      prefectureData={store.prefectures}
                      isSNSRegistration={isSNSRegistration(store.userAuth)}
                    />

                    <div className="col-12 text-center mt-btn-form">
                      <Button
                        variant="auth common-btn"
                        onClick={handleSubmit}
                        disabled={isSubmitting}
                      >
                        {isSubmitting && (
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            className="me-2"
                          />
                        )}
                        <span className="sr-only">確認</span>
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default SignUpCompany;
