import React, { useEffect, useRef, useState } from 'react';
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';
import TiktokAvatar from 'assets/tiktok_avatar.png';
import { ErrorMessage, useFormikContext } from 'formik';
import * as ROUTES from 'constants/routes';
import constants from 'constants/constants';

import API from 'axios/API';
import { Button, Spinner, Modal } from 'react-bootstrap';
import { HTTP_FORBIDDEN, HTTP_UNPROCESSABLE_ENTITY } from 'constants/ResponseStatus';
import TiktokLogo from 'assets/svg/tiktok-logo.svg';

const API_URL = process.env.REACT_APP_APP_API;

function TiktokerInformation() {
  const param = useParams();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { values, errors, setFieldValue } = useFormikContext();
  const [isConnectTiktok, setIsConnectTiktok] = useState(false);
  const [isTiktokInfoUsed, setIsTiktokInfoUsed] = useState(false);
  const connectTiktokButtonRef = useRef();

  const onClickConnectTiktok = () => {
    const data = JSON.stringify(values);
    localStorage.setItem(constants.OLD_DATA_INFLUENCER_LOCAL_STORAGE, data);
    setIsConnectTiktok(true);
    window.location.href = `${API_URL}/auth/tiktok/influencer/${param.userAuthToken}`;
  };

  const fetchTiktokInfo = async () => {
    setIsConnectTiktok(true);

    await API({
      method: 'GET',
      url: 'influencer/tiktok/fetch-tiktoker-info',
      params: {
        access_token: param.tiktokAccessToken
      }
    })
      .then((res) => {
        const userInfo = res.data.user_info.data.user;
        const tiktokVideos = res.data.video_list;

        let commentCount = 0;
        let shareCount = 0;
        let likeCount = 0;
        let viewCount = 0;
        const videoIds = [];
        tiktokVideos.forEach((video) => {
          commentCount += video.comment_count;
          shareCount += video.share_count;
          likeCount += video.like_count;
          viewCount += video.view_count;
          videoIds.push(video.id);
        });

        const tiktokInfoCount = {
          comment_count: commentCount,
          share_count: shareCount,
          like_count: likeCount,
          view_count: viewCount
        };

        setFieldValue('tiktok_info', { ...userInfo, ...tiktokInfoCount });
        setFieldValue('tiktok_auth_access_token', param.tiktokAccessToken);
        setFieldValue('tiktok_videos', videoIds.toString());
        setIsConnectTiktok(false);
        return res;
      })
      .catch(() => {
        setIsConnectTiktok(false);
        setIsTiktokInfoUsed(true);
        navigate(`${ROUTES.SIGN_UP_INFLUENCER_STEP_2}/${param.userAuthToken}`);

        window.scrollTo({
          top: connectTiktokButtonRef.current.offsetTop,
          left: 0,
          behavior: 'smooth'
        });
      });
  };

  const [showModalCancelTiktok, setShowModalCancelTiktok] = useState(false);
  const handleModalCancelTiktokClose = () => {
    setShowModalCancelTiktok(false);
    navigate(`${ROUTES.SIGN_UP_INFLUENCER_STEP_2}/${param.userAuthToken}`);
  };

  useEffect(() => {
    if (param.tiktokAccessToken != '' && typeof param.tiktokAccessToken != 'undefined') {
      fetchTiktokInfo();
    }
    if (searchParams.get('error') == HTTP_FORBIDDEN) {
      setShowModalCancelTiktok(true);
    }

    if (searchParams.get('error_valid_tiktok') == HTTP_UNPROCESSABLE_ENTITY) {
      setIsTiktokInfoUsed(true);
      navigate(`${ROUTES.SIGN_UP_INFLUENCER_STEP_2}/${param.userAuthToken}`);
    }
  }, []);

  return (
    <>
      <Modal className="modal-amazing" show={showModalCancelTiktok} size="lg" centered>
        <Modal.Header>
          <Modal.Title className="text-center">TikTokが認証されました</Modal.Title>
        </Modal.Header>
        <Modal.Body className="py-5 pb-0">
          <div className="text-center">TikTokの認証をキャンセルしました</div>
        </Modal.Body>
        <Modal.Footer className="justify-content-center border-top-0 pb-5">
          <Button
            variant="auth"
            className="px-5 common-btn btn-modal-redirect"
            size="lg"
            onClick={handleModalCancelTiktokClose}
          >
            閉じる
          </Button>
        </Modal.Footer>
      </Modal>

      <div className="form-group-item row pt-2">
        <div className="col-12">
          <div className="form-label">
            TikTok<span className="label-required">必須</span>
          </div>
          <div className="row">
            <div className="col-lg-6 col-md-8 col-sm-8 col-12">
              <Button
                ref={connectTiktokButtonRef}
                variant="outline"
                className="btn-tiktok-connect w-100"
                onClick={onClickConnectTiktok}
                disabled={isConnectTiktok}
              >
                <div className="btn-tiktok-connect-icon">
                  <img src={TiktokLogo} alt="" />
                </div>
                {isConnectTiktok && (
                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className="me-2" />
                )}
                TikTokを連携する
              </Button>
              <ErrorMessage name="tiktok_info" className="invalid-feedback d-block" component="span" />
              <ErrorMessage name="tiktok_auth_open_id" className="invalid-feedback d-block" component="span" />
              {isTiktokInfoUsed && !errors.tiktok_info && (
                <span className="invalid-feedback d-block">このTikTokアカウントは登録されています。</span>
              )}
            </div>
          </div>
        </div>
      </div>
      {Object.keys(values.tiktok_info).length > 0 && !isConnectTiktok && (
        <div className="tiktok-api-data mb-2">
          <div className="form-group-item row pt-1">
            <div className="col-12">
              <div className="tiktok-avatar">
                <img src={values.tiktok_info.avatar_url || TiktokAvatar} alt="" />
              </div>
            </div>
          </div>

          <div className="form-group-item row tiktok-info">
            <div className="d-md-flex">
              <div className="flex-md-fill">
                <div className="d-flex flex-wrap">
                  <div className="flex-fill">ニックネーム</div>
                  <div className="flex-fill">{values.tiktok_info.display_name}</div>
                </div>

                <div className="d-flex flex-wrap">
                  <div className="flex-fill">フォロワー数</div>
                  <div className="flex-fill">{values.tiktok_info.follower_count.toLocaleString()} 人
                  </div>
                </div>

                <div className="d-flex flex-wrap">
                  <div className="flex-fill">直近7日間に投稿された動画のいいね数</div>
                  <div className="flex-fill">{values.tiktok_info.like_count.toLocaleString()}</div>
                </div>
              </div>

              <div className="flex-md-fill">
                <div className="d-flex flex-wrap">
                  <div className="flex-fill">直近7日間に投稿された動画のコメント数</div>
                  <div className="flex-fill">{values.tiktok_info.comment_count.toLocaleString()}</div>
                </div>

                <div className="d-flex flex-wrap">
                  <div className="flex-fill">直近7日間に投稿された動画のシェア数</div>
                  <div className="flex-fill">{values.tiktok_info.share_count.toLocaleString()}</div>
                </div>

                <div className="d-flex flex-wrap">
                  <div className="flex-fill">直近7日間に投稿された動画の再生回数</div>
                  <div className="flex-fill">{values.tiktok_info.view_count.toLocaleString()}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default TiktokerInformation;
