import React from 'react';
import ReactPaginate from 'react-paginate';

function Pagination(props) {
  return (
    <ReactPaginate
      {...props}
      pageRangeDisplayed={3}
      marginPagesDisplayed={1}
      nextLabel="&nbsp;"
      previousLabel="&nbsp;"
      pageClassName="page-item"
      pageLinkClassName="page-link"
      previousClassName="page-item prev"
      previousLinkClassName="page-link"
      nextClassName="page-item next"
      nextLinkClassName="page-link"
      breakLabel="..."
      breakClassName="page-item"
      breakLinkClassName="page-link"
      containerClassName="pagination client-pagination-custom-1"
      activeClassName="active"
      renderOnZeroPageCount={null}
    />
  );
}

export default Pagination;
