import AdminAPI from 'axios/AdminAPI';
import { ResponseStatus } from 'constants';

export const GET_LIST_ADMIN_TAG = 'GET_LIST_ADMIN_TAG';
export const ADMIN_UPDATE_TAG_SUCCESS = 'ADMIN_UPDATE_TAG_SUCCESS';

export const getTags = (page = 1) => (dispatch) => {
  return AdminAPI({
    method: 'GET',
    url: `tag?page=${page}`
  })
    .then((res) => {
      dispatch({
        type: GET_LIST_ADMIN_TAG,
        payload: res
      });
    })
    .catch((error) => {
      return error;
    });
};

export const updateTag = (form) => (dispatch) => {
  return AdminAPI({
    method: 'PUT',
    url: `tag/${form.id}`,
    data: form
  }).then((res) => {
    if (res.status == ResponseStatus.HTTP_OK) {
      dispatch({
        type: ADMIN_UPDATE_TAG_SUCCESS,
        payload: form
      });
    }

    return res;
  });
};

export const deleteTag = (id) => () => {
  return AdminAPI({
    method: 'DELETE',
    url: `tag/${id}`
  })
    .catch((error) => {
      return error;
    });
};

export const createTag = (_requestForm) => () => {
  return AdminAPI({
    method: 'POST',
    url: 'tag/create',
    data: _requestForm
  })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error;
    });
};
