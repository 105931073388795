import React from 'react';
import PropTypes from 'prop-types';
import { Toast } from 'react-bootstrap';

function CustomBottomToRightToast(props) {
  const { msg, variant, showToast, setShowToast } = props;

  return (
    <div aria-live="polite" aria-atomic="true" className="toast-fixed-rn-custom-1">
      <Toast show={showToast} onClose={() => setShowToast(false)} delay={4000} autohide className={variant}>
        <Toast.Body>{msg}</Toast.Body>
      </Toast>
    </div>
  );
}

CustomBottomToRightToast.defaultProps = {
  variant: 'toast-success'
};

CustomBottomToRightToast.propTypes = {
  msg: PropTypes.string.isRequired,
  variant: PropTypes.string,
  showToast: PropTypes.bool.isRequired,
  setShowToast: PropTypes.func.isRequired
};

export default CustomBottomToRightToast;
