import React from 'react';
import TeddyBearSVG from 'assets/svg/teddy-bear-ic.svg';
import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import * as ROUTES from 'constants/routes';

function PageNotFound() {
  return (
    <div className="page-not-found">
      <Container className="error-page">
        <img className="error__img" src={TeddyBearSVG} alt="" />
        <h3 className="error__code">404</h3>
        <p className="error__msg">エラーが発生しました</p>
        <Link to={`${ROUTES.APP_ADMIN}/${ROUTES.MY_PAGE_ADMIN}`} className="btn common-btn error-btn">トップに戻る</Link>
      </Container>
    </div>
  );
}

export default PageNotFound;
