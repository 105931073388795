import React, { useState } from 'react';
import { Modal, Spinner } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import * as ja from 'helpers/yup-locale-ja';
import { Field, Formik } from 'formik';
import PropTypes from 'prop-types';
import ToastMessage from 'components/admin/common/ToastMessage';
import { ResponseStatus } from 'constants';
import { tiktokInfoStatusData } from 'data/tiktokInfoStatusData';
import { approvalRequest } from 'redux/admin/cancellation';

Yup.setLocale(ja.suggestive);
const FormSchema = Yup.object().shape({
  status: Yup.string().required()
});
function CompanyCancellationModal({ isShowModal, setIsShowModal, data, getCancellationRequest, isAllowAll }) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const dispatch = useDispatch();

  const initFormikValues = {
    talk_room_id: data?.talk_room_id,
    status: ''
  };

  const onSubmitForm = async (form) => {
    setIsSubmitting(true);
    try {
      const res = await dispatch(approvalRequest(form));
      if (res.status === ResponseStatus.HTTP_OK) {
        setIsSubmitting(false);
        setIsShowModal(false);
        getCancellationRequest(1, isAllowAll);
      } else {
        setIsSubmitting(false);
        setIsShowModal(false);
        setShowToast(true);
      }
      return true;
    } catch (error) {
      setIsSubmitting(false);
      return error;
    }
  };

  return (
    <div>
      <Modal
        onHide={() => setIsShowModal(false)}
        show={isShowModal}
        size="lg"
        centered
        className="admin-cancellation-modal"
      >
        <Modal.Header closeButton />
        <Modal.Body>
          <div className="container">
            <Formik initialValues={initFormikValues} validationSchema={FormSchema} onSubmit={onSubmitForm}>
              {({ handleSubmit, dirty, isValid }) => (
                <form className="admin-form-custom-1 form-update">
                  <div className="row mt-4">
                    <div className="col-6">
                      <Field as="select" name="status" id="status" className="form-select rounded-3" required>
                        <option value="">選択して下さい</option>
                        {tiktokInfoStatusData.map((status) => (
                          <option key={status.id} value={status.id}>
                            {status.text}
                          </option>
                        ))}
                      </Field>
                    </div>
                    <div className="col-6 text-end">
                      <button
                        type="button"
                        className="btn admin-btn-green"
                        onClick={() => {
                          handleSubmit();
                        }}
                        disabled={!(isValid && dirty) || isSubmitting}
                      >
                        {isSubmitting && (
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            className="me-2"
                          />
                        )}
                        登録する
                      </button>
                    </div>
                    <div className="col-12 mt-3">
                      <div className="form-control min-h-100" id="comment">
                        {data.comment}
                      </div>
                    </div>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </Modal.Body>
      </Modal>
      <ToastMessage
        isShow={showToast}
        onClose={() => {
          setShowToast(false);
        }}
      />
    </div>
  );
}

CompanyCancellationModal.defaultProps = {
  data: null
};
CompanyCancellationModal.propTypes = {
  isShowModal: PropTypes.bool.isRequired,
  setIsShowModal: PropTypes.func.isRequired,
  data: PropTypes.object,
  getCancellationRequest: PropTypes.func.isRequired,
  isAllowAll: PropTypes.bool.isRequired
};

export default CompanyCancellationModal;
