import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useSearchParams } from 'react-router-dom';
import { LoadingContext } from 'hooks/LoadingContext';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { QuestionCircleFill } from 'react-bootstrap-icons';
import ProjectItem from './ProjectItem';

function ProjectList({ myProjects }) {
  const [isLoading] = useContext(LoadingContext);
  const [searchParams] = useSearchParams();

  const setDefaultActiveTabs = () => {
    const tabArr = ['processing', 'draft', 'archive'];
    const tabActive = searchParams.get('tabActive');
    if (tabActive != '') {
      return tabArr[tabActive];
    }

    return tabArr[0];
  };

  return (
    <Tabs defaultActiveKey={setDefaultActiveTabs()} className="client-tabs mb-4">
      <Tab
        eventKey="processing"
        title={<p>進行中<span className="number">{!isLoading ? myProjects.processing?.count : 0}</span></p>}
        tabClassName="mt-4"
      >
        <div className="section row">
          {isLoading && [...Array(20)].map((e, i) => (
            <ProjectItem key={`${i.toString()}`} />
          ))}
          {myProjects.processing?.count === 0 && !isLoading && <p>見つかりません。</p>}
          {!isLoading && myProjects.processing?.data.map((item) => (
            <ProjectItem project={item} key={item.id} />
          ))}
        </div>
      </Tab>
      <Tab
        eventKey="draft"
        title={<p>下書き<span className="number">{!isLoading ? myProjects.draft?.count : 0}</span></p>}
        tabClassName="mt-4"
      >
        <div className="section row">
          {isLoading && [...Array(20)].map((e, i) => (
            <ProjectItem key={`${i.toString()}`} />
          ))}
          {myProjects.draft?.count === 0 && !isLoading && <p>見つかりません。</p>}
          {!isLoading && myProjects.draft?.data.map((item) => (
            <ProjectItem project={item} key={item.id} />
          ))}
        </div>
      </Tab>
      <Tab
        eventKey="archive"
        title={(
          <p>
            アーカイブ
            <span className="number">
              {!isLoading ? myProjects.archive?.count : 0}
            </span>
            <OverlayTrigger
              key="top"
              placement="top"
              overlay={(
                <Tooltip id="tooltip-top" className="amazing-tooltip">
                  完了・不採用・取引キャンセルとなったプロジェクトがアーカイブに収納されます
                </Tooltip>
              )}
            >
              <QuestionCircleFill className="question-circle-style label-icon ms-2 align-text-bottom" size={24} />
            </OverlayTrigger>
          </p>
        )}
        tabClassName="mt-4"
      >
        <div className="section row">
          {isLoading && [...Array(20)].map((e, i) => (
            <ProjectItem key={`${i.toString()}`} />
          ))}
          {myProjects.archive?.count === 0 && !isLoading && <p>見つかりません。</p>}
          {!isLoading && myProjects.archive?.data.map((item) => (
            <ProjectItem project={item} key={item.id} />
          ))}
        </div>
      </Tab>
    </Tabs>
  );
}
ProjectList.propTypes = {
  myProjects: PropTypes.object.isRequired
};
export default ProjectList;
