import React from 'react';
import PropTypes from 'prop-types';
import useSession from 'hooks/useSession';
import Skeleton from 'react-loading-skeleton';
import { USER_TYPE_INFLUENCER } from 'redux/client/auth';
import constants from 'constants/constants';

const getRandomInt = (min, max) => {
  const minValue = Math.ceil(min);
  const maxValue = Math.floor(max);
  // The maximum is exclusive and the minimum is inclusive
  return Math.floor(Math.random() * (maxValue - minValue) + minValue);
};

function MessageItem({ message, isLoading, projectData }) {
  const { user } = useSession();
  const userType = user?.user_type;
  let talkRoomStatusText = message?.contents?.status_text;
  if (message?.contents?.status == constants.TALK_ROOM_STATUS.RECRUIT
    && message?.contents?.old_status == constants.TALK_ROOM_STATUS.POST_REQUEST) {
    talkRoomStatusText = '投稿修正依頼';
  } else if (projectData.type == constants.PROJECT_TYPE.SPECIFIED
    && message?.contents?.status == constants.TALK_ROOM_STATUS.DO_NOT_RECRUIT
    && message?.contents?.old_status == constants.TALK_ROOM_STATUS.RECRUITMENT) {
    talkRoomStatusText = '指名否認';
  } else if (projectData.type == constants.PROJECT_TYPE.SPECIFIED
    && message?.contents?.status == constants.TALK_ROOM_STATUS.RECRUIT
    && message?.contents?.old_status == constants.TALK_ROOM_STATUS.RECRUITMENT) {
    talkRoomStatusText = '指名承認';
  }
  return (
    <div>
      {isLoading ? (
        <>
          <div className="mt-4 d-flex justify-content-end">
            <Skeleton width={getRandomInt(300, 750)} height={50} borderRadius={4} containerClassName="d-flex" />
          </div>
          <div className="mt-4 d-flex justify-content-start">
            <Skeleton width={getRandomInt(300, 750)} height={50} borderRadius={4} containerClassName="d-flex" />
          </div>
        </>
      ) : (
        <div>

          {(message?.is_system) ? (
            <div className="d-flex align-items-center my-3 justify-content-center text-size-mobile">
              {(message.is_system == 1) && (
                <>
                  <span className="p-2">
                    ステータスが
                  </span>
                  <span className={`text-center pt-1 pb-1 ps-2 pe-2 room-status status-${message?.contents.status}`}>
                    {talkRoomStatusText}
                  </span>
                  <span className="p-2">
                    に変更されました
                  </span>
                </>

              )}
              {(message.is_system == 2) && (
                <span className="btn room-status status-7 long-mess">
                  {message?.contents}
                </span>

              )}
            </div>
          ) : (
            <div>
              {userType != USER_TYPE_INFLUENCER ? (
                <div>
                  {user.id == message?.send_by ? (
                    <div className="pb-4 pt-4 pb-sm-2 pt-sm-2
                      d-flex justify-content-end flex-column-reverse flex-sm-row"
                    >
                      <div className="text-nowrap d-none d-sm-block align-self-end me-sm-3">
                        {message?.reply_status_text && (
                          <span className="reply-status-text me-2">{message?.reply_status_text}</span>
                        )}
                        {message?.created_at_text}
                      </div>
                      <div className="message-my-content">
                        { /* eslint-disable-next-line react/no-danger */}
                        <div className="line-break" dangerouslySetInnerHTML={{ __html: message?.contents }}>
                          { /* message?.contents */}
                        </div>
                        <div
                          className="d-block d-sm-none text-nowrap label-created-influencer"
                        >
                          {message?.reply_status_text && (
                            <span className="reply-status-text me-2">{message?.reply_status_text}</span>
                          )}
                          {message?.created_at_text}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="pb-4 pt-4 d-flex justify-content-start flex-column flex-sm-row ">
                      <div className="message-content">
                        <div className="box-white"> </div>
                        <div className="box-green"> </div>
                        <div className="box-pink"> </div>
                        { /* eslint-disable-next-line react/no-danger */}
                        <div className="line-break" dangerouslySetInnerHTML={{ __html: message?.contents }}>
                          {/* {message?.contents} */}
                        </div>
                        <div className="d-block d-sm-none text-end text-nowrap label-created-not-influencer">
                          {message?.created_at_text}
                        </div>
                      </div>
                      <div className="d-none d-sm-block ms-sm-3 text-nowrap pt-2 align-content-end label-created">
                        {message?.created_at_text}
                      </div>
                    </div>
                  )}
                </div>
              ) : (
                <div>
                  {user.id == message?.send_by ? (
                    <div
                      className="pb-4 pt-4 pb-lg-2 pt-lg-2
                       d-flex align-items-end justify-content-end flex-column-reverse flex-lg-row"
                    >
                      <div
                        className="d-none d-lg-block text-nowrap me-3 pt-2 align-content-end"
                      >
                        {message?.reply_status_text && (
                          <span className="reply-status-text me-2">{message?.reply_status_text}</span>
                        )}
                        {message?.created_at_text}
                      </div>
                      <div className="message-my-content-influencer">
                        <div className="box-white"> </div>
                        <div className="box-green"> </div>
                        <div className="box-pink"> </div>
                        { /* eslint-disable-next-line react/no-danger */}
                        <div className="line-break" dangerouslySetInnerHTML={{ __html: message?.contents }}>
                          {/* {message?.contents} */}
                        </div>
                        <div
                          className="d-block d-lg-none text-nowrap label-created-influencer"
                        >
                          {message?.reply_status_text && (
                            <span className="reply-status-text me-2">{message?.reply_status_text}</span>
                          )}
                          {message?.created_at_text}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="mt-4 pb-4 d-flex align-items-end justify-content-start flex-column flex-lg-row">
                      <div className="message-content-influencer">
                        { /* eslint-disable-next-line react/no-danger */}
                        <div className="line-break" dangerouslySetInnerHTML={{ __html: message?.contents }}>
                          {/* {message?.contents} */}
                        </div>
                        <div className="d-block d-lg-none text-end text-nowrap label-created-not-influencer">
                          {message?.created_at_text}
                        </div>
                      </div>
                      <div className="d-none d-lg-block me-lg-3 ms-lg-3 col-lg-1 text-end text-nowrap">
                        {message?.created_at_text}
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
}

MessageItem.defaultProps = {
  message: {},
  isLoading: true,
  projectData: {}
};

MessageItem.propTypes = {
  message: PropTypes.object,
  projectData: PropTypes.object,
  isLoading: PropTypes.bool
};

export default MessageItem;
