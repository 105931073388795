/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { getNPOs, PROJECT_TYPE_NOMINATION } from 'redux/client/project';
import { Formik, Field } from 'formik';
import { CustomSelectPicker } from 'components/formik';
import * as ROUTES from 'constants/routes';
import { useNavigate } from 'react-router-dom';

function NPOSearchFilter({
  NPOCategoriesData,
  projectState,
  setIsLoading,
  confirmNavigation,
  setNpoCategoryNpoItem
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [npoCategoryId, setNpoCategoryId] = useState(null);

  const initFormikValues = {
    npo_category_id: ''
  };

  const handleNavigate = () => {
    if (projectState.type == PROJECT_TYPE_NOMINATION) {
      navigate(`${ROUTES.APP_COMPANY}/${ROUTES.INFLUENCER_SELECT}`, {
        state: {
          ...projectState,
          npo_category_id: npoCategoryId
        }
      });
    } else {
      navigate(`${ROUTES.APP_COMPANY}/${ROUTES.MY_PROJECT_CREATE_CONFIRM}`, {
        state: {
          ...projectState,
          npo_category_id: npoCategoryId
        }
      });
    }
    confirmNavigation();
  };

  const onFormSubmit = async (form) => {
    setNpoCategoryId(form.npo_category_id);
    setNpoCategoryNpoItem({
      id: form.npo_category_id
    });
    setIsLoading(true);
    try {
      await dispatch(getNPOs(form.npo_category_id));
      setIsLoading(false);
      return true;
    } catch (error) {
      setIsLoading(false);
      return error;
    }
  };

  return (
    <Formik initialValues={initFormikValues} onSubmit={onFormSubmit}>
      {({ handleSubmit }) => (
        <form className="form-style-1">
          <div className="form-group-item row form-search-npo">
            <div className="col-xxl-6 col-xxl-ct-8 col-md-8 col-12 mb-mobile-form">
              <Field
                name="npo_category_id"
                label={['カテゴリ']}
                placeholder="選択してください"
                selectData={NPOCategoriesData}
                component={CustomSelectPicker}
                onChange={handleSubmit}
              />
            </div>
            <div
              className="col-xxl-6
                col-xxl-ct-4
                col-md-4 col-12
                mb-mobile-form
                d-flex
                amazing-mobile-content-center
                mt-mobile-form"
            >
              <button
                type="button"
                className="common-btn btn-submit-project"
                name="navigate_to_confirm"
                onClick={handleNavigate}
                disabled={npoCategoryId === null || npoCategoryId === ''}
              >
                選択する
              </button>
            </div>
          </div>
        </form>
      )}
    </Formik>
  );
}

export default NPOSearchFilter;
