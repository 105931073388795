export const displayAtData = [
  {
    key: 0,
    value: 'お知らせ'
  },
  {
    key: 1,
    value: 'ヘッダー'
  }
];
