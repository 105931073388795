import React from 'react';
import { Field, Formik } from 'formik';
import PropTypes from 'prop-types';
import { Spinner } from 'react-bootstrap';

function ProjectFilter({
  areaData,
  projectTypeData,
  categoryData,
  typeData,
  onSubmitFilterForm,
  isLoading,
  isLoadingExportCSV,
  _exportProjects
}) {
  const initFormikValues = {
    user_type: '',
    area_id: '',
    project_type: '',
    category_id: '',
    type_id: '',
    is_public: false
  };

  return (
    <Formik initialValues={initFormikValues} onSubmit={onSubmitFilterForm}>
      {({ handleChange, handleSubmit }) => (
        <form className="admin-form-custom-1">
          <div className="row mb-3">
            <div className="col-lg-2 col-12">
              <label className="form-label" htmlFor="user_type">
                区分
              </label>
              <Field
                as="select"
                id="user_type"
                name="user_type"
                className="form-select rounded-0"
                required
                onChange={(e) => {
                  handleChange(e);
                  handleSubmit();
                }}
              >
                <option value="">選択して下さい</option>
                <option value="1">企業</option>
                <option value="2">OTASUKE</option>
              </Field>
            </div>
            <div className="col-lg-2 col-12">
              <label className="form-label" htmlFor="area_id">
                エリア
              </label>
              <Field
                as="select"
                id="area_id"
                name="area_id"
                className="form-select rounded-0"
                required
                onChange={(e) => {
                  handleChange(e);
                  handleSubmit();
                }}
              >
                <option value="">都道府県</option>
                {areaData.map((item) => (
                  <option value={item.key} key={`area${item.key}`}>
                    {item.value}
                  </option>
                ))}
              </Field>
            </div>
            <div className="col-lg-3 col-12 rounded-0">
              <label className="form-label" htmlFor="project_type">
                タイプ
              </label>
              <Field
                as="select"
                id="project_type"
                name="project_type"
                className="form-select rounded-0"
                required
                onChange={(e) => {
                  handleChange(e);
                  handleSubmit();
                }}
              >
                <option value="">タイプ</option>
                {projectTypeData.map((item) => (
                  <option value={item.key} key={`projectType${item.key}`}>
                    {item.value}
                  </option>
                ))}
              </Field>
            </div>
            <div className="col-lg-3 col-12 rounded-0">
              <label className="form-label" htmlFor="category_id">
                ジャンル
              </label>
              <Field
                as="select"
                id="category_id"
                name="category_id"
                className="form-select rounded-0"
                required
                onChange={(e) => {
                  handleChange(e);
                  handleSubmit();
                }}
              >
                <option value="">ジャンル</option>
                {categoryData.map((item) => (
                  <option value={item.key} key={`category${item.key}`}>
                    {item.value}
                  </option>
                ))}
              </Field>
            </div>
            <div className="col-lg-2 col-12">
              <label className="form-label" htmlFor="type_id">
                プロジェクト区分
              </label>
              <Field
                as="select"
                id="type_id"
                name="type_id"
                className="form-select rounded-0"
                required
                onChange={(e) => {
                  handleChange(e);
                  handleSubmit();
                }}
              >
                <option value="">ジャンル</option>
                {typeData.map((item) => (
                  <option value={item.key} key={`type${item.key}`}>
                    {item.value}
                  </option>
                ))}
              </Field>
            </div>
          </div>
          <div className="mb-0 mt-4 d-flex flex-column flex-sm-row align-items-sm-center justify-content-between">
            <div className="form-check">
              <Field
                type="checkbox"
                name="is_public"
                id="is_public"
                className="form-check-input"
                onChange={(e) => {
                  handleChange(e);
                  handleSubmit();
                }}
              />
              <label className="form-check-label" htmlFor="is_public">
                公開中のみ表示する
              </label>
            </div>
            <button
              type="button"
              className="btn btn-outline-primary rounded-pill admin-btn-green mt-sm-0 mt-3"
              onClick={(_exportProjects)}
              disabled={isLoading || isLoadingExportCSV}
            >
              {isLoadingExportCSV && (
                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className="me-2" />
              )}
              <strong>CSVダウンロード</strong>
            </button>
          </div>
        </form>
      )}
    </Formik>
  );
}

ProjectFilter.propTypes = {
  areaData: PropTypes.array.isRequired,
  projectTypeData: PropTypes.array.isRequired,
  categoryData: PropTypes.array.isRequired,
  typeData: PropTypes.array.isRequired,
  onSubmitFilterForm: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isLoadingExportCSV: PropTypes.bool.isRequired,
  _exportProjects: PropTypes.func.isRequired
};
export default ProjectFilter;
