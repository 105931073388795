import React from 'react';
import { Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';

function CantDeleteUserModal({
  isCantDeleteUserModalShow,
  setIsCantDeleteUserModalShow
}) {
  const handleClose = () => {
    setIsCantDeleteUserModalShow(false);
  };

  return (
    <div>
      <Modal
        onHide={() => setIsCantDeleteUserModalShow(false)}
        show={isCantDeleteUserModalShow}
        size="md"
        centered
        className="project-list-modal"
      >
        <Modal.Header closeButton />
        <Modal.Body>
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="modal-title text-center">
                  このユーザーは進行中のプロジェクトがあるか、ポイントを保有しているため、削除できません。
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="action-column">
            <button
              type="button"
              tabIndex="0"
              className="admin-btn-light btn btn-secondary"
              onClick={() => handleClose()}
            >
              <span>閉じる</span>
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
CantDeleteUserModal.propTypes = {
  isCantDeleteUserModalShow: PropTypes.bool.isRequired,
  setIsCantDeleteUserModalShow: PropTypes.func.isRequired
};

export default CantDeleteUserModal;
