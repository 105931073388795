import React from 'react';
import { Accordion } from 'react-bootstrap';

function IdentityVerifyAccordion() {
  return (
    <Accordion className="amazing-accordion">
      <Accordion.Item className="amazing-accordion-item" eventKey="0">
        <Accordion.Header className="amazing-accordion-header">履歴事項全部証明書（法人の方）</Accordion.Header>
        <Accordion.Body className="amazing-accordion-body">
          <ul>
            <li>※日本国内発行の本人確認書類に限ります。</li>
            <li>※法人名、法人住所、代表者名、設立日、交付日がすべて確認できるページの提出が必要です。</li>
            <li>※6ヶ月以内に取得した履歴事項全部証明書をご提出ください。</li>
          </ul>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item className="amazing-accordion-item" eventKey="1">
        <Accordion.Header className="amazing-accordion-header">運転免許証</Accordion.Header>
        <Accordion.Body className="amazing-accordion-body">
          必要な面/ページ
          <ul>
            <li>1.表面</li>
            <li>2.裏面（※）</li>
          </ul>
          <div className="bg-content-accordion">
            <span className="fw-bold">注意事項</span>
            <br />
            ※住所変更を行った際は、裏面が必要になります。
          </div>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item className="amazing-accordion-item" eventKey="2">
        <Accordion.Header className="amazing-accordion-header">パスポート</Accordion.Header>
        <Accordion.Body className="amazing-accordion-body">
          必要な面/ページ
          <ul>
            <li>1.写真印刷ページ</li>
          </ul>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item className="amazing-accordion-item" eventKey="3">
        <Accordion.Header className="amazing-accordion-header">住民票</Accordion.Header>
        <Accordion.Body className="amazing-accordion-body">
          必要な面/ページ
          <ul>
            <li>1.氏名記載面</li>
            <li>2.住所記載面</li>
            <li>3.生年月日記載面</li>
          </ul>
          <div className="bg-content-accordion">
            <span className="fw-bold">注意事項</span>
            <br />
            ※交付から６ヶ月以内のものをご提出ください。 <br />
            ※住民票を取得する際には、個人番号（マイナンバー）の記載がないものを準備してください。
          </div>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item className="amazing-accordion-item" eventKey="4">
        <Accordion.Header className="amazing-accordion-header">在留カード（特別永住証明書）</Accordion.Header>
        <Accordion.Body className="amazing-accordion-body">
          必要な面/ページ
          <ul>
            <li>1.表面</li>
            <li>2.裏面（※）</li>
          </ul>
          <div className="bg-content-accordion">
            <span className="fw-bold">注意事項</span>
            <br />
            ※住所変更を行った際は、裏面が必要になります。
          </div>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item className="amazing-accordion-item" eventKey="5">
        <Accordion.Header className="amazing-accordion-header">マイナンバーカード</Accordion.Header>
        <Accordion.Body className="amazing-accordion-body">
          必要な面/ページ
          <ul>
            <li>&nbsp;&nbsp;表面</li>
          </ul>
          <div className="bg-content-accordion">
            <span className="fw-bold">注意事項</span>
            <br />
            ※裏面の画像がアップロードされた場合、弊社にてデータ破棄を行います。
          </div>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}

export default IdentityVerifyAccordion;
