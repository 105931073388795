import React, { useState } from 'react';
import { Modal, Spinner } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { ResponseStatus } from 'constants';
import { updateUserStatus } from 'redux/admin/user';

function DeleteUserModal({
  isDeleteUserModalShow,
  setIsDeleteUserModalShow,
  userId,
  setShowToastError,
  setShowToastSuccess,
  getUserPaginate
}) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const dispatch = useDispatch();

  const handleClose = () => {
    setIsSubmitting(false);
    setIsDeleteUserModalShow(false);
  };

  const handleDeleteUser = async () => {
    setIsSubmitting(true);
    const res = await dispatch(updateUserStatus(userId));
    if (res && res.status == ResponseStatus.HTTP_UNPROCESSABLE_ENTITY) {
      setShowToastError(true);
      return;
    }
    getUserPaginate();
    setShowToastSuccess(true);
    handleClose();
  };

  return (
    <div>
      <Modal
        onHide={() => setIsDeleteUserModalShow(false)}
        show={isDeleteUserModalShow}
        size="md"
        centered
        className="project-list-modal"
      >
        <Modal.Header closeButton />
        <Modal.Body>
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="modal-title text-center">
                  このユーザー削除しますか？<br />
                  削除されたユーザーはログインができなくなりますが、今までのPR情報はシステムに保存されています。
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="action-column mx-auto">
            <button
              type="button"
              tabIndex="0"
              className="admin-btn-light btn btn-secondary px-3"
              onClick={() => handleClose()}
            >
              <span>いいえ</span>
            </button>
            <button
              type="button"
              tabIndex="0"
              className="mx-2 admin-btn-green px-4 btn btn-success"
              onClick={() => handleDeleteUser()}
            >
              {isSubmitting && (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  className="me-2"
                />
              )}
              <span>はい</span>
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
DeleteUserModal.defaultProps = {
  userId: null
};
DeleteUserModal.propTypes = {
  isDeleteUserModalShow: PropTypes.bool.isRequired,
  setIsDeleteUserModalShow: PropTypes.func.isRequired,
  userId: PropTypes.number,
  setShowToastError: PropTypes.func.isRequired,
  setShowToastSuccess: PropTypes.func.isRequired,
  getUserPaginate: PropTypes.func.isRequired
};

export default DeleteUserModal;
