import React from 'react';

function NdaMessage() {
  return (
    <div className="justify-content-center">
      <div className="content__wrapper p-0">
        <div className="group-content">
          <div className="bg-content-finish text-center">
            <h3 className="title-sub mb-0">機密保持契約は完了しています</h3>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NdaMessage;
