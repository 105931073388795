import API from 'axios/API';

export const GET_NEWS_LIST = 'GET_NEWS_LIST';
export const GET_NEWS_DETAIL = 'GET_NEWS_DETAIL';
export const GET_NEWS_HEADER = 'GET_NEWS_HEADER';
export const GET_TOP_10_NEWS = 'GET_TOP_10_NEWS';
export const GET_TOP_3_MY_NEWS = 'GET_TOP_3_MY_NEWS';
export const GET_MY_NEWS = 'GET_MY_NEWS';

export const getNews = (page) => (dispatch) => {
  return API({
    method: 'GET',
    url: `news?page=${page}`
  })
    .then((res) => {
      dispatch({
        type: GET_NEWS_LIST,
        payload: res
      });
    })
    .catch((error) => {
      return error;
    });
};

export const getNewsById = (id) => (dispatch) => {
  return API({
    method: 'GET',
    url: `news/${id}`
  })
    .then((res) => {
      dispatch({
        type: GET_NEWS_DETAIL,
        payload: res
      });
      return res;
    })
    .catch((error) => {
      return error;
    });
};

export const getNewsHeader = () => (dispatch) => {
  return API({
    method: 'GET',
    url: 'news/header'
  })
    .then((res) => {
      dispatch({
        type: GET_NEWS_HEADER,
        payload: res
      });
      return res;
    })
    .catch((error) => {
      return error;
    });
};

export const getTop10News = () => (dispatch) => {
  return API({
    method: 'GET',
    url: 'news/top-10'
  })
    .then((res) => {
      dispatch({
        type: GET_TOP_10_NEWS,
        payload: res
      });
      return res;
    })
    .catch((error) => {
      return error;
    });
};

export const getTop3MyNews = () => (dispatch) => {
  return API({
    method: 'GET',
    url: 'news/my-top-3'
  })
    .then((res) => {
      dispatch({
        type: GET_TOP_3_MY_NEWS,
        payload: res
      });
      return res;
    })
    .catch((error) => {
      return error;
    });
};

export const getMyNews = (currentPage) => (dispatch) => {
  return API({
    method: 'GET',
    url: `my-news?page=${currentPage}`
  })
    .then((res) => {
      dispatch({
        type: GET_MY_NEWS,
        payload: res
      });
    })
    .catch((error) => {
      return error;
    });
};

export const findMyNewsById = (id) => () => {
  return API({
    method: 'GET',
    url: `my-news/${id}`
  })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error;
    });
};

