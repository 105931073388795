import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'react-bootstrap';

function EmailVerificationModal({ show, setShowModalCompleted }) {
  return (
    <Modal show={show} centered size="lg" className="bs-modal-custom-1" onHide={setShowModalCompleted}>
      <Modal.Header className="border-0">
        <Modal.Title className="mx-auto">
          <strong>メールを送信しました</strong>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="modal-title text-center text-muted">
          メールアドレスの変更にはメールアドレス認証が必要です。
          <br />
          変更されたメールアドレスにメールを送信しましたので、ご確認ください。
        </p>
      </Modal.Body>
      <Modal.Footer className="justify-content-center border-top-0">
        <Button className="btn common-btn w-25 py-3" onClick={setShowModalCompleted}>
          閉じる
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

EmailVerificationModal.propTypes = {
  show: PropTypes.bool.isRequired,
  setShowModalCompleted: PropTypes.func.isRequired
};

export default EmailVerificationModal;
