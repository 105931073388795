import React from 'react';
import { Field, Formik } from 'formik';
import PropTypes from 'prop-types';
import { PlusLg } from 'react-bootstrap-icons';

function NewsFilter({ newsTypeData, onSubmitFilterForm, onAddClick }) {
  const initFormikValues = {
    news_type: '',
    is_public: false
  };

  return (
    <Formik initialValues={initFormikValues} onSubmit={onSubmitFilterForm}>
      {({ handleChange, handleSubmit }) => (
        <form className="admin-form-custom-1 admin-news-filter">
          <div className="row mb-3 align-items-end">
            <div className="col-lg-5 col-md-5 col-12">
              <label className="form-label" htmlFor="user_type">
                配信先
              </label>
              <Field
                as="select"
                id="news_type"
                name="news_type"
                className="form-select rounded-0"
                required
                onChange={(e) => {
                  handleChange(e);
                  handleSubmit();
                }}
              >
                <option value="">配信先</option>
                {newsTypeData.map((item) => (
                  <option value={item.key} key={`area${item.key}`}>
                    {item.value}
                  </option>
                ))}
              </Field>
            </div>
            <div className="col-lg-4 col-md-4 col-12 pt-3">
              <div className="form-check">
                <Field
                  type="checkbox"
                  name="is_public"
                  id="is_public"
                  className="form-check-input"
                  onChange={(e) => {
                    handleChange(e);
                    handleSubmit();
                  }}
                />
                <label className="form-check-label" htmlFor="is_public">
                  公開中のみ表示する
                </label>
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-12 text-end">
              <button
                type="button"
                className="btn rounded-pill admin-btn-green icon-only"
                onClick={onAddClick}
              >
                <strong><PlusLg /></strong>
              </button>
            </div>
          </div>
        </form>
      )}
    </Formik>
  );
}

NewsFilter.propTypes = {
  newsTypeData: PropTypes.array.isRequired,
  onSubmitFilterForm: PropTypes.func.isRequired,
  onAddClick: PropTypes.func.isRequired
};
export default NewsFilter;
