/* eslint-disable react/prop-types */
import { FastField, Field, Form, Formik } from 'formik';
import React from 'react';
import { useDispatch } from 'react-redux';
import SearchIcon from 'assets/search.png';
import { getInfluencersForSearching } from 'redux/client/project';
import { genderData } from 'data/genderData';
import { FormLabel } from 'react-bootstrap';
import { CustomSelectPicker, CustomSelectMultiplePicker } from 'components/formik';

function InfluencerSearchFilter({ tagData, prefectureData, setIsLoading, isLoading }) {
  const dispatch = useDispatch();

  const initialSearchFilterItems = {
    keyword: '',
    tag_ids: '',
    prefecture_ids: '',
    gender: ''
  };

  const onFormSubmit = async (form) => {
    setIsLoading(true);
    try {
      await dispatch(getInfluencersForSearching(1, form));
      setIsLoading(false);
      return true;
    } catch (error) {
      setIsLoading(false);
      return error;
    }
  };
  return (
    <Formik initialValues={initialSearchFilterItems} onSubmit={onFormSubmit}>
      {({ handleSubmit }) => (
        <Form className="form-style-1">
          <div className="col-12">
            <FormLabel htmlFor="keyword">キーワード検索</FormLabel>
            <div className="input-group">
              <FastField
                className="form-control search-input"
                id="keyword"
                name="keyword"
                type="text"
                placeholder="コスメ"
              />
              <button
                className="btn btn-outline-secondary search-btn"
                style={{ backgroundColor: 'transparent' }}
                type="submit"
                onClick={handleSubmit}
                disabled={isLoading}
              >
                <img src={SearchIcon} alt="Search" />
              </button>
            </div>
          </div>
          <div className="form-group-item row mb-3">
            <div className="col-lg-6 col-12 mt-3">
              <Field
                name="tag_ids"
                label={['タグ']}
                placeholder="選択して下さい"
                selectData={tagData}
                component={CustomSelectMultiplePicker}
                onChange={handleSubmit}
              />
            </div>
            <div className="col-lg-6 col-12 mt-3">
              <Field
                name="prefecture_ids"
                label={['活動地域']}
                placeholder="選択して下さい"
                selectData={prefectureData}
                component={CustomSelectMultiplePicker}
                onChange={handleSubmit}
              />
            </div>
          </div>

          <div className="form-group-item row">
            <div className="col-12">
              <Field
                name="gender"
                label={['性別']}
                placeholder="選択して下さい"
                selectData={genderData}
                component={CustomSelectPicker}
                onChange={handleSubmit}
              />
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default InfluencerSearchFilter;
