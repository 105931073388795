import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';

import { getListIncomeHistoryByUserId } from 'redux/admin/user';
import IncomeHistoryRow from '../../components/IncomeHistoryRow';

function ListIncomeHistory({ userInfluencer }) {
  const dispatch = useDispatch();
  const param = useParams();
  const containerRef = useRef();
  const [loadingData, setLoadingData] = useState(false);
  const [page, setPage] = useState(2);
  const [data, setData] = useState([]);
  const [disableApi, setDisableApi] = useState(false);

  const loadMoreData = async () => {
    if (!disableApi) {
      setLoadingData(true);
      setDisableApi(true);
      const res = await dispatch(getListIncomeHistoryByUserId(param.id, page));
      if (res.data?.list_income_history?.length > 0) {
        setData([...data, ...res.data.list_income_history]);
        setPage(page + 1);
        setDisableApi(false);
        setLoadingData(false);
      }

      if (res.data?.list_income_history?.length <= 0) {
        setLoadingData(false);
      }
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      const container = containerRef.current;
      if (
        container && container.scrollTop + container.clientHeight >= container.scrollHeight && !disableApi
        && userInfluencer?.list_income_history_total > userInfluencer?.list_income_history_limit
      ) {
        loadMoreData();
      }
    };

    const container = containerRef.current;
    if (container) {
      container.addEventListener('scroll', handleScroll);
    }
    return () => {
      if (container) {
        container.removeEventListener('scroll', handleScroll);
      }
    };
  }, [data, disableApi]);

  const renderRows = (list, message = null) => {
    if (list?.length > 0) {
      return list?.map((item) => <IncomeHistoryRow key={item.user_id} itemRow={item} />);
    }

    if (message) {
      return (
        <tr>
          <td colSpan={6} className="text-center">
            {message}
          </td>
        </tr>
      );
    }

    return true;
  };

  return (
    <section className="section-list-income-history py-5">
      <h3 className="title-section mb-0">報酬獲得履歴</h3>
      <div className="table-responsive table-scroll" ref={containerRef}>
        <table className="table table-lg table-hover admin-table-custom-1 mb-0">
          <thead className="border-top border-2">
            <tr>
              <th>プロジェクト名</th>
              <th>投稿承認日</th>
              <th>投稿動画</th>
              <th>報酬額</th>
              <th>寄付金額</th>
              <th>手数料</th>
            </tr>
          </thead>
          <tbody>
            {renderRows(userInfluencer?.list_income_history, '見つかりません。')}
            {renderRows(data)}

            {
              loadingData && (
                <tr>
                  <td colSpan={6} className="text-center">
                    <Spinner
                      as="span"
                      animation="border"
                      size="lg"
                      role="status"
                      aria-hidden="true"
                      className="text-color-green"
                    />
                  </td>
                </tr>
              )
            }
          </tbody>
        </table>
      </div>
    </section>
  );
}

ListIncomeHistory.propTypes = {
  userInfluencer: PropTypes.object
};

ListIncomeHistory.defaultProps = {
  userInfluencer: null
};
export default ListIncomeHistory;
