import React from 'react';
import ImageDefault from 'assets/default-image.jpg';

function Image(props) {
  return (
    <img
      onError={(e) => {
        e.onerror = null;
        e.target.src = ImageDefault;
      }}
      alt=""
      {...props}
    />
  );
}

export default Image;
