import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import constants from 'constants/constants';
import { getAllArea } from 'redux/client/area';
import { projectTypeData } from 'data/projectTypeData';
import { getProjects } from 'redux/client/project';
import ProjectFilter from './components/ProjectFilter';
import ProjectList from './components/ProjectList';
import NpoTooltip from './components/NpoTooltip';


function index() {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [requestParamsFilter, setRequestParamsFilter] = useState([]);
  const [remountPagingComponent, setRemountPagingComponent] = useState(0);
  const routeParams = useParams();

  const store = useSelector((state) => ({
    areas: state.area.areas,
    projects: state.project.projects
  }));

  useEffect(() => {
    initialPage();
    _getProjects(
      1,
      {
        user_type: constants.USER_TYPE_BY_USER.KEY?.[routeParams.user_type],
        category_id: routeParams.category_id,
        project_type: routeParams.project_type
      },
    );
  }, [
    routeParams.user_type,
    routeParams.category_id,
    routeParams.project_type
  ]);

  const initialPage = async () => {
    try {
      const getAllAreaPromise = store.areas.length === 0 ? dispatch(getAllArea()) : null;

      await Promise.all([
        getAllAreaPromise
      ]);
      setIsLoading(false);
      return true;
    } catch (error) {
      setIsLoading(false);
      return error;
    }
  };

  const _getProjects = async (page = 1, requestParams = []) => {
    setIsLoading(true);
    try {
      await dispatch(getProjects(page, requestParams));
      setIsLoading(false);
      return true;
    } catch (error) {
      setIsLoading(false);
      return error;
    }
  };

  const onSubmitFilterForm = async (form) => {
    if (isLoading) {
      return;
    }
    setRemountPagingComponent(Math.random());
    setRequestParamsFilter(form);
    _getProjects(1, form);
  };

  const onPagingClick = (event) => {
    _getProjects(event.selected + 1, requestParamsFilter);
  };

  return (
    <div className="page-project bg-overlay-mobile">
      <div className="client-container">
        <NpoTooltip />
        <ProjectFilter
          areaData={store.areas}
          projectTypeData={projectTypeData}
          onSubmitFilterForm={onSubmitFilterForm}
        />
        <div className="content_wrapper">
          <ProjectList
            isLoading={isLoading}
            projects={store.projects}
            onPagingClick={onPagingClick}
            remountPagingComponent={remountPagingComponent}
          />
        </div>
      </div>
    </div>
  );
}

export default index;
