import {
  GET_NEWS_LIST,
  GET_NEWS_DETAIL,
  GET_NEWS_HEADER,
  GET_TOP_10_NEWS,
  GET_TOP_3_MY_NEWS,
  GET_MY_NEWS
} from './newsActions';

const initialState = {
  news: {},
  newsDetail: [],
  newsHeader: {},
  top10News: [],
  top3MyNews: [],
  myNews: {
    data: []
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_NEWS_LIST:
      return {
        ...state,
        news: action.payload.data
      };
    case GET_NEWS_DETAIL:
      return {
        ...state,
        newsDetail: action.payload.data
      };
    case GET_NEWS_HEADER:
      return {
        ...state,
        newsHeader: action.payload.data
      };
    case GET_TOP_10_NEWS:
      return {
        ...state,
        top10News: action.payload.data?.length > 0 ? action.payload.data : []
      };
    case GET_TOP_3_MY_NEWS:
      return {
        ...state,
        top3MyNews: action.payload.data?.length > 0 ? action.payload.data : []
      };
    case GET_MY_NEWS:
      return {
        ...state,
        myNews: action.payload.data
      };
    default:
      return state;
  }
};
