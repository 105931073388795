import React from 'react';
import PropTypes from 'prop-types';

function ListProjectReports({ project }) {
  const projectReports = project?.project_reports;
  return (
    <section className="section-list-income-history pb-5">
      <h3 className="title-section mb-2">投稿動画</h3>
      <div className="table-responsive table-scroll">
        <table className="table table-lg table-hover admin-table-custom-1">
          <thead className="border-top border-2">
            <tr>
              <th>ID</th>
              <th>投稿日</th>
              <th>いいね数</th>
              <th>コメント数</th>
              <th>シェア数</th>
              <th>いいね数 (3日後)</th>
              <th>コメント数 (3日後)</th>
              <th>シェア数 (3日後)</th>
              <th>投稿動画リンク</th>
            </tr>
          </thead>
          <tbody>
            {projectReports && projectReports.map((item) => {
              return (
                <tr key={item.id}>
                  <td>{item.id}</td>
                  <td>{item.create_time}</td>
                  <td>{item.like_count}</td>
                  <td>{item.comment_count}</td>
                  <td>{item.share_count}</td>
                  <td>{item.like_count_after}</td>
                  <td>{item.comment_count_after}</td>
                  <td>{item.share_count_after}</td>
                  <td>
                    <a className="link" href={item.video_url} target="_blank" rel="noreferrer">
                      {item.video_title}
                    </a>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </section>
  );
}

ListProjectReports.propTypes = {
  project: PropTypes.object
};

ListProjectReports.defaultProps = {
  project: null
};
export default ListProjectReports;
