import React from 'react';
import PropTypes from 'prop-types';

function NewsRow({ newsItem, onEditClick, onDeleteClick }) {
  return (
    <tr>
      <td className="title-col">
        <p>{newsItem.title}</p>
      </td>
      <td>{newsItem.news_type_text || newsItem?.user?.full_name}</td>
      <td>{newsItem.publish_at_format}</td>
      <td>{newsItem.created_at_format}</td>
      <td>
        <div
          role="button"
          tabIndex="0"
          className="hyperlink underline"
          onClick={onEditClick}
          onKeyDown={onEditClick}
        >
          <span>編集</span>
        </div>
      </td>
      <td>
        <div
          role="button"
          tabIndex="0"
          className="hyperlink underline"
          onClick={onDeleteClick}
          onKeyDown={onDeleteClick}
        >
          <span>削除</span>
        </div>
      </td>
    </tr>
  );
}

NewsRow.propTypes = {
  newsItem: PropTypes.object.isRequired,
  onEditClick: PropTypes.func.isRequired,
  onDeleteClick: PropTypes.func.isRequired
};
export default NewsRow;
