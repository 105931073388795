import React from 'react';
import Skeleton from 'react-loading-skeleton';
import PropTypes from 'prop-types';
import ImageDefault from 'assets/default-image.jpg';


function InfluencerRankingList({ isLoading, influencers }) {
  return (
    <div className="table-responsive">
      <table className="table table-lg table-hover admin-table-custom-1 project-list-table">
        {(!isLoading && influencers.length <= 0) && <caption className="text-center py-5 h5">見つかりません。</caption>}
        <thead className="border-top border-2">
          <tr>
            <th>氏名</th>
            <th>アバター</th>
            <th>報酬</th>
          </tr>
        </thead>
        <tbody>
          {isLoading && [...Array(10)].map((e, i) => (
            <tr key={`${i.toString()}`}>
              <td>
                <Skeleton height={10} />
              </td>
              <td>
                <Skeleton height={10} />
              </td>
              <td>
                <Skeleton height={10} />
              </td>
            </tr>
          ))}
          {(!isLoading && influencers)
              && influencers.map((item, index) => (
                <tr key={`${index.toString()}`} className="cursor-pointer position-relative">
                  <td>
                    {item.username}
                  </td>
                  <td>
                    <img src={item.avatar_image || ImageDefault} width="80px" alt="" />
                  </td>
                  <td>{item.amount_total}</td>
                </tr>
              ))}
        </tbody>
      </table>
    </div>
  );
}
InfluencerRankingList.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  influencers: PropTypes.array.isRequired
};
export default InfluencerRankingList;
