/* eslint-disable react/prop-types */
import { ErrorMessage, Field, useFormikContext } from 'formik';
import React from 'react';
import { Form } from 'react-bootstrap';

function CheckboxGroup({ label, name, options, ...rest }) {
  const { handleChange } = useFormikContext();

  return (
    <Form.Group>
      <label htmlFor={name} className="form-label mb-0">
        {label[0]}
        {label[1] && <span className="label-required">{label[1]}</span>}
      </label>
      <div className={name}>
        <Field name={name} {...rest}>
          {({ field }) => {
            return options.map((option) => {
              return (
                <div
                  className="form-check form-check-inline"
                  key={`${name}-${option.key}`}
                >
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id={`${name}${option.key.toString()}`}
                    {...field}
                    value={option.key.toString()}
                    checked={field.value?.includes(option.key.toString())}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                  />
                  <label htmlFor={`${name}${option.key.toString()}`} className="form-check-label">{option.value}</label>
                </div>
              );
            });
          }}
        </Field>
      </div>
      <ErrorMessage name={name} className="invalid-feedback d-block" component="div" />
    </Form.Group>
  );
}

export default CheckboxGroup;
