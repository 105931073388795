import {
  GET_INFLUENCERS,
  GET_INFLUENCERS_INFORMATION,
  GET_FAVORITE_INFLUENCERS,
  GET_ALL_PROJECT_BY_USER_SUCCESS,
  GET_ALL_PROJECT_OF_USER_BY_TYPE,
  ADD_TO_FAVORITE_LIST,
  GET_ALL_NPOS,
  GET_FAVORITE_NPOS,
  ADD_TO_FAVORITE_NPOS_LIST,
  PROJECT_LIST,
  MY_PROJECT_DETAIL,
  PROJECT_TOP_5,
  PROJECT_DETAIL,
  MY_PROJECT_TOP5,
  GET_PROJECTS_FOR_USER_INFLUENCER_FOR_MYPROJECT,
  GET_PROJECTS_FOR_USER_INFLUENCER_FOR_MYPAGE,
  GET_MY_PROJECT_SUCCESS,
  GET_INFLUENCERS_FOR_SEARCHING,
  GET_FAVORITE_INFLUENCERS_FOR_SEARCHING,
  MY_PROJECT_SAVE_TO_DRAFT
} from './projectActions';

const initialState = {
  influencers: [],
  influencerInformation: {},
  favoriteInfluencers: [],
  projectsOfUser: [],
  projectsOfUserByType: {
    processing: [],
    draft: [],
    archive: []
  },
  NPOUsers: {
    data: []
  },
  favoriteNPOs: [],
  projects: {
    data: []
  },
  myProjectDetail: [],
  top5Projects: [],
  projectDetail: {},
  top5MyProjects: {
    processing: [],
    draft: [],
    archive: []
  },
  projectsInfluencerMyProject: {
    items: {
      data: []
    },
    count: {}
  },
  projectsInfluencerMyPage: {
    processing: [],
    recruiting: [],
    archive: [],
    count: {}
  },
  myProjects: {
    processing: [],
    draft: [],
    archive: []
  }, // Screen 37
  influencerDetail: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_PROJECT_BY_USER_SUCCESS:
      return {
        ...state,
        projectsOfUser: action.payload.data
      };
    case GET_ALL_PROJECT_OF_USER_BY_TYPE:
      return {
        ...state,
        projectsOfUserByType: action.payload.data
      };
    case GET_INFLUENCERS:
      return {
        ...state,
        influencers: action.payload.data
      };
    case GET_FAVORITE_INFLUENCERS:
      return {
        ...state,
        favoriteInfluencers: action.payload.data
      };
    case ADD_TO_FAVORITE_LIST:
      return {
        ...state,
        favoriteInfluencers: {
          data: action.payload.data,
          total: action.payload.total,
          last_page: action.payload.lastPage,
          current_page: state.favoriteNPOs.current_page,
          per_page: state.favoriteNPOs.per_page
        }
      };
    case GET_ALL_NPOS:
      return {
        ...state,
        NPOUsers: action.payload.data
      };
    case GET_FAVORITE_NPOS:
      return {
        ...state,
        favoriteNPOs: action.payload.data
      };
    case ADD_TO_FAVORITE_NPOS_LIST: {
      return {
        ...state,
        favoriteNPOs: {
          data: action.payload.data,
          total: action.payload.total,
          last_page: action.payload.lastPage,
          current_page: state.favoriteNPOs.current_page,
          per_page: state.favoriteNPOs.per_page
        }
      };
    }
    case PROJECT_LIST:
      return {
        ...state,
        projects: action.payload.data
      };
    case GET_MY_PROJECT_SUCCESS:
      return {
        ...state,
        myProjects: action.payload.data
      };
    case MY_PROJECT_DETAIL:
      return {
        ...state,
        myProjectDetail: action.payload.data
      };
    case PROJECT_TOP_5:
      return {
        ...state,
        top5Projects: action.payload.data
      };
    case PROJECT_DETAIL:
      return {
        ...state,
        projectDetail: action.payload.data
      };
    case MY_PROJECT_TOP5:
      return {
        ...state,
        top5MyProjects: action.payload.data
      };
    case GET_PROJECTS_FOR_USER_INFLUENCER_FOR_MYPROJECT:
      return {
        ...state,
        projectsInfluencerMyProject: action.payload.data
      };
    case GET_PROJECTS_FOR_USER_INFLUENCER_FOR_MYPAGE:
      return {
        ...state,
        projectsInfluencerMyPage: action.payload.data
      };
    case GET_INFLUENCERS_INFORMATION:
      return {
        ...state,
        influencerInformation: action.payload.data
      };
    case GET_INFLUENCERS_FOR_SEARCHING:
      return {
        ...state,
        influencers: action.payload.data
      };
    case GET_FAVORITE_INFLUENCERS_FOR_SEARCHING:
      return {
        ...state,
        favoriteInfluencers: action.payload.data
      };
    case 'REMOVE_INFLUENCER_FROM_FAVORITE':
      return {
        ...state,
        favoriteInfluencers: {
          data: action.payload.data,
          total: action.payload.total,
          last_page: action.payload.lastPage,
          current_page: action.payload.currentPage,
          per_page: action.payload.purPage
        }
      };
    case MY_PROJECT_SAVE_TO_DRAFT: {
      return {
        ...state,
        myProjectDetail: {
          ...state.myProjectDetail,
          public: 1
        }
      };
    }
    default:
      return state;
  }
};
