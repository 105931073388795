import API from 'axios/API';

export const GET_MESSAGES = 'GET_MESSAGES';
export const FIND_TALK_ROOM = 'FIND_TALK_ROOM';
export const FIND_VIDEO_INFO = 'FIND_VIDEO_INFO';
export const FIND_PROJECT_APPOINT = 'FIND_PROJECT_APPOINT';


export const getMessages = (talkRoomId, currentPage) => () => {
  return API({
    method: 'GET',
    url: `talk-room/${talkRoomId}/messages?page=${currentPage}`
  })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error;
    });
};

export const getMessagesAfterCreatedAt = (talkRoomId, createdAt) => () => {
  return API({
    method: 'GET',
    url: `message/${talkRoomId}/after/${encodeURIComponent(createdAt)}`
  })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error;
    });
};


export const getNextStatus = (talkRoomId) => () => {
  return API({
    method: 'GET',
    url: `talk-room/${talkRoomId}/status/next`
  })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error;
    });
};

export const findTalkRoomWithMessages = (talkRoomId) => () => {
  return API({
    method: 'GET',
    url: `talk-room/${talkRoomId}?rel=messages,tiktokInfo`
  })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error;
    });
};

export const sendMessage = (fromData) => () => {
  return API({
    method: 'POST',
    url: 'message/create',
    data: fromData
  })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error;
    });
};

export const updateStatus = (fromData) => () => {
  return API({
    method: 'POST',
    url: 'talk-room/update-status?rel=projectReviews,tiktokInfo',
    data: fromData
  })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error;
    });
};

export const updateNotes = (fromData) => () => {
  return API({
    method: 'POST',
    url: 'talk-room/update-notes',
    data: fromData
  })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error;
    });
};


export const findTalkRoom = (talkRoomId) => () => {
  return API({
    method: 'GET',
    url: `talk-room/${talkRoomId}?rel=messages,tiktokInfo,projectReviews`
  })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error;
    });
};

export const findByProjectIdAndInfluencer = (projectId) => () => {
  return API({
    method: 'GET',
    url: `my-project/${projectId}/talk-room?rel=project,userRank,projectReward`
  })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error;
    });
};

export const findVideoInfo = (talkRoomId, url) => () => {
  return API({
    method: 'POST',
    url: 'talk-room/post-request',
    data: { talk_room_id: talkRoomId, video_url: url }
  })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error;
    });
};

export const findProjectAppoint = (talkRoomId) => () => {
  return API({
    method: 'GET',
    url: `project-appoint?talk_room_id=${talkRoomId}`
  })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error;
    });
};

export const updateProjectAppoint = (fromData) => () => {
  return API({
    method: 'POST',
    url: 'project-appoint/update',
    data: fromData
  })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error;
    });
};

export const findTalkRoomOnly = (talkRoomId) => () => {
  return API({
    method: 'GET',
    url: `talk-room/${talkRoomId}?rel=projectReviews,tiktokInfo`
  })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error;
    });
};

export const getUnreadMessagesCount = () => {
  return API({
    method: 'GET',
    url: 'message/unread-message-count'
  })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error;
    });
};

export const updateReadTalkRoomNotification = (talkRoomId) => {
  return API({
    method: 'POST',
    url: 'message/update-read-talkroom-notification',
    data: { talk_room_id: talkRoomId }
  })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error;
    });
};
