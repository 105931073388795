import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Spinner } from 'react-bootstrap';

import { registrationUserCompany, USER_TYPE_COMPANY } from 'redux/client/auth';
import * as ROUTES from 'constants/routes';
import { ResponseStatusMessage } from 'constants/ResponseStatusMessage';
import { findArray } from 'helpers/utils';

function ConfirmSignUpCompany() {
  const navigate = useNavigate();
  const location = useLocation();
  const userState = location.state || null;
  const dispatch = useDispatch();
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (!userState || !userState.verifyToken) {
      navigate(ROUTES.ERROR_404_PAGE);
    }
  }, []);

  const _goBackSignUpConfirm = () => {
    navigate(`${ROUTES.SIGN_UP_COMPANY}/${userState.verifyToken}`, {
      state: userState
    });
  };

  const _registrationUserCompany = async () => {
    setIsSubmitting(true);
    try {
      await dispatch(registrationUserCompany(userState));
      navigate(ROUTES.SIGN_UP_COMPLETE, {
        replace: true,
        state: {
          oauthConfirmSuccess: true,
          email: userState.email,
          password: userState.password,
          userType: USER_TYPE_COMPANY
        }
      });

      return true;
    } catch (error) {
      navigate(ROUTES.ERROR_PAGE, {
        replace: true,
        state: {
          error_code: error.response?.status,
          error_msg: ResponseStatusMessage[error.response?.status]
        }
      });
      return error;
    }
  };
  return (
    userState && (
      <div className="signup-confirm-page">
        <div className="container main-container">
          <div className="row justify-content-center">
            <div className="col-lg-10 col-11 form__wrapper form-style-1">
              <h3 className="title-form">本会員登録</h3>

              <div className="group-item-border row">
                <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                  <span className="form-label">メールアドレス</span>
                </div>
                <div className="col-lg-9 col-md-9 col-sm-6 col-12 text-color-gray-custom">
                  <span>{userState.email}</span>
                </div>
              </div>

              {userState.is_sns_registration == false && (
              <div className="group-item-border row">
                <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                  <span className="form-label">パスワード</span>
                </div>
                <div className="col-lg-9 col-md-9 col-sm-6 col-12 text-color-gray-custom">
                  <span>{'*'.repeat(userState.password.length)}</span>
                </div>
              </div>
              )}

              <div className="group-item-border row">
                <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                  <span className="form-label">会社名</span>
                </div>
                <div className="col-lg-9 col-md-9 col-sm-6 col-12 text-color-gray-custom">
                  <span>{userState.company_name}</span>
                </div>
              </div>

              <div className="group-item-border row">
                <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                  <span className="form-label">会社名（フリガナ）</span>
                </div>
                <div className="col-lg-9 col-md-9 col-sm-6 col-12 text-color-gray-custom">
                  <span>{userState.company_name_kana}</span>
                </div>
              </div>

              <div className="group-item-border row">
                <div className="col-lg-3 col-md-3 col-sm-6 col-6">
                  <span className="form-label">ご担当者様 姓</span>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-6 col-6 text-color-gray-custom">
                  <span>{userState.family_name}</span>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-6 col-6">
                  <span className="form-label">ご担当者様 名</span>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-6 col-6 text-color-gray-custom">
                  <span>{userState.first_name}</span>
                </div>
              </div>

              <div className="group-item-border row">
                <div className="col-lg-3 col-md-3 col-sm-6 col-6">
                  <span className="form-label">ご担当者様 姓（フリガナ）</span>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-6 col-6 text-color-gray-custom">
                  <span>{userState.family_name_kana}</span>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-6 col-6">
                  <span className="form-label">ご担当者様 名（フリガナ）</span>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-6 col-6 text-color-gray-custom">
                  <span>{userState.first_name_kana}</span>
                </div>
              </div>

              <div className="group-item-border row">
                <div className="col-lg-3 col-md-3 col-sm-6 col-6">
                  <span className="form-label">部署名</span>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-6 col-6 text-color-gray-custom">
                  <span>{userState.department || '-'}</span>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-6 col-6">
                  <span className="form-label">役職名</span>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-6 col-6 text-color-gray-custom">
                  <span>{userState.position || '-'}</span>
                </div>
              </div>

              <div className="group-item-border row">
                <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                  <span className="form-label">郵便番号</span>
                </div>
                <div className="col-lg-9 col-md-9 col-sm-6 col-12 text-color-gray-custom">
                  <span>{userState.postcode}</span>
                </div>
              </div>

              <div className="group-item-border row">
                <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                  <span className="form-label">都道府県</span>
                </div>
                <div className="col-lg-9 col-md-9 col-sm-6 col-12 text-color-gray-custom">
                  <span>{findArray(userState.prefecture_id, userState.prefectureData)?.value || '-'}</span>
                </div>
              </div>

              <div className="group-item-border row">
                <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                  <span className="form-label">住所</span>
                </div>
                <div className="col-lg-9 col-md-9 col-sm-6 col-12 text-color-gray-custom">
                  <span>{userState.address}</span>
                </div>
              </div>

              <div className="group-item-border row">
                <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                  <span className="form-label">電話番号</span>
                </div>
                <div className="col-lg-9 col-md-9 col-sm-6 col-12 text-color-gray-custom">
                  <span>{userState.phone}</span>
                </div>
              </div>

              <div className="group-item-border row">
                <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                  <span className="form-label">カテゴリ</span>
                </div>
                <div className="col-lg-9 col-md-9 col-sm-6 col-12 text-color-gray-custom">
                  <ul className="nav flex-column">
                    {userState.categoryList?.map((category) => (
                      <React.Fragment key={category}>
                        <li>{category}</li>
                      </React.Fragment>
                    ))}
                  </ul>
                </div>
              </div>

              <div className="group-item-border row">
                <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                  <span className="form-label">招待コード</span>
                </div>
                <div className="col-lg-9 col-md-9 col-sm-6 col-12 text-color-gray-custom">
                  <span>{userState.invite_code}</span>
                </div>
              </div>

              <div className="row mt-btn-cf-form text-center">
                <div className="col-lg-10 offset-lg-1 col-12 col-ct-12">
                  <div className="row">
                    <div className="col-lg-6 col-md-6 col-12 col-child-ct-12">
                      <Button
                        variant="redirect common-outline-btn my-2"
                        onClick={_goBackSignUpConfirm}
                      >
                        <span className="sr-only">戻る</span>
                      </Button>
                    </div>

                    <div className="col-lg-6 col-md-6 col-12 col-child-ct-12">
                      <Button
                        variant="auth common-btn my-2"
                        onClick={_registrationUserCompany}
                        disabled={isSubmitting}
                      >
                        {isSubmitting && (
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            className="me-2"
                          />
                        )}
                        <span className="sr-only">登録</span>
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  );
}

export default ConfirmSignUpCompany;
