import React, { useState } from 'react';
import { ErrorMessage } from 'formik';
import PropType from 'prop-types';
import { Eye, EyeSlash } from 'react-bootstrap-icons';

function CustomInputPassword({ field, form: { touched, errors }, inputRef, ...props }) {
  const [showPassword, setShowPassword] = useState(false);
  const toggleShowPassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  return (
    <div className="input-group input-group-password">
      <input
        type={showPassword ? 'text' : 'password'}
        autoComplete="off"
        id={field.name}
        className={`form-control ${touched[field.name] && errors[field.name] && 'is-invalid'}`}
        ref={inputRef}
        {...field}
        {...props}
      />
      <button type="button" onClick={toggleShowPassword} className="input-group-text">
        {showPassword ? <Eye size={22} /> : <EyeSlash size={22} />}
      </button>
      <ErrorMessage component="div" name={field.name} className="invalid-feedback" />
    </div>
  );
}

CustomInputPassword.defaultProps = {
  inputRef: undefined
};

CustomInputPassword.propTypes = {
  field: PropType.object.isRequired,
  form: PropType.object.isRequired,
  inputRef: PropType.oneOfType([PropType.func, PropType.shape({ current: PropType.instanceOf(Element) })])
};

export default CustomInputPassword;
