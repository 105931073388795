import React from 'react';
import * as page from 'pages_admin';
import * as ROUTES from 'constants/routes';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import useSessionAdmin from 'hooks/useSessionAdmin';
import Navigation from 'components/admin/common/Navigation';

function PublicRoute() {
  const { accessToken, admin } = useSessionAdmin();
  return accessToken && admin ? <Navigate to={`${ROUTES.APP_ADMIN}/${ROUTES.MY_PAGE_ADMIN}`} /> : <Outlet />;
}

function ProtectedRoute() {
  const { accessToken, admin } = useSessionAdmin();
  return accessToken && admin ? (
    <>
      <Navigation />
      <main className="admin-wrapper">
        <div className="admin-content">
          <div className="flex-grow-1 p-md-4 p-3">
            <Outlet />
          </div>
        </div>
      </main>
    </>
  ) : (
    <Navigate to={`${ROUTES.APP_ADMIN}/${ROUTES.SIGN_IN_ADMIN}`} />
  );
}

function AdminRouter() {
  return (
    <Routes>
      <Route element={<ProtectedRoute />}>
        <Route path={ROUTES.MY_PAGE_ADMIN} element={<page.MyPage />} />
        <Route path={ROUTES.USER_COMPANY_LIST_ADMIN} element={<page.UserCompanyList />} />
        <Route path={ROUTES.USER_COMPANY_DETAIL_ADMIN} element={<page.UserCompanyDetail />} />
        <Route path={ROUTES.USER_INFLUENCER_LIST_ADMIN} element={<page.UserInfluencerList />} />
        <Route path={ROUTES.USER_INFLUENCER_DETAIL_ADMIN} element={<page.UserInfluencerDetail />} />
        <Route path={ROUTES.USER_NPO_LIST_ADMIN} element={<page.UserNPOList />} />
        <Route path={ROUTES.USER_NPO_DETAIL_ADMIN} element={<page.UserNpoDetail />} />
        <Route path={ROUTES.USER_REQUEST_MANAGEMENT} element={<page.UserRequestManagement />} />
        <Route path={ROUTES.TRANSFER_HISTORY_LIST_ADMIN} element={<page.TransferHistoryList />} />
        <Route path={ROUTES.REWARD_RETE_SETTING} element={<page.RewardRateSetting />} />
        <Route path={ROUTES.DISTRIBUTION_TO_NPO} element={<page.DistributionToNPO />} />

        {/* Project routes */}
        <Route path={ROUTES.ADMIN_PROJECT_LIST} element={<page.ProjectList />} />
        <Route path={ROUTES.ADMIN_PROJECT_DETAIL} element={<page.ProjectDetail />} />

        {/* News routes */}
        <Route path={ROUTES.ADMIN_NEWS_LIST} element={<page.NewsList />} />

        {/* Change password */}
        <Route path={ROUTES.ADMIN_CHANGE_PASSWORD} element={<page.ChangeAdminPassword />} />
        <Route path={ROUTES.USER_ADMIN_LIST} element={<page.UserAdmin />} />

        {/* Cancellation Requests */}
        <Route path={ROUTES.COMPANY_CANCELLATION_REQUEST} element={<page.CancellationRequest />} />

        {/* Tag routes */}
        <Route path={ROUTES.TAG} element={<page.TagList />} />

        {/* Invitation routes */}
        <Route path={ROUTES.INVITATION} element={<page.InvitationSetting />} />
      </Route>

      <Route element={<PublicRoute />}>
        <Route path={ROUTES.SIGN_IN_ADMIN} element={<page.SignIn />} />;
      </Route>
      <Route path="*" element={<page.PageNotFound />} />
    </Routes>
  );
}

export default AdminRouter;
