import React from 'react';
import PropTypes from 'prop-types';
import CalculateRatingStars from 'components/common/CalculateRatingStars';

function Reviews({ userInfluencer }) {
  return (
    <section className="section-reviews">
      <div className="row py-2">
        <div className="col-md-6 col-12">
          <div className="row">
            <div className="col-md-6 col-sm-6">
              <div className="label form-control border-0 bg-overlay px-3">評価</div>
            </div>

            <div className="col-md-6 col-sm-6">
              <div className="info form-control border-0">
                <div className="d-flex justify-content-center">
                  <CalculateRatingStars rating={userInfluencer?.current_user_rank} />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-6 col-12">
          <div className="row">
            <div className="col-md-6 col-sm-6">
              <div className="label form-control border-0 bg-overlay px-3">テディベアランク</div>
            </div>

            <div className="col-md-6 col-sm-6">
              <div className="info form-control border-0 text-center">
                ランク{userInfluencer?.teddy_bear_rank}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

Reviews.propTypes = {
  userInfluencer: PropTypes.object
};

Reviews.defaultProps = {
  userInfluencer: null
};
export default Reviews;
