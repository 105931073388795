import React from 'react';
import PropTypes from 'prop-types';
import { Field, Formik } from 'formik';
import { Search } from 'react-bootstrap-icons';

function UserCompanySearchBar({ categoryData, prefectureData, onSubmitFilterForm }) {
  const initFormikValues = {
    category_id: '',
    prefecture_id: '',
    keyword: '',
    is_verified: false
  };

  return (
    <Formik initialValues={initFormikValues} onSubmit={onSubmitFilterForm}>
      {({ handleChange, handleSubmit }) => (
        <form className="admin-form-custom-1">
          <div className="row mb-3">
            <div className="col-md-4 col-12">
              <label htmlFor="category_id" className="form-label">
                ジャンル
              </label>
              <Field
                as="select"
                name="category_id"
                id="category_id"
                className="form-select rounded-0"
                required
                onChange={(e) => {
                  handleChange(e);
                  handleSubmit();
                }}
              >
                <option value="">選択してください</option>
                {categoryData.map((category) => (
                  <option key={category.key} value={category.key}>
                    {category.value}
                  </option>
                ))}
              </Field>
            </div>
            <div className="col-md-4 col-12">
              <label htmlFor="prefecture_id" className="form-label">
                都道府県
              </label>
              <Field
                as="select"
                name="prefecture_id"
                id="prefecture_id"
                className="form-select rounded-0"
                required
                onChange={(e) => {
                  handleChange(e);
                  handleSubmit();
                }}
              >
                <option value="">選択してください</option>
                {prefectureData.map((prefecture) => (
                  <option key={prefecture.key} value={prefecture.key}>
                    {prefecture.value}
                  </option>
                ))}
              </Field>
            </div>
            <div className="col-md-4 col-12">
              <label htmlFor="keyword" className="form-label">
                キーワード検索
              </label>
              <div className="input-group">
                <Field name="keyword" id="keyword" className="form-control rounded-0" />
                <button type="submit" onClick={handleSubmit} className="input-group-text rounded-0 bg-transparent">
                  <Search />
                </button>
              </div>
            </div>
          </div>
          <div className="mb-0 mt-4">
            <div className="form-check">
              <Field
                type="checkbox"
                name="is_verified"
                id="is_verified"
                className="form-check-input"
                onChange={(e) => {
                  handleChange(e);
                  handleSubmit();
                }}
              />
              <label className="form-check-label" htmlFor="is_verified">
                本人確認済み
              </label>
            </div>
          </div>
        </form>
      )}
    </Formik>
  );
}

UserCompanySearchBar.propTypes = {
  categoryData: PropTypes.array.isRequired,
  prefectureData: PropTypes.array.isRequired,
  onSubmitFilterForm: PropTypes.func.isRequired
};

export default UserCompanySearchBar;
