import { REMOVE_ACCESS_TOKEN, SIGN_IN_ADMIN } from './authActions';

const initialState = {
  admin: null,
  accessToken: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SIGN_IN_ADMIN: {
      const resData = action.payload.data;
      return {
        ...state,
        admin: {
          id: resData?.user_admin.id,
          email: resData?.user_admin.email,
          role: resData?.user_admin.role
        },
        accessToken: resData?.access_token
      };
    }

    case REMOVE_ACCESS_TOKEN:
      return {
        ...state,
        admin: null,
        accessToken: null
      };

    default:
      return state;
  }
};
