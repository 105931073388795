import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { getAllArea } from 'redux/client/area';
import { getAllCategory } from 'redux/client/category_global';
import { getAllType } from 'redux/client/type';
import { projectTypeData } from 'data/projectTypeData';
import { getProjects, exportProjects } from 'redux/admin/project';
import ProjectFilter from './components/ProjectFilter';
import ProjectList from './components/ProjectList';

function index() {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingExportCSV, setIsLoadingExportCSV] = useState(false);
  const [requestParamsFilter, setRequestParamsFilter] = useState([]);
  const [remountPagingComponent, setRemountPagingComponent] = useState(0);

  const store = useSelector((state) => ({
    areas: state.area.areas,
    categories: state.categoryGlobal.categories,
    types: state.type.types,
    projects: state.projectAdmin.projects
  }));

  useEffect(() => {
    initialPage();
    _getProjects();
  }, []);

  const initialPage = async () => {
    try {
      const getAllAreaPromise = store.areas.length === 0 ? dispatch(getAllArea()) : null;
      const getAllCategoryPromise = store.categories.length === 0 ? dispatch(getAllCategory()) : null;
      const getAllTypePromise = store.types.length === 0 ? dispatch(getAllType()) : null;

      await Promise.all([getAllAreaPromise, getAllCategoryPromise, getAllTypePromise]);
      setIsLoading(false);
      return true;
    } catch (error) {
      setIsLoading(false);
      return error;
    }
  };

  const _getProjects = async (page = 1, requestParams = []) => {
    setIsLoading(true);
    try {
      await dispatch(getProjects(page, requestParams));
      setIsLoading(false);
      return true;
    } catch (error) {
      setIsLoading(false);
      return error;
    }
  };

  const onSubmitFilterForm = async (form) => {
    if (isLoading) {
      return;
    }
    setRemountPagingComponent(Math.random());
    setRequestParamsFilter(form);
    _getProjects(1, form);
  };

  const onPagingClick = (event) => {
    _getProjects(event.selected + 1, requestParamsFilter);
  };

  const _exportProjects = async () => {
    setIsLoadingExportCSV(true);
    await dispatch(exportProjects(requestParamsFilter)).then((res) => {
      const bom = new Uint8Array([0xEF, 0xBB, 0xBF]);
      const url = URL.createObjectURL(new Blob([bom, res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `projects-list-${moment().format('YYYYMMDDHms')}.csv`);
      document.body.appendChild(link);
      link.click();
    });
    setIsLoadingExportCSV(false);
  };

  return (
    <>
      <ProjectFilter
        areaData={store.areas}
        projectTypeData={projectTypeData}
        categoryData={store.categories}
        typeData={store.types}
        setIsLoading={setIsLoading}
        isLoading={isLoading}
        isLoadingExportCSV={isLoadingExportCSV}
        _exportProjects={_exportProjects}
        onSubmitFilterForm={onSubmitFilterForm}
      />
      <ProjectList
        isLoading={isLoading}
        projects={store.projects}
        onPagingClick={onPagingClick}
        remountPagingComponent={remountPagingComponent}
      />
    </>
  );
}

export default index;
