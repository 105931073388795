import React from 'react';
import PropTypes from 'prop-types';
import { getFormattedDate } from 'helpers/utils';
import { Link } from 'react-router-dom';
import * as ROUTES from 'constants/routes';

function NewsRow({ newsItem }) {
  return (
    <div className="d-flex align-items-md-center flex-column flex-md-row news__item">
      <div className="date">
        <span>{getFormattedDate(newsItem.publish_at)}</span>
      </div>
      <div className="title">
        <Link to={ROUTES.NEWS_DETAIL.replace(':id', newsItem.id)} title={newsItem.title} className="custom-maxline-1">
          {newsItem.title}
        </Link>
      </div>
    </div>
  );
}

NewsRow.propTypes = {
  newsItem: PropTypes.object.isRequired
};
export default NewsRow;
