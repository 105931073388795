import React from 'react';
import PropTypes from 'prop-types';
import ExclamationTriangleFill from 'assets/svg/exclamation-triangle-fill.svg';

function AmazingAlert({ title, message, onCloseAlert, isClose }) {
  return (
    <div role="alert" className="fade alert alert-dismissible show amazing-alert">
      <div className="amazing-alert-wrap d-flex align-items-center">
        {
          isClose && (
            <button type="button" className="btn-close" aria-label="Close alert" onClick={onCloseAlert} />
          )
        }
        <div className="alert-icon">
          <img src={ExclamationTriangleFill} alt="ExclamationTriangleFill" />
        </div>

        <div className="alert-body">
          <h3 className="alert-title">{title}</h3>
          <div className="alert-content">{message}</div>
        </div>
      </div>
    </div>
  );
}

AmazingAlert.defaultProps = {
  title: 'エラー',
  onCloseAlert: () => {},
  isClose: true
};

AmazingAlert.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string.isRequired,
  onCloseAlert: PropTypes.func,
  isClose: PropTypes.bool
};

export default AmazingAlert;
