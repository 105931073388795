/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/forbid-prop-types */
import { ErrorMessage } from 'formik';
import PropType from 'prop-types';
import React, { useEffect } from 'react';
import { Form } from 'react-bootstrap';

function CustomTextArea({ field, form: { touched, errors }, label, inputRef, ...props }) {
  useEffect(() => {
    const textAreas = document.getElementsByTagName('textarea');

    Array.prototype.forEach.call(textAreas, (elem) => {
      const elem2 = elem;
      elem2.placeholder = elem.placeholder.replace(/\\n/g, '\n');
    });
  }, []);

  return (
    <Form.Group>
      {label && (
        <Form.Label htmlFor={field.name}>
          {label[0]}
          {label[1] && <span className="label-required">{label[1]}</span>}
        </Form.Label>
      )}

      <Form.Control
        type="text"
        as="textarea"
        id={field.name}
        rows="8"
        ref={inputRef}
        isInvalid={touched[field.name] && errors[field.name]}
        {...field}
        {...props}
      />
      <ErrorMessage name={field.name} component="div" className="invalid-feedback" />
    </Form.Group>
  );
}

CustomTextArea.defaultProps = {
  inputRef: undefined,
  label: undefined
};

CustomTextArea.propTypes = {
  label: PropType.array,
  field: PropType.object.isRequired,
  form: PropType.object.isRequired,
  inputRef: PropType.oneOfType([
    PropType.func,
    PropType.shape({
      current: PropType.instanceOf(Element)
    })
  ])
};

export default CustomTextArea;
