import API from 'axios/API';

export const GET_INVITE_CODE = 'GET_INVITE_CODE';
export const CREATE_INVITE_CODE_SUCCESS = 'CREATE_INVITE_CODE_SUCCESS';

export const getInviteCode = () => (dispatch) => {
  return API({
    method: 'GET',
    url: 'influencer/invitation'
  })
    .then((res) => {
      dispatch({
        type: GET_INVITE_CODE,
        payload: res
      });
      return res;
    })
    .catch((error) => {
      return error;
    });
};

export const createInviteCode = () => (dispatch) => {
  return API({
    method: 'POST',
    url: 'influencer/invitation/create'
  })
    .then((res) => {
      dispatch({
        type: CREATE_INVITE_CODE_SUCCESS,
        payload: res
      });
      return res;
    })
    .catch((error) => {
      return error;
    });
};
