import React from 'react';
import PropTypes from 'prop-types';
import * as ROUTES from 'constants/routes';
import { Link } from 'react-router-dom';

function UserProjectRow({ itemRow }) {
  return (
    <tr className="cursor-pointer position-relative">
      <td>
        <Link
          to={`${ROUTES.APP_ADMIN}/${ROUTES.ADMIN_PROJECT_DETAIL.replace(':id', itemRow.id)}`}
          className="full-row__link"
        />
        {itemRow.id}
      </td>
      <td>{itemRow.project_title}</td>
      <td>{itemRow.type_name}</td>
      <td>{itemRow.created_at}</td>
      <td align="center">{itemRow.talk_rooms_count_approval}</td>
    </tr>
  );
}

UserProjectRow.propTypes = {
  itemRow: PropTypes.object.isRequired
};
export default UserProjectRow;
