import React from 'react';
import LoadingOverlay from 'components/loading/LoadingOverlay';
import Pagination from 'components/client/Pagination/Pagination';
import PropTypes from 'prop-types';
import ProjectItem from './ProjectItem';

function ProjectList({ isLoading, projects, onPagingClick, remountPagingComponent }) {
  return (
    <div className="mt-1 mt-sm-4 section">
      <div className="header">
        <h2 className="title-bg">Project List</h2>
        <h2 className="title">プロジェクト一覧</h2>
      </div>
      <div className="mt-4 mt-md-4 row section-list-project">
        {isLoading && <LoadingOverlay />}
        {projects?.total < 1 && !isLoading && <p>見つかりません。</p>}
        {projects?.data && !isLoading && (
          <>
            {projects.data.map((item) => (
              <ProjectItem project={item} key={item.id} projectUrl={`/project/${item.id}`} />
            ))}
          </>
        )}
      </div>
      {projects?.last_page > 1 && (
        <div className="mt-4 pb-5 d-flex justify-content-center">
          <Pagination
            key={remountPagingComponent}
            pageCount={projects?.last_page}
            onPageChange={onPagingClick}
          />
        </div>
      )}
    </div>
  );
}

ProjectList.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  projects: PropTypes.object.isRequired,
  onPagingClick: PropTypes.func.isRequired,
  remountPagingComponent: PropTypes.number.isRequired
};

export default ProjectList;
