import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { logOut } from 'redux/admin/auth';
import { Container, Dropdown, Navbar, NavDropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import * as ROUTES from 'constants/routes';
import LOGO from 'assets/logo-black.svg';
import { PersonFill } from 'react-bootstrap-icons';
import PropTypes from 'prop-types';
import useSessionAdmin from 'hooks/useSessionAdmin';
import Sidebar from './Sidebar';

function NavDropdownTitleUser({ user }) {
  return (
    <>
      <PersonFill color="#98A6B5" size={24} />
      <span className="userinfo-name">{user.email}</span>
    </>
  );
}
NavDropdownTitleUser.propTypes = {
  user: PropTypes.object.isRequired
};

function Navigation() {
  const { admin } = useSessionAdmin();
  const dispatch = useDispatch();
  const [isProcessing, setIsProcessing] = useState(false);

  const handleLogOut = async () => {
    setIsProcessing(true);
    await dispatch(logOut());
    setIsProcessing(false);
  };

  return (
    <Navbar bg="light" expand="xl" fixed="top" className="admin-header">
      <Container fluid className="px-md-5 px-3">
        <Navbar.Brand href={`${ROUTES.APP_ADMIN}/${ROUTES.MY_PAGE_ADMIN}`}>
          <img src={LOGO} alt="インフルエンサーマッチング Webアプリ" className="client-footer__logo" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <NavDropdown
          align="end"
          title={<NavDropdownTitleUser user={admin} />}
          className="userinfo-dropdown d-none d-xl-block"
          renderMenuOnMount
        >
          <Dropdown.Item
            onClick={handleLogOut}
            disabled={isProcessing}
          >
            ログアウト
          </Dropdown.Item>
          <Dropdown.Item as={Link} to={ROUTES.ADMIN_CHANGE_PASSWORD}>
            パスワード変更
          </Dropdown.Item>
        </NavDropdown>
        <Sidebar />
      </Container>
    </Navbar>
  );
}

export default Navigation;
