import React from 'react';
import PropTypes from 'prop-types';

function NPORow({ itemRow, selectedItems, npoCategoryId, handleCheckedEvent, isShowCheckBox }) {
  return (
    <tr>
      <td>
        <input
          name="npo_id"
          value={itemRow.id}
          defaultChecked={!!selectedItems.find((element) => element.id == itemRow.id)}
          type={isShowCheckBox ? 'checkbox' : 'hidden'}
          onChange={(e) => handleCheckedEvent(e, itemRow)}
          disabled={!npoCategoryId || npoCategoryId != itemRow.npo_category}
        />
      </td>
      <td>{itemRow.user_id}</td>
      <td>{itemRow.npo_name}</td>
      <td>{itemRow.delegate_name}</td>
      <td>{itemRow.npo_category_name}</td>
      <td className="text-center">{itemRow.donation_format}{itemRow.donation > 0 ? 'pt' : ''}</td>
    </tr>
  );
}

NPORow.defaultProps = {
  isShowCheckBox: true,
  selectedItems: [],
  npoCategoryId: null
};

NPORow.propTypes = {
  itemRow: PropTypes.object.isRequired,
  selectedItems: PropTypes.array,
  npoCategoryId: PropTypes.string,
  handleCheckedEvent: PropTypes.func.isRequired,
  isShowCheckBox: PropTypes.bool
};

export default NPORow;
