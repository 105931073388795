import AdminAPI from 'axios/AdminAPI';

export const GET_USER_COMPANY_PAGINATE_SUCCESS = 'GET_USER_COMPANY_SUCCESS';
export const GET_USER_NPO_PAGINATE_SUCCESS = 'GET_USER_NPO_SUCCESS';
export const GET_USER_INFLUENCER_PAGINATE_SUCCESS = 'GET_USER_INFLUENCER_PAGINATE_SUCCESS';
export const GET_USER_INFLUENCER_REWARD_PAGINATE_SUCCESS = 'GET_USER_INFLUENCER_REWARD_PAGINATE_SUCCESS';
export const GET_USER_COMPANY_IDENTITY = 'GET_USER_COMPANY_IDENTITY';
export const GET_LIST_USER_ADMIN = 'GET_LIST_USER_ADMIN';

export const getUserCompanyPaginate = (page, requestParams) => (dispatch) => {
  let URLApi = `company/list?page=${page}`;
  const requestParamsStr = new URLSearchParams(requestParams).toString();
  if (requestParamsStr) {
    URLApi += `&${requestParamsStr}`;
  }

  return AdminAPI({
    method: 'GET',
    url: URLApi
  })
    .then((res) => {
      dispatch({
        type: GET_USER_COMPANY_PAGINATE_SUCCESS,
        payload: res
      });
    })
    .catch((error) => {
      return error;
    });
};

export const exportUserCompany = (requestParams) => () => {
  let URLApi = 'company/export';
  const requestParamsStr = new URLSearchParams(requestParams).toString();
  if (requestParamsStr) {
    URLApi += `?${requestParamsStr}`;
  }

  return AdminAPI({
    method: 'GET',
    url: URLApi
  })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error;
    });
};

export const getUserNPOPaginate = (page, requestParams) => (dispatch) => {
  let URLApi = `npo/list?page=${page}`;
  const requestParamsStr = new URLSearchParams(requestParams).toString();
  if (requestParamsStr) {
    URLApi += `&${requestParamsStr}`;
  }

  return AdminAPI({
    method: 'GET',
    url: URLApi
  })
    .then((res) => {
      dispatch({
        type: GET_USER_NPO_PAGINATE_SUCCESS,
        payload: res
      });
    })
    .catch((error) => {
      return error;
    });
};

export const exportUserNPO = (requestParams) => () => {
  let URLApi = 'npo/export';
  const requestParamsStr = new URLSearchParams(requestParams).toString();
  if (requestParamsStr) {
    URLApi += `?${requestParamsStr}`;
  }

  return AdminAPI({
    method: 'GET',
    url: URLApi
  })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error;
    });
};

export const getUserInfluencerPaginate = (page, requestParams) => (dispatch) => {
  let URLApi = `influencer/list?page=${page}`;
  const requestParamsStr = new URLSearchParams(requestParams).toString();
  if (requestParamsStr) {
    URLApi += `&${requestParamsStr}`;
  }

  return AdminAPI({
    method: 'GET',
    url: URLApi
  })
    .then((res) => {
      dispatch({
        type: GET_USER_INFLUENCER_PAGINATE_SUCCESS,
        payload: res
      });
    })
    .catch((error) => {
      return error;
    });
};

export const exportUserInfluencer = (requestParams) => () => {
  let URLApi = 'influencer/export';
  const requestParamsStr = new URLSearchParams(requestParams).toString();
  if (requestParamsStr) {
    URLApi += `?${requestParamsStr}`;
  }

  return AdminAPI({
    method: 'GET',
    url: URLApi
  })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error;
    });
};

export const getUserCompanyIdentity = (page, requestParams) => (dispatch) => {
  let URLApi = `company/identity/list?page=${page}`;

  if (requestParams?.is_verify) {
    URLApi += `&is_verify=${requestParams?.is_verify}`;
  }

  return AdminAPI({
    method: 'GET',
    url: URLApi
  })
    .then((res) => {
      dispatch({
        type: GET_USER_COMPANY_IDENTITY,
        payload: res
      });
    })
    .catch((error) => {
      return error;
    });
};

export const updateStatusUserCompanyIdentity = (requestForm) => () => {
  return AdminAPI({
    method: 'POST',
    url: `company/identity/${requestForm.id_company_identity}/status`,
    data: requestForm
  })
    .then((res) => res)
    .catch((error) => {
      return error;
    });
};

export const getUserAdmin = () => (dispatch) => {
  return AdminAPI({
    method: 'GET',
    url: 'list'
  })
    .then((res) => {
      dispatch({
        type: GET_LIST_USER_ADMIN,
        payload: res
      });
    })
    .catch((error) => {
      return error;
    });
};

export const deleteUserAdmin = (id) => () => {
  return AdminAPI({
    method: 'DELETE',
    url: `delete/${id}`
  })
    .catch((error) => {
      return error;
    });
};

export const registerUserAdmin = (_requestForm) => () => {
  return AdminAPI({
    method: 'POST',
    url: 'register',
    data: _requestForm
  })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error;
    });
};

export const getUserInfluencerDetail = (userId) => () => {
  const URLApi = `influencer/${userId}/detail`;

  return AdminAPI({
    method: 'GET',
    url: URLApi
  })
    .then((res) => {
      return res;
    });
};

export const getUserNpoDetail = (userId) => () => {
  const URLApi = `npo/${userId}/detail`;

  return AdminAPI({
    method: 'GET',
    url: URLApi
  })
    .then((res) => {
      return res;
    });
};

export const getListProjectJoinedByUserId = (userId, page) => () => {
  const URLApi = `influencer/list-project-joined/${userId}?page=${page}`;

  return AdminAPI({
    method: 'GET',
    url: URLApi
  })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error;
    });
};

export const getProjectsByNpoCreated = (userId, currentPage) => () => {
  const URLApi = `npo/${userId}/projects?page=${currentPage}`;

  return AdminAPI({
    method: 'GET',
    url: URLApi
  })
    .then((res) => {
      return res;
    });
};

export const getListIncomeHistoryByUserId = (userId, page) => () => {
  const URLApi = `influencer/list-income-history/${userId}?page=${page}`;

  return AdminAPI({
    method: 'GET',
    url: URLApi
  })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error;
    });
};


export const findUserCompanyDetail = (userId) => () => {
  const URLApi = `company/${userId}`
    + '?rel=prefecture,companyCategories,purchasePoints,user'
    + '&relAttr=userRank,projectStatistics'
    + '&relCount=ndaConclusion,identityVerified';

  return AdminAPI({
    method: 'GET',
    url: URLApi
  })
    .then((res) => {
      return res;
    });
};

export const getProjectsByUserCreated = (userId, currentPage) => () => {
  const URLApi = `company/${userId}/projects?page=${currentPage}&relAttr=sumAmount`;

  return AdminAPI({
    method: 'GET',
    url: URLApi
  })
    .then((res) => {
      return res;
    });
};


export const getPurchasePointsByUserId = (userId, currentPage) => () => {
  const URLApi = `company/${userId}/purchase-points?page=${currentPage}`;

  return AdminAPI({
    method: 'GET',
    url: URLApi
  })
    .then((res) => {
      return res;
    });
};

export const getDonationsByUserId = (userId, currentPage) => () => {
  const URLApi = `npo/${userId}/donations?page=${currentPage}`;

  return AdminAPI({
    method: 'GET',
    url: URLApi
  })
    .then((res) => {
      return res;
    });
};


export const allocPoint = (requestForm) => () => {
  return AdminAPI({
    method: 'POST',
    url: 'alloc-point',
    data: requestForm
  })
    .catch((error) => {
      return error;
    });
};

export const updateUserStatus = (userId) => () => {
  return AdminAPI({
    method: 'PUT',
    url: 'user/update-status',
    data: {
      user_id: userId
    }
  })
    .then((res) => {
      return res;
    });
};
