import React from 'react';
import PropTypes from 'prop-types';
import { ErrorMessage, Field, useFormikContext } from 'formik';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { QuestionCircleFill } from 'react-bootstrap-icons';

function RadioGroup({ label, name, options, optionType, ...rest }) {
  const { handleChange } = useFormikContext();
  return (
    <>
      <label htmlFor={name} className="form-label mb-0">
        {label[0]}
        {label[1] && <span className="label-required">{label[1]}</span>}
        {label[3] ? (
          <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{label[3]}</Tooltip>}>
            <span className="d-inline-block">{label[2] && <span className="label-icon">{label[2]}</span>}</span>
          </OverlayTrigger>
        ) : (
          <span className="d-inline-block">{label[2] && <span className="label-icon">{label[2]}</span>}</span>
        )}
      </label>
      <div className={`form-check-radio ${name}`}>
        <Field name={name} {...rest}>
          {({ field }) => {
            return options.map((option) => {
              return (
                <div className="form-check form-check-inline" key={`${name}-${option.key}`}>
                  <input
                    type="radio"
                    className="form-check-input"
                    id={`${name}${option.key}`}
                    {...field}
                    {...rest}
                    value={option.key.toString()}
                    checked={field.value == option.key.toString()}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                  />
                  <label htmlFor={`${name}${option.key.toString()}`}>
                    {option.value}
                    {optionType == 'project-type' && (
                      <OverlayTrigger
                        key="top"
                        placement="top"
                        overlay={(
                          <Tooltip id="tooltip-top" className="amazing-tooltip">
                            {option.key === 0 && 'プロジェクトを一般公開し、インフルエンサーからの応募を募ります。応募のあったインフルエンサーから選考してアサインが可能です'}
                            {option.key === 1 && 'プロジェクトを一般公開し、応募のあったインフルエンサーは選考作業を通さずに設定予算が消化されるまで全員採用となります'}
                            {option.key === 2 && (
                              <span>
                                インフルエンサーを検索してプロジェクト内容に合ったインフルエンサーへ指名オファーを送ることができます。<br />
                                プロジェクト内容は指名オファーを送ったインフルエンサーだけが見ることできます
                              </span>
                            )}
                          </Tooltip>
                        )}
                      >
                        <span className="align-middle">
                          <QuestionCircleFill className="question-circle-style label-required ms-1" size={25} />
                        </span>
                      </OverlayTrigger>
                    )}
                  </label>
                </div>
              );
            });
          }}
        </Field>
      </div>
      <ErrorMessage name={name} className="invalid-feedback d-block" component="div" />
    </>
  );
}
RadioGroup.defaultProps = {
  optionType: 'global'
};
RadioGroup.propTypes = {
  label: PropTypes.array.isRequired,
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  optionType: PropTypes.string
};

export default RadioGroup;
