import {
  CHECK_USER_AUTH_TOKEN,
  REMOVE_ACCESS_TOKEN,
  SAVE_ACCESS_TOKEN,
  SAVE_IS_PURCHASED_POINTS,
  SAVE_USER_INFO,
  SEND_FORM_DATA,
  SIGN_IN,
  SIGN_IN_ADMIN,
  UPDATE_UNREAD_MESSAGE,
  GET_DASHBOARD_COUNT
} from './authActions';

const initialState = {
  user: null,
  accessToken: null,
  unreadMessages: null,
  unreadMessagesPublicOffering: null,
  unreadMessagesCandidacy: null,
  unreadMessagesNominated: null,
  myPoints: null,
  canLeaveGuild: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SEND_FORM_DATA: {
      return {
        ...state,
        res: action.payload.data
      };
    }
    case CHECK_USER_AUTH_TOKEN:
      return {
        ...state,
        userAuth: action.payload.data
      };

    case SIGN_IN: {
      const resData = action.payload.data;
      return {
        ...state,
        user: {
          id: resData?.user.id,
          email: resData?.user.email,
          user_type: resData?.user.user_type,
          family_name: resData?.user.family_name,
          first_name: resData?.user.first_name
        },
        accessToken: resData?.access_token,
        isPurchasedPoints: resData?.is_purchased_points,
        unreadMessages: resData?.unread_messages,
        unreadMessagesPublicOffering: resData?.unread_messages_public_offering,
        unreadMessagesCandidacy: resData?.unread_messages_candidacy,
        unreadMessagesNominated: resData?.unread_messages_nominated,
        myPoints: resData?.my_points,
        canLeaveGuild: resData?.can_leave_guild
      };
    }

    case SIGN_IN_ADMIN: {
      const resData = action.payload.data;
      return {
        ...state,
        userAdmin: {
          id: resData?.user_admin.id,
          email: resData?.user_admin.email,
          role: resData?.user_admin.role
        }
      };
    }

    case SAVE_USER_INFO: {
      const resData = action.payload.data;
      return {
        ...state,
        user: {
          id: resData.id,
          email: resData.email,
          user_type: resData.user_type,
          family_name: resData?.family_name,
          first_name: resData?.first_name
        }
      };
    }

    case SAVE_ACCESS_TOKEN:
      return {
        ...state,
        accessToken: action.payload || null
      };
    case SAVE_IS_PURCHASED_POINTS:
      return {
        ...state,
        isPurchasedPoints: action.payload || null
      };

    case REMOVE_ACCESS_TOKEN:
      return {
        ...state,
        user: null,
        accessToken: null,
        isPurchasedPoints: null,
        unreadMessages: null,
        unreadMessagesPublicOffering: null,
        unreadMessagesCandidacy: null,
        unreadMessagesNominated: null,
        myPoints: null,
        canLeaveGuild: null
      };

    case UPDATE_UNREAD_MESSAGE:
      return {
        ...state,
        unreadMessages: action.payload || null
      };

    case GET_DASHBOARD_COUNT: {
      const resData = action.payload.data;
      return {
        ...state,
        unreadMessages: resData?.unread_messages,
        unreadMessagesPublicOffering: resData?.unread_messages_public_offering,
        unreadMessagesCandidacy: resData?.unread_messages_candidacy,
        unreadMessagesNominated: resData?.unread_messages_nominated,
        myPoints: resData?.my_points,
        canLeaveGuild: resData?.can_leave_guild
      };
    }
    default:
      return state;
  }
};
