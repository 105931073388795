import React, { useState } from 'react';
import { Modal, Spinner } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { ResponseStatus } from 'constants';
import { saveProjectToDraft } from 'redux/admin/project';

function SaveToDraft({
  isSaveToDraftModalShow,
  setIsSaveToDraftModalShow,
  projectId,
  setShowToastError,
  setShowToastSuccess
}) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const dispatch = useDispatch();

  const handleClose = () => {
    setIsSubmitting(false);
    setIsSaveToDraftModalShow(false);
  };

  const handleSaveToDraft = async () => {
    setIsSubmitting(true);
    const res = await dispatch(saveProjectToDraft(projectId));
    if (res && res.status == ResponseStatus.HTTP_UNPROCESSABLE_ENTITY) {
      setShowToastError(true);
      return;
    }

    setShowToastSuccess(true);
    handleClose();
  };

  return (
    <div>
      <Modal
        onHide={() => setIsSaveToDraftModalShow(false)}
        show={isSaveToDraftModalShow}
        size="md"
        centered
        className="project-list-modal"
      >
        <Modal.Header closeButton />
        <Modal.Body>
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="modal-title text-center">
                  このプロジェクトを非公開に変更しますか？<br />
                  非公開にしたプロジェクトは、下書き状態になります。
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="action-column">
            <button
              type="button"
              tabIndex="0"
              className="admin-btn-light btn btn-secondary"
              onClick={() => handleClose()}
            >
              <span>いいえ</span>
            </button>
            <button
              type="button"
              tabIndex="0"
              className="mx-2 admin-btn-green px-5 btn btn-success"
              onClick={() => handleSaveToDraft()}
            >
              {isSubmitting && (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  className="me-2"
                />
              )}
              <span>はい</span>
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
SaveToDraft.defaultProps = {
  projectId: null
};
SaveToDraft.propTypes = {
  isSaveToDraftModalShow: PropTypes.bool.isRequired,
  setIsSaveToDraftModalShow: PropTypes.func.isRequired,
  projectId: PropTypes.number,
  setShowToastError: PropTypes.func.isRequired,
  setShowToastSuccess: PropTypes.func.isRequired
};

export default SaveToDraft;
