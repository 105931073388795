import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';

import * as ROUTES from 'constants/routes';
import { Link } from 'react-router-dom';

function MissingBudgetScoreModal({ showModal, setShowModalMissingBudgetScore, missingBudgetScore }) {
  return (
    <Modal
      show={showModal}
      centered
      dialogClassName="bs-modal-custom-1"
      keyboard={false}
      onHide={() => setShowModalMissingBudgetScore(false)}
    >
      <Modal.Header closeButton className="border-0"> </Modal.Header>
      <Modal.Body className="p-4">
        <h3 className="modal-title h5 mb-3">
          <strong>
            予算が{missingBudgetScore}pt足りません <br />
            インフルエンサーを採用する場合、予算を追加をしてください。
          </strong>
        </h3>
        <ul className="nav flex-column">
          <li>
            <Link className="d-block px-0 py-2 link" to={`${ROUTES.APP_COMPANY}/${ROUTES.MY_POINT_INDEX}`}>
              ポイントを追加購入する
            </Link>
          </li>
        </ul>
      </Modal.Body>
    </Modal>
  );
}

MissingBudgetScoreModal.defaultProps = {
  showModal: false,
  missingBudgetScore: 0
};

MissingBudgetScoreModal.propTypes = {
  showModal: PropTypes.bool,
  setShowModalMissingBudgetScore: PropTypes.func.isRequired,
  missingBudgetScore: PropTypes.number
};

export default MissingBudgetScoreModal;
