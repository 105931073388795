import { PageNotFound } from 'pages';
import React from 'react';
import { Outlet, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';

function RegexpRouter({ regexVal, paramName }) {
  const location = useParams();
  const isRegexp = regexVal?.toString().split('|').includes(location[paramName]);
  return isRegexp ? <Outlet /> : <PageNotFound />;
}

RegexpRouter.propTypes = {
  regexVal: PropTypes.any.isRequired,
  paramName: PropTypes.string.isRequired
};

export default RegexpRouter;
