export default {
  APP_API: process.env.REACT_APP_APP_API,
  OLD_DATA_INFLUENCER_LOCAL_STORAGE: 'influencer-data-form',
  IDENTITY_VERIFY_APPLYING: 0,
  IDENTITY_VERIFY_APPROVED: 1,
  IDENTITY_VERIFY_REJECTED: 2,
  UPLOAD_FILE_MAX_FILE_IDENTITY_VERIFY: 3,
  MAX_UPLOAD_SIZE_1MB: 1048576, // 1MB
  MAX_UPLOAD_SIZE_5MB: 5242880, // 5MB
  MAX_UPLOAD_SIZE_8MB: 8388608, // 8MB
  UPLOAD_IMAGE_SUPPORTED_FORMATS: ['image/jpg', 'image/jpeg', 'image/png'],
  UPLOAD_FILE_SUPPORTED_FORMATS: ['image/jpg', 'image/jpeg', 'image/png', 'application/pdf'],
  PDF_FILE_TYPE: 'pdf',
  PROJECT_PROCESSING: 0,
  PROJECT_DRAFT: 1,
  PROJECT_ARCHIVE: 2,
  PROJECT_TYPE: {
    PUBLIC: 0,
    CANDIDACY: 1,
    SPECIFIED: 2
  },
  PROJECT_PUBLIC: {
    PUBLIC: 0,
    DRAFT: 1
  },
  TALK_ROOM_STATUS: {
    RECRUITMENT: 0,
    DO_NOT_RECRUIT: 1,
    RECRUIT: 2,
    PENDING: 3,
    ADJUSTING: 4,
    CANCEL_THE_TRANSACTION: 5,
    APPROVE_TO_CANCEL_TRANSACTION: 6,
    POST_REQUEST: 7,
    POST_APPROVAL: 8,
    INDICATION_APPROVAL: 9
  },

  USER_TYPE_BY_USER: {
    KEY: {
      influencer: 0,
      company: 1,
      npo: 2
    },

    TEXT: {
      0: 'influencer',
      1: 'company',
      2: 'npo'
    }
  },
  DAY_REMAIN: {
    min: 0,
    max: 5
  },
  VALIDATOR_APPLY_PROJECT_MESSAGE: {
    no_salary_change_request: '報酬変更申請は完了していますか？',
    min_max_follower_not_enough: 'FW数条件を満たしていません',
    not_match_salary_condition: 'このプロジェクトは報酬額{インフルエンサー1人辺りの報酬条件}Pt以上（以下）のインフルエンサーの募集となります'
  },
  LEAVE_IT_TO_MANAGEMENT: '運営にお任せする',
  LEAVE_IT_TO_MANAGEMENT_ID: 18,
  INVITE_CODE_STORAGE: 'invite_code_storage',
  LINE_LINK: 'https://lin.ee/qJ3RBzi'
};
