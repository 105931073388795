/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';

import {
  CheckboxGroup,
  CustomCityCodeInput,
  CustomInput,
  CustomInputPassword,
  CustomSelectPicker
} from 'components/formik';
// import { purposeData } from 'data/purposeData';
import { ErrorMessage, FastField, Field, useFormikContext } from 'formik';
import { CATEGORY_OTHERS_ID } from 'redux/client/category_global';
import { removeCategoryOthers } from 'helpers/utils';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { QuestionCircleFill } from 'react-bootstrap-icons';

function SiginUpCompanyForm({ categories, prefectureData, isSNSRegistration }) {
  const formikContent = useFormikContext();

  return (
    <>
      {!isSNSRegistration && (
      <div className="form-group-item row">
        <div className="col-lg-6 col-md-6 col-sm-6 col-12">
          <label htmlFor="password" className="form-label">
            パスワード
            <span className="label-required">必須</span>
          </label>
          <FastField
            id="password"
            name="password"
            placeholder="半角英数字8桁以上"
            component={CustomInputPassword}
          />
        </div>
      </div>
      )}
      <div className="form-group-item row">
        <div className="col-12">
          <FastField
            name="company_name"
            label={['会社名・団体名', '必須']}
            placeholder="入力してください"
            component={CustomInput}
          />
        </div>
      </div>

      <div className="form-group-item row">
        <div className="col-12">
          <FastField
            name="company_name_kana"
            label={['会社名・団体名（フリガナ）', '必須']}
            placeholder="入力してください"
            component={CustomInput}
          />
        </div>
      </div>

      <div className="form-group-item row">
        <div className="col-lg-6 col-md-6 col-sm-6 col-12 mb-mobile-form">
          <FastField name="family_name" label={['ご担当者様 姓', '必須']} placeholder="入力してください" component={CustomInput} />
        </div>
        <div className="col-lg-6 col-md-6 col-sm-6 col-12">
          <FastField name="first_name" label={['ご担当者様 名', '必須']} placeholder="入力してください" component={CustomInput} />
        </div>
      </div>

      <div className="form-group-item row">
        <div className="col-lg-6 col-md-6 col-sm-6 col-12 mb-mobile-form">
          <FastField
            name="family_name_kana"
            label={['ご担当者様 姓（フリガナ）', '必須']}
            placeholder="入力してください"
            component={CustomInput}
          />
        </div>
        <div className="col-lg-6 col-md-6 col-sm-6 col-12">
          <FastField
            name="first_name_kana"
            label={['ご担当者様 名（フリガナ）', '必須']}
            placeholder="入力してください"
            component={CustomInput}
          />
        </div>
      </div>

      <div className="form-group-item row">
        <div className="col-lg-6 col-md-6 col-sm-6 col-12 mb-mobile-form">
          <FastField name="department" label={['部署名']} placeholder="入力してください" component={CustomInput} />
        </div>
        <div className="col-lg-6 col-md-6 col-sm-6 col-12">
          <FastField name="position" label={['役職名']} placeholder="入力してください" component={CustomInput} />
        </div>
      </div>

      <div className="form-group-item row">
        <div className="col-lg-6 col-md-6 col-sm-6 col-12">
          <div className="auth-field">
            <FastField name="postcode" placeholder="入力してください" component={CustomCityCodeInput} />
          </div>
        </div>
      </div>

      <div className="form-group-item row">
        <div className="col-lg-3 col-md-4 col-sm-5 col-12 mb-mobile-form">
          <Field
            name="prefecture_id"
            label={['都道府県', '必須']}
            placeholder="選択して下さい"
            selectData={prefectureData}
            component={CustomSelectPicker}
          />
        </div>

        <div className="col-lg-9 col-md-8 col-sm-7 col-12">
          <FastField name="address" label={['住所', '必須']} placeholder="入力してください" component={CustomInput} />
        </div>
      </div>

      <div className="form-group-item row">
        <div className="col-12">
          <FastField name="phone" label={['電話番号', '必須']} placeholder="入力してください" component={CustomInput} />
        </div>
      </div>

      <div className="mb-2 pt-2">
        {categories.length !== 0 && (
          <CheckboxGroup
            name="category_id"
            label={['カテゴリ', '必須']}
            options={removeCategoryOthers(categories)}
          />
        )}
      </div>

      <div className="form-group-item row">
        <div className="d-sm-flex align-items-center">
          <div className="form-check form-check-inline d-inline-flex align-items-center me-3">
            <FastField
              type="checkbox"
              id="category_others_id"
              name="category_id"
              className="form-check-input"
              value={CATEGORY_OTHERS_ID.toString()}
            />
            <label className="form-check-label" htmlFor="category_others_id">
              その他
            </label>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-6 col-12">
            <FastField
              type="text"
              id="category_text"
              name="category_text"
              placeholder="入力してください"
              className="form-control"
            />
          </div>
        </div>
        <div className="d-sm-flex align-items-center">
          <ErrorMessage name="category_text" component="span" className="invalid-feedback d-block mt-0" />
        </div>
      </div>

      <div className="form-group-item row">
        <div className="col-lg-6 col-md-6 col-sm-6 col-12">
          <label htmlFor="recruits_number_label" className="form-label">
            招待コード
            <OverlayTrigger
              key="top"
              placement="top"
              overlay={(
                <Tooltip id="tooltip-top" className="amazing-tooltip">
                  招待コードをお持ちの方は入力してください。初回ポイント購入時に特典が受けられます
                </Tooltip>
                )}
            >
              <span className="align-middle">
                <QuestionCircleFill className="question-circle-style label-required mb-1 ms-3" size={25} />
              </span>
            </OverlayTrigger>
          </label>
          <div className="input-group">
            <Field
              name="invite_code"
              id="invite_code"
              type="text"
              value={formikContent.values.invite_code}
              placeholder="入力してください"
              className={`form-control ${formikContent.touched.invite_code && formikContent.errors.invite_code && 'is-invalid'}`}
            />
          </div>
          <ErrorMessage name="invite_code" component="div" className="invalid-feedback text-nowrap d-block" />
        </div>
      </div>
    </>
  );
}

SiginUpCompanyForm.propTypes = {
  categories: PropTypes.array.isRequired,
  prefectureData: PropTypes.array.isRequired,
  isSNSRegistration: PropTypes.bool.isRequired
};

export default SiginUpCompanyForm;
