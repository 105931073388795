import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import * as ROUTES from 'constants/routes';
import Skeleton from 'react-loading-skeleton';
import useSession from 'hooks/useSession';
import { toMemberPage } from 'helpers/utils';
import { addToTransferHistory,
  getPointsBalance,
  getPointsTransferHistory,
  GET_POINTS_BALANCE,
  MINIMUM_TRANSFERABLE_POINTS } from 'redux/client/transfer_points';
import { findMyBankAccount } from 'redux/client/bank_account';
import { ResponseStatusMessage } from 'constants/ResponseStatusMessage';
import Pagination from 'components/client/Pagination/Pagination';
import { USER_TYPE_INFLUENCER } from 'redux/client/auth';
import { HTTP_UNPROCESSABLE_ENTITY } from 'constants/ResponseStatus';
import TransferPointsModal from './components/TransferPointsModal';

function TransferPoints() {
  const [isLoading, setIsLoading] = useState(true);
  const [isShowModal, setIsShowModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [flashMessage, setFlashMessage] = useState('');
  const [isShowFlash, setIsShowFlash] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSession();
  const userType = user?.user_type;
  const store = useSelector((state) => ({
    pointsBalance: state.transferPoints.availablePoints,
    pointsTransferHistory: state.transferPoints.pointsTransferHistory,
    bankAccountInfo: state.bankAccount.bankAccountInfo
  }));
  useEffect(() => {
    initialPage();
  }, []);

  const initialPage = async () => {
    setIsLoading(true);
    try {
      const pointsBalancePromise = dispatch(getPointsBalance());
      const pointsTransferHistoryPromise = dispatch(getPointsTransferHistory());
      const bankAccountInfoPromise = dispatch(findMyBankAccount());
      await Promise.all([pointsBalancePromise, pointsTransferHistoryPromise, bankAccountInfoPromise]);
    } catch (error) {
      navigate(ROUTES.ERROR_PAGE, {
        replace: true,
        state: {
          error_code: error.response?.status,
          error_msg: ResponseStatusMessage[error.response?.status]
        }
      });
    }
    setIsLoading(false);
  };

  const _getTransferHistory = async (page = 1) => {
    setIsLoading(true);
    try {
      await dispatch(getPointsTransferHistory(page));
      setIsLoading(false);
      return true;
    } catch (error) {
      setIsLoading(false);
      return error;
    }
  };

  const onPagingClick = (event) => {
    _getTransferHistory(event.selected + 1);
  };

  const onRequestTransferClick = async () => {
    try {
      setIsSubmitting(true);
      const resp = await dispatch(addToTransferHistory(store.pointsBalance?.net));
      if (resp && resp.status === HTTP_UNPROCESSABLE_ENTITY) {
        setFlashMessage(resp.data.errors);
        setIsShowFlash(true);
      } else {
        dispatch({
          type: GET_POINTS_BALANCE,
          payload: {
            data: {
              gross: 0,
              gross_format: 0,
              net: 0,
              net_format: 0
            }
          }
        });
        setIsShowModal(false);
        _getTransferHistory();
      }
      setIsSubmitting(false);
      return true;
    } catch (error) {
      setFlashMessage('エラーが発生しました。 後でもう一度やり直してください');
      setIsShowFlash(true);
      setIsSubmitting(false);
      return error;
    }
  };

  const onClickRequestButton = async () => {
    setIsShowModal(true);
  };

  const disabledButtonRequestPayment = () => {
    let disabled = false;
    const minimumTransferablePoints = userType !== USER_TYPE_INFLUENCER
      ? MINIMUM_TRANSFERABLE_POINTS : MINIMUM_TRANSFERABLE_POINTS * 10;
    if (!store.bankAccountInfo?.id || store.pointsBalance?.gross < minimumTransferablePoints) {
      disabled = true;
    }

    return disabled;
  };

  return (
    <div className="transfer-points-page">
      <TransferPointsModal
        netAmount={store.pointsBalance?.net_format}
        isShowModal={isShowModal}
        setIsShowModal={setIsShowModal}
        onRequestTransferClick={onRequestTransferClick}
        isSubmitting={isSubmitting}
        flashMessage={flashMessage}
        isShowFlash={isShowFlash}
        setIsShowFlash={setIsShowFlash}
      />
      <div className="client-cpanel-container">
        <div className="position-relative">
          <h2 className="page-title">ポイント管理・振込申請</h2>
          <div className="client-cpanel__page-title-after">
            <span className="shadow-text">Sales Management <br /> Transfer Application</span>
          </div>
        </div>

        <div className="earned-info__container">
          <strong className="earned-box-text">売上金残高</strong>
          <div className="box-style-1__border">
            <div className="row justify-content-center align-item-center transfer_points__container">
              {isLoading ? (
                <div className="w-100 d-flex align-items-center justify-content-between">
                  <Skeleton containerClassName="w-25" height={90} />
                  <Skeleton containerClassName="w-50" height={90} />
                  <Skeleton containerClassName="w-25" height={90} />
                </div>
              ) : (
                <>
                  <div className="col justify-content-center my-points-value-group">
                    <strong className="my-points__name">ポイント残高合計</strong>
                    <div className="transfer-points__box-value">
                      <span>{store.pointsBalance?.gross_format}</span>
                      <span>pt</span>
                    </div>
                  </div>
                  <div
                    // eslint-disable-next-line max-len
                    className="col-12 col-md-5 d-inline-flex flex-wrap align-items-end justify-content-center my-points-intro pt-2"
                  >
                    <div className="transfer-points__box-value">
                      <p>※ 1pt＝1円となります。</p>
                      <p>※ 振込手数料として660pt消費されます。</p>
                      <p>※ 5,660 pt以上から振込申請が可能です。</p>
                      <p>※<Link to={toMemberPage(userType, ROUTES.BANK_ACCOUNT)}>銀行口座登録</Link>を完了後、振込申請が利用いただけます。</p>
                    </div>
                  </div>

                  <button
                    type="button"
                    className="col-12 col-md-3 w-auto btn common-btn earned-points__btn"
                    disabled={disabledButtonRequestPayment()}
                    onClick={onClickRequestButton}
                  >
                    振込申請を申請する
                  </button>
                </>
              )}
            </div>
          </div>
        </div>

        <div className="mb-4">
          <p>
            ※ポイントは、当月末締めで集計し、翌月15日(15日が土日祝日の場合は次の直近の平日)に出金することができます。<br />
            ※報酬獲得後、2週間以内にPR動画を削除した場合は獲得した報酬ptを差し引いた金額での振込となります
          </p>
        </div>

        <div className="earned-list__container">
          <div className="earned-list__header">
            <strong>売上履歴</strong>
          </div>
          <div className="earned-list__body">
            {isLoading && [...Array(10)].map((e, i) => (
              <div className="earned-list__item" key={`${i.toString()}`}>
                <Skeleton containerClassName="w-50" height={24} />
                <Skeleton containerClassName="w-25" height={24} />
              </div>
            ))}
            {store.pointsTransferHistory?.total < 1 && !isLoading && <p className="pt-3">見つかりません。</p>}
            {store.pointsTransferHistory?.data && !isLoading && (
            <>
              {store.pointsTransferHistory.data.map((item) => (
                <div className="earned-list__item" key={item.id}>
                  <div className="earned-list__box-history">
                    <small className="text-muted">{item.updated_at}</small>
                    {item.project_title && <p className="my-2 fw-bold">{item.project_title}</p>}
                    {item.company_name && <p className="mb-0">{item.company_name}</p>}
                  </div>
                  <div className="earned-list__box-value text-center">
                    <span>{user?.user_type == USER_TYPE_INFLUENCER ? item.reward : item.donation}</span>
                    <span>pt</span><br />
                    {item.expiration_at && !item.is_transfer_application ? (
                      <>
                        <span className="font-14 fw-bold">ポイント有効期限: </span>
                        <span className="d-block d-sm-inline font-14 fw-bold">{item.expiration_at}</span>
                      </>
                    ) : ''}
                  </div>
                </div>
              ))}
            </>
            )}
          </div>
          {store.pointsTransferHistory?.last_page > 1 && (
          <div className="mt-4 pb-5 d-flex justify-content-center">
            <Pagination
              pageCount={store.pointsTransferHistory?.last_page}
              activePage={store.pointsTransferHistory?.current_page}
              onPageChange={onPagingClick}
            />
          </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default TransferPoints;
