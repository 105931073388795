import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Spinner } from 'react-bootstrap';

import useSession from 'hooks/useSession';
import { USER_TYPE_NPO } from 'redux/client/auth';
import * as ROUTES from 'constants/routes';
import { HTTP_OK } from 'constants/ResponseStatus';
import { ResponseStatusMessage } from 'constants/ResponseStatusMessage';
import { findArray, toMemberPage } from 'helpers/utils';
import { updateUser } from 'redux/client/user';
import { purposeDataNPO } from 'data/purposeData';
import EmailVerificationModal from '../components/EmailVerificationModal';
import UpdateCompleted from '../components/UpdateCompleted';

function ConfirmUpdateNpo() {
  const { user } = useSession();
  const userType = user?.user_type;
  const navigate = useNavigate();
  const location = useLocation();
  const userState = location.state || null;
  const dispatch = useDispatch();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showModalCompleted, setShowModalCompleted] = useState(false);

  useEffect(() => {
    if (!userState) {
      navigate(ROUTES.ERROR_404_PAGE);
    }
  }, []);

  const _goBackUpdate = () => {
    navigate(toMemberPage(USER_TYPE_NPO, ROUTES.MY_PROFILE_UPDATE));
  };

  const _updateUser = async () => {
    setIsSubmitting(true);
    try {
      const res = await dispatch(updateUser(userState, USER_TYPE_NPO));
      if (res.status === HTTP_OK) {
        setIsSubmitting(false);
        setShowModalCompleted(true);
      } else {
        return _goBackUpdate();
      }
      return true;
    } catch (error) {
      navigate(ROUTES.ERROR_PAGE, {
        replace: true,
        state: {
          error_code: error.response?.status,
          error_msg: ResponseStatusMessage[error.response?.status]
        }
      });
      return error;
    }
  };

  const handleCloseModal = (redirect = false) => {
    setShowModalCompleted(false);

    if (redirect) {
      navigate(toMemberPage(userType, ROUTES.MYPAGE));
    }
  };

  return (
    userState && (
      <div className="signup-npo-confirm">
        <EmailVerificationModal
          show={showModalCompleted && userState.email != user.email}
          setShowModalCompleted={handleCloseModal}
        />
        <UpdateCompleted
          showModalCompleted={showModalCompleted && userState.email == user.email}
          handleCloseModal={() => handleCloseModal(true)}
        />
        <div className="container main-container">
          <div className="row justify-content-center">
            <div className="col-lg-10 col-11 form__wrapper form-style-1">
              <h3 className="title-form">会員情報</h3>

              <div className="group-item-border row">
                <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                  <span className="form-label">メールアドレス</span>
                </div>
                <div className="col-lg-9 col-md-9 col-sm-6 col-12 text-color-gray-custom">
                  <span>{userState.email}</span>
                </div>
              </div>

              <div className="group-item-border row">
                <div className="col-lg-3 col-md-3 col-sm-6 col-6">
                  <span className="form-label">姓</span>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-6 col-6 text-color-gray-custom">
                  <span>{userState.family_name}</span>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-6 col-6">
                  <span className="form-label">名</span>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-6 col-6 text-color-gray-custom">
                  <span>{userState.first_name}</span>
                </div>
              </div>

              <div className="group-item-border row">
                <div className="col-lg-3 col-md-3 col-sm-6 col-6">
                  <span className="form-label">姓（フリガナ）</span>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-6 col-6 text-color-gray-custom">
                  <span>{userState.family_name_kana}</span>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-6 col-6">
                  <span className="form-label">名（フリガナ）</span>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-6 col-6 text-color-gray-custom">
                  <span>{userState.first_name_kana}</span>
                </div>
              </div>

              <div className="group-item-border row">
                <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                  <span className="form-label">電話番号</span>
                </div>
                <div className="col-lg-9 col-md-9 col-sm-6 col-12 text-color-gray-custom">
                  <span>{userState.phone}</span>
                </div>
              </div>

              <div className="group-item-border row">
                <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                  <span className="form-label">カテゴリ</span>
                </div>
                <div className="col-lg-9 col-md-9 col-sm-6 col-12 text-color-gray-custom">
                  <ul className="nav flex-column">
                    {userState.categoryList?.map((category) => (
                      <React.Fragment key={category}>
                        <li>{category}</li>
                      </React.Fragment>
                    ))}
                  </ul>
                </div>
              </div>

              <div className="group-item-border row">
                <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                  <span className="form-label">会社名</span>
                </div>
                <div className="col-lg-9 col-md-9 col-sm-6 col-12 text-color-gray-custom">
                  <span>{userState.npo_name}</span>
                </div>
              </div>

              <div className="group-item-border row">
                <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                  <span className="form-label">会社名（フリガナ）</span>
                </div>
                <div className="col-lg-9 col-md-9 col-sm-6 col-12 text-color-gray-custom">
                  <span>{userState.npo_name_kana}</span>
                </div>
              </div>

              <div className="group-item-border row">
                <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                  <span className="form-label">会社URL</span>
                </div>
                <div className="col-lg-9 col-md-9 col-sm-6 col-12 text-color-gray-custom">
                  <span>{userState.url}</span>
                </div>
              </div>

              <div className="group-item-border row">
                <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                  <span className="form-label">代表者名</span>
                </div>
                <div className="col-lg-9 col-md-9 col-sm-6 col-12 text-color-gray-custom">
                  <span>{userState.delegate_name}</span>
                </div>
              </div>

              <div className="group-item-border row">
                <div className="col-lg-3 col-md-3 col-sm-6 col-6">
                  <span className="form-label">部署名</span>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-6 col-6 text-color-gray-custom">
                  <span>{userState.department}</span>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-6 col-6">
                  <span className="form-label">役職名</span>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-6 col-6 text-color-gray-custom">
                  <span>{userState.position}</span>
                </div>
              </div>

              <div className="group-item-border row">
                <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                  <span className="form-label">郵便番号</span>
                </div>
                <div className="col-lg-9 col-md-9 col-sm-6 col-12 text-color-gray-custom">
                  <span>{userState.postcode}</span>
                </div>
              </div>

              <div className="group-item-border row">
                <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                  <span className="form-label">住所</span>
                </div>
                <div className="col-lg-9 col-md-9 col-sm-6 col-12 text-color-gray-custom">
                  <span>{userState.address}</span>
                </div>
              </div>

              <div className="group-item-border row">
                <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                  <span className="form-label">都道府県</span>
                </div>
                <div className="col-lg-9 col-md-9 col-sm-6 col-12 text-color-gray-custom">
                  <span>{findArray(userState.prefecture_id, userState.prefectureData)?.value || '-'}</span>
                </div>
              </div>

              <div className="group-item-border row border-0">
                <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                  <span className="form-label">利用動機</span>
                </div>
                <div className="col-lg-9 col-md-9 col-sm-6 col-12 text-color-gray-custom">
                  <span>{findArray(userState.npo_purpose, purposeDataNPO)?.value || '-'}</span>
                </div>
              </div>

              <div className="row mt-btn-cf-form text-center">
                <div className="col-lg-10 offset-lg-1 col-12 col-ct-12">
                  <div className="row">
                    <div className="col-lg-6 col-md-6 col-12 col-child-ct-12">
                      <Button
                        variant="redirect common-outline-btn my-2"
                        onClick={_goBackUpdate}
                      >
                        <span className="sr-only">戻る</span>
                      </Button>
                    </div>

                    <div className="col-lg-6 col-md-6 col-12 col-child-ct-12">
                      <Button
                        variant="auth common-btn my-2"
                        onClick={_updateUser}
                        disabled={isSubmitting}
                      >
                        {isSubmitting && (
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            className="me-2"
                          />
                        )}
                        <span className="sr-only">編集</span>
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  );
}

export default ConfirmUpdateNpo;
