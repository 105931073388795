import React from 'react';
import PropTypes from 'prop-types';
import * as ROUTES from 'constants/routes';
import { Link } from 'react-router-dom';

function UserNPORow({ itemRow, onAddNewsClick, onAddPointClick, onDeleteUser }) {
  return (
    <tr className="cursor-pointer position-relative">
      <td>
        <Link
          to={`${ROUTES.APP_ADMIN}/${ROUTES.USER_NPO_DETAIL_ADMIN.replace(':id', itemRow.user_id)}`}
          className="full-row__link"
        />
        {itemRow.user_id}
      </td>
      <td>{itemRow.npo_name}</td>
      <td>{itemRow.full_name}</td>
      <td>{itemRow.npo_category_name}</td>
      <td>{itemRow.category_name}</td>
      <td className="action-column">
        <div
          role="button"
          tabIndex="0"
          className="hyperlink underline d-inline-block mx-2"
          onClick={onAddNewsClick}
          onKeyDown={onAddNewsClick}
        >
          <span>お知らせ配信</span>
        </div>
        <div
          role="button"
          tabIndex="0"
          className="hyperlink underline d-inline-block mx-2"
          onClick={onAddPointClick}
          onKeyDown={onAddPointClick}
        >
          <span>ポイント付与</span>
        </div>
        <div
          role="button"
          tabIndex="0"
          className="hyperlink underline d-inline-block mx-2"
          onClick={onDeleteUser}
          onKeyDown={onDeleteUser}
        >
          <span>削除</span>
        </div>
      </td>
    </tr>
  );
}

UserNPORow.propTypes = {
  itemRow: PropTypes.object.isRequired,
  onAddNewsClick: PropTypes.func.isRequired,
  onAddPointClick: PropTypes.func.isRequired,
  onDeleteUser: PropTypes.func.isRequired
};
export default UserNPORow;
