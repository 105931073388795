import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { getAllPrefectures } from 'redux/client/category_global';
import {
  addInfluencerToFavorite,
  getFavoriteInfluencersForSearching,
  getInfluencersForSearching,
  PROJECT_TYPE_NOMINATION,
  removeUserFromFavorite
} from 'redux/client/project';
import { HTTP_OK } from 'constants/ResponseStatus';
import { useCallbackPrompt } from 'hooks';
import { Button, Modal, Tab, Tabs } from 'react-bootstrap';
import * as ROUTES from 'constants/routes';
import DialogBox from 'components/common/DialogBox';
import LoadingOverlay from 'components/loading/LoadingOverlay';
import useSession from 'hooks/useSession';
import { toMemberPage } from 'helpers/utils';
import Pagination from 'components/client/Pagination/Pagination';
import { getAllTags } from 'redux/client/tag';
import MissingBudgetScoreModal from './components/MissingBudgetScoreModal';
import InfluencerItem from './components/InfluencerItem';
import InfluencerSearchFilter from './components/InfluencerSearchFilter';

function InfluencerSelect() {
  const { user } = useSession();
  const [nominatedList, setNominatedList] = useState([]);
  const [isProcessing, setIsProcessing] = useState(false);
  const [tempFavorite, setTempFavorite] = useState([]);
  const [isFavoriteTab, setIsFavoriteTab] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isShowModalSendMail, setIsShowModalSendMail] = useState(false);
  const [influencerSendMail, setInfluencerSendMail] = useState([]);
  const [showModalMissingBudgetScore, setShowModalMissingBudgetScore] = useState(false);
  const [missingBudgetScore, setMissingBudgetScore] = useState(0);
  const store = useSelector((state) => ({
    tags: state.tag.tags,
    prefectures: state.categoryGlobal.prefectures,
    influencers: state.project.influencers,
    favoriteInfluencers: state.project.favoriteInfluencers
  }));
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const projectState = location.state || null;
  const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(true);


  useEffect(() => {
    if (!projectState || projectState.type != PROJECT_TYPE_NOMINATION) {
      navigate(toMemberPage(user?.user_type, ROUTES.MY_PROJECT_CREATE));
    }
    initialPage();
  }, []);

  const initialPage = async () => {
    try {
      setNominatedList(projectState.nominatedInfluencers ?? []);
      const getAllTagPromise = store.tags.length === 0 ? dispatch(getAllTags()) : null;
      const getAllPrefecturesPromise = store.prefectures.length === 0 ? dispatch(getAllPrefectures()) : null;
      const getInfluencersPromise = dispatch(getInfluencersForSearching(1));
      const getFavoriteInfluencersPromise = dispatch(getFavoriteInfluencersForSearching(1));

      await Promise.all([
        getAllTagPromise,
        getAllPrefecturesPromise,
        getInfluencersPromise,
        getFavoriteInfluencersPromise
      ]);

      getFavoriteInfluencersPromise.then((result) => {
        if (result.data?.total > 0) {
          result.data.data?.map((influencer) => (
            setTempFavorite(Object.assign(tempFavorite, {
              [influencer.id]: true
            }))
          ));
        }
        return result;
      });
      setIsLoading(false);
      return true;
    } catch (error) {
      setIsLoading(false);
      return error;
    }
  };

  // handle pagination
  const handleFetchData = (currentPage = 1, isOpenFavoriteTab) => {
    setIsLoading(true);
    setIsFavoriteTab(isOpenFavoriteTab);

    try {
      if (isOpenFavoriteTab) {
        dispatch(getFavoriteInfluencersForSearching(currentPage + 1));
      } else {
        dispatch(getInfluencersForSearching(currentPage + 1));
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const handlePageChange = (selectedObject) => {
    const isOpenFavoriteTab = false;
    handleFetchData(selectedObject.selected, isOpenFavoriteTab);
  };

  const handleFavoritePageChange = (selectedObject) => {
    const isOpenFavoriteTab = true;
    handleFetchData(selectedObject.selected, isOpenFavoriteTab);
  };
  const addToFavorite = async (influencer) => {
    setIsProcessing(true);
    setTempFavorite(Object.assign(tempFavorite, {
      [influencer.id]: true
    }));
    try {
      const resp = await dispatch(addInfluencerToFavorite(user.id, influencer.id));
      if (resp.status === HTTP_OK) {
        const newDataObject = [...store.favoriteInfluencers.data, influencer];
        const totalPage = store.favoriteInfluencers.total + 1;
        const lastPageCount = Math.ceil((store.favoriteInfluencers.total + 1) / store.favoriteInfluencers.per_page);
        await dispatch({
          type: 'ADD_TO_FAVORITE_LIST',
          payload: {
            data: newDataObject,
            total: totalPage,
            lastPage: lastPageCount
          }
        });
      }
      setIsProcessing(false);
      return true;
    } catch (error) {
      setIsProcessing(false);
      return false;
    }
  };

  const removeFromFavorite = async (influencer) => {
    setIsProcessing(true);
    const influencerId = influencer.id;

    setTempFavorite((current) => {
      const copy = { ...current };
      delete copy[influencerId];
      return copy;
    });

    try {
      const resp = await dispatch(removeUserFromFavorite(influencerId));
      if (resp.status === HTTP_OK) {
        const newDataObject = store.favoriteInfluencers.data.filter((item) => item.id !== influencer.id);
        const totalPage = store.favoriteInfluencers.total - 1;
        const lastPageCount = Math.ceil((store.favoriteInfluencers.total - 1) / store.favoriteInfluencers.per_page);
        await dispatch({
          type: 'REMOVE_INFLUENCER_FROM_FAVORITE',
          payload: {
            data: newDataObject,
            total: totalPage,
            lastPage: lastPageCount,
            currentPage: isFavoriteTab ? store.favoriteInfluencers.current_page : store.influencers.current_page,
            perPage: isFavoriteTab ? store.favoriteInfluencers.per_page : store.influencers.per_page
          }
        });
      }
      setIsProcessing(false);
      return true;
    } catch (error) {
      setIsProcessing(false);
      return false;
    }
  };

  const handleNomination = (influencerId, nickName, salaryAmount) => {
    if (isDisableNomination(influencerId)) {
      setNominatedList(nominatedList.filter((item) => item.influencerId !== influencerId));
    } else {
      setNominatedList([...nominatedList, { influencerId, nickName, salaryAmount }]);
    }
  };

  const handleNavigate = () => {
    let totalSalaryAmount = 0;
    nominatedList.forEach((element) => {
      totalSalaryAmount += Number(element.salaryAmount.replaceAll(',', ''));
    });

    if (totalSalaryAmount > Number(projectState.budget)) {
      setMissingBudgetScore(totalSalaryAmount - Number(projectState.budget));
      setShowModalMissingBudgetScore(true);
    } else {
      navigate(`${ROUTES.APP_COMPANY}/${ROUTES.MY_PROJECT_CREATE_CONFIRM}`, {
        state: {
          ...projectState,
          nominatedInfluencers: nominatedList
        }
      });
      confirmNavigation();
    }
  };

  const handleDetail = (influencer) => {
    navigate(`${ROUTES.APP_COMPANY}/${ROUTES.PROJECT_CREATION_INFLUENCER_DETAIL.replace(':id', influencer.id)}`, {
      state: {
        ...projectState,
        nominatedInfluencers: nominatedList
      }
    });
    confirmNavigation();
  };
  const isDisableNomination = (influencerId) => {
    if ((nominatedList.length > 0)
      && nominatedList.find((item) => {
        return item.influencerId == influencerId;
      })
    ) {
      return true;
    }
    return false;
  };

  const handleCloseModal = () => {
    setIsShowModalSendMail(false);
  };

  const addInfluencerSendMail = async (userIdInfluencer) => {
    if (influencerSendMail.includes(userIdInfluencer)) return;
    await setInfluencerSendMail((preState) => [...preState, userIdInfluencer]);
  };

  return (
    <div className="client-cpanel-container">
      <DialogBox
        showDialog={showPrompt}
        confirmNavigation={confirmNavigation}
        cancelNavigation={cancelNavigation}
      />

      <MissingBudgetScoreModal
        showModal={showModalMissingBudgetScore}
        setShowModalMissingBudgetScore={setShowModalMissingBudgetScore}
        missingBudgetScore={missingBudgetScore}
      />

      <div className="page-search-influencers">
        <div className="box-style-1__border create-project__search-user-form">
          <div className="row justify-content-center">
            <div className="col-lg-8 col-12 form__wrapper">
              <h3 className="title-form">
                インフルエンサー検索
                <span>指名するインフルエンサーを選択してください</span>
              </h3>
              <InfluencerSearchFilter
                tagData={store.tags}
                prefectureData={store.prefectures}
                setIsLoading={setIsLoading}
                isLoading={isLoading}
              />
              <div className="col-12 d-flex justify-content-end amazing-mobile-content-center">
                <button
                  type="button"
                  className="common-btn btn-submit-project"
                  name="navigate_nomination"
                  onClick={handleNavigate}
                  disabled={nominatedList?.length <= 0}
                >
                  次に進む
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="list-wrapper carousel-nav">
          <Tabs defaultActiveKey="list" id="uncontrolled-tab" className="amazing-tabs">
            <Tab eventKey="list" title="一覧">
              <div className="row">
                {isLoading && <LoadingOverlay /> }
                {(store.influencers?.total < 1 && !isLoading) && <p>見つかりません。</p>}
                {store.influencers?.data && !isLoading && (
                <>
                  {store.influencers.data.map((influencer) => (
                    <InfluencerItem
                      influencer={influencer}
                      key={`influencer${influencer.id}`}
                      addToFavorite={() => addToFavorite(influencer)}
                      removeFromFavorite={() => removeFromFavorite(influencer)}
                      tempFavorite={tempFavorite}
                      isFavorite={influencer.is_favorite}
                      isProcessing={isProcessing}
                      isDisableNomination={isDisableNomination(influencer.id)}
                      handleNomination={() => handleNomination(
                        influencer.id,
                        influencer.tiktok_info?.nickname,
                        influencer.expected_salary_amount_certain
                      )}
                      handleDetail={() => handleDetail(influencer)}
                      setIsShowModalSendMail={setIsShowModalSendMail}
                      influencerSendMail={influencerSendMail}
                      addInfluencerSendMail={addInfluencerSendMail}
                    />
                  ))}

                  {store.influencers?.last_page > 1 && (
                    <div className="d-flex justify-content-center">
                      <Pagination
                        pageCount={store.influencers.last_page}
                        activePage={store.influencers.current_page}
                        pageRange={2}
                        marginPagesDisplayed={2}
                        onPageChange={handlePageChange}
                      />
                    </div>
                  )}
                </>
                )}
              </div>
            </Tab>
            <Tab eventKey="favorite" title="お気に入り">
              <div className="row">
                {store.favoriteInfluencers?.total < 1 && <p>見つかりません。</p>}
                {store.favoriteInfluencers?.data
                && store.favoriteInfluencers.data.map((favoriteInfluencer) => (
                  <InfluencerItem
                    influencer={favoriteInfluencer}
                    key={`influencer_favorite${favoriteInfluencer.id}`}
                    addToFavorite={() => addToFavorite(favoriteInfluencer)}
                    removeFromFavorite={() => removeFromFavorite(favoriteInfluencer)}
                    tempFavorite={tempFavorite}
                    isProcessing={isProcessing}
                    isDisableNomination={isDisableNomination(favoriteInfluencer.id)}
                    handleNomination={() => handleNomination(
                      favoriteInfluencer.id,
                      favoriteInfluencer.tiktok_info?.nickname,
                      favoriteInfluencer.expected_salary_amount_certain
                    )}
                    handleDetail={() => handleDetail(favoriteInfluencer)}
                    setIsShowModalSendMail={setIsShowModalSendMail}
                    influencerSendMail={influencerSendMail}
                    addInfluencerSendMail={addInfluencerSendMail}
                  />
                ))}
                {store.favoriteInfluencers?.last_page > 1
                && (
                <div className="d-flex justify-content-center">
                  <Pagination
                    pageCount={store.favoriteInfluencers.last_page}
                    activePage={store.favoriteInfluencers.current_page}
                    pageRange={2}
                    marginPagesDisplayed={2}
                    onPageChange={handleFavoritePageChange}
                  />
                </div>
                )}
              </div>
            </Tab>
          </Tabs>
        </div>
      </div>
      <Modal className="modal-amazing" show={isShowModalSendMail} size="lg" centered>
        <Modal.Body>
          <div className="text-center">
            <p className="mb-0">インフルエンサーにログインリクエストを送信しました</p>
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-center border-top-0">
          <Button variant="modal-redirect" className="common-btn btn-small py-2" onClick={handleCloseModal}>
            閉じる
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default InfluencerSelect;
