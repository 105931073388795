import React from 'react';
import Skeleton from 'react-loading-skeleton';
import PropTypes from 'prop-types';

function NpoRankingList({ isLoading, npoList }) {
  return (
    <div className="table-responsive">
      <table className="table table-lg table-hover admin-table-custom-1 project-list-table">
        {(!isLoading && npoList.length <= 0) && <caption className="text-center py-5 h5">見つかりません。</caption>}
        <thead className="border-top border-2">
          <tr>
            <th>NPO団体名</th>
            <th>ポイント数</th>
          </tr>
        </thead>
        <tbody>
          {isLoading && [...Array(10)].map((e, i) => (
            <tr key={`${i.toString()}`}>
              <td>
                <Skeleton height={10} />
              </td>
              <td>
                <Skeleton height={10} />
              </td>
            </tr>
          ))}
          {(!isLoading && npoList)
              && npoList.map((item, index) => (
                <tr key={`${index.toString()}`} className="cursor-pointer position-relative">
                  <td>
                    {item.npo_name}
                  </td>
                  <td>{item.donation_total}</td>
                </tr>
              ))}
        </tbody>
      </table>
    </div>
  );
}
NpoRankingList.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  npoList: PropTypes.array.isRequired
};
export default NpoRankingList;
