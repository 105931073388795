import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import useSession from 'hooks/useSession';
import { toMemberPage } from 'helpers/utils';
import { MYPAGE, SIGN_UP_COMPLETE, HOME } from 'constants/routes';

function PublicRoute() {
  const location = useLocation();
  const { accessToken, user } = useSession();

  const url = location.pathname === SIGN_UP_COMPLETE
    ? HOME : toMemberPage(user?.user_type, MYPAGE);

  return accessToken && user ? <Navigate to={url} /> : <Outlet />;
}

export default PublicRoute;
