import { CATEGORY_OTHERS_ID } from 'redux/client/category_global';
import * as Yup from 'yup';
import * as ja from 'helpers/yup-locale-ja';

Yup.setLocale(ja.suggestive);
export const SignUpCompanyValidation = Yup.object().shape({
  password: Yup.string()
    .when('is_sns_registration', (isSNSRegistration, schema) => {
      return isSNSRegistration
        ? schema : schema.required()
          .max(255)
          .matches(/^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z0-9]{8,}$/, '半角英数字8桁以上で入力してください');
    }),
  first_name: Yup.string()
    .required()
    .min(1)
    .max(128)
    .matches(/^([一-龠 ]|[ぁ-ゔ ]|[ァ-ヴー ]|[a-zA-Z ]|[ａ-ｚＡ-Ｚ ]|[々〆〤ヶ ])+$/)
    .matches(/\S/), // Names cannot contain all spaces
  family_name: Yup.string()
    .required()
    .min(1)
    .max(128)
    .matches(/^([一-龠 ]|[ぁ-ゔ ]|[ァ-ヴー ]|[a-zA-Z ]|[ａ-ｚＡ-Ｚ ]|[々〆〤ヶ ])+$/)
    .matches(/\S/), // Names cannot contain all spaces
  first_name_kana: Yup.string()
    .required()
    .min(1)
    .max(128)
    .matches(/^([ァ-ン]|ー)+$/),
  family_name_kana: Yup.string()
    .required()
    .min(1)
    .max(128)
    .matches(/^([ァ-ン]|ー)+$/),
  phone: Yup.string()
    .required()
    .matches(/^[0-9]{10,11}$/),
  category_id: Yup.array().of(Yup.string()).min(1),
  category_text: Yup.string().when('category_id', (categoryIds, schema) => {
    return categoryIds?.includes(CATEGORY_OTHERS_ID.toString()) ? schema.required().max(255) : schema;
  }),
  company_name: Yup.string().required().min(1).max(128),
  company_name_kana: Yup.string()
    .required()
    .min(1)
    .max(128)
    // eslint-disable-next-line no-irregular-whitespace
    .matches(/^([一-龠Z　]|[ぁ-ゔZ　]|[ァ-ヴーZ　]|[a-zA-Z　]|[ａ-ｚＡ-Ｚ　]|[々〆〤ヶ ])+$/)
    .matches(/\S/), // Names cannot contain all spaces
  department: Yup.string().min(0).max(128),
  position: Yup.string().min(0).max(128),
  postcode: Yup.string()
    .required()
    .matches(/^[0-9-]{7,8}$/),
  prefecture_id: Yup.string().required().max(255),
  address: Yup.string().required().min(1).max(255),
  invite_code: Yup.string().min(7).max(7).matches(/^[A-Z0-9]+$/)
});
