import AdminAPI from 'axios/AdminAPI';

export const GET_NEWS_LIST = 'GET_NEWS_LIST';


export const getNews = (page, filterItems) => (dispatch) => {
  let URLApi = `news?page=${page}`;
  if (filterItems?.news_type?.toString()) {
    URLApi += `&news_type=${filterItems.news_type}`;
  }
  if (filterItems?.is_public) {
    URLApi += `&is_public=${filterItems.is_public}`;
  }

  return AdminAPI({
    method: 'GET',
    url: URLApi
  })
    .then((res) => {
      dispatch({
        type: GET_NEWS_LIST,
        payload: res
      });
    })
    .catch((error) => {
      return error;
    });
};

export const createNews = (requestForm) => () => {
  return AdminAPI({
    method: 'POST',
    url: 'news',
    data: requestForm
  })
    .catch((error) => {
      return error;
    });
};

export const updateNews = (requestForm) => () => {
  return AdminAPI({
    method: 'PUT',
    url: `news/${requestForm.id}`,
    data: requestForm
  })
    .catch((error) => {
      return error;
    });
};

export const deleteNews = (id) => () => {
  return AdminAPI({
    method: 'DELETE',
    url: `news/${id}`
  })
    .catch((error) => {
      return error;
    });
};
