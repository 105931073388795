import {
  GET_USER_COMPANY_PAGINATE_SUCCESS,
  GET_USER_INFLUENCER_PAGINATE_SUCCESS,
  GET_USER_NPO_PAGINATE_SUCCESS,
  GET_USER_INFLUENCER_REWARD_PAGINATE_SUCCESS,
  GET_USER_COMPANY_IDENTITY,
  GET_LIST_USER_ADMIN
} from './userActions';

const initialState = {
  usersCompany: [],
  NPOsCompany: [],
  influencersCompany: [],
  userInfluencerReward: [],
  userCompanyIdentity: [],
  usersAdmin: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_USER_COMPANY_PAGINATE_SUCCESS:
      return {
        ...state,
        usersCompany: action.payload.data
      };

    case GET_USER_NPO_PAGINATE_SUCCESS:
      return {
        ...state,
        NPOsCompany: action.payload.data
      };
    case GET_USER_INFLUENCER_PAGINATE_SUCCESS:
      return {
        ...state,
        influencersCompany: action.payload.data
      };
    case GET_USER_INFLUENCER_REWARD_PAGINATE_SUCCESS:
      return {
        ...state,
        userInfluencerReward: action.payload.data
      };
    case GET_USER_COMPANY_IDENTITY:
      return {
        ...state,
        userCompanyIdentity: action.payload.data
      };
    case GET_LIST_USER_ADMIN:
      return {
        ...state,
        usersAdmin: action.payload.data
      };
    default:
      return state;
  }
};
