import React, { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import LineChart from 'components/admin/common/LineChart';
import { getStatisticalRegisterUsers, getStatisticalRevenue, getStatisticalDashBoard } from 'redux/admin/my_page';
import { useDispatch, useSelector } from 'react-redux';
import InfluencerRankingList from './components/InfluencerRankingList';
import CompanyRankingList from './components/CompanyRankingList';
import NpoRankingList from './components/NpoRankingList';
import YearFilter from './components/YearFilter';

function MyPage() {
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();
  const store = useSelector((state) => ({
    revenue: state.myPage.revenue,
    registerUsers: state.myPage.registerUsers,
    dataGeneral: state.myPage.dataGeneral
  }));

  useEffect(() => {
    initialPage();
  }, []);

  const initialPage = async () => {
    try {
      setIsLoading(true);
      const getRevenue = store.revenue.years.length === 0 ? dispatch(getStatisticalRevenue()) : null;
      const getRegisterUsers = store.registerUsers.years.length === 0 ? dispatch(getStatisticalRegisterUsers()) : null;
      const statisticalDashBoard = store.dataGeneral.count_project_public === null
        ? dispatch(getStatisticalDashBoard()) : null;
      await Promise.all([getRevenue, getRegisterUsers, statisticalDashBoard]);
      return true;
    } catch (error) {
      return error;
    } finally {
      setIsLoading(false);
    }
  };

  const dataRevenue = {
    title: '売上金額',
    labels: store.revenue?.labels || [],
    values: store.revenue?.values || [],
    borderColor: '#E55555',
    backgroundColor: '#FFFFFF'
  };

  const dataRegisterUsers = {
    title: 'ユーザー登録数',
    labels: store.registerUsers?.labels || [],
    values: store.registerUsers?.values || [],
    borderColor: '#54DBC2',
    backgroundColor: '#FFFFFF'
  };


  const _onSubmitFilterRevenue = async (form) => {
    if (isLoading) {
      return;
    }
    await dispatch(getStatisticalRevenue(form));
  };

  const _onSubmitFilterFormRegisterUser = async (form) => {
    if (isLoading) {
      return;
    }
    await dispatch(getStatisticalRegisterUsers(form));
  };

  return (
    <div>
      <div className="admin_my-page">
        <div className="section row mt-4">
          <div className="section_item col-xl-6 col-lg-6 col-md-12">
            <div className="border-bottom_dashboard d-flex justify-content-between align-items-center py-2">
              <h4 className="col mb-0">売上金額</h4>
              <div className="col-xl-4 col-lg-5 col-sm-4">
                <YearFilter
                  yearOptions={store.revenue?.years}
                  onSubmitFilterForm={_onSubmitFilterRevenue}
                />
              </div>
            </div>
            <div className="mt-3 chart-container">
              {isLoading && (
              <Skeleton height={300} />
              )}
              {!isLoading && dataRevenue && (
              <LineChart item={dataRevenue} />
              )}
            </div>
          </div>
          <div className="section_item col-xl-6 col-lg-6 col-md-12 mt-3 mt-lg-0">
            <div className="border-bottom_dashboard d-flex justify-content-between align-items-center py-2">
              <h4 className="col mb-0">ユーザー登録数</h4>
              <div className="col-xl-4 col-lg-5 col-sm-4">
                <YearFilter
                  yearOptions={store.registerUsers?.years}
                  onSubmitFilterForm={_onSubmitFilterFormRegisterUser}
                />
              </div>
            </div>
            <div className="mt-3 chart-container">
              {isLoading && (
              <Skeleton height={300} />
              )}
              {!isLoading && dataRegisterUsers && (
              <LineChart item={dataRegisterUsers} />
              )}
            </div>
          </div>
        </div>
        <div className="section row mt-lg-5 mt-0">
          <div className="col-lg-5 col-sm-12 mt-4 mt-lg-0">
            <h4>インフルエンサー報酬ランキング</h4>
            <InfluencerRankingList
              isLoading={isLoading}
              influencers={store.dataGeneral.reward_ranking_influencer}
            />
          </div>
          <div className="col-lg-7 col-sm-12 mt-4 mt-lg-0">
            <div className="row">
              <div className="section_item col-12 mb-lg-4">
                <h4 className="border-bottom_dashboard pb-2 mb-0">現在公開中のプロジェクト数</h4>
                {isLoading && (
                <Skeleton height={80} />
                )}
                {!isLoading && store.dataGeneral.count_project_public && (
                <div className="total_project">
                  <p className="total_project-number">
                    {store.dataGeneral.count_project_public ?? 0}件
                  </p>
                </div>
                )}
              </div>
              <div className="section_item col-xl-6 col-lg-7 col-sm-6 mt-4 mt-lg-0">
                <h4>ポイント購入ランキング</h4>
                <CompanyRankingList
                  isLoading={isLoading}
                  companies={store.dataGeneral.point_purchase_ranking_company}
                />
              </div>
              <div className="section_item col-xl-6 col-lg-5 col-sm-6  mt-4 mt-lg-0">
                <h4>寄付ランキング</h4>
                <NpoRankingList isLoading={isLoading} npoList={store.dataGeneral.donation_ranking} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MyPage;
