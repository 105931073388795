import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import * as ROUTES from 'constants/routes';
import { USER_TYPE_NPO, signIn } from 'redux/client/auth';
import { HTTP_OK } from 'constants/ResponseStatus';
import { Spinner } from 'react-bootstrap';
import { ResponseStatusMessage } from 'constants/ResponseStatusMessage';
import constants from 'constants/constants';

function CompleteSignUp() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLinkClicked, setIsLinkClicked] = useState(false);

  useEffect(() => {
    if (!state) {
      navigate(ROUTES.HOME);
    } else {
      // navigate(ROUTES.SIGN_UP_COMPLETE, { replace: true });
    }
  }, []);

  const handleLogin = async () => {
    if (!state.email || !state.password) {
      navigate(ROUTES.HOME);
    } else {
      setIsSubmitting(true);
      const requestData = {
        email: state.email,
        password: state.password
      };
      const formData = new FormData();
      Object.keys(requestData).forEach((key) => {
        formData.append(key, requestData[key]);
      });
      const resp = await dispatch(signIn(formData));
      if (resp && resp?.status !== HTTP_OK) {
        navigate(ROUTES.ERROR_PAGE, {
          replace: true,
          state: {
            error_code: resp?.status,
            error_msg: ResponseStatusMessage[resp?.status]
          }
        });
      }
      setIsSubmitting(false);
    }
  };

  const handleClickLink = () => {
    setIsLinkClicked(true);
  };

  return (
    <div className="auth-complete">
      {state?.userType === USER_TYPE_NPO ? (
        <div className="container text-center">
          <div className="align-center-wrap">
            <div className="position-wrap">
              <h3 className="title-section mb-5">本会員登録が完了しました</h3>
              <p className="title-section-sub mb-5">
                ご登録ありがとうございます。<br />
                ENMAを活用して、効果的なインフルエンサーマーケティングを実現しましょう。
              </p>

              <div className="text-center mb-5">
                <button
                  type="button"
                  className="common-btn btn btn-auth"
                  onClick={() => handleLogin()}
                >
                  {isSubmitting && (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                      className="me-2"
                    />
                  )}
                  <span className="sr-only">トップページへ</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="signup-complete-page">
          <div className="client-container initial-auth__page">
            <div className="auth__card box-style-1__border registration-compelete-email-card p-3 p-md-5">
              <h3>
                <strong>ご登録ありがとうございます。</strong>
              </h3>
              <div className="wrap-text">最後にENMAの公式LINEの追加をお願いします。</div>
              <p>プロジェクトのお知らせ、ご相談窓口、各種キャンペーン情報の配信を行います。</p>
              <div className="text-center py-3">
                <a
                  href={constants.LINE_LINK}
                  target="_blank"
                  rel="noreferrer"
                  className="d-inline-block btn common-btn btn-auth btn-line"
                  onClick={() => handleClickLink()}
                >
                  公式LINEを追加する
                </a>
              </div>
              <div className="wrap-text pt-3">※すでに公式LINEを追加済みの場合は追加ボタンを押した後に前の画面にお戻りください</div>
              <div className="p-3">
                <h3>
                  <strong>公式LINEを追加したら</strong>
                </h3>
                <p className="mb-4">以下フォーマットにてメッセージの送信をお願いします。</p>
                <p className="mb-3">①TikTokのアカウント名or登録メールアドレス<br />※企業ユーザーは会社名・団体名</p>
                <p className="mb-3">②当サービスを知ったきっかけ</p>
                <p className="mb-3"><small>送信後、お手続きは完了です。</small></p>
              </div>
              <div className="text-center">
                <button
                  type="button"
                  className="common-btn btn btn-auth"
                  onClick={() => handleLogin()}
                  disabled={!isLinkClicked}
                >
                  {isSubmitting && (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                      className="me-2"
                    />
                  )}
                  <span className="sr-only">ENMAにアクセス</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default CompleteSignUp;
