// SOURCE List of HTTP status codes: https://ja.wikipedia.org/wiki/HTTPステータスコード#5xx_Server_Error_サーバエラー
export const ResponseStatusMessage = {
  100: '継続', // HTTP_CONTINUE = 100
  101: 'プロトコル切替', // HTTP_SWITCHING_PROTOCOLS = 101
  102: '処理中', // HTTP_PROCESSING = 102
  103: '早期のヒント', // HTTP_EARLY_HINTS = 103
  200: 'OK', // HTTP_OK = 200
  201: '作成', // HTTP_CREATED = 201
  202: '受理', // HTTP_ACCEPTED = 202
  203: '信頼できない情報', // HTTP_NON_AUTHORITATIVE_INFORMATION = 203
  204: '内容なし', // HTTP_NO_CONTENT = 204
  205: '内容のリセット', // HTTP_RESET_CONTENT = 205
  206: '部分的内容', // HTTP_PARTIAL_CONTENT = 206
  207: '複数のステータス', // HTTP_MULTI_STATUS = 207
  208: '既に報告', // HTTP_ALREADY_REPORTED = 208
  226: 'IM使用', // HTTP_IM_USED = 226
  300: '複数の選択', // HTTP_MULTIPLE_CHOICES = 300
  301: '恒久的に移動した', // HTTP_MOVED_PERMANENTLY = 301
  302: '発見した', // HTTP_FOUND = 302
  303: '他を参照せよ', // HTTP_SEE_OTHER = 303
  304: '未更新', // HTTP_NOT_MODIFIED = 304
  305: 'プロキシを使用せよ', // HTTP_USE_PROXY = 305
  306: '将来のために予約されている', // HTTP_RESERVED = 306
  307: '一時的リダイレクト', // HTTP_TEMPORARY_REDIRECT = 307
  308: '恒久的リダイレクト', // HTTP_PERMANENTLY_REDIRECT = 308
  400: 'リクエストが不正である', // HTTP_BAD_REQUEST = 400
  401: '認証エラーです', // HTTP_UNAUTHORIZED = 401
  402: '支払いが必要である', // HTTP_PAYMENT_REQUIRED = 402
  403: 'このページへのアクセスは、禁止されています', // HTTP_FORBIDDEN = 403
  404: '未検出', // HTTP_NOT_FOUND = 404
  405: '許可されていないメソッド', // HTTP_METHOD_NOT_ALLOWED = 405
  406: '受理できない', // HTTP_NOT_ACCEPTABLE = 406
  407: 'プロキシ認証が必要である', // HTTP_PROXY_AUTHENTICATION_REQUIRED = 407
  408: 'リクエストタイムアウト', // HTTP_REQUEST_TIMEOUT = 408
  409: '競合', // HTTP_CONFLICT = 409
  410: '消滅した', // HTTP_GONE = 410
  411: '長さが必要', // HTTP_LENGTH_REQUIRED = 411
  412: '前提条件で失敗した', // HTTP_PRECONDITION_FAILED = 412
  413: 'ペイロードが大きすぎる', // HTTP_REQUEST_ENTITY_TOO_LARGE = 413
  414: 'URIが大きすぎる', // HTTP_REQUEST_URI_TOO_LONG = 414
  415: 'サポートしていないメディアタイプ', // HTTP_UNSUPPORTED_MEDIA_TYPE = 415
  416: 'レンジは範囲外にある', // HTTP_REQUESTED_RANGE_NOT_SATISFIABLE = 416
  417: 'Expectヘッダによる拡張が失敗', // HTTP_EXPECTATION_FAILED = 417
  418: '私はティーポット', // HTTP_I_AM_A_TEAPOT = 418
  421: '誤ったリクエスト', // HTTP_MISDIRECTED_REQUEST = 421
  422: '処理できない内容', // HTTP_UNPROCESSABLE_ENTITY = 422
  423: 'ロックされている', // HTTP_LOCKED = 423
  424: '依存関係で失敗', // HTTP_FAILED_DEPENDENCY = 424
  425: 'Early dataを受け入れない', // HTTP_TOO_EARLY = 425
  426: 'アップグレード要求', // HTTP_UPGRADE_REQUIRED = 426
  428: '事前条件が必要', // HTTP_PRECONDITION_REQUIRED = 428
  429: '要求が多すぎる', // HTTP_TOO_MANY_REQUESTS = 429
  431: 'リクエストヘッダーフィールドが大きすぎる', // HTTP_REQUEST_HEADER_FIELDS_TOO_LARGE = 431
  451: '法的理由により利用不可', // HTTP_UNAVAILABLE_FOR_LEGAL_REASONS = 451
  500: 'サーバ内部エラー', // HTTP_INTERNAL_SERVER_ERROR = 500
  501: '実装されていない', // HTTP_NOT_IMPLEMENTED = 501
  502: '不正なゲートウェイ', // HTTP_BAD_GATEWAY = 502
  503: 'サービス利用不可', // HTTP_SERVICE_UNAVAILABLE = 503
  504: 'ゲートウェイタイムアウト', // HTTP_GATEWAY_TIMEOUT = 504
  505: 'サポートしていないHTTPバージョン', // HTTP_VERSION_NOT_SUPPORTED = 505
  506: 'Variant Also Negotiates', // HTTP_VARIANT_ALSO_NEGOTIATES_EXPERIMENTAL = 506
  507: '容量不足', // HTTP_INSUFFICIENT_STORAGE = 507
  508: 'ループを検出', // HTTP_LOOP_DETECTED = 508
  510: '拡張できない', // HTTP_NOT_EXTENDED = 510
  511: 'ネットワークに対する認証が必要' // HTTP_NETWORK_AUTHENTICATION_REQUIRED = 511
};
