import React from 'react';
import PropTypes from 'prop-types';

function CompanyCancellationRow({ itemRow, handleConfirmRequestClick }) {
  return (
    <tr
      onClick={handleConfirmRequestClick}
      className="cursor-pointer"
    >
      <td>{itemRow.project_title}</td>
      <td>{itemRow.provider_name}</td>
      <td>{itemRow.influencer_full_name}</td>
      <td>{itemRow.cancellation_at}</td>
      <td>{itemRow.talk_room_status_value}</td>
    </tr>
  );
}

CompanyCancellationRow.propTypes = {
  itemRow: PropTypes.object.isRequired,
  handleConfirmRequestClick: PropTypes.func.isRequired
};
export default CompanyCancellationRow;
