export const newsTypeData = [
  {
    key: 0,
    value: 'インフルエンサーユーザーのみ'
  },
  {
    key: 1,
    value: '企業ユーザーのみ'
  },
  {
    key: 2,
    value: 'OTASUKEユーザーのみ'
  },
  {
    key: 3,
    value: '会員登録済みの全ユーザー（インフルエンサー/企業/OTASUKE）'
  },
  {
    key: 4,
    value: '非会員を含む全ユーザー'
  }
];
