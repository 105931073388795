import React from 'react';
import PropTypes from 'prop-types';

import { FastField, Field, Form, Formik } from 'formik';
import { Search } from 'react-bootstrap-icons';

function NPOSearchBar({ isLoading, initFormikValues, onSubmitFilterForm, NPOCategoryData }) {
  return (
    <Formik initialValues={initFormikValues} onSubmit={onSubmitFilterForm} enableReinitialize>
      {({ handleChange, handleSubmit }) => (
        <Form className="row mb-3 flex-column flex-md-row admin-form-custom-1 npo-search-bar-component">
          <div className="col-lg-4 col-xxl-3 col-12">
            <label htmlFor="npo_category_id" className="form-label">
              OTASUKEカテゴリ
            </label>
            <Field
              as="select"
              name="npo_category_id"
              id="npo_category_id"
              className="form-select rounded-0"
              required
              disabled={isLoading}
              onChange={(e) => {
                handleChange(e);
                handleSubmit();
              }}
            >
              <option value="">選択してください</option>
              {NPOCategoryData.map((NPOCategory) => (
                <option key={NPOCategory.key} value={NPOCategory.key}>
                  {NPOCategory.value}
                </option>
              ))}
            </Field>
          </div>
          <div className="col-lg-6 col-xxl-5 col-12 keyword-search-group">
            <label htmlFor="keyword" className="form-label">
              キーワード検索
            </label>
            <div className="input-group">
              <FastField
                name="keyword"
                id="keyword"
                type="text"
                className="border-right-none form-control rounded-0"
              />
              <button
                type="submit"
                className="input-group-text rounded-0 bg-transparent search-admin-inner-icon"
                onClick={handleSubmit}
              >
                <Search />
              </button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}

NPOSearchBar.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  initFormikValues: PropTypes.object.isRequired,
  onSubmitFilterForm: PropTypes.func.isRequired,
  NPOCategoryData: PropTypes.array.isRequired
};

export default NPOSearchBar;
