import React from 'react';
import PropTypes from 'prop-types';

function TransferHistoryRow({ itemRow }) {
  return (
    <tr>
      <td>{itemRow.id}</td>
      <td>{itemRow.user_influencer_full_name || itemRow.user_npo_name}</td>
      <td>{itemRow.user_email}</td>
      <td>{itemRow.transfer_amount}</td>
      <td>{itemRow.applicated_at}</td>
    </tr>
  );
}

TransferHistoryRow.propTypes = {
  itemRow: PropTypes.object.isRequired
};

export default TransferHistoryRow;
