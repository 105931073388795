import React from 'react';
import { FastField, Field } from 'formik';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import * as ja from 'helpers/yup-locale-ja';
import { subDays } from 'date-fns';

import {
  CustomDatePicker,
  CustomInput,
  CustomSelectMultiplePicker,
  CustomTextArea,
  RadioGroup
} from 'components/formik';
import { genderData } from 'data/genderData';

Yup.setLocale(ja.suggestive);
function UpdateInfluencerForm({ tagData, prefectureData }) {
  return (
    <>
      <div className="form-group-item row">
        <div className="col-lg-6 col-md-6 col-sm-6 col-12 mb-mobile-form">
          <FastField name="family_name" label={['姓', '必須']} placeholder="入力してください" component={CustomInput} />
        </div>
        <div className="col-lg-6 col-md-6 col-sm-6 col-12">
          <FastField name="first_name" label={['名', '必須']} placeholder="入力してください" component={CustomInput} />
        </div>
      </div>

      <div className="form-group-item row">
        <div className="col-lg-6 col-md-6 col-sm-6 col-12 mb-mobile-form">
          <FastField
            name="family_name_kana"
            label={['姓（フリガナ）', '必須']}
            placeholder="入力してください"
            component={CustomInput}
          />
        </div>
        <div className="col-lg-6 col-md-6 col-sm-6 col-12">
          <FastField
            name="first_name_kana"
            label={['名（フリガナ）', '必須']}
            placeholder="入力してください"
            component={CustomInput}
          />
        </div>
      </div>

      <div className="form-group-item row">
        <div className="col-12">
          <FastField name="phone" label={['電話番号', '必須']} placeholder="入力してください" component={CustomInput} />
        </div>
      </div>

      <div className="form-group-item row">
        <div className="col-lg-6 col-md-6 col-sm-6 col-12">
          <FastField
            name="birthday"
            label={['生年月日', '必須']}
            placeholderText="日付を選択してください"
            minDate={new Date(1920, 1, 1)}
            maxDate={subDays(new Date(), 1)}
            dateFormat="yyyy/MM/dd"
            component={CustomDatePicker}
          />
        </div>
      </div>
      <div className="form-group-item row bm-26-lg">
        <div className="col-12">
          <RadioGroup name="gender" label={['性別']} options={genderData} />
        </div>
      </div>
      <div className="form-group-item row">
        <div className="col-lg-6 col-md-6 col-sm-6 col-12">
          <Field
            name="prefecture_ids"
            label={['活動可能地域', '必須']}
            placeholder="選択してください"
            selectData={prefectureData}
            component={CustomSelectMultiplePicker}
          />
        </div>
      </div>
      <div className="form-group-item row">
        <div className="col-lg-6 col-md-6 col-sm-6 col-12">
          <Field
            name="tag_ids"
            label={['タグ', '必須']}
            placeholder="選択して下さい"
            selectData={tagData}
            component={CustomSelectMultiplePicker}
          />
        </div>
      </div>

      <div className="form-group-item row">
        <div className="col-12">
          <FastField name="pr" label={['PR欄']} placeholder="入力してください" component={CustomTextArea} maxLength={1000} />
        </div>
      </div>
    </>
  );
}

UpdateInfluencerForm.propTypes = {
  tagData: PropTypes.array.isRequired,
  prefectureData: PropTypes.array.isRequired
};

export default UpdateInfluencerForm;
