import React from 'react';
import PropTypes from 'prop-types';
import { GeoAlt } from 'react-bootstrap-icons';
import BoxArrowUpRight from 'assets/external-link.png';
import GlobeAsia from 'assets/svg/globe-asia.svg';

function NPOItem({ npo }) {
  const LEAVE_IT_TO_MANAGEMENT = 18;
  const titlePaddingBottom = npo.npo_category_id == LEAVE_IT_TO_MANAGEMENT ? '3' : '4';

  return (
    <div className="col-6 mb-4" key={`npo_${npo.id}`}>
      <div className="card-galaxy h-100">
        <div className="card-galaxy-body h-100">
          <div className="row">
            <div className="col-lg-12">
              <h2
                className={`item-title text-truncate pb-2 pb-sm-${titlePaddingBottom}`}
              >
                <i className={`icon-svg npo-category-icon-${npo.npo_category_id} me-1 me-sm-3`} />
                {npo.npo_category_name}
              </h2>
            </div>
          </div>
          {npo.npo_category_id == LEAVE_IT_TO_MANAGEMENT ? (
            <div className="row">
              <div className="leave_management">
                「運営にお任せ」は、寄付先の選択をENMA運営チームにおまかせいただくものです。
                ENMAは多くの分野や社会的課題に関わる優れたNPO団体と連携し、企業の寄付がより大きな社会的効果を生むように努めています。<br />
                特定のカテゴリにこだわらず、幅広い社会課題への支援を希望される場合には、「運営にお任せ」いただくことをおすすめします。
              </div>
            </div>
          ) : (
            <div className="row">
              <p className="item-sub-title">{npo.npo_name}</p>
              <div>
                {npo.prefecture_name && (
                  <span className="item-share">
                    <GeoAlt height={22} width={22} className="amazing-icon" />
                    {npo.prefecture_name}
                  </span>
                )}
                {npo.url && (
                  <span className="item-share mt-2 mt-md-0">
                    <img height={22} width={22} src={GlobeAsia} className="amazing-icon" alt="" />
                    {npo.url}
                    <a href={npo.url} target="_blank" rel="noreferrer">
                      <img className="external-icon-custom" src={BoxArrowUpRight} alt="" />
                    </a>
                  </span>
                )}
              </div>
              {npo.pr && (
                <div className="col-12 mt-4 item-pr">{npo.pr}</div>
              )}
            </div>
          )}

        </div>
      </div>
    </div>
  );
}


NPOItem.propTypes = {
  npo: PropTypes.object.isRequired
};

export default NPOItem;
