import React from 'react';
import PropTypes from 'prop-types';

function UserInfluencerDetailRow({ itemRow }) {
  return (
    <tr>
      <td>{itemRow.project_id}</td>
      <td>{itemRow.project_title}</td>
      <td>{itemRow.type_name}</td>
      <td>{itemRow.company_or_npo_name}</td>
      <td>{itemRow.status}</td>
    </tr>
  );
}

UserInfluencerDetailRow.propTypes = {
  itemRow: PropTypes.object.isRequired
};
export default UserInfluencerDetailRow;
