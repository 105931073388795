import API from 'axios/API';

export const GET_ALL_PRODUCT_SUCCESS = 'GET_ALL_PRODUCT_SUCCESS';
export const GET_SPECIFIC_PRODUCT = 'GET_SPECIFIC_PRODUCT';

export const getAllProduct = () => (dispatch) => {
  return API({
    method: 'GET',
    url: 'product'
  })
    .then((res) => {
      dispatch({
        type: GET_ALL_PRODUCT_SUCCESS,
        payload: res
      });
    });
};

export const getProductById = (id) => (dispatch) => {
  return API({
    method: 'GET',
    url: `product/${id}`
  })
    .then((res) => {
      dispatch({
        type: GET_SPECIFIC_PRODUCT,
        payload: res
      });
    });
};
