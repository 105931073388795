import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ErrorMessage } from 'formik';
import { Form } from 'react-bootstrap';
import { X } from 'react-bootstrap-icons';

function CustomInputHashtag({ field, form, form: { touched, errors }, label, ...props }) {
  const [input, setInput] = useState('');
  const [tags, setTags] = useState([]);
  const [isKeyReleased, setIsKeyReleased] = useState(false);

  useEffect(() => {
    setTags(field.value);
  }, [field.value]);

  const onChange = (e) => {
    const { value } = e.target;
    setInput(value);
  };

  const onKeyUp = () => {
    setIsKeyReleased(true);
  };

  const onBlue = (e) => {
    let trimmedInput = input.trim();
    if (trimmedInput.length && !tags.includes(`#${trimmedInput}`)) {
      e.preventDefault();
      trimmedInput = trimmedInput.indexOf('#') !== -1 ? trimmedInput : `#${trimmedInput}`;

      setTags((prevState) => [...prevState, trimmedInput]);
      form.setFieldValue(field.name, [...tags, trimmedInput]);
    }

    setInput('');
  };

  const onKeyDown = (e) => {
    const { key } = e;
    let trimmedInput = input.trim();

    if (key === 'Enter' && trimmedInput.length && !tags.includes(`#${trimmedInput}`)) {
      e.preventDefault();
      setTimeout(() => {
        trimmedInput = trimmedInput.indexOf('#') !== -1 ? trimmedInput : `#${trimmedInput}`;

        setTags((prevState) => [...prevState, trimmedInput]);
        form.setFieldValue(field.name, [...tags, trimmedInput]);
        setInput('');
      }, 0);
    }

    if (key === 'Backspace' && !input.length && tags.length && isKeyReleased) {
      const tagsCopy = [...tags];
      const poppedTag = tagsCopy.pop();
      if (poppedTag != '#PR' && poppedTag != '#ENMAPR') {
        e.preventDefault();
        setTags(tagsCopy);
        setInput(poppedTag);
      }
    }

    setIsKeyReleased(false);
  };

  const deleteTag = (index) => {
    setTags((prevState) => prevState.filter((tag, i) => i !== index));
    form.setFieldValue(field.name, tags.filter((tag, i) => i !== index));
  };
  return (
    <div>
      {label && (
        <Form.Label htmlFor={field.name}>
          {label[0]}
          {label[1] && <span className="label-required">{label[1]}</span>}
        </Form.Label>
      )}
      <div
        className={touched[field.name] && errors[field.name] ? 'hashtag__container is-invalid' : 'hashtag__container'}
      >
        {tags.map((tag, index) => (
          <div key={`${tag}`} className="hashtag-item">
            {tag}
            {(!tag.includes('#PR') && !tag.includes('#ENMAPR')) && (
              <button type="button" onClick={() => deleteTag(index)} className="btn hashtag__btn-remove">
                <X />
              </button>
            )}
          </div>
        ))}
        <Form.Control
          type="text"
          name={field.name}
          value={input}
          onKeyDown={onKeyDown}
          onChange={onChange}
          onBlur={onBlue}
          onKeyUp={onKeyUp}
          className="hashtag__input"
          isInvalid={touched[field.name] && errors[field.name]}
          {...props}
        />
      </div>

      <ErrorMessage name={field.name} component="div" className="invalid-feedback" />
    </div>
  );
}

CustomInputHashtag.propTypes = {
  label: PropTypes.array.isRequired,
  field: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired
};

export default CustomInputHashtag;
