import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import { useDispatch, useSelector } from 'react-redux';
import { exportUserNPO, getUserNPOPaginate } from 'redux/admin/user';

import { ERROR_404_PAGE } from 'constants/routes';
import Pagination from 'components/admin/Pagination/Pagination';
import { getAllCategory, getAllNPOCategory } from 'redux/client/category_global';
import ToastMessage from 'components/admin/common/ToastMessage';
import CustomBottomToRightToast from 'components/admin/common/CustomBottomToRightToast';
import UserNPORow from './components/UserNPORow';
import UserNPOSearchBar from './components/UserNPOSearchBar';
import NewsAddModal from '../NewsAddModal';
import PointAddModal from '../PointAddModal';
import CantDeleteUserModal from '../CantDeleteUserModal';
import DeleteUserModal from '../DeleteUserModal';


function UserNPOList() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingExportCSV, setIsLoadingExportCSV] = useState(false);
  const [remountComponent, setRemountComponent] = useState(0);
  const [requestParamsFilter, setRequestParamsFilter] = useState([]);
  const [isAddNewsModalShow, setIsAddNewsModalShow] = useState(false);
  const [isAddPointModalShow, setIsAddPointModalShow] = useState(false);
  const [isDeleteUserModalShow, setIsDeleteUserModalShow] = useState(false);
  const [isCantDeleteUserModalShow, setIsCantDeleteUserModalShow] = useState(false);
  const [userId, setUserId] = useState(null);
  const [showToastError, setShowToastError] = useState(false);
  const [showToastSuccess, setShowToastSuccess] = useState(false);
  const dispatch = useDispatch();
  const store = useSelector((state) => ({
    categories: state.categoryGlobal.categories,
    NPOCategories: state.categoryGlobal.NPOCategories,
    NPOsCompany: state.userAdminPanel.NPOsCompany
  }));

  useEffect(() => {
    initialPage();
    _getUserNPOPaginate();
  }, []);

  const initialPage = async () => {
    try {
      const getAllCategoryPromise = store.categories.length === 0 ? dispatch(getAllCategory()) : null;
      const getAllNPOCategoryPromise = store.NPOCategories.length === 0 ? dispatch(getAllNPOCategory()) : null;
      await Promise.all([getAllCategoryPromise, getAllNPOCategoryPromise]);

      return true;
    } catch (error) {
      navigate(ERROR_404_PAGE, {
        replace: true
      });
      return error;
    }
  };

  const _getUserNPOPaginate = async (page = 1, requestParams = []) => {
    setIsLoading(true);
    try {
      await dispatch(getUserNPOPaginate(page, requestParams));

      setIsLoading(false);
      return true;
    } catch (error) {
      setIsLoading(false);
      return error;
    }
  };

  const _exportUserNPO = async () => {
    setIsLoadingExportCSV(true);
    await dispatch(exportUserNPO(requestParamsFilter)).then((res) => {
      const bom = new Uint8Array([0xEF, 0xBB, 0xBF]);
      const url = URL.createObjectURL(new Blob([bom, res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `npo-users-${moment().format('YYYYMMDDHms')}.csv`);
      document.body.appendChild(link);
      link.click();
    });

    setIsLoadingExportCSV(false);
  };

  const _handlePageClick = (event) => {
    _getUserNPOPaginate(event.selected + 1);
  };

  const _onSubmitFilterForm = (form) => {
    if (isLoading) {
      return;
    }

    setRemountComponent(Math.random());
    setRequestParamsFilter(form);
    _getUserNPOPaginate(1, form);
  };

  const _onAddNewsClickHandle = (currentUserId) => {
    setIsAddNewsModalShow(true);
    setUserId(currentUserId);
  };

  const _onAddPointClickHandle = (currentUserId) => {
    setIsAddPointModalShow(true);
    setUserId(currentUserId);
  };

  const _onDeleteUser = (NPOsCompany) => {
    if (NPOsCompany?.can_delete_user) {
      setIsDeleteUserModalShow(true);
      setUserId(NPOsCompany.user_id);
    } else {
      setIsCantDeleteUserModalShow(true);
    }
  };

  return (
    <div className="user-list-inner">
      <NewsAddModal
        isAddNewsModalShow={isAddNewsModalShow}
        setIsAddNewsModalShow={setIsAddNewsModalShow}
        userId={userId}
      />
      <PointAddModal
        isAddPointModalShow={isAddPointModalShow}
        setIsAddPointModalShow={setIsAddPointModalShow}
        userId={userId}
      />
      <DeleteUserModal
        isDeleteUserModalShow={isDeleteUserModalShow}
        setIsDeleteUserModalShow={setIsDeleteUserModalShow}
        userId={userId}
        setShowToastError={setShowToastError}
        setShowToastSuccess={setShowToastSuccess}
        getUserPaginate={_getUserNPOPaginate}
      />
      <CantDeleteUserModal
        isCantDeleteUserModalShow={isCantDeleteUserModalShow}
        setIsCantDeleteUserModalShow={setIsCantDeleteUserModalShow}
      />
      <UserNPOSearchBar
        categoryData={store.categories}
        NPOCategoryData={store.NPOCategories}
        onSubmitFilterForm={_onSubmitFilterForm}
      />
      <div className="mb-4 text-end">
        <button
          type="button"
          className="btn btn-outline-primary rounded-pill admin-btn-green mt-0"
          onClick={_exportUserNPO}
          disabled={isLoading || !store.NPOsCompany?.total || isLoadingExportCSV}
        >
          {isLoadingExportCSV && (
            <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className="me-2" />
          )}
          <strong>CSVダウンロード</strong>
        </button>
      </div>

      <div className="table-responsive">
        <table className="table table-lg table-hover admin-table-custom-1">
          {!isLoading && !store.NPOsCompany?.total && (
            <caption className="text-center py-5 h5">見つかりません。</caption>
          )}
          <thead className="border-top border-2">
            <tr>
              <th width="10%">ID</th>
              <th width="30%">団体名</th>
              <th width="20%">担当者名</th>
              <th width="20%">OTASUKEカテゴリ</th>
              <th width="20%">ジャンル</th>
              <th width="10%">&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            {isLoading && [...Array(20)].map((e, i) => (
              <tr key={`${i.toString()}`}>
                <td>
                  <Skeleton height={17} />
                </td>
                <td>
                  <Skeleton height={17} />
                </td>
                <td>
                  <Skeleton height={17} />
                </td>
                <td>
                  <Skeleton height={17} />
                </td>
                <td>
                  <Skeleton height={17} />
                </td>
                <td>
                  <Skeleton height={17} />
                </td>
              </tr>
            ))}

            {(!isLoading && store.NPOsCompany?.data)
              && store.NPOsCompany.data.map((item) => (
                <UserNPORow
                  key={item.user_id}
                  itemRow={item}
                  onAddNewsClick={() => _onAddNewsClickHandle(item.user_id)}
                  onAddPointClick={() => _onAddPointClickHandle(item.user_id)}
                  onDeleteUser={() => _onDeleteUser(item)}
                />
              ))}
          </tbody>
        </table>
      </div>

      {store.NPOsCompany?.last_page > 1 && (
        <div className="d-flex justify-content-center my-5">
          <Pagination
            className="pagination admin-pagination-custom-1"
            key={remountComponent}
            pageCount={store.NPOsCompany?.last_page}
            onPageChange={_handlePageClick}
          />
        </div>
      )}
      <ToastMessage
        isShow={showToastError}
        onClose={() => {
          setShowToastError(false);
        }}
      />
      <CustomBottomToRightToast msg="更新されました" showToast={showToastSuccess} setShowToast={setShowToastSuccess} />
    </div>
  );
}

export default UserNPOList;
