/* eslint-disable */
import React from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from 'redux/store';
import { Helmet } from 'react-helmet';
import AppRouter from './routes/AppRouter';

function App() {
  const getAppEnvScript = () => {
    if (process.env.REACT_APP_ENV === 'production') {
      return `
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-5P3ZWZ2');
      `;
    } else if (process.env.REACT_APP_ENV === 'staging') {
      return `
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl+ '&gtm_auth=nlNUuqal_222C_R7TALH8w&gtm_preview=env-4&gtm_cookies_win=x';f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-5P3ZWZ2');
      `;
    } else if (process.env.REACT_APP_ENV === 'development') {
      return `
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl+ '&gtm_auth=se-EHE-JIkDAGD6gdhkM7Q&gtm_preview=env-3&gtm_cookies_win=x';f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-5P3ZWZ2');
      `;
    } else {
      return '';
    }
  };

  const getAppEnvBodyScript = () => {
    if (process.env.REACT_APP_ENV === 'production') {
      return `
        <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-5P3ZWZ2"
        height="0" width="0" style="display:none;visibility:hidden"></iframe>
      `;
    } else if (process.env.REACT_APP_ENV === 'staging') {
      return `
        <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-5P3ZWZ2&gtm_auth=nlNUuqal_222C_R7TALH8w&gtm_preview=env-4&gtm_cookies_win=x"
        height="0" width="0" style="display:none;visibility:hidden"></iframe>
      `;
    } else if (process.env.REACT_APP_ENV === 'development') {
      return `
        <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-5P3ZWZ2&gtm_auth=se-EHE-JIkDAGD6gdhkM7Q&gtm_preview=env-3&gtm_cookies_win=x"
        height="0" width="0" style="display:none;visibility:hidden"></iframe>
      `;
    } else {
      return '';
    }
  };

  const setNoIndex = () => {
    if (process.env.REACT_APP_ENV !== 'production') {
      return <meta name="robots" content="noindex, nofollow" />;
    }
  }

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Helmet>
          {setNoIndex()}
          <script>{getAppEnvScript()}</script>
        </Helmet>
        <AppRouter />
        <noscript>{getAppEnvBodyScript()}</noscript>
      </PersistGate>
    </Provider>
  );
}

export default App;
