import React from 'react';
import Toast from 'react-bootstrap/Toast';
import PropTypes from 'prop-types';

function ToastMessage({ type, title, message, isShow, onClose }) {
  return (
    <div className="position-fixed bottom-0 end-0 p-3 admin-toast">
      <Toast className={type} onClose={onClose} show={isShow} delay={5000} autohide>
        <Toast.Header>
          <strong className="me-auto">{title}</strong>
        </Toast.Header>
        <Toast.Body className="toast-body">{message}</Toast.Body>
      </Toast>
    </div>
  );
}
ToastMessage.defaultProps = {
  type: 'error',
  title: 'Error',
  message: 'Internal Server Error'
};
ToastMessage.propTypes = {
  type: PropTypes.string,
  title: PropTypes.string,
  message: PropTypes.string,
  isShow: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
};
export default ToastMessage;
