import * as Yup from 'yup';
import * as ja from 'helpers/yup-locale-ja';

Yup.setLocale(ja.suggestive);
export const InvitationFormValidationCompany = Yup.object().shape({
  invitation_settings: Yup.array().of(
    Yup.object().shape({
      company_points: Yup.number()
        .required()
        .min(0)
        .max(9999999)
    })
  )
});

export const InvitationFormValidationInfluencer = Yup.object().shape({
  invitation_settings: Yup.array().of(
    Yup.object().shape({
      influencer_points: Yup
        .number()
        .required()
        .min(0)
        .max(9999999)
    })
  )
});
