import React, { useState } from 'react';
import { FastField, Form, FormikProvider, useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Button, Spinner } from 'react-bootstrap';

import { CustomInputPassword } from 'components/formik';
import { doChangePassword } from 'redux/client/user';
import * as ROUTES from 'constants/routes';
import { ResponseStatus } from 'constants';
import { ResponseStatusMessage } from 'constants/ResponseStatusMessage';
import { setFormikErrors } from 'helpers/utils';
import CheckIcon from 'assets/svg/check-ic.svg';
import { ChangePasswordValidation } from './ChangePasswordValidation';


function ChangePassword() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isSuccess, setIsSuccess] = useState(false);

  const formik = useFormik({
    initialValues: {
      old_password: '',
      password: '',
      password_confirmation: ''
    },
    validationSchema: ChangePasswordValidation,
    onSubmit: (form, formikHelpers) => onFormSubmit(form, formikHelpers)
  });
  const onFormSubmit = async (form, formikHelpers) => {
    const resp = await dispatch(doChangePassword(form));
    if (resp && resp.status == ResponseStatus.HTTP_UNPROCESSABLE_ENTITY) {
      setFormikErrors(resp.data.errors, formikHelpers.setFieldError);
      return;
    }
    // If check validate not OK
    if (resp.status != ResponseStatus.HTTP_OK) {
      navigate(ROUTES.ERROR_PAGE, {
        replace: true,
        state: {
          error_code: resp.status,
          error_msg: ResponseStatusMessage[resp.status]
        }
      });
    }
    setIsSuccess(true);
    formik.resetForm();
  };
  return (
    <div className="container main-container">
      <div className="row justify-content-center">
        <div className="col-lg-10 col-11 form__wrapper">
          <FormikProvider value={formik}>
            <Form className="form-style-1">
              <h3 className="title-form">パスワード変更</h3>
              {isSuccess && (
                <div className="form-group-item row">
                  <div
                    role="alert"
                    className="col-lg-6 col-md-6 col-sm-6 col-12 fade alert alert-dismissible show amazing-alert"
                  >
                    <div className="amazing-alert-wrap d-flex align-items-center">
                      <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"> </button>
                      <div className="alert-icon-success">
                        <img src={CheckIcon} alt="CheckIcon" />
                      </div>
                      <div className="alert-body">
                        <div className="alert-content">パスワードの変更が完了しました。</div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div className="form-group-item row">
                <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                  <label htmlFor="old_password" className="form-label">
                    現在のパスワード
                    <span className="label-required">必須</span>
                  </label>
                  <FastField
                    id="old_password"
                    name="old_password"
                    placeholder="現在のパスワード"
                    component={CustomInputPassword}
                  />
                </div>
              </div>
              <div className="form-group-item row">
                <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                  <label htmlFor="password" className="form-label">
                    新しいパスワード
                    <span className="label-required">必須</span>
                  </label>
                  <FastField
                    id="password"
                    name="password"
                    placeholder="新しいパスワード"
                    component={CustomInputPassword}
                  />
                </div>
              </div>
              <div className="form-group-item row">
                <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                  <label htmlFor="password_confirmation" className="form-label">
                    新しいパスワード（確認）
                    <span className="label-required">必須</span>
                  </label>
                  <FastField
                    id="password_confirmation"
                    name="password_confirmation"
                    placeholder="新しいパスワード（確認）"
                    component={CustomInputPassword}
                  />
                </div>
              </div>
              <div className="col-12 text-center mt-btn-form">
                <Button
                  variant="auth common-btn"
                  onClick={formik.submitForm}
                  disabled={!(formik.isValid && formik.dirty) || formik.isSubmitting}
                >
                  {formik.isSubmitting && (
                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className="me-2" />
                  )}
                  <span className="sr-only">登録</span>
                </Button>
              </div>
            </Form>
          </FormikProvider>
        </div>
      </div>
    </div>
  );
}

export default ChangePassword;
