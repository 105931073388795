import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import * as ROUTES from 'constants/routes';
import Skeleton from 'react-loading-skeleton';
import useSession from 'hooks/useSession';
import { toMemberPage } from 'helpers/utils';
import { getMyPurchases } from 'redux/client/purchase_points';
import { myPointsCompany } from 'redux/client/user';
import { ResponseStatusMessage } from 'constants/ResponseStatusMessage';
import Pagination from 'components/client/Pagination/Pagination';

function MyPurchase() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSession();
  const [isLoading, setIsLoading] = useState(true);

  const store = useSelector((state) => ({
    myPurchases: state.purchasePoints.myPurchases,
    currentMyPointsCompany: state.user.currentMyPointsCompany
  }));

  const _getMyPurchases = async (page = 1) => {
    setIsLoading(true);
    try {
      await dispatch(getMyPurchases(page));
      setIsLoading(false);
      return true;
    } catch (error) {
      setIsLoading(false);
      return error;
    }
  };

  const onPagingClick = (event) => {
    _getMyPurchases(event.selected + 1);
  };

  const initialPage = async () => {
    setIsLoading(true);
    try {
      const myPointCompanyPromise = dispatch(myPointsCompany());
      const getAllProductPromise = dispatch(getMyPurchases());
      await Promise.all([myPointCompanyPromise, getAllProductPromise]);
    } catch (error) {
      navigate(ROUTES.ERROR_PAGE, {
        replace: true,
        state: {
          error_code: error.response?.status,
          error_msg: ResponseStatusMessage[error.response?.status]
        }
      });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    initialPage();
  }, []);

  return (
    <div className="my-points-index-page mt-0">
      <div className="client-cpanel-container">
        <div className="row pb-5">
          <Link to={toMemberPage(user?.user_type, ROUTES.MY_POINT_INDEX)} className="btn-back-link mb-2 mt-0">
            <span>戻る</span>
          </Link>
          <div className="col-12 mt-2">
            <div className="position-relative">
              <h2 className="page-title">ポイント履歴</h2>
              <div className="client-cpanel__page-title-after">
                <span className="shadow-text">Point History</span>
              </div>
            </div>
          </div>
          <div className="box-style-1__border">
            <div className="my-points__container mb-0">
              {isLoading ? (
                <div className="w-100 d-flex align-items-center justify-content-between">
                  <Skeleton containerClassName="w-100" height={61} />
                </div>
              ) : (
                <div
                  className="d-inline-flex flex-wrap align-items-end justify-content-center my-points-value-group w-100"
                >
                  <strong className="my-points__name">現在保持しているポイント</strong>
                  <div className="my-points__box-value">
                    <span>{store.currentMyPointsCompany?.points}</span>
                    <span>pt</span>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="row p-0 mt-5">
            <div className="col-12 table-responsive">
              <table className="table align-middle">
                <thead className="border_bottom_2">
                  <tr>
                    <th scope="col" className="ps-3 ps-md-5 w-50">日付</th>
                    <th scope="col">内容</th>
                  </tr>
                </thead>
                <tbody>
                  {isLoading && [...Array(20)].map((e, i) => (
                    <tr className="table-purchase-row" key={`${i.toString()}`}>
                      <td>
                        <Skeleton height={17} />
                      </td>
                      <td>
                        <Skeleton height={17} />
                      </td>
                    </tr>
                  ))}
                  {store.myPurchases?.total < 1 && !isLoading && <tr><td colSpan={2}>見つかりません。</td></tr>}
                  {store.myPurchases?.data && !isLoading && (
                    <>
                      {store.myPurchases.data.map((item, index) => (
                        <tr className="table-purchase-row" key={`purchases-${index.toString()}`}>
                          <td>{item.payment_at}</td>
                          <td>
                            {(item.is_buy == 1) ? (
                              <span className="text-nowrap">
                                <span className="number_point">+{item.points} pt</span>
                                {item.product_id ? (
                                  <span>購入</span>
                                ) : (
                                  <span>{item.title ? item.title : '付与'}</span>
                                )}
                                <br />
                                <span className="ps-sm-5 font-14">ポイント有効期限：{item.expiration_at}</span>
                              </span>
                            ) : (
                              <span className="color-pink text-nowrap">
                                <span className="number_point">-{item.points}</span>
                                <span>pt使用</span>
                              </span>
                            )}
                          </td>
                        </tr>
                      ))}
                    </>
                  )}
                </tbody>
              </table>
            </div>
            {store.myPurchases?.last_page > 1 && (
              <div className="mt-4 pb-5 d-flex justify-content-center">
                <Pagination
                  pageCount={store.myPurchases?.last_page}
                  activePage={store.myPurchases?.current_page}
                  onPageChange={onPagingClick}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default MyPurchase;
