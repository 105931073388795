export const genderData = [
  {
    key: 0,
    value: '男性'
  },
  {
    key: 1,
    value: '女性'
  },
  {
    key: 2,
    value: 'その他'
  }
];
