import API from 'axios/API';

export const GET_MY_BANK_ACCOUNT = 'GET_MY_BANK_ACCOUNT';

export const findMyBankAccount = () => (dispatch) => {
  return API({
    method: 'GET',
    url: 'bank-account/my-info'
  })
    .then((res) => {
      dispatch({
        type: GET_MY_BANK_ACCOUNT,
        payload: res
      });
    });
};

export const createBankAccount = (requestForm) => () => {
  return API({
    method: 'POST',
    url: 'bank-account/create',
    data: requestForm
  })
    .then((res) => {
      return res;
    });
};

