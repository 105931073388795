import AdminAPI from 'axios/AdminAPI';

export const GET_AVAILABLE_POINTS_BY_NPO_CATEGORY = 'GET_AVAILABLE_POINTS_BY_NPO_CATEGORY';
export const GET_NPOS_FOR_DONATION = 'GET_NPOS_FOR_DONATION';

export const getAvailablePointsByNpoCategory = (npoCategoryId, useDispatch = true) => (dispatch) => {
  let URLApi = 'donation/points';
  if (npoCategoryId) {
    URLApi += `?npo_category=${npoCategoryId}`;
  }

  return AdminAPI({
    method: 'GET',
    url: URLApi
  })
    .then((res) => {
      if (useDispatch) {
        dispatch({
          type: GET_AVAILABLE_POINTS_BY_NPO_CATEGORY,
          payload: res
        });
      }
      return res;
    })
    .catch((error) => {
      return error;
    });
};

export const searchNPOsForDonation = (page = 1, requestParams) => (dispatch) => {
  let URLApi = `donation/npo?page=${page}`;
  const requestParamsStr = new URLSearchParams(requestParams).toString();
  if (requestParamsStr) {
    URLApi += `&${requestParamsStr}`;
  }
  return AdminAPI({
    method: 'GET',
    url: URLApi
  })
    .then((res) => {
      dispatch({
        type: GET_NPOS_FOR_DONATION,
        payload: res
      });
      return res;
    })
    .catch((error) => {
      return error;
    });
};

export const exportNPOsForDonation = (params) => () => {
  return AdminAPI({
    method: 'GET',
    url: `donation/export?${new URLSearchParams(params).toString()}`
  })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error;
    });
};

export const createDonationForNPOs = (requestForm) => () => {
  return AdminAPI({
    method: 'POST',
    url: 'donation/create',
    data: requestForm
  })
    .catch((error) => {
      return error;
    });
};
