import {
  GET_ALL_CATEGORY_SUCCESS, GET_ALL_NPO_CATEGORY_SUCCESS, GET_ALL_PREFECTURES, POPULAR_CATEGORY
} from './categoryAction';

const initialState = {
  categories: [],
  NPOCategories: [],
  prefectures: [],
  prefecturesAndForeign: [],
  error: null,
  popularCategories: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_CATEGORY_SUCCESS:
      return {
        ...state,
        categories: action.payload.data
      };

    case GET_ALL_NPO_CATEGORY_SUCCESS:
      return {
        ...state,
        NPOCategories: action.payload.data
      };
    case GET_ALL_PREFECTURES: {
      const { data } = action.payload;
      return {
        ...state,
        prefectures: data.filter((prefecture) => prefecture.key !== 99),
        prefecturesAndForeign: data
      };
    }
    case POPULAR_CATEGORY:
      return {
        ...state,
        popularCategories: action.payload.data || []
      };


    default:
      return state;
  }
};
