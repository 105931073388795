import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { useCallbackPrompt } from 'hooks';
import { getAllNPOCategory } from 'redux/client/category_global';
import { getNPOs } from 'redux/client/project';
import { Tab, Tabs } from 'react-bootstrap';
import * as ROUTES from 'constants/routes';
import constants from 'constants/constants';
import DialogBox from 'components/common/DialogBox';
import LoadingOverlay from 'components/loading/LoadingOverlay';
import useSession from 'hooks/useSession';
import { toMemberPage } from 'helpers/utils';
import NPOSearchFilter from './components/NPOSearchFilter';
import NPOItem from './components/NPOItem';

function MyProjectCreateAndSearchNPO() {
  const [isLoading, setIsLoading] = useState(true);
  const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(true);
  const [nopCategoryNpoItem, setNpoCategoryNpoItem] = useState({
    id: null,
    name: null
  });

  const store = useSelector((state) => ({
    NPOCategories: state.categoryGlobal.NPOCategories,
    NPOs: state.project.NPOUsers
  }));

  const { user } = useSession();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const projectState = location.state || null;

  useEffect(() => {
    if (!projectState) {
      navigate(toMemberPage(user?.user_type, ROUTES.MY_PROJECT_CREATE));
    }
    initialPage();
  }, []);

  const initialPage = async () => {
    try {
      const getAllNPOCategoryPromise = store.NPOCategories.length === 0 ? dispatch(getAllNPOCategory()) : null;
      const getAllNPOUsers = dispatch(getNPOs());
      await Promise.all([getAllNPOCategoryPromise, getAllNPOUsers]);
      setIsLoading(false);
      return true;
    } catch (error) {
      setIsLoading(false);
      return error;
    }
  };

  return (
    <div className="client-cpanel-container">
      <DialogBox
        showDialog={showPrompt}
        confirmNavigation={confirmNavigation}
        cancelNavigation={cancelNavigation}
      />

      <div className="select-npo_and_company-page">
        <div className="box-style-1__border create-project__search-user-form">
          <div className="row justify-content-center">
            <div className="col-lg-10 col-11">
              <h3 className="title-form">
                NPO検索
                <span>支援するＮＰＯの活動分野を選択してください</span>
              </h3>
              <NPOSearchFilter
                NPOCategoriesData={store.NPOCategories}
                setIsLoading={setIsLoading}
                isLoading={isLoading}
                projectState={projectState}
                confirmNavigation={confirmNavigation}
                setNpoCategoryNpoItem={setNpoCategoryNpoItem}
              />
            </div>
          </div>
        </div>
        <div className="list-wrapper carousel-nav">
          <Tabs defaultActiveKey="list" id="uncontrolled-tab" className="mb-3 amazing-tabs">
            <Tab eventKey="list" title="一覧">
              {isLoading && <LoadingOverlay />}
              {store.NPOs?.length < 1 && !isLoading && nopCategoryNpoItem.id == constants.LEAVE_IT_TO_MANAGEMENT_ID && (
                <div className="col-6">
                  <div className="card-galaxy">
                    <div className="card-galaxy-body">
                      <div className="row">
                        <div className="col-lg-12">
                          <h2
                            className="item-title text-truncate pb-2 pb-sm-3"
                          >
                            <i className={`icon-svg npo-category-icon-${nopCategoryNpoItem.id} me-1 me-sm-3`} />
                            {constants.LEAVE_IT_TO_MANAGEMENT}
                          </h2>
                        </div>
                      </div>
                      <div className="row">
                        <div className="leave_management">
                          「運営にお任せ」は、寄付先の選択をENMA運営チームにおまかせいただくものです。
                          ENMAは多くの分野や社会的課題に関わる優れたNPO団体と連携し、企業の寄付がより大きな社会的効果を生むように努めています。<br />
                          特定のカテゴリにこだわらず、幅広い社会課題への支援を希望される場合には、「運営にお任せ」いただくことをおすすめします。
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {store.NPOs?.length < 1 && !isLoading && nopCategoryNpoItem.id != constants.LEAVE_IT_TO_MANAGEMENT_ID && (
                <p>見つかりません。</p>
              )}
              {store.NPOs?.length > 0 && !isLoading && (
                <div className="row">
                  {store.NPOs.map((userNpo) => (
                    <NPOItem
                      npo={userNpo}
                      key={`npo${userNpo.id}`}
                    />
                  ))}

                </div>
              )}
            </Tab>
          </Tabs>
        </div>
      </div>
    </div>
  );
}

export default MyProjectCreateAndSearchNPO;
