import React from 'react';
import PropTypes from 'prop-types';

function IncomeHistoryRow({ itemRow }) {
  return (
    <tr>
      <td>{itemRow.project_title}</td>
      <td>{itemRow.posted_at}</td>
      <td>{itemRow.video_url}</td>
      <td>{itemRow.reward}</td>
      <td>{itemRow.donation}</td>
      <td>{itemRow.fee}</td>
    </tr>
  );
}

IncomeHistoryRow.propTypes = {
  itemRow: PropTypes.object.isRequired
};
export default IncomeHistoryRow;
