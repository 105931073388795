import React from 'react';
import PropTypes from 'prop-types';

import { Field, Form, Formik } from 'formik';
import { Spinner } from 'react-bootstrap';

function PointsSearchBar({
  isLoading,
  initFormikValues,
  onSubmitFilterForm,
  NPOCategoryData,
  availablePoints,
  isLoadingExportCSV,
  exportNPOsForDonation
}) {
  return (
    <Formik initialValues={initFormikValues} onSubmit={onSubmitFilterForm} enableReinitialize>
      {({ submitForm, handleChange }) => (
        <Form onChange={submitForm} className="row flex-column flex-md-row admin-form-custom-1">
          <div className="col mb-2 mb-md-0 point-search-bar_container">
            <div role="group" className="btn-group d-inline-flex flex-wrap align-items-center justify-content-center">
              <div className="available-points_cover">
                現在配分可能なポイント数
                <span className="available-points">
                  {availablePoints ? availablePoints.points_format : 0}pt
                </span>
              </div>
              <div className="npo_categoy_cover">
                <label htmlFor="npo_category_id" className="form-label">
                  OTASUKEカテゴリ
                </label>
                <Field
                  as="select"
                  name="npo_category_id"
                  id="npo_category_id"
                  className="form-select rounded-0"
                  required
                  disabled={isLoading}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                >
                  <option value="">選択してください</option>
                  {NPOCategoryData.map((NPOCategory) => (
                    <option key={NPOCategory.key} value={NPOCategory.key}>
                      {NPOCategory.value}
                    </option>
                  ))}
                </Field>
              </div>
              <div className="col admin-form-bg-custom-1">
                <button
                  type="button"
                  className="btn btn-outline-primary rounded-pill admin-btn-green mt-4"
                  onClick={exportNPOsForDonation}
                  disabled={isLoadingExportCSV}
                >
                  {isLoadingExportCSV && (
                  <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className="me-2" />
                  )}
                  <strong>履歴ダウンロード</strong>
                </button>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}

PointsSearchBar.defaultProps = {
  availablePoints: {}
};

PointsSearchBar.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  initFormikValues: PropTypes.object.isRequired,
  onSubmitFilterForm: PropTypes.func.isRequired,
  NPOCategoryData: PropTypes.array.isRequired,
  availablePoints: PropTypes.object,
  isLoadingExportCSV: PropTypes.bool.isRequired,
  exportNPOsForDonation: PropTypes.func.isRequired
};

export default PointsSearchBar;
