import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Skeleton from 'react-loading-skeleton';

import { LoadingContext } from 'hooks/LoadingContext';
import TopProjectItem from './TopProjectItem';
import TopProjectSlideItem from './TopProjectSlideItem';

const settingSlider = {
  dots: false,
  centerMode: true,
  slidesToShow: 1,
  arrows: false
};

function TopProject({ top5Projects }) {
  const [isLoading] = useContext(LoadingContext);

  return (
    <section className="home-top">
      <div className="client-container">
        <div className="row">
          {isLoading ? (
            <>
              <div className="col-lg-6 d-none d-sm-none d-md-none d-lg-block">
                <div className="card h-100">
                  <Skeleton height={600} />
                </div>
              </div>
              <div className="col-lg-6 d-none d-sm-none d-md-none d-lg-block">
                <div className="row">
                  {[...Array(4)].map((e, i) => (
                    <div className="col-sm-6 small-card" key={`${i.toString()}`}>
                      <div className="card h-100">
                        <Skeleton height={290} />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </>
          ) : top5Projects?.length > 0 && (
            <>
              <div className="col-12 col-lg-6 custom-mb d-none d-sm-none d-md-none d-lg-block">
                {top5Projects[0] && <TopProjectItem project={top5Projects[0]} isSubCard={false} />}
              </div>
              <div className="col-lg-6 d-none d-sm-none d-md-none d-lg-block">
                <div className="row">
                  {top5Projects?.map(
                    (item, index) => index > 0 && (
                      <div className="col-sm-6 small-card" key={item.id}>
                        <TopProjectItem project={item} isSubCard />
                      </div>
                    )
                  )}
                </div>
              </div>
              <div className="col-12 d-lg-none d-xl-none slider">
                <Slider {...settingSlider}>
                  {top5Projects?.map(
                    (item) => <TopProjectSlideItem project={item} key={item.id} />
                  )}
                </Slider>
              </div>
            </>
          )}
        </div>
      </div>
    </section>
  );
}
TopProject.propTypes = {
  top5Projects: PropTypes.array.isRequired
};
export default TopProject;
