/* eslint-disable prefer-destructuring */
/* eslint-disable prefer-template */
/* eslint-disable func-names */
/* eslint-disable no-param-reassign */
/* eslint-disable prefer-arrow-callback */
import axios from 'axios';
import * as ResponseStatus from 'constants/ResponseStatus';
import { store } from 'redux/store';
import { removeAccessToken } from 'redux/client/auth';

const URL = process.env.REACT_APP_APP_API;
function API(config) {
  const accessToken = store.getState().auth.accessToken;
  config.headers = {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': '*',
    'Access-Control-Expose-Headers': '*',
    'Content-Type': 'application/json',
    'CloudFront-Authorization': `Bearer ${accessToken}`
  };

  /**
   * interceptors handle network error
   */
  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    function (error) {
      if (error.response && error.response.status === ResponseStatus.HTTP_UNPROCESSABLE_ENTITY) return error.response;

      if (!error.response) {
        error.response = {
          data: 'net work error',
          status: ResponseStatus.HTTP_INTERNAL_SERVER_ERROR
        };
      }
      if (error.response.status === ResponseStatus.HTTP_UNAUTHORIZED) {
        store.dispatch(removeAccessToken());
        throw error;
      }
      return Promise.reject(error);
    }
  );

  config.baseURL = URL;
  return axios(config);
}

export default API;
