import React from 'react';
import PropTypes from 'prop-types';
import { ProgressBar } from 'react-bootstrap';

function CustomProgressBar({ now, customLabel, minNumber }) {
  if (now <= minNumber) {
    return (
      <ProgressBar
        now={now}
        label={customLabel != '' ? customLabel : `${now}%`}
        className={`progress-custom-1 ${now == 0 ? 'progress__less-zero' : ''}
          ${now <= minNumber ? 'progress__less-than' : ''}`}
      >
        <div className="progress-bar" style={{ width: `${now}%` }}>{now}</div>
        <span>{now}%</span>
      </ProgressBar>
    );
  }
  return (
    <ProgressBar
      now={now}
      label={customLabel != '' ? customLabel : `${now}%`}
      className="progress-custom-1"
    />
  );
}

CustomProgressBar.defaultProps = {
  now: 0,
  customLabel: '',
  minNumber: 8
};

CustomProgressBar.propTypes = {
  now: PropTypes.number,
  customLabel: PropTypes.string,
  minNumber: PropTypes.number
};

export default CustomProgressBar;
