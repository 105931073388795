export const comparisonTypeData = [
  {
    key: 0,
    value: '以下'
  },
  {
    key: 1,
    value: '以上'
  }
];
