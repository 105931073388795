import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';

import * as ROUTES from 'constants/routes';
import { Link, useParams } from 'react-router-dom';

function NotPointsRecruitTalkRoomModal({ showModal, missingBudgetScore }) {
  const [show, setShow] = useState(false);
  const { id } = useParams();

  useEffect(() => {
    setShow(showModal);
  }, [showModal]);
  return (
    <Modal
      show={show}
      centered
      dialogClassName="bs-modal-custom-1 not_points_recruit_talk_room"
      keyboard={false}
      onHide={() => setShow(false)}
    >
      <Modal.Header closeButton className="border-0"> </Modal.Header>
      <Modal.Body className="p-4">
        <h3 className="modal-title h5 mb-3">
          <strong>
            予算が{missingBudgetScore}pt足りません <br />
            インフルエンサーを採用する場合、予算を追加をしてください。
          </strong>
        </h3>
        <ul className="nav flex-column">
          <li>
            <Link
              className="d-block px-0 py-2 link"
              to={`${ROUTES.APP_COMPANY}/${ROUTES.MY_PROJECT_EDIT.replace(':id', id)}`}
            >
              プロジェクトに予算を追加
            </Link>
          </li>
          <li>
            <Link className="d-block px-0 py-2 link" to={`${ROUTES.APP_COMPANY}/${ROUTES.MY_POINT_INDEX}`}>
              ポイントを追加購入する
            </Link>
          </li>
        </ul>
      </Modal.Body>
    </Modal>
  );
}

NotPointsRecruitTalkRoomModal.defaultProps = {
  showModal: false,
  missingBudgetScore: 0
};

NotPointsRecruitTalkRoomModal.propTypes = {
  showModal: PropTypes.bool,
  missingBudgetScore: PropTypes.number
};

export default NotPointsRecruitTalkRoomModal;
