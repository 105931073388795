import API from 'axios/API';
import { USER_TYPE_INFLUENCER } from 'redux/client/auth';

export const findUserInfo = (userType = USER_TYPE_INFLUENCER) => () => {
  const userTypeUrls = {
    1: 'company/my-info'
  };
  const userUrl = userTypeUrls[userType] || userTypeUrls[0];

  return API({
    method: 'GET',
    url: userUrl
  });
};

export const createNdaconclusion = () => () => {
  return API({
    method: 'POST',
    url: 'nda/conclusion'
  });
};
