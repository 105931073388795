import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import * as ROUTES from 'constants/routes';
import Skeleton from 'react-loading-skeleton';
import useSession from 'hooks/useSession';
import { toMemberPage } from 'helpers/utils';
import {
  getPointsBalance,
  getPointsTransferHistory
} from 'redux/client/transfer_points';
import { findMyBankAccount } from 'redux/client/bank_account';
import { ResponseStatusMessage } from 'constants/ResponseStatusMessage';
import { USER_TYPE_INFLUENCER } from 'redux/client/auth';

function TransferPointHistory() {
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSession();
  const userType = user?.user_type;
  const store = useSelector((state) => ({
    pointsBalance: state.transferPoints.availablePoints,
    pointsTransferHistory: state.transferPoints.pointsTransferHistory,
    bankAccountInfo: state.bankAccount.bankAccountInfo
  }));
  useEffect(() => {
    initialPage();
  }, []);

  const initialPage = async () => {
    setIsLoading(true);
    try {
      const pointsBalancePromise = dispatch(getPointsBalance());
      const pointsTransferHistoryPromise = dispatch(getPointsTransferHistory());
      const bankAccountInfoPromise = dispatch(findMyBankAccount());
      await Promise.all([pointsBalancePromise, pointsTransferHistoryPromise, bankAccountInfoPromise]);
    } catch (error) {
      navigate(ROUTES.ERROR_PAGE, {
        replace: true,
        state: {
          error_code: error.response?.status,
          error_msg: ResponseStatusMessage[error.response?.status]
        }
      });
    }
    setIsLoading(false);
  };

  return (
    <div className="transfer-points-page">
      <div className="earned-list__container">
        <div className="earned-list__header position-relative">
          <strong>売上履歴</strong>
          <Link
            to={toMemberPage(userType, ROUTES.TRANSFER_HISTORY)}
            className="position-absolute hyperlink end-0"
          >
            すべて見る &gt;
          </Link>
        </div>
        <div className="earned-list__body">
          {isLoading && [...Array(10)].map((e, i) => (
            <div className="earned-list__item" key={`${i.toString()}`}>
              <Skeleton containerClassName="w-50" height={24} />
              <Skeleton containerClassName="w-25" height={24} />
            </div>
          ))}
          {store.pointsTransferHistory?.total < 1 && !isLoading && <p className="pt-3">見つかりません。</p>}
          {store.pointsTransferHistory?.data && !isLoading && (
            <>
              {store.pointsTransferHistory.data.map((item, index) => {
                return index < 3 ? (
                  <div className="earned-list__item" key={item.id}>
                    <div className="earned-list__box-history">
                      <small className="text-muted">{item.updated_at}</small>
                      {item.project_title && <p className="my-2 fw-bold">{item.project_title}</p>}
                      {item.company_name && <p className="mb-0">{item.company_name}</p>}
                    </div>
                    <div className="earned-list__box-value">
                      <span>{user?.user_type == USER_TYPE_INFLUENCER ? item.reward : item.donation}</span>
                      <span>pt</span>
                    </div>
                  </div>
                ) : ('');
              })}
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default TransferPointHistory;
