import React, { useState } from 'react';
import { Modal, Button, Spinner } from 'react-bootstrap';
import { Form, Formik, Field } from 'formik';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import * as ja from 'helpers/yup-locale-ja';
import useSession from 'hooks/useSession';
import { updateUserStatus } from 'redux/client/user';
import { logOut } from 'redux/client/auth';
import { ResponseStatus } from 'constants';
import { useNavigate } from 'react-router-dom';
import * as ROUTES from 'constants/routes';

import ToastMessage from 'components/admin/common/ToastMessage';
import CantLeaveGuildModal from './CantLeaveGuildModal';
import LeaveGuildSuccessModal from './LeaveGuildSuccessModal';

Yup.setLocale(ja.suggestive);
const FormSchema = Yup.object().shape({
  confirmed_leave: Yup.boolean().required()
});

function LeaveGuild() {
  const { user, canLeaveGuild } = useSession();
  const [isSubmit, setIsSubmit] = useState(false);
  const navigate = useNavigate();
  const [isVerifyLeaveGuildModal, setIsVerifyLeaveGuildModal] = useState(false);
  const [isCantLeaveGuildModal, setIsCantLeaveGuildModal] = useState(false);
  const [isLeaveGuildSuccessModal, setIsLeaveGuildSuccessModal] = useState(false);
  const [showToastError, setShowToastError] = useState(false);
  const dispatch = useDispatch();

  const initFormikValues = {
    confirmed_leave: false
  };

  const _openVerifyModel = () => {
    setIsVerifyLeaveGuildModal(true);
  };

  const _handleLeaveGuild = async () => {
    if (canLeaveGuild) {
      setIsSubmit(true);
      const res = await dispatch(updateUserStatus(user.id));
      if (res && res.status == ResponseStatus.HTTP_UNPROCESSABLE_ENTITY) {
        setShowToastError(true);
        return;
      }
      setIsLeaveGuildSuccessModal(true);
      setTimeout(() => {
        dispatch(logOut());
        navigate(ROUTES.HOME);
      }, 1000);
    } else {
      setIsCantLeaveGuildModal(true);
    }
    setIsVerifyLeaveGuildModal(false);
  };

  return (
    <div className="leave-guild-page">
      <CantLeaveGuildModal
        isCantLeaveGuildModal={isCantLeaveGuildModal}
        setIsCantLeaveGuildModal={setIsCantLeaveGuildModal}
      />
      <LeaveGuildSuccessModal
        isLeaveGuildSuccessModal={isLeaveGuildSuccessModal}
        setIsLeaveGuildSuccessModal={setIsLeaveGuildSuccessModal}
      />

      <Modal
        onHide={() => setIsVerifyLeaveGuildModal(false)}
        show={isVerifyLeaveGuildModal}
        size="lg"
        centered
        className="project-list-modal"
      >
        <Modal.Header closeButton />
        <Modal.Body>
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="modal-title text-center h4">
                  <b>退会は取り消しができません。本当に退会しますか？</b>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="justify-content-center border-top-0 pb-5 w-100 modal-footer">
            <button
              type="button"
              tabIndex="0"
              className="common-btn btn px-5"
              onClick={() => _handleLeaveGuild()}
              disabled={isSubmit}
            >
              {isSubmit && (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  className="me-2"
                />
              )}
              <span className="sr-only">退会する</span>
            </button>
          </div>
        </Modal.Footer>
      </Modal>

      <div className="container main-container mb-5">
        <div className="row justify-content-center mt-3">
          <div className="col-lg-10 col-11 form__wrapper">
            <div className="group-content">
              <h3 className="title-sub">退会手続き</h3>
              <p>
                退会手続きを行います。注意事項の記載内容をご確認の上、チェックをし、「退会する」ボタンを押してください。
              </p>
              <p>
                ※プロジェクトが終了していない場合、進行中のプロジェクトがある場合は退会手続きができません。
              </p>
            </div>

            <div className="group-content">
              <h3 className="title-sub">注意事項</h3>
              <ul>
                <li>退会は取り消しができません。</li>
                <li>退会後、現在ログインに使用しているアカウントでサービスが利用できなくなります。過去のデータなども全て閲覧ができなくなります。</li>
                <li>保有中のポイントも削除されます。払い戻し等はできませんのでご注意ください。</li>
              </ul>
            </div>
            <Formik initialValues={initFormikValues} validationSchema={FormSchema} onSubmit={_openVerifyModel}>
              {({ handleSubmit, handleChange, values }) => (
                <Form className="transfer-modal form-style-1">
                  <div className="text-center">
                    <div className="form-check form-check-inline m-0">
                      <Field
                        id="confirm-leave"
                        type="checkbox"
                        name="confirmed_leave"
                        className="form-check-input"
                        onChange={(e) => {
                          handleChange(e);
                        }}
                      />
                      <label className="form-check-label" htmlFor="confirm-leave">上記を確認しました</label>
                    </div>
                  </div>

                  <div className="text-center mt-3">
                    <Button
                      variant="submit-leave-guild common-btn"
                      onClick={() => handleSubmit()}
                      disabled={isSubmit || !values.confirmed_leave}
                    >
                      {isSubmit && (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                          className="me-2"
                        />
                      )}
                      <span className="sr-only">退会する</span>
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
        <ToastMessage
          isShow={showToastError}
          onClose={() => {
            setShowToastError(false);
          }}
        />
      </div>
    </div>
  );
}

export default LeaveGuild;
