import React from 'react';
import PropTypes from 'prop-types';
import { Field, Formik } from 'formik';
import { Search } from 'react-bootstrap-icons';

function UserNPOSearchBar({ categoryData, NPOCategoryData, onSubmitFilterForm }) {
  const initFormikValues = {
    category_id: '',
    npo_category_id: '',
    keyword: '',
    is_verified: false
  };

  return (
    <Formik initialValues={initFormikValues} onSubmit={onSubmitFilterForm}>
      {({ handleChange, handleSubmit }) => (
        <form className="admin-form-custom-1">
          <div className="row mb-3">
            <div className="col-md-4 col-12">
              <label htmlFor="category_id" className="form-label">
                ジャンル
              </label>
              <Field
                as="select"
                name="category_id"
                id="category_id"
                className="form-select rounded-0"
                required
                onChange={(e) => {
                  handleChange(e);
                  handleSubmit();
                }}
              >
                <option value="">選択してください</option>
                {categoryData.map((category) => (
                  <option key={category.key} value={category.key}>
                    {category.value}
                  </option>
                ))}
              </Field>
            </div>
            <div className="col-md-4 col-12">
              <label htmlFor="npo_category_id" className="form-label">
                OTASUKEカテゴリ
              </label>
              <Field
                as="select"
                name="npo_category_id"
                id="npo_category_id"
                className="form-select rounded-0"
                required
                onChange={(e) => {
                  handleChange(e);
                  handleSubmit();
                }}
              >
                <option value="">選択してください</option>
                {NPOCategoryData.map((NPOCategory) => (
                  <option key={NPOCategory.key} value={NPOCategory.key}>
                    {NPOCategory.value}
                  </option>
                ))}
              </Field>
            </div>
            <div className="col-md-4 col-12">
              <label htmlFor="keyword" className="form-label">
                キーワード検索
              </label>
              <div className="input-group">
                <Field name="keyword" id="keyword" className="form-control rounded-0" />
                <button type="submit" className="input-group-text rounded-0 bg-transparent" onClick={handleSubmit}>
                  <Search />
                </button>
              </div>
            </div>
          </div>
        </form>
      )}
    </Formik>
  );
}

UserNPOSearchBar.propTypes = {
  categoryData: PropTypes.array.isRequired,
  NPOCategoryData: PropTypes.array.isRequired,
  onSubmitFilterForm: PropTypes.func.isRequired
};

export default UserNPOSearchBar;
