import React, { useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import Pagination from 'components/admin/Pagination/Pagination';
import PropTypes from 'prop-types';
import ToastMessage from 'components/admin/common/ToastMessage';
import CustomBottomToRightToast from 'components/admin/common/CustomBottomToRightToast';
import ProjectRow from './ProjectRow';
import SaveToDraft from './SaveToDraft';

function ProjectList({ isLoading, projects, onPagingClick, remountPagingComponent }) {
  const [isSaveToDraftModalShow, setIsSaveToDraftModalShow] = useState(false);
  const [projectId, setProjectId] = useState(null);
  const [showToastError, setShowToastError] = useState(false);
  const [showToastSuccess, setShowToastSuccess] = useState(false);
  const _onSaveToDraftClickHandle = (currentProjectId) => {
    setIsSaveToDraftModalShow(true);
    setProjectId(currentProjectId);
  };

  return (
    <>
      <SaveToDraft
        isSaveToDraftModalShow={isSaveToDraftModalShow}
        setIsSaveToDraftModalShow={setIsSaveToDraftModalShow}
        projectId={projectId}
        setShowToastError={setShowToastError}
        setShowToastSuccess={setShowToastSuccess}
      />
      <div className="table-responsive mt-5">
        <table className="table table-lg table-hover admin-table-custom-1 project-list-table">
          {(!isLoading && projects?.total < 1) && <caption className="text-center py-5 h5">見つかりません。</caption>}
          <thead className="border-top border-2">
            <tr>
              <th width="5%">ID</th>
              <th>タイトル</th>
              <th width="5%">区分</th>
              <th width="15%">タイプ</th>
              <th width="5%">区分</th>
              <th width="8%">予算pt.</th>
              <th width="8%">応募人数</th>
              <th width="8%">採用人数</th>
              <th width="8%">納品日</th>
              <th width="8%">&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            {isLoading && [...Array(30)].map((e, i) => (
              <tr key={`${i.toString()}`}>
                <td>
                  <Skeleton height={17} />
                </td>
                <td>
                  <Skeleton height={17} />
                </td>
                <td>
                  <Skeleton height={17} />
                </td>
                <td>
                  <Skeleton height={17} />
                </td>
                <td>
                  <Skeleton height={17} />
                </td>
                <td>
                  <Skeleton height={17} />
                </td>
                <td>
                  <Skeleton height={17} />
                </td>
                <td>
                  <Skeleton height={17} />
                </td>
                <td>
                  <Skeleton height={17} />
                </td>
              </tr>
            ))}
            {(!isLoading && projects?.data)
              && projects.data.map((item) => (
                <ProjectRow
                  key={item.id}
                  project={item}
                  onSaveToDraftClickHandle={() => _onSaveToDraftClickHandle(item.id)}
                />
              ))}
          </tbody>
        </table>
      </div>
      {projects?.last_page > 1 && (
        <div className="d-flex justify-content-center  my-5">
          <Pagination
            className="pagination admin-pagination-custom-1"
            key={remountPagingComponent}
            pageCount={projects?.last_page}
            onPageChange={onPagingClick}
          />
        </div>
      )}
      <ToastMessage
        isShow={showToastError}
        onClose={() => {
          setShowToastError(false);
        }}
      />
      <CustomBottomToRightToast msg="更新されました" showToast={showToastSuccess} setShowToast={setShowToastSuccess} />
    </>
  );
}
ProjectList.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  projects: PropTypes.object.isRequired,
  onPagingClick: PropTypes.func.isRequired,
  remountPagingComponent: PropTypes.number.isRequired
};
export default ProjectList;
