import React from 'react';

function LoadingOverlay() {
  return (
    <div className="loading-full__wrapper d-flex justify-content-center align-items-center">
      <div className="spinner-border spinner-lg text-color-green" role="status">
        <span className="sr-only"> </span>
      </div>
    </div>
  );
}

export default LoadingOverlay;
