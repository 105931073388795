import {
  GET_PAYMENT_KEY,
  GET_POP_CLIENT_KEY,
  GET_MY_PURCHASE
} from './purchasePointsActions';

const initialState = {
  paymentKeyData: [],
  myPurchases: [],
  popClientKey: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_PAYMENT_KEY:
      return {
        ...state,
        paymentKeyData: action.payload.data
      };
    case GET_POP_CLIENT_KEY:
      return {
        ...state,
        popClientKey: action.payload.data.pop_client_key
      };
    case GET_MY_PURCHASE:
      return {
        ...state,
        myPurchases: action.payload.data
      };
    default:
      return state;
  }
};

