import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import * as ROUTES from 'constants/routes';
import { Button } from 'react-bootstrap';

function TempCompleteSignUpMail() {
  const { state } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!state) {
      navigate(ROUTES.HOME);
    }
  }, []);

  return (
    <div className="signup-complete-page">
      <h3 className="signup-npo-complete__title">
        <strong>メールを送信しました</strong>
      </h3>
      <div className="wrap-text">
        <span>入力いただいたメールアドレスにメールを送信しました。</span>
        <br />
        <span>メールに記載されたURLから本登録にお進みください。</span>
      </div>
      <Button variant="auth" className="common-btn" onClick={() => navigate(ROUTES.HOME)}>
        <span className="sr-only">トップページへ</span>
      </Button>
    </div>
  );
}

export default TempCompleteSignUpMail;
