import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import './style/select-picker-custom.scss';
import { ErrorMessage } from 'formik';
import { ChevronDown } from 'react-bootstrap-icons';
import { findArray } from 'helpers/utils';

function CustomSelectPicker({
  field,
  form: { touched, errors, setFieldValue },
  label,
  selectData,
  onChange,
  ...props
}) {
  const [selected, setSelected] = useState(null);
  const [showSelectPopper, setShowSelectPopper] = useState(false);

  const [selectPopperStyle, setSelectPopperStyle] = useState({});
  const selectInputRef = useRef();
  const selectPopperRef = useRef();

  useEffect(() => {
    const handler = (event) => {
      if (!selectInputRef.current.contains(event.target)) {
        setShowSelectPopper(false);
      }
    };
    document.addEventListener('click', handler);
    return () => {
      document.removeEventListener('click', handler);
    };
  }, []);

  useEffect(() => {
    const selectedValue = (field.value.toString() && selectData.length > 0)
      ? findArray(field.value, selectData)?.value
      : null;

    setSelected(selectedValue);
  }, [selectData, field.value]);

  const onClickSelectInput = async (event) => {
    await setShowSelectPopper((prev) => !prev);
    const clientYScreen = document.documentElement.clientHeight - event.clientY;
    const selectInputHeight = selectInputRef.current.offsetHeight;
    const selectPopperHeight = selectPopperRef.current.offsetHeight;

    if (clientYScreen > selectPopperHeight + selectInputHeight) {
      setSelectPopperStyle({
        inset: '0px 0px auto auto',
        transform: `translate(0px, ${selectInputHeight}px)`
      });
    } else {
      setSelectPopperStyle({
        inset: 'auto 0px 0px auto',
        transform: `translate(0px, -${selectInputHeight}px)`
      });
    }

    return true;
  };

  const onClickSelectPickerOptionPopper = (key = '', value = '') => {
    if (field.value === key) {
      return;
    }

    setSelected(key !== '' ? value : null);
    setFieldValue(field.name, key);
    onChange();
  };
  return (
    <>
      {label.length > 0 && (
        <label htmlFor={field.name} className="form-label">
          {label[0]}
          {label[1] && <span className="label-required">{label[1]}</span>}
        </label>
      )}

      <div className={`select-picker__container ${touched[field.name] && errors[field.name] && 'is-invalid'}`}>
        <div className="select-picker__input-group">
          <input
            id={field.name}
            ref={selectInputRef}
            type="text"
            className="select-picker__input custom-maxline-1"
            autoComplete="off"
            readOnly
            onBlur={(e) => {
              setTimeout(() => {
                field.onBlur(e);
              }, 100);
            }}
            name={field.name}
            defaultValue={selected || ''}
            {...props}
            onClick={onClickSelectInput}
            onKeyPress={(e) => e.preventDefault()}
          />
          <span className="select-picker__input-group-text">
            <ChevronDown size={18} color="#8B8E8D " />
          </span>
        </div>

        <div
          ref={selectPopperRef}
          style={selectPopperStyle}
          className={`select-picker-option__popper ${showSelectPopper ? 'show' : ''}`}
        >
          {{ ...props }.placeholder && (
            <div
              className="select-picker-option__item placeholder-text"
              onClick={() => onClickSelectPickerOptionPopper()}
              aria-hidden="true"
            >
              {{ ...props }.placeholder}
            </div>
          )}

          {selectData.map((option) => (
            <div
              className={`select-picker-option__item ${option.value == selected && 'active'}`}
              key={option.key}
              onClick={() => onClickSelectPickerOptionPopper(option.key, option.value)}
              aria-hidden="true"
            >
              {option.value}
            </div>
          ))}
        </div>
      </div>
      <ErrorMessage name={field.name} component="div" className="invalid-feedback" />
    </>
  );
}

CustomSelectPicker.defaultProps = {
  label: [],
  selectData: [],
  onChange: () => {}
};

CustomSelectPicker.propTypes = {
  label: PropTypes.array,
  selectData: PropTypes.array,
  onChange: PropTypes.func,
  field: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired
};

export default CustomSelectPicker;
