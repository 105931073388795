import React, { useState } from 'react';
import { Modal, Spinner } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import ToastMessage from 'components/admin/common/ToastMessage';
import { ResponseStatus } from 'constants';
import { deleteNews, getNews } from 'redux/admin/news';

function NewsDeleteConfirmModal({
  isDeleteModalShow,
  setIsDeleteModalShow,
  newsId,
  requestParamsFilter,
  setRemountPagingComponent
}) {
  const dispatch = useDispatch();
  const [showToast, setShowToast] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);

  const handleDelete = async () => {
    setIsProcessing(true);
    const res = await dispatch(deleteNews(newsId));
    if (res && res.status === ResponseStatus.HTTP_OK) {
      dispatch(getNews(1, requestParamsFilter));
      setRemountPagingComponent(Math.random());
      setIsProcessing(false);
      setIsDeleteModalShow(false);
    } else {
      setIsProcessing(false);
      setShowToast(true);
      setIsDeleteModalShow(false);
    }
  };

  return (
    <div>
      <Modal
        onHide={() => setIsDeleteModalShow(false)}
        show={isDeleteModalShow}
        size="lg"
        centered
        className="user-request-modal form-modal"
      >
        <Modal.Header closeButton />
        <Modal.Body>
          <div className="container text-center">
            <h4>データを削除すると復元することができません。</h4>
            <h4>データを削除しますか？</h4>
            <button
              type="button"
              className="btn admin-btn-green mt-4"
              onClick={() => {
                handleDelete();
              }}
            >
              {isProcessing && (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  className="me-2"
                />
              )}
              登録する
            </button>
          </div>
        </Modal.Body>
      </Modal>
      <ToastMessage
        isShow={showToast}
        onClose={() => {
          setShowToast(false);
        }}
      />
    </div>
  );
}

NewsDeleteConfirmModal.propTypes = {
  isDeleteModalShow: PropTypes.bool.isRequired,
  setIsDeleteModalShow: PropTypes.func.isRequired,
  newsId: PropTypes.number.isRequired,
  requestParamsFilter: PropTypes.array.isRequired,
  setRemountPagingComponent: PropTypes.func.isRequired
};

export default NewsDeleteConfirmModal;
