import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import * as ROUTES from 'constants/routes';
import { ResponseStatusMessage } from 'constants/ResponseStatusMessage';
import { checkNumberParam } from 'helpers/utils';

import LoadingOverlay from 'components/loading/LoadingOverlay';
import { getUserInfluencerDetail } from 'redux/admin/user';
import UserInfo from './components/UserInfo';
import TiktokInfo from './components/TikTokInfo';
import Reviews from './components/Reviews';
import ProjectStatistics from './components/ProjectStatistics';
import ListProjectJoined from './components/ListProjectJoined';
import ListIncomeHistory from './components/ListIncomeHistory';
import IncomeStatistics from './components/IncomeStatistics';

function UserInfluencerDetail() {
  const param = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [userInfluencerDetail, setUserInfluencerDetail] = useState(null);

  const _getUserInfluencerDetail = async () => {
    if (!checkNumberParam(param.id)) {
      navigate(ROUTES.ERROR_404_PAGE, {
        replace: true,
        state: {
          error_code: 404,
          error_msg: ResponseStatusMessage[404]
        }
      });
    }

    try {
      const res = await dispatch(getUserInfluencerDetail(param.id));
      setUserInfluencerDetail(res.data);
      setIsLoading(true);
      return true;
    } catch (error) {
      setIsLoading(false);
      navigate(ROUTES.ERROR_PAGE, {
        replace: true,
        state: {
          error_code: error.response?.status,
          error_msg: ResponseStatusMessage[error.response?.status]
        }
      });
      return error;
    }
  };

  useEffect(() => {
    _getUserInfluencerDetail();
  }, []);

  return (
    <div className="client-cpanel user-detail">
      {!isLoading ? (
        <div className="text-color-green">
          <LoadingOverlay />
        </div>
      ) : (
        <>
          <UserInfo userInfluencer={userInfluencerDetail} />

          <TiktokInfo userInfluencer={userInfluencerDetail} />

          <Reviews userInfluencer={userInfluencerDetail} />

          <ProjectStatistics userInfluencer={userInfluencerDetail} />

          <ListProjectJoined userInfluencer={userInfluencerDetail} />

          <IncomeStatistics userInfluencer={userInfluencerDetail} />

          <ListIncomeHistory userInfluencer={userInfluencerDetail} />
        </>
      )}
    </div>
  );
}

export default UserInfluencerDetail;
