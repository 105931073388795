/* eslint-disable no-alert */
import { useState } from 'react';
import { generateId } from 'helpers/utils';

const useFileHandler = (initState) => {
  const [imageFile, setImageFile] = useState(initState);
  const [isFileLoading, setFileLoading] = useState(false);

  const removeImage = ({ id, name }) => {
    const items = Array.isArray(imageFile[name]) ? imageFile[name].filter((item) => item.id !== id) : '';

    setImageFile({
      ...imageFile,
      [name]: items
    });
  };

  const onFileChange = (event, { name, type }) => {
    const filesData = event.type == 'drop' ? event.dataTransfer.files : event.target.files;

    if (filesData.length <= 0) {
      setImageFile({ imageFile });
      return;
    }

    // const val = event.target.value;
    const img = filesData[0];
    // const size = img.size / 1024 / 1024;
    // const regex = /(\.jpg|\.jpeg|\.png|\.pdf)$/i;

    setFileLoading(true);
    if (type === 'multiple') {
      Array.from(filesData).forEach((file) => {
        const reader = new FileReader();
        reader.addEventListener('load', (e) => {
          setImageFile((oldFiles) => ({
            ...oldFiles,
            [name]: [...oldFiles[name], { file, url: e.target.result, id: generateId() }]
          }));
        });
        reader.readAsDataURL(file);
      });

      setFileLoading(false);
    } else {
      // type is single
      const reader = new FileReader();

      reader.addEventListener('load', (e) => {
        setImageFile({
          ...imageFile,
          [name]: { file: img, url: e.target.result, id: 1 }
        });
        setFileLoading(false);
      });
      reader.readAsDataURL(img);
    }
  };

  return {
    imageFile,
    setImageFile,
    isFileLoading,
    onFileChange,
    removeImage
  };
};

export default useFileHandler;
