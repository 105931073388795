import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import * as ROUTES from 'constants/routes';
import Skeleton from 'react-loading-skeleton';
import useSession from 'hooks/useSession';
import { toMemberPage } from 'helpers/utils';
import { getMyPurchases } from 'redux/client/purchase_points';
import { myPointsCompany } from 'redux/client/user';
import { ResponseStatusMessage } from 'constants/ResponseStatusMessage';

function MyPurchaseHistory() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSession();
  const [isLoading, setIsLoading] = useState(true);

  const store = useSelector((state) => ({
    myPurchases: state.purchasePoints.myPurchases,
    currentMyPointsCompany: state.user.currentMyPointsCompany
  }));

  const initialPage = async () => {
    setIsLoading(true);
    try {
      const myPointCompanyPromise = dispatch(myPointsCompany());
      const getAllProductPromise = dispatch(getMyPurchases());
      await Promise.all([myPointCompanyPromise, getAllProductPromise]);
    } catch (error) {
      navigate(ROUTES.ERROR_PAGE, {
        replace: true,
        state: {
          error_code: error.response?.status,
          error_msg: ResponseStatusMessage[error.response?.status]
        }
      });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    initialPage();
  }, []);

  return (
    <div className="my-points-index-page mt-0">
      <div className="pb-5">
        <div className="p-0 mt-5">
          <div className="position-relative border_bottom_2 pb-3">
            <strong>ポイント履歴</strong>
            <Link
              to={toMemberPage(user?.user_type, ROUTES.MY_POINT_PURCHASE)}
              className="position-absolute hyperlink end-0"
            >
              すべて見る &gt;
            </Link>
          </div>
          <div className="table-responsive">
            <table className="table align-middle">
              <tbody>
                {isLoading && [...Array(20)].map((e, i) => (
                  <tr className="table-purchase-row" key={`${i.toString()}`}>
                    <td>
                      <Skeleton height={17} />
                    </td>
                    <td>
                      <Skeleton height={17} />
                    </td>
                  </tr>
                ))}
                {store.myPurchases?.total < 1 && !isLoading && <tr><td colSpan={2}>見つかりません。</td></tr>}
                {store.myPurchases?.data && !isLoading && (
                  <>
                    {store.myPurchases.data.map((item, index) => {
                      return index < 3 ? (
                        <tr className="table-purchase-row" key={`purchases-${index.toString()}`}>
                          <td>{item.payment_at}</td>
                          <td className="text-end">
                            {(item.is_buy == 1) ? (
                              <span className="text-nowrap">
                                <span className="number_point">+{item.points} pt</span>
                                {item.product_id ? (
                                  <span>購入</span>
                                ) : (
                                  <span> {item.title ? item.title : '付与'}</span>
                                )}
                                <br />
                                <span className="ps-sm-5 font-14">ポイント有効期限：{item.expiration_at}</span>
                              </span>
                            ) : (
                              <span className="color-pink text-nowrap">
                                <span className="number_point">-{item.points}</span>
                                <span>pt使用</span>
                              </span>
                            )}
                          </td>
                        </tr>
                      ) : ('');
                    })}
                  </>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MyPurchaseHistory;
