import API from 'axios/API';

export const GET_ALL_AREA_SUCCESS = 'GET_ALL_AREA_SUCCESS';

export const getAllArea = () => (dispatch) => {
  return API({
    method: 'GET',
    url: 'project-area'
  })
    .then((res) => {
      dispatch({
        type: GET_ALL_AREA_SUCCESS,
        payload: res
      });
    })
    .catch((error) => {
      return error;
    });
};
