import React from 'react';
import { Link } from 'react-router-dom';
import { BoxArrowUpRight } from 'react-bootstrap-icons';
import * as ROUTES from 'constants/routes';
import LOGO from 'assets/logo-white.svg';
import useSession from 'hooks/useSession';
import constants from 'constants/constants';

function Footer() {
  const { accessToken, user } = useSession();
  const isAuthenticated = accessToken && user;
  return (
    <footer className="client__footer-main">
      <div className="client-container position-relative">
        <div className="client-footer__body">
          <div className="row">
            <div className="col-md-4 col-12">
              <Link to="/" className="text-decoration-none">
                <img src={LOGO} alt="インフルエンサーマッチング Webアプリ" className="client-footer__logo" />
              </Link>
            </div>
            <div className="col-md-8 col-12">
              <div className="client-footer__main-nav d-flex justify-content-md-end">
                <ul className="nav flex-column client-footer__nav">
                  { !isAuthenticated && (
                    <li className="nav-item">
                      <Link to={ROUTES.SIGN_IN} className="nav-link" title="ログイン">
                        ログイン
                        <BoxArrowUpRight />
                      </Link>
                    </li>
                  )}
                  <li className="nav-item">
                    <a
                      href="https://influence.enma.buzz/terms-influence"
                      target="_blank"
                      rel="noreferrer"
                      className="nav-link text-decoration-none"
                    >
                      利用規約（インフルエンサー向け）
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      href="https://influence.enma.buzz/terms-corp"
                      target="_blank"
                      rel="noreferrer"
                      className="nav-link text-decoration-none"
                    >
                      利用規約（企業向け）
                    </a>
                  </li>
                  <li className="nav-item d-none">
                    <Link to="/" onClick={(e) => e.preventDefault()} className="nav-link" title="お問合わせ">
                      お問合わせ
                    </Link>
                  </li>
                  <li className="nav-item">
                    <a
                      href="https://influence.enma.buzz/privacy-policy"
                      target="_blank"
                      rel="noreferrer"
                      className="nav-link text-decoration-none"
                    >
                      プライバシーポリシー
                    </a>
                  </li>
                  <li className="nav-item">
                    <Link to={ROUTES.LEGAL} className="nav-link">
                      特定商取引法に基づく表記
                    </Link>
                  </li>
                </ul>
                <ul className="nav flex-column client-footer__nav">
                  { !isAuthenticated && (
                  <li className="nav-item">
                    <Link to="/influencer/registration" className="nav-link" title="会員登録">
                      <span>会員登録</span>
                      <BoxArrowUpRight />
                    </Link>
                  </li>
                  )}
                  <li className="nav-item d-none">
                    <Link to="/" onClick={(e) => e.preventDefault()} className="nav-link" title="特定商取引法に関する表記">
                      特定商取引法に関する表記
                    </Link>
                  </li>
                  <li className="nav-item d-none">
                    <Link to="/" onClick={(e) => e.preventDefault()} className="nav-link" title="よくあるご質問">
                      よくあるご質問
                    </Link>
                  </li>
                </ul>
                <ul className="nav flex-column client-footer__nav">
                  <li className="nav-item">
                    <a
                      href="https://the-amazing.co.jp/"
                      target="_blank"
                      rel="noreferrer"
                      className="nav-link text-decoration-none"
                    >
                      <strong>運営会社</strong>
                      <BoxArrowUpRight />
                    </a>
                  </li>
                  <li className="nav-item">
                    <span>
                      事業再構築
                    </span>
                  </li>
                  <li className="nav-item">
                    <a
                      href={constants.LINE_LINK}
                      target="_blank"
                      rel="noreferrer"
                      className="nav-link text-decoration-none"
                    >
                      お問い合わせ
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <p className="text-copyright">Copyright© 2022 Amazing All rights Reserved.</p>
      </div>
    </footer>
  );
}

export default Footer;
