import React from 'react';
import PropTypes from 'prop-types';
import arrowIcon from 'assets/arrow.png';
import { getFormattedDate } from 'helpers/utils';

function UserCompanyIdentityRow({ itemRow, onClickUserCompanyIdentityShowModal }) {
  return (
    <tr>
      <td>{itemRow.user_id}</td>
      <td>{itemRow.full_name}</td>
      <td>{itemRow.email}</td>
      <td>{getFormattedDate(itemRow.birthday)}</td>
      <td>{getFormattedDate(itemRow.created_at)}</td>
      <td>
        <div
          role="button"
          tabIndex="0"
          className="detail"
          onClick={onClickUserCompanyIdentityShowModal}
          onKeyDown={onClickUserCompanyIdentityShowModal}
        >
          <span className="margin-right">詳細を見る</span>
          <img src={arrowIcon} alt="arrow" />
        </div>
      </td>
    </tr>
  );
}
UserCompanyIdentityRow.propTypes = {
  itemRow: PropTypes.object.isRequired,
  onClickUserCompanyIdentityShowModal: PropTypes.func.isRequired
};
export default UserCompanyIdentityRow;
