import API from 'axios/API';

export const CHECK_USER_AUTH_TOKEN = 'CHECK_USER_AUTH_TOKEN';
export const SEND_FORM_DATA = 'SEND_FORM_DATA';
export const SIGN_IN = 'SIGN_IN';
export const SIGN_IN_ADMIN = 'SIGN_IN_ADMIN';
export const SAVE_USER_INFO = 'SAVE_USER_INFO';
export const SAVE_ACCESS_TOKEN = 'SAVE_ACCESS_TOKEN';
export const SAVE_IS_PURCHASED_POINTS = 'SAVE_IS_PURCHASED_POINTS';
export const REMOVE_ACCESS_TOKEN = 'REMOVE_ACCESS_TOKEN';
export const UPDATE_UNREAD_MESSAGE = 'UPDATE_UNREAD_MESSAGE';
export const GET_DASHBOARD_COUNT = 'GET_DASHBOARD_COUNT';

// user_type in user_auth table
export const USER_TYPE_INFLUENCER = 0;
export const USER_TYPE_COMPANY = 1;
export const USER_TYPE_NPO = 2;

export const sendFormData = (dataForm, userType) => (dispatch) => {
  return API({
    method: 'POST',
    url: 'register',
    data: Object.assign(dataForm, {
      user_type: userType
    })
  })
    .then((res) => {
      dispatch({
        type: SEND_FORM_DATA,
        payload: res
      });
      return res;
    })
    .catch((error) => {
      return error;
    });
};

export const checkUserAuthToken = (requestAll) => (dispatch) => {
  return API({
    method: 'POST',
    url: 'user-auth/token/check',
    data: requestAll
  }).then((res) => {
    dispatch({
      type: CHECK_USER_AUTH_TOKEN,
      payload: res
    });
  });
};

// Company
export const validatorRegistrationUserCompany = (requestAll) => () => {
  return API({
    method: 'POST',
    url: 'company/validate-register-user-company',
    data: requestAll
  }).catch((error) => {
    return error;
  });
};

export const registrationUserCompany = (requestAll) => () => {
  return API({
    method: 'POST',
    url: 'company/register-user-company',
    data: requestAll
  }).then((res) => {
    return res;
  });
};

// NPO
export const validatorRegistrationUserNPO = (requestAll) => () => {
  return API({
    method: 'POST',
    url: 'npo/user/registration/validator',
    data: requestAll
  }).catch((error) => {
    return error;
  });
};

export const registrationUserNPO = (requestAll) => () => {
  return API({
    method: 'POST',
    url: 'npo/user/registration',
    data: requestAll
  }).then((res) => {
    return res;
  });
};

// influencer
const formDataRegistrationUserInfluencer = (request) => {
  const requestData = {
    verifyToken: request.verifyToken,
    password: request.password,
    family_name: request.family_name,
    first_name: request.first_name,
    family_name_kana: request.family_name_kana,
    first_name_kana: request.first_name_kana,
    phone: request.phone,
    birthday: request.birthday,
    gender: request.gender,
    pr: request.pr,
    prefectures: request.prefectures,
    influencer_purpose: request.influencer_purpose,
    tag_ids: request.tag_ids,
    is_sns_registration: request.is_sns_registration,
    // Tiktok info
    tiktok_auth_open_id: request.tiktok_info?.open_id,
    tiktok_info_nickname: request.tiktok_info?.display_name,
    tiktok_info_username: request.tiktok_info?.username,
    tiktok_info_profile_deep_link: request.tiktok_info?.profile_deep_link,
    tiktok_info_follower_count: request.tiktok_info?.follower_count,
    tiktok_info_like_count: request.tiktok_info?.like_count,
    tiktok_info_comment_count: request.tiktok_info?.comment_count,
    tiktok_info_share_count: request.tiktok_info?.share_count,
    tiktok_info_view_count: request.tiktok_info?.view_count,
    tiktok_info_avatar_image: request.tiktok_info?.avatar_url,
    tiktok_videos: request.tiktok_videos,
    tiktok_auth_access_token: request.tiktok_auth_access_token
  };

  const formData = new FormData();
  Object.keys(requestData).forEach((key) => {
    formData.append(key, requestData[key]);
  });
  return formData;
};

const formDataRegistrationUserInfluencerStep2 = (request) => {
  const requestData = {
    pr: request.pr,
    prefectures: request.prefectures,
    influencer_purpose: request.influencer_purpose,
    tag_ids: request.tag_ids,
    is_sns_registration: request.is_sns_registration,
    // Tiktok info
    tiktok_auth_open_id: request.tiktok_info?.open_id,
    tiktok_info_username: request.tiktok_info?.username,
    tiktok_info_nickname: request.tiktok_info?.display_name,
    tiktok_info_profile_deep_link: request.tiktok_info?.profile_deep_link,
    tiktok_info_follower_count: request.tiktok_info?.follower_count,
    tiktok_info_like_count: request.tiktok_info?.like_count,
    tiktok_info_comment_count: request.tiktok_info?.comment_count,
    tiktok_info_share_count: request.tiktok_info?.share_count,
    tiktok_info_view_count: request.tiktok_info?.view_count,
    tiktok_info_avatar_image: request.tiktok_info?.avatar_url,
    tiktok_videos: request.tiktok_videos,
    tiktok_auth_access_token: request.tiktok_auth_access_tokens
  };

  const formData = new FormData();
  Object.keys(requestData).forEach((key) => {
    formData.append(key, requestData[key]);
  });
  return formData;
};


export const validatorRegistrationUserInfluencer = (requestAll = {}) => () => {
  return API({
    method: 'POST',
    url: 'influencer/registration/validator',
    data: formDataRegistrationUserInfluencer(requestAll)
  }).catch((error) => {
    return error;
  });
};

export const validatorSignUpUserInfluencerStep1 = (formData = {}) => () => {
  return API({
    method: 'POST',
    url: 'influencer/registration/validate-step1',
    data: formData
  }).catch((error) => {
    return error;
  });
};

export const validatorSignUpUserInfluencerStep2 = (formData = {}) => () => {
  return API({
    method: 'POST',
    url: 'influencer/registration/validate-step2',
    data: formDataRegistrationUserInfluencerStep2(formData)
  }).catch((error) => {
    return error;
  });
};

export const registrationUserInfluencer = (requestAll) => () => {
  return API({
    method: 'POST',
    url: 'influencer/registration',
    data: formDataRegistrationUserInfluencer(requestAll)
  }).then((res) => {
    return res;
  });
};

export const signIn = (requestForm) => (dispatch) => {
  return API({
    method: 'POST',
    url: 'login',
    data: requestForm
  })
    .then((res) => {
      dispatch({
        type: SIGN_IN,
        payload: res
      });

      return res;
    })
    .catch((error) => {
      return error;
    });
};

export const logOut = () => (dispatch) => {
  return API({
    method: 'POST',
    url: 'logout'
  }).then(() => {
    dispatch({
      type: REMOVE_ACCESS_TOKEN
    });
  });
};

export const signInAdmin = (requestForm) => (dispatch) => {
  return API({
    method: 'POST',
    url: 'admin/login/',
    data: requestForm
  })
    .then((res) => {
      dispatch({
        type: SIGN_IN_ADMIN,
        payload: res
      });
      return res;
    })
    .catch((error) => {
      return error;
    });
};

export const getUserInfo = () => (dispatch) => {
  return API({
    method: 'GET',
    url: 'me'
  }).then((res) => {
    dispatch({
      type: SAVE_USER_INFO,
      payload: res
    });

    return res;
  });
};

export const removeAccessToken = () => ({
  type: REMOVE_ACCESS_TOKEN
});

export const getBank = (bankCode) => () => {
  return API({
    method: 'GET',
    url: `get-bank/${bankCode}`
  }).then((res) => {
    return res;
  });
};

export const getBranch = (bankCode, branchCode) => () => {
  return API({
    method: 'GET',
    url: `get-bank/${bankCode}/branch/${branchCode}`
  }).then((res) => {
    return res;
  });
};

const formUpdateTiktokInfo = (request) => {
  const requestData = {
    // Tiktok info
    tiktok_auth_open_id: request.tiktok_info?.open_id,
    tiktok_info_nickname: request.tiktok_info?.display_name,
    tiktok_info_username: request.tiktok_info?.username,
    tiktok_info_profile_deep_link: request.tiktok_info?.profile_deep_link,
    tiktok_info_follower_count: request.tiktok_info?.follower_count,
    tiktok_info_like_count: request.tiktok_info?.like_count,
    tiktok_info_comment_count: request.tiktok_info?.comment_count,
    tiktok_info_share_count: request.tiktok_info?.share_count,
    tiktok_info_view_count: request.tiktok_info?.view_count,
    tiktok_videos: request.tiktok_videos,
    tiktok_info_avatar_image: request.tiktok_info?.avatar_url,
    tiktok_auth_access_token: request.tiktok_auth_access_token
  };

  const formData = new FormData();
  Object.keys(requestData).forEach((key) => {
    formData.append(key, requestData[key]);
  });
  return formData;
};

export const updateTiktokInfo = (requestAll = {}) => () => {
  return API({
    method: 'POST',
    url: 'influencer/tiktok/update-tiktok-info',
    data: formUpdateTiktokInfo(requestAll)
  }).catch((error) => {
    return error;
  });
};

export const doForgotPassword = (requestForm) => () => {
  return API({
    method: 'POST',
    url: 'forgot-password',
    data: requestForm
  })
    .then((res) => {
      return res;
    });
};

export const doCheckTokenResetPassword = (token) => () => {
  return API({
    method: 'GET',
    url: `reset-password-check/${token}`
  })
    .then((res) => {
      return res;
    });
};

export const doResetPassword = (requestForm) => () => {
  return API({
    method: 'POST',
    url: 'reset-password',
    data: requestForm
  })
    .then((res) => {
      return res;
    });
};

export const emailVerificationMember = (token) => (dispatch) => {
  return API({
    method: 'POST',
    url: `email-verify/${token}`
  })
    .then((res) => {
      if (res.data) {
        dispatch({
          type: REMOVE_ACCESS_TOKEN
        });
      }

      return res;
    });
};

export const getDashboardCount = () => (dispatch) => {
  return API({
    method: 'GET',
    url: 'dashboard'
  })
    .then((res) => {
      dispatch({
        type: GET_DASHBOARD_COUNT,
        payload: res
      });
    })
    .catch((error) => {
      return error;
    });
};
