import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { ErrorMessage } from 'formik';

function CustomTextAreaGiftingNotes({ field, form: { touched, errors, values, setFieldValue }, ...props }) {
  const [isDisabled, setIsDisabled] = useState(false);
  useEffect(() => {
    const isGifting = !!JSON.parse(values.gifting);
    setIsDisabled(isGifting);
    if (isGifting) {
      setFieldValue(field.name, '');
    }
  }, [values.gifting]);

  return (
    <>
      <Form.Label htmlFor={field.name}>
        ギフティングに関する特記事項
        {!isDisabled && (
          <span className="label-required">必須</span>
        )}
      </Form.Label>
      <Form.Control
        type="text"
        as="textarea"
        id={field.name}
        disabled={isDisabled}
        isInvalid={touched[field.name] && errors[field.name]}
        {...field}
        {...props}
        placeholder={(
          'ギフティングに関する内容、詳細について記載してください\n'
          + 'インフルエンサーによるENMAのサイト外からの購入が必要な場合、そのプラットフォームの規約に違反することを禁止します\n'
          + '※インフルエンサーにAmazonで一度購入してもらい、後でギフト券等による返金など\n\n'
          + '例）商品系の場合\n'
          + '通常〇〇円の商品を１つ無料でプレゼント\n\n'
          + '例）来店体験型・サービス系の場合\n'
          + 'お会計〇〇円分のお料理を無料提供'
        )}
      />
      <ErrorMessage name={field.name} component="div" className="invalid-feedback" />
    </>
  );
}

CustomTextAreaGiftingNotes.propTypes = {
  field: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired
};

export default CustomTextAreaGiftingNotes;
