import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { ErrorMessage, useFormikContext } from 'formik';
import { Paperclip, FiletypePdf } from 'react-bootstrap-icons';

import DeleteIcon from 'assets/svg/delete-ic.svg';
import constants from 'constants/constants';

const PDF_FILE_TYPE = 'application/pdf';
function UploadAttachedFile({ onFileChange, imageFile, removeImage }) {
  const formikContent = useFormikContext();

  // drag state
  const [multipleDragActive, setMultipleDragActive] = useState(false);

  // ref
  const inputRef = useRef([]);

  const handleMultipleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setMultipleDragActive(true);
    } else if (e.type === 'dragleave') {
      setMultipleDragActive(false);
    }
  };

  const handleMultipleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setMultipleDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      formikContent.setFieldValue('attached_files', getValueUploadAttachedFiles(e.dataTransfer.files));
      onFileChange(e, { name: 'attached_files', type: 'multiple' });
    }
  };

  // triggers the input when the button is clicked
  const onButtonClick = (type) => {
    const currentItem = type == 'multiple' ? inputRef.current.attached_files : inputRef.current.cover_image;
    currentItem.click();
  };

  const onChangeUpdateAttachedFiles = (e) => {
    onFileChange(e, { name: 'attached_files', type: 'multiple' });
    formikContent.setFieldValue('attached_files', getValueUploadAttachedFiles(e.target.files));
  };

  const onClickRemoveAttachedFiles = (fileId, i) => {
    const attachedFiles = formikContent.values.attached_files;
    attachedFiles.splice(i, 1);
    formikContent.setFieldValue('attached_files', attachedFiles);
    removeImage({ id: fileId, name: 'attached_files' });
  };

  const getValueUploadAttachedFiles = (files) => {
    const values = [];
    for (let index = 0; index < files.length; index += 1) {
      values.push({
        file: files[index],
        id: null,
        url: null
      });
    }

    return [...formikContent.values.attached_files, ...values];
  };

  return (
    <>
      <div className="instruction-block">
        <p className="form-label">
          <span>プロジェクトの参考素材</span>
        </p>
        <p className="text-muted">
          <span>インフルエンサーが自由に使用可能な画像などございましたら、アップロードしてください。</span>
          <br />
          <span>複数ファイルを選択可能です。</span>
        </p>
      </div>

      <div id="multiple-file-upload" onDragEnter={handleMultipleDrag}>
        <label
          id="label-multiple-upload"
          htmlFor="attached_files"
          className={multipleDragActive ? 'drag-active' : ''}
        >
          <input
            accept={constants.UPLOAD_FILE_SUPPORTED_FORMATS}
            hidden
            id="attached_files"
            name="attached_files"
            ref={(element) => { inputRef.current.attached_files = element; }}
            multiple
            onChange={onChangeUpdateAttachedFiles}
            onClick={(e) => {
              e.target.value = null;
            }}
            type="file"
          />
          <div className="drap-text">
            <p className="fw-bold text-top">
              <span>ファイルをドラッグ＆ドロップするか</span>
              <br />
              <span>クリップボードから画像を貼り付けてください</span>
            </p>
            <p className="divider">または</p>
            <button
              className="btn btn-primary btn--blue"
              onClick={() => onButtonClick('multiple')}
              title="ファイル選択"
              type="button"
            >
              <Paperclip className="paperclip" />
              ファイル選択
            </button>
            <p className="text-bottom">
              <span>pdf,png,jpg,jpeg形式のみアップロード可</span>
              <br />
              <span>画像は５M以下でアップロードしてください</span>
            </p>
          </div>
        </label>
        { multipleDragActive
            && (
            <div
              id="drag-multiple-element"
              onDragEnter={handleMultipleDrag}
              onDragLeave={handleMultipleDrag}
              onDragOver={handleMultipleDrag}
              onDrop={handleMultipleDrop}
            />
            ) }
      </div>
      <ErrorMessage name="attached_files" component="div" className="invalid-feedback d-block" />

      {imageFile.attached_files.length >= 1 && (
        <div className="row">
          <div className="attached-files-block">
            {imageFile.attached_files.map((image, i) => (
              <div
                key={`${image.id}_${image.file.name}`}
                className="attached-file-cover"
              >
                <div
                  key={image.id}
                  className="mb-2 rounded align-items-center justify-content-between"
                >
                  {image?.file?.type === PDF_FILE_TYPE || image?.mime_type === constants.PDF_FILE_TYPE ? (
                    <FiletypePdf className="cover-image-view pdf" />
                  ) : (
                    <img className="cover-image-view" src={image.url} alt={image.file.name} />
                  )}

                  <button
                    className="btn delete-btn"
                    onClick={() => onClickRemoveAttachedFiles(image.id, i)}
                    title="Delete Image"
                    type="button"
                  >
                    <img src={DeleteIcon} alt="" className="delete-ic" />
                  </button>
                </div>
                <p className="attach-file-name">{image.file.name}</p>
              </div>

            ))}
          </div>

        </div>
      )}
    </>
  );
}

UploadAttachedFile.propTypes = {
  imageFile: PropTypes.object.isRequired,
  onFileChange: PropTypes.func.isRequired,
  removeImage: PropTypes.func.isRequired
};

export default UploadAttachedFile;
