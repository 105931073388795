import React from 'react';
import PropTypes from 'prop-types';
import Skeleton from 'react-loading-skeleton';
import Pagination from 'components/client/Pagination/Pagination';
import MyNewsItem from './MyNewsItem';

function MyNewsList({ isLoading, news, onPagingClick }) {
  return (
    <>
      {news?.meta?.total < 1 && !isLoading && <p>見つかりません。</p>}
      {isLoading ? [...Array(8)].map((e, i) => (
        <div className="news__item" key={`${i.toString()}`}>
          <Skeleton height={24} />
        </div>
      )) : (
        <>
          {news.data.map((item) => (
            <MyNewsItem
              newsItem={item}
              key={item.id}
            />
          ))}
        </>
      )}
      {news?.meta?.last_page > 1 && (
        <div className="mt-4 pb-5 text-center d-flex justify-content-center">
          <Pagination pageCount={news?.meta?.last_page} onPageChange={onPagingClick} />
        </div>
      )}
    </>
  );
}

MyNewsList.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  news: PropTypes.object.isRequired,
  onPagingClick: PropTypes.func.isRequired
};

export default MyNewsList;
