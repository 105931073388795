import API from 'axios/API';

export const GET_LIST_TAG = 'GET_LIST_TAG';

export const getAllTags = () => (dispatch) => {
  return API({
    method: 'GET',
    url: 'tag'
  })
    .then((res) => {
      dispatch({
        type: GET_LIST_TAG,
        payload: res
      });
    })
    .catch((error) => {
      return error;
    });
};
