import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import * as ROUTES from 'constants/routes';
import { ResponseStatusMessage } from 'constants/ResponseStatusMessage';
import { Spinner } from 'react-bootstrap';
import moment from 'moment';

import { getProjectDetail, reportProjectCsv } from 'redux/admin/project';
import LoadingOverlay from 'components/loading/LoadingOverlay';
import ProjectInfo from './components/ProjectInfo';
import ListProjectRewards from './components/ListProjectRewards';
import ListProjectReports from './components/ListProjectReports';

function ProjectDetail() {
  const param = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingExportCSV, setIsLoadingExportCSV] = useState(false);
  const [projectDetail, setProjectDetail] = useState(null);

  const _getProjectDetail = async () => {
    try {
      const res = await dispatch(getProjectDetail(param.id));
      if (Object.keys(res.data).length > 0) {
        setProjectDetail(res.data);
        setIsLoading(true);
      } else {
        setIsLoading(false);
        navigate(ROUTES.ERROR_404_PAGE, {
          replace: true,
          state: {
            error_code: 404,
            error_msg: ResponseStatusMessage[404]
          }
        });
      }
      return true;
    } catch (error) {
      setIsLoading(false);
      navigate(ROUTES.ERROR_PAGE, {
        replace: true,
        state: {
          error_code: error.response?.status,
          error_msg: ResponseStatusMessage[error.response?.status]
        }
      });
      return error;
    }
  };

  useEffect(() => {
    _getProjectDetail();
  }, []);

  const _reportProject = async () => {
    setIsLoadingExportCSV(true);
    await dispatch(reportProjectCsv(param.id)).then((res) => {
      const bom = new Uint8Array([0xEF, 0xBB, 0xBF]);
      const url = URL.createObjectURL(new Blob([bom, res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `project-report-${moment().format('YYYYMMDDHms')}.csv`);
      document.body.appendChild(link);
      link.click();
    });
    setIsLoadingExportCSV(false);
  };

  return (
    <div className="client-cpanel project-detail">
      {!isLoading ? (
        <div className="text-color-green">
          <LoadingOverlay />
        </div>
      ) : (
        <>
          <div className="mb-4 text-end">
            <button
              type="button"
              className="btn btn-outline-primary rounded-pill admin-btn-green mt-0"
              onClick={_reportProject}
              disabled={isLoadingExportCSV}
            >
              {isLoadingExportCSV && (
                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className="me-2" />
              )}
              <strong>レポート</strong>
            </button>
          </div>
          <ProjectInfo project={projectDetail} />
          <ListProjectRewards project={projectDetail} />
          <ListProjectReports project={projectDetail} />
        </>
      )}
    </div>
  );
}

export default ProjectDetail;
