import API from 'axios/API';
import { store } from 'redux/store';
import { ResponseStatus } from 'constants';

export const GET_INFLUENCERS = 'GET_INFLUENCERS';
export const GET_INFLUENCERS_INFORMATION = 'GET_INFLUENCERS_INFORMATION';
export const GET_FAVORITE_INFLUENCERS = 'GET_FAVORITE_INFLUENCERS';
export const ADD_TO_FAVORITE_LIST = 'ADD_TO_FAVORITE_LIST';
export const ADD_TO_FAVORITE = 'ADD_TO_FAVORITE';
export const GET_ALL_PROJECT_BY_USER_SUCCESS = 'GET_ALL_PROJECT_BY_USER_SUCCESS';
export const GET_MY_PROJECT_SUCCESS = 'GET_MY_PROJECT_SUCCESS';
export const GET_ALL_PROJECT_OF_USER_BY_TYPE = 'GET_ALL_PROJECT_OF_USER_BY_TYPE';
export const GET_ALL_NPOS = 'GET_ALL_NPOS';
export const GET_FAVORITE_NPOS = 'GET_FAVORITE_NPOS';
export const ADD_NPO_TO_FAVORITE = 'ADD_NPO_TO_FAVORITE';
export const ADD_TO_FAVORITE_NPOS_LIST = 'ADD_TO_FAVORITE_NPOS_LIST';

export const PROJECT_TYPE_PUBLIC_RECRUITMENT = 0;
export const PROJECT_TYPE_CANDIDACY = 1;
export const PROJECT_TYPE_NOMINATION = 2;
export const PROJECT_ALL_TYPE = -1;

export const PROJECT_LIST = 'PROJECT_LIST';
export const PROJECT_ADD_TO_FAVORITE = 'PROJECT_ADD_TO_FAVORITE';
export const PROJECT_REMOVE_FROM_FAVORITE = 'PROJECT_REMOVE_FROM_FAVORITE';
export const MY_PROJECT_DETAIL = 'MY_PROJECT_DETAIL';
export const PROJECT_TOP_5 = 'PROJECT_TOP_5';
export const PROJECT_DETAIL = 'PROJECT_DETAIL';
export const VALIDATOR_APPLY_TO_PROJECT = 'VALIDATOR_APPLY_TO_PROJECT';
export const APPLY_TO_PROJECT = 'APPLY_TO_PROJECT';
export const GET_PROJECTS_FOR_USER_INFLUENCER_FOR_MYPROJECT = 'GET_PROJECTS_FOR_USER_INFLUENCER_FOR_MYPROJECT';
export const GET_PROJECTS_FOR_USER_INFLUENCER_FOR_MYPAGE = 'GET_PROJECTS_FOR_USER_INFLUENCER_FOR_MYPAGE';
export const GET_INFLUENCERS_FOR_SEARCHING = 'GET_INFLUENCERS_FOR_SEARCHING';
export const GET_FAVORITE_INFLUENCERS_FOR_SEARCHING = 'GET_FAVORITE_INFLUENCERS_FOR_SEARCHING';
export const INFLUENCER_REMOVE_FROM_FAVORITE = 'INFLUENCER_REMOVE_FROM_FAVORITE';
// top 5 projects
export const MY_PROJECT_TOP5 = 'MY_PROJECT_TOP5';

export const MY_PROJECT_SAVE_TO_DRAFT = 'MY_PROJECT_SAVE_TO_DRAFT';

export const getAllProjectByUserId = (typeId = null) => (dispatch) => {
  let urlProject = 'my-project';
  let dispatchType = GET_ALL_PROJECT_BY_USER_SUCCESS;
  if (typeId !== null) {
    urlProject += `?type_id=${typeId}`;
    dispatchType = GET_ALL_PROJECT_OF_USER_BY_TYPE;
  }
  return API({
    method: 'GET',
    url: urlProject
  })
    .then((res) => {
      dispatch({
        type: dispatchType,
        payload: res
      });
    })
    .catch((error) => {
      return error;
    });
};

export const getMyProject = (typeId) => (dispatch) => {
  const endpoint = `my-project?type_id=${typeId}`;

  return API({
    method: 'GET',
    url: endpoint
  })
    .then((res) => {
      dispatch({
        type: GET_MY_PROJECT_SUCCESS,
        payload: res
      });
    })
    .catch((error) => {
      return error;
    });
};

const handleRequestCreateProject = (_requestForm) => {
  const requestForm = {
    ..._requestForm,
    cover_image: _requestForm.cover_image?.file,
    attached_files: _requestForm.attached_files
  };

  const formData = new FormData();
  requestForm.attached_files.forEach((attachedFile) => {
    formData.append('attached_files[]', attachedFile.file);
  });
  delete requestForm.attached_files;

  Object.keys(requestForm).forEach((key) => {
    formData.append(key, requestForm[key]);
  });

  return formData;
};

export const validateCreateProject = (_requestForm) => () => {
  const formData = handleRequestCreateProject(_requestForm);

  return API({
    method: 'POST',
    url: 'my-project/validate',
    data: formData
  });
};

export const createProject = (_requestForm) => () => {
  const formData = handleRequestCreateProject(_requestForm);

  return API({
    method: 'POST',
    url: 'my-project/create',
    data: formData
  });
};

const handleRequestEditProject = (_requestForm) => {
  const requestForm = {
    ..._requestForm,
    cover_image: _requestForm.cover_image?.file,
    attached_files: _requestForm.attached_files.filter((item) => typeof item.id === 'string'),
    old_attached_index: _requestForm.attached_files.map((item) => item.id).filter((id) => typeof id === 'number')
  };

  const formData = new FormData();
  requestForm.attached_files.forEach((attachedFile) => {
    formData.append('attached_files[]', attachedFile.file);
  });
  delete requestForm.attached_files;

  Object.keys(requestForm).forEach((key) => {
    formData.append(key, requestForm[key]);
  });

  return formData;
};

export const validateEditProject = (_requestForm) => () => {
  const formData = handleRequestEditProject(_requestForm);

  return API({
    method: 'POST',
    url: 'my-project/edit-validate',
    data: formData
  });
};

export const updateProject = (_requestForm) => () => {
  const requestForm = {
    ..._requestForm,
    cover_image: _requestForm.cover_image?.file,
    attached_files: _requestForm.attached_files.filter((item) => typeof item.id === 'string'),
    old_attached_index: _requestForm.attached_files.map((item) => item.id).filter((id) => typeof id === 'number')
  };

  const formData = new FormData();
  requestForm.attached_files.forEach((attachedFile) => {
    formData.append('attached_files[]', attachedFile.file);
  });
  delete requestForm.attached_files;

  Object.keys(requestForm).forEach((key) => {
    formData.append(key, requestForm[key]);
  });

  return API({
    method: 'POST',
    url: 'my-project/update',
    data: formData
  });
};

export const addInfluencerToFavorite = (userId, influencerId) => (dispatch) => {
  return API({
    method: 'POST',
    url: 'my-project/add-to-favorite',
    data: {
      user_id: userId,
      influencer_id: influencerId
    }
  })
    .then((res) => {
      dispatch({
        type: ADD_TO_FAVORITE,
        payload: res
      });
      return res;
    })
    .catch((error) => {
      return error;
    });
};

export const getNPOs = (npoCategoryId = '') => (dispatch) => {
  let urlSearch = 'npo/find';
  if (npoCategoryId != '' || npoCategoryId == 0) {
    urlSearch += `?npo_category_id=${npoCategoryId}`;
  }
  return API({
    method: 'GET',
    url: urlSearch
  })
    .then((res) => {
      dispatch({
        type: GET_ALL_NPOS,
        payload: res
      });
      return res;
    })
    .catch((error) => {
      return error;
    });
};

export const getFavoriteNpos = (currentPage) => (dispatch) => {
  return API({
    method: 'GET',
    url: `npo/favorites?page=${currentPage}`
  })
    .then((res) => {
      dispatch({
        type: GET_FAVORITE_NPOS,
        payload: res
      });
      return res;
    })
    .catch((error) => {
      return error;
    });
};
export const addNPOToFavorite = (npoId) => (dispatch) => {
  return API({
    method: 'POST',
    url: 'npo/add-to-favorite',
    data: {
      npo_id: npoId
    }
  })
    .then((res) => {
      dispatch({
        type: ADD_NPO_TO_FAVORITE,
        payload: res
      });
      return res;
    })
    .catch((error) => {
      return error;
    });
};

export const getProjects = (currentPage, filterItems) => (dispatch) => {
  let urlProject = `${store.getState().auth.user ? 'project' : 'project-public'}?page=${currentPage}`;

  if (filterItems?.user_type) {
    urlProject += `&user_type=${filterItems.user_type}`;
  }
  if (filterItems?.area_id?.toString()) {
    urlProject += `&area_id=${filterItems.area_id}`;
  }
  if (filterItems?.project_type?.toString()) {
    urlProject += `&project_type=${filterItems.project_type}`;
  }
  if (filterItems?.category_id?.toString()) {
    urlProject += `&category_id=${filterItems.category_id}`;
  }
  if (filterItems?.type_id?.toString()) {
    urlProject += `&type_id=${filterItems.type_id}`;
  }
  if (filterItems?.order) {
    urlProject += `&order=${filterItems.order}`;
  }

  return API({
    method: 'GET',
    url: urlProject
  })
    .then((res) => {
      dispatch({
        type: PROJECT_LIST,
        payload: res
      });
      return res;
    })
    .catch((error) => {
      return error;
    });
};

export const addProjectToFavorite = (id) => (dispatch) => {
  return API({
    method: 'POST',
    url: 'project/add-to-favorite',
    data: {
      project_id: id
    }
  })
    .then((res) => {
      dispatch({
        type: PROJECT_ADD_TO_FAVORITE,
        payload: res
      });
      return res;
    })
    .catch((error) => {
      return error;
    });
};
export const removeProjectFromFavorite = (id) => (dispatch) => {
  return API({
    method: 'DELETE',
    url: 'project/remove-from-favorite',
    data: {
      project_id: id
    }
  })
    .then((res) => {
      dispatch({
        type: PROJECT_REMOVE_FROM_FAVORITE,
        payload: res
      });
      return res;
    })
    .catch((error) => {
      return error;
    });
};

export const findMyProjectById = (id) => (dispatch) => {
  const urlProject = `my-project/${id}/detail`;

  return API({
    method: 'GET',
    url: urlProject
  })
    .then((res) => {
      dispatch({
        type: MY_PROJECT_DETAIL,
        payload: res
      });
      return res;
    })
    .catch((error) => {
      return error;
    });
};

export const getTop5Projects = () => (dispatch) => {
  const urlApi = 'get-five-project-latest';
  return API({
    method: 'GET',
    url: urlApi
  })
    .then((res) => {
      dispatch({
        type: PROJECT_TOP_5,
        payload: res
      });
      return res;
    })
    .catch((error) => {
      return error;
    });
};

export const getProjectDetail = (id) => (dispatch) => {
  const urlProject = `${store.getState().auth.user ? 'project' : 'project-detail'}/${id}`;

  return API({
    method: 'GET',
    url: urlProject
  })
    .then((res) => {
      dispatch({
        type: PROJECT_DETAIL,
        payload: res
      });
      return res;
    })
    .catch((error) => {
      return error;
    });
};

export const validatorApplyToProject = (id) => () => {
  const urlApi = `influencer/project/${id}/validator`;
  return API({
    method: 'POST',
    url: urlApi
  })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error;
    });
};

export const applyToProject = (id) => () => {
  const urlApi = `influencer/project/${id}/apply`;
  return API({
    method: 'POST',
    url: urlApi
  })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error;
    });
};

export const getTop5MyProjects = () => (dispatch) => {
  return API({
    method: 'GET',
    url: 'my-project/top-5'
  })
    .then((res) => {
      dispatch({
        type: MY_PROJECT_TOP5,
        payload: res
      });
    })
    .catch((error) => {
      return error;
    });
};

export const getProjectsForUserInfluencerForMyProject = (filterItems) => (dispatch) => {
  let urlProject = 'influencer/my-page?';

  if (filterItems?.type && filterItems?.type === 'is_favorited') {
    urlProject += 'is_favorited=true';
  } else if (filterItems?.type) {
    urlProject += `type=${filterItems.type}`;
  }
  return API({
    method: 'GET',
    url: urlProject
  })
    .then((res) => {
      dispatch({
        type: GET_PROJECTS_FOR_USER_INFLUENCER_FOR_MYPROJECT,
        payload: res
      });
    })
    .catch((error) => {
      return error;
    });
};

export const getProjectsForUserInfluencerForMyPage = (filterItems) => (dispatch) => {
  let urlProject = `influencer/my-page?is_top_3=${filterItems.is_top_3}`;

  if (filterItems?.is_favorited) {
    urlProject += `&is_favorited=${filterItems.is_favorited}`;
  }
  return API({
    method: 'GET',
    url: urlProject
  })
    .then((res) => {
      dispatch({
        type: GET_PROJECTS_FOR_USER_INFLUENCER_FOR_MYPAGE,
        payload: res
      });
    })
    .catch((error) => {
      return error;
    });
};

export const getInfluencerInformation = () => (dispatch) => {
  const url = 'influencer/detail';
  return API({
    method: 'GET',
    url
  })
    .then((res) => {
      dispatch({
        type: GET_INFLUENCERS_INFORMATION,
        payload: res
      });
      return res;
    })
    .catch((error) => {
      return error;
    });
};

export const findInfluencer = (influencerId) => () => {
  let urlProject = `my-project/influencer/${influencerId}`;
  urlProject += '?rel=userRank,tags,tiktokInfo,tiktokAuth,prefectures,influencerReward,tiktokLatestVideo';
  urlProject += '&relAttr=teddy_bear_rank,hiring_number,favorite_influencer_count';
  return API({
    method: 'GET',
    url: urlProject
  }).then((res) => {
    return res;
  });
};

export const getInfluencersForSearching = (currentPage, filterItems) => (dispatch) => {
  let urlSearch = `search-influencers?page=${currentPage}`;
  if (filterItems?.keyword) {
    urlSearch += `&keyword=${filterItems.keyword}`;
  }
  if (filterItems?.prefecture_ids?.toString()) {
    urlSearch += `&prefecture_ids=${filterItems.prefecture_ids}`;
  }
  if (filterItems?.tag_ids?.toString()) {
    urlSearch += `&tag_ids=${filterItems.tag_ids}`;
  }
  if (filterItems?.gender?.toString()) {
    urlSearch += `&gender=${filterItems.gender}`;
  }
  return API({
    method: 'GET',
    url: urlSearch
  })
    .then((res) => {
      dispatch({
        type: GET_INFLUENCERS_FOR_SEARCHING,
        payload: res
      });
      return res;
    })
    .catch((error) => {
      return error;
    });
};

export const getFavoriteInfluencersForSearching = (currentPage) => (dispatch) => {
  return API({
    method: 'GET',
    url: `get-favorites-influencers?page=${currentPage}`
  })
    .then((res) => {
      dispatch({
        type: GET_FAVORITE_INFLUENCERS_FOR_SEARCHING,
        payload: res
      });
      return res;
    })
    .catch((error) => {
      return error;
    });
};

export const removeUserFromFavorite = (id) => (dispatch) => {
  return API({
    method: 'DELETE',
    url: 'my-project/remove-from-favorite',
    data: {
      influencer_id: id
    }
  })
    .then((res) => {
      dispatch({
        type: INFLUENCER_REMOVE_FROM_FAVORITE,
        payload: res
      });
      return res;
    })
    .catch((error) => {
      return error;
    });
};

export const reportProjectCsv = (projectId) => () => {
  return API({
    method: 'GET',
    url: `project/report/${projectId}`
  })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      return error;
    });
};

export const saveProjectToDraft = (projectId) => (dispatch) => {
  return API({
    method: 'PUT',
    url: 'my-project/save-to-draft',
    data: {
      project_id: projectId
    }
  })
    .then((res) => {
      if (res.status == ResponseStatus.HTTP_OK) {
        dispatch({
          type: MY_PROJECT_SAVE_TO_DRAFT,
          payload: projectId
        });
      }
      return res;
    });
};

export const deleteDraftProject = (id) => () => {
  return API({
    method: 'DELETE',
    url: `my-project/${id}`
  });
};
