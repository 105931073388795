import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getAllType } from 'redux/client/type';
import { getMyProject, PROJECT_ALL_TYPE } from 'redux/client/project';
import { LoadingContext } from 'hooks/LoadingContext';
import useSession from 'hooks/useSession';
import { USER_TYPE_COMPANY } from 'redux/client/auth';
import { useLocation, useSearchParams } from 'react-router-dom';
import ProjectList from './components/ProjectList';
import { MyProjectFilter } from './components';

function index() {
  const { user, unreadMessages } = useSession();
  const userType = user?.user_type;
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const type = location.pathname.includes('/nominated') ? '2' : `${PROJECT_ALL_TYPE}`; // -1 -> get all
  const [paramsUrl, setParamsUrl] = useState({
    type_id: userType !== USER_TYPE_COMPANY ? '1' : type
  });

  const store = useSelector((state) => ({
    types: state.type.types,
    myProjects: state.project.myProjects
  }));

  useEffect(() => {
    if (store.types.length == 0) {
      dispatch(getAllType());
    }
  }, []);

  useEffect(() => {
    _getMyProject();
  }, [paramsUrl, unreadMessages]);

  const _getMyProject = async () => {
    await dispatch(getMyProject(paramsUrl.type_id));
    setIsLoading(false);
  };

  const onSubmitMyProjectFilter = (form) => {
    const tabActive = searchParams.get('tabActive');
    if (tabActive) {
      searchParams.delete('tabActive');
      setSearchParams(searchParams);
    }
    setIsLoading(true);
    setParamsUrl((preState) => ({
      ...preState,
      ...form
    }));
  };

  const NOMINATION_PROJECT_TYPE = 1;
  let typeData = store.types;
  if (store.types.length > 0 && userType !== USER_TYPE_COMPANY) {
    typeData = store.types.filter((item) => item.key === NOMINATION_PROJECT_TYPE);
  }

  return (
    <LoadingContext.Provider value={[isLoading]}>
      <div className="page-project">
        <div className="client-container">
          <div className="row section mb-5">
            <div className="header">
              <h2 className="title-bg">Project List</h2>
              <h2 className="title">プロジェクト一覧</h2>
            </div>
          </div>

          <MyProjectFilter typeData={typeData} initFormikValues={paramsUrl} onSubmit={onSubmitMyProjectFilter} />
          <ProjectList myProjects={store.myProjects} />
        </div>
      </div>
    </LoadingContext.Provider>
  );
}

export default index;
