import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import * as ROUTES from 'constants/routes';
import { ResponseStatusMessage } from 'constants/ResponseStatusMessage';
import { checkNumberParam } from 'helpers/utils';

import LoadingOverlay from 'components/loading/LoadingOverlay';
import { findUserCompanyDetail } from 'redux/admin/user';
import UserCompanyInfo from './components/UserCompanyInfo';
import UserCompanyStatistics from './components/UserCompanyStatistics';
import UserCompanyPointStatistics from './components/UserCompanyPointStatistics';
import UserCompanyReviews from './components/UserCompanyReviews';
import UserCompanyProjects from './components/UserCompanyProjects';
import UserCompanyPurchasePoints from './components/UserCompanyPurchasePoints';

function UserCompanyDetail() {
  const param = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [userCompany, setUserCompany] = useState(null);

  const _findUserCompanyDetail = async () => {
    if (!checkNumberParam(param.id)) {
      navigate(ROUTES.ERROR_404_PAGE, {
        replace: true,
        state: {
          error_code: 404,
          error_msg: ResponseStatusMessage[404]
        }
      });
    }

    try {
      const res = await dispatch(findUserCompanyDetail(param.id));
      if (res.data.length < 1) {
        navigate(ROUTES.ERROR_404_PAGE, {
          replace: true,
          state: {
            error_code: 404,
            error_msg: ResponseStatusMessage[404]
          }
        });
      }
      setUserCompany(res.data);
      setIsLoading(true);
      return true;
    } catch (error) {
      setIsLoading(false);
      navigate(ROUTES.ERROR_PAGE, {
        replace: true,
        state: {
          error_code: error.response?.status,
          error_msg: ResponseStatusMessage[error.response?.status]
        }
      });
      return error;
    }
  };

  useEffect(() => {
    _findUserCompanyDetail();
  }, []);

  return (
    <div className="client-cpanel user-detail">
      {!isLoading ? (
        <div className="text-color-green">
          <LoadingOverlay />
        </div>
      ) : (
        <>
          <section className="section-identity-verify-nda">
            {userCompany?.identity_verified_count > 0 && (
              <span className="admin-btn-pink border border-pink mx-sm-2 rounded-pill">
                <strong>本人確認済み</strong>
              </span>
            )}

            {userCompany?.nda_conclusion_count > 0 && (
              <span className="admin-btn-pink border border-pink mx-sm-2 rounded-pill">
                <strong>NDA締結済み</strong>
              </span>
            )}
          </section>
          <UserCompanyInfo userCompany={userCompany} />
          <UserCompanyPointStatistics userCompany={userCompany} />
          <UserCompanyReviews userCompany={userCompany} />
          <UserCompanyStatistics userCompany={userCompany} />
        </>
      )}
      <UserCompanyProjects />
      <UserCompanyPurchasePoints />
    </div>
  );
}

export default UserCompanyDetail;
