import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { LoadingContext } from 'hooks/LoadingContext';
import * as ROUTES from 'constants/routes';
import { toMemberPage } from 'helpers/utils';
import useSession from 'hooks/useSession';
import ProjectItem from './ProjectItem';

function ProjectInfluencerFavoriteList({ myProjects }) {
  const [isLoading] = useContext(LoadingContext);
  const { user } = useSession();

  return (
    <div className="section row mt-4">
      {isLoading && [...Array(20)].map((e, i) => <ProjectItem key={`${i.toString()}`} />)}
      {myProjects?.items?.length === 0 && !isLoading && <p>見つかりません。</p>}
      {myProjects?.items && !isLoading && (
        <>
          {myProjects.items.map((project) => {
            const item = project;
            item.not_show_messages = 1;
            return (
              <ProjectItem
                project={item}
                isShow={false}
                projectUrl={toMemberPage(user?.user_type, ROUTES.MY_PROJECT_DETAIL.replace(':id', item.id))}
                key={item.id}
              />
            );
          })}
        </>
      )}
    </div>
  );
}
ProjectInfluencerFavoriteList.propTypes = {
  myProjects: PropTypes.object.isRequired
};
export default ProjectInfluencerFavoriteList;
