import { applyMiddleware, combineReducers, createStore } from 'redux';
import ReduxThunk from 'redux-thunk';
import { persistStore } from 'redux-persist';
import clientReducer from 'redux/client';
import adminReducer from 'redux/admin';

const rootReducer = { ...clientReducer, ...adminReducer };
const store = createStore(combineReducers(rootReducer), applyMiddleware(ReduxThunk));

const persistor = persistStore(store);

export { store, persistor };
