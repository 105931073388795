import {
  GET_INVITE_CODE,
  CREATE_INVITE_CODE_SUCCESS
} from './invitationAction';

const initialState = {
  userInfluencer: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_INVITE_CODE: {
      return {
        ...state,
        userInfluencer: action.payload.data
      };
    }
    case CREATE_INVITE_CODE_SUCCESS: {
      return {
        ...state,
        userInfluencer: action.payload.data
      };
    }

    default:
      return state;
  }
};
