import React from 'react';

function TermsInfluencer() {
  return (
    <div className="container">
      <h1 className="text-center py-5">ENMA利用規約</h1>

      <div className="content_wrapper">
        <h3>第1条（目的）</h3>
        <ol>
          <li>
            この利用規約（以下「本規約」といいます。）は、株式会社アメージング（以下「当社」といいます。）が運営する「ENMA」（以下「本サービス」といいます。）を利用するインフルエンサー会員（以下「会員」といいます。）
            に適用されます。会員は、本規約に同意の上、本サービスを利用します。
          </li>
          <li>
            本規約は、本サービスの利用条件を定めています。本サービスに会員登録した会員は全て本規約に従い、年齢や利用環境等の条件に応じて、本規約の定める条件に従って本サービスを利用します。
          </li>
          <li>
            会員が本規約に同意することにより、当社との間に本契約（第2条で定義します。）が成立します。
          </li>
        </ol>
        <h3>第2条（定義）</h3>
        <p>本規約において使用する以下の用語は、以下の各号に定める意味を有します。</p>
        <ol className="amazing-number">
          <li>
            「本契約」：本規約を契約条件として当社及び会員との間で締結される、本サービスの利用契約を指します。
          </li>
          <li>
            「会員」：本サービスにインフルエンサーとして登録をしている方を指します。
          </li>
          <li>
            「会員情報」：本サービスに登録した会員のID及びパスワードを指します。
          </li>
          <li>
            「広告主」：本サービスを利用して会員に対し、プロジェクトの実施を依頼し、または依頼しようとする事業者を指します。
          </li>
          <li>
            「商品等」：広告主が会員に対してPRを依頼する商品またはサービスを指します。
          </li>
          <li>
            「プロジェクト」：広告主が会員に対して商品等のPRを依頼する案件を指します。
          </li>
          <li>
            「プロジェクト情報」：広告主が本サービスにおいて掲載したプロジェクトの情報を指します。
          </li>
          <li>
            「TikTok」：ByteDanceが運営するSNS（
            <a
              href="https://www.tiktok.com/ja-JP/"
              target="_blank"
              rel="noreferrer"
              className="link-dark text-decoration-none"
            >
              https://www.tiktok.com/ja-JP/
            </a>
            ）を指します。
          </li>
          <li>
            「通信機器」： スマートフォン、タブレット端末及びコンピューター機器を指します。
          </li>
        </ol>
        <h3>第3条（会員登録）</h3>
        <ol>
          <li>本サービスの会員になろうとする方は、TikTokにおいてアカウントを有していることが条件となります。</li>
          <li>本サービスの会員になろうとする方は、本規約の内容に同意の上、当社が定める手続きにより会員登録を行います。</li>
          <li>会員登録しようとする者が18歳未満の場合、事前に法定代理人の同意を得て登録を行うものとします。</li>
          <li>会員は、前項に基づき登録した情報に変更が発生した場合、直ちに、登録情報の変更手続を行う義務を負います。</li>
          <li>当社の裁量により、会員登録を拒否する場合があります。</li>
          <li>会員は、本サービス上のアカウントを第三者に対して利用、貸与、譲渡、売買又は質入等をすることはできません。</li>
        </ol>
        <h3>第4条（本サービスの内容）</h3>
        <ol>
          <li>本サービスは、広告主に対して、TikTokにおいて商品等のPRを行う会員とのマッチングを支援するとともに、利用料金の一部をOTASUKEへ寄付するサービスです。</li>
          <li>本サービスにおいて、当社は、会員に対して以下のサービスを提供します。</li>
          <ol className="amazing-number">
            <li>広告主において会員を募集しているプロジェクトに関する情報の掲載</li>
            <li>上記(1)について、会員のプロジェクトへの申込み又はプロジェクト実施に関するサポート</li>
            <li>広告主から会員に対して提供する商品等にかかる発送代行</li>
            <li>その他上記各号に付随するサービス</li>
          </ol>
        </ol>
        <h3>第5条（本サービスの契約関係）</h3>
        <ol>
          <li>本サービスの契約は、広告主と会員との間で直接成立するものとし、当社は広告主と会員との間の契約関係の成立を仲介するのみとなります。</li>
          <li>広告主と会員との契約関係は、本サービスが提示する標準的な取引条件に準拠するものとします。</li>
        </ol>
        <h3>第6条（プロジェクトの実施）</h3>
        <ol>
          <li>会員は、広告主からの依頼内容を、本サービスのプロジェクト情報に記載された条件又は注意事項を遵守の上、実施するものとします。</li>
          <li>会員は、広告主からの依頼内容に反して、商品等のPRを行ってはならないものとします。</li>
          <li>会員は、本サービス上のチャットルームにおいてインフルエンサーと商品等の発送及びプロジェクトの実施に関する連絡を行うものとします。</li>
          <li>当社は、会員が本契約に違反した場合、または当社の裁量により、プロジェクトの実施を中止することができます。</li>
        </ol>
        <h3>第7条（投稿コンテンツ）</h3>
        <ol>
          <li>
            会員は、プロジェクトを実施した広告主において、会員がTikTokに商品等のPRを投稿した画像及び動画（以下「投稿コンテンツ」といいます。）
            の二次利用を行う場合があることについて同意し、プロジェクトを実施した広告主に対し、予め、投稿コンテンツについて
            の非独占的かつ無償による、使用、複製、公衆送信、頒布、翻訳・翻案等に関するライセンスを付与するものとします。
          </li>
          <li>会員は、投稿コンテンツの投稿から少なくとも2週間は投稿コンテンツを削除しないものとします。</li>
          <li>前項にかかわらず、会員は、広告主から投稿コンテンツの削除の依頼があった場合、投稿コンテンツを削除するものとします。</li>
          <li>会員は、前項において、当社及びプロジェクトを実施した広告主に対し、著作者人格権を行使しないことに同意するものとします。</li>
          <li>会員は、投稿コンテンツをTikTokに投稿する際、商品等が実際のものより優良または有利であると誤認させる表現をしてはならないものとします。</li>
          <li>会員は、投稿コンテンツをTikTokに投稿する際、広告またはPRであることがわかるハッシュタグによる表記を行うものとします。</li>
        </ol>
        <h3>第8条（報酬の支払い）</h3>
        <ol>
          <li>本サービスの報酬は、金銭に換価できるポイントの付与によって支払われます。</li>
          <li>報酬のポイント数は、プロジェクト毎に異なります。</li>
          <li>
            会員がTikTokに投稿コンテンツを投稿した際に報酬の30%のポイントが付与され、投稿コンテンツ
            の投稿から2週間後に報酬の70%のポイントが付与されます。ただし、広告主が投稿コンテンツの削除を依頼した場合、投稿コンテンツを削除した時点で70％のポイントが付与されるものとします。
          </li>
          <li>ポイントは、付与された月の翌月末日から金銭に換価することができます。</li>
          <li>ポイントの金銭への換価は、当社所定の出金手数料が発生します。</li>
          <li>会員に付与されたポイントは、180日の経過により自動的に失効します。</li>
        </ol>
        <h3>第9条（会員情報及び通信機器に関する管理）</h3>
        <ol>
          <li>
            会員は、本サービスの提供を受けるために必要な機器、通信手段及び交通手段等の環境を全て自らの費用と責任で備えます。また、本サービスの利用にあたり必要となる通信費用は、全て会員の負担とします。
          </li>
          <li>
            会員は、会員情報及び通信機器の管理責任を負います。会員情報及び通信機器の管理不十分、使用上の過誤、第三者の使用等による損害の責任は会員が負い、当社は当社に故意又は過失のない限り一切の責任を負いません。
          </li>
          <li>
            会員は、会員情報又は通信機器を第三者に使用されるおそれのある場合は、直ちに当社にその旨を連絡するとともに、当社の指示がある場合はこれに従います。
          </li>
        </ol>
        <h3>第10条（本サービスの提供条件）</h3>
        <p>
          当社は、メンテナンス等のために、会員に通知することなく、本サービスを停止又は変更することがあります。
        </p>
        <h3>第11条（知的財産権等）</h3>
        <ol>
          <li>
            会員の投稿コンテンツに関して、著作物性の有無を問わず、掲載内容の一部又は全部に関し、発生しうる全て
            の著作権（著作権法第27条及び第28条に定める権利を含みます。）について、目的を問わず、無償かつ無制限に利用できる権利を広告主に対して許諾することについて同意します。
          </li>
          <li>
            会員は、方法又は形態の如何を問わず、本サービスにおいて提供される全ての情報及びコンテンツ（以下総称して「当社コンテンツ」といいます。）
            を著作権法に定める、私的使用の範囲を超えて複製、転載、公衆送信、改変その他の利用をすることはできません。
          </li>
        </ol>
        <h3>第12条（禁止事項）</h3>
        <ol>
          <li>当社は、会員による本サービスの利用に際して、以下の各号に定める行為を禁止します。
            <ol className="amazing-number">
              <li>本規約に違反する行為</li>
              <li>本サービスを通じて接触した広告主との間で、本サービスの利用によることなくやり取りをする行為、または広告・宣伝に関する契約を含む一切の取引を行い、又は当該取引を受諾する行為</li>
              <li>広告主の商品等に関して事実と異なる内容を投稿する行為</li>
              <li>広告主からの依頼内容並びに当社サービス上で表示した条件又は注意事項に違反する行為</li>
              <li>インフルエンサーが一般に公開していない個人事項又は業務上の事項を公表し又はインフルエンサーマーケティング以外の目的に利用する行為</li>
              <li>景品表示法、医薬品、医療機器等の品質、有効性及び安全性の確保等に関する法律、医療法、金融商品取引法、特定商取引法その他一切の法令に反する行為</li>
              <li>TikTokの利用規約で禁止されている行為</li>
              <li>本サービスを通して入手した広告主の秘密情報を第三者に提供する行為</li>
              <li>
                当社、当社がライセンスを受けているライセンサーその他第三者の知的財産権、特許権、実用新案権、意匠権、商標権、著作権、
                肖像権等の財産的又は人格的な権利を侵害する行為又はこれらを侵害する恐れのある行為
              </li>
              <li>当社又は第三者に不利益若しくは損害を与える行為又はその恐れのある行為</li>
              <li>不当に他人の名誉や権利、信用を傷つける行為又はその恐れのある行為</li>
              <li>法令又は条例等に違反する行為</li>
              <li>公序良俗に反する行為若しくはその恐れのある行為又は公序良俗に反する恐れのある情報を掲載する行為</li>
              <li>犯罪行為、犯罪行為に結びつく行為若しくはこれを助長する行為又はその恐れのある行為</li>
              <li>
                当社のシステムへの不正アクセス、それに伴うプログラムコードの改ざん、
                位置情報の改ざん、故意に虚偽、通信機器の仕様その他アプリケーションを利用してのチート行為、コンピューターウィルスの頒布その他本サービスの正常な運営を妨げる行為又はその恐れのある行為
              </li>
              <li>マクロ及び操作を自動化する機能やツール等を使用する行為</li>
              <li>本サービスの信用を損なう行為又はその恐れのある行為</li>
              <li>青少年の心身及びその健全な育成に悪影響を及ぼす恐れのある行為</li>
              <li>他の会員のアカウントの使用その他の方法により、第三者になりすまして本サービスを利用する行為</li>
              <li>同一の個人が重複して会員登録を行う行為</li>
              <li>詐欺、預貯金口座及び携帯電話の違法な売買等の犯罪に結びつく又は結びつく恐れのある行為</li>
              <li>犯罪収益に関する行為、テロ資金供与に関する行為又はその疑いがある行為</li>
              <li>その他当社が不適当と判断する行為</li>
            </ol>
          </li>
          <li>
            当社は、会員の行為が、第１項各号のいずれかに該当すると判断した場合、事前に通知することなく、以下の各号のいずれか又は全ての措置を講じることができます。
            <ol className="amazing-number">
              <li>本サービスの利用制限</li>
              <li>本契約の解除による退会処分（この場合、付与されたポイントは金銭に換価することができません。）</li>
              <li>その他当社が必要と合理的に判断する行為</li>
            </ol>
          </li>
        </ol>
        <h3>第13条（解除）</h3>
        <ol>
          <li>
            当社は、会員が以下の各号のいずれかに該当した場合、何らの通知等を要することなく、本契約を解除し、退会させることができます。
            <ol className="amazing-number">
              <li>登録情報に虚偽の情報が含まれている場合</li>
              <li>前条第1項の禁止事項に該当する行為を行った場合</li>
              <li>過去に当社から退会処分を受けていた場合</li>
              <li>当社からの要請に対し誠実に対応しない場合</li>
              <li>その他当社が不適当と判断した場合</li>
            </ol>
          </li>
          <li>
            前項各号に掲げる場合のほか、当社は、会員に対して30日前までに事前に通知することにより、本契約を解除し、
            退会させることができます。また、会員が退会を希望する場合、当社が定める退会手続により、当月末日をもって本契約を解除し、退会することができます。
          </li>
          <li>
            第1項及び第2項の措置により退会した会員は、退会時に期限の利益を喪失し、直ちに、当社に対し負担する全ての債務を履行します。
          </li>
        </ol>
        <h3>第14条（非保証・免責）</h3>
        <ol>
          <li>会員が登録情報の変更を行わなかったことにより損害を被った場合でも、当社は一切の責任を負いません。</li>
          <li>会員は、法令の範囲内で本サービスをご利用ください。本サービスの利用に関連して会員が日本又は外国の法令に触れた場合でも、当社は一切の責任を負いません。</li>
          <li>
            予期しない不正アクセス等の行為によって会員情報を盗取された場合でも、それによって生じる会員の損害等に対して、当社は一切の責任を負いません。
          </li>
          <li>
            当社は、天災、地変、火災、ストライキ、通商停止、戦争、内乱、感染症の流行その他の不可抗力により本契約の全部又は一部に不履行が発生した場合、一切の責任を負いません。
          </li>
          <li>
            本サービスの利用に関し、会員が広告主または他の会員との間でトラブル（本サービス内外を問いません。）
            になった場合でも、当社は一切の責任を負わず、広告主間または会員間のトラブルは、当該会員が自らの費用と負担において解決します。
          </li>
          <li>
            会員は、本サービスを通じて報酬を受領した場合、自らの責任において確定申告等の税務上の対応を行うものとします。
          </li>
        </ol>
        <h3>第15条（損害賠償責任）</h3>
        <ol>
          <li>会員は、本規約の違反又は本サービスの利用に関連して当社に損害を与えた場合、当社に発生した損害（逸失利益及び弁護士費用を含みます。）を賠償します。</li>
          <li>
            当社は、当社の帰責事由により会員に損害を与えた場合、当社に故意又は重過失のない限り、
            本サービスに関連して当該会員が被った損害につき当社は一切の責任を負いません。なお、当社が損害を賠償する場合は、損害発生日から起算して過去1年間に支払った報酬額を上限とします。
          </li>
          <li>
            会員は、本サービスの利用により他の第三者との間で紛争が生じた場合、あるいは第三者に対して損害を与えた場合は、自己の費用と責任で損害を賠償し又は紛争を解決するものとし、
            当社及び広告主に対し迷惑をかけたり、損害を与えたりしないものとします。
          </li>
        </ol>
        <h3>第16条（本サービスの廃止）</h3>
        <ol>
          <li>
            当社は、当社が本サービスの提供を廃止すべきと合理的に判断した場合、本サービスの提供を廃止できます。
          </li>
          <li>
            前項の場合、当社に故意又は重過失がある場合を除き、当社は一切の責任を負いません。
          </li>
        </ol>
        <h3>第17条（秘密保持）</h3>
        <ol>
          <li>
            会員及び当社は、本サービスの提供に関して知り得た相手方の秘密情報（本サービスに関するノウハウ、当社のシステムに関する情報、技術上又は営業上の一切の秘密情報を含みます。）
            を、厳重かつ適正に管理するものとし、相手方の事前の書面による同意なく第三者（当社の関連会社及び委託先を含みます。）に開示、提供及び漏洩しないものとします。
          </li>
          <li>
            次の各号の情報は、秘密情報に該当しないものとします。
            <ol className="amazing-number">
              <li>開示を受けた時、既に所有していた情報</li>
              <li>開示を受けた時、既に公知であった情報又はその後自己の責に帰さない事由により公知となった情報</li>
              <li>開示を受けた後に、第三者から合法的に取得した情報</li>
              <li>開示された秘密情報によらず独自に開発し又は創作した情報</li>
              <li>法令の定め又は裁判所の命令に基づき開示を要請された情報</li>
            </ol>
          </li>
          <li>
            会員及び当社は、相手方の指示があった場合又は本契約が終了した場合は、相手方の指示に従い速やかに秘密情報を、原状に回復した上で返却又は廃棄し、以後使用しないものとします。
          </li>
          <li>
            当社は、会員の同意を得て当社の関連会社又は委託先に会員の秘密情報を開示した場合、当該関連会社及び委託先の当該秘密情報の取扱いについて一切の責任を負いません。
          </li>
          <li>
            当社は、本サービスを提供する目的のために、会員の秘密情報を利用することができます。
          </li>
        </ol>
        <h3>第18条（反社会的勢力の排除）</h3>
        <ol>
          <li>
            会員及び当社は、現在、暴力団、暴力団員、暴力団員でなくなった時から5年を経過しない者、暴力団準構成員、暴力団関係企業、総会屋等、社会運動等標ぼうゴロ又は特殊知能暴力集団等、
            その他これらに準ずる者（以下総称して「暴力団員等」といいます。）に該当しないこと、及び次の各号のいずれにも該当しないことを表明し、かつ将来にわたっても該当しないことを保証します。
            <ol className="amazing-number">
              <li>暴力団員等が経営を支配していると認められる関係を有すること</li>
              <li>暴力団員等が経営に実質的に関与していると認められる関係を有すること</li>
              <li>
                自己、自社若しくは第三者の不正の利益を図る目的又は第三者に損害を加える目的をもってする等、不当に暴力団員等を利用していると認められる関係を有すること
              </li>
              <li>
                暴力団員等に対して資金等を提供し、又は便宜を供与する等の関与をしていると認められる関係を有すること
              </li>
              <li>
                役員又は経営に実質的に関与している者が暴力団員等と社会的に非難されるべき関係を有すること
              </li>
            </ol>
          </li>
          <li>
            会員及び当社は、自ら又は第三者を利用して次の各号のいずれかに該当する行為を行わないことを確約します。
            <ol className="amazing-number">
              <li>暴力的な要求行為</li>
              <li>法的な責任を超えた不当な要求行為</li>
              <li>
                取引に関して、脅迫的な言動をし、又は暴力を用いる行為
              </li>
              <li>
                風説を流布し、偽計を用い又は威力を用いて相手方の信用を毀損し、又は相手方の業務を妨害する行為
              </li>
              <li>
                その他前各号に準ずる行為
              </li>
            </ol>
          </li>
          <li>
            会員及び当社は、相手方が、暴力団員等若しくは第1項各号のいずれかに該当し、若しくは前項各号のいずれかに該当する行為をし、
            又は第1項の規定にもとづく表明・保証に関して虚偽の申告をしたことが判明した場合には、自己の責に帰すべき事由の有無を問わず、相手方に対して何らの催告をすることなく本契約を解除することができます。
          </li>
          <li>
            会員及び当社は、前項により本契約を解除した場合、相手方に損害が生じたとしてもこれを一切賠償する責任はないことを確認し、これを了承します。
          </li>
        </ol>
        <h3>第19条（地位の譲渡等）</h3>
        <p>
          会員及び当社は、相手方の書面による事前の承諾なく、本契約上の地位又は本規約に基づく権利若しくは義務の全部又は一部につき、第三者に対し、譲渡、移転、担保設定、その他の処分をすることはできません。
          但し、株式譲渡若しくは事業譲渡又は合併、会社分割その他の組織再編についてはこの限りではありません。
        </p>
        <h3>第20条（個人情報の取り扱い）</h3>
        <p>
          本サービスにおける個人情報の取り扱いに関しては、当社が定める「プライバシーポリシー」に基づき取り扱います。
        </p>
        <h3>第21条（分離可能性）</h3>
        <ol>
          <li>
            本規約の規定の一部が法令に基づいて無効と判断されても、本規約の他の規定は有効とします。
          </li>
          <li>
            本規約の規定の一部がある会員との関係で無効又は取消となった場合でも、本規約は他の会員との関係では有効とします。
          </li>
        </ol>
        <h3>第22条（違反行為への対処方法）</h3>
        <ol>
          <li>
            会員は、本規約に違反する行為を発見した場合は、当社にご連絡ください。
          </li>
          <li>
            会員は、本規約に違反する行為への当社の対処について、異議を申し立てることはできません。
          </li>
        </ol>
        <h3>第23条（本契約の有効期間）</h3>
        <p>
          本契約の有効期間は、本契約成立時から会員が退会するまでの間とします。なお、第11条、第12条、第13条第3項、第14条から第16条、第18条第3項及び第4項、第20条、第22条、本条、
          第25条及び第26条の規定は、本契約の終了後も有効に存続するものとします。
        </p>
        <h3>第24条（本規約の変更）</h3>
        <ol>
          <li>
            当社は、以下の各号のいずれかに該当する場合は、民法第548条の4の規定に基づき本規約を随時変更できます。本規約が変更された後の本契約は、変更後の本規約が適用されます。
            <ol className="amazing-number">
              <li>
                本規約の変更が、会員の一般の利益に適合するとき
              </li>
              <li>
                本規約の変更が、契約をした目的に反せず、かつ、変更の必要性、変更後の内容の相当性及びその内容その他の変更に係る事情に照らして合理的なものであるとき
              </li>
            </ol>
          </li>
          <li>
            当社は、本規約の変更を行う場合は、変更後の本規約の効力発生時期を定め、効力発生時期の2週間前までに、変更後の本規約の内容及び効力発生時期を会員に通知、
            本サービス上への表示その他当社所定の方法により会員に周知します。
          </li>
          <li>
            前二項の規定にかかわらず、前項の本規約の変更の周知後に会員が本サービスを利用した場合又は当社所定の期間内に会員が解約の手続をとらなかった場合、当該会員は本規約の変更に同意したものとします。
          </li>
        </ol>
        <h3>第25条（準拠法）</h3>
        <p>
          本規約に関する準拠法は、全て日本国の法令が適用されます。
        </p>
        <h3>第26条（合意管轄）</h3>
        <p>
          会員と当社との間における一切の訴訟は、東京地方裁判所を第一審の専属的合意管轄裁判所とします。
        </p>
        <h3>第27条（その他）</h3>
        <ol>
          <li>
            会員は、本規約に定めのない事項について、当社が細目等を別途定めた場合、これに従います。この場合、当該細目等は、本規約と一体をなします。
          </li>
          <li>
            細目等は、当社所定の箇所に掲載した時点より効力を生じます。
          </li>
          <li>
            細目等と本規約の内容に矛盾抵触がある場合、本規約が優先します。
          </li>
        </ol>
        <p className="mt-5">付則</p>
        <p className="mt-2 mb-0 pb-5">2023年3月31日：制定・施行</p>
      </div>
    </div>
  );
}

export default TermsInfluencer;
