import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { newsTypeData } from 'data/newsTypeData';
import { displayAtData } from 'data/displayAtData';
import { getNews } from 'redux/admin/news';
import Skeleton from 'react-loading-skeleton';
import Pagination from 'components/admin/Pagination/Pagination';
import NewsFilter from './components/NewsFilter';
import NewsAddOrEditModal from './components/NewsAddOrEditModal';
import NewsRow from './components/NewsRow';
import NewsDeleteConfirmModal from './components/NewsDeleteConfirmModal';

function index() {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [requestParamsFilter, setRequestParamsFilter] = useState([]);
  const [remountPagingComponent, setRemountPagingComponent] = useState(0);
  const [isAddOrEditModalShow, setIsAddOrEditModalShow] = useState(false);
  const [isDeleteModalShow, setIsDeleteModalShow] = useState(false);
  const [popupData, setPopupData] = useState({});
  const [newsId, setNewsId] = useState(0);

  const store = useSelector((state) => ({
    news: state.newsAdmin.news
  }));

  useEffect(() => {
    _getNews();
  }, []);

  const _getNews = async (page = 1, requestParams = []) => {
    setIsLoading(true);
    try {
      await dispatch(getNews(page, requestParams));
      setIsLoading(false);
      return true;
    } catch (error) {
      setIsLoading(false);
      return error;
    }
  };

  const onSubmitFilterForm = async (form) => {
    if (isLoading) {
      return;
    }
    setRemountPagingComponent(Math.random());
    setRequestParamsFilter(form);
    _getNews(1, form);
  };

  const onPagingClick = (event) => {
    _getNews(event.selected + 1, requestParamsFilter);
  };

  const addOrEditModalHandle = (item = null) => {
    setIsAddOrEditModalShow(true);
    setPopupData(item);
  };

  const deleteModalHandle = (id) => {
    setIsDeleteModalShow(true);
    setNewsId(id);
  };

  return (
    <>
      <NewsAddOrEditModal
        isAddOrEditModalShow={isAddOrEditModalShow}
        setIsAddOrEditModalShow={setIsAddOrEditModalShow}
        data={popupData}
        newsTypeData={newsTypeData}
        displayAtData={displayAtData}
        requestParamsFilter={requestParamsFilter}
        setRemountPagingComponent={setRemountPagingComponent}
      />
      <NewsDeleteConfirmModal
        isDeleteModalShow={isDeleteModalShow}
        setIsDeleteModalShow={setIsDeleteModalShow}
        newsId={newsId}
        requestParamsFilter={requestParamsFilter}
        setRemountPagingComponent={setRemountPagingComponent}
      />
      <NewsFilter
        newsTypeData={newsTypeData}
        setIsLoading={setIsLoading}
        isLoading={isLoading}
        onSubmitFilterForm={onSubmitFilterForm}
        onAddClick={() => addOrEditModalHandle()}
      />
      <div className="table-responsive mt-5">
        <table className="table table-lg table-hover admin-table-custom-1">
          {(!isLoading && store.news?.total < 1) && <caption className="text-center py-5 h5">見つかりません。</caption>}
          <thead className="border-top border-2">
            <tr>
              <th>タイトル</th>
              <th width="10%">区分</th>
              <th width="15%">公開日時</th>
              <th width="5%">作成日</th>
              <th width="4%">&nbsp;</th>
              <th width="4%">&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            {isLoading && [...Array(30)].map((e, i) => (
              <tr key={`${i.toString()}`}>
                <td>
                  <Skeleton height={17} />
                </td>
                <td>
                  <Skeleton height={17} />
                </td>
                <td>
                  <Skeleton height={17} />
                </td>
                <td>
                  <Skeleton height={17} />
                </td>
                <td>
                  <Skeleton height={17} />
                </td>
                <td>
                  <Skeleton height={17} />
                </td>
              </tr>
            ))}
            {(!isLoading && store.news?.data)
              && store.news.data.map((item) => (
                <NewsRow
                  key={item.id}
                  newsItem={item}
                  onEditClick={() => addOrEditModalHandle(item)}
                  onDeleteClick={() => deleteModalHandle(item.id)}
                />
              ))}
          </tbody>
        </table>
      </div>
      {store.news?.last_page > 1 && (
        <div className="d-flex justify-content-center my-5">
          <Pagination
            className="pagination admin-pagination-custom-1"
            key={remountPagingComponent}
            pageCount={store.news?.last_page}
            onPageChange={onPagingClick}
          />
        </div>
      )}
    </>
  );
}

export default index;
