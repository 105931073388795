import React, { useState } from 'react';
import DefaultAvatarImg from 'assets/image.png';
import HeartFill from 'assets/svg/heart-fill.svg';
import Heart from 'assets/svg/heart.svg';
import PropTypes from 'prop-types';
import constants from 'constants/constants';
import RankSystem from 'components/common/RankSystem';
import useSession from 'hooks/useSession';
import { requestInfluencerLogin } from 'redux/client/user';
import { ResponseStatus, ResponseStatusMessage } from 'constants';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as ROUTES from 'constants/routes';
import { OverlayTrigger, Spinner, Tooltip } from 'react-bootstrap';
import { QuestionCircleFill } from 'react-bootstrap-icons';

function InfluencerItem({
  influencer,
  addToFavorite,
  removeFromFavorite,
  tempFavorite,
  isProcessing,
  isDisableNomination,
  handleNomination,
  handleDetail,
  setIsShowModalSendMail,
  influencerSendMail,
  addInfluencerSendMail
}) {
  const { isPurchasedPoints } = useSession();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoadingSendMail, setIsLoadingSendMail] = useState(false);


  const handleRequestInfluencerLogin = async (userIdInfluencer) => {
    setIsLoadingSendMail(true);
    const res = await dispatch(requestInfluencerLogin(userIdInfluencer));
    if (res && res.status == ResponseStatus.HTTP_UNPROCESSABLE_ENTITY) {
      return;
    }

    if (res.status != ResponseStatus.HTTP_OK) {
      navigate(ROUTES.ERROR_PAGE, {
        replace: true,
        state: {
          error_code: res.status,
          error_msg: ResponseStatusMessage[res.status]
        }
      });
    }
    addInfluencerSendMail(userIdInfluencer);
    setIsShowModalSendMail(true);
    setIsLoadingSendMail(false);
  };

  return (
    <div
      className="mb-4 col-lg-4 col-6 influencer-search-item"
      key={`influencer_${influencer.id}`}
    >
      <div className="card-galaxy create-project-search-influencer">
        <div className={
          `card-galaxy-body justify-content-md-center col-lg-12 ${influencer.is_update_reward ? '' : 'bg-gray'}`
          }
        >
          <div className="influencer-item-info">
            <div className="avatar position-relative">
              <img
                className="rounded-circle"
                width={180}
                height={180}
                src={influencer.tiktok_info?.link_avatar_image || DefaultAvatarImg}
                alt=""
              />
              <span className="mt-0 position-absolute" role="button">

                <button
                  type="button"
                  className="border-0 bg-transparent"
                  disabled={isProcessing}
                  onClick={() => (tempFavorite && tempFavorite[influencer.id]
                    ? removeFromFavorite(influencer) : addToFavorite(influencer))}
                >
                  {(tempFavorite && tempFavorite[influencer.id]) ? (
                    <img src={HeartFill} alt="" />
                  ) : (
                    <img src={Heart} alt="" />
                  )}

                </button>
              </span>
            </div>
            <button type="button" className="btn btn-link btn-remove-css w-100" onClick={handleDetail}>
              <p className="item-username text-truncate pb-1 pt-1 mt-3">@{influencer.tiktok_info?.username}</p>
            </button>

            <div className="item-sub-item">
              <ul className="list-inline rank-verify mb-0">
                <li className="list-inline-item ranks_and_request-login">
                  {(influencer.is_update_reward) ? (
                    <RankSystem
                      userType={constants.USER_TYPE_BY_USER.KEY.influencer}
                      rank={influencer.user_rank || 0}
                    />
                  ) : (
                    <>
                      <div className="request-login">
                        <OverlayTrigger
                          key="top"
                          placement="top"
                          overlay={(
                            <Tooltip id="tooltip-top" className="amazing-tooltip">
                              このインフルエンサーは直近での戦闘力の計測を行っていないため、指名オファーを送る事はできません。<br />
                              ログインリクエストを押すとインフルエンサーにあなたからのリクエストが通知されます。
                            </Tooltip>
                          )}
                        >
                          <QuestionCircleFill className="question-circle-style label-icon ms-2" size={20} />
                        </OverlayTrigger>
                        <button
                          type="button"
                          className="btn btn-outline-green ms-sm-2 ms-0 mb-1 mt-sm-0 mt-2"
                          disabled={
                            !isProcessing
                            && (
                              !isPurchasedPoints
                              || isLoadingSendMail
                              || (influencerSendMail && influencerSendMail.includes(influencer.user_id))
                            )
                          }
                          onClick={() => handleRequestInfluencerLogin(influencer.user_id)}
                        >
                          { isLoadingSendMail ? (
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                              className="me-2"
                            />
                          ) : (
                            'ログインリクエスト'
                          )}
                        </button>
                      </div>
                      <p className="text-danger mb-0">最終更新日時: {influencer.updated_at_reward ?? '-'}</p>
                    </>
                  )}
                </li>
              </ul>
              <ul className="list-inline d-flex justify-content-center align-items-center count_number">
                <li className="list-inline-item d-grid p-2">
                  <span className="fsize-32">{influencer.tiktok_info?.follower_count_short_number}</span>
                  フォロワー
                </li>
                <li className="list-inline-item d-grid p-2">
                  <span className="fsize-32">{influencer.tiktok_info?.engagement_count_short_number}</span>
                  ENG
                </li>
                <li className="list-inline-item d-grid p-2">
                  <span className="fsize-32">{influencer.teddy_bear_rank}</span>
                  テディベア
                </li>
              </ul>
              <div className="paid-reward">
                <div className="points d-flex justify-content-center align-items-center">
                  <div><strong>戦闘力</strong></div>
                  <span className="fsize-32">
                    {influencer?.expected_salary_amount_certain || 0} <small><strong>pt</strong></small>
                  </span>
                </div>
                <p className="mt-1">
                  このインフルエンサーに依頼した場合のポイントです。ポイント情報は定期的に更新されます。
                </p>
              </div>
            </div>
            <div className="col-12 mt-lg-4 pb-sm-4 item-pr mt-3 mb-3 d-md-block d-none" />
            <div className="d-flex justify-content-center ps-sm-1 pe-sm-1">
              <button
                type="button"
                className="btn btn-outline-green w208 h50 btn-mobile-with-max"
                onClick={handleDetail}
              >
                詳細情報を見る
              </button>
            </div>
            <div className="d-flex justify-content-center mt-2 ps-sm-1 pe-sm-1">
              <button
                type="button"
                className={`${
                  isDisableNomination ? 'btn-amazing-disabled' : ''
                } btn btn-green w208 h50 btn-mobile-with-max`}
                onClick={() => {
                  handleNomination(influencer.id);
                }}
                disabled={!influencer.is_update_reward}
              >
                指名する
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

InfluencerItem.defaultProps = {
  tempFavorite: undefined
};

InfluencerItem.propTypes = {
  influencer: PropTypes.object.isRequired,
  addToFavorite: PropTypes.func.isRequired,
  removeFromFavorite: PropTypes.func.isRequired,
  tempFavorite: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  isProcessing: PropTypes.bool.isRequired,
  isDisableNomination: PropTypes.bool.isRequired,
  handleNomination: PropTypes.func.isRequired,
  handleDetail: PropTypes.func.isRequired,
  setIsShowModalSendMail: PropTypes.func.isRequired,
  influencerSendMail: PropTypes.array.isRequired,
  addInfluencerSendMail: PropTypes.func.isRequired
};

export default InfluencerItem;
