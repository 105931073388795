import Pagination from 'components/client/Pagination/Pagination';
import React, { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useDispatch, useSelector } from 'react-redux';
import { getNews } from 'redux/client/news';
import NewsRow from './components/NewsRow';

function index() {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);

  const store = useSelector((state) => ({
    news: state.news.news,
    newsHeader: state.news.newsHeader
  }));

  useEffect(() => {
    _getNews();
  }, []);

  const _getNews = async (page = 1) => {
    setIsLoading(true);
    try {
      await dispatch(getNews(page));
      setIsLoading(false);
      return true;
    } catch (error) {
      setIsLoading(false);
      return error;
    }
  };

  const onPagingClick = (event) => {
    _getNews(event.selected + 1);
  };

  return (
    <section className="news page-news margin-content">
      <div className="client-container">
        <div className="row">
          <div className="col-12 col-lg-2">
            <div className="section title">
              <div className="header">
                <h2 className="title-bg">News</h2>
                <h2 className="title">お知らせ</h2>
                <h3 className="position-absolute outline-1">Topics</h3>
                <h3 className="position-absolute outline-2">Topics</h3>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-8">
            <div className="news-content">
              {isLoading ? [...Array(8)].map((e, i) => (
                <div className="news__item" key={`${i.toString()}`}>
                  <Skeleton height={24} />
                </div>
              )) : store.news.data.map((item) => <NewsRow key={item.id} newsItem={item} />)}
            </div>

            {store.news?.last_page > 1 && (
              <div className="d-flex justify-content-center">
                <Pagination pageCount={store.news?.last_page} onPageChange={onPagingClick} />
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}

export default index;
