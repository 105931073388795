import React, { useState } from 'react';
import { Modal, Spinner } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import * as ja from 'helpers/yup-locale-ja';
import { Field, ErrorMessage, FormikProvider, useFormik } from 'formik';
import PropTypes from 'prop-types';
import ToastMessage from 'components/admin/common/ToastMessage';
import { ResponseStatus } from 'constants';
import { allocPoint } from 'redux/admin/user';
import { setFormikErrors } from 'helpers/utils';

Yup.setLocale(ja.suggestive);
const FormSchema = Yup.object().shape({
  point: Yup.number().required().min(1)
});

function PointAddModal({
  isAddPointModalShow,
  setIsAddPointModalShow,
  userId
}) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      point: '',
      user_id: userId
    },
    onSubmit: (form, formikHelpers) => onSubmitForm(form, formikHelpers),
    enableReinitialize: true,
    validationSchema: FormSchema
  });

  const onSubmitForm = async (form, formikHelpers) => {
    setIsSubmitting(true);
    const res = await dispatch(allocPoint(form));
    if (res && res.status === ResponseStatus.HTTP_UNPROCESSABLE_ENTITY) {
      setFormikErrors(res.data.errors, formikHelpers.setFieldError);
      setIsSubmitting(false);
      return;
    }
    if (res && res.status === ResponseStatus.HTTP_OK) {
      setIsSubmitting(false);
      setIsAddPointModalShow(false);
    } else {
      setIsSubmitting(false);
      setShowToast(true);
    }
  };


  return (
    <div>
      <Modal
        onHide={() => setIsAddPointModalShow(false)}
        show={isAddPointModalShow}
        size="md"
        centered
        className="user-request-modal form-modal"
      >
        <Modal.Header closeButton />
        <Modal.Body>
          <div className="container">
            <FormikProvider value={formik}>
              <form className="admin-form-custom-1 form-update form-style-1">
                <div className="row mt-4">
                  <div className="col-12">
                    <label className="form-label" htmlFor="title">
                      付与するポイント数
                    </label>
                    <Field
                      type="number"
                      name="point"
                      id="point"
                      className={`form-control ${
                        !formik.isValid && formik.errors.point && formik.touched.point ? 'is-invalid' : ''
                      }`}
                      placeholder="入力してください"
                      value={formik.values.point}
                      onChange={(e) => {
                        if (e.target.value < 0) {
                          formik.setFieldValue('point', 1);
                        } else {
                          formik.setFieldValue('point', e.target.value.replace(/^0+/, ''));
                        }
                      }}
                      onKeyPress={(event) => {
                        if (event.charCode < 48 || event.charCode > 57) {
                          event.preventDefault();
                        }
                      }}
                    />
                    <ErrorMessage component="div" name="point" className="invalid-feedback" />
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col-12 text-center">
                    <button
                      type="button"
                      className="btn admin-btn-green"
                      onClick={() => {
                        formik.handleSubmit();
                      }}
                      disabled={!(formik.isValid && formik.dirty) || isSubmitting}
                    >
                      {isSubmitting && (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                          className="me-2"
                        />
                      )}
                      付与する
                    </button>
                  </div>
                </div>
              </form>
            </FormikProvider>
          </div>
        </Modal.Body>
      </Modal>
      <ToastMessage
        isShow={showToast}
        onClose={() => {
          setShowToast(false);
        }}
      />
    </div>
  );
}
PointAddModal.defaultProps = {
  userId: null
};
PointAddModal.propTypes = {
  isAddPointModalShow: PropTypes.bool.isRequired,
  setIsAddPointModalShow: PropTypes.func.isRequired,
  userId: PropTypes.number
};

export default PointAddModal;
