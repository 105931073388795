import {
  GET_MY_BANK_ACCOUNT
} from './bankAccountAction';

const initialState = {
  bankAccountInfo: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_MY_BANK_ACCOUNT:
      return {
        ...state,
        bankAccountInfo: action.payload.data
      };
    default:
      return state;
  }
};
