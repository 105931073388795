import React from 'react';
import PropTypes from 'prop-types';
import TypeIcon from 'assets/svg/type-ic.svg';
import { Link } from 'react-router-dom';
import Image from 'components/common/Image';
import useSession from 'hooks/useSession';
import { USER_TYPE_COMPANY, USER_TYPE_NPO } from 'redux/client/auth';
import RankSystem from 'components/common/RankSystem';
import constants from 'constants/constants';
import CustomProgressBar from 'components/client/CustomProgressBar';

function TopProjectItem({ project, isSubCard }) {
  const { user } = useSession();

  return (
    <div className={`card ${isSubCard ? 'sub-card' : ''} ${user ? 'top-page-project' : ''}`}>
      <div className="position-relative">
        <div className="project__img-box">
          <Link to={`/project/${project.id}`}>
            <Image className="img-fluid" src={project.link_cover_image} alt={project.project_title} />
          </Link>
          <div className="position-absolute card-sub-title">
            <span>{project.category_name}</span>
          </div>
          <div className="position-absolute text-white m-0 card-title">
            <h5 className="m-0">
              <Link to={`/project/${project.id}`}>{project.project_title}</Link>
            </h5>
          </div>
        </div>
      </div>
      <div className="card-content d-flex justify-content-between">
        <div className="info-project">
          <h5 className="m-0 d-flex align-items-center">
            <img src={TypeIcon} alt="" />
            {project.project_type_text}
            <span className={`type type-${project.type} mx-2`}>{project.type_name}</span>
            <div className="d-flex align-items-center">
              {user && project.user_type !== USER_TYPE_NPO && !isSubCard && (
                <RankSystem
                  userType={constants.USER_TYPE_BY_USER.KEY.company}
                  rank={project.user_rank?.rank || 0}
                />
              )}
              <div className="remain-day d-inline-flex align-items-baseline d-md-none">
                <span>残り</span>
                <br />
                <h3>
                  {project.day_remain}<span>日</span>
                </h3>
              </div>
            </div>
          </h5>

          <div className="mt-2 d-md-flex align-items-center npo_or_company_name d-none">
            <p className="m-0 me-2">{project.npo_or_company_name}&nbsp;</p>
            {user && (
              <div>
                {isSubCard ? (
                  <div>
                    {project.user_type !== USER_TYPE_NPO && (
                      <RankSystem
                        userType={constants.USER_TYPE_BY_USER.KEY.company}
                        rank={project.user_rank?.rank || 0}
                      />
                    )}
                  </div>
                ) : (
                  <div className="me-2">
                    {(project.user_type === USER_TYPE_COMPANY) ? (
                      <CustomProgressBar now={project.budget_utilization_rate} minNumber={16} />
                    ) : (
                      <CustomProgressBar now={100} customLabel="テディベアプロジェクト" />
                    )}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
        <div className="remain-day d-md-flex align-items-center justify-content-center d-none">
          <div>
            <span>残り</span>
            <br />
            <h3>
              {project.day_remain}<span>日</span>
            </h3>
          </div>
        </div>
      </div>
    </div>
  );
}

TopProjectItem.defaultProps = {
  isSubCard: false
};

TopProjectItem.propTypes = {
  project: PropTypes.object.isRequired,
  isSubCard: PropTypes.bool
};

export default TopProjectItem;
