import AdminAPI from 'axios/AdminAPI';

export const GET_CANCELLATION_REQUEST_LIST = 'GET_CANCELLATION_REQUEST_LIST';

export const getCancellationRequestsList = (page = 1, isAllowAll = false) => (dispatch) => {
  const URLApi = `cancellation/list?page=${page}&allow_all=${isAllowAll}`;

  return AdminAPI({
    method: 'GET',
    url: URLApi
  })
    .then((res) => {
      dispatch({
        type: GET_CANCELLATION_REQUEST_LIST,
        payload: res
      });
    })
    .catch((error) => {
      return error;
    });
};

export const approvalRequest = (requestForm) => () => {
  return AdminAPI({
    method: 'POST',
    url: 'cancellation/confirm/status',
    data: requestForm
  })
    .catch((error) => {
      return error;
    });
};
