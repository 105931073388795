import React, { useEffect, useState } from 'react';
import { Button, Modal, Spinner } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';

import * as ROUTES from 'constants/routes';
import { ResponseStatus } from 'constants';
import { ResponseStatusMessage } from 'constants/ResponseStatusMessage';
import { findArray, formatMoney, toMemberPage } from 'helpers/utils';
import ImageDefault from 'assets/default-image.jpg';
import useSession from 'hooks/useSession';
import { giftingData } from 'data/giftingData';
import { projectTypeData } from 'data/projectTypeData';
import { updateProject } from 'redux/client/project';
import { useDispatch } from 'react-redux';
import { FiletypePdf } from 'react-bootstrap-icons';
import constants from 'constants/constants';
import { comparisonTypeData } from 'data/comparisonTypeData';
import { useCallbackPrompt } from 'hooks';
import DialogBox from 'components/common/DialogBox';

const PDF_FILE_TYPE = 'application/pdf';

function MyProjectEditConfirm() {
  const { user } = useSession();
  const location = useLocation();
  const dispatch = useDispatch();
  const projectState = location.state || null;
  const [showModalEditSuccess, setShowModalEditSuccess] = useState(projectState?.editSuccess || false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(true);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!projectState) {
      navigate(toMemberPage(user?.user_type, ROUTES.MY_PROJECT_LIST));
    }
  }, []);

  const handleEditSuccessClose = () => {
    setShowModalEditSuccess(false);
    navigate(toMemberPage(user?.user_type, ROUTES.MY_PROJECT_LIST));
    confirmNavigation();
  };

  const onSubmitEditProject = async () => {
    if (projectState.editSuccess) {
      return;
    }

    const requestAllForm = {
      project_id: projectState.project_id,
      delivery_at: projectState.delivery_at,
      type: projectState.type,
      follower_min: projectState.follower_min,
      recruits_number: projectState.recruits_number,
      budget: projectState.budget,
      reward_condition: projectState.reward_condition,
      comparison_type: projectState.comparison_type,
      notes: projectState.notes,
      project_title: projectState.project_title,
      project_contents: projectState.project_contents,
      project_type: projectState.project_type,
      project_category: projectState.project_category,
      area_ids: projectState.area_ids,
      gifting: projectState.gifting,
      gifting_notes: projectState.gifting_notes,
      project_url: projectState.project_url,
      hashtag: projectState.hashtag,
      cover_image: projectState.cover_image,
      attached_files: projectState.attached_files,
      public: projectState.public,
      npo_category_id: projectState.npo_category_id,
      influencer_ids: projectState.nominatedInfluencers?.map((item) => item.influencerId) || []
    };

    setIsLoading(true);
    const resp = await dispatch(updateProject(requestAllForm));
    if (resp?.status != ResponseStatus.HTTP_OK) {
      navigate(ROUTES.ERROR_PAGE, {
        replace: true,
        state: {
          error_code: resp?.status || 413,
          error_msg: ResponseStatusMessage[resp?.status || 413]
        }
      });
      return;
    }

    window.scrollTo(0, 0);
    setShowModalEditSuccess(true);
    navigate(location.pathname, { state: { ...projectState, editSuccess: true }, replace: true });
    setIsLoading(false);
  };

  const _goBackToEditProject = () => {
    navigate(toMemberPage(user?.user_type, ROUTES.MY_PROJECT_EDIT.replace(':id', projectState.project_id)), {
      state: projectState
    });
    confirmNavigation();
  };

  return (
    projectState && (
      <>
        <DialogBox
          showDialog={showPrompt}
          confirmNavigation={confirmNavigation}
          cancelNavigation={cancelNavigation}
        />
        <Modal className="modal-amazing" show={showModalEditSuccess} size="lg" centered>
          <Modal.Header>
            <Modal.Title className="text-center">新規プロジェクトを公開しました</Modal.Title>
          </Modal.Header>
          <Modal.Footer className="justify-content-center border-top-0">
            <Button variant="modal-redirect" className="common-btn" onClick={handleEditSuccessClose}>
              プロジェクト一覧へ
            </Button>
          </Modal.Footer>
        </Modal>
        <div className="client-cpanel-container">
          <div className="confirm-edit-project box-style-1__border">
            <div className="row justify-content-center row--bs-gutter-2">
              <div className="col-lg-10 col-11 form__wrapper form-style-1">
                <h3 className="title-form mt-0 bm-ct-18">
                  プロジェクト新規編集確認
                  <span>内容にお間違いがないか確認し、「公開する」ボタンを押してください。</span>
                </h3>

                <div className="group-item-border row">
                  <div className="col-lg-3 col-md-3 col-sm-4 col-12">
                    <span className="form-label">納品期限</span>
                  </div>
                  <div className="col-lg-9 col-md-9 col-sm-6 col-12 text-color-gray-custom">
                    <span>{projectState.delivery_at}</span>
                  </div>
                </div>

                {(user?.user_type != constants.USER_TYPE_BY_USER.KEY.npo) && (
                <div className="group-item-border row">
                  <div className="col-lg-3 col-md-3 col-sm-4 col-12">
                    <span className="form-label">プロジェクト形式</span>
                  </div>
                  <div className="col-lg-9 col-md-9 col-sm-6 col-12 text-color-gray-custom">
                    <span>{projectState.typeFormValue?.value || '-'}</span>
                  </div>
                </div>
                )}

                <div className="group-item-border row">
                  <div className="col-lg-3 col-md-3 col-sm-4 col-12">
                    <span className="form-label">プロジェクトのタイトル</span>
                  </div>
                  <div className="col-lg-9 col-md-9 col-sm-6 col-12 text-color-gray-custom">
                    <span>{projectState.project_title || '-'}</span>
                  </div>
                </div>

                <div className="group-item-border row">
                  <div className="col-12">
                    <span className="form-label">プロジェクトのサムネイル画像</span>
                  </div>
                  <div className="col-12 pt-1">
                    <img
                      className="img-fluid img--br-radius w-100"
                      src={projectState.cover_image?.url || ImageDefault}
                      alt=""
                    />
                  </div>
                </div>

                <div className="group-item-border row">
                  <div className="col-lg-3 col-md-3 col-sm-4 col-12">
                    <span className="form-label">プロジェクト内容</span>
                  </div>
                  <div className="col-lg-9 col-md-9 col-sm-6 col-12 text-color-gray-custom">
                    <p className="mb-0 text-pre-line">{projectState.project_contents || '-'}</p>
                  </div>
                </div>

                <div className="group-item-border row">
                  <div className="col-lg-3 col-md-3 col-sm-4 col-12">
                    <span className="form-label">プロジェクト特記事項</span>
                  </div>
                  <div className="col-lg-9 col-md-9 col-sm-6 col-12 text-color-gray-custom">
                    <p className="mb-0 text-pre-line">{projectState.notes || '-'}</p>
                  </div>
                </div>

                {(user?.user_type != constants.USER_TYPE_BY_USER.KEY.npo) && (
                <div className="group-item-border row">
                  <div className="col-lg-3 col-md-3 col-sm-4 col-12">
                    <span className="form-label">ギフティング</span>
                  </div>
                  <div className="col-lg-9 col-md-9 col-sm-6 col-12 text-color-gray-custom">
                    <span>{findArray(projectState.gifting, giftingData)?.value || '-'}</span>
                  </div>
                </div>
                )}

                {(user?.user_type != constants.USER_TYPE_BY_USER.KEY.npo) && (
                <div className="group-item-border row">
                  <div className="col-lg-3 col-md-3 col-sm-4 col-12">
                    <span className="form-label">
                      ギフティングに <br />
                      関する特記事項
                    </span>
                  </div>
                  <div className="col-lg-9 col-md-9 col-sm-6 col-12 text-color-gray-custom">
                    <p className="mb-0 text-pre-line">{projectState.gifting_notes || '-'}</p>
                  </div>
                </div>
                )}

                <div className="group-item-border row">
                  <div className="col-lg-3 col-md-3 col-sm-4 col-12">
                    <span className="form-label">募集予定人数</span>
                  </div>
                  <div className="col-lg-9 col-md-9 col-sm-6 col-12 text-color-gray-custom">
                    <span>{projectState.recruits_number}</span>
                  </div>
                </div>

                {(user?.user_type != constants.USER_TYPE_BY_USER.KEY.npo) && (
                <div className="group-item-border row">
                  <div className="col-lg-3 col-md-3 col-sm-4 col-12">
                    <span className="form-label">予算</span>
                  </div>
                  <div className="col-lg-9 col-md-9 col-sm-6 col-12 text-color-gray-custom">
                    <span>{formatMoney(projectState.budget)}pt</span>
                  </div>
                </div>
                )}

                {(user?.user_type != constants.USER_TYPE_BY_USER.KEY.npo) && (
                <div className="group-item-border row">
                  <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                    <span className="form-label">フォロワー数条件</span>
                  </div>
                  <div className="col-lg-3 col-md-3 col-sm-6 col-12 text-color-gray-custom">
                    <span>{projectState.follower_min}名以上</span>
                  </div>
                </div>
                )}

                {user?.user_type != constants.USER_TYPE_BY_USER.KEY.npo
                && projectState.type == constants.PROJECT_TYPE.CANDIDACY && (
                  <div className="group-item-border row align-items-center">
                    <div className="col-lg-3 col-md-3 col-sm-4 col-12">
                      <span className="form-label">
                        インフルエンサー <br />
                        1人辺りの報酬条件
                      </span>
                    </div>
                    <div className="col-lg-9 col-md-9 col-sm-6 col-12 text-color-gray-custom">
                      <span>
                        {formatMoney(projectState.reward_condition)}pt{'　'}
                        {findArray(projectState.comparison_type, comparisonTypeData).value}
                      </span>
                    </div>
                  </div>
                )}

                <div className="group-item-border row">
                  <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                    <span className="form-label">タイプ</span>
                  </div>
                  <div className="col-lg-3 col-md-3 col-sm-6 col-12 text-color-gray-custom">
                    <span>{findArray(projectState.project_type, projectTypeData)?.value || '-'}</span>
                  </div>
                  <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                    <span className="form-label">プロジェクトのジャンル</span>
                  </div>
                  <div className="col-lg-3 col-md-3 col-sm-6 col-12 text-color-gray-custom">
                    <span>{projectState.categoryForValue?.value || '-'}</span>
                  </div>
                </div>

                <div className="group-item-border row">
                  <div className="col-lg-3 col-md-3 col-sm-4 col-12">
                    <span className="form-label">募集エリア</span>
                  </div>
                  <div className="col-lg-9 col-md-9 col-sm-6 col-12 text-color-gray-custom">
                    <ul className="mb-0">
                      {projectState.areaFormValue.map((area) => (
                        <li key={`${area.value}${area.key}`}>{area.value}</li>
                      ))}
                    </ul>
                  </div>
                </div>

                <div className="group-item-border row align-items-center">
                  <div className="col-lg-3 col-md-3 col-sm-4 col-12">
                    <span className="form-label">指定ハッシュタグ</span>
                  </div>
                  <div className="col-lg-9 col-md-9 col-sm-6 col-12 text-color-gray-custom list-hashtag">
                    {projectState.hashtag.length >= 1 && (
                    <>
                      {projectState.hashtag.map((hashtag) => (
                        <div className="item-hashtag" key={hashtag}>
                          {hashtag}
                        </div>
                      ))}
                    </>
                    )}
                  </div>
                </div>

                <div className="group-item-border row">
                  <div className="col-lg-3 col-md-3 col-sm-4 col-12">
                    <span className="form-label">プロジェクトの参考URL</span>
                  </div>
                  <div className="col-lg-9 col-md-9 col-sm-6 col-12 text-color-gray-custom">
                    <span>{projectState.project_url || '-'}</span>
                  </div>
                </div>

                <div className="group-item-border row">
                  <div className="col-lg-3 col-md-3 col-12">
                    <span className="form-label">
                      プロジェクトの <br />
                      参考素材
                    </span>
                  </div>
                  <div className="col-lg-9 col-md-9 col-12 text-color-gray-custom">
                    {projectState.attached_files.length >= 1 ? (
                      <div className="list-attachments">
                        {projectState.attached_files.map((attachedFile) => (
                          <div key={attachedFile.id} className="attached-item">
                            <div className="attached-img d-flex align-items-center">
                              {attachedFile.file.type === PDF_FILE_TYPE ? (
                                <FiletypePdf className="pdf" />
                              ) : (
                                <img src={attachedFile.url} alt="" />
                              )}
                            </div>
                            <div className="attached-name">{attachedFile.file.name}</div>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <span>-</span>
                    )}
                  </div>
                </div>

                {projectState.nominatedInfluencers?.length >= 1 && (
                  <div className="group-item-border row border-0">
                    <div className="col-lg-3 col-md-3 col-sm-4 col-12">
                      <span className="form-label">指名するインフルエンサー</span>
                    </div>
                    <div className="col-lg-9 col-md-9 col-sm-8 col-12 text-color-gray-custom">
                      <ul className="mb-0">
                        {projectState.nominatedInfluencers.map((influencer) => (
                          <li key={influencer.influencerId}>{influencer.nickName}</li>
                        ))}
                      </ul>
                    </div>
                  </div>
                )}

                <div className="row mt-btn-cf-form text-center">
                  <div className="col-lg-10 offset-lg-1 col-12 col-ct-12">
                    <div className="confirm-project__note">
                      <span>
                        【プロジェクト公開前に必ずご確認ください】<br />
                        予算として設定したポイントが、このプロジェクト用にプールされた状態になります。<br />
                        プールされたポイントは、プロジェクト終了まで、他のプロジェクトに使用することができませんのでご注意ください。<br />
                        「公開する」をクリックした時点から、プロジェクトの公開を取り消すことはできませんのでご注意ください。<br />
                        ※応募者が0名の状態時のみ非公開に変更可能
                      </span>
                    </div>
                    <div className="row confirm-project__button">
                      <div className="col-lg-6 col-md-6 col-12 col-child-ct-12 button button__back">
                        <Button variant="redirect" className="common-outline-btn my-2" onClick={_goBackToEditProject}>
                          <span className="sr-only">戻る</span>
                        </Button>
                      </div>

                      <div className="col-lg-6 col-md-6 col-12 col-child-ct-12 button button__submit">
                        <Button
                          variant="submit-project"
                          className="common-btn my-2"
                          onClick={onSubmitEditProject}
                          disabled={isLoading}
                        >
                          {isLoading && (
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                              className="me-2"
                            />
                          )}
                          {projectState.type == constants.PROJECT_TYPE.SPECIFIED && (
                            <span className="sr-only">指名オファーを送信</span>
                          )}
                          {projectState.type != constants.PROJECT_TYPE.SPECIFIED && (
                            <span className="sr-only">公開する</span>
                          )}
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  );
}

export default MyProjectEditConfirm;
