export const giftingData = [
  {
    key: 0,
    value: 'あり'
  },
  {
    key: 1,
    value: 'なし'
  }
];
