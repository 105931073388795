import useSession from 'hooks/useSession';
import React from 'react';
import BannerTopPage from 'assets/images/enma_banner_top_page.png';
import LineBanner from 'assets/images/line-banner.png';

function Point() {
  const { user } = useSession();

  if (user) {
    return (
      <section className="point margin-content text-center">
        <div className="client-container">
          <a
            href="https://influence.enma.buzz/column/592"
            target="_blank"
            rel="noreferrer"
            className="d-inline-block me-0 me-xl-4 mb-4 mb-xl-0"
          >
            <img src={LineBanner} alt="LINE Banner Top Page" className="mw-100 mw-pc" />
          </a>
          <a
            href="https://influence.enma.buzz/guide"
            target="_blank"
            rel="noreferrer"
          >
            <img src={BannerTopPage} alt="Banner Top Page" className="mw-100 mw-pc" />
          </a>
        </div>
      </section>
    );
  }

  return (
    <section className="point margin-content">
      <div className="client-container">
        <div className="position-absolute start-0 end-0 bg-item"> </div>
        <div className="list-content">
          <div className="point-content">
            <div className="point-list">
              <h3>Point</h3>
              <h4>0.1</h4>
            </div>
            <div className="title">
              <h5>様々な応募方法</h5>
            </div>
            <div className="content">
              <span>
                ENMAでは、立候補式の他に先着式のプロジェクトがございます。
                <br />
                また、企業から依頼が届く指名式もご利用いただけます。
              </span>
            </div>
          </div>
          <div className="point-content">
            <div className="point-list">
              <h3>Point</h3>
              <h4>0.2</h4>
            </div>
            <div className="title">
              <h5>オンラインでのやり取り</h5>
            </div>
            <div className="content">
              <span>
                企業やOTASUKEとのやり取りは全てオンラインで管理。
                <br />
                応募から納品まで、スムーズにやり取りができ、現在の進捗状況もわかりやすくなっています。
              </span>
            </div>
          </div>
          <div className="point-content">
            <div className="point-list">
              <h3>Point</h3>
              <h4>0.3</h4>
            </div>
            <div className="title">
              <h5>必要なのはTikTokアカウントだけ</h5>
            </div>
            <div className="content">
              <span>
                登録・応募の条件は、TikTokアカウントのみ。
                <br />
                フォロワー数の条件などないので、これからPRに挑戦してみたい方にも、始めやすい条件になっています。
              </span>
            </div>
          </div>
        </div>
        <div className="position-absolute start-0 end-0 bg-bottom-item d-block d-lg-none d-xl-none"> </div>
      </div>
    </section>
  );
}
export default Point;
