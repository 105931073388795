import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Container, Spinner } from 'react-bootstrap';
import * as Yup from 'yup';
import * as ja from 'helpers/yup-locale-ja';
import Alert from 'react-bootstrap/Alert';
import { Form, Formik, Field } from 'formik';

import CustomInput from 'components/formik/CustomInput';
import { HTTP_UNAUTHORIZED } from 'constants/ResponseStatus';
import { signIn } from 'redux/admin/auth';
import LOGO from 'assets/logo-black.svg';
import { CustomInputPassword } from 'components/formik';

Yup.setLocale(ja.suggestive);
const FormSchema = Yup.object().shape({
  email: Yup.string()
    .required()
    .min(3)
    .max(255)
    .email(),
  password: Yup.string().required().min(8)
});

function index() {
  const initFormikValues = {
    email: '',
    password: ''
  };

  const [isLoginFailed, setIsLoginFailed] = useState(false);

  const dispatch = useDispatch();

  const onSubmitForm = async (form) => {
    setIsLoginFailed(false);
    const resp = await dispatch(signIn(form));
    if (resp && resp.response.status == HTTP_UNAUTHORIZED) {
      await setIsLoginFailed(true);
    }
  };

  return (
    <Container fluid className="admin-login-wrapper">
      <div className="card admin-login__card">
        <div className="card-header">
          <img src={LOGO} alt="インフルエンサーマッチング Webアプリ" className="navbar-brand__logo" />
        </div>
        <div className="card-body">
          <Formik initialValues={initFormikValues} validationSchema={FormSchema} onSubmit={onSubmitForm}>
            {({ isValid, dirty, isSubmitting }) => (
              <Form>
                {isLoginFailed && (
                  <Alert className="py-2" variant="danger">
                    メールアドレスまたはパスワードが間違っています。
                  </Alert>
                )}
                <div className="mb-3">
                  <Field
                    name="email"
                    label={['メールアドレス']}
                    placeholder="入力してください"
                    className="admin-form-control"
                    component={CustomInput}
                  />
                </div>

                <div className="mb-4">
                  <label className="form-label" htmlFor="password">
                    パスワード
                  </label>
                  <Field
                    id="password"
                    name="password"
                    placeholder="入力してください"
                    component={CustomInputPassword}
                  />
                </div>

                <div className="text-center">
                  <button
                    type="submit"
                    className="btn admin-btn-green btn-login"
                    disabled={!(isValid && dirty) || isSubmitting}
                  >
                    {isSubmitting && (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        className="me-2"
                      />
                    )}
                    ログイン
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </Container>
  );
}

export default index;
