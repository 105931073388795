/* eslint-disable react/self-closing-comp */
import { FastField, useFormikContext } from 'formik';
import React from 'react';

import CustomInput from 'components/formik/CustomInput';
import { RadioGroup } from 'components/formik';
import { bankAccountTypeData } from 'data/bankAccountTypeData';
import { useDispatch } from 'react-redux';
import { getBank, getBranch } from 'redux/client/auth';

function CreateOrUpdateBackAccountForm() {
  const dispatch = useDispatch();
  const { setFieldValue, values } = useFormikContext();

  const handleOnBlurBranchCode = () => {
    if (!values.bank_code || !values.branch_code) {
      setFieldValue('branch_name', '');
      return;
    }
    dispatch(getBranch(values.bank_code, values.branch_code))
      .then((res) => {
        const branchName = res.data && res.data.length >= 1 ? res.data[0].name : '';
        setFieldValue('branch_name', branchName);
      })
      .catch((error) => {
        setFieldValue('branch_name', '');
        return error;
      });
  };

  const handleOnBlurBankCode = () => {
    if (!values.bank_code) {
      setFieldValue('bank_name', '');
      return;
    }
    dispatch(getBank(values.bank_code))
      .then((res) => {
        const bankName = res.data ? res.data.name : '';
        setFieldValue('bank_name', bankName);
      })
      .catch((error) => {
        setFieldValue('bank_name', '');
        return error;
      });
  };
  return (
    <div>
      <h3 className="title-form">振込口座情報登録</h3>
      <p className="mb-4">
        振込申請後に振込口座を変更された場合、次回お振込みが前口座へのお振込みとなる場合がございます。
      </p>
      <p className="mb-4">
        銀行コード・支店コードが不明な場合はこちらからお調べください<br />
        <a
          href="https://zengin.ajtw.net/"
          target="_blank"
          rel="noreferrer"
          className="nav-link text-decoration-underline"
        >
          ※外部サイトへ遷移します
        </a>
      </p>
      <div className="pb-sm-1">
        <div className="form-group-item row bm-ct-18">
          <div className="col-lg-6 col-md-6 col-sm-6 col-12 mb-mobile-form">
            <FastField
              name="bank_code"
              label={['銀行コード']}
              placeholder="入力してください"
              component={CustomInput}
              onBlur={handleOnBlurBankCode}
            />
          </div>
          {
            values.bank_name && (
              <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                <div className="form-label mb-2 d-inline-block d-none d-sm-block">
                  <span className="d-inline-block "></span>
                </div>
                <div className="form-control border-0 px-0">{values.bank_name ?? ''}</div>
              </div>
            )
          }
        </div>

        <div className="form-group-item row">
          <div className="col-lg-6 col-md-6 col-sm-6 col-12 mb-mobile-form">
            <FastField
              name="branch_code"
              label={['支店コード']}
              placeholder="入力してください"
              component={CustomInput}
              onBlur={handleOnBlurBranchCode}
            />
          </div>
          {
            values.branch_name && (
              <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                <div className="form-label mb-2 d-inline-block d-none d-sm-block">
                  <span className="d-inline-block "></span>
                </div>
                <div className="form-control border-0 px-0">{values.branch_name ?? ''}</div>
              </div>
            )
          }
        </div>

        <div className="form-group-item row bm-26-lg">
          <div className="col-12">
            <RadioGroup name="account_type" label={['種類']} options={bankAccountTypeData} />
          </div>
        </div>

        <div className="form-group-item row">
          <div className="col-12">
            <FastField
              name="account_number"
              label={['口座番号']}
              placeholder="入力してください"
              component={CustomInput}
            />
          </div>
        </div>
        <div className="form-group-item row">
          <div className="col-12">
            <FastField
              name="account_name"
              label={['口座名義（カナ）']}
              placeholder="入力してください"
              component={CustomInput}
            />
          </div>
        </div>
        <div className="form-group-item row">
          <p>
            ※ポイントは、当月末締めで集計し、翌月15日(15日が土日祝日の場合は次の直近の平日)に出金することができます。
          </p>
        </div>
      </div>
    </div>
  );
}

export default CreateOrUpdateBackAccountForm;
