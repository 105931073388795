import { GET_CANCELLATION_REQUEST_LIST } from './cancellationAction';

const initialState = {
  cancellationRequests: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_CANCELLATION_REQUEST_LIST:
      return {
        ...state,
        cancellationRequests: action.payload.data
      };

    default:
      return state;
  }
};
