import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Formik } from 'formik';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Button, Spinner } from 'react-bootstrap';

import { checkUserAuthToken, USER_TYPE_INFLUENCER, validatorSignUpUserInfluencerStep1 } from 'redux/client/auth';
import { setFormikErrors, scrollToErrorField, getArrayValueFromKeyPair, isSNSRegistration } from 'helpers/utils';
import * as ROUTES from 'constants/routes';
import LoadingOverlay from 'components/loading/LoadingOverlay';
import { genderData } from 'data/genderData';
import { ResponseStatus } from 'constants';
import constants from 'constants/constants';
import SignUpInfluencerFormStep1 from './components/SignUpInfluencerFormStep1';
import { SignUpInfluencerStep1Validation } from './SignUpInfluencerStep1Validation';

function SignUpInfluencerStep1() {
  const step2SignUpLocation = useLocation();
  const navigate = useNavigate();
  const { userAuthToken, tiktokAccessToken } = useParams();

  const store = useSelector((state) => ({
    userAuth: state.auth.userAuth
  }));
  const dispatch = useDispatch();

  useEffect(() => {
    const influencerDataForm = localStorage.getItem(constants.OLD_DATA_INFLUENCER_LOCAL_STORAGE);
    if (influencerDataForm) {
      setInitFormikValues(JSON.parse(influencerDataForm));
    }

    if (step2SignUpLocation.state !== null) {
      setInitFormikValues(step2SignUpLocation.state);
    }

    initialPage();
  }, []);

  const [initFormikValues, setInitFormikValues] = useState({
    password: '',
    first_name: '',
    family_name: '',
    first_name_kana: '',
    family_name_kana: '',
    phone: '',
    birthday: '',
    gender: 0,
    prefectures: [],
    tag_ids: [],
    // category_ids: [],
    // category_text: '',
    pr: '',
    // Tiktok info
    tiktok_info: '',
    tiktok_auth_access_token: '',
    is_sns_registration: false
  });

  const initialPage = async () => {
    try {
      const checkUserAuthTokenPromise = dispatch(
        checkUserAuthToken({
          token: userAuthToken,
          user_type: USER_TYPE_INFLUENCER
        })
      ).catch(() => {
        navigate(ROUTES.ERROR_404_PAGE);
      });

      await Promise.all([checkUserAuthTokenPromise]);

      return true;
    } catch (error) {
      return error;
    }
  };

  const onFormSubmit = async (form, formikHelpers) => {
    const requestForm = Object.assign(form, {
      email: store.userAuth.email,
      verifyToken: userAuthToken,
      genderList: getArrayValueFromKeyPair(genderData, form.gender)
    });
    const resp = await dispatch(validatorSignUpUserInfluencerStep1(requestForm));
    if (resp && resp.status == ResponseStatus.HTTP_UNPROCESSABLE_ENTITY) {
      setFormikErrors(resp.data.errors, formikHelpers.setFieldError);
      scrollToErrorField();
    }
    // If check validate not OK
    if (resp.status != ResponseStatus.HTTP_OK) {
      navigate(resp.status);
    } else {
      let nextUrl = `${ROUTES.SIGN_UP_INFLUENCER_STEP_2}/${userAuthToken}`;
      if (tiktokAccessToken) {
        nextUrl += `/${tiktokAccessToken}`;
      }
      navigate(nextUrl, {
        state: requestForm
      });
    }
  };

  return (
    <div className="signup-confirm-page">
      {!store.userAuth ? (
        <LoadingOverlay />
      ) : (
        <div className="container main-container signup-confirm-page">
          <div className="row justify-content-center">
            <div className="col-lg-10 col-11 form__wrapper">
              <Formik
                initialValues={({ ...initFormikValues, is_sns_registration: isSNSRegistration(store.userAuth) })}
                enableReinitialize
                validationSchema={SignUpInfluencerStep1Validation}
                onSubmit={onFormSubmit}
              >
                {({ handleSubmit, isSubmitting }) => (
                  <Form className="form-style-1">
                    <h3 className="title-form">ご登録ありがとうございます。<br />ENMAのご利用にあたって、公式LINEの追加をお願いします。</h3>
                    <div className="form-group-item row">
                      <div className="col-lg-2 col-md-4 col-sm-4 col-12 mb-mobile-form col-md-ct-4">
                        <span className="form-label">メールアドレス</span>
                      </div>
                      <div className="col-lg-7 col-md-8 col-sm-8 col-12">
                        <span className="text-color-gray-custom">{store.userAuth && store.userAuth.email}</span>
                      </div>
                    </div>

                    <div className="mb-5">
                      <SignUpInfluencerFormStep1
                        isSNSRegistration={isSNSRegistration(store.userAuth)}
                      />
                    </div>

                    <div className="col-12 text-center mt-btn-form">
                      <Button
                        variant="auth common-btn"
                        onClick={handleSubmit}
                        disabled={isSubmitting}
                      >
                        {isSubmitting && (
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            className="me-2"
                          />
                        )}
                        <span className="sr-only">次へ</span>
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default SignUpInfluencerStep1;
