import React from 'react';

function Legal() {
  return (
    <div className="container">
      <h1 className="text-center py-5">特定商取引法に基づく表記</h1>

      <div className="content_wrapper">
        <p>
          販売事業者：株式会社アメージング
        </p>
        <p>
          代表者：小山賢治
        </p>
        <p>
          所在地/お問合せ先：東京都豊島区巣鴨2丁目15番1
        </p>
        <p>
          電話番号：03-5960-5990（受付時間：平日 9:00〜17:00）
        </p>
        <p>
          メールアドレス：info@the-amazing.co.jp
        </p>
        <p>
          販売価格：企業ユーザーのポイント購入ページに記載しております（表示価格は税込みです）
        </p>
        <p>
          支払い方法：クレジットカード決済のみ対応しています
        </p>
        <p>
          支払い時期：プロジェクトの作成前にお支払いください
        </p>
        <p>
          ポイントの提供時期：決済完了後、即時にポイントが提供されます
        </p>
        <p>
          ポイント支払い以外に必要な料金：インターネット接続に必要な通信回線等の諸費用は別途ご負担ください
        </p>
        <p className="mb-0 pb-5">
          返品について：商品の性質上、決済完了後の返品又はキャンセルはお受けできません
        </p>
      </div>
    </div>
  );
}

export default Legal;
