import React, { useEffect, useState } from 'react';
import { Button, Modal, Tab, Tabs } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import {
  addInfluencerToFavorite,
  getFavoriteInfluencersForSearching,
  getInfluencersForSearching,
  removeUserFromFavorite } from 'redux/client/project';
import useSession from 'hooks/useSession';
import { useNavigate } from 'react-router-dom';
import { toMemberPage } from 'helpers/utils';
import LoadingOverlay from 'components/loading/LoadingOverlay';
import Pagination from 'components/client/Pagination/Pagination';
import { HTTP_OK } from 'constants/ResponseStatus';
import { getAllPrefectures } from 'redux/client/category_global';
import { getAllTags } from 'redux/client/tag';
import * as ROUTES from 'constants/routes';
import SearchBar from './components/SearchBar';
import InfluencerSearchItem from './components/InfluencerSearchItem';


function index() {
  const [isLoading, setIsLoading] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [tempFavorite, setTempFavorite] = useState([]);
  const [isFavoriteTab, setIsFavoriteTab] = useState(false);
  const [showModalPurchasePoint, setShowModalPurchasePoint] = useState(false);
  const { user, isPurchasedPoints } = useSession();
  const userType = user?.user_type;
  const [isShowModalSendMail, setIsShowModalSendMail] = useState(false);
  const [influencerSendMail, setInfluencerSendMail] = useState([]);
  const navigate = useNavigate();
  const store = useSelector((state) => ({
    tags: state.tag.tags,
    prefectures: state.categoryGlobal.prefectures,
    influencers: state.project.influencers,
    favoriteInfluencers: state.project.favoriteInfluencers
  }));

  const dispatch = useDispatch();
  useEffect(() => {
    initialPage();
    if (!isPurchasedPoints) {
      setShowModalPurchasePoint(true);
      const handleContextmenu = (e) => {
        e.preventDefault();
      };
      document.addEventListener('contextmenu', handleContextmenu);
    }
  }, []);

  const initialPage = async () => {
    setIsLoading(true);
    try {
      const getAllTagPromise = store.tags.length === 0 ? dispatch(getAllTags()) : null;
      const getAllPrefecturesPromise = store.prefectures.length === 0 ? dispatch(getAllPrefectures()) : null;
      const getInfluencersPromise = dispatch(getInfluencersForSearching(1));
      const getFavoriteInfluencersPromise = dispatch(getFavoriteInfluencersForSearching(1));
      await Promise.all([
        getAllTagPromise,
        getAllPrefecturesPromise,
        getInfluencersPromise,
        getFavoriteInfluencersPromise]);

      getFavoriteInfluencersPromise.then((result) => {
        if (result.data?.total > 0) {
          result.data.data?.map((influencer) => (
            setTempFavorite(Object.assign(tempFavorite, {
              [influencer.id]: true
            }))
          ));
        }
        return result;
      });
      setIsLoading(false);
      return true;
    } catch (error) {
      setIsLoading(false);
      return error;
    }
  };
  // handle pagination
  const handleFetchData = (currentPage = 1, isOpenFavoriteTab) => {
    setIsLoading(true);
    setIsFavoriteTab(isOpenFavoriteTab);

    try {
      if (isOpenFavoriteTab) {
        dispatch(getFavoriteInfluencersForSearching(currentPage + 1));
      } else {
        dispatch(getInfluencersForSearching(currentPage + 1));
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const handlePageChange = (selectedObject) => {
    const isOpenFavoriteTab = false;
    handleFetchData(selectedObject.selected, isOpenFavoriteTab);
  };

  const handleFavoritePageChange = (selectedObject) => {
    const isOpenFavoriteTab = true;
    handleFetchData(selectedObject.selected, isOpenFavoriteTab);
  };

  const addToFavorite = async (influencer) => {
    setIsProcessing(true);
    setTempFavorite(Object.assign(tempFavorite, {
      [influencer.id]: true
    }));
    try {
      const resp = await dispatch(addInfluencerToFavorite(user.id, influencer.id));
      if (resp.status === HTTP_OK) {
        const newDataObject = [...store.favoriteInfluencers.data, influencer];
        const totalPage = store.favoriteInfluencers.total + 1;
        const lastPageCount = Math.ceil((store.favoriteInfluencers.total + 1) / store.favoriteInfluencers.per_page);
        await dispatch({
          type: 'ADD_TO_FAVORITE_LIST',
          payload: {
            data: newDataObject,
            total: totalPage,
            lastPage: lastPageCount
          }
        });
      }
      setIsProcessing(false);
      return true;
    } catch (error) {
      setIsProcessing(false);
      return false;
    }
  };

  const removeFromFavorite = async (influencer) => {
    setIsProcessing(true);
    const influencerId = influencer.id;

    setTempFavorite((current) => {
      const copy = { ...current };
      delete copy[influencerId];
      return copy;
    });

    try {
      const resp = await dispatch(removeUserFromFavorite(influencerId));
      if (resp.status === HTTP_OK) {
        const newDataObject = store.favoriteInfluencers.data.filter((item) => item.id !== influencer.id);
        const totalPage = store.favoriteInfluencers.total - 1;
        const lastPageCount = Math.ceil((store.favoriteInfluencers.total - 1) / store.favoriteInfluencers.per_page);
        await dispatch({
          type: 'REMOVE_INFLUENCER_FROM_FAVORITE',
          payload: {
            data: newDataObject,
            total: totalPage,
            lastPage: lastPageCount,
            currentPage: isFavoriteTab ? store.favoriteInfluencers.current_page : store.influencers.current_page,
            perPage: isFavoriteTab ? store.favoriteInfluencers.per_page : store.influencers.per_page
          }
        });
      }
      setIsProcessing(false);
      return true;
    } catch (error) {
      setIsProcessing(false);
      return false;
    }
  };

  const handleCloseModal = () => {
    setIsShowModalSendMail(false);
  };

  const addInfluencerSendMail = async (userIdInfluencer) => {
    if (influencerSendMail.includes(userIdInfluencer)) return;
    await setInfluencerSendMail((preState) => [...preState, userIdInfluencer]);
  };

  return (
    <div className={isPurchasedPoints ? 'page-search-influencers' : 'page-search-influencers dont-purchase'}>
      <Modal
        className="modal-amazing modal-influencer-search"
        onHide={() => setShowModalPurchasePoint(false)}
        show={showModalPurchasePoint}
        size="sm"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="text-center pt-3">
            インフルエンサー検索にはポイントの購入が必要です
          </Modal.Title>
        </Modal.Header>
        <Modal.Footer className="justify-content-center border-top-0">
          <Button
            className="btn  btn-small common-btn w-100"
            onClick={() => {
              setShowModalPurchasePoint(false);
              navigate(toMemberPage(userType, ROUTES.MY_POINT_INDEX));
            }}
          >
            ポイント購入はこちら
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="client-cpanel-container">
        <div className="box-style-1__border create-project__search-user-form">
          <div className="row justify-content-center">
            <div className="col-lg-8 col-12 form__wrapper">
              <h3 className="title-form">
                インフルエンサー検索
                <span>指名するインフルエンサーを選択してください</span>
              </h3>
              <SearchBar
                tagData={store.tags}
                prefectureData={store.prefectures}
                setIsLoading={setIsLoading}
                isLoading={isLoading}
              />
            </div>
          </div>
        </div>
        <div className="list-wrapper carousel-nav">
          <Tabs defaultActiveKey="list" id="uncontrolled-tab" className="amazing-tabs">
            <Tab eventKey="list" title="一覧">
              <div className="row">
                {isLoading && <LoadingOverlay /> }
                {(store.influencers?.total < 1 && !isLoading) && <p>見つかりません。</p>}
                {store.influencers?.data && !isLoading && (
                <>
                  {store.influencers.data.map((influencer) => (
                    <InfluencerSearchItem
                      influencer={influencer}
                      key={`influencer${influencer.id}`}
                      addToFavorite={() => addToFavorite(influencer)}
                      removeFromFavorite={() => removeFromFavorite(influencer)}
                      tempFavorite={tempFavorite}
                      isFavorite={influencer.is_favorite}
                      isProcessing={isProcessing}
                      setIsShowModalSendMail={setIsShowModalSendMail}
                      influencerSendMail={influencerSendMail}
                      addInfluencerSendMail={addInfluencerSendMail}
                    />
                  ))}

                  {store.influencers?.last_page > 1 && (
                    <div className="d-flex justify-content-center">
                      <Pagination
                        pageCount={store.influencers.last_page}
                        activePage={store.influencers.current_page}
                        pageRange={2}
                        marginPagesDisplayed={2}
                        onPageChange={handlePageChange}
                      />
                    </div>
                  )}
                </>
                )}
              </div>
            </Tab>
            <Tab eventKey="favorite" title="お気に入り" disabled={!isPurchasedPoints}>
              <div className="row">
                {isLoading && <LoadingOverlay /> }
                {(store.favoriteInfluencers?.total < 1 && !isLoading) && <p>見つかりません。</p>}
                {store.favoriteInfluencers?.data && !isLoading && (
                <>
                  {store.favoriteInfluencers.data.map((influencer) => (
                    <InfluencerSearchItem
                      influencer={influencer}
                      key={`influencer${influencer.id}`}
                      addToFavorite={() => addToFavorite(influencer)}
                      removeFromFavorite={() => removeFromFavorite(influencer)}
                      tempFavorite={tempFavorite}
                      isProcessing={isProcessing}
                      setIsShowModalSendMail={setIsShowModalSendMail}
                      influencerSendmail={influencerSendMail}
                      addInfluencerSendMail={addInfluencerSendMail}
                    />
                  ))}

                  {store.favoriteInfluencers?.last_page > 1 && (
                    <div className="d-flex justify-content-center">
                      <Pagination
                        pageCount={store.favoriteInfluencers.last_page}
                        activePage={store.favoriteInfluencers.current_page}
                        pageRange={2}
                        marginPagesDisplayed={2}
                        onPageChange={handleFavoritePageChange}
                      />
                    </div>
                  )}
                </>
                )}
              </div>
            </Tab>
          </Tabs>
        </div>
      </div>
      <Modal className="modal-amazing" show={isShowModalSendMail} size="lg" centered>
        <Modal.Body>
          <div className="text-center">
            <p className="mb-0">インフルエンサーにログインリクエストを送信しました</p>
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-center border-top-0">
          <Button variant="modal-redirect" className="common-btn btn-small py-2" onClick={handleCloseModal}>
            閉じる
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default index;
