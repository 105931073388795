import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import GenreImage1 from 'assets/images/genre-image-1.png';
import GenreImage2 from 'assets/images/genre-image-2.png';
import GenreImage3 from 'assets/images/genre-image-3.png';
import GenreImage4 from 'assets/images/genre-image-4.png';
import { Link } from 'react-router-dom';
import * as ROUTES from 'constants/routes';
import { LoadingContext } from 'hooks/LoadingContext';

const popularGenreImages = [{ img: GenreImage1 }, { img: GenreImage2 }, { img: GenreImage3 }, { img: GenreImage4 }];
function PopularGenre({ projectTypeData }) {
  const [isLoading] = useContext(LoadingContext);

  return (
    <section className="popular-genre margin-content">
      <div className="inner">
        <div className="client-container">
          <div className="pt-4 pb-3 section title">
            <div className="header">
              <h2 className="title-bg">Popular Genre</h2>
              <h2 className="title">人気ジャンル</h2>
            </div>
          </div>
          <div className="row content">
            {isLoading ? [...Array(4)].map((e, i) => (
              <div className="col-6 col-lg-3" key={`${i.toString()}`}>
                <div className="card">
                  <div className="card-image"> </div>
                </div>
              </div>
            )) : projectTypeData.slice(0, 4).map((item) => (
              <div className="col-6 col-lg-3" key={item.key}>
                <div className="card position-relative">
                  <Link to={ROUTES.PROJECT_TYPE_LIST.replace(':project_type', item.key)} title={item.value}>
                    <div className="card-image">
                      <img src={popularGenreImages[item.key].img} alt={item.value} />
                    </div>
                    <h5 className="card-title custom-maxline-1 position-absolute">
                      <strong>{item.value}</strong>
                    </h5>
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}
PopularGenre.propTypes = {
  projectTypeData: PropTypes.array.isRequired
};
export default PopularGenre;
