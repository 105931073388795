import {
  GET_LATEST_REWARD_CHANGE_DATE,
  GET_MY_POINTS_COMPANY_SUCCESS,
  GET_MY_POINTS_AND_RANK,
  MY_USER_INFO,
  UPDATE_USER_INFO_SUCCESS
} from './userAction';

const initialState = {
  lastestRewardChangeDate: [],
  myInfo: {},
  currentMyPointsCompany: {},
  myPointAndRank: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_LATEST_REWARD_CHANGE_DATE: {
      const reward = action.payload.data;
      return {
        ...state,
        latestRewardChangeDate: reward.updated_at
      };
    }
    case MY_USER_INFO: {
      return {
        ...state,
        myInfo: action.payload.data
      };
    }
    case UPDATE_USER_INFO_SUCCESS: {
      return {
        ...state,
        myInfo: action.payload
      };
    }
    case GET_MY_POINTS_COMPANY_SUCCESS: {
      return {
        ...state,
        currentMyPointsCompany: action.payload.data
      };
    }
    case GET_MY_POINTS_AND_RANK: {
      return {
        ...state,
        myPointAndRank: action.payload.data
      };
    }

    default:
      return state;
  }
};
