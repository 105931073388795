import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { Form, Formik, Field } from 'formik';
import useSession from 'hooks/useSession';

import constants from 'constants/constants';
import { ERROR_404_PAGE, ERROR_PAGE, MY_PROFILE_UPDATE, POLICY_PRIVACY } from 'constants/routes';
import * as ResponseStatus from 'constants/ResponseStatus';
import { ResponseStatusMessage } from 'constants/ResponseStatusMessage';
import { setFormikErrors, toMemberPage } from 'helpers/utils';
import { Button, Spinner, Modal } from 'react-bootstrap';
import { findUserInfo } from 'redux/client/nda';
import { createIdentityVerify } from 'redux/client/company_identity_verify';
import { identityVerifyTypeData } from 'data/identityVerifyTypeData';
import { identityVerifyMessageData } from 'data/identityVerifyMessageData';
import { CustomSelectPicker } from 'components/formik';
import UploadMultipleFileForm from './components/UploadMultipleFileForm';
import IdentityVerifyAccordion from './components/IdentityVerifyAccordion';
import { CreateIdentityVerifyValidation } from './CreateIdentityVerifyValidation';

function company() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSession();
  const userType = user?.user_type;
  const [isLoading, setIsLoading] = useState(true);
  const [companyInfo, setCompanyInfo] = useState(null);
  const [isIdentityVerifyComplete, setIsIdentityVerifyComplete] = useState(false);
  const [messageShow, setMessageShow] = useState(null);
  const [showModalCompleted, setShowModalCompleted] = useState(false);

  const initFormData = {
    is_public: false,
    docs_type: '',
    attached_files: []
  };
  const [initFormikValues, setInitFormikValues] = useState(initFormData);

  const onFormSubmit = async (form, formikHelpers) => {
    const res = await dispatch(createIdentityVerify(form));
    if (res && res.status == ResponseStatus.HTTP_UNPROCESSABLE_ENTITY) {
      setFormikErrors(res.data.errors, formikHelpers.setFieldError);
    }

    if (res?.status != ResponseStatus.HTTP_OK) {
      navigate(ERROR_PAGE, {
        replace: true,
        state: {
          error_code: res.response.status || 413,
          error_msg: ResponseStatusMessage[res.response.status || 413]
        }
      });
    }

    const resData = res.data;
    setIsIdentityVerifyComplete(true);
    setIdentityVerifyMessageStatus(resData?.status);
    setShowModalCompleted(true);

    setInitFormikValues({
      is_public: true,
      docs_type: resData.docs_type.toString(),
      attached_files: resData.attached_files
    });
  };

  const setIdentityVerifyMessageStatus = (statusNumber, messageSub) => {
    let message = '';
    message = identityVerifyMessageData[statusNumber]?.value;
    if (messageSub) {
      message += '\n';
      message += messageSub;
    }

    setMessageShow(message);
  };

  const fetchUserCompanyInfo = async () => {
    const res = await dispatch(findUserInfo(userType));
    if (res.status != ResponseStatus.HTTP_OK) {
      navigate(ERROR_404_PAGE, {
        replace: true
      });
    }

    const resData = res.data;
    setCompanyInfo(resData);

    if (resData?.identity_verify) {
      /** Show Message */
      let messageSub = '';
      if (resData.identity_verify?.status == constants.IDENTITY_VERIFY_REJECTED) {
        messageSub = resData.identity_verify?.message;
      }
      setIdentityVerifyMessageStatus(resData.identity_verify?.status, messageSub);
      setIsIdentityVerifyComplete(true);

      /** Show Data Form */
      setInitFormikValues({
        is_public: true,
        docs_type: resData.identity_verify?.docs_type.toString(),
        attached_files: resData.identity_verify.attached_files
      });
    }

    setIsLoading(false);
  };

  const handleCloseModal = () => {
    setShowModalCompleted(false);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    fetchUserCompanyInfo();
  }, []);

  return (
    <div className="page__wrap identity-verify-page client-cpanel-container">
      <Modal className="modal-amazing" show={showModalCompleted} size="lg" centered>
        <Modal.Header>
          <Modal.Title className="text-center">本人確認申請</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="text-center">
            <p>申請が完了しました。</p>
            <p className="mb-0">確認まで約5営業日掛かる場合がございます。</p>
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-center border-top-0">
          <Button variant="modal-redirect" className="common-btn btn-small py-2" onClick={handleCloseModal}>
            閉じる
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="position-relative">
        <h2 className="page-title">本人確認</h2>
        <div className="client-cpanel__page-title-after">
          <span className="shadow-text">Identification</span>
        </div>
      </div>

      {isIdentityVerifyComplete ? (
        <div className="justify-content-center mt-4">
          <div className="content__wrapper p-0">
            <div className="group-content">
              <div className="bg-content-finish text-center text-pre-wrap text-first-line">{messageShow}</div>
            </div>
          </div>
        </div>
      ) : (
        <div className="line-height-34">
          本人確認とは、公的証明書類をもとに運営側がご本人であることを確認する手続きです。
          <br />
          本人確認を行うと、インフルエンサーはプロジェクトへの応募ができ、企業・OTASUKEはプロジェクトを作成できます。
        </div>
      )}

      <h2 className="page-title mt-5 mb-1">本人確認に利用できる書類</h2>
      <div className="line-height-34">
        ご本人であることを確認するため、氏名、生年月日、住所、有効期限が確認できる下記の書類のうち一つをご提出ください。<br />
        ※住民票の場合は発行から6カ月以内のものが必要です <br />
        ※法人の場合は発行から6カ月以内の履歴事項全部証明書が必要です
      </div>

      <div className="box-style-1__border mt-5">
        <div className="justify-content-center">
          <div className="content__wrapper">
            <div className="group-content mb-0">
              <IdentityVerifyAccordion />
            </div>
          </div>
        </div>
      </div>

      <h2 className="page-title mt-5 pt-3">登録情報</h2>
      <div className="box-style-1__border">
        <div className="justify-content-center">
          <div className="content__wrapper">
            <div className="group-content mb-0">
              <div className="table-responsive amazing-table-style-1">
                <table className="table mb-0">
                  <tbody>
                    <tr>
                      <td>アカウントの種類</td>
                      <td>企業</td>
                    </tr>
                    <tr>
                      <td>姓</td>
                      <td>{companyInfo?.family_name}</td>
                    </tr>
                    <tr>
                      <td>名</td>
                      <td>{companyInfo?.first_name}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="justify-content-center">
        <div className="content__wrapper pb-0">
          <div className="form__wrapper form-style-1 mx-auto mb-0">
            <div className="group-content mb-0 text-center">
              <Link to={toMemberPage(userType, MY_PROFILE_UPDATE)} className="subsidebar-nav__link">
                <Button variant="green" className="btn-w-287">
                  <span className="sr-only">登録情報を修正する</span>
                </Button>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <h2 className="page-title mt-5 pt-3">本人確認書類を提出する</h2>
      <div className="box-style-1__border">
        <div className="justify-content-center">
          <div className="content__wrapper">
            <div className="form__wrapper form-style-1 mx-auto mb-0">
              <div className="group-content">
                <h3 className="title-sub mb-3">添付書類</h3>
                {isLoading ? (
                  <div className="text-center mb-3">
                    <Spinner
                      as="span"
                      animation="border"
                      size="lg"
                      role="status"
                      aria-hidden="true"
                      className="me-2 text-color-green"
                    />
                  </div>
                ) : (
                  <Formik
                    initialValues={initFormikValues}
                    enableReinitialize
                    validationSchema={CreateIdentityVerifyValidation}
                    onSubmit={onFormSubmit}
                  >
                    {({ values, isSubmitting }) => (
                      <Form>
                        <div className="col-md-6 col-12 cls-type">
                          <Field
                            name="docs_type"
                            label={['本人確認書類の種類']}
                            placeholder="選択して下さい"
                            selectData={identityVerifyTypeData}
                            component={CustomSelectPicker}
                          />
                        </div>

                        <UploadMultipleFileForm />

                        <div className="text-center">
                          <div className="form-check form-check-inline m-0">
                            <Field
                              id="is_public"
                              name="is_public"
                              type="checkbox"
                              className="form-check-input"
                              checked={values.is_public}
                            />
                            <label htmlFor="is_public">
                              本人確認書類と登録情報の「氏名」「住所」「生年月日」に相違がないことを確認した
                            </label>
                          </div>
                        </div>

                        <div className="col-12 text-center mt-4 mb-5">
                          <Button type="submit" variant="green" className="btn-w-287" disabled={!values.is_public}>
                            {isSubmitting && (
                              <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                                className="me-2"
                              />
                            )}
                            本人確認書類を提出する
                          </Button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                )}
                <div className="line-height-34">
                  ご提出いただいた個人情報は、<Link to={POLICY_PRIVACY} className="text-color-green">プライバシーポリシー</Link>
                  に定める通り、ご提出いただいた個人情報の滅失、棄損、漏洩及び不正利用等を防止し、その安全管理を行うために必要な措置を講じ、個人情報安全に管理します。
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default company;
