import React, { useEffect, useState } from 'react';
import moment from 'moment';
import 'moment-timezone';
import Skeleton from 'react-loading-skeleton';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getAllNPOCategory } from 'redux/client/category_global';
import {
  exportNPOsForDonation,
  getAvailablePointsByNpoCategory,
  searchNPOsForDonation
} from 'redux/admin/donation';
import Pagination from 'components/admin/Pagination/Pagination';
import { HTTP_OK } from 'constants/ResponseStatus';
import * as ROUTES from 'constants/routes';
import NPOSearchBar from './Components/NPOSearchBar';
import PointsSearchBar from './Components/PointsSearchBar';
import NPORow from './Components/NPORow';
import DistributionToNPOModal from './Components/DistributionToNPOModal';

moment.tz.setDefault('Asia/Tokyo');

function DistributionToNPO() {
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingExportCSV, setIsLoadingExportCSV] = useState(false);
  const dispatch = useDispatch();
  const [isSearchingPoints, setIsSearchingPoints] = useState(false);
  const [isShowModal, setIsShowModal] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [pointsForDonation, setPointsForDonation] = useState({});
  const navigate = useNavigate();
  const store = useSelector((state) => ({
    availablePoints: state.donation.availablePoints,
    NPOCategories: state.categoryGlobal.NPOCategories,
    npos: state.donation.npos
  }));
  const [requestParamsFilter, setRequestParamsFilter] = useState('');
  const [initialValuesForSearchingPoints, setInitialValuesForSearchingPoints] = useState({
    npo_category_id: ''
  });
  const [initialValuesForSearchingNPOs, setInitialValuesForSearchingNPOs] = useState({
    npo_category_id: '',
    keyword: ''
  });

  useEffect(() => {
    initialPage();
  }, []);

  const initialPage = async () => {
    setIsLoading(true);
    try {
      const getAllNPOCategoryPromise = store.NPOCategories.length === 0 ? dispatch(getAllNPOCategory()) : null;
      const getNPOsForDonationPromise = store.npos.length === 0 ? dispatch(searchNPOsForDonation()) : null;

      await Promise.all([
        getAllNPOCategoryPromise,
        getNPOsForDonationPromise
      ]);

      const resp = await dispatch(getAvailablePointsByNpoCategory());
      if (resp.status != HTTP_OK) {
        navigate(ROUTES.ERROR_404_PAGE, {
          replace: true
        });
      }

      setIsLoading(false);
      return true;
    } catch (error) {
      setIsLoading(false);
      return error;
    }
  };

  const _getNPOsForDonationPaginate = async (requestParams = [], page = 1) => {
    setIsLoading(true);
    try {
      await dispatch(searchNPOsForDonation(page, requestParams));

      if (requestParams?.npo_category_id && requestParams?.npo_category_id >= 0) {
        const resp = await dispatch(
          getAvailablePointsByNpoCategory(requestParams.npo_category_id, false)
        );
        if (resp.status != HTTP_OK) {
          navigate(ROUTES.ERROR_404_PAGE, {
            replace: true
          });
        }
        setPointsForDonation(resp.data);
      } else {
        setPointsForDonation({});
      }

      setIsLoading(false);
      return true;
    } catch (error) {
      setIsLoading(false);
      return error;
    }
  };

  const _getPointsForDonation = async (requestParams = []) => {
    setIsSearchingPoints(true);
    try {
      setInitialValuesForSearchingPoints({ npo_category_id: requestParams });
      await dispatch(getAvailablePointsByNpoCategory(requestParams));
      setIsSearchingPoints(false);
      return true;
    } catch (error) {
      setIsSearchingPoints(false);
      return error;
    }
  };

  const _onSubmitFilterForm = (form) => {
    if (isLoading) {
      return;
    }
    setSelectedItems([]);
    setInitialValuesForSearchingNPOs({
      npo_category_id: form.npo_category_id,
      keyword: form.keyword
    });
    setRequestParamsFilter(form);
    _getNPOsForDonationPaginate(form);
  };

  const _onSubmitFilterPoints = (form) => {
    if (isSearchingPoints) {
      return;
    }
    _getPointsForDonation(form.npo_category_id);
  };

  const _handlePageClick = (event) => {
    _getNPOsForDonationPaginate(requestParamsFilter, event.selected + 1);
  };

  const _exportNPOsForDonation = async () => {
    setIsLoadingExportCSV(true);
    await dispatch(exportNPOsForDonation(initialValuesForSearchingPoints)).then((res) => {
      const bom = new Uint8Array([0xEF, 0xBB, 0xBF]);
      const url = URL.createObjectURL(new Blob([bom, res.data]));
      const link = document.createElement('a');
      link.href = url;

      link.setAttribute('download', `OTASUKE報酬配分${moment().format('YYYYMM')}.csv`);
      document.body.appendChild(link);
      link.click();
    });

    setIsLoadingExportCSV(false);
  };

  const handleCheckedEvent = async (event, item) => {
    if (event.target.checked) {
      // add to the list
      setSelectedItems((prevState) => [...prevState, item]);
    } else {
      const updatedItems = selectedItems.filter((selectedItem) => item.id !== selectedItem.id);
      // remove from the list
      setSelectedItems(updatedItems);
    }
  };

  return (
    <>
      <div className="mb-3 mn-md-4 distribute-to-npo-page">
        <div className="row flex-wrap mb-3">
          <div className="col-12 admin-form-bg-custom-1">
            <PointsSearchBar
              isLoading={isSearchingPoints}
              initFormikValues={initialValuesForSearchingPoints}
              onSubmitFilterForm={_onSubmitFilterPoints}
              availablePoints={store.availablePoints}
              NPOCategoryData={store.NPOCategories}
              isLoadingExportCSV={isLoadingExportCSV}
              exportNPOsForDonation={_exportNPOsForDonation}
            />
          </div>
        </div>
        <NPOSearchBar
          isLoading={isLoading}
          initFormikValues={initialValuesForSearchingNPOs}
          onSubmitFilterForm={_onSubmitFilterForm}
          NPOCategoryData={store.NPOCategories}
        />
        <div className="row">
          <div className="col-12 col-lg-10 col-xxl-8">
            <div className="d-flex justify-content-between align-items-center">
              <p className="mb-2 mb-lg-0">ポイントを付与する団体を選択し、ポイント付与ボタンをクリックしてください。</p>
              <button
                type="button"
                className="btn btn-green px-4"
                onClick={() => setIsShowModal(true)}
                disabled={isShowModal || selectedItems.length <= 0}
              >
                <strong>付与する</strong>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="table-responsive">
        <table className="table table-lg table-hover admin-table-custom-1">
          {!isLoading && store.npos?.total < 1 && (
            <caption className="text-center py-5 h5">見つかりません。</caption>
          )}
          <thead className="border-top border-2">
            <tr>
              <th> </th>
              <th>ID</th>
              <th>団体名</th>
              <th>担当者名</th>
              <th>OTASUKEカテゴリ</th>
              <th className="text-center">累計付与ポイント</th>
            </tr>
          </thead>
          <tbody>
            {isLoading && [...Array(20)].map((e, i) => (
              <tr key={`${i.toString()}`}>
                <td>
                  <Skeleton height={17} />
                </td>
                <td>
                  <Skeleton height={17} />
                </td>
                <td>
                  <Skeleton height={17} />
                </td>
                <td>
                  <Skeleton height={17} />
                </td>
                <td>
                  <Skeleton height={17} />
                </td>
                <td>
                  <Skeleton height={17} />
                </td>
              </tr>
            ))}
            {!isLoading && store.npos?.data
              && store.npos.data.map((item) => (
                <NPORow
                  key={item.id}
                  itemRow={item}
                  selectedItems={selectedItems}
                  npoCategoryId={initialValuesForSearchingNPOs.npo_category_id}
                  handleCheckedEvent={handleCheckedEvent}
                />
              ))}
          </tbody>
        </table>
      </div>

      {store.npos?.last_page > 1 && (
        <div className="d-flex justify-content-center my-3 py-md-5">
          <Pagination
            className="pagination admin-pagination-custom-1"
            pageCount={store.npos?.last_page}
            onPageChange={_handlePageClick}
          />
        </div>
      )}
      <DistributionToNPOModal
        isShowModal={isShowModal}
        setIsShowModal={setIsShowModal}
        pointsForDonation={pointsForDonation}
        npoCategoryId={initialValuesForSearchingNPOs.npo_category_id}
        selectedItems={selectedItems}
        handleCheckedEvent={handleCheckedEvent}
      />
    </>
  );
}

export default DistributionToNPO;
