export const projectTypeData = [
  {
    key: 0,
    value: '来店体験型'
  },
  {
    key: 1,
    value: 'ギフティング型'
  },
  {
    key: 2,
    value: 'オンライン・リモート型'
  },
  {
    key: 3,
    value: 'その他'
  },
  {
    key: 4,
    value: 'テディベアプロジェクト'
  }
];
