/* eslint-disable react/prop-types */
import React from 'react';
import { Star, StarFill, StarHalf } from 'react-bootstrap-icons';

function CalculateRatingStars({ rating, size }) {
  const newRating = !rating || rating < 0 || rating > 5 ? 0 : rating;
  const fullStars = Math.floor(newRating);
  const halfStars = Math.ceil(newRating - fullStars) === 1 ? 1 : 0;
  const emptyStars = 5 - fullStars - halfStars;

  return (
    <div className="rating-stars">
      {[...Array(fullStars)].map((_, index) => (
        <StarFill color="#FFCC00" className="star" size={size} key={`full-${index.toString()}`} />
      ))}
      {[...Array(halfStars)].map((_, index) => (
        <StarHalf className="star" color="#FFCC00" size={size} key={`half-${index.toString()}`} />
      ))}
      {[...Array(emptyStars)].map((_, index) => (
        <Star className="star" size={size} key={`empty-${index.toString()}`} />
      ))}
    </div>
  );
}

CalculateRatingStars.defaultProps = {
  size: 40
};

export default CalculateRatingStars;
