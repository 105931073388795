import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useSession from 'hooks/useSession';
import { toMemberPage } from 'helpers/utils';
import * as ROUTES from 'constants/routes';

import { getTop5MyProjects } from 'redux/client/project';
import { LoadingContext } from 'hooks/LoadingContext';
import { getTop3MyNews } from 'redux/client/news';
import ProjectNews from './components/ProjectNews';
import TransferPointHistory from './components/TransferPointHistory';

function MyPageNPO() {
  const { user } = useSession();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const providerValue = useMemo(() => [isLoading], [isLoading]);

  const dispatch = useDispatch();
  const store = useSelector((state) => ({
    top5MyProjects: state.project.top5MyProjects,
    top3MyNews: state.news.top3MyNews
  }));

  useEffect(() => {
    initialPage();
  }, []);

  const initialPage = async () => {
    setIsLoading(true);
    try {
      const getTop5MyProjectPromise = dispatch(getTop5MyProjects());
      const getTop3MyNewsPromise = dispatch(getTop3MyNews());
      await Promise.all([getTop5MyProjectPromise, getTop3MyNewsPromise]);
      setIsLoading(false);
      return true;
    } catch (error) {
      setIsLoading(false);
      return error;
    }
  };

  const linkToMyProjectList = (tabId) => {
    const urlRedirect = `${ROUTES.MY_PROJECT_LIST}?tabActive=${tabId}`;
    navigate(toMemberPage(user?.user_type, urlRedirect));
  };

  return (
    <LoadingContext.Provider value={providerValue}>
      <div className="page-project my-projects my-page-npo">
        <div className="client-cpanel-container">
          <div className="content_wrapper">
            <ProjectNews newsList={store.top3MyNews} />

            <div className="user-statistical">
              <div className="project-statistical">
                <div className="project-statistical-inner">
                  <div
                    className="project-statistical-item processing"
                    onClick={() => linkToMyProjectList(0)}
                    onKeyDown={() => linkToMyProjectList(0)}
                    role="button"
                    tabIndex="0"
                  >
                    <div className="number">{store.top5MyProjects?.count?.processing || 0}</div>
                    <div className="type-text">進行中</div>
                  </div>
                  <div
                    className="project-statistical-item draft"
                    onClick={() => linkToMyProjectList(1)}
                    onKeyDown={() => linkToMyProjectList(1)}
                    role="button"
                    tabIndex="0"
                  >
                    <div className="number">{store.top5MyProjects?.count?.draft || 0}</div>
                    <div className="type-text">下書き</div>
                  </div>
                  <div
                    className="project-statistical-item archive"
                    onClick={() => linkToMyProjectList(2)}
                    onKeyDown={() => linkToMyProjectList(2)}
                    role="button"
                    tabIndex="0"
                  >
                    <div className="number">{store.top5MyProjects?.count?.archive || 0}</div>
                    <div className="type-text">アーカイブ</div>
                  </div>
                </div>
              </div>
            </div>

            <TransferPointHistory />
          </div>
        </div>
      </div>
    </LoadingContext.Provider>
  );
}

export default MyPageNPO;
