import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import CalculateRatingStars from 'components/common/CalculateRatingStars';
import { useNavigate } from 'react-router-dom';
import { getMyReviews } from 'redux/client/project_reviews';
import * as ROUTES from 'constants/routes';
import { ResponseStatusMessage } from 'constants/ResponseStatusMessage';
import { ERROR_404_PAGE } from 'constants/routes';
import Skeleton from 'react-loading-skeleton';
import { ResponseStatus } from 'constants';

function influencer() {
  const [myReviews, setMyReviews] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    initialPage();
  }, []);

  const initialPage = async () => {
    setIsLoading(true);
    try {
      const res = await dispatch(getMyReviews());
      if (res.status != ResponseStatus.HTTP_OK) {
        navigate(ERROR_404_PAGE, {
          replace: true
        });
      }
      const resData = res.data;
      setMyReviews(resData);
    } catch (error) {
      navigate(ROUTES.ERROR_PAGE, {
        replace: true,
        state: {
          error_code: error.response?.status,
          error_msg: ResponseStatusMessage[error.response?.status]
        }
      });
    }
    setIsLoading(false);
  };

  return (
    <div className="evaluation-page">
      <div className="client-cpanel-container">
        <div className="position-relative">
          <h2 className="page-title">評価</h2>
          <div className="client-cpanel__page-title-after">
            <span className="shadow-text">Rating</span>
          </div>
        </div>
        <div className="box-style-1__border mt-5">
          <div className="justify-content-center">
            <div className="content__wrapper">
              <div className="group-content mb-0">
                <div
                  className="overall-rating d-flex flex-column flex-lg-row flex-wrap
                    align-items-start align-items-lg-center"
                >
                  <h3>あなたの総合評価</h3>
                  <div className="overall-rating-stars">
                    <CalculateRatingStars rating={myReviews?.average_total_point ? myReviews.average_total_point : 0} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="rating-history my-5">
          <h3>評価履歴</h3>
          {isLoading && [...Array(8)].map((e, i) => (
            <div className="news__item" key={`${i.toString()}`}>
              <Skeleton height={80} />
            </div>
          ))}
          {!isLoading && (!myReviews?.reviews || myReviews?.reviews?.length === 0) && (
            <p>見つかりません。</p>
          )}
          {!isLoading && myReviews?.reviews && myReviews?.reviews?.length > 0 && (
            <ul className="nav flex-column rating-history-list mt-3">
              {myReviews.reviews.map((item) => (
                <li
                  className="item d-flex flex-column flex-lg-row align-items-lg-center justify-content-between"
                  key={item.id}
                >
                  <div className="item-info">
                    <div className="rating-datetime mb-2">{item.created_at}</div>
                    <h4 className="project-name">{item.project_title}</h4>
                    <div className="company-name">{item.reviewer_name}</div>
                  </div>
                  <div className="item-rating">
                    <CalculateRatingStars rating={item.total_point} size="25" />
                  </div>
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </div>
  );
}

export default influencer;
