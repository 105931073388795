import React, { useEffect, useState } from 'react';
import moment from 'moment';
import 'moment-timezone';
import { Spinner } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';
import { useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { objectFlip } from 'helpers/utils';
import constants from 'constants/constants';
import { exportTransferHistory, getTransferHistoryPaginate } from 'redux/admin/transfer_history';
import Pagination from 'components/admin/Pagination/Pagination';
import TransferHistoryRow from './components/TransferHistoryRow';
import TransferHistorySearchBar from './components/TransferHistorySearchBar';

moment.tz.setDefault('Asia/Tokyo');

function TransferHistoryList() {
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingExportCSV, setIsLoadingExportCSV] = useState(false);
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  const [requestParamsFilter, setRequestParamsFilter] = useState({
    user_type: constants.USER_TYPE_BY_USER.KEY[searchParams.get('type')]?.toString()
    || constants.USER_TYPE_BY_USER.KEY.influencer.toString(),
    applicated_at: moment().format('YYYY-MM')
  });
  const transferHistories = useSelector((state) => state.transferHistory.transferHistories);

  useEffect(() => {
    const userTypeParam = constants.USER_TYPE_BY_USER.KEY[searchParams.get('type')]?.toString();
    if (!!userTypeParam && requestParamsFilter.user_type != userTypeParam) {
      requestParamsFilter.user_type = userTypeParam;
    }

    _getTransferHistoryPaginate(requestParamsFilter);
  }, [searchParams.get('type')]);

  const _getTransferHistoryPaginate = async (requestParams = [], page = 1) => {
    setIsLoading(true);
    try {
      await dispatch(getTransferHistoryPaginate(requestParams, page));

      setIsLoading(false);
      return true;
    } catch (error) {
      setIsLoading(false);
      return error;
    }
  };

  const _onSubmitFilterForm = (form) => {
    if (isLoading) {
      return;
    }
    setRequestParamsFilter(form);
    _getTransferHistoryPaginate(form);
  };

  const _handlePageClick = (event) => {
    _getTransferHistoryPaginate(requestParamsFilter, event.selected + 1);
  };

  const _exportTransferHistory = async () => {
    setIsLoadingExportCSV(true);
    await dispatch(exportTransferHistory(requestParamsFilter)).then((res) => {
      const bom = new Uint8Array([0xEF, 0xBB, 0xBF]);
      const url = URL.createObjectURL(new Blob([bom, res.data]));
      const link = document.createElement('a');
      link.href = url;

      let fileCsvName = {
        インフルエンサー: constants.USER_TYPE_BY_USER.KEY.influencer,
        NPO: constants.USER_TYPE_BY_USER.KEY.npo
      };
      fileCsvName = objectFlip(fileCsvName);
      fileCsvName = fileCsvName[requestParamsFilter.user_type];
      link.setAttribute('download', `振込申請一覧(${fileCsvName})${moment().format('YYYYMM')}.csv`);
      document.body.appendChild(link);
      link.click();
    });

    setIsLoadingExportCSV(false);
  };


  return (
    <>
      <div className="mb-3 mn-md-4">
        <div className="row flex-wrap">
          <div className="col-12 col-xl-9 col-xxl-8 mb-2 mb-xl-0">
            <TransferHistorySearchBar
              isLoading={isLoading}
              initFormikValues={requestParamsFilter}
              onSubmitFilterForm={_onSubmitFilterForm}
            />
          </div>
          <div className="col">
            <button
              type="button"
              className="btn btn-outline-primary rounded-pill admin-btn-green"
              onClick={_exportTransferHistory}
              disabled={isLoading || !transferHistories?.total || isLoadingExportCSV}
            >
              {isLoadingExportCSV && (
                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className="me-2" />
              )}
              <strong>CSVダウンロード</strong>
            </button>
          </div>
        </div>
      </div>
      <div className="table-responsive">
        <table className="table table-lg table-hover admin-table-custom-1">
          {!isLoading && transferHistories?.total < 1 && (
            <caption className="text-center py-5 h5">見つかりません。</caption>
          )}
          <thead className="border-top border-2">
            <tr>
              <th>ID</th>
              <th>氏名</th>
              <th>メールアドレス</th>
              <th>振込額</th>
              <th>振込申請日</th>
            </tr>
          </thead>
          <tbody>
            {isLoading && [...Array(20)].map((e, i) => (
              <tr key={`${i.toString()}`}>
                <td>
                  <Skeleton height={17} />
                </td>
                <td>
                  <Skeleton height={17} />
                </td>
                <td>
                  <Skeleton height={17} />
                </td>
                <td>
                  <Skeleton height={17} />
                </td>
                <td>
                  <Skeleton height={17} />
                </td>
                <td>
                  <Skeleton height={17} />
                </td>
              </tr>
            ))}

            {!isLoading && transferHistories?.data
              && transferHistories.data.map((item) => <TransferHistoryRow key={item.id} itemRow={item} />)}
          </tbody>
        </table>
      </div>

      {transferHistories?.last_page > 1 && (
        <div className="d-flex justify-content-center my-3 py-md-5">
          <Pagination
            className="pagination admin-pagination-custom-1"
            pageCount={transferHistories?.last_page}
            onPageChange={_handlePageClick}
          />
        </div>
      )}
    </>
  );
}

export default TransferHistoryList;
