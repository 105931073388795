export const identityVerifyTypeData = [
  {
    key: 0,
    value: '履歴事項全部証明書'
  },
  {
    key: 1,
    value: '運転免許証'
  },
  {
    key: 2,
    value: 'パスポート'
  },
  {
    key: 3,
    value: '住民票'
  },
  {
    key: 4,
    value: '在留カード'
  },
  {
    key: 5,
    value: 'マイナンバーカード'
  }
];
