import React from 'react';
import PropTypes from 'prop-types';

function ListProjectRewards({ project }) {
  const rewardsTalkRooms = project?.rewards_talk_rooms;
  return (
    <section className="section-list-income-history py-5">
      <h3 className="title-section mb-2">応募履歴</h3>
      <div className="table-responsive table-scroll">
        <table className="table table-lg table-hover admin-table-custom-1">
          <thead className="border-top border-2">
            <tr>
              <th>ID</th>
              <th>氏名</th>
              <th>応募日</th>
              <th>ステータス</th>
              <th>報酬額</th>
              <th>寄附金額</th>
            </tr>
          </thead>
          <tbody>
            {rewardsTalkRooms && rewardsTalkRooms.map((item) => {
              return (
                <tr key={item.influencer_id}>
                  <td>{item.influencer_id}</td>
                  <td>{item.influencer_name}</td>
                  <td>{item.talk_room_created_at}</td>
                  <td>{item.talk_room_status}</td>
                  <td>{item.reward}</td>
                  <td>{item.donation}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </section>
  );
}

ListProjectRewards.propTypes = {
  project: PropTypes.object
};

ListProjectRewards.defaultProps = {
  project: null
};
export default ListProjectRewards;
