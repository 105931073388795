import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import { Field, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import * as ja from 'helpers/yup-locale-ja';
import { Spinner } from 'react-bootstrap';
import { CustomDatePickerStartSunday } from 'components/formik';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useSession from 'hooks/useSession';
import { HTTP_OK, HTTP_UNPROCESSABLE_ENTITY } from 'constants/ResponseStatus';
import { ERROR_404_PAGE } from 'constants/routes';
import { findProjectAppoint, updateProjectAppoint } from 'redux/client/message';
import { USER_TYPE_COMPANY, USER_TYPE_INFLUENCER, USER_TYPE_NPO } from 'redux/client/auth';
import Skeleton from 'react-loading-skeleton';
import { setFormikErrors } from 'helpers/utils';

Yup.setLocale(ja.suggestive);
const today = new Date();
today.setHours(0, 0, 0, 0);
const FormSchema = Yup.object().shape({
  candidate_date_1: Yup.date().required(),
  candidate_date_2: Yup.date().min(today).test(
    'unique-date2',
    'この日付を選択されています。',
    (value, testContext) => {
      return new Date(value).getTime() != new Date(testContext.parent?.candidate_date_1).getTime();
    }
  ),
  candidate_date_3: Yup.date().min(today).test(
    'unique-date3',
    'この日付を選択されています。',
    (value, testContext) => {
      const valueTest = new Date(value).getTime();
      return valueTest != new Date(testContext.parent?.candidate_date_2).getTime()
        && valueTest != new Date(testContext.parent?.candidate_date_1).getTime();
    }
  ),
  appoint_date: Yup.string().when('candidate_date_1', (val) => {
    if (val > 0) {
      return Yup.string().notRequired();
    }
    return Yup.string().required();
  })
});


function ScheduleModal({
  isShowScheduleModal,
  setIsShowScheduleModal,
  talkRoomId,
  onSubmit
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSession();

  const formik = useFormik({
    initialValues: {
      candidate_date_1: '',
      candidate_date_2: '',
      candidate_date_3: '',
      appoint_date: ''
    },
    onSubmit: (form, formikHelpers) => handleOnSubmit(form, formikHelpers),
    enableReinitialize: true,
    validationSchema: FormSchema
  });

  const handleOnSubmit = async (form, formikHelpers) => {
    const data = { ...form, talk_room_id: talkRoomId };
    const resp = await dispatch(updateProjectAppoint(data));
    if (resp && resp.status === HTTP_UNPROCESSABLE_ENTITY) {
      setIsLoading(false);
      setFormikErrors(resp.data.errors, formikHelpers.setFieldError);
      return;
    }
    if (resp.status != HTTP_OK) {
      navigate(ERROR_404_PAGE, {
        replace: true
      });
    }
    onSubmit();
    setIsShowScheduleModal(false);
    formik.resetForm();
  };

  const [isLoading, setIsLoading] = useState(true);
  const userType = user?.user_type;
  const [isReceiver, setIsReceiver] = useState(false);
  const [appoint, setAppoint] = useState({});

  const _getProjectAppoint = async () => {
    setIsLoading(true);
    const resp = await dispatch(findProjectAppoint(talkRoomId));
    if (resp.status != HTTP_OK) {
      navigate(ERROR_404_PAGE, {
        replace: true
      });
    }

    // send by influencer, receive by company/npo
    if ((resp.data.request_by === USER_TYPE_INFLUENCER && userType !== USER_TYPE_INFLUENCER)
      // send by company/npo, receive by influencer
      || ((resp.data.request_by === USER_TYPE_COMPANY || resp.data.request_by === USER_TYPE_NPO)
        && userType === USER_TYPE_INFLUENCER)) {
      setIsReceiver(true);
    } else {
      setIsReceiver(false);
    }

    setAppoint(resp.data);
    formik.setFieldValue('candidate_date_1', resp.data.candidate_date_1 ?? '');
    formik.setFieldValue('candidate_date_2', resp.data.candidate_date_2 ?? '');
    formik.setFieldValue('candidate_date_3', resp.data.candidate_date_3 ?? '');
    setIsLoading(false);
  };

  const closeModal = () => {
    setIsLoading(true);
    formik.resetForm();
  };

  return (
    <Modal
      onHide={() => setIsShowScheduleModal(false)}
      show={isShowScheduleModal}
      centered
      dialogClassName="schedule-modal bs-modal-custom-1"
      backdrop="static"
      keyboard={false}
      onEntered={() => { _getProjectAppoint(); }}
    >
      <Modal.Header closeButton onClick={() => closeModal()} />
      <Modal.Body>
        <div className="container">
          {appoint.appoint_date && (
            <h3 className="modal-title h5 mb-4 text-center">
              <strong>{appoint.appoint_date_formatted}で日程が確定されました</strong>
            </h3>
          )}
          {!appoint.appoint_date && (
            <FormikProvider value={formik}>
              <form className="form-style-1">
                {isReceiver ? (
                  <div className="px-3">
                    <h3 className="modal-title h5 mb-4 text-center">
                      <strong>希望日程を選択してください</strong>
                    </h3>
                    <p className="text-center">
                      {appoint.username}さんより日程候補を送信されました。<br />
                      あなたの希望日程を選択し、送信してください。
                    </p>
                    {isLoading ? [...Array(3)].map((e, i) => (
                      <div className="news__item" key={`${i.toString()}`}>
                        <Skeleton height={24} />
                      </div>
                    )) : (
                      <>
                        {appoint.candidate_date_1 && appoint.is_candidate_date_1 && (
                          <div className="row mb-3">
                            <div className="col-md-6 co-12 offset-md-3">
                              <div className="form-radio">
                                <label htmlFor="candidate_date_1">
                                  <Field
                                    id="candidate_date_1"
                                    type="radio"
                                    name="appoint_date"
                                    value={appoint.candidate_date_1}
                                    className="form-radio-input"
                                  />
                                  {appoint.candidate_date_1_formatted}
                                </label>
                              </div>
                            </div>
                          </div>
                        )}
                        {appoint.candidate_date_2 && appoint.is_candidate_date_2 && (
                          <div className="row mb-3">
                            <div className="col-md-6 co-12 offset-md-3">
                              <div className="form-radio">
                                <label htmlFor="candidate_date_2">
                                  <Field
                                    id="candidate_date_2"
                                    type="radio"
                                    name="appoint_date"
                                    value={appoint.candidate_date_2}
                                    className="form-radio-input"
                                  />
                                  {appoint.candidate_date_2_formatted}
                                </label>
                              </div>
                            </div>
                          </div>
                        )}
                        {appoint.candidate_date_3 && appoint.is_candidate_date_3 && (
                          <div className="row mb-3">
                            <div className="col-md-6 co-12 offset-md-3">
                              <div className="form-radio">
                                <label htmlFor="candidate_date_3">
                                  <Field
                                    id="candidate_date_3"
                                    type="radio"
                                    name="appoint_date"
                                    value={appoint.candidate_date_3}
                                    className="form-radio-input"
                                  />
                                  {appoint.candidate_date_3_formatted}
                                </label>
                              </div>
                            </div>
                          </div>
                        )}
                        <div className="row mb-3">
                          <div className="col-md-6 co-12 offset-md-3">
                            <div className="form-radio">
                              <label htmlFor="is_refuse_schedule">
                                <Field
                                  id="is_refuse_schedule"
                                  type="radio"
                                  name="appoint_date"
                                  value="0000-00-00"
                                  className="form-radio-input"
                                />
                                日程を差し戻す
                              </label>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                ) : (
                  <div className="px-5 mobile-px-0">
                    <h3 className="modal-title h5 mb-4 text-center">
                      <strong>日程候補を選択してください</strong>
                    </h3>
                    {isLoading ? [...Array(3)].map((e, i) => (
                      <div className="news__item" key={`${i.toString()}`}>
                        <Skeleton height={24} />
                      </div>
                    )) : (
                      <>
                        <div className="row mb-3">
                          <div className="col-12">
                            <Field
                              name="candidate_date_1"
                              label={['第一希望日', '必須']}
                              placeholderText="日付を選択してください"
                              minDate={new Date()}
                              dateFormat="yyyy/MM/dd"
                              component={CustomDatePickerStartSunday}
                            />
                          </div>
                        </div>
                        <div className="row mb-3">
                          <div className="col-12">
                            <Field
                              name="candidate_date_2"
                              label={['第二希望日']}
                              placeholderText="日付を選択してください"
                              minDate={new Date()}
                              dateFormat="yyyy/MM/dd"
                              component={CustomDatePickerStartSunday}
                            />
                          </div>
                        </div>
                        <div className="row mb-5">
                          <div className="col-12">
                            <Field
                              name="candidate_date_3"
                              label={['第三希望日']}
                              placeholderText="日付を選択してください"
                              minDate={new Date()}
                              dateFormat="yyyy/MM/dd"
                              component={CustomDatePickerStartSunday}
                            />
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                )}

                <div className="row mt-5 mb-3">
                  <div className="col-12 text-center">
                    <button
                      type="button"
                      className="btn btn-green"
                      onClick={() => {
                        formik.handleSubmit();
                        setIsLoading(true);
                      }}
                      disabled={formik.isSubmitting || !formik.isValid || isLoading}
                    >
                      {formik.isSubmitting && (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                          className="me-2"
                        />
                      )}
                      日程を送る
                    </button>
                  </div>
                </div>
                {isReceiver && (
                  <div className="text-center mb-3">※メッセージを入力中の場合、画面更新により入力内容が消えてしまいますので、ご注意ください。</div>
                )}
              </form>
            </FormikProvider>
          )}

        </div>
      </Modal.Body>
    </Modal>
  );
}

ScheduleModal.propTypes = {
  isShowScheduleModal: PropTypes.bool.isRequired,
  setIsShowScheduleModal: PropTypes.func.isRequired,
  talkRoomId: PropTypes.number.isRequired,
  onSubmit: PropTypes.func.isRequired
};

export default ScheduleModal;
