import React from 'react';
import constants from 'constants/constants';

function ContactLine() {
  return (
    <section className="contact-line margin-content">
      <div className="client-container text-center">
        <a
          href={constants.LINE_LINK}
          target="_blank"
          rel="noreferrer"
          className="line-link text-decoration-none text-dark"
        >
          公式LINEにお問合せ @160qrgpb
        </a>
      </div>
    </section>
  );
}
export default ContactLine;
