import React from 'react';
import PropTypes from 'prop-types';

function UserCompanyPointStatistics({ userCompany }) {
  return (
    <section className="row py-4">
      <div className="col-md-4 col-sm-4 py-1">
        <ul className="align-self-center list-unstyled text-center light-pink p-4 fw-bold">
          <li>購入済み累計ポイント</li>
          <li>{userCompany?.purchase_total_point.toLocaleString()}pt</li>
        </ul>
      </div>
      <div className="col-md-4 col-sm-4 py-1">
        <ul className="align-self-center list-unstyled text-center light-pink p-4 fw-bold">
          <li>使用済み累計ポイント</li>
          <li>{userCompany?.purchase_total_point_used.toLocaleString()}pt</li>
        </ul>
      </div>
      <div className="col-md-4 col-sm-4 py-1">
        <ul className="align-self-center list-unstyled text-center light-pink p-4 fw-bold">
          <li>現在の保有ポイント</li>
          <li>{userCompany?.purchase_current_point.toLocaleString()}pt</li>
        </ul>
      </div>
    </section>
  );
}

UserCompanyPointStatistics.propTypes = {
  userCompany: PropTypes.object
};

UserCompanyPointStatistics.defaultProps = {
  userCompany: null
};
export default UserCompanyPointStatistics;
