/**
 * 0 Applying
 * 1 Approved
 * 2 Rejected
 */
export const identityVerifyMessageData = [
  {
    key: 0,
    value: '審査中です。\n確認まで約5営業日掛かる場合がございます。'
  },
  {
    key: 1,
    value: '本人確認は完了しています \n情報に更新がある場合は、再度本人確認を行ってください'
  },
  {
    key: 2,
    value: '申請が却下されました。'
  }
];
