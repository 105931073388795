import { ErrorMessage, FormikProvider, useFormik } from 'formik';
import useSession from 'hooks/useSession';
import React, { useEffect, useState } from 'react';
import { Modal, Spinner } from 'react-bootstrap';
import TiktokAvatar from 'assets/tiktok_avatar.png';
import * as ROUTES from 'constants/routes';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import API from 'axios/API';
import { HTTP_FORBIDDEN, HTTP_OK, HTTP_UNPROCESSABLE_ENTITY } from 'constants/ResponseStatus';
import { useDispatch, useSelector } from 'react-redux';
import { getLatestRewardChangeDate, GET_LATEST_REWARD_CHANGE_DATE } from 'redux/client/user';
import { updateTiktokInfo } from 'redux/client/auth';
import moment from 'moment';
import 'moment-timezone';
import { setFormikErrors, toMemberPage } from 'helpers/utils';
import { ResponseStatusMessage } from 'constants/ResponseStatusMessage';
import { RewardChangeValidation } from '../reward/RewardChangeValidation';

moment.tz.setDefault('Asia/Tokyo');

const API_URL = process.env.REACT_APP_APP_API;

function InfluencerRewardChange() {
  const { user } = useSession();
  const navigate = useNavigate();
  const param = useParams();
  const dispatch = useDispatch();

  const [searchParams] = useSearchParams();
  const [isConnectTiktok, setIsConnectTiktok] = useState(false);
  const [tiktokAccessToken, setTiktokAccessToken] = useState();
  const [modalTitle, setModalTitle] = useState('内容にお間違いがないかご確認ください');
  const [modalText, setModalText] = useState('');
  const [isModalTiktok, setIsModalTiktok] = useState(true);
  const formik = useFormik({
    initialValues: {
      tiktok_info: ''
    },
    validationSchema: RewardChangeValidation,
    onSubmit: (form, formikHelpers) => handleOnFormSubmit(form, formikHelpers)
  });

  const latestRewardChangeDate = useSelector((state) => state.user.latestRewardChangeDate);

  const getTikTokAccessToken = async () => {
    setIsConnectTiktok(true);
    await API({
      method: 'GET',
      url: 'influencer/tiktok/get-access-token'
    })
      .then((res) => {
        if (res.data && !res.data.refresh_token) {
          setIsModalTiktok(true);
          setTiktokAccessToken(res.data.access_token);
          setShowModal(true);
        } else if (res.data && res.data.refresh_token) {
          window.location.href = `${API_URL}/auth/tiktok/reward/${res.data.access_token}`;
        }
        return res.statusCode;
      })
      .catch(() => {
        setIsConnectTiktok(false);
        return false;
      });
  };

  // Fetch tiktok info right after the access token is returned
  const fetchTiktokInfo = async () => {
    setIsConnectTiktok(true);

    await API({
      method: 'GET',
      url: 'influencer/tiktok/fetch-tiktoker-for-reward',
      params: {
        access_token: tiktokAccessToken
      }
    })
      .then((res) => {
        const userInfo = res.data.user_info.data.user;
        const tiktokVideos = res.data.video_list;
        let commentCount = 0;
        let shareCount = 0;
        let likeCount = 0;
        let viewCount = 0;
        const videoIds = [];

        tiktokVideos.forEach((video) => {
          commentCount += video.comment_count;
          shareCount += video.share_count;
          likeCount += video.like_count;
          viewCount += video.view_count;
          videoIds.push(video.id);
        });

        const tiktokInfoCount = {
          comment_count: commentCount,
          share_count: shareCount,
          like_count: likeCount,
          view_count: viewCount
        };

        formik.setFieldValue('tiktok_info', { ...userInfo, ...tiktokInfoCount });
        formik.setFieldValue('tiktok_auth_access_token', tiktokAccessToken);
        formik.setFieldValue('tiktok_videos', videoIds.toString());
        setIsConnectTiktok(false);
        return res;
      })
      .catch(() => {
        setIsConnectTiktok(false);
        window.location.href = `${API_URL}/auth/tiktok/influencer/${param.userAuthToken}`;
        return false;
      });
  };

  const [showModal, setShowModal] = useState(false);
  const handleModalClose = async () => {
    setShowModal(false);
    setModalTitle('内容にお間違いがないかご確認ください');
    setModalText('');
    setIsModalTiktok(true);
    setTiktokAccessToken('');
    formik.resetForm();
    navigate(toMemberPage(user?.user_type, ROUTES.MYPAGE));
  };

  useEffect(() => {
    if (typeof tiktokAccessToken != 'undefined' && tiktokAccessToken != '') {
      fetchTiktokInfo();
    }

    if (searchParams.get('error') == HTTP_FORBIDDEN) {
      setIsModalTiktok(false);
      setModalTitle('TikTokが認証されました');
      setModalText('TikTokの認証をキャンセルしました');
      setShowModal(true);
    }

    if (searchParams.get('error') == HTTP_UNPROCESSABLE_ENTITY) {
      setIsModalTiktok(false);
      setModalTitle('TikTokが認証されました');
      setModalText('この TikTok アカウントはまだ登録されていません。');
      setShowModal(true);
    }

    if (searchParams.get('error_valid_tiktok') == HTTP_UNPROCESSABLE_ENTITY) {
      navigate(toMemberPage(user?.user_type, ROUTES.MYPAGE));
    }

    if (typeof param.tiktokAccessToken != 'undefined' && param.tiktokAccessToken != '') {
      setIsModalTiktok(true);
      setTiktokAccessToken(param.tiktokAccessToken);
      setModalTitle('内容にお間違いがないかご確認ください');
      setModalText('');
      setShowModal(true);
    }

    if (!latestRewardChangeDate) {
      initialPage();
    }
  }, [tiktokAccessToken]);

  // Load the latest reward change date on the first time render
  const initialPage = () => {
    try {
      dispatch(getLatestRewardChangeDate());
      return true;
    } catch (error) {
      return error;
    }
  };

  const handleOnFormSubmit = async (form, formikHelpers) => {
    try {
      const resp = await dispatch(updateTiktokInfo(form));
      if (resp && resp.status === HTTP_UNPROCESSABLE_ENTITY) {
        setFormikErrors(resp.data.errors, formikHelpers.setFieldError);
      }
      if (resp.status && resp.status === HTTP_OK) {
        // set new latest request change
        dispatch({
          type: GET_LATEST_REWARD_CHANGE_DATE,
          payload: {
            data: resp.data
          }
        });

        setIsModalTiktok(false);
        setModalTitle('報酬変更申請');
        setModalText('申請を送信しました。');
      }
      return true;
    } catch (error) {
      navigate(ROUTES.ERROR_PAGE, {
        replace: true,
        state: {
          error_code: 413,
          error_msg: ResponseStatusMessage[413]
        }
      });
      return error;
    }
  };

  return (
    <div className="reward-page">
      <FormikProvider value={formik}>
        <Modal
          onHide={handleModalClose}
          className="modal-amazing reward-modal"
          show={showModal}
          size="lg"
          centered
        >
          <Modal.Header closeButton />
          <Modal.Body className="py-5 pb-0">
            <Modal.Title className="text-center">{modalTitle}</Modal.Title>
            <div className="modal-text-center">{modalText}</div>
            {formik.values.tiktok_info && (Object.keys(formik.values.tiktok_info)?.length > 0
            && !isConnectTiktok && isModalTiktok) && (
              <form>
                <ErrorMessage name="tiktok_info" className="invalid-feedback d-block" component="div" />
                <div className="tiktok-api-data mb-4 form-style-1 ">
                  <div className="form-group-item row pt-1">
                    <div className="col-12">
                      <div className="tiktok-avatar">
                        <img src={formik.values.tiktok_info.avatar_url || TiktokAvatar} alt="" />
                      </div>
                    </div>
                  </div>
                  <div className="form-group-item row tiktok-info">
                    <div className="d-md-flex">
                      <div className="flex-md-fill">
                        <div className="d-flex flex-wrap">
                          <div className="flex-fill">
                            ニックネーム
                          </div>
                          <div className="flex-fill text-color-gray-custom">
                            {formik.values.tiktok_info.display_name}
                          </div>
                        </div>
                        <div className="d-flex flex-wrap">
                          <div className="flex-fill">
                            フォロワー数
                          </div>
                          <div className="flex-fill text-color-gray-custom">
                            {formik.values.tiktok_info.follower_count.toLocaleString()} 人
                          </div>
                        </div>
                        <div className="d-flex flex-wrap">
                          <div className="flex-fill">
                            直近7日間に投稿された動画のいいね数
                          </div>
                          <div className="flex-fill text-color-gray-custom">
                            {formik.values.tiktok_info.like_count.toLocaleString()}
                          </div>
                        </div>
                      </div>
                      <div className="flex-md-fill">
                        <div className="d-flex flex-wrap">
                          <div className="flex-fill">
                            直近7日間に投稿された動画のコメント数
                          </div>
                          <div className="flex-fill text-color-gray-custom">
                            {formik.values.tiktok_info.comment_count.toLocaleString()}
                          </div>
                        </div>
                        <div className="d-flex flex-wrap">
                          <div className="flex-fill">
                            直近7日間に投稿された動画のシェア数
                          </div>
                          <div className="flex-fill text-color-gray-custom">
                            {formik.values.tiktok_info.share_count.toLocaleString()}
                          </div>
                        </div>
                        <div className="d-flex flex-wrap">
                          <div className="flex-fill">
                            直近7日間に投稿された動画の再生回数
                          </div>
                          <div className="flex-fill text-color-gray-custom">
                            {formik.values.tiktok_info.view_count.toLocaleString()}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            )}
          </Modal.Body>
          <Modal.Footer className="justify-content-center border-top-0 pb-5">
            {isModalTiktok ? (
              <button
                className="btn-auth common-btn d-block mx-auto"
                size="lg"
                type="submit"
                onClick={formik.submitForm}
                disabled={formik.isSubmitting || isConnectTiktok}
              >
                {(formik.isSubmitting || isConnectTiktok) && (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    className="me-2"
                  />
                )}
                申請する
              </button>
            ) : (
              <button
                className="btn-auth common-btn d-block mx-auto"
                size="lg"
                type="button"
                onClick={handleModalClose}
              >
                閉じる
              </button>
            )}
          </Modal.Footer>
        </Modal>
      </FormikProvider>
      <div className="position-relative">
        <h2 className="page-title">戦闘力の計測</h2>
        <div className="client-cpanel__page-title-after">
          <span className="shadow-text">Reward Change Application</span>
        </div>
      </div>

      <div className="box-style-1__border outline-wrapper text-center">
        <p className="mb-0 mb-sm-3">
          最終 戦闘力の計測日
          <span className="latest-update">
            {latestRewardChangeDate ? moment(latestRewardChangeDate).format('YYYY.MM.DD') : ''}
          </span>
        </p>
        <p className="font-mb-18">戦闘力の計測をして、最新の情報に更新しましょう</p>
        <p className="reward-warning">
          <i className="icon-svg warning-svg" />
          最終 戦闘力の計測日から8日以上経過している場合、プロジェクトへの応募及び指名オファーが届きません
        </p>
        <button
          type="button"
          className="btn-auth common-btn d-block mx-auto"
          onClick={getTikTokAccessToken}
          disabled={isConnectTiktok}
        >
          {isConnectTiktok && (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
              className="me-2"
            />
          )}
          計測する
        </button>
      </div>
    </div>
  );
}

export default InfluencerRewardChange;
