import { toMemberPage } from 'helpers/utils';
import useSession from 'hooks/useSession';
import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';

import VisaIcon from 'assets/svg/visa.svg';
import { useDispatch, useSelector } from 'react-redux';
import { getPopClientKey,
  purchasePoints } from 'redux/client/purchase_points';
import * as ROUTES from 'constants/routes';
import { useScript } from 'hooks';
import { HTTP_OK } from 'constants/ResponseStatus';
import { getProductById } from 'redux/client/product';
import Skeleton from 'react-loading-skeleton';
import { SAVE_IS_PURCHASED_POINTS } from 'redux/client/auth';

function Purchase() {
  const { user } = useSession();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const store = useSelector((state) => ({
    product: state.product.product,
    popClientKey: state.purchasePoints.popClientKey
  }));

  useScript('https://pay.veritrans.co.jp/pop/v1/javascripts/pop.js', store.popClientKey);

  useEffect(() => {
    initialPage();
  }, []);

  const handleOnClickCreditCardPayment = async () => {
    setIsLoading(true);
    await dispatch(purchasePoints(store.product))
      .then((res) => {
        const options = {
          onSuccess(result) {
            dispatch({ type: SAVE_IS_PURCHASED_POINTS, payload: true });
            navigate(toMemberPage(user?.user_type, ROUTES.PURCHASE_COMPLETE), {
              state: result
            });
          },
          onFailure() {
          },
          onIncomplete() {
            // stand in current page incase of response as incomplete
          }
        };

        window.pop.pay(res.data?.payment_key, options);
      })
      .catch((error) => {
        return error;
      });
    setIsLoading(false);
  };

  const initialPage = async () => {
    await dispatch(getProductById(id))
      .then((res) => {
        if (res.status != HTTP_OK || (res.data && res.data.length)) {
          navigate(ROUTES.ERROR_404_PAGE, {
            replace: true
          });
        }
      })
      .catch((error) => {
        return error;
      });

    await dispatch(getPopClientKey());

    setIsLoading(false);
  };

  return (
    <div className="client-cpanel-container">
      <div className="row page-purchase pb-5">
        <Link to={toMemberPage(user?.user_type, ROUTES.MY_POINT_INDEX)} className="btn-back-link mb-2 mt-0">
          <span>戻る</span>
        </Link>
        <div className="col-12 mt-5 mt-md-2 purchase-title">
          <div className="position-relative">
            <h2 className="page-title">ポイント管理</h2>
            <div className="client-cpanel__page-title-after">
              <span className="shadow-text">Point Management</span>
            </div>
          </div>
        </div>
        <div className="col-12 total-fee">
          <div className="total-fee-container">

            {store.product?.price ? (
              <div className="d-inline-flex flex-wrap align-items-center justify-content-center">
                <div className="fee-text"> 合計金額</div>
                <div className="fee-value">
                  <span>￥</span>
                  <span>{store.product.price}</span>
                </div>
              </div>
            ) : (
              <div className="d-inline-flex flex-wrap align-items-center justify-content-center">
                <Skeleton containerClassName="w-25" height={25} />
                <Skeleton containerClassName="w-50" height={50} />
              </div>
            )}
          </div>
        </div>
        <div className="col-12">
          <div className="row info">
            <div className="d-inline-flex flex-wrap align-items-center justify-content-center">
              <p className="col-lg-2 col-md-2 col-12 px-0 label">クレジットカード</p>
              <p className="col-lg-6 col-md-6 col-12 content">
                <img src={VisaIcon} alt="" />
              </p>
              <p className="col-lg-4 col-md-4 col-12">
                <Button
                  variant="redirect common-btn mt-3 pop-navigate"
                  onClick={() => handleOnClickCreditCardPayment()}
                  disabled={isLoading}
                >
                  <span className="sr-only">クレジットカード決済</span>
                </Button>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Purchase;
