import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getInfluencerInformation,
  getProjectsForUserInfluencerForMyPage
} from 'redux/client/project';
import { getTop3MyNews } from 'redux/client/news';
import { LoadingContext } from 'hooks/LoadingContext';
import InfluencerInformation from './components/InfluencerInformation';
import InfluencerRewardChange from './components/InfluencerRewardChange';
import TransferPointHistory from './components/TransferPointHistory';

function MyPageInfluencer() {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const providerValue = useMemo(() => [isLoading], [isLoading]);
  const store = useSelector((state) => ({
    myProjects: state.project.projectsInfluencerMyPage,
    top3MyNews: state.news.top3MyNews,
    influencerInformation: state.project.influencerInformation
  }));
  const [MyProjectRequestParamsFilter] = useState({
    is_favorited: 'false',
    is_top_3: 'false'
  });

  useEffect(() => {
    initialPage();
  }, []);
  const initialPage = async () => {
    setIsLoading(true);
    try {
      const getTop3MyNewsPromise = dispatch(getTop3MyNews());
      const getInfluencerInformationPromise = dispatch(getInfluencerInformation());
      const myProjectsPromise = dispatch(getProjectsForUserInfluencerForMyPage(MyProjectRequestParamsFilter));
      await Promise.all([
        getTop3MyNewsPromise,
        getInfluencerInformationPromise,
        myProjectsPromise
      ]);
      setIsLoading(false);
      return true;
    } catch (error) {
      setIsLoading(false);
      return error;
    }
  };

  return (
    <LoadingContext.Provider value={providerValue}>
      <div className="page-project my-pages">
        <div className="client-cpanel-container">
          <InfluencerInformation
            newsList={store.top3MyNews}
            influencerInformation={store.influencerInformation}
            myProjects={store.myProjects}
          />
          <InfluencerRewardChange />
          <TransferPointHistory />
        </div>
      </div>
    </LoadingContext.Provider>
  );
}
export default MyPageInfluencer;
