import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import * as ROUTES from 'constants/routes';
import { ResponseStatusMessage } from 'constants/ResponseStatusMessage';
import { checkNumberParam } from 'helpers/utils';

import LoadingOverlay from 'components/loading/LoadingOverlay';
import { getUserNpoDetail } from 'redux/admin/user';
import UserInfo from './components/UserInfo';
import PointStatistics from './components/PointStatistics';
import Statistics from './components/Statistics';
import UserProjects from './components/UserProjects';
import UserDonations from './components/UserDonations';

function UserNpoDetail() {
  const param = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [userNpoDetail, setUserNpoDetail] = useState(null);

  const _getUserNpoDetail = async () => {
    if (!checkNumberParam(param.id)) {
      navigate(ROUTES.ERROR_404_PAGE, {
        replace: true,
        state: {
          error_code: 404,
          error_msg: ResponseStatusMessage[404]
        }
      });
    }

    try {
      const res = await dispatch(getUserNpoDetail(param.id));
      if (res.data.length < 1) {
        navigate(ROUTES.ERROR_404_PAGE, {
          replace: true,
          state: {
            error_code: 404,
            error_msg: ResponseStatusMessage[404]
          }
        });
      }
      setUserNpoDetail(res.data);
      setIsLoading(true);
      return true;
    } catch (error) {
      setIsLoading(false);
      navigate(ROUTES.ERROR_PAGE, {
        replace: true,
        state: {
          error_code: error.response?.status,
          error_msg: ResponseStatusMessage[error.response?.status]
        }
      });
      return error;
    }
  };

  useEffect(() => {
    _getUserNpoDetail();
  }, []);

  return (
    <div className="client-cpanel user-detail">
      {!isLoading ? (
        <div className="text-color-green">
          <LoadingOverlay />
        </div>
      ) : (
        <>
          <UserInfo userInfo={userNpoDetail} />
          <PointStatistics userInfo={userNpoDetail} />
          <Statistics userInfo={userNpoDetail} />
          <UserProjects />
          <UserDonations />
        </>
      )}
    </div>
  );
}

export default UserNpoDetail;
