import { GET_ALL_AREA_SUCCESS } from './areaActions';

const initialState = {
  areas: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_AREA_SUCCESS:
      return {
        ...state,
        areas: action.payload.data
      };
    default:
      return state;
  }
};
