import React, { useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import * as ROUTES from 'constants/routes';

function CompleteResetPassword() {
  const { state } = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    if (!state) {
      navigate(ROUTES.HOME);
    }

    navigate(ROUTES.RESET_PASSWORD_COMPLETE, { replace: true });
  }, []);

  return (
    <div className="auth-complete">
      <div className="container text-center">
        <div className="align-center-wrap">
          <div className="position-wrap">
            <h3 className="title-section mb-5">パスワード再設定が完了しました</h3>
            <p className="title-section-sub mb-5">
              新しく登録したパスワードでログインをお願い致します。
            </p>

            <div className="row">
              <div className="col-12 mb-4">
                <Link to={ROUTES.SIGN_IN} className="btn-submit-project common-btn d-block mx-auto">
                  ログイン
                </Link>
              </div>

              <div className="col-12">
                <Link to={ROUTES.HOME} className="btn-redirect common-outline-btn d-block mx-auto">
                  トップに戻る
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CompleteResetPassword;
