import React from 'react';
import { Modal, Spinner } from 'react-bootstrap';
import * as Yup from 'yup';
import * as ja from 'helpers/yup-locale-ja';
import { Field, Formik } from 'formik';
import PropTypes from 'prop-types';
import AmazingAlert from 'components/common/AmazingAlert';

Yup.setLocale(ja.suggestive);
const FormSchema = Yup.object().shape({
  confirmed_transfer: Yup.boolean().required()
});

function TransferPointsModal({
  netAmount,
  isShowModal,
  setIsShowModal,
  onRequestTransferClick,
  isSubmitting,
  flashMessage,
  isShowFlash,
  setIsShowFlash
}) {
  const initFormikValues = {
    confirmed_transfer: false
  };

  return (
    <div>
      <Modal
        onHide={() => setIsShowModal(false)}
        show={isShowModal}
        size="md"
        centered
        className="transfer-points-modal modal-amazing"
      >
        <Modal.Header closeButton />
        <Modal.Body>
          <div className="container">
            {flashMessage && isShowFlash
              ? <AmazingAlert message={flashMessage} onCloseAlert={() => setIsShowFlash(false)} /> : null}
            <Formik initialValues={initFormikValues} validationSchema={FormSchema} onSubmit={onRequestTransferClick}>
              {({ handleSubmit, handleChange, values }) => (
                <form className="transfer-modal form-style-1">
                  <div className="justify-content-center transfer-modal-value-group">
                    <strong className="transfer-modal__name">振込申請</strong>
                    <div className="transfer-modal__box-value">
                      <span>{netAmount}</span>
                      <span>円</span>
                    </div>
                  </div>
                  <div className="mt-4 form-check form-check-inline">
                    <Field
                      id="confirm-transfer"
                      type="checkbox"
                      name="confirmed_transfer"
                      className="form-check-input"
                      onChange={(e) => {
                        handleChange(e);
                      }}
                    />
                    <label className="form-check-label" htmlFor="confirm-transfer">上記の内容で振り込みを申請しますか？</label>
                  </div>
                  <div className="row mt-4">
                    <div className="col-12 text-center">
                      <button
                        type="button"
                        className="btn common-btn confirmed-earned-points__btn"
                        onClick={() => {
                          handleSubmit();
                        }}
                        disabled={isSubmitting || !values.confirmed_transfer}
                      >
                        {isSubmitting && (
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            className="me-2"
                          />
                        )}
                        申請する
                      </button>
                    </div>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

TransferPointsModal.defaultProps = {
  netAmount: '0',
  flashMessage: ''
};

TransferPointsModal.propTypes = {
  netAmount: PropTypes.string,
  isShowModal: PropTypes.bool.isRequired,
  setIsShowModal: PropTypes.func.isRequired,
  onRequestTransferClick: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  flashMessage: PropTypes.string,
  isShowFlash: PropTypes.bool.isRequired,
  setIsShowFlash: PropTypes.func.isRequired
};

export default TransferPointsModal;
