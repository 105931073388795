import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ResponseStatus } from 'constants';
import * as ROUTES from 'constants/routes';
import { ResponseStatusMessage } from 'constants/ResponseStatusMessage';

import { Button, Spinner, Modal } from 'react-bootstrap';
import LoadingOverlay from 'components/loading/LoadingOverlay';
import { Form, Formik } from 'formik';
import { setFormikErrors } from 'helpers/utils';
import { findMyBankAccount, createBankAccount } from 'redux/client/bank_account';
import CreateOrUpdateBackAccountForm from './components/CreateOrUpdateBackAccountForm';
import { CreateOrUpdateBackAccountValidation } from './CreateOrUpdateBackAccountValidation';

function index() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const initFormData = {
    bank_code: '',
    bank_name: '',
    branch_code: '',
    branch_name: '',
    account_type: 1,
    account_number: '',
    account_name: ''
  };
  const [isLoading, setIsLoading] = useState(true);
  const [showModalCompleted, setShowModalCompleted] = useState(false);
  const store = useSelector((state) => ({
    bankAccountInfo: state.bankAccount.bankAccountInfo
  }));

  const fetchMyBankAccount = async () => {
    try {
      const bankAccountInfoPromise = dispatch(findMyBankAccount());
      await Promise.all([bankAccountInfoPromise]);
    } catch (error) {
      navigate(ROUTES.ERROR_PAGE, {
        replace: true,
        state: {
          error_code: error.response?.status,
          error_msg: ResponseStatusMessage[error.response?.status]
        }
      });
    }
    setIsLoading(false);
  };

  const onFormSubmit = async (form, formikHelpers) => {
    const res = await dispatch(createBankAccount(form));
    if (res && res.status == ResponseStatus.HTTP_UNPROCESSABLE_ENTITY) {
      setFormikErrors(res.data.errors, formikHelpers.setFieldError);
    } else if (res?.status != ResponseStatus.HTTP_OK && res?.status != ResponseStatus.HTTP_UNPROCESSABLE_ENTITY) {
      navigate(ROUTES.ERROR_PAGE, {
        replace: true,
        state: {
          error_code: res?.status,
          error_msg: ResponseStatusMessage[res?.status]
        }
      });
    } else {
      setShowModalCompleted(true);
    }
  };

  const handleCloseModal = () => {
    setShowModalCompleted(false);
  };

  useEffect(() => {
    fetchMyBankAccount();
  }, []);

  return (
    <div className="back-account-page client-cpanel-container">
      <Modal className="modal-amazing" show={showModalCompleted} size="lg" centered>
        <Modal.Header>
          <Modal.Title className="text-center">振込口座の登録が完了しました</Modal.Title>
        </Modal.Header>
        <Modal.Footer className="justify-content-center border-top-0">
          <Button variant="modal-redirect" className="common-btn btn-small py-2" onClick={handleCloseModal}>
            閉じる
          </Button>
        </Modal.Footer>
      </Modal>

      <div className="box-style-1__border">
        <div className="justify-content-center">
          <div className="content__wrapper">
            <Formik
              initialValues={store.bankAccountInfo || initFormData}
              enableReinitialize
              validationSchema={CreateOrUpdateBackAccountValidation}
              onSubmit={onFormSubmit}
            >
              {({ isSubmitting }) => (
                <Form className="form__wrapper mx-auto form-style-1">
                  {
                    isLoading ? (
                      <div className="text-color-green">
                        <LoadingOverlay />
                      </div>
                    ) : (
                      <>
                        <CreateOrUpdateBackAccountForm />

                        <div className="col-12 text-center mt-5 mb-3">
                          <Button type="submit" className="btn-w-287 common-btn py-1" disabled={isSubmitting}>
                            {isSubmitting && (
                              <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                                className="me-2"
                              />
                            )}
                            <span className="sr-only">登録</span>
                          </Button>
                        </div>
                      </>
                    )
                  }
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
}

export default index;
