import React from 'react';
import PropTypes from 'prop-types';
import { Field, Formik } from 'formik';
import { CustomSelectMultiplePicker } from 'components/formik';

function UserInfluencerSearchBar({ tagData, prefectureData, genderData, onSubmitFilterForm, isLoadingForm }) {
  const initFormikValues = {
    tag_ids: '',
    prefecture_ids: '',
    gender: '',
    is_verified: false,
    is_concluded: false
  };

  return (
    <Formik initialValues={initFormikValues} onSubmit={onSubmitFilterForm}>
      {({ handleChange, handleSubmit }) => (
        <form className="admin-form-custom-1">
          <div className="row mb-3">
            <div className="col-md-4 col-12">
              <label htmlFor="tag_id" className="form-label">
                タグ
              </label>
              <Field
                name="tag_ids"
                label={['活動地域']}
                placeholder="選択してください"
                selectData={tagData}
                component={CustomSelectMultiplePicker}
                mouseLeaveChange
                onChange={handleSubmit}
              />
            </div>
            <div className="col-md-4 col-12">
              <Field
                name="prefecture_ids"
                label={['活動可能地域']}
                placeholder="選択してください"
                selectData={prefectureData}
                component={CustomSelectMultiplePicker}
                mouseLeaveChange
                isLoadingForm={isLoadingForm}
                onChange={handleSubmit}
              />
            </div>
            <div className="col-md-4 col-12">
              <label htmlFor="gender" className="form-label">
                性別
              </label>
              <Field
                as="select"
                name="gender"
                id="gender"
                className="form-select rounded-0"
                required
                onChange={(e) => {
                  handleChange(e);
                  handleSubmit();
                }}
              >
                <option value="">選択してください</option>
                {genderData.map((gender) => (
                  <option key={gender.key} value={gender.key}>
                    {gender.value}
                  </option>
                ))}
              </Field>
            </div>
          </div>
        </form>
      )}
    </Formik>
  );
}

UserInfluencerSearchBar.defaultProps = {
  isLoadingForm: false
};

UserInfluencerSearchBar.propTypes = {
  tagData: PropTypes.array.isRequired,
  prefectureData: PropTypes.array.isRequired,
  genderData: PropTypes.array.isRequired,
  onSubmitFilterForm: PropTypes.func.isRequired,
  isLoadingForm: PropTypes.bool
};

export default UserInfluencerSearchBar;
