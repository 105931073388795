import API from 'axios/API';

export const TALK_ROOM_LIST = 'TALK_ROOM_LIST';

export const STATUS_ROOM_CANDIDACY = 0;
export const STATUS_ROOM_DO_NOT_HIRED = 1;
export const STATUS_ROOM_RECRUITMENT = 2;
export const STATUS_ROOM_PENDING = 3;
export const STATUS_ROOM_UNDER_ADJUSTMENT = 4;
export const STATUS_ROOM_TRANSACTION_CANCELLATION = 5;
export const STATUS_ROOM_TRANSACTION_CANCELLATION_APPROVAL = 6;
export const STATUS_ROOM_SUBMISSION_APPLICATION = 7;
export const STATUS_ROOM_POST_APPROVAL = 8;
export const STATUS_ROOM_INDICATION_APPROVAL = 9;

export const getListTalkRoomByProjectId = (projectId) => (dispatch) => {
  let urlProject = `my-project/${projectId}/talk-rooms?rel=tiktokInfo,userRank`;
  urlProject += '&relAttr=teddy_bear_rank,influencer_reward';

  return API({
    method: 'GET',
    url: urlProject
  })
    .then((res) => {
      dispatch({
        type: TALK_ROOM_LIST,
        payload: res
      });
      return res;
    })
    .catch((error) => {
      return error;
    });
};

export const sendCancellationRequest = (talkRoomId, requestForm) => () => {
  return API({
    method: 'POST',
    url: `talk-room/${talkRoomId}/send-cancellation-request`,
    data: requestForm
  })
    .catch((error) => {
      return error;
    });
};

