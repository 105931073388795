import {
  GET_STATISTICAL_REVENUE_SUCCESS,
  GET_STATISTICAL_REGISTER_USERS_SUCCESS,
  GET_STATISTICAL_REGISTER_DASHBOARD_SUCCESS
} from './myPageActions';

const initialState = {
  revenue: {
    labels: [],
    values: [],
    years: []
  },
  registerUsers: {
    labels: [],
    values: [],
    years: []
  },
  dataGeneral: {
    count_project_public: null,
    reward_ranking_influencer: [],
    point_purchase_ranking_company: [],
    donation_ranking: []
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_STATISTICAL_REVENUE_SUCCESS:
      return {
        ...state,
        revenue: action.payload.data
      };

    case GET_STATISTICAL_REGISTER_USERS_SUCCESS:
      return {
        ...state,
        registerUsers: action.payload.data
      };
    case GET_STATISTICAL_REGISTER_DASHBOARD_SUCCESS:
      return {
        ...state,
        dataGeneral: action.payload.data
      };
    default:
      return state;
  }
};
