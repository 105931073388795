import * as Yup from 'yup';
import * as ja from 'helpers/yup-locale-ja';

Yup.setLocale(ja.suggestive);
export const ChangePasswordValidation = Yup.object().shape({
  old_password: Yup.string()
    .required()
    .max(255)
    .matches(/^[a-zA-Z0-9]{8}/, '半角英数字8桁以上で入力してください。'),
  password: Yup.string()
    .required()
    .max(255)
    .matches(/^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z0-9]{8,}$/, '半角英数字8桁以上で入力してください。'),
  password_confirmation: Yup.string()
    .required()
    .max(255)
    .matches(/^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z0-9]{8,}$/, '半角英数字8桁以上で入力してください。')
    .oneOf([Yup.ref('password')], 'パスワードと確認用パスワードが一致しません。')
});
