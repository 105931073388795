import React, { useEffect } from 'react';
import { getRewardRateSetting } from 'redux/admin/reward_rate_setting';
import { useDispatch, useSelector } from 'react-redux';
import { Tab, Tabs } from 'react-bootstrap';
import FormRewardRateSetting from './components/FormRewardRateSetting';

function RewardRateSetting() {
  const dispatch = useDispatch();
  const rewardRateSetting = useSelector((state) => state.rewardRateSetting.data);

  const setInitialValuesFormByType = (typeValue) => {
    let rewardRate = (Object.keys(rewardRateSetting).length > 0 && !rewardRateSetting.success)
      && rewardRateSetting.filter((item) => item.type == typeValue);

    if (rewardRate && rewardRate.length > 0) {
      rewardRate = Object.assign({}, ...rewardRate);
    }

    return {
      type: typeValue,
      comment_count: rewardRate?.comment_count || '',
      like_count: rewardRate?.like_count || '',
      share_count: rewardRate?.share_count || '',
      view_count: rewardRate?.view_count || '',
      engagement: rewardRate?.engagement || '',
      average_pr_engagement: rewardRate?.average_pr_engagement || ''
    };
  };

  useEffect(() => {
    if (Object.keys(rewardRateSetting).length < 1) {
      dispatch(getRewardRateSetting());
    }
  }, []);

  return (
    <div id="reward-rate-setting-page">
      <div className="card rounded-0 border-0">
        <div className="card-header px-0 bg-transparent border-bottom-0 title__page">報酬倍率変更</div>
        <div className="card-body p-0 az-admin-tabs">
          <Tabs defaultActiveKey="reward-0" id="reward-rate-setting-tab" className="mb-3">
            <Tab key="reward-0" eventKey="reward-0" title="立候補式">
              <div className="row pt-4">
                <div className="col-12 col-lg-8 col-xl-9 col-xxl-8">
                  <FormRewardRateSetting initialValuesForm={setInitialValuesFormByType(0)} />
                </div>
              </div>
            </Tab>

            <Tab key="reward-1" eventKey="reward-1" title="先着式">
              <div className="row pt-4">
                <div className="col-12 col-lg-8 col-xl-9 col-xxl-8">
                  <FormRewardRateSetting initialValuesForm={setInitialValuesFormByType(1)} />
                </div>
              </div>
            </Tab>

            <Tab key="reward-2" eventKey="reward-2" title="指名式">
              <div className="row pt-4">
                <div className="col-12 col-lg-8 col-xl-9 col-xxl-8">
                  <FormRewardRateSetting initialValuesForm={setInitialValuesFormByType(2)} />
                </div>
              </div>
            </Tab>
          </Tabs>
        </div>
      </div>
    </div>
  );
}

export default RewardRateSetting;
