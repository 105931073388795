import AdminAPI from 'axios/AdminAPI';

export const GET_STATISTICAL_REVENUE_SUCCESS = 'GET_STATISTICAL_REVENUE_SUCCESS';
export const GET_STATISTICAL_REGISTER_USERS_SUCCESS = 'GET_STATISTICAL_REGISTER_USERS_SUCCESS';
export const GET_STATISTICAL_REGISTER_DASHBOARD_SUCCESS = 'GET_STATISTICAL_REGISTER_DASHBOARD_SUCCESS';

export const getStatisticalRevenue = (requestParams) => (dispatch) => {
  let URLApi = 'dashboard/revenue';
  const requestParamsStr = requestParams?.year ? new URLSearchParams(requestParams).toString() : null;
  if (requestParamsStr) {
    URLApi += `?${requestParamsStr}`;
  }

  return AdminAPI({
    method: 'GET',
    url: URLApi
  })
    .then((res) => {
      dispatch({
        type: GET_STATISTICAL_REVENUE_SUCCESS,
        payload: res
      });
    })
    .catch((error) => {
      return error;
    });
};


export const getStatisticalRegisterUsers = (requestParams) => (dispatch) => {
  let URLApi = 'dashboard/registered-users';
  const requestParamsStr = requestParams?.year ? new URLSearchParams(requestParams).toString() : null;
  if (requestParamsStr) {
    URLApi += `?${requestParamsStr}`;
  }

  return AdminAPI({
    method: 'GET',
    url: URLApi
  })
    .then((res) => {
      dispatch({
        type: GET_STATISTICAL_REGISTER_USERS_SUCCESS,
        payload: res
      });
    })
    .catch((error) => {
      return error;
    });
};

export const getStatisticalDashBoard = () => (dispatch) => {
  const URLApi = 'dashboard/project-and-ranking-info';

  return AdminAPI({
    method: 'GET',
    url: URLApi
  })
    .then((res) => {
      dispatch({
        type: GET_STATISTICAL_REGISTER_DASHBOARD_SUCCESS,
        payload: res
      });
    })
    .catch((error) => {
      return error;
    });
};
