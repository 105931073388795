import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Skeleton from 'react-loading-skeleton';

import Pagination from 'components/admin/Pagination/Pagination';
import { Field, Formik } from 'formik';
import { getCancellationRequestsList } from 'redux/admin/cancellation';
import { STATUS_ROOM_TRANSACTION_CANCELLATION } from 'redux/client/talk_room';
import CompanyCancellationRow from './Components/CompanyCancellationRow';
import CompanyCancellationModal from './Components/CompanyCancellationModal';

function List() {
  const [isLoading, setIsLoading] = useState(true);
  const [isAllowAll, setIsAllowAll] = useState(false);
  const [remountPagingComponent, setRemountPagingComponent] = useState(0);
  const [popUpData, setPopUpData] = useState();
  const [isShowModal, setIsShowModal] = useState(false);
  const dispatch = useDispatch();
  const store = useSelector((state) => ({
    cancellationRequests: state.cancellation.cancellationRequests
  }));

  const initFormikValues = {
    is_allow_all: false
  };

  useEffect(() => {
    _getCancellationRequest(1, isAllowAll);
  }, []);

  const _getCancellationRequest = async (page = 1, isAllowAllStatus) => {
    setIsLoading(true);
    try {
      await dispatch(getCancellationRequestsList(page, isAllowAllStatus));
      setIsLoading(false);
      return true;
    } catch (error) {
      setIsLoading(false);
      return error;
    }
  };

  const onPagingClick = (event) => {
    _getCancellationRequest(event.selected + 1, isAllowAll);
  };

  const onSubmitFilterForm = (form) => {
    setRemountPagingComponent(Math.random());
    setIsAllowAll(form.is_allow_all);
    _getCancellationRequest(1, form.is_allow_all);
  };

  const _handleConfirmRequestClick = (item) => {
    if (item.talk_room_status == STATUS_ROOM_TRANSACTION_CANCELLATION) {
      setIsShowModal(true);
      setPopUpData(item);
    }
  };

  return (
    <div className="company-cancellation-list-filter">
      <Formik initialValues={initFormikValues} onSubmit={onSubmitFilterForm}>
        {({ handleChange, handleSubmit }) => (
          <form className="admin-form-custom-1">
            <div className="mb-0 mt-4">
              <div className="form-check form-check-inline">
                <Field
                  type="checkbox"
                  name="is_allow_all"
                  id="is_allow_all"
                  className="form-check-input"
                  onChange={(e) => {
                    handleChange(e);
                    handleSubmit();
                  }}
                />
                <label className="form-check-label" htmlFor="is_allow_all">
                  取引キャンセル承認済みも表示する
                </label>
              </div>
            </div>
          </form>
        )}
      </Formik>

      <div className="table-responsive mt-5">
        <table className="table table-lg table-hover admin-table-custom-1">
          {(!isLoading && store.cancellationRequests?.total < 1) && (
            <caption className="text-center py-5 h5">見つかりません。</caption>
          )}
          <thead className="border-top border-2">
            <tr>
              <th width="20%">プロジェクト名</th>
              <th width="20%">会社名 / 団体名</th>
              <th width="20%">インフルエンサー氏名</th>
              <th width="20%">申請日</th>
              <th width="20%">ステータス</th>
            </tr>
          </thead>
          <tbody>
            {isLoading && [...Array(20)].map((e, i) => (
              <tr key={`${i.toString()}`}>
                <td>
                  <Skeleton height={17} />
                </td>
                <td>
                  <Skeleton height={17} />
                </td>
                <td>
                  <Skeleton height={17} />
                </td>
                <td>
                  <Skeleton height={17} />
                </td>
                <td>
                  <Skeleton height={17} />
                </td>
              </tr>
            ))}

            {(!isLoading && store.cancellationRequests?.data)
              && store.cancellationRequests.data.map((item) => (
                <CompanyCancellationRow
                  key={item.talk_room_id}
                  itemRow={item}
                  handleConfirmRequestClick={() => _handleConfirmRequestClick(item)}
                />
              ))}
          </tbody>
        </table>
      </div>

      {store.cancellationRequests?.last_page > 1 && (
        <div className="d-flex justify-content-center my-5">
          <Pagination
            className="pagination admin-pagination-custom-1"
            pageCount={store.cancellationRequests?.last_page}
            key={remountPagingComponent}
            onPageChange={onPagingClick}
          />
        </div>
      )}
      <CompanyCancellationModal
        isShowModal={isShowModal}
        setIsShowModal={setIsShowModal}
        data={popUpData}
        getCancellationRequest={_getCancellationRequest}
        isAllowAll={isAllowAll}
      />
    </div>
  );
}

export default List;
