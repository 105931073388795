import React from 'react';
import { Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import VideoGuideMethodPostRequest from 'assets/videos/guide-method-post-request.mp4';

function GuideMethodPostRequestModel({
  isShowGuideVideoModel,
  setIsShowGuideVideoModel
}) {
  const handleClose = () => {
    setIsShowGuideVideoModel(false);
  };

  return (
    <div>
      <Modal
        onHide={() => setIsShowGuideVideoModel(false)}
        show={isShowGuideVideoModel}
        size="md"
        centered
        className="project-list-modal"
      >
        <Modal.Header closeButton />
        <Modal.Body>
          <div className="text-center">
            <video width="100%" height="auto" controls preload="auto" muted>
              <source src={VideoGuideMethodPostRequest} type="video/mp4" />
            </video>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="action-column">
            <button
              type="button"
              tabIndex="0"
              className="admin-btn-light btn btn-secondary"
              onClick={() => handleClose()}
            >
              <span>閉じる</span>
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
GuideMethodPostRequestModel.propTypes = {
  isShowGuideVideoModel: PropTypes.bool.isRequired,
  setIsShowGuideVideoModel: PropTypes.func.isRequired
};

export default GuideMethodPostRequestModel;
