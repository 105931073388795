import React from 'react';
import PropTypes from 'prop-types';
import CalculateRatingStars from 'components/common/CalculateRatingStars';
// import CalculateTeddyBearRank from 'components/common/CalculateTeddyBearRank';

function UserCompanyReviews({ userCompany }) {
  return (
    <section className="row section-reviews">
      <div className="col-md-3 col-sm-6">
        <div className="label form-control border-0 bg-overlay px-3">評価</div>
      </div>
      <div className="col-md-3 col-sm-6">
        <div className="d-flex justify-content-center">
          <CalculateRatingStars rating={userCompany?.user_rank} />
        </div>
      </div>
      {/* remove teddybear_rank of company https://nat-doj.backlog.com/view/AMAZING-204
      <div className="col-md-3 col-sm-6">
        <div className="label form-control border-0 bg-overlay px-3">テディベアランク</div>
      </div>
      <div className="col-md-3 col-sm-6">
        <CalculateTeddyBearRank rating={userCompany?.purchase_teddy_bear_rank} />
      </div> */}
    </section>
  );
}

UserCompanyReviews.propTypes = {
  userCompany: PropTypes.object
};

UserCompanyReviews.defaultProps = {
  userCompany: null
};
export default UserCompanyReviews;
