import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import { ErrorMessage } from 'formik';
import { useYubinBango } from 'hooks';

function CustomCityCodeInput({ field, form, form: { touched, errors }, inputRef, ...props }) {
  return (
    <div>
      <Form.Group>
        <label htmlFor={field.name} className="form-label">
          <span>郵便番号</span>
          <span className="label-required">必須</span>
        </label>
        <Form.Control
          id={field.name}
          type="text"
          className="form-control"
          isInvalid={touched[field.name] && errors[field.name]}
          {...props}
          {...field}
          onBlur={async (e) => {
            form.handleBlur(field.name);
            const { region, locality, street } = await useYubinBango(e.target.value);
            setTimeout(() => {
              form.setFieldValue(field.name, e.target.value);
              form.setFieldValue('prefecture_id', region);
            }, 0);
            setTimeout(() => {
              form.setFieldValue('address', `${locality}${street}`);
            }, 2);
          }}
        />
        <ErrorMessage name={field.name} component="div" className="invalid-feedback" />
      </Form.Group>
    </div>
  );
}

CustomCityCodeInput.defaultProps = {
  inputRef: undefined
};

CustomCityCodeInput.propTypes = {
  field: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  inputRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({
      current: PropTypes.instanceOf(Element)
    })
  ])
};

export default CustomCityCodeInput;
