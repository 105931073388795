import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';

import useSession from 'hooks/useSession';
import * as ROUTES from 'constants/routes';
import { EnvelopeCheckFill } from 'react-bootstrap-icons';
import { useDispatch } from 'react-redux';
import { emailVerificationMember } from 'redux/client/auth';
import LoadingOverlay from 'components/loading/LoadingOverlay';
import { ResponseStatusMessage } from 'constants/ResponseStatusMessage';

const verifyStatusObj = {
  false: {
    title: 'メールアドレス認証に失敗しました！',
    color: 'failed'
  },
  true: {
    title: 'メールアドレス認証に成功しました！',
    color: 'success'
  }
};
function index() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { token } = useParams();
  const { accessToken } = useSession();
  const [isLoading, setIsLoading] = useState(true);
  const [isVerifySuccess, setIsVerifySuccess] = useState(null);
  const isAuthenticated = accessToken;

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(emailVerificationMember(token))
        .then((res) => {
          setIsLoading(false);
          setIsVerifySuccess(res.data);
        })
        .catch((error) => {
          navigate(ROUTES.ERROR_PAGE, {
            replace: true,
            state: {
              error_code: error.response?.status,
              error_msg: ResponseStatusMessage[error.response?.status]
            }
          });
        });
    } else {
      navigate(ROUTES.SIGN_IN);
    }
  }, []);
  return isLoading ? (
    <LoadingOverlay />
  ) : (
    <div className="client-container email-verify-page">
      <EnvelopeCheckFill size="4rem" className={`email-verify__icon ${verifyStatusObj[isVerifySuccess]?.color}`} />
      <h3 className={`email-verify__title ${verifyStatusObj[isVerifySuccess]?.color}`}>
        {verifyStatusObj[isVerifySuccess]?.title}
      </h3>

      {isVerifySuccess && (
        <Link to={ROUTES.SIGN_IN} className="btn common-btn email-verify__btn">
          ログイン
        </Link>
      )}
    </div>
  );
}

export default index;
