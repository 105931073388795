import {
  TALK_ROOM_LIST
} from './talkRoomActions';

const initialState = {
  talkRooms: [],
  pureTalkRooms: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TALK_ROOM_LIST:
      return {
        ...state,
        talkRooms: action.payload.data,
        pureTalkRooms: action.payload.data
      };
    case 'FILTER_EXCHANGE_TAB': {
      let filterData = state.pureTalkRooms;
      if (action.payload.status != '' && action.payload.status != 'all') {
        const exchangeData = filterData.exchange?.data.filter((talkRoom) => {
          return talkRoom.status == action.payload.status;
        });
        filterData = {
          ...filterData,
          exchange: {
            ...filterData.exchange,
            data: exchangeData
          }
        };
      }
      return {
        ...state,
        talkRooms: filterData
      };
    }
    default:
      return state;
  }
};

