import React from 'react';
import { Field, Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import useSession from 'hooks/useSession';
import constants from 'constants/constants';
import { PROJECT_ALL_TYPE } from 'redux/client/project';

function MyProjectInfluencerFilter({ initFormikValues, onSubmit }) {
  const { unreadMessagesPublicOffering, unreadMessagesCandidacy, unreadMessagesNominated } = useSession();

  const showUnreadMessages = (typeProject) => {
    let contentHtml = '';
    const totalMess = unreadMessagesPublicOffering + unreadMessagesCandidacy + unreadMessagesNominated;
    switch (typeProject) {
      case constants.PROJECT_TYPE.PUBLIC:
        contentHtml = unreadMessagesPublicOffering > 0 && (
          <span className="unread-messages-total">{unreadMessagesPublicOffering}</span>
        );
        break;
      case constants.PROJECT_TYPE.CANDIDACY:
        contentHtml = unreadMessagesCandidacy > 0 && (
          <span className="unread-messages-total">{unreadMessagesCandidacy}</span>
        );
        break;
      case constants.PROJECT_TYPE.SPECIFIED:
        contentHtml = unreadMessagesNominated > 0 && (
          <span className="unread-messages-total">{unreadMessagesNominated}</span>
        );
        break;
      case PROJECT_ALL_TYPE:
        contentHtml = totalMess > 0 && (
          <span className="unread-messages-total">{totalMess}</span>
        );
        break;
      default:
        break;
    }
    return contentHtml;
  };

  return (
    <Formik initialValues={initFormikValues} onSubmit={onSubmit}>
      {({ handleBlur, handleSubmit }) => (
        <div className="mt-4 section filter">
          <Form
            onChange={(e) => {
              handleBlur(e);
              handleSubmit();
            }}
            className="d-flex"
          >
            <div className="mt-4 row">
              <div>
                {/* Get All */}
                <div className="mb-2 pe-2 mb-sm-3 pe-sm-4 radio-button float-start">
                  <Field id="type-all" type="radio" name="type" value={`${PROJECT_ALL_TYPE}`} />
                  <label htmlFor="type-all">すべて {showUnreadMessages(PROJECT_ALL_TYPE) || ''}</label>
                </div>
                <div className="mb-2 pe-2 mb-sm-3 pe-sm-4 radio-button float-start">
                  <Field id="type1" type="radio" name="type" value={constants.PROJECT_TYPE.PUBLIC.toString()} />
                  <label htmlFor="type1">立候補式 {showUnreadMessages(constants.PROJECT_TYPE.PUBLIC) || ''}</label>
                </div>
                <div className="mb-2 pe-2 mb-sm-3 pe-sm-4 radio-button text-nowrap float-start">
                  <Field id="type2" type="radio" name="type" value={constants.PROJECT_TYPE.CANDIDACY.toString()} />
                  <label htmlFor="type2">先着式 {showUnreadMessages(constants.PROJECT_TYPE.CANDIDACY) || ''}</label>
                </div>
                <div className="mb-2 pe-2 mb-sm-3 pe-sm-4 radio-button float-start">
                  <Field id="type3" type="radio" name="type" value={constants.PROJECT_TYPE.SPECIFIED.toString()} />
                  <label htmlFor="type3">指名式 {showUnreadMessages(constants.PROJECT_TYPE.SPECIFIED) || ''}</label>
                </div>
                <div className="mb-2 pe-2 mb-sm-3 pe-sm-4 radio-button float-start">
                  <Field id="type4" type="radio" name="type" value="is_favorited" />
                  <label htmlFor="type4">お気に入り</label>
                </div>
              </div>
            </div>

          </Form>
        </div>
      )}
    </Formik>
  );
}

MyProjectInfluencerFilter.propTypes = {
  initFormikValues: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired
};

export default MyProjectInfluencerFilter;
