import React from 'react';
import { FastField, Field } from 'formik';
import PropTypes from 'prop-types';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { QuestionCircleFill } from 'react-bootstrap-icons';
import * as Yup from 'yup';
import * as ja from 'helpers/yup-locale-ja';

import {
  CustomSelectMultiplePicker,
  CustomTextArea
} from 'components/formik';
import TiktokerInformation from './TiktokerInformation';

Yup.setLocale(ja.suggestive);
function SignUpInfluencerFormStep2({ prefectures, tags }) {
  return (
    <>
      <div className="form-group-item row">
        <div className="col-lg-7 col-md-7 col-sm-12 col-12">
          <label htmlFor="prefectures" className="form-label">
            活動可能地域
            <span className="label-required">必須</span>
            <OverlayTrigger
              key="top"
              placement="top"
              overlay={(
                <Tooltip id="tooltip-top" className="amazing-tooltip">
                  インフルエンサー活動を行うにあたってPR可能な活動地域を選択してください（複数選択可）
                </Tooltip>
              )}
            >
              <span className="align-middle">
                <QuestionCircleFill className="question-circle-style label-required mb-1 ms-3" size={25} />
              </span>
            </OverlayTrigger>
          </label>
          <Field
            name="prefectures"
            placeholder="選択してください"
            selectData={prefectures}
            component={CustomSelectMultiplePicker}
          />
        </div>
      </div>
      <div className="form-group-item row">
        <div className="col-lg-7 col-md-7 col-sm-12 col-12">
          <label htmlFor="tag_ids" className="form-label">
            該当タグ
            <span className="label-required">必須</span>
            <OverlayTrigger
              key="top"
              placement="top"
              overlay={(
                <Tooltip id="tooltip-top" className="amazing-tooltip">
                  企業がインフルエンサーを検索する際の絞り込み機能に活用されます。タグは登録後に変更可能です（複数選択可）
                </Tooltip>
              )}
            >
              <span className="align-middle">
                <QuestionCircleFill className="question-circle-style label-required mb-1 ms-3" size={25} />
              </span>
            </OverlayTrigger>
          </label>
          <Field
            name="tag_ids"
            // label={['該当タグ', '必須']}
            placeholder="自身のアカウントに該当するタグを選択してください"
            selectData={tags}
            component={CustomSelectMultiplePicker}
          />
        </div>
      </div>

      <div className="form-group-item row">
        <div className="col-12">
          <FastField
            name="pr"
            label={['PR文']}
            placeholder="例）\n・東京都、埼玉、神奈川でしたら直接来店が可能です\n・専業主婦なので平日であれば時間の融通が利きます\n・グルメ系の動画をよく投稿してます\n・女性フォロワーが多いのが私の強みです"
            component={CustomTextArea}
            maxLength={1000}
          />
        </div>
      </div>

      <TiktokerInformation />
    </>
  );
}

SignUpInfluencerFormStep2.propTypes = {
  prefectures: PropTypes.array.isRequired,
  tags: PropTypes.array.isRequired
};

export default SignUpInfluencerFormStep2;
