import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button } from 'react-bootstrap';
import { Form, Formik, FastField } from 'formik';
import CustomInput from 'components/formik/CustomInput';
import * as Yup from 'yup';
import * as ja from 'helpers/yup-locale-ja';
import Stack from 'react-bootstrap/Stack';

import { sendFormData } from 'redux/client/auth';
import { Link, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import * as ROUTES from 'constants/routes';
import { setFormikErrors } from 'helpers/utils';
import { ResponseStatus } from 'constants';
import { ResponseStatusMessage } from 'constants/ResponseStatusMessage';

// import TwitterIcon from 'assets/svg/twitter-ic.svg';
import FacebookIcon from 'assets/svg/facebook-ic.svg';
import GoogleIcon from 'assets/svg/google-ic.svg';
import AmazingAlert from 'components/common/AmazingAlert';
import constants from 'constants/constants';

const API_URL = process.env.REACT_APP_APP_API;
Yup.setLocale(ja.suggestive);
const FormSchema = Yup.object().shape({
  checkEmail: Yup.boolean(),
  email: Yup.string()
    .required()
    .min(3)
    .max(255)
    .matches(/^[ぁ-んァ-ン一-龯a-zA-Z0-9._+-]+@[ぁ-んァ-ン一-龯a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/)
    .email()
});

function InitialSignUp() {
  const initFormikValues = { email: '' };
  const [flashMessage, setFlashMessage] = useState(null);
  const [show, setShow] = useState(false);
  const [tittle, setTittle] = useState();
  const [userType, setUserType] = useState(0);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const param = useParams();
  const [searchParams] = useSearchParams();

  const location = useLocation();

  useEffect(() => {
    if (param.role === 'npo') {
      setUserType(2);
      setTittle('NPO会員登録');
    } else if (param.role == 'company') {
      setUserType(1);
      setTittle('企業会員登録');
    } else if (param.role == 'influencer') {
      setUserType(0);
      setTittle('インフルエンサー会員登録');
    }

    const errorCode = new URLSearchParams(location.search).get('error');
    if (errorCode == ResponseStatus.HTTP_UNPROCESSABLE_ENTITY) {
      setFlashMessage('このメールアドレスは既に使われています。別のメールアドレスをお試しください。');
      setShow(true);
    } else if (errorCode == ResponseStatus.HTTP_FAILED_DEPENDENCY) {
      setFlashMessage(
        'メールアドレスが登録されていないか、取得できませんでした。SNSのアカウント情報を確認してください。'
      );
      setShow(true);
    }
    const inviteCode = searchParams.get('invite_code');
    if (inviteCode && inviteCode !== '') {
      localStorage.setItem(constants.INVITE_CODE_STORAGE, inviteCode);
    } else {
      localStorage.removeItem(constants.INVITE_CODE_STORAGE);
    }
  }, [param.role]);


  const onFormSubmit = async (form, formikHelpers) => {
    let navigateTo = ROUTES.TEMP_COMPLETE_SIGN_UP_INFLUENCER_MAIL;
    if (param.role === 'npo') {
      navigateTo = ROUTES.TEMP_COMPLETE_SIGN_UP_NPO_MAIL;
    } else if (param.role === 'company') {
      navigateTo = ROUTES.TEMP_COMPLETE_SIGN_UP_COMPANY_MAIL;
    }

    try {
      const response = dispatch(sendFormData(form, userType)).then((res) => {
        if (res && res.status === ResponseStatus.HTTP_UNPROCESSABLE_ENTITY) {
          setFormikErrors(res.data.errors, formikHelpers.setFieldError);
        } else {
          navigate(navigateTo, {
            replace: true,
            state: {
              confirmSuccess: true,
              user_type: param.role
            }
          });
        }
      });
      await response;
      return true;
    } catch (error) {
      navigate(ROUTES.ERROR_PAGE, {
        replace: true,
        state: {
          error_code: error.response?.status,
          error_msg: ResponseStatusMessage[error.response?.status]
        }
      });
      return error;
    }
  };

  return (
    <div className="client-container initial-auth__page">
      <div className="auth__card box-style-1__border">
        <h3 className="auth__title fw-bold">{tittle}</h3>
        <div className="auth__title title-form fw-bold">
          { param.role === 'company' && (
            <Link to={ROUTES.TERMS_OF_USE_COMPANY} className="btn border-0">必ず【利用規約】をご覧下さい</Link>
          )}
          { param.role === 'influencer' && (
            <Link to={ROUTES.TERMS_OF_USE_INFLUENCER} className="btn btn border-0">必ず【利用規約】をご覧下さい</Link>
          )}
        </div>
        <Stack gap={4} className="mx-auto sns-button-list gap-4-ct">
          {flashMessage && show ? <AmazingAlert message={flashMessage} onCloseAlert={() => setShow(false)} /> : null}

          {/* <a className="continueTwitterBtn" href={`${API_URL}/auth/${param.role}/twitter`}>
            <img src={TwitterIcon} alt="twitter" />
            Twitterで会員登録
          </a> */}
          <a className="continueFacebookBtn" href={`${API_URL}/auth/${param.role}/facebook`}>
            <img src={FacebookIcon} alt="facebook" />
            Facebookで会員登録
          </a>
          <a className="continueGoogleBtn" href={`${API_URL}/auth/${param.role}/google`}>
            <img src={GoogleIcon} alt="google" />
            Googleで会員登録
          </a>
        </Stack>
        <div className="divider">
          <hr className="divider-line" />
          <div className="trfeet">または</div>
          <hr className="divider-line" />
        </div>

        <Stack gap={2} className="mx-auto">
          <Formik initialValues={initFormikValues} validationSchema={FormSchema} onSubmit={onFormSubmit}>
            {({ isValid, handleSubmit, isSubmitting }) => (
              <Form className="auth-form">
                <div className="col-12">
                  <div className="form-group">
                    <FastField
                      name="email"
                      label={['メールアドレス']}
                      placeholder="入力してください"
                      component={CustomInput}
                      autoComplete="email"
                    />
                  </div>
                  <Button
                    variant="auth common-btn d-block mx-auto"
                    onClick={handleSubmit}
                    disabled={!isValid || isSubmitting}
                  >
                    登録
                  </Button>
                  { param.role === 'company' && (
                  <Link to={ROUTES.REGISTER_INFLUENCER} className="register-link">インフルエンサー会員登録はこちらから</Link>
                  )}
                  { param.role === 'influencer' && (
                  <Link to={ROUTES.REGISTER_COMPANY} className="register-link">企業会員登録はこちらから</Link>
                  )}
                </div>
              </Form>
            )}
          </Formik>
        </Stack>
      </div>
    </div>
  );
}

export default InitialSignUp;
