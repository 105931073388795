import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FiletypePdf, ShieldCheck } from 'react-bootstrap-icons';
import { useParams, Link, useNavigate } from 'react-router-dom';

import {
  getProjectDetail,
  addProjectToFavorite,
  removeProjectFromFavorite,
  validatorApplyToProject
} from 'redux/client/project';
import { Button, Spinner } from 'react-bootstrap';
import { HTTP_OK, HTTP_UNPROCESSABLE_ENTITY } from 'constants/ResponseStatus';
import { ERROR_404_PAGE, REGISTER_INFLUENCER } from 'constants/routes';
import { getFormattedDate, findArray } from 'helpers/utils';
import constants from 'constants/constants';

import Skeleton from 'react-loading-skeleton';
import HeartFullIcon from 'assets/svg/heart-full-ic.svg';
import HeartEmptyIcon from 'assets/svg/heart-empty-ic.svg';
import useSession from 'hooks/useSession';
import { giftingData } from 'data/giftingData';
import { USER_TYPE_INFLUENCER, USER_TYPE_NPO } from 'redux/client/auth/authActions';
import CalculateRatingStars from 'components/common/CalculateRatingStars';
import CustomProgressBar from 'components/client/CustomProgressBar';
import RankSystem from 'components/common/RankSystem';
import Image from 'components/common/Image';
import ApplyProjectConfirm from './ApplyProjectConfirm';
import AttachImagesModal from './AttachImagesModal';

function index() {
  const { id } = useParams();
  const { user } = useSession();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const project = useSelector((state) => state.project.projectDetail);
  let indexImage = -1;
  const attachmentsFile = project.attachments?.map((file) => {
    if (file?.mime_type !== constants.PDF_FILE_TYPE) {
      indexImage += 1;
      return { ...file, indexImage };
    }
    return file;
  });
  const [isLoading, setIsLoading] = useState(true);
  const [isApplyToProject, setIsApplyToProject] = useState(false);
  const [validatorErrorMessage, setValidatorErrorMessage] = useState('');
  const [isShowAttachImagesModal, setIsShowAttachImagesModal] = useState(false);
  const [attachImageSelected, setAttachImageSelected] = useState(0);

  const isUserInfluencer = user?.user_type === USER_TYPE_INFLUENCER;

  useEffect(() => {
    _getProjectDetail();
  }, []);

  const _getProjectDetail = async () => {
    const resp = await dispatch(getProjectDetail(id));
    if (resp.status != HTTP_OK || (resp.data && resp.data.length == 0)) {
      navigate(ERROR_404_PAGE, {
        replace: true
      });
    }

    setIsLoading(false);
    setIsApplyToProject(true);
  };

  const [isProcessing, setIsProcessing] = useState(false);
  const addToFavorite = async (projectId) => {
    setIsProcessing(true);
    const resp = await dispatch(addProjectToFavorite(projectId));
    if (resp && resp.status === HTTP_OK) {
      project.is_favorited = 1;
    }
    setIsProcessing(false);
  };

  const removeFromFavorite = async (projectId) => {
    setIsProcessing(true);
    const resp = await dispatch(removeProjectFromFavorite(projectId));
    if (resp && resp.status === HTTP_OK) {
      project.is_favorited = 0;
    }
    setIsProcessing(false);
  };

  const [isConfirmShow, setIsConfirmShow] = useState(false);
  const applyProjectHandle = async () => {
    setIsApplyToProject(false);
    const resp = await dispatch(validatorApplyToProject(id));
    if (resp.status == HTTP_OK) {
      setIsApplyToProject(true);
    } else if (resp.status == HTTP_UNPROCESSABLE_ENTITY) {
      setValidatorErrorMessage(resp.data.errors);
    }
    setIsConfirmShow(true);
  };

  useEffect(() => {
    if (project?.attachments?.length > 0) {
      const imageNameMaxHeight = 21;
      const listEl = document.querySelectorAll('.project__attachment-name');
      for (let i = 0; i < listEl.length; i += 1) {
        if (listEl[i].offsetHeight > imageNameMaxHeight) {
          const imageName = listEl[i].textContent;
          let maxLength = 20;
          while (listEl[i].offsetHeight > imageNameMaxHeight) {
            listEl[i].innerHTML = `${imageName.substring(0, maxLength)}...${imageName.slice(-7)}`;
            maxLength -= 1;
          }
        }
      }
    }
  }, [project]);

  const handleAttachImagesModal = (indexAttachImage) => {
    setAttachImageSelected(indexAttachImage);
    setIsShowAttachImagesModal(true);
  };

  return (
    <div className="project-detail-page">
      <ApplyProjectConfirm
        isConfirmShow={isConfirmShow}
        setIsConfirmShow={setIsConfirmShow}
        project={project}
        isApplyToProject={isApplyToProject}
        setIsApplyToProject={setIsApplyToProject}
        validatorErrorMessage={validatorErrorMessage}
      />
      <AttachImagesModal
        attachImageSelected={attachImageSelected}
        isShowAttachImagesModal={isShowAttachImagesModal}
        setIsShowAttachImagesModal={setIsShowAttachImagesModal}
        attachments={project.attachments}
      />
      <div className="client-container">
        <h1 className="fw-bold project__title">
          {isLoading ? <Skeleton height={24} width="60%" /> : project.project_title}
        </h1>
        <div className="mb-3">
          {isLoading ? (
            <Skeleton height={24} width="35%" />
          ) : (
            <div className="d-flex flex-wrap">
              <span className="project__category-name">{project.category_name}</span>
              <strong className="project__type-name">{project.project_type_text}</strong>
            </div>
          )}
        </div>

        <div className="row">
          <div className="col__left">
            <div className="card project-detail__card box-style-1__border">
              <div className="project__img-top-box">
                {isLoading ? (
                  <Skeleton height={242} className="line-height--inherit" />
                ) : (
                  <>
                    <img className="project__img" src={project.link_cover_image} alt={project.project_title} />
                    {isUserInfluencer && (
                      <button
                        type="button"
                        className="project__favorite"
                        onClick={() => (project.is_favorited ? removeFromFavorite(id) : addToFavorite(id))}
                      >
                        {isProcessing && <Spinner as="span" animation="border" className="text-color-green" />}
                        {!isProcessing && project.is_favorited === 0 && <img src={HeartEmptyIcon} alt="" />}
                        {!isProcessing && project.is_favorited === 1 && <img src={HeartFullIcon} alt="" />}
                      </button>
                    )}
                  </>
                )}
              </div>
              <div className="card-body">
                <div className="project-info-mobile">
                  <div className="d-flex align-items-center justify-content-between mb-2">
                    {isLoading ? <Skeleton height={24} width={200} /> : (
                      <>
                        <strong>{project.type_name}</strong>
                        <strong className="project__day-remain">
                          {project.day_remain >= constants.DAY_REMAIN.min ? (
                            <>
                              <small>残り</small>
                              <span>{project.day_remain}</span>
                              <small>日</small>
                            </>
                          ) : (
                            <span>終了</span>
                          )}
                        </strong>
                      </>
                    )}
                  </div>
                  <div className="d-flex align-items-center justify-content-between mb-2">
                    <div className="project__recruits_number d-flex align-items-center justify-content-between">
                      <span className="project__label">募集予定人数</span>
                      {isLoading ? <Skeleton height={24} width={40} /> : (
                        <strong className="project__value">
                          <span>{project.recruits_number}</span>
                          <small>人</small>
                        </strong>
                      )}
                    </div>
                    <div className="project__recruits_number d-flex align-items-center justify-content-between">
                      <span className="project__label">現在の応募人数</span>
                      {isLoading ? <Skeleton height={24} width={40} /> : (
                        <strong className="project__value">
                          <span>{project.current_recruits_number}</span>
                          <small>人</small>
                        </strong>
                      )}
                    </div>
                  </div>
                  {user && project?.user_type_create_project != USER_TYPE_NPO && (
                    <div className="project__item">
                      <p className="mb-2">予算消化率</p>
                      {isLoading
                        ? <Skeleton height={24} width="100%" />
                        : <CustomProgressBar now={project.budget_utilization_rate} />}
                    </div>
                  )}
                </div>
                <div className="project-info">
                  <div className="project__item">
                    <span className="project__label">納品期限</span>
                    <p className="project__value">
                      {isLoading ? <Skeleton height={24} /> : getFormattedDate(project.delivery_at)}
                      {(project.day_remain >= constants.DAY_REMAIN.min
                      && project.day_remain < constants.DAY_REMAIN.max) && (
                        <span className="font-color-note">
                          <br />
                          ※このプロジェクトは本日から{project.day_remain}
                          日以内の納品が必要です。スケジュールにはご注意ください。
                        </span>
                      )}
                    </p>
                  </div>
                  <div className="project__item">
                    <span className="project__label">募集エリア</span>
                    <p className="project__value">
                      {isLoading ? (
                        <Skeleton height={24} />
                      ) : (
                        <span className="style-location-icon">{project.area_text}</span>
                      )}
                    </p>
                  </div>
                  {project?.user_type_create_project != USER_TYPE_NPO && (
                    <>
                      <div className="project__item">
                        <span className="project__label">ギフティング</span>
                        <p className="project__value">
                          {isLoading ? <Skeleton height={24} /> : findArray(project.gifting, giftingData)?.value || '-'}
                        </p>
                      </div>
                      <div className="project__item">
                        <span className="project__label">
                          ギフティング <br />
                          注意事項
                        </span>
                        <p className="project__value line-break">
                          {isLoading ? <Skeleton height={24} /> : project.gifting_notes}
                        </p>
                      </div>
                    </>
                  )}
                  <div className="project__item">
                    <span className="project__label">プロジェクト内容</span>
                    <p className="project__value line-break">
                      {isLoading ? <Skeleton height={24} /> : project.project_contents}
                    </p>
                  </div>
                  <div className="project__item">
                    <span className="project__label">プロジェクト特記事項</span>
                    <p className="project__value line-break">{isLoading ? <Skeleton height={24} /> : project.notes}</p>
                  </div>
                  <div className="project__item align-items-center">
                    <span className="project__label">指定ハッシュタグ</span>
                    <div className="project__value">
                      {isLoading ? <Skeleton height={24} /> : (
                        <ul className="nav project__hashtag-list">
                          {project.hashtag?.map((item, i) => (
                            <li key={item[i]} className="project__hashtag-item">
                              <span className="project__hashtag-name">{item}</span>
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>
                  </div>
                  <div className="project__item">
                    <span className="project__label">プロジェクトの参考URL</span>
                    <div className="project__value">
                      {isLoading ? <Skeleton height={22} /> : project.project_url && (
                        <a
                          href={project.project_url}
                          title={project.project_url}
                          className="link-blank text-decoration-none"
                          target="_blank"
                          rel="noreferrer"
                        >
                          {project.project_url}
                        </a>
                      )}
                    </div>
                  </div>
                  <div className="project__item">
                    <span className="project__label">プロジェクトの参考素材</span>
                    <div className="project__value">
                      <ul className="nav project__attachment-list">
                        {attachmentsFile?.map((item) => (
                          <li key={item.id} className="project__attachment-item">
                            {item.mime_type === constants.PDF_FILE_TYPE ? (
                              <a href={item.link_attached_file} target="_blank" rel="noreferrer">
                                <div className="project__attachment-box text-dark">
                                  <FiletypePdf size={150} />
                                </div>
                                <span className="project__attachment-name">{item.name}</span>
                              </a>
                            ) : (
                              <Button
                                variant="link"
                                className="text-decoration-none w-100 p-0"
                                onClick={() => { handleAttachImagesModal(item.indexImage); }}
                                title={item.name}
                              >
                                <div className="project__attachment-box">
                                  <Image src={item.link_attached_file} alt={item.name} />
                                </div>
                                <span className="project__attachment-name">{item.name}</span>
                              </Button>
                            )}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col__right">
            <div className="card bg-transparent border-0">
              <div className="d-flex align-items-center justify-content-between mb-4">
                {isLoading ? <Skeleton height={24} width={200} /> : (
                  <>
                    <strong className="project__type">{project.type_name}</strong>
                    <strong className="project__day-remain mb-0">
                      {project.day_remain >= constants.DAY_REMAIN.min ? (
                        <>
                          <span>残り</span>
                          <span className="number">{project.day_remain}</span>
                          <span>日</span>
                        </>
                      ) : (
                        <span>終了</span>
                      )}
                    </strong>
                  </>
                )}
              </div>
              {user && project?.user_type_create_project != USER_TYPE_NPO && (
                <div className="project-right-top__item">
                  <p className="mb-3">予算消化率</p>
                  {isLoading
                    ? <Skeleton height={24} width="100%" />
                    : <CustomProgressBar now={project.budget_utilization_rate} />}
                </div>
              )}
              <div className="project-right-top__item d-flex align-items-center">
                <span className="project__label">募集予定人数</span>
                {isLoading ? <Skeleton height={24} width={100} className="line-height--inherit" /> : (
                  <strong className="project__value">
                    <span className="number">{project.recruits_number}</span>
                    <span>人</span>
                  </strong>
                )}
              </div>
              <div className="project-right-top__item d-flex align-items-center">
                <span className="project__label">現在の応募人数</span>
                {isLoading ? <Skeleton height={24} width={100} className="line-height--inherit" /> : (
                  <strong className="project__value">
                    <span className="number">{project.current_recruits_number}</span>
                    <span>人</span>
                  </strong>
                )}
              </div>
              {user ? (
                isUserInfluencer && project.type != constants.PROJECT_TYPE.SPECIFIED
                && project.day_remain >= constants.DAY_REMAIN.min && (
                  <button
                    type="button"
                    disabled={!isApplyToProject || project.is_joined === 1}
                    className="project__apply-btn common-btn"
                    onClick={applyProjectHandle}
                  >
                    応募する
                  </button>
                )
              ) : (
                <Link to={REGISTER_INFLUENCER} className="project__apply-btn common-btn">
                  新規会員登録
                </Link>
              )}
            </div>

            <div className="card project-right__card h-auto box-style-1__border">
              <div className="card-body">
                <strong className="project__npo_or_company_name custom-maxline-2">
                  {isLoading ? <Skeleton height={22} /> : project.npo_or_company_name}
                </strong>
                <div className="d-flex align-items-center flex-wrap">
                  <div className="d-inline-flex align-items-center">
                    {project.is_identity_verify && (
                      <div className="me-3 mb-3 d-inline-flex align-items-center company__certificate">
                        <ShieldCheck />
                        <span className="ms-1">本人確認済</span>
                      </div>
                    )}
                    {project.is_nda && (
                      <div className="me-3 mb-3 d-inline-flex align-items-center company__certificate">
                        <ShieldCheck />
                        <span className="ms-1">NDA締結済</span>
                      </div>
                    )}
                  </div>
                </div>
                {isLoading ? <Skeleton height={22} className="mb-2" /> : (
                  <div className="d-flex align-items-center flex-wrap">
                    {project?.user_type_create_project !== USER_TYPE_NPO && (
                      <div className="user-rank-area-new company me-2">
                        <RankSystem
                          userType={constants.USER_TYPE_BY_USER.KEY.company}
                          rank={project.user_rank?.rank || 0}
                        />
                      </div>
                    )}
                    {project.project_review.reviewer_total > 0 && (
                      <div className="d-flex">
                        <CalculateRatingStars rating={project.project_review.rank} />
                        <span>({project.project_review.reviewer_total})</span>
                      </div>
                    )}
                  </div>
                )}


                <p className="text-decoration-underline mb-2">
                  {isLoading ? <Skeleton height={22} /> : project.npo_or_company_address}
                </p>
                {isLoading ? <Skeleton height={22} /> : project.npo_or_company_url && (
                  <a href={project.npo_or_company_url} target="_blank" rel="noreferrer" className="link-blank">
                    {project.npo_or_company_url}
                  </a>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="text-center mt-5">
          {user ? (
            isUserInfluencer && project.type != constants.PROJECT_TYPE.SPECIFIED
            && project.day_remain >= constants.DAY_REMAIN.min && (
              <button
                type="button"
                disabled={!isApplyToProject || project.is_joined === 1}
                className="project__apply-btn fixed-bottom-btn common-btn"
                onClick={applyProjectHandle}
              >
                応募する
              </button>
            )
          ) : (
            <Link to={REGISTER_INFLUENCER} className="project__apply-btn fixed-bottom-btn common-btn">
              新規会員登録
            </Link>
          )}
        </div>
      </div>
    </div>
  );
}

export default index;
