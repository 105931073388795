import React from 'react';

import NdaContents from '../components/NdaContents';

function index() {
  return (
    <NdaContents />
  );
}

export default index;
