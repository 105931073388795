import React, { useEffect, useState } from 'react';

import useSession from 'hooks/useSession';
import { HTTP_OK } from 'constants/ResponseStatus';
import { findUserInfo } from 'redux/client/nda';
import { useDispatch } from 'react-redux';
import Skeleton from 'react-loading-skeleton';
import NdaInfo from './NdaInfo';

function NdaContents() {
  const { user } = useSession();
  const userType = user?.user_type;
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [ndaConclusionData, setNdaConclusionData] = useState({});

  const fetchUserInfo = async () => {
    const res = await dispatch(findUserInfo(userType));
    if (res.status === HTTP_OK) {
      const resData = res.data;
      setNdaConclusionData(resData);
      setIsLoading(false);
      return res;
    }

    setIsLoading(false);
    return false;
  };

  const ndaConclusionContent = () => {
    return <NdaInfo myInfo={ndaConclusionData} />;
  };

  useEffect(() => {
    fetchUserInfo();
  }, []);

  return (
    <div className="page__wrap nda-page client-cpanel-container">
      <div className="position-relative">
        <h2 className="page-title">機密保持契約（NDA）</h2>
        <div className="client-cpanel__page-title-after">
          <span className="shadow-text">Non-Disclosure Agreement</span>
        </div>
      </div>
      {isLoading ? (
        <>
          <Skeleton height={24} width="100%" count={3} />
          <Skeleton height={24} width="90%" />
          <Skeleton height={24} width="80%" />
          <Skeleton height={24} width="70%" />
        </>
      ) : (
        ndaConclusionContent()
      )}
    </div>
  );
}

export default NdaContents;
