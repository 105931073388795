import { useSelector } from 'react-redux';

const useSessionAdmin = () => {
  return useSelector(({ authAdmin: { admin, accessToken } }) => ({
    admin: admin || null,
    accessToken
  }));
};

export default useSessionAdmin;
